import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { useNavigate, useParams } from "react-router-dom";

import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import "./networkScreeningEB.scss";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import {
  INameId,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import Permission from "../../../components/permission/permision";
import { ModuleOfModulePermissions } from "../../../constants/Permissions";
import {
  EmpiricalBayesAnalysis,
  ErrorMessageRequestResponseResult,
  IAnalysisParams,
} from "../../../types/safety/safetyTypes";
import Results from "./components/results/results";
import Errors from "./components/errors/errors";
import Diagnosis from "./components/dignosis/diagnosis";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
  SafetyApiUrl,
} from "../../../environment/routeSettings";
import { SimplifiedField } from "../../../types/field/fieldType";
import SafetyPermissions from "../../../constants/Permissions/SafetyPermissions";
import TesTabs from "../../../components/tesTab/tesTab";

const NetworkScreeningEB = () => {
  const [initData, setInitData] = useState<EmpiricalBayesAnalysis>(
    new EmpiricalBayesAnalysis()
  );
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initDataTesFields, setInitDataTesFields] = useState<SimplifiedField[]>(
    []
  );
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<IAnalysisParams>();
  const [isLocked, setIsLocked] = useState(true);
  const [backPermission, setBackPermission] = useState(false);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          await getInitData(params.analysisId!);
          await getInitialTesFieldData();
          await getInitDataSiteSubTypes();
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        SafetyApiUrl() + "/api/Analysis/GetNetworkScreeningDetails/" + id,
        true
      )) as RequestResponseResult<EmpiricalBayesAnalysis>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const getInitialTesFieldData = async () => {
    setInitDataTesFields(
      await TesGet(
        FieldApiUrl() +
          "/api/SimplifiedFields/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  };

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.moduleId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false);
                } else {
                  // onAdd();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function onUpdate() {
    try {
      // console.log("INist Data " + JSON.stringify(initData))
      if (activeLoading) activeLoading(true);
      var postObj = initData;
      postObj.results = [];
      postObj.errors = new ErrorMessageRequestResponseResult();
      const res = (await TesPost(
        SafetyApiUrl() + "/api/Analysis/UpdateRunNetworkScreening",
        postObj,
        true
      )) as RequestResponseResult<EmpiricalBayesAnalysis>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode == ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        setInitData({
          ...initData,
          results: res.results.results,
          errors: res.results.errors,
        });
      } else if (res.responseCode == ResponseCode.SeverityWeightNotExist) {
        notify(t("enum-SeverityWeightNotExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  // async function onAdd() {
  //   try {
  //     // const postObj: ClientModule = {
  //     //   ...initData,
  //     //   customerId: localStorage.getItem("selectedCustomerId")!
  //     // }
  //     // if (activeLoading) activeLoading(true);
  //     // const res = (await TesPost(
  //     //   apiAddresses.moduleOfModuleAPI + "ClientModule/AddClientModule",
  //     //   postObj,
  //     //   true
  //     // )) as RequestResponseResult<null>;
  //     // if (activeLoading) activeLoading(false);
  //     // if (res.responseCode == ResponseCode.OK) {
  //     //   notify(t("dataSuccessfullyAdded"), "success", 5000);
  //     //   return;
  //     // } else {
  //     //   await RequestErrorHandling(res);;
  //     // }
  //   } catch {
  //     if (activeLoading) activeLoading(false);
  //     notify(t("someErrorOccurred"), "error", 5000);
  //   }
  //   setDataChanged(false)
  // }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_V_Workbook]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("networkScreeningEB")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={goBackPermission}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <Permission
                allowed={[SafetyPermissions.Safety_D_Workbook]}
                hasFeedBackElement={true}
              >
                <div className="leftColumn"></div>
                <div className="rightColumn">
                  {/* {params.moduleId === "AddNew" && (
                <Button onClick={onAdd} icon="fa-solid fa-floppy-disk" hint={t("save")} />
              )} */}
                  {params.moduleId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={onUpdate}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </Permission>
            </div>
          </div>
          <div className={"dx-card "}>
            <TesTabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onTabChange={handleTabChange}
            />

            {selectedIndex === 0 && (
              <Results
                initData={initData}
                setInitData={setInitData}
                isLocked={isLocked}
                initDataTesFields={initDataTesFields}
                initDataSiteSubTypes={initDataSiteSubTypes}
                //   setDataChanged={setDataChanged}
              />
            )}
            {selectedIndex === 1 && (
              <Errors initData={initData} isLocked={isLocked} />
            )}
            {selectedIndex === 2 && (
              <Diagnosis
                //   initData={initData}
                //   setInitData={setInitData}
                isLocked={isLocked}
                //   setDataChanged={setDataChanged}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default NetworkScreeningEB;
