//imports
import { ContextMenu, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GridFilter from "../../../../../components/gridFilter/gridFilter";
import Permission from "../../../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { useInfrastructure } from "../../../../../contexts/infrastructure";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
} from "../../../../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../../../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../types/field/fieldType";
import {
  GridType,
  ResponseCode,
  TesMapModule,
} from "../../../../../types/general/enums/generalEnums";
import {
  GridSetting,
  INameId,
  RequestResponseResult,
} from "../../../../../types/general/generalTypes";
import {
  MisPositionType,
  MisXRoadSegmentDetailsDTO,
} from "../../../../../types/infrastructure/dto/locationdDto";
import { TrafficSectionVolumeInheritType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { InfrastructureLinearSystemType } from "../../../../../types/infrastructure/infrastructureTypes";
import { FacilityType } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../../../utils/dateTimeTools";
import { Enum2Array, EnumFlag2Array } from "../../../../../utils/enumTools";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../../utils/rest";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";

interface IPros {
  data: MisXRoadSegmentDetailsDTO[];
  validationRef: React.RefObject<ValidationGroupRef>;
}

const RoadSegment = (props: IPros) => {
  const { infrastructureData } = useInfrastructure();
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const gridSetting =
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { customerDivisions } = useClientSetting();
  const lstVolumeInheritType = Enum2Array(TrafficSectionVolumeInheritType);
  const lstFacilityType = Enum2Array(FacilityType);
  const lstPositionType = Enum2Array(MisPositionType);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitDataSiteSubTypes();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.InfrastructureMidBlockGeneral,
        FieldCategoryType.InfrastructureMidBlockDetails,
        FieldCategoryType.InfrastructureMidBlockCrossSection,
        FieldCategoryType.InfrastructureMidBlockOperation,
        FieldCategoryType.InfrastructureMidBlockRoot,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/infrastructure/roadSegmentDetails/${e.data._id}`,
          "_blank"
        );
      } else {
        history(`/infrastructure/roadSegmentDetails/${e.data._id}`);
      }
    }
  }

  const LHRSLinearSystemColumns = [
    { dataField: "EndLHRS", caption: t("endLHRS") },
    { dataField: "EndOffset", caption: t("endOffset") },
    { dataField: "LHRS", caption: t("lhrs") },
    { dataField: "NextEndOffset", caption: t("nextEndOffset") },
    { dataField: "NextLHRS", caption: t("nextLHRS") },
    { dataField: "NextStartOffset", caption: t("nextStartOffset") },
    { dataField: "PriorEndOffset", caption: t("priorEndOffset") },
    { dataField: "PriorLHRS", caption: t("priorLHRS") },
    { dataField: "PriorStartOffset", caption: t("priorStartOffset") },
    {
      dataField: "StartOffset",
      caption: t("StartOffset"),
      format: { type: "fixedPoint", precision: 2 },
    },
  ];
  const shouldRenderLHRSColumns =
    infrastructureData?.linearSystemType ===
    InfrastructureLinearSystemType.LHRS;

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_D_Site,
        InfrastructurePermissions.Infrastructure_R_Site,
        InfrastructurePermissions.Infrastructure_V_Site,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data._id,
                "/infrastructure/roadSegmentDetails/"
              )
            }
          />
          <ValidationGroup ref={props.validationRef}>
            <div className={"content-block"}>
              <div className={"dx-card responsive-paddings"}>
                {initDataFields && initDataFields.length > 0 && (
                  <DataGrid
                    id="gridContainer"
                    key={"_id"}
                    ref={dataGridRef}
                    dataSource={props.data}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onRowClick={onRowClicked}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    style={{ margin: "0 1rem" }}
                    onExporting={OnExporting}
                    onContextMenuPreparing={(e) => {
                      setSelectedRowData(e);
                    }}
                    columnAutoWidth={gridSetting.horizontalScrolling}
                    //onContentReady={e => {e.component.deselectAll()}}
                    //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                  >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                    <GroupPanel visible={true} /> {/* or "auto" */}
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup position={"top"} />
                    <StateStoring
                      enabled={true}
                      type="localStorage"
                      storageKey={GridType.RoadSegment.toString()}
                      savingTimeout={500}
                    />
                    <SortByGroupSummaryInfo
                      summaryItem="Total Count"
                      sortOrder="desc"
                    />
                    <Summary>
                      <GroupItem
                        summaryType="count"
                        alignByColumn
                        name="Total Count"
                      />
                    </Summary>
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[100, 200, 300, 400, 500]}
                      showNavigationButtons={true}
                      showInfo={true}
                      visible={true}
                    />
                    <Selection
                      mode="multiple"
                      selectAllMode={"allPages"}
                      showCheckBoxesMode={"always"}
                      deferred={true}
                      // @ts-ignore
                      maxFilterLengthInRequest={10000}
                    />
                    <FilterRow visible={true} applyFilter="auto" />
                    <HeaderFilter visible={true} />
                    {/* <SearchPanel visible={true} width={285} placeholder="Search..." /> */}
                    <ColumnChooser
                      width={350}
                      height={400}
                      enabled={true}
                      mode="select"
                      sortOrder="asc"
                    >
                      <Search enabled />
                    </ColumnChooser>
                    <Column
                      alignment="left"
                      dataField="id"
                      allowSorting={true}
                      caption={t("id")}
                      visible={false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      alignment="left"
                      dataField="GeoId"
                      allowSorting={true}
                      caption={t("geoId")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="Order"
                      allowSorting={true}
                      caption={t("order")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField={"MisPositionType "}
                      caption={t("positionType")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          lstPositionType,
                          "name"
                        )}
                        valueExpr="value"
                        displayExpr="name"
                      />
                    </Column>
                    <Column
                      dataField="StartOffset"
                      allowSorting={true}
                      caption={t("startOffset")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="EndOffset"
                      allowSorting={true}
                      caption={t("endOffset")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="Inactive"
                      allowSorting={true}
                      caption={t("inactive")}
                      visible={false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="Latitude"
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={t("latitude")}
                      format={{ type: "fixedPoint", precision: 4 }}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="Longitude"
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={t("longitude")}
                      format={{ type: "fixedPoint", precision: 4 }}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="SiteSubTypeId"
                      allowSorting={true}
                      visible={false}
                      caption={t("siteSubType")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          initDataSiteSubTypes,
                          "name"
                        )}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                    <Column
                      dataField="IsReviewNeeded"
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={t("isReviewNeeded")}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="OverwriteAutoGenerateDescription"
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={t("overwriteAutoGenerateDescription")}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="ReviewNote"
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={t("reviewNote")}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="TesId"
                      allowSorting={true}
                      allowHeaderFiltering={false}
                      caption={t("TesId")}
                      visible={false}
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="VolumeInheritType"
                      allowHeaderFiltering={true}
                      caption={t("volumeInheritType")}
                      visible={false}
                    >
                      <Search enabled />
                      <Lookup
                        dataSource={SortObjectByPropName(
                          lstVolumeInheritType,
                          "name"
                        )}
                        valueExpr="value"
                        displayExpr="name"
                      />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="LastCountDt"
                      width={170}
                      caption={t("lastCountDt")}
                      dataType="datetime"
                      format={generalSetting?.dateTimeFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField={"FacilityType"}
                      caption={t("facilityType")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          lstFacilityType,
                          "name"
                        )}
                        valueExpr="value"
                        displayExpr="name"
                      />
                    </Column>
                    {shouldRenderLHRSColumns &&
                      LHRSLinearSystemColumns.map((col) => (
                        <Column
                          key={col.dataField}
                          dataField={col.dataField}
                          allowSorting={true}
                          allowHeaderFiltering={false}
                          caption={col.caption}
                          format={col.format}
                          visible={false}
                        >
                          <Search enabled />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      ))}
                    {initDataFields.map((d) => {
                      if (d.fieldType === FieldType.List) {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            allowFiltering={true}
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <Search enabled />
                            <Lookup
                              dataSource={SortObjectByPropName(
                                d.fieldValues,
                                "name"
                              )}
                              valueExpr="id"
                              displayExpr="name"
                            />
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      } else if (d.fieldType === FieldType.Number) {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            dataType="number"
                            alignment="left"
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      } else if (d.fieldType === FieldType.Date) {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            dataType="date"
                            calculateCellValue={(e: any) =>
                              GridActualDateCalculator(e, d, generalSetting)
                            }
                            format={generalSetting?.dateFormat}
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      } else if (d.fieldType === FieldType.Time) {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            dataType="datetime"
                            calculateCellValue={(e: any) =>
                              GridActualDateCalculator(e, d, generalSetting)
                            }
                            format={generalSetting?.timeFormat}
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      } else if (d.fieldType === FieldType.DateTime) {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            dataType="datetime"
                            calculateCellValue={(e: any) =>
                              GridActualDateCalculator(e, d, generalSetting)
                            }
                            format={generalSetting?.dateTimeFormat}
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      } else if (d.fieldType === FieldType.Boolean) {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            dataType="boolean"
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      } else {
                        return (
                          <Column
                            key={d.name}
                            dataField={d.name}
                            caption={d.labelText}
                            allowSorting={true}
                            visible={
                              EnumFlag2Array(
                                FieldLocation2Show,
                                d.fieldLocation2Show
                              ).some((x) => x === FieldLocation2Show.GridView)
                                ? true
                                : false
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        );
                      }
                    })}
                    <Column
                      dataField="DivisionId"
                      allowSorting={true}
                      visible={false}
                      caption={t("division")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          customerDivisions!,
                          "name"
                        )}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  </DataGrid>
                )}
              </div>
            </div>

            <GridFilter
              dataGridRef={dataGridRef}
              setShowModal={setShowFilter}
              showModal={showFilter}
              tesModule={TesMapModule.Collision}
            />
          </ValidationGroup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default RoadSegment;
