import i18n from "../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "general",
    icon: "fa-solid fa-circle-info",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "xmlTags",
    icon: "fa-solid fa-tag",
    content: "Location tab content",
  },
  {
    id: 2,
    text: "pdfRedactions",
    icon: "fa-solid fa-file-pdf",
    content: "Permissions tab content",
  },
  {
    id: 3,
    text: "pictureExtractions",
    icon: "fa-regular fa-images",
    content: "Permissions tab content",
  },
];
export default tabTitles;
