import { TabTitle } from "../../../../types/general/generalTypes";
export default function TabsDataUpdate(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 0,
        text: "Summary",
        icon: "fa-solid fa-list-ul",
        content: "General tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 1,
        text: "General",
        icon: "fa-solid fa-circle-info",
        content: "General tab content",
      },
      {
        id: 2,
        text: "Location",
        icon: "fa-solid fa-map",
        content: "General tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 3,
        text: "roadSegments",
        icon: "fa-solid fa-road",
        content: "RoadSegments tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 4,
        text: "Collisions",
        icon: "fa-solid fa-car-burst",
        content: "Collisions tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 5,
        text: "Traffic Studies",
        icon: "fa-solid fa-traffic-light",
        content: "Traffic Studies tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 6,
        text: "AADT",
        icon: "fa-solid fa-calendar",
        content: "AADT tab content",
        disabled: id === "AddNew" ? true : false,
      },
    ])
  );
}

export function TabsDataAdd(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 1,
        text: "General",
        icon: "fa-solid fa-circle-info",
        content: "General tab content",
      },
    ])
  );
}
