//#region imports
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import ValidationGroup, {
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CustomerCollision } from "../../../../../types/collision/collisionTypes";
import { VMCollisionGroupedField } from "../../../../../types/field/dto/fieldDTO";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
//#endregion imports
//#region props
interface IPros {
  initDataFields: VMCollisionGroupedField;
  initDataCollision: CustomerCollision;
  generalSettings: GeneralSetting | null;
  validationRef: React.RefObject<ValidationGroupRef>;
  isLocked: boolean;
}
//#endregion props
const ChangeLogs = (props: IPros) => {
  //#region consts
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#endregion consts
  //#region functions
  function findFieldValueName(fromValue: string, fields: any[]) {
    const s: string[] = fromValue.split(",");
    var res: any[] = [];
    const result = fields
      .filter((x) => x.fieldValues != null)
      .flatMap((a) => a.fieldValues);
    s.forEach((val) => {
      const v = result.find((x) => x.id === val);
      if (v != null) {
        res.push(v.name);
      } else {
        res.push(val);
      }
    });
    return res;
  }
  function calFromValue(c: any) {
    if (c.fromValue === null || c.fromValue === undefined) {
      return "";
    }
    if (c.fieldName.includes("New")) {
      return "";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue != null &&
      c.fromValue == null
    ) {
      return "";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue == null &&
      c.fromValue != null
    ) {
      return "";
    }

    if (c.fieldName === "GeoId") {
      return c.fromValue;
    }
    if (c.fieldName === "LocationDescription") {
      return c.fromValue;
    }
    if (c.fieldName === "LocationId") {
      return c.fromValue;
    }

    if (c.fieldName === "YCoord") {
      return c.fromValue;
    }
    if (c.fieldName === "XCoord") {
      return c.fromValue;
    }
    if (c.fieldName === "Longitude") {
      return c.fromValue;
    }
    if (c.fieldName === "Latitude") {
      return c.fromValue;
    }

    if (c.fieldName === "Road.Deleted") {
      return findFieldValueName(c.fromValue, props.initDataFields.roadFields);
    }

    if (c.fieldName === "Vehicles.Deleted") {
      return findFieldValueName(
        c.fromValue,
        props.initDataFields.vehicleFields
      );
    }
    if (c.fieldName === "Driver.Deleted") {
      return findFieldValueName(c.fromValue, [
        ...props.initDataFields.driverFields,
        ...props.initDataFields.personFields,
      ]);
    }
    if (c.fieldName === "Person.Deleted") {
      return findFieldValueName(c.fromValue, props.initDataFields.personFields);
    }
    if (c.fieldName === "Pedestrian.Deleted") {
      return findFieldValueName(c.fromValue, [
        ...props.initDataFields.pedestrianFields,
        ...props.initDataFields.personFields,
      ]);
    }
    if (c.fieldName === "Passenger.Deleted") {
      return findFieldValueName(c.fromValue, [
        ...props.initDataFields.passengerFields,
        ...props.initDataFields.personFields,
      ]);
    }

    const splitName = c.fieldName.split(".");
    if (splitName[0] === "General" && c.fromValue != null) {
      const v = props.initDataFields.generalFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }
    if (splitName[0] === "Vehicle" && c.fromValue != null) {
      const v = props.initDataFields.vehicleFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }
    if (splitName[0] === "Person" && c.fromValue != null) {
      const v = props.initDataFields.personFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }

    if (splitName[0] === "Road" && c.fromValue != null) {
      const v = props.initDataFields.roadFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }

    if (splitName[0] === "Driver" && c.fromValue != null) {
      const v = [
        ...props.initDataFields.driverFields,
        ...props.initDataFields.personFields,
      ].find((x) => x.name === splitName[1]);
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }

    if (splitName[0] === "Pedestrian" && c.fromValue != null) {
      const v = [
        ...props.initDataFields.pedestrianFields,
        ...props.initDataFields.personFields,
      ].find((x) => x.name === splitName[1]);
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }

    if (splitName[0] === "Passenger" && c.fromValue != null) {
      const v = [
        ...props.initDataFields.passengerFields,
        ...props.initDataFields.personFields,
      ].find((x) => x.name === splitName[1]);
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.fromValue);
        if (d) {
          return d.name;
        } else {
          return c.fromValue;
        }
      } else {
        return c.fromValue;
      }
    }
  }
  function calToValue(c: any) {
    if (c.toValue === null || c.toValue === undefined) {
      return "";
    }
    if (c.fieldName.includes("New")) {
      return "";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue != null &&
      c.fromValue == null
    ) {
      return "Add Attachment";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue == null &&
      c.fromValue != null
    ) {
      return "Remove Attachment";
    }

    if (c.fieldName === "GeoId") {
      return c.toValue;
    }
    if (c.fieldName === "LocationDescription") {
      return c.toValue;
    }
    if (c.fieldName === "LocationId") {
      return c.toValue;
    }

    if (c.fieldName === "YCoord") {
      return c.toValue;
    }
    if (c.fieldName === "XCoord") {
      return c.toValue;
    }
    if (c.fieldName === "Longitude") {
      return c.toValue;
    }
    if (c.fieldName === "Latitude") {
      return c.toValue;
    }

    const splitName = c.fieldName.split(".");
    if (splitName[0] === "General" && c.toValue != null) {
      const v = props.initDataFields.generalFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }
    if (splitName[0] === "Vehicle" && c.toValue != null) {
      const v = props.initDataFields.vehicleFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }
    if (splitName[0] === "Person" && c.toValue != null) {
      const v = props.initDataFields.personFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }
    if (splitName[0] === "Road" && c.toValue != null) {
      const v = props.initDataFields.roadFields.find(
        (x) => x.name === splitName[1]
      );
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }

    if (splitName[0] === "Driver" && c.toValue != null) {
      const v = [
        ...props.initDataFields.driverFields,
        ...props.initDataFields.personFields,
      ].find((x) => x.name === splitName[1]);
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }

    if (splitName[0] === "Pedestrian" && c.toValue != null) {
      const v = [
        ...props.initDataFields.pedestrianFields,
        ...props.initDataFields.personFields,
      ].find((x) => x.name === splitName[1]);
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }

    if (splitName[0] === "Passenger" && c.toValue != null) {
      const v = [
        ...props.initDataFields.passengerFields,
        ...props.initDataFields.personFields,
      ].find((x) => x.name === splitName[1]);
      if (v != null && v.fieldValues) {
        const d = v.fieldValues.find((x) => x.id === c.toValue);
        if (d) {
          return d.name;
        } else {
          return c.toValue;
        }
      } else {
        return c.toValue;
      }
    }
  }

  //#endregion functions
  return (
    <div
      className={`collisionDetails-changeLogs ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <div className="row" style={{ marginTop: 20, padding: 10 }}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initDataCollision.changeLogs}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={false}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />

              <Export enabled={true} allowExportSelectedData={true} />

              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>

              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField={"fieldName"} caption={t("fieldName")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                dataField={"changeDT"}
                caption={t("dateTime")}
                dataType="datetime"
                format={props.generalSettings?.dateTimeFormat}
                sortOrder="desc"
                sortIndex={0}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                dataField={"fromValue"}
                caption={t("fromValue")}
                calculateDisplayValue={(c: any) => calFromValue(c)}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                dataField={"toValue"}
                caption={t("toValue")}
                calculateDisplayValue={(c: any) => calToValue(c)}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField={"username"} caption={t("username")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </ValidationGroup>
      </React.Fragment>
    </div>
  );
};
export default ChangeLogs;
