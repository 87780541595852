//#region imports
import { ContextMenu } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../../components/gisMap/generalGisMapManager";
import GridFilter from "../../../../components/gridFilter/gridFilter";
import GridProfilePicker from "../../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../../components/permission/permision";
import RecalculateStudy from "../../../../components/recalculateStudy/recalculateStudy";
import StatusChanger from "../../../../components/statusChanger/statusChanger";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import {
  FieldApiUrl,
  TrafficApiUrl,
} from "../../../../environment/routeSettings";
import {
  GridType,
  ResponseCode,
  TesMapModule,
} from "../../../../types/general/enums/generalEnums";
import {
  GridSetting,
  LazyLoadingRequest,
  LazyLoadingRequestBase,
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  MapUpdateStatus,
  SectionDetailType,
} from "../../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import { SimpleTrafficCounterDTO } from "../../../../types/trafficStudy/dtos/TrafficCounterDTO";
import {
  StudyStatus,
  StudyType,
} from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import "./allStudies.scss";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";
//#endregion imports

const dataSource = new CustomStore({
  load: async (loadOption) => {
    return await TesPost(
      `${TrafficApiUrl()}/api/Study/GetCustomerStudyByTypeLazyLoading`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        filter: { type: StudyType.Volume },
      } as LazyLoadingRequest,
      true
    );
  },
});

const Volume = () => {
  //#region consts
  const [showMap, setShowMap] = useState(false);
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const [lstMapUpdateStatus, setLstMapUpdateStatus] = useState<NameValue[]>([]);

  const [lstTrafficCounter, setLstTrafficCounter] =
    useState<SimpleTrafficCounterDTO[]>();
  const [lstSectionType, setLstSectionType] = useState<NameValue[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting()
  );
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { generalSetting, customerDivisions } = useClientSetting();
  //#endregion consts

  //#region functions
  useBrowserTitle({ pathname: "/trafficStudy/studies/volume" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInfrastructureTesCodeValues();
        await getInitialDataReports();
        await getLstTrafficCounter();
        setLstStudyType(Enum2Array(StudyType));
        setLstStudyStatus(Enum2Array(StudyStatus));
        setLstSectionType(Enum2Array(SectionDetailType));
        setLstMapUpdateStatus(Enum2Array(MapUpdateStatus));

        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToStudyDetails = useCallback(
    (d: any) => {
      history(`/trafficStudy/studies/studyDetails/1/AddNew`);
    },
    [history]
  );

  async function getInitialDataReports() {
    try {
      //   setInitDataReports(await TesGet(ReportApiUrl() + "/api/ClientReports/" + localStorage.getItem('selectedCustomerId'), true));
    } catch (ex) {}
  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getLstTrafficCounter() {
    const postObj: LazyLoadingRequestBase = {
      customerId: localStorage.getItem("selectedCustomerId") as string,
      divisionId: null,
      filter: { returnAll: true },
    };
    const res = (await TesPost(
      TrafficApiUrl() + "/api/Import/GetCustomerSimplifiedTrafficCounter",
      postObj,
      true
    )) as RequestResponseResult<SimpleTrafficCounterDTO[]>;
    if (res.responseCode === ResponseCode.OK) {
      setLstTrafficCounter(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.id}`,
          "_blank"
        );
      } else {
        history(
          `/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.id}`
        );
      }
    }
  }

  const headerFilterData = [
    {
      text: t("volume"),
      value: ["StudyCategory", "=", 1],
    },
    {
      text: t("volume") + "," + t("classification"),
      value: ["StudyCategory", "=", 5],
    },
    {
      text: t("volume") + "," + t("continuousCount"),
      value: ["StudyCategory", "=", 9],
    },
    {
      text:
        t("volume") + "," + t("classification") + "," + t("continuousCount"),
      value: ["StudyCategory", "=", 13],
    },
  ];

  const headerLookup = [
    {
      name: t("volume"),
      value: 1,
    },
    {
      name: t("volume") + "," + t("classification"),
      value: 5,
    },
    {
      name: t("volume") + "," + t("continuousCount"),
      value: 9,
    },
    {
      name:
        t("volume") + "," + t("classification") + "," + t("continuousCount"),
      value: 13,
    },
  ];

  //#endregion functions
  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.key.id,
                `/trafficStudy/studies/studyDetails/${selectedRowData.row.data.studyType}/`,
                selectedRowData.row.data.aadt.locationType,
                selectedRowData.row.data.locationId
              )
            }
          />
          <h2 className={"content-block"}>{t("volume")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div
                  className="leftColumn"
                  style={{ display: "flex", gap: "6px" }}
                >
                  <Button
                    onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                  />
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.VolumeStudies}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />
                  <Button
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />
                  <StatusChanger datagridRef={dataGridRef} />
                  <RecalculateStudy datagridRef={dataGridRef} />
                </div>
                <Permission
                  allowed={[
                    TrafficStudyPermissions.TrafficStudy_D_Study,
                    TrafficStudyPermissions.TrafficStudy_E_Study,
                  ]}
                  hasFeedBackElement={false}
                >
                  <div className="rightColumn">
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={navigateToStudyDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("volume")}
                    />
                  </div>
                </Permission>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                columnAutoWidth={gridSetting.horizontalScrolling}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.VolumeStudies.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  visible={false}
                  caption={t("id")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="parentId"
                  visible={false}
                  caption={t("parentId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="customerId"
                  visible={false}
                  caption={t("customerId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="workOrder.no"
                  caption={t("workOrderNo")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="studyNo"
                  caption={t("studyNo")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="locationDescription"
                  caption={t("locationDescription")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="sectionType"
                  caption={t("sectionType")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstSectionType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="municipalityId"
                  visible={
                    initDataTesCodeValues.municipalities?.length !== 0 &&
                    initDataTesCodeValues.municipalities !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("municipality")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataTesCodeValues?.municipalities,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={initDataTesCodeValues?.municipalities?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="MapUpdateStatus"
                  caption={t("mapUpdateStatus")}
                  visible={false}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstMapUpdateStatus,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="jurisdictionId"
                  visible={
                    initDataTesCodeValues.jurisdictions?.length !== 0 &&
                    initDataTesCodeValues.jurisdictions !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("jurisdiction")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataTesCodeValues?.jurisdictions,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={initDataTesCodeValues?.jurisdictions?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="studyType" caption={t("studyType")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstStudyType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="studyCategory">
                  <HeaderFilter dataSource={headerFilterData} width={350}>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={headerLookup}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="status" caption={t("status")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstStudyStatus, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  alignment="left"
                  dataField="duration"
                  caption={t("duration")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="rawDataStartDT"
                  caption={t("startDT")}
                  dataType="datetime"
                  format={generalSetting?.dateTimeFormat}
                  sortOrder="desc"
                  sortIndex={0}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="rawDataEndDT"
                  caption={t("endDT")}
                  dataType="datetime"
                  format={generalSetting?.dateTimeFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column visible={false} caption={t("trafficCounter")}>
                  <Column
                    dataField="trafficCounterId"
                    visible={false}
                    allowHeaderFiltering={true}
                    caption={t("trafficCounter")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstTrafficCounter,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    dataField="binMapId"
                    visible={false}
                    allowHeaderFiltering={true}
                    caption={t("binMaps")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstTrafficCounter?.flatMap((x) => x.binMaps),
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </Column>
                <Column caption={t("aadt")} visible={true}>
                  <Column
                    dataField="aadt.totalVolume"
                    caption={t("totalVolumeAadt")}
                    visible={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="aadt.percentSummary"
                    caption={t("directionSplit")}
                    visible={false}
                    format="#.00"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="aadt.northPercent"
                    caption={t("northPercent")}
                    visible={false}
                    format="#.00"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="aadt.eastPercent"
                    caption={t("eastPercent")}
                    visible={false}
                    format="#.00"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="aadt.westPercent"
                    caption={t("westPercent")}
                    visible={false}
                    format="#.00"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="aadt.southPercent"
                    caption={t("southPercent")}
                    visible={false}
                    format="#.00"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </Column>
                <Column caption={t("volumeSummary")} visible={false}>
                  <Column
                    dataField="volumeSummary.northApproachVolume"
                    caption={t("northApproachVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.eastApproachVolume"
                    caption={t("eastApproachVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.westApproachVolume"
                    caption={t("westApproachVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.southApproachVolume"
                    caption={t("southApproachVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.totalVolume"
                    caption={t("totalVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.pedestrianVolume"
                    caption={t("pedestrianVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.carVolume"
                    caption={t("carVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    dataField="volumeSummary.truckVolume"
                    caption={t("truckVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.heavyTruckVolume"
                    caption={t("heavyTruckVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="volumeSummary.cyclistVolume"
                    caption={t("cyclistVolume")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </Column>
                <Column
                  dataField="processDT"
                  visible={false}
                  caption={t("submissionDT")}
                  dataType="datetime"
                  format={generalSetting?.dateTimeFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="createdDT"
                  visible={false}
                  caption={t("createdDT")}
                  dataType="datetime"
                  format={generalSetting?.dateTimeFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="lastModifiedDT"
                  visible={false}
                  caption={t("lastModifiedDT")}
                  dataType="datetime"
                  format={generalSetting?.dateTimeFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="divisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      customerDivisions!,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.Study}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="locationId"
            />
          </Popup>

          {/*Grid Filter*/}
          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Study}
          />
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Volume;
