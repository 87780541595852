import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "pages",
    icon: "fa-regular fa-clone",
    content: "Details tab content",
  },
  {
    id: 2,
    text: "attachments",
    icon: "fa-solid fa-paperclip",
    content: "Attachments tab content",
  },
];
export default tabTitles;
