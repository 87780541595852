import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import { TMSPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
} from "../../../../environment/routeSettings";
import { FieldServiceType } from "../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../types/field/fieldType";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import {
  FieldDataSource,
  IFiledDataSourceParams,
} from "../../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import ColumnMap from "./components/columnMap/columnMap";
import Details from "./components/details/details";
import tabTitles from "./data/index";
import "./fieldDataSourceDetails.scss";
import TesTabs from "../../../../components/tesTab/tesTab";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const FieldDataSourceDetails = () => {
  const [initDataFieldDataSource, setInitDataFieldDataSource] =
    useState<FieldDataSource>(new FieldDataSource());
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fieldIdData, setFieldIdData] = useState<TesField[]>([]);
  const [lockField, setLockField] = useState<boolean>(false);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<IFiledDataSourceParams>();
  const [isLocked, setIsLocked] = useState(true);
  const validationRef = useRef<ValidationGroupRef>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);

  useBrowserTitle({
    pathname: "/infrastructure/updateImport/fieldDataSourceDetails",
    additionalText: `${initDataFieldDataSource?.dataSourceName}`,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          if (params.fieldDataSourceId !== "AddNew") {
            await getInitData(params.fieldDataSourceId!);
            setLockField(true);
          } else {
            setIsLocked(false);
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();

    async function getFieldIdData() {
      setFieldIdData(
        await TesGet(
          FieldApiUrl() +
            "/api/TesFields/GetAllFields/" +
            localStorage.getItem("selectedCustomerId") +
            "/" +
            FieldServiceType.Infrastructure,
          true
        )
      );
    }
    getFieldIdData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        InfrastructureApiUrl() +
          "/api/FieldDataSource/GetFieldDataSourceDetails/" +
          id,
        true
      )) as RequestResponseResult<FieldDataSource>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitDataFieldDataSource(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.fieldDataSourceId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false);
                  history(-1);
                } else {
                  onAdd();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //add and update functions
  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        InfrastructureApiUrl() +
          "/api/FieldDataSource/AddUpdateFieldDataSources",
        initDataFieldDataSource,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  async function onAdd() {
    try {
      const postObj: FieldDataSource = {
        ...initDataFieldDataSource,
        customerId: localStorage.getItem("selectedCustomerId")!,
      };
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        InfrastructureApiUrl() +
          "/api/FieldDataSource/AddUpdateFieldDataSources",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
        return;
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div
        className={`fieldDataSourceDetails ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p
                    ref={headingParagraphRef}
                    className={"detailsHeading"}
                    style={{ display: "inline" }}
                  >
                    <span>
                      {t("fieldDataSource")}:{" "}
                      {initDataFieldDataSource?.dataSourceName}
                    </span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={goBackPermission}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn">
                  <Button
                    onClick={() => LockHandler()}
                    icon={
                      isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                    }
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">
                  {params.fieldDataSourceId === "AddNew" && (
                    <Button
                      onClick={onAdd}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  )}
                  {params.fieldDataSourceId !== "AddNew" &&
                    isLocked === false && (
                      <Button
                        onClick={onUpdate}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />

              {selectedIndex === 0 && (
                <Details
                  initDataFieldDataSource={initDataFieldDataSource}
                  setInitDataFieldDataSource={setInitDataFieldDataSource}
                  isLocked={isLocked}
                  lockField={lockField}
                  setDataChanged={setDataChanged}
                  validationRef={validationRef}
                />
              )}

              {selectedIndex === 1 && (
                <ColumnMap
                  initDataFieldDataSource={initDataFieldDataSource}
                  setInitDataFieldDataSource={setInitDataFieldDataSource}
                  isLocked={isLocked}
                  setDataChanged={setDataChanged}
                  fieldIdData={fieldIdData}
                  validationRef={validationRef}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default FieldDataSourceDetails;
