const TMSPermissions = {
  TMS_D_Customer: "TMS_D_Customer",
  TMS_Admin: "TMS_Admin",
  TMS_D_VersioningApplications: "TMS_D_VersioningApplications",
  TMS_D_Classification: "TMS_D_Classification",
  TMS_D_AppVersion: "TMS_D_AppVersion",
  TMS_Developer: "TMS_Developer",
  TMS_D_Translation: "TMS_D_Translation",
  TMS_Management: "TMS_Management",
};
export default TMSPermissions;
