import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import Details from "./components/details/details";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { useNavigate, useParams } from "react-router-dom";

import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import "./moduleDetails.scss";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  ClientData,
  IDataParams,
} from "../../../types/moduleOfModules/moduleOfModulesTypes";
import {
  DataDetailsDTO,
  ModuleTabDTO,
} from "../../../types/moduleOfModules/dtos/moduleOfModuleDto";
import { TabType } from "../../../types/moduleOfModules/enums/moduleOfModulesEnums";
import { v4 as uuidv4 } from "uuid";
import { ModuleOfModulePermissions } from "../../../constants/Permissions";
import Permission from "../../../components/permission/permision";
import { ModuleOfModuleApiUrl } from "../../../environment/routeSettings";
import TesTabs from "../../../components/tesTab/tesTab";

const DataDetails = () => {
  const [initData, setInitData] = useState<DataDetailsDTO>(
    new DataDetailsDTO()
  );
  const [initDataTab, setInitDataTab] = useState<ModuleTabDTO[]>([]);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const params = useParams<IDataParams>();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          await getInitData(params.moduleId!, params.dataId!);
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitData(moduleId: string, dataId: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        ModuleOfModuleApiUrl() +
          "/api/ClientModule/GetModuleTabs/" +
          moduleId +
          "/" +
          dataId,
        true
      )) as RequestResponseResult<DataDetailsDTO>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
        setInitDataTab(res.results.tabs!);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.moduleId !== "AddNew") {
                  onUpdate();
                } else {
                  onAdd();
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              //setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }
  //add and update functions
  async function onUpdate() {
    try {
      const postObj: ClientData = {
        detailsData: initData.detailsData,
        moduleId: params.moduleId!,
        submissionDT: new Date(),
        id: params.dataId!,
        listData: [],
      };
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        ModuleOfModuleApiUrl() + "/api/ClientModule/UpdateData",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onAdd() {
    try {
      const postObj: ClientData = {
        detailsData: initData.detailsData,
        moduleId: params.moduleId!,
        submissionDT: new Date(),
        id: uuidv4(),
        listData: [],
      };
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        ModuleOfModuleApiUrl() + "/api/ClientModule/AddData",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        return;
      } else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[
        ModuleOfModulePermissions.ModuleOfModule_D_Data,
        ModuleOfModulePermissions.ModuleOfModule_E_Data,
        ModuleOfModulePermissions.ModuleOfModule_V_Data,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={goBackPermission}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.dataId === "AddNew" && (
                  <Button
                    onClick={onAdd}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("save")}
                  />
                )}
                {params.dataId !== "AddNew" && (
                  <Button
                    onClick={onUpdate}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("update")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={"dx-card "}>
            <Tabs
              width={"100%"}
              dataSource={
                initData.tabs
                  ?.map((x) => {
                    // Ensure all required properties are defined
                    if (x.order !== undefined && x.name !== undefined) {
                      return {
                        id: x.order,
                        text: x.name,
                        icon: "fa-solid fa-info",
                        content: x.name,
                      };
                    }
                    return null; // Exclude invalid items
                  })
                  .filter(
                    (item): item is NonNullable<typeof item> => item !== null
                  ) ?? [] // Type guard to exclude null values // Fallback to an empty array if initData.tabs is undefined
              }
              selectedIndex={selectedIndex}
              onOptionChanged={(args) => {
                if (args.name === "selectedIndex") {
                  handleTabChange(args.value); // Pass the updated index to your handler
                }
              }}
            />
            {/* eslint-disable-next-line array-callback-return */}
            {initDataTab
              ?.filter((x) => x.type === TabType.Detail)
              .map((x) => {
                if (selectedIndex === x.order) {
                  return (
                    <Details
                      order={x.order}
                      generalSettings={generalSetting}
                      initData={initData}
                      setInitData={setInitData}
                    />
                  );
                }
              })}
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default DataDetails;
