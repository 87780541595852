import { RequestResponseResult } from "../../types/general/generalTypes";
import { RequestErrorHandling, TesGet } from "../../utils/rest";
import { FieldApiUrl } from "../../environment/routeSettings";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import { VMField } from "../../types/field/dto/fieldDTO";
import { TFieldsData } from "./index";

export const storeFieldsDataKeyName = "TESInfrastructureData";

export const getFieldsData = async () => {
  const customerId = localStorage.getItem("selectedCustomerId");
  if (!customerId) return undefined;
  try {
    const res: RequestResponseResult<VMField[]> = await TesGet(
      `${FieldApiUrl()}/api/TesFields/GetCustomerFieldsByPermission/${customerId}`,
      true,
      false
    );
    if (res.responseCode === ResponseCode.OK) {
      return res.results;
    } else {
      await RequestErrorHandling(res);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching languages:", error);
    return undefined;
  }
};

export const getStoredFieldsData = (): TFieldsData | undefined => {
  const storedData = localStorage.getItem(storeFieldsDataKeyName);
  if (!storedData) return undefined;
  return JSON.parse(storedData);
};
export const storeFieldsData = (data: TFieldsData) => {
  localStorage.setItem(storeFieldsDataKeyName, JSON.stringify(data));
};
