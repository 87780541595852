import React, { useEffect, useState } from "react";
import "./3.scss";
import { t } from "i18next";
import {
  Justification,
  Justification3,
} from "../../../../../../../types/trafficStudy/dtos/studyDto";

// props
interface IPros {
  justificationName: string;
  signalWarrants: Justification;
}

const TabThree = (props: IPros) => {
  const [selectedJustification, setSelectedJustification] =
    useState<Justification3>();

  useEffect(() => {
    async function fetchMyAPI() {
      var justification3 = props.signalWarrants.justification3.find(
        (x) => x.name === props.justificationName
      );
      setSelectedJustification(justification3);
    }
    fetchMyAPI();
  }, [props.justificationName, props.signalWarrants]);

  return (
    <React.Fragment>
      <h2 className={"content-block"} style={{ margin: "1rem" }}>
        {t("justification3VolumeDelayCombination")}
      </h2>
      {selectedJustification?.note && selectedJustification.note !== "" && (
        <p
          style={{ fontSize: "1rem", padding: "0", margin: "1.5rem 0 0 1rem" }}
        >
          <span style={{ fontWeight: "bolder" }}>{t("note")}: </span>
          {selectedJustification?.note}
        </p>
      )}
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        {/* <h6 className={"content-block"} style={{ margin: "0rem 1rem 1rem 1rem" }}>{t("aAllApproaches")}</h6> */}
        <table className="tableBorder">
          <tbody>
            <tr>
              <td className="tableBorder" colSpan={3}>
                {t("justificationSatisfied80OrMore")}
              </td>
              <td className="tableBorder">
                {t("TwoJustificationsSatisfied80OrMore")}
              </td>
            </tr>
            <tr>
              <td className="tableBorder">{t("justification1")}</td>
              <td className="tableBorder">{t("minimumVehicularVolume")}</td>
              <td className="tableBorder">
                {selectedJustification?.justification180Satisfied
                  ? "Yes"
                  : "No"}
              </td>

              <td className="tableBorder" rowSpan={2}>
                {selectedJustification?.twoJustificationSatisfied
                  ? "Yes"
                  : "No"}
              </td>
            </tr>
            <tr>
              <td className="tableBorder">{t("justification2")}</td>

              <td className="tableBorder">{t("delayToCrossTraffic")}</td>
              <td className="tableBorder">
                {selectedJustification?.justification280Satisfied
                  ? "Yes"
                  : "No"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default TabThree;
