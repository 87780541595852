import i18n from "../../../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "volumeHistory",
    icon: "fa-solid fa-clock-rotate-left",
    content: "Details tab content",
  },
  {
    id: 0,
    text: "matchingCurveStats",
    icon: "fa-solid fa-chart-line",
    content: "Details tab content",
  },
];
export default tabTitles;
