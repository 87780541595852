import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "general",
    icon: "fa-solid fa-circle-info",
    content: "General tab content",
  },
  {
    id: 1,
    text: "location",
    icon: "fa-solid fa-map",
    content: "Location tab content",
  },
  {
    id: 2,
    text: "roads",
    icon: "fa-solid fa-map-location-dot",
    content: "Roads tab content",
  },
  {
    id: 3,
    text: "vehicles",
    icon: "fa-solid fa-car",
    content: " Vehicles tab content",
  },
  {
    id: 4,
    text: "drivers",
    icon: "fa-solid fa-id-card",
    content: "Drivers tab content",
  },
  {
    id: 5,
    text: "passengers",
    icon: "fa-solid fa-user",
    content: "Passengers tab content",
  },
  {
    id: 6,
    text: "pedestrians",
    icon: "fa-solid fa-person-walking",
    content: "Pedestrians tab content",
  },
  {
    id: 7,
    text: "people",
    icon: "fa-solid fa-people-group",
    content: "People tab content",
  },
  {
    id: 8,
    text: "attachments",
    icon: "fa-solid fa-paperclip",
    content: "Attachments tab content",
  },
  {
    id: 9,
    text: "remarks",
    icon: "fa-solid fa-circle-exclamation",
    content: "Remarks tab content",
  },
  {
    id: 10,
    text: "changeLogs",
    icon: "fa-solid fa-list-check",
    content: "Change Logs tab content",
  },
  {
    id: 11,
    text: "validations",
    icon: "fa-solid fa-check-double",
    content: "Validations tab content",
  },
  {
    id: 12,
    text: "mergeLogs",
    icon: "fa-solid fa-list-check",
    content: "Change Logs tab content",
  },
];
export default tabTitles;
