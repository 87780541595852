import React, { useEffect, useState } from "react";
import ScrollView from "devextreme-react/scroll-view";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button, DataGrid, Popup, SelectBox } from "devextreme-react";
import { t } from "i18next";
import {
  NameValue,
  RequestResponseResult,
} from "../../types/general/generalTypes";
import { useAuth } from "../../contexts/auth";
import { Enum2Array } from "../../utils/enumTools";
import { StudyStatus } from "../../types/trafficStudy/enums/trafficStudyEnums";
import notify from "devextreme/ui/notify";
import { TrafficApiUrl } from "../../environment/routeSettings";
import { RequestErrorHandling, TesPost } from "../../utils/rest";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import { ChangeStudyStatusDTO } from "../../types/trafficStudy/dtos/studyDto";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { DataGridRef } from "devextreme-react/cjs/data-grid";

interface IPros {
  datagridRef: React.RefObject<DataGridRef<any, any>>;
}

const StatusChanger = (props: IPros) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
  const [status, setStatus] = useState<StudyStatus>(0);
  const { activeLoading } = useAuth();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstStudyStatus(Enum2Array(StudyStatus));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 2500);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onOk() {
    try {
      const postObj: ChangeStudyStatusDTO = {
        customerId: localStorage.getItem("selectedCustomerId") as string,
        status: status,
        studyIds: props.datagridRef.current
          ?.instance()
          .getSelectedRowKeys()
          .map((x) => x.id) as string[],
      };
      if (activeLoading) activeLoading(true);
      const res: RequestResponseResult<null> = await TesPost(
        TrafficApiUrl() + "/api/Recalculations/ChangeStudyStatus",
        postObj,
        true
      );
      props.datagridRef.current?.instance().refresh();
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        onClose();
        return;
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onClick() {
    if (
      (
        props.datagridRef.current
          ?.instance()
          .getSelectedRowKeys()
          .map((x) => x.id) as string[]
      ).length === 0
    ) {
      notify(t("noSelectedRows"), "warning", 3000);
      return;
    }
    setShowModal(true);
  }

  function onClose() {
    setShowModal(false);
    setStatus(0);
  }

  return (
    <React.Fragment>
      <Button
        onClick={() => onClick()}
        icon={"fa-solid fa-retweet"}
        hint={t("statusChanger")}
      />

      <Popup
        visible={showModal}
        width={"40%"}
        height={"auto%"}
        resizeEnabled={true}
        showTitle={true}
        title={t("changeStatus")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onClose()}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <SelectBox
                placeholder=""
                label={t("status")}
                valueExpr="value"
                displayExpr="name"
                labelMode="floating"
                value={status}
                onValueChange={(e) => setStatus(e)}
                className="modalInput"
                items={SortObjectByPropName(lstStudyStatus, "name")}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
          </Form>
          <div>
            <div className="rightColumn" style={{ marginTop: 10 }}>
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onClose()}
                text={t("Close")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={() => onOk()}
                text={t("apply")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

export default StatusChanger;
