import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { SignPermissions } from "../../../constants/Permissions";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  MaintenanceApiUrl,
  SignApiUrl,
} from "../../../environment/routeSettings";
import { TabTitle } from "../../../types/general/generalTypes";
import { VMLocation } from "../../../types/infrastructure/dto/locationdDto";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { Job } from "../../../types/maintenance/maintenanceTypes";
import {
  VMAppSetups,
  VMCode,
  VMSignUpdate,
} from "../../../types/sign/dto/signDto";
import {
  Attachment,
  ISignParams,
  TesSign,
} from "../../../types/sign/signTypes";
import { Support } from "../../../types/sign/supportTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { TesGet, TesPost, TesPutUploadFile } from "../../../utils/rest";
import Attachments from "./components/attachments/attachments";
import ChangeLogs from "./components/changeLogs/changeLogs";
import Details from "./components/details/details";
import Location from "./components/location/location";
import Maintenance from "./components/maintenance/maintenance";
import TabsData from "./data";
import "./signDetails.scss";
import TesTabs from "../../../components/tesTab/tesTab";
import ReportSelector from "../../../components/reportSelector";

const SignDetails = () => {
  const history = useNavigate();
  const params = useParams<ISignParams>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initDataSetups, setInitDataSetups] = useState<VMAppSetups>(
    new VMAppSetups()
  );
  const [initDataSign, setInitDataSign] = useState<TesSign>(new TesSign());
  const [initDataMaintenance, setInitDataMaintenance] = useState<Job[]>([
    new Job(),
  ]);
  const [existingFiles, setExistingFiles] = useState<Attachment[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [removedFile, setRemovedFile] = useState<string[]>([]);
  const [initDataLocation, setInitDataLocation] = useState<VMLocation>(
    {} as VMLocation
  );
  const [dataChanged, setDataChanged] = useState(false);
  const [backPermission, setBackPermission] = useState(false);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const [titles, setTitles] = useState<TabTitle[]>([]);
  const [signCodeDesc, setSignCodeDesc] = useState<string>("");
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const validationRef = useRef<ValidationGroupRef>(null);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const signRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataSetups();
        setTitles(TabsData(params.signId!));
        if (params.signId !== "AddNew") {
          await getInitDataSign(params.signId!);
        } else {
          setIsLocked(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.signId]);

  useEffect(() => {
    const c = initDataSetups?.signCode?.find(
      (x: VMCode) => x.id === initDataSign?.signCodeId
    );
    if (c !== undefined) {
      setSignCodeDesc(c.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initDataSetups, initDataSign]);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }
  //TesGets
  async function getInitialDataSetups() {
    setInitDataSetups(
      await TesGet(
        SignApiUrl() +
          "/api/sync/GetSetups/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitDataSign(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesGet(
        SignApiUrl() + "/api/TesSigns/GetSignData/" + id,
        true
      );
      //  var sign = new TesSign();
      if (res !== null) {
        //Object.assign(sign, res);
        setInitDataSign(res);

        setExistingFiles(res.attachments);
        await getInitDataMaintenance();
      }

      if (res.support) {
        if (res.support.latitude !== 0) {
          initDataLocation.latitude = res.support.latitude;
          initDataLocation.longitude = res.support.longitude;
        }
      }
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  async function getInitDataMaintenance() {
    setInitDataMaintenance(
      await TesGet(
        MaintenanceApiUrl() + "/api/jobs/AssetJobs/" + params.signId,
        true
      )
    );
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.signId !== "AddNew") {
                  updateSign();
                  setDataChanged(false);
                } else {
                  addSign();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //add and update functions
  async function updateSign() {
    let postObj: VMSignUpdate = {
      ...initDataSign,
      // customerId: localStorage.getItem("selectedCustomerId") as string,
      assets: initDataMaintenance
        .filter((x: Job) => x.id != null)
        .map((x: Job) => x.id),
      deletedAttachments: removedFile,
    };

    try {
      if (activeLoading) activeLoading(true);
      const res = await TesPost(
        SignApiUrl() + "/api/TesSigns/UpdateSign",
        postObj,
        true
      );
      if (res.isChanged) {
        await uploadImages(initDataSign.id, false);
      }
      if (activeLoading) activeLoading(false);

      if (backPermission === true) {
        history(-1);
        return;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
    setDataChanged(false);
  }

  async function addSign() {
    let postObj: VMSignUpdate = {
      ...initDataSign,
      customerId: localStorage.getItem("selectedCustomerId") as string,
      assets: initDataMaintenance
        .filter((x: Job) => x.id != null)
        .map((x: Job) => x.id),
    };
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesPost(
        SignApiUrl() + "/api/TesSigns/AddSign",
        postObj,
        true
      );
      if (res.isChanged) {
        await uploadImages(res.id, true);
      }
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    setDataChanged(false);
  }

  //Attachments

  async function uploadImages(id: string, isNew: boolean) {
    try {
      const data = new FormData();
      // eslint-disable-next-line array-callback-return
      files.map((a: any) => {
        data.append("file", a);
      });
      data.append(id, id);
      await TesPutUploadFile(
        SignApiUrl() + "/api/Attachments/Sign/" + isNew,
        data,
        true
      );

      if (params.signId !== "AddNew") {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        if (backPermission === true) {
          history(-1);
        }
      } else {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
      }
    } catch (ex) {}
  }

  function handleChangeFile(f: any) {
    setFiles([...files, ...f]);
    setDataChanged(true);
  }

  function onDeleteNewFile(name: string) {
    setFiles([...files.filter((x) => x.name !== name)]);
    setDataChanged(true);
  }

  function onDeleteExistingFile(id: string) {
    setInitDataSign({
      ...initDataSign,
      attachments: initDataSign.attachments.filter((x) => x.id !== id),
    });
    setRemovedFile([...removedFile, id]);
    setDataChanged(true);
  }

  //Detail Functions
  const onValueChange = (name: string, value: any) => {
    if (name === "signCodeId") {
      const c = initDataSetups.signCode.find((x: VMCode) => x.id === value);
      if (c !== undefined) {
        setInitDataSign({
          ...initDataSign,
          signCodeId: value,
          signDimensionId: c.dimensionId,
          materialCost: c.materialCost,
          labourCost: c.labourCost,
          installationCost: c.installationCost,
        });
        setDataChanged(true);
      }
    } else {
      setInitDataSign({ ...initDataSign, [name]: value });
    }
    setDataChanged(true);
  };

  function onChangeLocationDescription(
    locationId: string,
    locationDescription: string,
    geoId: string,
    locationType: LocationType,
    latitude: number,
    longitude: number
  ) {
    const support = new Support();
    support.locationDescription = locationDescription;
    support.geoId = geoId;
    support.locationType = locationType;
    support.latitude = latitude;
    support.longitude = longitude;
    support.locationId = locationId;
    setInitDataSign({ ...initDataSign, support: support });
    setInitDataLocation({ latitude: latitude, longitude: longitude });
    setDataChanged(true);
  }

  //Maintenance Functions
  function addMaintenace(lstMaintenaces: Job[]) {
    setInitDataMaintenance([
      ...initDataMaintenance,
      ...lstMaintenaces.filter(
        (x: Job) => !initDataMaintenance.map((a: Job) => a.id).includes(x.id)
      ),
    ]);
    setDataChanged(true);
  }

  function onDeleteMaintenace(deletedMaintenace: Job) {
    setInitDataMaintenance(
      initDataMaintenance.filter((x: Job) => x.id !== deletedMaintenace.id)
    );
    setDataChanged(true);
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && signRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = signRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  const copyOneTextToClipboard = () => {
    if (signRef.current) {
      const textToCopy = signRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        SignPermissions.Sign_D,
        SignPermissions.Sign_R,
        SignPermissions.Sign_V,
        SignPermissions.Sign_E,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`signDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t("sign")}: </span>
                    {initDataSign?.geoId && initDataSign?.geoId !== "" && (
                      <span ref={geoIdRef}>[{initDataSign?.geoId}]</span>
                    )}
                    <span> {t("withSignId")}: </span>
                    <span ref={signRef}>{initDataSign?.signId}</span>
                  </p>
                  {initDataSign?.geoId && initDataSign?.geoId !== "" ? (
                    <DropDownButton
                      style={{ marginLeft: 10, width: "6rem" }}
                      text={t("copy")}
                      items={[
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("signId"), value: t("signId") },
                      ]}
                      dropDownOptions={{
                        width: MaxDropdownItemWidthCalculator([
                          { name: t("geoId"), value: t("geoId") },
                          { name: t("signId"), value: t("signId") },
                        ]),
                      }}
                      displayExpr="name"
                      onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                      stylingMode="text"
                      hoverStateEnabled={false}
                      focusStateEnabled={false}
                    />
                  ) : (
                    <Button
                      onClick={() => copyOneTextToClipboard()}
                      icon="fa-solid fa-copy"
                      hint={t("copy")}
                    />
                  )}
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div
                className="row"
                style={{
                  marginTop: compactViewModel ? 0 : 15,
                  height: compactViewModel ? "2rem" : "",
                }}
              >
                <div className="leftColumn">
                  <Button
                    onClick={goBackPermission}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                  <ReportSelector
                    reportLocations={[reportLocations.Sign_SignDetails]}
                    getRecords={() => [{ id: initDataSign.id }]}
                  />
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[SignPermissions.Sign_E]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={() => LockHandler()}
                      icon={
                        isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                      }
                      hint={isLocked ? t("unlock") : t("lock")}
                    />
                  </Permission>
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[SignPermissions.Sign_D]}
                    hasFeedBackElement={false}
                  >
                    {params.signId === "AddNew" && (
                      <Button
                        onClick={addSign}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    )}
                  </Permission>
                  <Permission
                    allowed={[SignPermissions.Sign_E]}
                    hasFeedBackElement={false}
                  >
                    {params.signId !== "AddNew" && isLocked === false && (
                      <Button
                        onClick={updateSign}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    )}
                    <ShareURL url={url} />
                  </Permission>
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                width={"100%"}
                dataSource={titles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />

              {selectedIndex === 0 && (
                <Details
                  initDataSetups={initDataSetups}
                  setInitDataSign={setInitDataSign}
                  signCodeDesc={signCodeDesc}
                  setSignCodeDesc={setSignCodeDesc}
                  onValueChange={onValueChange}
                  signId={params.signId!}
                  initDataSign={initDataSign}
                  onChangeLocationDescription={onChangeLocationDescription}
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  setDataChanged={setDataChanged}
                  validationRef={validationRef}
                />
              )}

              {selectedIndex === 1 && (
                <Location
                  initDataSign={initDataSign}
                  initDataLocation={initDataLocation}
                  isLocked={isLocked}
                  setDataChanged={setDataChanged}
                  onValueChange={onValueChange}
                  validationRef={validationRef}
                />
              )}
              {selectedIndex === 2 && (
                <Attachments
                  isLocked={isLocked}
                  initDataSign={initDataSign}
                  files={files}
                  handleChangeFile={handleChangeFile}
                  onDeleteExistingFile={onDeleteExistingFile}
                  onDeleteNewFile={onDeleteNewFile}
                  validationRef={validationRef}
                />
              )}
              {selectedIndex === 3 && (
                <ChangeLogs
                  initDataSign={initDataSign}
                  initDataSetups={initDataSetups}
                  isLocked={isLocked}
                  validationRef={validationRef}
                />
              )}
              {selectedIndex === 4 && (
                <Maintenance
                  initDataSign={initDataSign}
                  initDataMaintenance={initDataMaintenance}
                  addMaintenace={addMaintenace}
                  onDeleteMaintenace={onDeleteMaintenace}
                  isLocked={isLocked}
                  setDataChanged={setDataChanged}
                  validationRef={validationRef}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SignDetails;
