import { useEffect, useRef, useState } from "react";
import { TesMapModule } from "../../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import { isLayerExist, layerSuccessfullyLoadedInDomCallback } from "../utils";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import SimpleRenderer from "@arcgis/core/renderers/SimpleRenderer";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import MapView from "@arcgis/core/views/MapView";
import Map from "@arcgis/core/Map";
import { MapSetting } from "../../../../types/infrastructure/infrastructureTypes";
import {
  roadSegmentPopupActionBTNs,
  TLayerPopupAction,
} from "../utils/popupsActions";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

type TProps = {
  view: MapView | null;
  map: Map | null;
  mapEl: any;
  initDataGis?: MapSetting | null;
  tesModule: TesMapModule;
};

export function useRoadSegmentLayer({
  view,
  map,
  mapEl,
  initDataGis,
  tesModule,
}: TProps) {
  const { t } = useTranslation();

  const roadSegmentLayer = useRef<FeatureLayer | null>(null);
  const [roadSegmentLayerId, setRoadSegmentLayerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getRoadSegmentFieldInfos = () => {
    const layerAttributes = initDataGis?.gisLayerAttributes;
    let filedInfos = [
      {
        fieldName: initDataGis?.midblockGeoIdName,
        label: "Geo ID",
      },
    ];
    if (layerAttributes && layerAttributes?.length > 0) {
      layerAttributes.forEach((attribute) => {
        if (attribute.layerType === LocationType.Midblock) {
          filedInfos.push({
            fieldName: attribute.name,
            label: attribute.label,
          });
        }
      });
    }
    return filedInfos;
  };

  const createRoadSegmentLayer = () => {
    setIsLoading(true);
    const featureLayers = new FeatureLayer({
      title: "Road Segment",
      id: "road-segment-layer",
      copyright: "TNS",
      url: initDataGis!.midblockFileAddress,
      apiKey: initDataGis!.arcGisApiKey,
      minScale: 0, // Always visible
      maxScale: 0, // Always visible
      outFields: [initDataGis!.midblockGeoIdName],
      popupTemplate: {
        lastEditInfoEnabled: false,
        // actions: getRoadSegmentPopupAction(),
        actions: roadSegmentPopupActionBTNs(tesModule),
        content: [
          {
            type: "fields",
            fieldInfos: getRoadSegmentFieldInfos(),
          },
        ],
      },
      renderer: new SimpleRenderer({
        symbol: new SimpleLineSymbol({
          color: [0, 0, 0], // Set the color to black (RGB values)
          width: 1,
        }),
      }),
    });

    featureLayers.load().then(() => {
      map!.add(featureLayers);
    });

    roadSegmentLayer.current = featureLayers;
    // setRoadSegmentLayerId(featureLayers.uid);
    setRoadSegmentLayerId(featureLayers.id);

    layerSuccessfullyLoadedInDomCallback(view!, featureLayers, () => {
      map?.reorder(featureLayers, 0);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (map && initDataGis !== null && mapEl && view) {
      if (isLayerExist(map, "road-segment-layer")) return;
      createRoadSegmentLayer();
    }
  }, [mapEl, initDataGis, map, view]);

  return {
    roadSegmentLayer: roadSegmentLayer.current,
    roadSegmentLayerId: roadSegmentLayerId,
    isRoadSegmentLoading: isLoading,
  };
}
