import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { CollisionApiUrl } from "../../../environment/routeSettings";
import { CollisionValidationReport } from "../../../types/report/reportTypes";
import { OnExporting } from "../../../utils/dataGridTools";
import { useScreenSize } from "../../../utils/media-query";
import { TesPost } from "../../../utils/rest";

interface IProps {
  collisionIds: string[];
  isOpen: boolean;
  onClose: () => void;
}

const ValidationReport_v1 = (props: IProps) => {
  const { isOpen, onClose, collisionIds } = props;
  const [initData, setInitData] = useState<CollisionValidationReport[]>([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { isXLarge, is2xLarge } = useScreenSize();
  const [gridHeight, setGridHeight] = useState<number | string>(630);

  useEffect(() => {
    if (isXLarge || is2xLarge) {
      setGridHeight("auto");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    if (isOpen) {
      fetchMyAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, collisionIds]);

  async function getInitialData() {
    if (collisionIds.length > 0) {
      try {
        const res = await TesPost(
          CollisionApiUrl() + "/api/reports/validationReport",
          collisionIds,
          true
        );
        setInitData(res);
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
  }

  return (
    <div>
      <Popup
        visible={isOpen}
        width={"80%"}
        height={gridHeight}
        resizeEnabled={true}
        showTitle={true}
        title={t("validationReport")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={onClose}
      >
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={initData}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
          height={"100%"}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Grouping contextMenuEnabled={true} autoExpandAll={false} />
          <GroupPanel visible={true} /> {/* or "auto" */}
          <FilterPanel visible={true} />
          <FilterBuilderPopup position={"top"} />
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <Column
            alignment="left"
            dataField="id"
            allowSorting={true}
            caption={t("id")}
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="collisionIdentifier"
            caption={t("collision")}
            //  groupIndex={0}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="level"
            caption={t("validationStatus")}
            // groupIndex={1}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="message" caption={t("message")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
        </DataGrid>
      </Popup>
    </div>
  );
};

export default ValidationReport_v1;
