import { EnvConfig } from "./environment-model";

let globalEnvConfig = (window as any).globalEnvConfig! as EnvConfig;
const EnvRounteSetting = globalEnvConfig.Routes;

function GetModuleName(
  defaultValue: string,
  envValue: string | undefined
): string {
  if (envValue === null || envValue === undefined) {
    return defaultValue;
  } else {
    return envValue;
  }
}

function GetURL(path: string): string | undefined {
  if (path === null || path === undefined) {
    return undefined;
  } else if (path.startsWith("http")) {
    return path;
  } else {
    var strRootPath = "";
    if (
      EnvRounteSetting.RootPath === null ||
      EnvRounteSetting.RootPath === undefined ||
      EnvRounteSetting.RootPath === ""
    ) {
      strRootPath = "";
    } else {
      strRootPath = "/" + EnvRounteSetting.RootPath.trimEnd();
    }
    //return (EnvRounteSetting.BaseUrlApi?.trimEnd() +strRootPath  + "/"  + path.trimStart()).toLocaleLowerCase();
    return (strRootPath + "/" + path.trimStart()).toLocaleLowerCase();
  }
}

function InfrastructureApiUrl(defaultAddress: string = "infrastructure") {
  var moduleName = GetModuleName(
    defaultAddress,
    EnvRounteSetting.Infrastructure
  );
  return GetURL(moduleName);
}

function SignApiUrl(defaultAddress: string = "sign") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Sign);
  return GetURL(moduleName);
}
function FieldApiUrl(defaultAddress: string = "field") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Field);
  return GetURL(moduleName);
}
function MaintenanceApiUrl(defaultAddress: string = "maintenance") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Maintenance);
  return GetURL(moduleName);
}
function CollisionApiUrl(defaultAddress: string = "collision") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Collision);
  return GetURL(moduleName);
}
function ModuleOfModuleApiUrl(defaultAddress: string = "modules") {
  var moduleName = GetModuleName(
    defaultAddress,
    EnvRounteSetting.ModuleOfModule
  );
  return GetURL(moduleName);
}
function SafetyApiUrl(defaultAddress: string = "safety") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Safety);
  return GetURL(moduleName);
}
function SyncApiUrl(defaultAddress: string = "sync") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Sync);
  return GetURL(moduleName);
}
function SettingApiUrl(defaultAddress: string = "setting") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Setting);
  return GetURL(moduleName);
}
function EmailApiUrl(defaultAddress: string = "email") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Email);
  return GetURL(moduleName);
}
function TrafficApiUrl(defaultAddress: string = "traffic") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Traffic);
  return GetURL(moduleName);
}
function FileManagementApiUrl(defaultAddress: string = "fileManagement") {
  var moduleName = GetModuleName(
    defaultAddress,
    EnvRounteSetting.FileManagement
  );
  return GetURL(moduleName);
}
function LogAppApiUrl(defaultAddress: string = "logApp") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.LogApp);
  return GetURL(moduleName);
}
function ReportApiUrl(defaultAddress: string = "report") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Report);
  return GetURL(moduleName);
}
function VersioningApplicationApiUrl(
  defaultAddress: string = "versioningApplications"
) {
  var moduleName = GetModuleName(
    defaultAddress,
    EnvRounteSetting.VersioningApplication
  );
  return GetURL(moduleName);
}
function IntegrationApiUrl(defaultAddress: string = "integration") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Integration);
  return GetURL(moduleName);
}
function KenyaIntegrationApiUrl(defaultAddress: string = "kenyaIntegration") {
  var moduleName = GetModuleName(
    defaultAddress,
    EnvRounteSetting.KenyaIntegration
  );
  return GetURL(moduleName);
}
function PhoenixApiUrl(defaultAddress: string = "phoenix") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Phoenix);
  return GetURL(moduleName);
}
function TranslationApiUrl(defaultAddress: string = "translation") {
  var moduleName = GetModuleName(defaultAddress, EnvRounteSetting.Translation);
  return GetURL(moduleName);
}
function AuthApiUrl() {
  //  return EnvRounteSetting.Auth;
  return "/auth";
}
export {
  InfrastructureApiUrl,
  SignApiUrl,
  FieldApiUrl,
  MaintenanceApiUrl,
  CollisionApiUrl,
  ModuleOfModuleApiUrl,
  SafetyApiUrl,
  SyncApiUrl,
  SettingApiUrl,
  EmailApiUrl,
  TrafficApiUrl,
  FileManagementApiUrl,
  LogAppApiUrl,
  ReportApiUrl,
  VersioningApplicationApiUrl,
  IntegrationApiUrl,
  KenyaIntegrationApiUrl,
  AuthApiUrl,
  PhoenixApiUrl,
  TranslationApiUrl,
};
