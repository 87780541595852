import { Popup, TextArea } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FilePicker from "../../../components/filePicker/filePicker";
import Permission from "../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { TrafficApiUrl } from "../../../environment/routeSettings";
import { VMStorage } from "../../../types/fileManagement/dto/fileManagementDTO";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { NuMetricConvertorRequest } from "../../../types/trafficStudy/importTypes";
import {
  OnExporting,
  calculateFileTypeCell,
} from "../../../utils/dataGridTools";
import { RequestErrorHandling, TesPost } from "../../../utils/rest";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const NumetricConvertor = () => {
  const [selectedData, setSelectedData] = useState<NuMetricConvertorRequest>(
    new NuMetricConvertorRequest()
  );
  const [emails, setEmails] = useState<string>("");
  const [lstLastFiles, setLstLastFiles] = useState<VMStorage[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [showImportMessage, setShowImportMessage] = useState<boolean>(false);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useBrowserTitle({ pathname: "/trafficStudy/import/numetricConvertor" });

  function addFromFileStorage(files: VMStorage[]) {
    setLstLastFiles([...lstLastFiles, ...files]);
    const res = files.map((x) => x.filePath);
    setSelectedData({
      ...(selectedData ?? {}),
      filePaths: [...selectedData?.filePaths, ...res],
    });
  }

  async function onImportCheck() {
    setLstLastFiles([]);
    //check email regex
    const emailRegex = new RegExp(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
    var emailArray = emails.split(";") as string[];
    emailArray.forEach((element: string) => {
      if (!emailRegex.test(element ?? "")) {
        notify(t("invalidEmailFormat"), "error", 2500);
      }
    });
    const postObj: NuMetricConvertorRequest = {
      ...selectedData,
      emails: emailArray,
      customerId: localStorage.getItem("selectedCustomerId") as string,
      divisionId: localStorage.getItem("selectedDivisionId") as string,
    };
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Import/ConvertNuMetric",
        postObj,
        true
      )) as RequestResponseResult<NuMetricConvertorRequest>;
      if (res.responseCode === ResponseCode.OK) {
        setShowImportMessage(true);
        if (activeLoading) activeLoading(false);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onValueChangeEmail(value: any) {
    setEmails(value as string);
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`xmlImport ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("numetricConvertor")}</h2>
          <div className={"content-block"}>
            <div
              className={"dx-card responsive-paddings"}
              style={{ padding: "2rem 2rem 0rem 2rem" }}
            >
              <div className="row">
                <div>
                  <div style={{ marginLeft: "1rem" }}></div>
                  <Form colCount={8}>
                    <SimpleItem colSpan={4}>
                      <TextArea
                        labelMode="floating"
                        label={t("emails")}
                        value={emails}
                        onValueChange={(e) => onValueChangeEmail(e)}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <div id="filePicker" style={{ marginTop: "1rem" }}>
                        <FilePicker
                          isLocked={false}
                          label={t("filePicker")}
                          addFromFileStorage={addFromFileStorage}
                          disable={false}
                          lstLastFiles={lstLastFiles}
                          multiFileMode={true}
                          AddFolderAllowed={true}
                        />
                      </div>
                    </SimpleItem>
                    <SimpleItem colSpan={3} />
                    <SimpleItem colSpan={8}>
                      <DataGrid
                        id="gridContainer"
                        ref={dataGridRef}
                        dataSource={lstLastFiles}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        selection={{ mode: "single" }}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        onExporting={OnExporting}
                      >
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                          showPageSizeSelector={true}
                          allowedPageSizes={[100, 200, 300, 400, 500]}
                          showNavigationButtons={true}
                          showInfo={true}
                          visible={true}
                        />
                        <FilterRow visible={true} applyFilter="auto" />
                        <HeaderFilter visible={true} />
                        <SearchPanel
                          visible={true}
                          width={285}
                          placeholder={t("search...")}
                        />
                        <ColumnChooser
                          width={350}
                          height={400}
                          enabled={true}
                          mode="select"
                          sortOrder="asc"
                        >
                          <Search enabled />
                        </ColumnChooser>
                        <SortByGroupSummaryInfo
                          summaryItem="Total Count"
                          sortOrder="desc"
                        />
                        <Summary>
                          <GroupItem
                            summaryType="count"
                            alignByColumn
                            name="Total Count"
                          />
                        </Summary>
                        <Editing mode="row" allowDeleting={true} />
                        <Column
                          alignment="left"
                          dataField="id"
                          allowSorting={true}
                          caption={t("id")}
                          visible={false}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                        <Column
                          alignment="left"
                          dataField="fileType"
                          cellRender={calculateFileTypeCell}
                          caption={t("fileType")}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                        <Column
                          alignment="left"
                          dataField="fileName"
                          caption={t("fileName")}
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      </DataGrid>
                    </SimpleItem>
                  </Form>
                  <Button
                    onClick={onImportCheck}
                    className="tes-modal-btn-add rightColumn"
                    text={t("start")}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showImportMessage}
            resizeEnabled={true}
            showTitle={true}
            title={t("import")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowImportMessage(false)}
          >
            <div>
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <p className="title">{t("importMessage")}</p>
                </SimpleItem>
              </Form>
            </div>
            <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
              <Button
                className="tes-modal-btn-add"
                onClick={() => setShowImportMessage(false)}
                text={t("close")}
              />
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default NumetricConvertor;
