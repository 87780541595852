//#region imports
import {
  Form,
  NumberBox,
  ScrollView,
  SelectBox,
  TextBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../components/permission/permision";
import SafetyPermissions from "../../../../constants/Permissions/SafetyPermissions";
import { useAuth } from "../../../../contexts/auth";
import { SafetyApiUrl } from "../../../../environment/routeSettings";
import {
  ModalMode,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  DistanceUnit,
  SpfEquationType,
} from "../../../../types/safety/enums/spfFunctionEnums";
import { SpfFunction } from "../../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import "./spfFunctions.scss";
import { v4 as uuidv4 } from "uuid";

//#endregion imports
const SPFFunctions = () => {
  //#region consts
  const [initData, setInitData] = useState<SpfFunction[]>([new SpfFunction()]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<SpfFunction>(
    new SpfFunction()
  );
  const [lstEquationType, setLstEquationType] = useState<NameValue[]>([]);
  const [lstDistanceUnit, setLstDistanceUnit] = useState<NameValue[]>([]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const Type1Eq = "/images/Type1Eq.svg";
  const Type2Eq = "/images/Type2Eq.svg";
  const Type3Eq = "/images/Type3Eq.svg";
  const Type4Eq = "/images/Type4Eq.svg";
  const Type5Eq = "/images/Type5Eq.svg";
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#endregion
  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    setLstEquationType(Enum2Array(SpfEquationType));
    setLstDistanceUnit(Enum2Array(DistanceUnit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        SafetyApiUrl() +
          "/api/Settings/GetCustomerSpfFunction/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new SpfFunction());
    } else {
      setSelectedData(new SpfFunction());
      setShowModal(false);
    }
  }
  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  function onEdit(d: any) {
    let newData = new SpfFunction();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...selectedData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          SafetyApiUrl() + "/api/Settings/AddSpfFunction",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          dataGridRef.current?.instance().refresh();
          await getInitData();
          onCloseModal();
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          SafetyApiUrl() + "/api/Settings/UpdateSpfFunction",
          selectedData,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          await getInitData();
          onCloseModal();
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
          if (activeLoading) activeLoading(false);
        } else {
          await RequestErrorHandling(res);
        }
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onDelete(d: any) {
    // let myDialog = custom({
    //   title: t("warning"),
    //   messageHtml: t("deleteWarningMessage"),
    //   buttons: [
    //     {
    //       text: t("yes"),
    //       onClick: async (e) => {
    //         try {
    //           await TesDelete(
    //             apiAddresses.trafficStudyAPI + "NEEDED" + d.row.data.id,
    //             true
    //           );
    //           await getInitData();
    //           notify(t("dataSuccessfullyDeleted"), "success", 5000);
    //         } catch {
    //           notify(t("someErrorOccurred"), "error", 5000);
    //         }
    //         return { buttonText: e.component.option("text") };
    //       },
    //     },
    //     {
    //       text: t("no"),
    //       onClick: (e) => {
    //         return { buttonText: e.component.option("text") };
    //       },
    //     },
    //   ],
    // });
    // myDialog.show();
  }

  async function onDuplicate(e: any) {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...e.row.data,
          id: uuidv4(),
          name: `${e.row.data.name} - Duplicated`,
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          SafetyApiUrl() + "/api/Settings/AddSpfFunction",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          dataGridRef.current?.instance().refresh();
          await getInitData();
          onCloseModal();
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyDuplicated"), "success", 5000);
        } else {
          await RequestErrorHandling(res);
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  //#endregion functions

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("spfFunctions")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("spfFunction")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  dataField="Id"
                  caption={t("id")}
                  alignment="left"
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Id"
                  caption={t("customerId")}
                  alignment="left"
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="equationType"
                  caption={t("equationType")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstEquationType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="calibrationYear"
                  caption={t("calibrationYear")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="alpha" caption={t("alpha")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="variableB"
                  caption={t("variableB")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="variableC"
                  caption={t("variableC")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="variableD"
                  caption={t("variableD")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="variableE"
                  caption={t("variableE")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="dispersionFactor"
                  caption={t("dispersionFactor")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="variableE"
                  caption={t("variableE")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="equationType"
                  caption={t("equationType")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="distanceUnit"
                  caption={t("distanceUnit")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstDistanceUnit, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  width={110}
                  fixed={true}
                  fixedPosition="right"
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("duplicate")}
                    onClick={(e) => onDuplicate(e)}
                    icon="fa-solid fa-clone"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Popup for add/edit */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={validationRef}>
                <Form colCount={3}>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("equationType")}
                      labelMode="floating"
                      value={selectedData.equationType}
                      onValueChange={(e) => onValueChanged("equationType", e)}
                      items={SortObjectByPropName(lstEquationType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={2}>
                    {selectedData.equationType === SpfEquationType.Type1 && (
                      <img
                        src={Type1Eq}
                        alt="Type 1 Eq."
                        style={{ height: "26px", marginTop: "0.75rem" }}
                      />
                    )}
                    {selectedData.equationType === SpfEquationType.Type2 && (
                      <img
                        src={Type2Eq}
                        alt="Type 2 Eq."
                        style={{ height: "29px", marginTop: "0.75rem" }}
                      />
                    )}
                    {selectedData.equationType === SpfEquationType.Type3 && (
                      <img
                        src={Type3Eq}
                        alt="Type 3 Eq."
                        style={{ height: "54px" }}
                      />
                    )}
                    {selectedData.equationType === SpfEquationType.Type4 && (
                      <img
                        src={Type4Eq}
                        alt="Type 4 Eq."
                        style={{ height: "26px", marginTop: "0.75rem" }}
                      />
                    )}
                    {selectedData.equationType === SpfEquationType.Type5 && (
                      <img
                        src={Type5Eq}
                        alt="Type 5 Eq."
                        style={{ height: "26px", marginTop: "0.75rem" }}
                      />
                    )}
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("name")}
                      labelMode="floating"
                      value={selectedData.name}
                      onValueChange={(e) => onValueChanged("name", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      step={0}
                      label={t("calibrationYear")}
                      labelMode="floating"
                      value={selectedData.calibrationYear}
                      onValueChange={(e) =>
                        onValueChanged("calibrationYear", e)
                      }
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("alpha")}
                      labelMode="floating"
                      value={selectedData.alpha}
                      onValueChange={(e) => onValueChanged("alpha", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("variableB")}
                      labelMode="floating"
                      value={selectedData.variableB}
                      onValueChange={(e) => onValueChanged("variableB", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("variableC")}
                      labelMode="floating"
                      value={selectedData.variableC}
                      onValueChange={(e) => onValueChanged("variableC", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("variableD")}
                      labelMode="floating"
                      value={selectedData.variableD}
                      onValueChange={(e) => onValueChanged("variableD", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("variableE")}
                      labelMode="floating"
                      value={selectedData.variableE}
                      onValueChange={(e) => onValueChanged("variableE", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("dispersionFactor")}
                      labelMode="floating"
                      value={selectedData.dispersionFactor}
                      onValueChange={(e) =>
                        onValueChanged("dispersionFactor", e)
                      }
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("distanceUnit")}
                      labelMode="floating"
                      value={selectedData.distanceUnit}
                      onValueChange={(e) => onValueChanged("distanceUnit", e)}
                      items={SortObjectByPropName(lstDistanceUnit, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                </Form>
              </ValidationGroup>
              <div>
                <div className="rightColumn" style={{ marginTop: "1.5rem" }}>
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SPFFunctions;
