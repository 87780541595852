import { DateBox, Form, ScrollView, TextArea, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Permission from "../../../../../components/permission/permision";
import { CollisionPermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import {
  CollisionApiUrl,
  MaintenanceApiUrl,
} from "../../../../../environment/routeSettings";
import { ModalMode } from "../../../../../types/general/enums/generalEnums";
import {
  Hospitalization,
  IHospParams,
  Patient,
} from "../../../../../types/hospitalization/hospitalizationTypes";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { TesDelete, TesGet } from "../../../../../utils/rest";

// props
interface IPros {
  generalSettings: GeneralSetting | null;
}

const Patients = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const params = useParams<IHospParams>();
  const [initData, setInitData] = useState<Hospitalization>(
    new Hospitalization()
  );
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<Patient>(new Patient());
  const { generalSetting } = useClientSetting();
  const validationRef = useRef<ValidationGroupRef>(null);
  const { activeLoading } = useAuth();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitiData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitiData() {
    setInitData(
      await TesGet(
        CollisionApiUrl() +
          "/api/Hospitalizations/GetHospitalizationDatailes/" +
          params.hospitalizationId,
        true
      )
    );
  }
  function onNew() {
    setModalMode(ModalMode.Add);
    validationRef.current?.instance().reset();
    setShowModal(true);
  }
  function onEdit(d: any) {
    let newData = new Patient();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                MaintenanceApiUrl() + "/api/jobTypes/" + d.row.data.id,
                true
              );
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new Patient());
    } else {
      setSelectedData(new Patient());
      setShowModal(false);
    }
  }
  function onAdd() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      setInitData({
        ...initData,
        patients: [...initData.patients, { ...selectedData, id: uuidv4() }],
      });
      onCloseModal();
    }
  }
  function onUpdate() {
    var patients: Patient[] = initData.patients;
    var selectedDataIndex: number = patients.findIndex(
      (x: Patient) => x.id === selectedData.id
    );
    patients[selectedDataIndex] = selectedData;
    setInitData({
      ...initData,
      patients: patients,
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  return (
    <div
      className={`hospitalizationDetails-patients ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Permission
                  allowed={[CollisionPermissions.Collision_D_Hospitalization]}
                  hasFeedBackElement={false}
                >
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("patient")}
                  />
                </Permission>
              </div>
            </div>
            <DataGrid
              id="patientsGridContainer"
              ref={dataGridRef}
              dataSource={initData.patients}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="accidentNumber" caption={t("accidentNumber")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="patientName"
                defaultSortOrder="asc"
                caption={t("patientName")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="patientId" caption={t("patientId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="hospital" caption={t("hospital")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="admittedDate"
                dataType="date"
                format={generalSetting?.dateFormat}
                caption={t("admissionDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="diagnosis" caption={t("diagnosisInjury")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="dischargedDate"
                dataType="date"
                format={generalSetting?.dateFormat}
                caption={t("DischargedDateDeath")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                type="buttons"
                caption={t("actions")}
                width={110}
                fixed={true}
                fixedPosition="right"
              >
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </div>
        </div>

        {/* add/edit Popup  */}
        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <ValidationGroup ref={validationRef}>
              <Form colCount={2} className="popupFields">
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("name")}
                    labelMode="floating"
                    value={selectedData.patientName}
                    onValueChange={(e) => onValueChanged("patientName", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </TextBox>
                </SimpleItem>

                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("id")}
                    labelMode="floating"
                    value={selectedData.patientId}
                    onValueChange={(e) => onValueChanged("patientId", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </TextBox>
                </SimpleItem>

                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("hospital")}
                    labelMode="floating"
                    value={selectedData.hospital}
                    onValueChange={(e) => onValueChanged("hospital", e)}
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t("admittedDate")}
                    defaultValue={
                      selectedData.admittedDate === new Date(0) ||
                      selectedData.admittedDate == null
                        ? undefined
                        : selectedData.admittedDate
                    }
                    type="date"
                    onValueChange={(e) => onValueChanged("admittedDate", e)}
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateFormat}
                    displayFormat={props.generalSettings?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextArea
                    label={t("diagnosis")}
                    labelMode="floating"
                    value={selectedData.diagnosis}
                    onValueChange={(e) => onValueChanged("diagnosis", e)}
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t("dischargedDate")}
                    defaultValue={
                      selectedData.dischargedDate === new Date(0) ||
                      selectedData.dischargedDate == null
                        ? undefined
                        : selectedData.dischargedDate
                    }
                    type="date"
                    onValueChange={(e) => onValueChanged("dischargedDate", e)}
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateFormat}
                    displayFormat={props.generalSettings?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                  />
                </SimpleItem>
              </Form>
            </ValidationGroup>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </div>
  );
};
export default Patients;
