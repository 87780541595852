import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "signs",
    icon: "fa-solid fa-triangle-exclamation",
    content: "Signs tab content",
  },
  {
    id: 2,
    text: "supports",
    icon: "fa-solid fa-sign-hanging",
    content: "Supports tab content",
  },
  {
    id: 3,
    text: "personnels",
    icon: "fa-solid fa-people-roof",
    content: "Personnels tab content",
  },
  {
    id: 4,
    text: "equipments",
    icon: "fa-solid fa-toolbox",
    content: "Equipments tab content",
  },
  {
    id: 5,
    text: "attachments",
    icon: "fa-solid fa-paperclip",
    content: "Attachments tab content",
  },
];
export default tabTitles;
