//#region imports
import {
  Form,
  ScrollView,
  SelectBox,
  Switch,
  TextArea,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import {
  EmailApiUrl,
  VersioningApplicationApiUrl,
} from "../../../environment/routeSettings";
import { EmailTemplate } from "../../../types/email/emailTypes";
import { EmailTemplateType } from "../../../types/email/enums/emailEnums";
import { ModalMode } from "../../../types/general/enums/generalEnums";
import { NameValue } from "../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { Enum2Array } from "../../../utils/enumTools";
import { TesDelete, TesGet, TesPost, TesPut } from "../../../utils/rest";
import "./emailTemplates.scss";

//#endregion imports

const EmailTemplates = () => {
  //#region consts
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [initDataSignSetup, setInitData] = useState<EmailTemplate[]>([
    new EmailTemplate(),
  ]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<EmailTemplate>(
    new EmailTemplate()
  );
  const [lstEmailTypes, setLstEmailTypes] = useState<NameValue[]>([
    new NameValue(),
  ]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  //#endregion

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstEmailTypes(Enum2Array(EmailTemplateType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        EmailApiUrl() +
          "/api/emailTemplates/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new EmailTemplate());
    } else {
      setSelectedData(new EmailTemplate());
      setShowModal(false);
    }
    validationRef.current?.instance().reset();
  }
  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  function onEdit(d: any) {
    let newData = new EmailTemplate();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  function onNew() {
    validationRef.current?.instance().reset();
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...selectedData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        await TesPost(EmailApiUrl() + "/api/emailTemplates", postObj, true);
        //refresh the grid
        await getInitData();
        onCloseModal();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        await TesPut(
          EmailApiUrl() + "/api/emailTemplates/" + selectedData.id,
          selectedData,
          true
        );
        await getInitData();
        onCloseModal();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        if (activeLoading) activeLoading(false);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                VersioningApplicationApiUrl() +
                  "/api/emailTemplates/" +
                  d.row.data.id,
                true
              );
              await getInitData();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  //#endregion functions

  return (
    <div className={` ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        <h2 className={"content-block"}>{t("emailTemplates")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  onClick={onNew}
                  icon="fa-solid fa-circle-plus"
                  text={t("template")}
                />
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initDataSignSetup}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.Street.toString()} savingTimeout={500} /> */}
              <Summary>
                <TotalItem column="GeoId" summaryType="count" />
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="emailTemplateType"
                alignment="left"
                caption={t("type")}
              >
                <Lookup
                  dataSource={SortObjectByPropName(lstEmailTypes, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="isDefault"
                caption={t("default")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                type="buttons"
                caption={t("actions")}
                width={110}
                fixed={true}
                fixedPosition="right"
              >
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </div>
        </div>

        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <ValidationGroup ref={validationRef}>
              <Form colCount={2} className="popupFields">
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("emailTemplateType")}
                    labelMode="floating"
                    value={selectedData.emailTemplateType}
                    onValueChange={(e) =>
                      onValueChanged("emailTemplateType", e)
                    }
                    className="modalInput"
                    valueExpr={"value"}
                    displayExpr={"name"}
                    items={SortObjectByPropName(lstEmailTypes, "name")}
                    showClearButton={true}
                    searchEnabled={true}
                    style={{ margin: "0 0 0 -1rem" }}
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </SelectBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <div>{t("isDefault")}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: "bold" }}
                    value={selectedData.isDefault}
                    onValueChange={(e) => onValueChanged("isDefault", e)}
                    hint={t("isDefault")}
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </Switch>
                </SimpleItem>
                <SimpleItem colSpan={2}>
                  <TextArea
                    label={t("content")}
                    labelMode="floating"
                    value={selectedData.content}
                    onValueChange={(e) => onValueChanged("content", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </TextArea>
                </SimpleItem>
              </Form>
            </ValidationGroup>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </div>
  );
};
export default EmailTemplates;
