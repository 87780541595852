import { useState } from "react";
import styles from "./test.module.scss";
import { Button } from "devextreme-react";
import data from "./mock";
import { VRUScoring } from "../../types/systematicScreening/systematicScreeningTypes";

type Group = {
  name: string;
  rows: (number | null)[][];
};

type Groups = {
  [key: string]: Group[];
};

type VRUScoringKey = keyof VRUScoring;

const Test: React.FC = () => {
  const [groups, setGroups] = useState<Groups>(() => {
    const processedGroups: Groups = {};
    (Object.keys(data.vruScoring) as VRUScoringKey[]).forEach((key) => {
      const scoringData = data.vruScoring[key];
      processedGroups[key] = [
        {
          name: key,
          rows: scoringData.vruTargetPercentiles.map((item) => [
            item.from,
            item.to,
            item.value,
          ]),
        },
      ];
    });
    return processedGroups;
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editingRows, setEditingRows] = useState<Groups>(groups);

  const handleEditToggle = () => {
    if (isEditing) {
      setGroups(editingRows);
    } else {
      setEditingRows({ ...groups });
    }
    setIsEditing(!isEditing);
  };

  const handleEditChange = (
    groupKey: VRUScoringKey,
    groupIndex: number,
    rowIndex: number,
    cellIndex: number,
    value: string
  ) => {
    const updatedGroups = { ...editingRows };
    updatedGroups[groupKey][groupIndex].rows[rowIndex][cellIndex] = parseInt(value) || 0;
    setEditingRows(updatedGroups);
  };

  const addRow = (groupKey: VRUScoringKey, groupIndex: number) => {
    const updatedGroups = { ...groups };
    updatedGroups[groupKey][groupIndex].rows.push([0, 0, 0]);
    setGroups(updatedGroups);
  };

  const handleDelete = (groupKey: VRUScoringKey, groupIndex: number, rowIndex: number) => {
    const updatedGroups = { ...groups };
    updatedGroups[groupKey][groupIndex].rows = updatedGroups[groupKey][groupIndex].rows.filter((_, i) => i !== rowIndex);
    setGroups(updatedGroups);
  };

  return (
    <div style={{ margin: "1rem" }}>
      <Button
        text={isEditing ? "Save All" : "Edit All"}
        onClick={handleEditToggle}
        style={{ marginBottom: "1rem" }}
      />
      <table className={styles["bordered-table"]}>
        <tbody>
          {(Object.keys(groups) as VRUScoringKey[]).map((groupKey) => (
            groups[groupKey].map((group, groupIndex) => (
              <>
                <tr key={groupKey + groupIndex}>
                  <td rowSpan={group.rows.length + 1}>
                    {group.name}
                    <br />
                    <Button
                      icon="fa-solid fa-plus"
                      onClick={() => addRow(groupKey, groupIndex)}
                      style={{ margin: "0", padding: "0" }}
                    />
                  </td>
                  <th>Target Percentile</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Delete</th>
                </tr>
                {group.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex}>
                        {isEditing ? (
                          <input
                            type="number"
                            value={cell || ""}
                            onChange={(e) => handleEditChange(groupKey, groupIndex, rowIndex, cellIndex, e.target.value)}
                            className={styles["input-no-border"]}
                            step="any"
                          />
                        ) : (
                          cell
                        )}
                      </td>
                    ))}
                    <td>
                      <Button
                        icon="fa-solid fa-trash"
                        onClick={() => handleDelete(groupKey, groupIndex, rowIndex)}
                        style={{ margin: "0", padding: "0" }}
                      />
                    </td>
                  </tr>
                ))}
              </>
            ))
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Test;