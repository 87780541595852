const InfrastructurePermissions = {
  Infrastructure_D_Street: "Infrastructure_D_Street",
  Infrastructure_R_Street: "Infrastructure_R_Street",
  Infrastructure_V_Street: "Infrastructure_V_Street",
  Infrastructure_D_Site: "Infrastructure_D_Site",
  Infrastructure_R_Site: "Infrastructure_R_Site",
  Infrastructure_V_Site: "Infrastructure_V_Site",
  Infrastructure_D_Setups: "Infrastructure_D_Setups",
  Infrastructure_D_Area: "Infrastructure_D_Area",
  Infrastructure_V_MIS: "Infrastructure_V_MIS",
  Infrastructure_E_MIS: "Infrastructure_E_MIS",
  Infrastructure_V_CountStation: "Infrastructure_V_CountStation",
  Infrastructure_E_CountStation: "Infrastructure_E_CountStation",
  Infrastructure_V_TrafficSection: "Infrastructure_V_TrafficSection",
  Infrastructure_E_TrafficSection: "Infrastructure_E_TrafficSection",
};
export default InfrastructurePermissions;
