import { Routes, Route, Navigate } from "react-router-dom";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { Footer } from "./components";
import LoadPanel from "devextreme-react/load-panel";
import { useAuth } from "./contexts/auth";

export default function TesContent() {
  const { localLoading } = useAuth();

  return (
    <SideNavBarLayout title="">
      <Routes>
        {routes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}

        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
      <Footer>Copyright © 2023-2024 True North Safety Group.</Footer>
      <br />

      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={localLoading}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
    </SideNavBarLayout>
  );
}
