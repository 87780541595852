//#region impoprts
import { ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../components/permission/permision";
import { MaintenancePermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { MaintenanceApiUrl } from "../../../../../environment/routeSettings";
import { PersonnelName } from "../../../../../types/maintenance/dto/maintenanceDto";
import { Cost } from "../../../../../types/maintenance/maintenanceTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { useScreenSize } from "../../../../../utils/media-query";
import { TesGet } from "../../../../../utils/rest";
//#endregion imports

//#region props
interface IPros {
  currentPersonnels: PersonnelName[];
  generalSettings: any;
  assignPersonnel: (lstPersonnels: PersonnelName[]) => void;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}
//#endregion props
const Personnels = (props: IPros) => {
  //#region  consts
  const [showModalAssignPersonnel, setShowModalAssignPersonnel] =
    useState(false);
  const [initDataPersonnels, setInitDataPersonnels] =
    useState<PersonnelName[]>();
  const [showModalCosts, setShowModalCost] = useState(false);
  const [initDataCosts, setInitDataCosts] = useState<Cost[]>([new Cost()]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const datagridCostRef = useRef<DataGridRef<any, any>>(null);
  const dataGridRefAssignPersonnel = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [popupHeight, setPopupHeight] = useState<string>("85%");
  const [assignCostGridHeight, setAssignCostGridHeight] = useState<number>(420);
  const [costGridHeight, setCostGridHeight] = useState<number>(430);

  //#endregion consts

  useEffect(() => {
    if (isXLarge) {
      setPopupHeight("auto");
      setAssignCostGridHeight(530);
      setCostGridHeight(530);
    } else if (is2xLarge) {
      setPopupHeight("auto");
      setAssignCostGridHeight(900);
      setCostGridHeight(530);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onAssign() {
    setShowModalAssignPersonnel(true);
    if (activeLoading) activeLoading(true);
    setInitDataPersonnels(
      await TesGet(
        MaintenanceApiUrl() +
          "/api/personnels/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
    if (activeLoading) activeLoading(false);
  }
  function onCloseModalAssignPersonnel() {
    setShowModalAssignPersonnel(false);
  }
  function onAssignPersonnel() {
    var lstPersonnels = dataGridRefAssignPersonnel.current
      ?.instance()
      .getSelectedRowKeys() as PersonnelName[];
    props.assignPersonnel(lstPersonnels);
    setShowModalAssignPersonnel(false);
    dataGridRefAssignPersonnel.current?.instance().deselectAll();
    props.setDataChanged(true);
  }
  function calculateCostsListCell(d: any) {
    return (
      <Button
        className="tes-modal-btn-add"
        onClick={() => onOpenCosts(d.data)}
        text={t("costs")}
      />
    );
  }
  function onCloseModalCosts() {
    setShowModalCost(false);
    dataGridRefAssignPersonnel.current?.instance().deselectAll();
  }
  async function onOpenCosts(data: any) {
    if (activeLoading) activeLoading(true);

    setInitDataCosts(
      await TesGet(
        MaintenanceApiUrl() + "/api/costs/personnelCosts/" + data.id,
        true
      )
    );
    datagridCostRef.current?.instance().refresh();
    setShowModalCost(true);
    if (activeLoading) activeLoading(false);
  }
  //#endregion functions
  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className={"content-block"}>
          <div className={" responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={onAssign}
                  icon="fa-solid fa-link"
                  text={t("assign")}
                  disabled={props.isLocked}
                />
              </div>
            </div>
            {/* Personnel datagrid */}
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.currentPersonnels}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />

              <Export enabled={true} allowExportSelectedData={true} />

              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="firstname" caption={t("firstName")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="lastname" caption={t("lastName")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="costForJob" caption={t("costForJob")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                cellRender={calculateCostsListCell}
                caption={t("costs")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>

        {/* Assign Personnel Modal */}
        <Popup
          width="70%"
          height={popupHeight}
          visible={showModalAssignPersonnel}
          resizeEnabled={true}
          showTitle={true}
          title={t("assignPersonnel")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalAssignPersonnel()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              ref={dataGridRefAssignPersonnel}
              dataSource={initDataPersonnels}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              height={assignCostGridHeight}
              onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRefAssignPersonnel?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="firstname" caption={t("firstname")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="lastname" caption={t("lastname")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                cellRender={calculateCostsListCell}
                caption={t("costs")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={onCloseModalAssignPersonnel}
                text={t("cancel")}
              />
              <Permission
                allowed={[
                  MaintenancePermissions.Maintenance_D_Job,
                  MaintenancePermissions.Maintenance_E_Job,
                ]}
                hasFeedBackElement={false}
              >
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAssignPersonnel}
                  text={t("assign")}
                />
              </Permission>
            </div>
          </div>
        </Popup>

        {/* Cost Modal */}
        <Popup
          width={"65%"}
          height={popupHeight}
          visible={showModalCosts}
          resizeEnabled={true}
          showTitle={true}
          title={t("costs")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalCosts()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={datagridCostRef}
              dataSource={initDataCosts}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              height={costGridHeight}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="price" caption={t("cost")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fromDate"
                dataType="date"
                format={props.generalSettings?.dateFormat}
                caption={t("from")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="toDate"
                dataType="date"
                format={props.generalSettings?.dateFormat}
                caption={t("to")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                onClick={onCloseModalCosts}
                text={t("close")}
              />
            </div>
          </div>
        </Popup>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Personnels;
