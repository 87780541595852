//imports
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Month } from "../../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { MonthlyAdjustmentFactor } from "../../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";

interface IPros {
  gridData: MonthlyAdjustmentFactor[];
}

const MonthlyAdjFactor = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const [lstMonths, setLstMonths] = useState<NameValue[]>([]);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    setLstMonths(Enum2Array(Month));
  }, []);

  return (
    <div className={compactViewModel ? "compactStyle" : ""}>
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.gridData}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <StateStoring
                enabled={true}
                type="localStorage"
                savingTimeout={500}
              />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                dataField="id"
                caption={t("id")}
                alignment="left"
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="monthOfYear"
                caption={t("monthOfYear")}
                alignment="left"
              >
                <Lookup
                  dataSource={SortObjectByPropName(lstMonths, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="firstHalfAdtFactor"
                caption={t("firstHalfAdtFactor")}
                alignment="left"
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="secondHalfAdtFactor"
                caption={t("secondHalfAdtFactor")}
                alignment="left"
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="firstHalfAwdFactor"
                caption={t("firstHalfAwdFactor")}
                format={{ type: "fixedPoint", precision: 2 }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="secondHalfAwdFactor"
                caption={t("secondHalfAwdFactor")}
                format={{ type: "fixedPoint", precision: 2 }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fullMonthAdtFactor"
                caption={t("fullMonthAdtFactor")}
                format={{ type: "fixedPoint", precision: 2 }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fullMonthAwdFactor"
                caption={t("fullMonthAwdFactor")}
                format={{ type: "fixedPoint", precision: 2 }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="firstHalfAdt"
                caption={t("firstHalfAdt")}
                format={{ type: "fixedPoint" }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="secondHalfAdt"
                caption={t("secondHalfAdt")}
                format={{ type: "fixedPoint" }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="firstHalfAwd"
                caption={t("firstHalfAwd")}
                format={{ type: "fixedPoint" }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="secondHalfAwd"
                caption={t("secondHalfAwd")}
                format={{ type: "fixedPoint" }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fullMonthAdt"
                caption={t("fullMonthAdt")}
                format={{ type: "fixedPoint" }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fullMonthAwd"
                caption={t("fullMonthAwd")}
                format={{ type: "fixedPoint" }}
                alignment="left"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
export default MonthlyAdjFactor;
