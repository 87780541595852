//#region imports
import { ContextMenu } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import { AADTPattern } from "../../../../types/trafficStudy/trafficStudyTypes";
import { OnExporting } from "../../../../utils/dataGridTools";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import "./AADTPatterns.scss";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { PCSPatternType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

//#endregion imports
const AADTPatterns = () => {
  //#region consts
  const [initData, setInitData] = useState<AADTPattern[]>([]);
  //   const [lstStudyTypes, setLstStudyTypes] = useState<NameValue[]>([])
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [lstPatterns, setLstPatterns] = useState<NameValue[]>([]);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#endregion
  //#region functions

  useBrowserTitle({ pathname: "/trafficStudy/setups/AADTPatterns" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstPatterns(Enum2Array(PCSPatternType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      var res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/GetCustomerAADTPatterns",
        {
          customerId: localStorage.getItem("selectedCustomerId"),
        } as LazyLoadingRequestBase,
        true
      )) as RequestResponseResult<AADTPattern[]>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/trafficStudy/setups/AADTPatternDetails/${e.data.id}`,
          "_blank"
        );
      } else {
        history(`/trafficStudy/setups/AADTPatternDetails/${e.data.id}`);
      }
    }
  }
  //#endregion functions

  const navigateToAADTPatternDetails = useCallback(() => {
    history("/trafficStudy/setups/AADTPatternDetails/AddNew");
  }, [history]);

  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data.id,
                "/trafficStudy/setups/AADTPatternDetails/"
              )
            }
          />
          <h2 className={"content-block"}>{t("AADTPatterns")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToAADTPatternDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("AADTPatterns")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClicked}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="divisionId"
                  caption={t("divisionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isDefault" caption={t("isDefault")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="patternType"
                  allowSorting={false}
                  caption={t("patternType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstPatterns, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="year" caption={t("year")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default AADTPatterns;
