//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../components/permission/permision";
import { FMSPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { FileManagementApiUrl } from "../../../environment/routeSettings";
import { FileType } from "../../../types/fileManagement/dto/enums/fileManagementEnums";
import { VMStorage } from "../../../types/fileManagement/dto/fileManagementDTO";
import { AttachmentDownloadURL } from "../../../types/general/generalTypes";
import {
  OnExporting,
  calculateFileTypeCell,
} from "../../../utils/dataGridTools";
import { TesPost } from "../../../utils/rest";
import "./myStorage.scss";
const MyStorage = () => {
  const [initDataFile, setInitDataFile] = useState<VMStorage[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [currentPath, setCurrentPath] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const { activeLoading, user } = useAuth();
  const { generalSetting } = useClientSetting();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (user?.permissions?.includes(FMSPermissions.FMS_Admin)) {
          onGetData("");
        } else {
          onGetData(user?.userName + "/");
        }
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onRowClick(selectedRowData: any) {
    dataGridRef.current?.instance().clearFilter();
    if (selectedRowData.data?.fileType === FileType.Folder) {
      onGetData(currentPath + selectedRowData.data?.fileName + "/");
    } else {
      getDownloadLink(selectedRowData.data?.filePath);
    }
  }

  async function onGetData(path: string) {
    if (activeLoading) activeLoading(true);
    //   setShowLoading(true)
    var res = await TesPost(
      FileManagementApiUrl() + "/api/tesFiles/getFiles",
      { path: path },
      true
    );
    setInitDataFile(res);
    setCurrentPath(path);
    if (activeLoading) activeLoading(false);
  }

  async function onGoHome() {
    await onGetData("");
  }

  function onGoBack() {
    if (currentPath === "") {
      return;
    }
    let arr = [];
    arr = currentPath.split("/");
    if (arr.length === 2) {
      if (user?.permissions?.includes(FMSPermissions.FMS_Admin)) {
        onGetData("");
        return;
      } else {
        onGetData(user?.userName + "/");
        return;
      }
    } else if (arr.length > 2) {
      arr.pop();
      arr.pop();
      var pat = arr?.join("/") + "/";
      onGetData(pat);
      return;
    }
  }

  async function showDownloadFile(d: any) {
    await getDownloadLink(d.row.data.filePath);
  }

  async function getDownloadLink(fileKey: string) {
    var res = (await TesPost(
      FileManagementApiUrl() + "/api/tesFiles/DownloadFile",
      { key: fileKey },
      true
    )) as AttachmentDownloadURL;
    setUrl(res.downloadUrl);
    setShowURLModal(true);
  }

  return (
    <Permission allowed={[FMSPermissions.FMS_V_File]} hasFeedBackElement={true}>
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("myStorage")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row" style={{ marginTop: 2, marginBottom: -15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => onGoHome()}
                    icon="fa-solid fa-house"
                    hint={t("goBack")}
                  />
                  <Button
                    onClick={() => onGoBack()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn" style={{ display: "none" }}>
                  <Button
                    // onClick={() => GoToBasket()}
                    icon="fa-solid fa-basket-shopping"
                    hint={t("goToBasket")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                dataSource={initDataFile}
                ref={dataGridRef}
                rowAlternationEnabled={true}
                showBorders={true}
                selection={{ mode: "single" }}
                onRowClick={onRowClick}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />

                <HeaderFilter visible={true} />

                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />

                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  alignment="left"
                  dataField="fileType"
                  cellRender={calculateFileTypeCell}
                  width={100}
                  caption={t("fileType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  alignment="left"
                  dataField="fileName"
                  caption={t("fileName")}
                  defaultSortOrder="asc"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  alignment="left"
                  dataField="uploadDate"
                  dataType="datetime"
                  format={generalSetting?.dateTimeFormat}
                  caption={t("uploadDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  type="buttons"
                  caption={t("actions")}
                  width={110}
                  fixed={true}
                  fixedPosition="right"
                >
                  <DevExpressButton
                    hint={t("download")}
                    onClick={showDownloadFile}
                    icon="fa-solid fa-download"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* URL modal */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showURLModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("download")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowURLModal(false)}
          >
            <ScrollView width="100%" height="100%">
              <div style={{ height: "90%" }}>
                <div className="row">
                  <div className="col-md-6" style={{ textAlign: "left" }}>
                    <span style={{ fontWeight: "bold" }}>{t("link")}: </span>
                    <a
                      href={url}
                      style={{ textDecoration: "underline", color: "#404040" }}
                    >
                      {url}
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="rightColumn" style={{ marginTop: 10 }}>
                  <Button
                    className="tes-modal-btn-add"
                    style={{ marginRight: 20 }}
                    onClick={() => window.open(url, "_blank")}
                    text={t("download")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                      notify(t("linkCopied"), "success", 5000);
                    }}
                    text={t("copyLink")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>
          {/* <button onClick={() => {optionHandler(dataGridRef,"showBorders",false)}}>showBorders</button> */}
        </React.Fragment>
      </div>
      {/* {console.log("rendered")} */}
    </Permission>
  );
};
export default MyStorage;
