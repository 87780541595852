import {
  CountStationType,
  DirSplitCalculationStatus,
  MatchByType,
  MMSResStatus,
} from "./enums/infrastructureEnums";
import { INameId } from "./../general/generalTypes";
import {
  CastType,
  DataSourceType,
  LocationType,
  StreetDirection,
} from "../infrastructure/enums/infrastructureEnums";
import { FieldType } from "../field/enums/fieldEnums";
import { VMLocation } from "./dto/locationdDto";
import { DayOfWeek, Month } from "../general/enums/generalEnums";
import {
  ApproachType,
  StudyType,
} from "../trafficStudy/enums/trafficStudyEnums";

export interface IInfrastructureGisLayer {
  id: string;
  name: string;
  url: string;
  midblockFileAddress: string;
  settingId: string;
}

export interface IIntersectionParams {
  [key: string]: any;
  intersectionId?: string;
}

export interface IMMSParams {
  [key: string]: any;
  mmsId?: string;
}

export class IntersectionDescription {
  [key: string]: any;
  public street1Id!: string;
  public street2Id!: string;
  public street3Id?: string;
  public street4Id?: string;
}

//area
export interface IAreaParams {
  [key: string]: any;
  areaId: string;
}
export class Area {
  constructor() {
    this.locations = [];
    this.sharedAreas = [];
  }
  id!: string;
  customerId!: string;
  name!: string;
  userId!: string;
  locations!: AreaXLocation[];
  sharedAreas!: SharedArea[];
  locationCount!: number;
}

export class UpdateArea {
  [key: string]: any;
  customerId!: string;
  name!: string;
  lstLocationIds?: string[];
  listUserIds?: string[];
  listGroupIds?: string[];
  divisionId!: string;
}

export class AreaXLocation {
  id!: string;
  locationId!: string;
  order!: number;
  areaId!: IAreaParams;
}
export class SharedArea {
  id!: string;
  fromUserId!: string;
  toUserId!: string;
  toGroupId!: string;
  area!: Area;
  areaId!: IAreaParams;
}

export class MapSetting {
  constructor() {
    this.baseMapLayers = [];
    this.gisLayerAttributes = [];
  }
  id!: string;
  customerId!: string;
  intersectionFileAddress!: string;
  intersectionGeoIdName!: string;
  intersectionGeoIdType!: FieldType;
  midblockFileAddress!: string;
  midblockGeoIdName!: string;
  midblockGeoIdType!: FieldType;
  latitude!: number;
  longitude!: number;
  arcGisApiKey!: string;
  wkidNumber!: number;
  apiKeyExpiryDT!: Date;
  expiryEmailNotification!: string[];
  gisLayers!: GISLayerSetting[];
  baseMapLayers!: BaseMapLayer[];
  gisLayerAttributes!: GISLayerAttribute[];
  baseLayersEnabled!: boolean;
  setInfrastructureCoordinatesInGeoCoding!: boolean;
  linearSystemType!: InfrastructureLinearSystemType;
}
export enum InfrastructureLinearSystemType {
  None = 0,
  LHRS = 1,
}
export class GISLayerAttribute {
  id!: string;
  name!: string;
  label!: string;
  type!: FieldType;
  layerType!: LocationType;
}

//GIS Layer
export class GISLayerSetting {
  id!: string;
  name!: string;
  url!: string;
  setting!: MapSetting;
  settingId!: string;
}

//street
export class Street {
  [key: string]: any;
  id!: string;
  Name!: string;
  general: any;
  municiplaity!: string;
  inactive!: boolean;
}

export class Location {
  aadtPatternId!: string;
  countStationId!: string;
  customerId!: string;
  description!: string;
  divisionId!: string;
  endLHRS!: number;
  endOffset!: number;
  general!: any;
  geoId!: string;
  id!: string;
  inactive!: boolean;
  intersectionEastApproachId!: string;
  intersectionNorthApproachId!: string;
  intersectionSouthApproachId!: string;
  intersectionWestApproachId!: string;
  isDeleted!: boolean;
  isReviewNeeded!: boolean;
  latitude!: number;
  lhrs!: number;
  limit1IntersectionId!: string;
  limit1StreetId!: string;
  limit2IntersectionId!: string;
  limit2StreetId!: string;
  locationType!: number;
  longitude!: number;
  nextEndOffset!: number;
  nextLHRS!: number;
  nextStartOffset!: number;
  overwriteAutoGenerateDescription!: boolean;
  priorEndOffset!: number;
  priorLHRS!: number;
  priorStartOffset!: number;
  reviewNote!: string;
  roadSegmentMIS: any;
  signalWarrantsPredictionSettings: any;
  simplifiedLocation: any;
  siteSubTypeId!: string;
  startOffset!: number;
  street1Id!: string;
  street2Id!: string;
  street3Id!: string;
  street4Id!: string;
  streetDirection1!: number;
  streetDirection2!: number;
  streetId!: string;
  tesId!: string;
  trafficSectionId!: string;
  volumeInheritType!: number;
  xCoord!: number;
  yCoord!: number;
}

export class RoadSegmentDescription {
  streetId!: string;
  limit1StreetId!: string;
  limit2StreetId!: string;
}

export interface IRoadSegmentParams {
  [key: string]: any;
  roadSegmentId: string;
}

export interface IMISParams {
  [key: string]: any;
  misId: string;
}

export interface ISectionParams {
  [key: string]: any;
  sectionId: string;
}

export interface IStationParams {
  [key: string]: any;
  stationId: string;
}

export class TesCodeValue {
  municipalities!: INameId[];
  jurisdictions!: INameId[];
}

export class MunicipalityPopulation {
  id!: string;
  customerId!: string;
  municipalityId!: string;
  population!: number;
}

export class FieldDataSource {
  constructor() {
    this.lstColumnMap = [];
  }
  id!: string;
  customerId!: string;
  dataSourceType!: DataSourceType;
  dataSourceName!: string;
  infrastructureType!: LocationType;
  lstColumnMap!: ColumnMap[];
  isApproachLayer!: boolean;
}

export class ColumnMap {
  id!: string;
  clientFieldName!: string;
  clientFieldIndex!: number;
  isForeignKey!: boolean;
  castType!: CastType;
  fieldId!: string;
  constantField!: string;
  dateTimeFormat!: string;
  matchByType!: MatchByType;
}

export interface IFiledDataSourceParams {
  [key: string]: any;
  fieldDataSourceId: string;
}

export class InfrastructureLoadDataReq {
  id?: string;
  customerId!: string;
  startDt?: string;
  note?: string;
  importReqStatus?: ImportReqStatus;
  lstFieldDataSourceAddress!: FieldDataSourceAddress[];
}

export class FieldDataSourceAddress {
  [key: string]: any;
  id?: string;
  fieldDataSourceId?: string;
  fieldDataSourceType?: DataSourceType;
  fileAddress?: string;
  note?: string;
  importReqStatus?: ImportReqStatus;
}

export class ImportReqStatus {
  Processing!: 1;
  Complete!: 2;
  Failed!: 3;
}

// ITesBase Interface
export interface ITesBase {
  customerId: string;
  divisionId?: string;
}

// CountStation Class
export class CountStation implements ITesBase {
  id!: string;
  customerId!: string;
  divisionId!: string;
  locationId!: string;
  name!: string;
  description!: string;
  installationDate!: Date;
  lhrs!: number;
  offset!: number;
  longitude!: number;
  latitude!: number;
  aadtPatternId!: string;
  countStationType!: CountStationType;
  general!: any;
  isDeleted!: boolean;
}

export interface ICountStationParams {
  [key: string]: any;
  stationId?: string;
}

export interface ITrafficSectionParams {
  [key: string]: any;
  areaId: string;
}

export class TrafficSection {
  constructor() {
    this.locations = [];
  }
  [key: string]: any;
  id?: string;
  tesId!: string;
  customerId!: string;
  divisionId!: string;
  name!: string;
  geoId!: string;
  lhrs!: number;
  offset!: number;
  nextTrafficSectionId?: string;
  previousTrafficSectionId?: string;
  overlapTrafficSectionId?: string;
  countStationId!: string;
  excludeMatchingTrending!: boolean;
  isNonAssumedRoad!: boolean;
  isBorderCrossing!: boolean;
  isOverLap!: boolean;
  isPDCS!: boolean;
  general!: any;
  locations!: Location[];
  // VolumeDatas!:TrafficSectionVolumeData[];
  isDeleted!: boolean;
}

export class SignalWarrantsPredictionSettings {
  northUserDefinedGrowth!: number;
  eastUserDefinedGrowth!: number;
  southUserDefinedGrowth!: number;
  westUserDefinedGrowth!: number;
}

export class MmsRequest {
  id!: string;
  customerId!: string;
  divisionId?: string;
  name!: string;
  description!: string;
  year!: number;
}

export class MmsRes {
  id!: string;
  mmsRequestId!: string;
  customerId!: string;
  divisionId?: string;
  locationId!: string;
  locationDescription!: string;
  geoId!: string;
  postedSpeed!: number;
  aadt!: number;
  mms!: number;
  status!: MMSResStatus;
  rin!: string;
  smoothMMS!: number;
  mmsSmoothingStatus!: MMSSmoothingStatus;
}

export class BaseMapLayer {
  id!: string;
  url!: string;
  title!: string;
}

export enum MMSSmoothingStatus {
  None = 0,
  Manually = 1,
  CalculatedByMinAlgorithm = 2,
  FilledByGap = 3,
}

export class DetailedMMSRes {
  rin!: string;
  smoothMMS!: number;
  mmsSmoothingStatus!: number;
  mmsResultDetails!: Omit<MmsRes, "rin" | "smoothMMS" | "mmsSmoothingStatus">[];
}

export class AdjustmentFactor {
  id!: string;
  pcsId!: string;
  aadt!: number;
  year!: number;
  totalVolume!: number;
  noSupplementedDay!: number;
  totalVolume4Supplemented!: number;
  totalVolume4Original!: number;
  noOriginalDay!: number;
  dhvPercent!: number;
  seasonalFactor!: SeasonalAdjustmentFactor;
  monthlyAdjustmentFactors!: MonthlyAdjustmentFactor[];
  dayOfWeekAdjustmentFactors!: DayOfWeekAdjustmentFactor[];
  hourAdjustmentFactors!: HourAdjustmentFactor[];
}

export class SeasonalAdjustmentFactor {
  id!: string;
  sadt!: number;
  wadt!: number;
  factorSadt!: number;
  factorSawdt!: number;
  factorJulAdt!: number;
  factorJulAwdt!: number;
  factorAugAdt!: number;
  factorAugAwdt!: number;
  factorWadt!: number;
}

export class MonthlyAdjustmentFactor {
  id!: string;
  monthOfYear!: Month;
  firstHalfAdtFactor!: number;
  secondHalfAdtFactor!: number;
  firstHalfAwdFactor!: number;
  secondHalfAwdFactor!: number;
  fullMonthAdtFactor!: number;
  fullMonthAwdFactor!: number;

  firstHalfAdt!: number;
  secondHalfAdt!: number;
  firstHalfAwd!: number;
  secondHalfAwd!: number;
  fullMonthAdt!: number;
  fullMonthAwd!: number;
}

export class DayOfWeekAdjustmentFactor {
  id!: string;
  dayOfWeek!: DayOfWeek;
  dayOfWeekAdt!: number;
  dayOfWeekAdtFactor!: number;
}

export class HourAdjustmentFactor {
  id!: string;
  hour!: number;
  hourFactor!: number;
}

export class CountStationDirSplit {
  id!: string;
  countStationId!: string;
  customerId!: string;
  year!: number;
  studyId!: string;
  studyType!: StudyType;
  splitPercentage!: number;
  approachWithHigherVolume!: ApproachType;
  status!: DirSplitCalculationStatus;
  note!: string;
}
