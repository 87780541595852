//imports
import {
  ContextMenu,
  Form,
  NumberBox,
  Popup,
  ScrollView,
  TextBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import { SimpleItem } from "devextreme-react/cjs/form";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import {
  TMSPermissions,
  TrafficStudyPermissions,
} from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import {
  GridType,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { MatchingProject } from "../../../../types/trafficStudy/trafficStudyTypes";
import { OnExporting } from "../../../../utils/dataGridTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const MatchingProjects = () => {
  const [initData, setInitData] = useState<MatchingProject[]>([]);
  const [selectedData, setSelectedData] = useState<MatchingProject>(
    new MatchingProject()
  );
  const [showModal, setShowModal] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useBrowserTitle({ pathname: "/trafficStudy/tools/matchingProjects" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      const res = (await TesGet(
        TrafficApiUrl() +
          "/api/Matching/GetCustomerMatchingProject/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )) as RequestResponseResult<MatchingProject[]>;
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("defaultDivisionId")!,
      };
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Matching/AddNewProject",
        postObj,
        true
      )) as RequestResponseResult<any>;
      if (res.responseCode === ResponseCode.OK) {
        await getInitData();
        dataGridRef?.current?.instance().refresh();
        onCloseModal();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (er) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(
        `/trafficStudy/tools/matchingProjectDetails/${e.data.id}`,
        "_blank"
      );
    } else {
      history(`/trafficStudy/tools/matchingProjectDetails/${e.data.id}`);
    }
  }

  function onCloseModal() {
    setSelectedData(new MatchingProject());
    setShowModal(false);
  }

  function onNew() {
    setSelectedData(new MatchingProject());
    setShowModal(true);
  }

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.key.id,
                "/trafficStudy/tools/matchingProjectDetails"
              )
            }
          />
          <h2 className={"content-block"}>{t("matchingProject")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("matchingProject")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.MatchingProject.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="year" caption={t("year")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("add")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("name")}
                    labelMode="floating"
                    value={selectedData?.name}
                    onValueChange={(e) =>
                      setSelectedData({ ...selectedData, name: e })
                    }
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    label={t("year")}
                    labelMode="floating"
                    value={selectedData.year}
                    onValueChange={(e) =>
                      setSelectedData({ ...selectedData, year: e })
                    }
                    className="modalInput"
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn" style={{ marginTop: "1.5rem" }}>
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default MatchingProjects;
