import React, { useEffect } from "react";
import { Form, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import {
  LocationPickerMode,
  LocationType,
} from "../../../../../types/infrastructure/enums/infrastructureEnums";
import InfrastructureLocationPicker from "../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { TesMapModule } from "../../../../../types/general/enums/generalEnums";
import { VMMapLocation } from "../../../../../types/collision/dto/collisionDtos";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { SignalWarrantsPredictionResult } from "../../../../../types/systematicScreening/systematicScreeningTypes";

// props
interface IPros {
  initData: SignalWarrantsPredictionResult;
  didMount: boolean;
  renderMap: boolean;
  tesModule: TesMapModule;
  componentMode: LocationPickerMode;
}

const Map = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLocationChange = useCallback(async (loc: VMMapLocation) => {
  //     props.setInitDataBase({ ...props.initDataBase })

  //   }, []);

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2}>
          <SimpleItem colSpan={2}>
            <InfrastructureLocationPicker
              didMount={props.didMount}
              renderMap={props.renderMap}
              initLocationData={{
                customerId: localStorage.getItem(
                  "selectedCustomerId"
                ) as string,
                id: props.initData.locationId,
                description: props.initData.locationDescription,
                geoId: props.initData.geoId,
                locationType: LocationType.Intersection,
              }}
              tesModule={TesMapModule.StudyDetails}
              componentMode={LocationPickerMode.TextBox}
              renderCoordinate={false}
              locationType={LocationType.Intersection}
              showIcons={true}
              isLocked={true}
            />
          </SimpleItem>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default Map;
