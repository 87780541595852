import React, { createContext, PropsWithChildren, useContext } from "react";
import TranslationHelperDialog from "./components/TranslationHelperDialog";
import { useTranslationHelperWidget } from "./useTranslationHelperWidget";
import { GetTranslationDto } from "../../../types/translation/dtos/translationDtos";
import { useTranslations } from "../index";

const TranslationHelperContext = createContext({
  toggleFeature: () => {},
  isFeatureActive: false,
});
const useTranslationHelper = () => useContext(TranslationHelperContext);

function TextCaptureProvider({ children }: PropsWithChildren) {
  const { reFetchTranslations, isTranslationLoading } = useTranslations();
  const {
    selectedText,
    toggleFeature,
    isFeatureActive,
    isModalOpen,
    closeModal,
    isLoading,
    translations,
    errorText,
    selectedLanguageDetails,
    reFetch,
  } = useTranslationHelperWidget();

  const extractSubstrings = (text: string) => {
    const words = text.split(" ");
    const substrings = new Set();

    for (let i = 0; i < words.length; i++) {
      let currentString = "";
      for (let j = i; j < words.length; j++) {
        currentString = currentString
          ? `${currentString} ${words[j]}`
          : words[j];
        substrings.add(currentString);
      }
    }

    return Array.from(substrings) as string[];
  };

  const extractExistingTranslations = (
    substrings: string[]
  ): GetTranslationDto[] => {
    const result: GetTranslationDto[] = [];

    for (let i = 0; i < substrings.length; i++) {
      const substring = substrings[i];
      if (translations && translations.length > 0) {
        for (let j = 0; j < translations.length; j++) {
          if (translations[j].value.toLowerCase() === substring.toLowerCase()) {
            result.push(translations[j]);
          }
        }
      }
    }
    return result;
  };

  const onEditSucceed = () => {
    reFetch();
  };
  const onRefreshClick = () => {
    reFetchTranslations();
  };

  const value = {
    toggleFeature,
    isFeatureActive,
  };

  return (
    <TranslationHelperContext.Provider value={value}>
      {children}
      <TranslationHelperDialog
        isLoading={isLoading}
        capturedText={selectedText}
        isOpen={isModalOpen}
        onClose={closeModal}
        errorText={errorText}
        items={extractExistingTranslations(extractSubstrings(selectedText))}
        onEditSucceed={onEditSucceed}
        selectedLanguageDetails={selectedLanguageDetails}
        onRefresh={onRefreshClick}
        isRefreshLoading={isTranslationLoading}
      />
    </TranslationHelperContext.Provider>
  );
}
export { TextCaptureProvider, useTranslationHelper };
