import React from "react";
import { Form, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { ClientModule } from "../../../../../types/moduleOfModules/moduleOfModulesTypes";
import { TextBox } from "devextreme-react";

// props
interface IPros {
  initDataModule: ClientModule;
  setInitDataModule: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();

  function onValueChange(name: string, value: any) {
    props.setInitDataModule({ ...props.initDataModule, [name]: value });
    props.setDataChanged(true);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2}>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("name")}
              labelMode="floating"
              value={props.initDataModule.name}
              onValueChange={(e) => onValueChange("name", e)}
              disabled={props.isLocked}
            />
          </SimpleItem>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default Details;
