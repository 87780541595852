import { useState, useEffect } from "react";
import { GetTranslationDto } from "../../../types/translation/dtos/translationDtos";
import {
  fetchTranslationsByLangId,
  getStoredTranslations,
  getStoredTranslationsHelperWidgetState,
  storeTranslationsHelperWidgetState,
} from "../utils";
import { TTranslation, useTranslations } from "../index";

export function useTranslationHelperWidget() {
  const [selectedText, setSelectedText] = useState("");
  const [isFeatureActive, setIsFeatureActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [translations, setTranslations] = useState<GetTranslationDto[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>("");
  const [selectedLanguageDetails, setSelectedLanguageDetails] =
    useState<TTranslation>();

  const { selectedLanguage, isTranslationLoading } = useTranslations();

  const getTranslationsData = async () => {
    setIsLoading(true);
    const translationsJSON = getStoredTranslations();
    const translationJSON = translationsJSON.find(
      (item) => item.languageCode === selectedLanguage
    );
    if (!translationJSON) return;
    setSelectedLanguageDetails(translationJSON);
    const res = await fetchTranslationsByLangId(
      translationJSON.languageType,
      translationJSON.id
    );
    if (res) {
      setIsLoading(false);
      setTranslations(res);
      setErrorText("");
    } else {
      setIsLoading(false);
      setErrorText("Error occurred while fetching translations");
      // await RequestErrorHandling(res);
    }
  };

  useEffect(() => {
    if (!isFeatureActive || isModalOpen || isTranslationLoading) return;

    const handleClick = (e: any) => {
      if (
        e.target.classList.contains("translationHelper") ||
        e.target.closest(".translationHelper") ||
        (!e.ctrlKey && !e.metaKey)
      ) {
        return;
      }

      e.stopPropagation();

      if (e.target && e.target.innerText) {
        getTranslationsData();
        setSelectedText(e.target.innerText.trim());
        setIsModalOpen(true); // Open the modal and disable the listener
      }
    };

    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [isFeatureActive, isModalOpen, isTranslationLoading]);

  useEffect(() => {
    const storedState = getStoredTranslationsHelperWidgetState();
    if (storedState) {
      setIsFeatureActive(storedState);
    }
  }, []);

  const toggleFeature = () => {
    setIsFeatureActive((prev) => {
      storeTranslationsHelperWidgetState(!prev);
      return !prev;
    });
  };

  const closeModal = () => {
    setSelectedText("");
    setIsModalOpen(false);
  };

  const reFetch = () => {
    getTranslationsData();
  };

  return {
    selectedText,
    isFeatureActive,
    toggleFeature,
    closeModal,
    isModalOpen,
    isLoading,
    translations,
    errorText,
    selectedLanguageDetails,
    reFetch,
  };
}
