import { Form, SelectBox, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DateBox from "devextreme-react/date-box";
import { SimpleItem } from "devextreme-react/form";
import { Switch } from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import StreetValuePicker from "../../../../../components/streetValuePicker/streetValuePicker";
import { useAuth } from "../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import {
  FieldCategoryType,
  FieldPermission,
  FieldType,
} from "../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../types/field/fieldType";
import { PageMode } from "../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../types/general/generalTypes";
import { StreetDirection } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { Location } from "../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { DatePickerDateCalculator } from "../../../../../utils/dateTimeTools";
import { Enum2Array } from "../../../../../utils/enumTools";

// props
interface IPros {
  location: Location;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  pageMode: number;
  validationRef: React.RefObject<ValidationGroupRef>;
  initDataFields: TesField[] | undefined;
  generalSettings: GeneralSetting | null;
}

const Details = (props: IPros) => {
  const [lstStreetDirection, setLstStreetDirection] = useState<NameValue[]>([]);
  const { activeLoading } = useAuth();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onChangeLocationFields(
    name: string,
    value: any,
    isUpperCase: boolean
  ) {
    props.setLocation({
      ...props.location,
      [name]: isUpperCase ? value.toUpperCase() : value,
    });
    if (props.pageMode === PageMode.Update) {
      props.setDataChanged(true);
    }
  }

  function onChangeFields(name: string, value: any, isUpperCase: boolean) {
    console.log(name, value);
    props.setLocation({
      ...props.location,
      general: {
        ...props.location.general,
        [name]: isUpperCase ? value.toUpperCase() : value,
      },
    });
    if (props.pageMode === PageMode.Update) {
      props.setDataChanged(true);
    }
  }

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstStreetDirection(Enum2Array(StreetDirection));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`intersectionDetails-details ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <ValidationGroup ref={props.validationRef}>
            <Form colCount={2}>
              <SimpleItem colSpan={1}>
                <StreetValuePicker
                  label={t("street1")}
                  name="street1Id"
                  value={props.location.street1Id}
                  onChangeFields={onChangeLocationFields}
                  isLocked={props.isLocked}
                  isTextBox={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <StreetValuePicker
                  label={t("Street 2")}
                  name="street2Id"
                  value={props.location.street2Id}
                  onChangeFields={onChangeLocationFields}
                  isLocked={props.isLocked}
                  isTextBox={true}
                />
              </SimpleItem>
              {props.location.street3Id !== null && (
                <SimpleItem colSpan={1}>
                  <StreetValuePicker
                    label={t("Street 3")}
                    name="street3Id"
                    value={props.location.street3Id}
                    onChangeFields={onChangeLocationFields}
                    isLocked={props.isLocked}
                    isTextBox={true}
                  />
                </SimpleItem>
              )}
              {props.location.street4Id !== null && (
                <SimpleItem colSpan={1}>
                  <StreetValuePicker
                    label={t("Street 4")}
                    name="street4Id"
                    value={props.location.street4Id}
                    onChangeFields={onChangeLocationFields}
                    isLocked={props.isLocked}
                    isTextBox={true}
                  />
                </SimpleItem>
              )}
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  items={SortObjectByPropName(lstStreetDirection, "name")}
                  label={t("streetDirection1")}
                  labelMode="floating"
                  displayExpr="name"
                  valueExpr="value"
                  value={props.location.streetDirection1}
                  onValueChange={(e) =>
                    onChangeLocationFields("streetDirection1", e, false)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  items={SortObjectByPropName(lstStreetDirection, "name")}
                  label={t("streetDirection2")}
                  labelMode="floating"
                  displayExpr="name"
                  valueExpr="value"
                  value={props.location.streetDirection2}
                  onValueChange={(e) =>
                    onChangeLocationFields("streetDirection2", e, false)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>

              {props.initDataFields &&
                props.initDataFields
                  .filter(
                    (x) =>
                      x.fieldCategoryType ===
                      FieldCategoryType.InfrastructureIntersectionDetails
                  )
                  .map((f, i) => {
                    if (f.fieldType === FieldType.String) {
                      return (
                        <SimpleItem colSpan={1}>
                          <TextBox
                            style={{ marginBottom: 20 }}
                            label={f.labelText}
                            labelMode="floating"
                            value={
                              props.location.general &&
                              props.location.general[f.name]
                            }
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            key={f.id}
                            name={f.name}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                          />
                        </SimpleItem>
                      );
                    }
                    if (
                      f.fieldType === FieldType.Number ||
                      f.fieldType === FieldType.DecimalNumber ||
                      f.fieldType === FieldType.DoubleNumber
                    ) {
                      return (
                        <SimpleItem colSpan={1}>
                          <TextBox
                            style={{ marginBottom: 20 }}
                            label={f.labelText}
                            labelMode="floating"
                            value={
                              props.location.general &&
                              props.location.general[f.name]
                            }
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            key={f.id}
                            name={f.name}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                          />
                        </SimpleItem>
                      );
                    }

                    if (f.fieldType === FieldType.Boolean) {
                      return (
                        <SimpleItem colSpan={1}>
                          <div style={{ fontWeight: "bold" }}>
                            {f.labelText}
                          </div>
                          <Switch
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            value={
                              props.location.general &&
                              props.location.general[f.name]
                            }
                            key={f.id}
                            name={f.name}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                          />
                        </SimpleItem>
                      );
                    }

                    if (f.fieldType === FieldType.Date) {
                      return (
                        <SimpleItem colSpan={1}>
                          <DateBox
                            label={f.labelText}
                            value={
                              f.exactDT === true
                                ? DatePickerDateCalculator(
                                    props.location.general[f.name]
                                  )
                                : props.location.general[f.name]
                            }
                            dateSerializationFormat={
                              f.exactDT === true
                                ? "yyyy-MM-ddTHH:mm:ss"
                                : undefined
                            }
                            type="date"
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            key={f.id}
                            name={f.name}
                            pickerType="calendar"
                            placeholder={props.generalSettings?.dateFormat}
                            displayFormat={props.generalSettings?.dateFormat}
                            useMaskBehavior={true}
                            openOnFieldClick={true}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                            showClearButton={true}
                          />
                        </SimpleItem>
                      );
                    }

                    if (f.fieldType === FieldType.Time) {
                      return (
                        <SimpleItem colSpan={1}>
                          <DateBox
                            label={f.labelText}
                            value={
                              f.exactDT === true
                                ? DatePickerDateCalculator(
                                    props.location.general[f.name]
                                  )
                                : props.location.general[f.name]
                            }
                            dateSerializationFormat={
                              f.exactDT === true
                                ? "yyyy-MM-ddTHH:mm:ss"
                                : undefined
                            }
                            type="time"
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            key={f.id}
                            name={f.name}
                            pickerType="rollers"
                            placeholder={props.generalSettings?.timeFormat}
                            displayFormat={props.generalSettings?.timeFormat}
                            useMaskBehavior={true}
                            openOnFieldClick={true}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                            showClearButton={true}
                          />
                        </SimpleItem>
                      );
                    }

                    if (f.fieldType === FieldType.DateTime) {
                      return (
                        <SimpleItem colSpan={1}>
                          <DateBox
                            label={f.labelText}
                            value={
                              f.exactDT === true
                                ? DatePickerDateCalculator(
                                    props.location.general[f.name]
                                  )
                                : props.location.general[f.name]
                            }
                            dateSerializationFormat={
                              f.exactDT === true
                                ? "yyyy-MM-ddTHH:mm:ss"
                                : undefined
                            }
                            type="datetime"
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            key={f.id}
                            name={f.name}
                            pickerType="calendar"
                            placeholder={props.generalSettings?.dateTimeFormat}
                            displayFormat={
                              props.generalSettings?.dateTimeFormat
                            }
                            useMaskBehavior={true}
                            openOnFieldClick={true}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                            showClearButton={true}
                          />
                        </SimpleItem>
                      );
                    }

                    if (f.fieldType === FieldType.List) {
                      return (
                        <SimpleItem colSpan={1}>
                          <SelectBox
                            placeholder=""
                            label={f.labelText}
                            labelMode="floating"
                            items={SortObjectByPropName(f.fieldValues, "name")}
                            displayExpr="name"
                            valueExpr="id"
                            value={
                              props.location.general &&
                              props.location.general[f.name]
                            }
                            onValueChange={(e) =>
                              onChangeFields(f.name, e, true)
                            }
                            key={f.id}
                            name={f.name}
                            disabled={
                              props.isLocked ||
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            }
                            showClearButton={true}
                            searchEnabled={true}
                          />
                        </SimpleItem>
                      );
                    }
                  })}
            </Form>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default Details;
