import {
  Button,
  Chart,
  CheckBox,
  ContextMenu,
  DataGrid,
  NumberBox,
  Popup,
  TagBox,
  TextBox,
} from "devextreme-react";
import {
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Point,
  Series,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Editing,
  Export as ExportOption,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CollapsibleCard from "../../../../components/collapsibleCard/collapsibleCard";
import ShareURL from "../../../../components/shareURL/shareURL";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import {
  GridType,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import {
  NameValue,
  NameValueNumber,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import { AADTPredictionStatus } from "../../../../types/trafficStudy/enums/importEnums";
import {
  AADTPredictionProjectType,
  AADTQualityFactor,
  AADTSourceType,
} from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import {
  AADTPredictionProject,
  AADTPredictionProjectDetail,
  AADTPredictionProjectDetailResult,
  AADTPredictionUpdateReq,
  AADTPredictionYearResult,
  GetProjectDetailRequest,
  IAADTPredictionParams,
} from "../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import design from "./aadtPredictDetails.module.scss";
import Permission from "../../../../components/permission/permision";
import TrafficStudyPermissions from "../../../../constants/Permissions/trafficStudyPermissions";

const AADTPredictionDetails = () => {
  const [projectDetailRequest, setProjectDetailRequest] =
    useState<GetProjectDetailRequest>(new GetProjectDetailRequest());
  const [initData, setInitData] = useState<AADTPredictionProjectDetail>(
    new AADTPredictionProjectDetail()
  );
  const [popupGridData, setPopupGridData] = useState<
    AADTPredictionYearResult[]
  >([]);
  const [popupTitle, setPopupTitle] = useState<string>("");
  const [selectedocationId, setSelectedocationId] = useState<string>("");
  const currentYear = new Date().getFullYear();
  const [showModal, setShowModal] = useState<boolean>(false);
  const params = useParams<IAADTPredictionParams>();
  const [isLocked, setIsLocked] = useState(true);
  const [lastNyears, setlastNYears] = useState<number[]>([]);
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const modalDataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const didMount = useRef(false);
  const last15Years: NameValueNumber[] = Array.from(
    { length: 15 },
    (_, index) => {
      const year = currentYear - index;
      return { name: year.toString(), value: year };
    }
  );
  const next15Years: NameValueNumber[] = Array.from(
    { length: 15 },
    (_, index) => {
      const year = currentYear - 10 + index;
      return { name: year.toString(), value: year };
    }
  );
  const [lstCurrentSourceType, setLstCurrentSourceType] = useState<NameValue[]>(
    []
  );
  const [lstAADTQualityFactor, setLstAADTQualityFactor] = useState<NameValue[]>(
    []
  );
  const [lstAADTPredictionStatus, setLstAADTPredictionStatus] = useState<
    NameValue[]
  >([]);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [selectedRowDataForPopup, setSelectedRowDataForPopup] =
    useState<any>(null);
  const history = useNavigate();
  const [fieldsDataChanged, setFieldsDataChanged] = useState(false);
  const [finalValueChanged, setFinalValueChanged] = useState(false);

  const [predChartVisible, setPredChartVisible] = useState(true);
  const [currentChartVisible, setCurrentChartVisible] = useState(true);
  const [finalChartVisible, setFinalChartVisible] = useState(false);

  const [intersectionTotalGroup, setIntersectionTotalGroup] = useState(true);
  const [intersectionMajorGroup, setIntersectionMajorGroup] = useState(false);
  const [intersectionMinorGroup, setIntersectionMinorGroup] = useState(false);
  const location = useLocation();

  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          var yearRes = [];
          for (
            let year = currentYear - projectDetailRequest.showDataForLastNYear;
            year <= currentYear;
            year++
          ) {
            yearRes.push(year);
          }
          setlastNYears(yearRes);
          setLstCurrentSourceType(Enum2Array(AADTSourceType));
          setLstAADTQualityFactor(Enum2Array(AADTQualityFactor));
          setLstAADTPredictionStatus(Enum2Array(AADTPredictionStatus));

          if (params.AADTPredictionId !== "AddNew") {
            await getInitData();
          } else {
            setIsLocked(false);
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      if (activeLoading) activeLoading(true);
      var postOb: GetProjectDetailRequest = {
        ...projectDetailRequest,
        projectId: params.AADTPredictionId!,
      };
      var res = (await TesPost(
        TrafficApiUrl() + "/api/AADTPrediction/GetAADTPredictionProjectDetails",
        postOb,
        true
      )) as RequestResponseResult<AADTPredictionProjectDetail>;
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        if (activeLoading) activeLoading(false);
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function onPredictionSettingChange(name: string, value: any) {
    setInitData((prevData) => {
      if (!prevData) return prevData;
      const updatedProject = { ...prevData.project };
      updatedProject.predictionSettings = {
        ...updatedProject.predictionSettings,
        [name]: value,
      };
      setFieldsDataChanged(true);
      return { ...prevData, project: updatedProject };
    });
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  function onRowClick(selectedRowData: any) {
    // setPopupGridData(Object.values(selectedRowData.data.aadTs))
    var lstFinalRes: AADTPredictionYearResult[] = [];
    for (const [year, value] of Object.entries(selectedRowData.data.aadTs)) {
      var res: AADTPredictionYearResult = {
        currentSourceType: selectedRowData.data.aadTs[year].currentSourceType,
        currentValue: selectedRowData.data.aadTs[year].currentValue,
        finalValue: selectedRowData.data.aadTs[year].finalValue,
        message: selectedRowData.data.aadTs[year].message,
        predictedSourceType:
          selectedRowData.data.aadTs[year].predictedSourceType,
        predictionValue: selectedRowData.data.aadTs[year].predictionValue,
        qualityFactor: selectedRowData.data.aadTs[year].qualityFactor,
        year: selectedRowData.data.aadTs[year].year,
        currentMajorValue: selectedRowData.data.aadTs[year].currentMajorValue,
        currentMinorValue: selectedRowData.data.aadTs[year].currentMinorValue,
        finalMajorValue: selectedRowData.data.aadTs[year].finalMajorValue,
        finalMinorValue: selectedRowData.data.aadTs[year].finalMinorValue,
        predictionMajorValue:
          selectedRowData.data.aadTs[year].predictionMajorValue,
        predictionMinorValue:
          selectedRowData.data.aadTs[year].predictionMinorValue,
        status: selectedRowData.data.aadTs[year].status,
        isChanged: selectedRowData.data.aadTs[year].isChanged,
        currentNorthValue: selectedRowData.data.aadTs[year].currentNorthValue,
        predictionNorthValue:
          selectedRowData.data.aadTs[year].predictionNorthValue,
        currentEastValue: selectedRowData.data.aadTs[year].currentEastValue,
        predictionEastValue:
          selectedRowData.data.aadTs[year].predictionEastValue,
        currentSouthValue: selectedRowData.data.aadTs[year].currentSouthValue,
        predictionSouthValue:
          selectedRowData.data.aadTs[year].predictionSouthValue,
        currentWestValue: selectedRowData.data.aadTs[year].currentWestValue,
        predictionWestValue:
          selectedRowData.data.aadTs[year].predictionWestValue,
      };
      lstFinalRes.push(res);
    }
    setPopupGridData(lstFinalRes);
    setPopupTitle(
      (initData.project.projectType === AADTPredictionProjectType.Intersection
        ? t("intersection")
        : t("roadSegment")) +
        ": " +
        selectedRowData.data.locationDescription
    );
    setSelectedocationId(selectedRowData.data.locationId);
    setShowModal(true);
  }

  function onCloseModal(): void {
    dataGridRef.current?.instance().refresh();
    setShowModal(false);
  }

  function convertToObjectArray(inputObject: any) {
    const valuesArray = Object.values(inputObject);
    return valuesArray;
  }

  function onPopupSave(): void {
    var res = {};
    var lstRowData = modalDataGridRef.current
      ?.instance()
      .getDataSource()
      .items()! as AADTPredictionYearResult[];
    lstRowData.forEach((r) => {
      var obj: AADTPredictionYearResult = {
        currentSourceType: r.currentSourceType,
        currentValue: r.currentValue,
        finalValue: r.finalValue,
        message: r.message,
        predictedSourceType: r.predictedSourceType,
        predictionValue: r.predictionValue,
        qualityFactor: r.qualityFactor,
        year: r.year,
        currentMajorValue: r.currentMajorValue,
        currentMinorValue: r.currentMinorValue,
        finalMajorValue: r.finalMajorValue,
        finalMinorValue: r.finalMinorValue,
        predictionMajorValue: r.predictionMajorValue,
        predictionMinorValue: r.predictionMinorValue,
        currentNorthValue: r.currentNorthValue,
        predictionNorthValue: r.predictionNorthValue,
        currentEastValue: r.currentEastValue,
        predictionEastValue: r.predictionEastValue,
        currentSouthValue: r.currentSouthValue,
        predictionSouthValue: r.predictionSouthValue,
        currentWestValue: r.currentWestValue,
        predictionWestValue: r.predictionWestValue,
        status: r.status,
        isChanged:
          (r.predictionValue > 0 && r.finalValue !== r.predictionValue) ||
          (r.predictionValue === 0 && r.finalValue !== r.currentValue) ||
          (r.currentValue === 0 && r.finalValue > 0 && r.predictionValue < 0) ||
          (r.predictionMajorValue > 0 &&
            r.finalMajorValue !== r.predictionMajorValue) ||
          (r.predictionMinorValue > 0 &&
            r.finalMinorValue !== r.predictionMinorValue) ||
          (r.predictionMajorValue <= 0 &&
            r.finalMajorValue !== r.currentMajorValue) ||
          (r.predictionMinorValue <= 0 &&
            r.finalMinorValue !== r.currentMinorValue)
            ? true
            : false,
      };
      res = { ...res, [r.year.toString()]: obj };
    });

    var index = initData?.results.findIndex(
      (x) => x.locationId === selectedocationId
    );
    if (index !== undefined) {
      var newRes = initData?.results!;
      newRes[index] = {
        ...newRes[index]!,
        aadTs: { ...newRes[index].aadTs, ...res },
      };
      setInitData({ ...initData!, results: newRes });
      dataGridRef.current?.instance().refresh();
      setFinalValueChanged(
        convertToObjectArray(newRes[index].aadTs).some(
          (obj: any) =>
            typeof obj === "object" && (obj as any).isChanged === true
        )
      );
    }
    setShowModal(false);
  }

  const onProjectValuesChange = (key: string, value: any) => {
    setInitData({
      ...initData!,
      project: { ...initData!.project, [key]: value },
    });
    setFieldsDataChanged(true);
  };

  async function onRun() {
    try {
      if (activeLoading) activeLoading(true);
      const postObj: AADTPredictionProject = {
        ...initData?.project!,
        customerId: localStorage.getItem("selectedCustomerId")!,
        projectType: params.ProjectType!,
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/AADTPrediction/Run",
        postObj,
        true
      )) as RequestResponseResult<any>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setFieldsDataChanged(false);
        setFinalValueChanged(false);
        notify(t("analysisMessage"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
      history(-1);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function showDataForLastNYearSetter(e: number) {
    try {
      if (activeLoading) activeLoading(true);
      var postOb: GetProjectDetailRequest = {
        ...projectDetailRequest,
        showDataForLastNYear: e,
      };
      var res = (await TesPost(
        TrafficApiUrl() + "/api/AADTPrediction/GetAADTPredictionProjectDetails",
        postOb,
        true
      )) as RequestResponseResult<AADTPredictionProjectDetail>;
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
        setProjectDetailRequest(postOb);
      } else {
        if (activeLoading) activeLoading(false);
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }

    setProjectDetailRequest({
      ...projectDetailRequest,
      showDataForLastNYear: e,
    });
  }

  async function onUpdate() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowsData();
    if (!gridData || gridData?.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    }
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("overwriteAADTMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            await update();
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function update() {
    var gridData = await dataGridRef.current?.instance().getSelectedRowsData();

    var lstData = gridData as AADTPredictionProjectDetailResult[];

    var lstPostData: AADTPredictionUpdateReq[] = [];
    lstData.forEach((loc) => {
      for (const [year, value] of Object.entries(loc.aadTs)) {
        var curretnAADT = loc.aadTs[Number.parseInt(year)];
        if (
          curretnAADT?.isChanged === true ||
          (curretnAADT?.predictionValue ?? 0) > 0
        ) {
          var newData: AADTPredictionUpdateReq = {
            sourceType:
              curretnAADT?.isChanged === true
                ? AADTSourceType.UserModifiedPermanent
                : curretnAADT?.predictedSourceType!,
            finalValue: curretnAADT?.finalValue!,
            finalMajorValue: curretnAADT?.finalMajorValue!,
            finalMinorValue: curretnAADT?.finalMinorValue!,
            locationId: loc.locationId,
            qualityFactor:
              curretnAADT?.qualityFactor ?? AADTQualityFactor.NotFound,
            Year: Number.parseInt(year),
            customerId: initData?.project.customerId!,
            projectType: initData.project.projectType,
          };
          lstPostData.push(newData);
        }
      }
    });

    try {
      if (activeLoading) activeLoading(true);

      var res = (await TesPost(
        TrafficApiUrl() + "/api/AADTPrediction/UpdateAADTs",
        lstPostData,
        true
      )) as RequestResponseResult<AADTPredictionProjectDetail>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        setFieldsDataChanged(false);
        setFinalValueChanged(false);
      }
    } catch (ex) {
      notify(t("someErrorOccurred" + ex), "error", 5000);
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (fieldsDataChanged || finalValueChanged) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.AADTPredictionId !== "AddNew") {
                  onUpdate();
                } else {
                  onRun();
                }
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function customizeArgumentAxisLabels({ value }: { value: any }) {
    return value;
  }

  function go2Location() {
    if (
      initData.project.projectType === AADTPredictionProjectType.RoadSegment
    ) {
      window.open(
        "/infrastructure/roadSegmentDetails/" +
          selectedRowDataForPopup.data.locationId,
        "_blank"
      );
    } else {
      window.open(
        "/infrastructure/intersectionDetails/" +
          selectedRowDataForPopup.data.locationId,
        "_blank"
      );
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_E_AadtPrediction,
        TrafficStudyPermissions.TrafficStudy_V_AadtPrediction,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={(e) =>
            RightClickMenu(
              e,
              selectedRowData.row.data.locationId,
              initData.project.projectType ===
                AADTPredictionProjectType.Intersection
                ? "/infrastructure/intersectionDetails/"
                : "/infrastructure/roadSegmentDetails/"
            )
          }
        />
        <h2 className={"content-block"}>{t("aadtPrediction")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={goBackPermission}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <Permission
                allowed={[
                  TrafficStudyPermissions.TrafficStudy_E_AadtPrediction,
                ]}
                hasFeedBackElement={false}
              >
                <div className="rightColumn">
                  {params.AADTPredictionId !== "AddNew" && (
                    <ShareURL url={url} />
                  )}
                  {params.AADTPredictionId !== "AddNew" && (
                    <Button
                      onClick={() => LockHandler()}
                      icon={
                        isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                      }
                      hint={isLocked ? t("unlock") : t("lock")}
                    />
                  )}
                </div>
              </Permission>
              <Permission
                allowed={[
                  TrafficStudyPermissions.TrafficStudy_E_AadtPrediction,
                ]}
                hasFeedBackElement={false}
              >
                <div className="rightColumn">
                  <Button
                    onClick={onRun}
                    icon="fa-solid fa-play"
                    hint={t("run")}
                  />
                </div>
              </Permission>
            </div>
          </div>
          <div className={"dx-card "}>
            <div className="row" style={{ marginTop: 20, padding: 10 }}>
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <div style={{ marginTop: "-2rem" }}>
                    <CollapsibleCard title={t("aadtPredictionSettings")}>
                      <Form colCount={10}>
                        <SimpleItem colSpan={3}>
                          <TextBox
                            placeholder=""
                            label={t("title")}
                            labelMode="floating"
                            value={initData?.project?.title}
                            onValueChange={(e) =>
                              onProjectValuesChange("title", e)
                            }
                            disabled={isLocked}
                            showClearButton={true}
                            style={{ margin: "-0.75rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={7}>
                          <TextBox
                            placeholder=""
                            label={t("description")}
                            labelMode="floating"
                            value={initData?.project?.description}
                            onValueChange={(e) =>
                              onProjectValuesChange("description", e)
                            }
                            disabled={isLocked}
                            showClearButton={true}
                            style={{ margin: "-0.75rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={10}>
                          <hr style={{ marginTop: "-1.75rem" }} />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <NumberBox
                            label={t("growthFactor(eg2)")}
                            labelMode="floating"
                            value={
                              initData?.project.predictionSettings.growthFactor
                            }
                            onValueChange={(e) =>
                              onPredictionSettingChange("growthFactor", e)
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <NumberBox
                            label={t("maxVariationAADTInPercent(eg2)")}
                            labelMode="floating"
                            value={
                              initData?.project.predictionSettings
                                .maxVariationAADTInPercent
                            }
                            onValueChange={(e) =>
                              onPredictionSettingChange(
                                "maxVariationAADTInPercent",
                                e
                              )
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={6} />
                        <SimpleItem colSpan={10}>
                          <hr style={{ marginTop: "-1.75rem" }} />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <NumberBox
                            label={t("showDataForLastNYear")}
                            labelMode="floating"
                            value={projectDetailRequest.showDataForLastNYear}
                            onValueChange={(e) => showDataForLastNYearSetter(e)}
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={8} />
                        <SimpleItem colSpan={10}>
                          <hr style={{ marginTop: "-1.75rem" }} />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <NumberBox
                            label={t("fromYear")}
                            labelMode="floating"
                            value={
                              initData?.project.predictionSettings.fromYear
                            }
                            onValueChange={(e) =>
                              onPredictionSettingChange("fromYear", e)
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                            height={60}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <NumberBox
                            label={t("toYear")}
                            labelMode="floating"
                            value={initData?.project.predictionSettings.toYear}
                            onValueChange={(e) =>
                              onPredictionSettingChange("toYear", e)
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                            height={60}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <TagBox
                            label={t("predictionYears")}
                            labelMode="floating"
                            className="modalInput"
                            dataSource={SortObjectByPropName(
                              next15Years,
                              "name"
                            )}
                            value={
                              initData?.project.predictionSettings
                                .predictionYears
                            }
                            valueExpr="value"
                            displayExpr="name"
                            onValueChange={(e) =>
                              onPredictionSettingChange("predictionYears", e)
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <TagBox
                            label={t("excludeYears")}
                            labelMode="floating"
                            className="modalInput"
                            dataSource={SortObjectByPropName(
                              last15Years,
                              "name"
                            )}
                            value={
                              initData?.project.predictionSettings.excludeYears
                            }
                            valueExpr="value"
                            displayExpr="name"
                            onValueChange={(e) =>
                              onPredictionSettingChange("excludeYears", e)
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                          <TagBox
                            label={t("sourceTypesToUse")}
                            labelMode="floating"
                            className="modalInput"
                            dataSource={SortObjectByPropName(
                              lstCurrentSourceType,
                              "name"
                            )}
                            value={
                              initData?.project?.predictionSettings?.sourceTypes
                            }
                            valueExpr="value"
                            displayExpr="name"
                            onValueChange={(e) =>
                              onPredictionSettingChange("sourceTypes", e)
                            }
                            disabled={isLocked}
                            style={{ margin: "-2.5rem 0 0 0" }}
                          />
                        </SimpleItem>
                      </Form>
                    </CollapsibleCard>
                  </div>
                </SimpleItem>
                <SimpleItem colSpan={2}>
                  {initData.results && (
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridRef}
                      dataSource={initData?.results}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      onRowClick={(e) => {
                        onRowClick(e);
                        setSelectedRowDataForPopup(e);
                      }}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      style={{ margin: "0 1rem" }}
                      keyExpr="locationId"
                      onContextMenuPreparing={(e) => {
                        setSelectedRowData(e);
                      }}
                      onExporting={OnExporting}
                    >
                      <ExportOption
                        enabled={true}
                        allowExportSelectedData={true}
                      />
                      <Grouping
                        contextMenuEnabled={true}
                        autoExpandAll={false}
                      />
                      <GroupPanel visible={true} />
                      <FilterPanel visible={true} />
                      <FilterBuilderPopup position={"top"} />
                      <Summary>
                        <GroupItem
                          summaryType="count"
                          alignByColumn
                          name="Total Count"
                        />
                      </Summary>
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />
                      <FilterRow visible={true} applyFilter="auto" />
                      <HeaderFilter visible={true} />
                      <SearchPanel
                        visible={true}
                        width={285}
                        placeholder={t("search...")}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>
                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"always"}
                      />
                      <Column
                        alignment="left"
                        dataField="id"
                        allowSorting={true}
                        caption={t("id")}
                        visible={false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        dataField="locationId"
                        caption={t("locationId")}
                        visible={false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        dataField="locationDescription"
                        caption={t("locationDescription")}
                        width={275}
                        cssClass={design.VAlign}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        dataField="geoId"
                        caption={t("geoId")}
                        width={100}
                        cssClass={design.VAlign}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      {[
                        ...initData?.project?.predictionSettings
                          ?.predictionYears,
                        ...lastNyears,
                      ]
                        .filter(
                          (value, index, array) =>
                            array.indexOf(value) === index
                        )
                        .sort()
                        .map((y, index) => {
                          return (
                            <Column
                              dataField={y.toString()}
                              caption={y.toString()}
                              cssClass={
                                index % 2 === 0
                                  ? design.gradient0
                                  : design.gradient1
                              }
                            >
                              <Column
                                caption={t("current")}
                                cssClass={
                                  index % 2 === 0
                                    ? design.gradient0
                                    : design.gradient1
                                }
                              >
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.RoadSegment && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentValue"
                                    }
                                    caption={t("value")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentMajorValue"
                                    }
                                    caption={t("major")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentMinorValue"
                                    }
                                    caption={t("minor")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                <Column
                                  dataField={
                                    "aadTs." +
                                    y.toString() +
                                    "." +
                                    "currentSourceType"
                                  }
                                  caption={t("source")}
                                  width={120}
                                  cssClass={
                                    index % 2 === 0
                                      ? design.gradient0
                                      : design.gradient1
                                  }
                                >
                                  <Lookup
                                    dataSource={SortObjectByPropName(
                                      lstCurrentSourceType,
                                      "name"
                                    )}
                                    valueExpr="value"
                                    displayExpr="name"
                                  />
                                  <HeaderFilter>
                                    <Search enabled />
                                  </HeaderFilter>
                                </Column>
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentNorthValue"
                                    }
                                    caption={t("north")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentEastValue"
                                    }
                                    caption={t("east")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentSouthValue"
                                    }
                                    caption={t("south")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "currentWestValue"
                                    }
                                    caption={t("west")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                              </Column>

                              <Column
                                caption={t("predicted")}
                                cssClass={
                                  index % 2 === 0
                                    ? design.gradient0
                                    : design.gradient1
                                }
                              >
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.RoadSegment && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionValue"
                                    }
                                    caption={t("value")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionMajorValue"
                                    }
                                    caption={t("major")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionMinorValue"
                                    }
                                    caption={t("minor")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                <Column
                                  dataField={
                                    "aadTs." +
                                    y.toString() +
                                    "." +
                                    "predictedSourceType"
                                  }
                                  caption={t("source")}
                                  width={120}
                                  cssClass={
                                    index % 2 === 0
                                      ? design.gradient0
                                      : design.gradient1
                                  }
                                >
                                  <Lookup
                                    dataSource={SortObjectByPropName(
                                      lstCurrentSourceType,
                                      "name"
                                    )}
                                    valueExpr="value"
                                    displayExpr="name"
                                  />
                                  <HeaderFilter>
                                    <Search enabled />
                                  </HeaderFilter>
                                </Column>
                                <Column
                                  dataField={
                                    "aadTs." + y.toString() + "." + "quality"
                                  }
                                  caption={t("quality")}
                                  width={100}
                                  cssClass={
                                    index % 2 === 0
                                      ? design.gradient0
                                      : design.gradient1
                                  }
                                >
                                  <Lookup
                                    dataSource={SortObjectByPropName(
                                      lstAADTQualityFactor,
                                      "name"
                                    )}
                                    valueExpr="value"
                                    displayExpr="name"
                                  />
                                  <HeaderFilter>
                                    <Search enabled />
                                  </HeaderFilter>
                                </Column>
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionNorthValue"
                                    }
                                    caption={t("north")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionEastValue"
                                    }
                                    caption={t("east")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionSouthValue"
                                    }
                                    caption={t("south")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                                {initData.project.projectType ===
                                  AADTPredictionProjectType.Intersection && (
                                  <Column
                                    dataField={
                                      "aadTs." +
                                      y.toString() +
                                      "." +
                                      "predictionWestValue"
                                    }
                                    caption={t("west")}
                                    width={90}
                                    cssClass={
                                      index % 2 === 0
                                        ? design.gradient0
                                        : design.gradient1
                                    }
                                    visible={false}
                                  >
                                    <HeaderFilter>
                                      <Search enabled />
                                    </HeaderFilter>
                                  </Column>
                                )}
                              </Column>
                              {initData.project.projectType ===
                                AADTPredictionProjectType.RoadSegment && (
                                <Column
                                  dataField={
                                    "aadTs." + y.toString() + "." + "finalValue"
                                  }
                                  caption={t("final")}
                                  width={90}
                                  cssClass={`${design.VAlign} ${
                                    index % 2 === 0
                                      ? design.gradient0
                                      : design.gradient1
                                  }`}
                                >
                                  <HeaderFilter>
                                    <Search enabled />
                                  </HeaderFilter>
                                </Column>
                              )}
                              {initData.project.projectType ===
                                AADTPredictionProjectType.Intersection && (
                                <Column
                                  dataField={
                                    "aadTs." +
                                    y.toString() +
                                    "." +
                                    "finalMajorValue"
                                  }
                                  caption={t("major")}
                                  width={90}
                                  cssClass={
                                    index % 2 === 0
                                      ? design.gradient0
                                      : design.gradient1
                                  }
                                >
                                  <HeaderFilter>
                                    <Search enabled />
                                  </HeaderFilter>
                                </Column>
                              )}
                              {initData.project.projectType ===
                                AADTPredictionProjectType.Intersection && (
                                <Column
                                  dataField={
                                    "aadTs." +
                                    y.toString() +
                                    "." +
                                    "finalMinorValue"
                                  }
                                  caption={t("minor")}
                                  width={90}
                                  cssClass={
                                    index % 2 === 0
                                      ? design.gradient0
                                      : design.gradient1
                                  }
                                >
                                  <HeaderFilter>
                                    <Search enabled />
                                  </HeaderFilter>
                                </Column>
                              )}

                              <Column
                                dataField={
                                  "aadTs." + y.toString() + "." + "status"
                                }
                                caption={t("status")}
                                width={100}
                                cssClass={`${design.VAlign} ${
                                  index % 2 === 0
                                    ? design.gradient0
                                    : design.gradient1
                                }`}
                              >
                                <Lookup
                                  dataSource={SortObjectByPropName(
                                    lstAADTPredictionStatus,
                                    "name"
                                  )}
                                  valueExpr="value"
                                  displayExpr="name"
                                />
                                <HeaderFilter>
                                  <Search enabled />
                                </HeaderFilter>
                              </Column>
                            </Column>
                          );
                        })}
                    </DataGrid>
                  )}
                </SimpleItem>
              </Form>
              {params.AADTPredictionId !== "AddNew" && (
                <div className="row">
                  <div
                    className="rightColumn"
                    style={{ margin: "0.5rem 0 -2rem 0" }}
                  >
                    <Button
                      onClick={onUpdate}
                      className="tes-modal-btn-add rightColumn"
                      text={t("update")}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Popup
          width={"75%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          titleComponent={() => (
            <div>
              <div
                className="leftColumn"
                style={{
                  fontSize: "20px",
                  fontWeight: 500,
                  margin: "0.5rem 0 0 2rem",
                }}
              >
                {popupTitle}
              </div>
              <div
                className="leftColumn"
                style={{ margin: "0.625rem 0 0 1rem" }}
                onClick={(e) => {
                  go2Location();
                }}
              >
                <i
                  className="fa-solid fa-arrow-up-right-from-square"
                  style={{
                    margin: "0.25rem 0 0 0",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div
                className="rightColumn"
                style={{ margin: "0.75rem 0.5rem 0 0", cursor: "pointer" }}
                onClick={(e) => onCloseModal()}
              >
                <i className="fa-xl fa-solid fa-xmark" />
              </div>
            </div>
          )}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <DataGrid
            id="gridContainer"
            ref={modalDataGridRef}
            dataSource={popupGridData}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            // remoteOperations={true}
            //  onRowClick={e => onRowClick(e)}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ margin: "0 1rem" }}
            // onEditCanceled={e=>e}
            //  onEditCanceling={e=>e}
            // onExporting={OnExporting}
            // onContextMenuPreparing={e => { setSelectedRowData(e) }}
          >
            <ExportOption enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={"top"} />
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={GridType.AADTPredictionModalGrid.toString()}
              savingTimeout={500}
            />
            <Summary>
              <GroupItem summaryType="count" alignByColumn name="Total Count" />
            </Summary>
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <Editing mode="row" allowUpdating={true} />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <Column
              alignment="left"
              dataField="id"
              allowSorting={true}
              caption={t("id")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField={"year"}
              caption={t("year")}
              allowEditing={false}
              minWidth={80}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {initData.project.projectType ===
              AADTPredictionProjectType.RoadSegment && (
              <Column
                dataField={"currentValue"}
                caption={t("currentValue")}
                allowEditing={false}
                minWidth={100}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            )}

            {initData.project.projectType ===
              AADTPredictionProjectType.Intersection && (
              <Column caption={t("current")} allowEditing={false}>
                <Column
                  dataField={"currentMajorValue"}
                  caption={t("major")}
                  allowEditing={false}
                  minWidth={90}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField={"currentMinorValue"}
                  caption={t("minor")}
                  allowEditing={false}
                  minWidth={90}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField={"currentSourceType"}
                  caption={t("source")}
                  allowEditing={false}
                  minWidth={120}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstCurrentSourceType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
              </Column>
            )}
            {initData.project.projectType ===
              AADTPredictionProjectType.RoadSegment && (
              <Column
                dataField={"predictionValue"}
                caption={t("predictionValue")}
                allowEditing={false}
                minWidth={120}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            )}
            {initData.project.projectType ===
              AADTPredictionProjectType.Intersection && (
              <Column caption={t("predicted")} allowEditing={false}>
                <Column
                  dataField={"predictionMajorValue"}
                  caption={t("major")}
                  allowEditing={false}
                  minWidth={90}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField={"predictionMinorValue"}
                  caption={t("minor")}
                  allowEditing={false}
                  minWidth={90}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField={"predictedSourceType"}
                  caption={t("source")}
                  allowEditing={false}
                  minWidth={140}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstCurrentSourceType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
              </Column>
            )}

            {initData.project.projectType ===
              AADTPredictionProjectType.RoadSegment && (
              <Column
                dataField={"currentSourceType"}
                caption={t("currentSourceType")}
                allowEditing={false}
                minWidth={130}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(
                    lstCurrentSourceType,
                    "name"
                  )}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
            )}
            {initData.project.projectType ===
              AADTPredictionProjectType.RoadSegment && (
              <Column
                dataField={"predictedSourceType"}
                caption={t("predictedSourceType")}
                allowEditing={false}
                minWidth={130}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(
                    lstCurrentSourceType,
                    "name"
                  )}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
            )}
            <Column
              dataField={"qualityFactor"}
              caption={t("quality")}
              allowEditing={false}
              minWidth={100}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              <Lookup
                dataSource={SortObjectByPropName(lstAADTQualityFactor, "name")}
                valueExpr="value"
                displayExpr="name"
              />
            </Column>
            {initData.project.projectType ===
              AADTPredictionProjectType.RoadSegment && (
              <Column
                dataField={"finalValue"}
                caption={t("final")}
                minWidth={90}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            )}

            {initData.project.projectType ===
              AADTPredictionProjectType.Intersection && (
              <Column caption={t("final")}>
                <Column
                  dataField={"finalMajorValue"}
                  caption={t("major")}
                  minWidth={90}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField={"finalMinorValue"}
                  caption={t("minor")}
                  minWidth={90}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </Column>
            )}

            <Column
              dataField={"message"}
              caption={t("message")}
              allowEditing={false}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              dataField={"status"}
              caption={t("status")}
              allowEditing={false}
              minWidth={115}
            >
              <Lookup
                dataSource={SortObjectByPropName(
                  lstAADTPredictionStatus,
                  "name"
                )}
                valueExpr="value"
                displayExpr="name"
              />
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            {/* <Column type="buttons" width={60} visible={true} fixed={true} fixedPosition="right">
            <DevExpressButton hint={t('save')} onClick={e => notify(t("rowSaved"), "success", 2500)} icon="fa-solid fa-floppy-disk" />
          </Column> */}
          </DataGrid>
          {initData.project.projectType ===
            AADTPredictionProjectType.RoadSegment && (
            <>
              <Chart
                id="chart"
                dataSource={popupGridData}
                style={{ marginBottom: "1rem" }}
              >
                <CommonSeriesSettings argumentField="year" type="line" />
                <Series
                  valueField="finalValue"
                  name={t("finalValue")}
                  color="#F0B70D"
                  visible={finalChartVisible}
                >
                  <Point visible={true} size={20} />
                </Series>
                <Series
                  valueField="predictionValue"
                  name={t("predictionValue")}
                  color="#0b1d78"
                  visible={predChartVisible}
                >
                  <Point visible={true} size={15} />
                </Series>
                <Series
                  valueField="currentValue"
                  name={t("currentValue")}
                  color="#D81E5B"
                  visible={currentChartVisible}
                >
                  <Point visible={true} size={10} />
                </Series>
                {/* x axis */}
                <ArgumentAxis
                  title={t("year")}
                  allowDecimals={false}
                  argumentType={"numeric"}
                >
                  <Label customizeText={customizeArgumentAxisLabels} />
                </ArgumentAxis>
                <Export enabled={true} printingEnabled={false} />
                <Tooltip enabled={true} location="edge" zIndex={100000000} />
                <Legend
                  position="inside"
                  horizontalAlignment="right"
                  verticalAlignment="bottom"
                />
                <ValueAxis showZero={true} />
              </Chart>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckBox
                  text={t("current")}
                  value={currentChartVisible}
                  onValueChanged={(e) => setCurrentChartVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t("predicted")}
                  value={predChartVisible}
                  onValueChanged={(e) => setPredChartVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t("final")}
                  value={finalChartVisible}
                  onValueChanged={(e) => setFinalChartVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
              </div>
            </>
          )}
          {initData.project.projectType ===
            AADTPredictionProjectType.Intersection && (
            <>
              <Chart
                id="chart"
                dataSource={popupGridData}
                style={{ marginBottom: "1rem" }}
              >
                <CommonSeriesSettings argumentField="year" type="line" />
                <Series
                  valueField="predictionValue"
                  name={t("prediction")}
                  color="#77DD77"
                  visible={intersectionTotalGroup}
                >
                  <Point visible={false} />
                </Series>
                <Series
                  valueField="finalValue"
                  name={t("final")}
                  color="#32CD32"
                  type="scatter"
                  visible={intersectionTotalGroup}
                />
                <Series
                  valueField="currentValue"
                  name={t("current")}
                  color="#006400"
                  type="scatter"
                  visible={intersectionTotalGroup}
                />
                <Series
                  valueField="predictionMajorValue"
                  name={t("predictionMajor")}
                  color="#87CEEB"
                  visible={intersectionMajorGroup}
                >
                  <Point visible={false} />
                </Series>
                <Series
                  valueField="currentMajorValue"
                  name={t("current_Major")}
                  color="#0000CD"
                  type="scatter"
                  visible={intersectionMajorGroup}
                />
                <Series
                  valueField="finalMajorValue"
                  name={t("finalMajor")}
                  color="#00008B"
                  type="scatter"
                  visible={intersectionMajorGroup}
                />
                <Series
                  valueField="predictionMinorValue"
                  name={t("predictionMinor")}
                  color="#FF9999"
                  visible={intersectionMinorGroup}
                >
                  <Point visible={false} />
                </Series>
                <Series
                  valueField="currentMinorValue"
                  name={t("current_Minor")}
                  color="#FF0000"
                  type="scatter"
                  visible={intersectionMinorGroup}
                />
                <Series
                  valueField="finalMinorValue"
                  name={t("finalMinor")}
                  color="#8B0000"
                  type="scatter"
                  visible={intersectionMinorGroup}
                />
                {/* x axis */}
                <ArgumentAxis
                  title={t("year")}
                  allowDecimals={false}
                  argumentType={"numeric"}
                >
                  <Label customizeText={customizeArgumentAxisLabels} />
                </ArgumentAxis>
                <Export enabled={true} printingEnabled={false} />
                <Tooltip enabled={true} location="edge" zIndex={100000000} />
                <Legend position="outside" horizontalAlignment="right" />
                <ValueAxis showZero={true} />
              </Chart>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CheckBox
                  text={t("total")}
                  value={intersectionTotalGroup}
                  onValueChanged={(e) => setIntersectionTotalGroup(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t("major")}
                  value={intersectionMajorGroup}
                  onValueChanged={(e) => setIntersectionMajorGroup(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t("minor")}
                  value={intersectionMinorGroup}
                  onValueChanged={(e) => setIntersectionMinorGroup(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
              </div>
            </>
          )}
          <div className="row">
            <div className="rightColumn" style={{ margin: "0.5rem 0 -2rem 0" }}>
              <Button
                onClick={onPopupSave}
                className="tes-modal-btn-add rightColumn"
                text={t("save")}
              />
              <Button
                onClick={(e) => {
                  setShowModal(false);
                  setFinalValueChanged(false);
                }}
                className="tes-modal-btn-cancel rightColumn"
                text={t("cancel")}
              />
            </div>
          </div>
        </Popup>
      </React.Fragment>
    </Permission>
  );
};

export default AADTPredictionDetails;
