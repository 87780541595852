const SystematicScreeningPermissions = {
  SystematicScreening_V: "SystematicScreening_V",
  SystematicScreening_D_SWP: "SystematicScreening_D_SWP",
  SystematicScreening_V_SWP: "SystematicScreening_V_SWP",
  SystematicScreening_D_AadtPrediction: "SystematicScreening_D_AadtPrediction",
  SystematicScreening_V_AadtPrediction: "SystematicScreening_V_AadtPrediction",
  SystematicScreening_D_VRU: "SystematicScreening_D_VRU",
  SystematicScreening_V_VRU: "SystematicScreening_V_VRU",
  SystematicScreening_D_SRB: "SystematicScreening_D_SRB",
  SystematicScreening_V_SRB: "SystematicScreening_V_SRB",
  SystematicScreening_D_RLC: "SystematicScreening_D_RLC",
  SystematicScreening_V_RLC: "SystematicScreening_V_RLC",
  SystematicScreening_D_ASE: "SystematicScreening_D_ASE",
  SystematicScreening_V_ASE: "SystematicScreening_V_ASE",
};
export default SystematicScreeningPermissions;
