//#region imports
import { ContextMenu } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import { TrafficCounterDTO } from "../../../../types/trafficStudy/dtos/TrafficCounterDTO";
import { TrafficCounterType } from "../../../../types/trafficStudy/enums/importEnums";
import { StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array, EnumFlag2Array } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import "./trafficCounters.scss";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

//#endregion imports
const TrafficCounters = () => {
  //#region consts
  const [initData, setInitData] = useState<TrafficCounterDTO[]>([]);
  const [lstStudyTypes, setLstStudyTypes] = useState<NameValue[]>([]);
  const [lstTrafficCounterTypes, setLstTrafficCounterTypes] = useState<
    NameValue[]
  >([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#endregion
  //#region functions

  useBrowserTitle({ pathname: "/trafficStudy/setups/trafficCounters" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstStudyTypes(Enum2Array(StudyType));
        setLstTrafficCounterTypes(Enum2Array(TrafficCounterType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      var res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/GetCustomerTrafficCounter",
        {
          customerId: localStorage.getItem("selectedCustomerId"),
        } as LazyLoadingRequestBase,
        true
      )) as RequestResponseResult<TrafficCounterDTO[]>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/trafficStudy/setups/trafficCountersDetails/${e.data.id}`,
          "_blank"
        );
      } else {
        history(`/trafficStudy/setups/trafficCountersDetails/${e.data.id}`);
      }
    }
  }

  const navigateToTrafficCountersDetails = useCallback(() => {
    history("/trafficStudy/setups/trafficCountersDetails/AddNew");
  }, [history]);

  //#endregion functions

  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data.id,
                "/trafficStudy/setups/trafficCountersDetails/"
              )
            }
          />
          <h2 className={"content-block"}>{t("trafficCounters")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToTrafficCountersDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("trafficCounters")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClicked}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                onEditorPreparing={(e) => {
                  if (
                    (e.parentType === "dataRow" ||
                      e.parentType === "filterRow") &&
                    e.dataField === "studyTypes"
                  ) {
                    e.editorName = "dxTagBox";
                    e.editorOptions.dataSource = lstStudyTypes;
                    e.editorOptions.showSelectionControls = true;
                    e.editorOptions.displayExpr = "name";
                    e.editorOptions.valueExpr = "value";
                    e.editorOptions.value =
                      EnumFlag2Array(StudyType, e.value) || [];
                    e.editorOptions.onValueChanged = function (args: any) {
                      e.setValue(args.value);
                    };
                  }
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="maxBinNo" caption={t("maxBinNo")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="trafficCounterType"
                  caption={t("trafficCounterType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstTrafficCounterTypes,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="studyTypes"
                  caption={t("studyType")}
                  calculateDisplayValue={function (rowData: any) {
                    var values = EnumFlag2Array(StudyType, rowData.studyTypes);
                    var res = [];
                    if (values !== undefined) {
                      for (let i = 0; i < values.length; i++) {
                        res.push(StudyType[values[i]]);
                      }
                    }
                    return res.join(",");
                  }}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="isDisabled"
                  caption={t("isDisabled")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="divisionId"
                  caption={t("divisionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default TrafficCounters;
