import React from "react";
import ScrollView from "devextreme-react/scroll-view";

import "./single-card.scss";

interface IProps {
  title: string;
  description?: string;
  children: any;
}

const singleCard = ({ title, description, children }: IProps) => (
  <ScrollView
    height={"100%"}
    width={"100%"}
    className={"with-footer single-card backgroundImage"}
  >
    <div className={"dx-card content"}>
      <div className={"header"}>
        <div className={"title"}>{title}</div>
        <div className={"description"}>{description}</div>
      </div>
      {children}
    </div>
  </ScrollView>
);
export default singleCard;
