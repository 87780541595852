import { JobStatus, JobType } from "./enums/tesSyncEnums";

export class Job {
  id!: string;
  customerId!: string;
  status!: JobStatus;
  jobType!: JobType;
  createDate!: string;
  startDT!: string;
  endDT!: string;
  computerName!: string;
  note!: string;
}
