import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import CustomStore from "devextreme/data/custom_store";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SignApiUrl } from "../../environment/routeSettings";
import { LocationType } from "../../types/infrastructure/enums/infrastructureEnums";
import { TesSign } from "../../types/sign/signTypes";
import { OnExporting } from "../../utils/dataGridTools";
import { TesPut } from "../../utils/rest";

interface IProps {
  label: string;
  initDataSign: TesSign;
  onChangeLocationDescription: (
    locationId: string,
    description: string,
    geoId: string,
    locationType: LocationType,
    latitude: number,
    longitude: number
  ) => void;
  isLocked: boolean;
  setInitDataSign: React.Dispatch<React.SetStateAction<TesSign>>;
}

const dataSource = new CustomStore({
  load: async (loadOption) => {
    return await TesPut(
      `${SignApiUrl()}/api/Supports/LazyLoading/${localStorage.getItem(
        "selectedCustomerId"
      )}`,
      JSON.stringify(loadOption),
      true
    );
  },
});

const SupportValuePicker = (props: IProps) => {
  const [showSupportPicker, setShowSupportPicker] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();

  useEffect(() => {
    async function fetchMyAPI() {}
    fetchMyAPI();
  }, []);

  function onRowClick(selectedRowData: any) {
    setShowSupportPicker(false);
    props.setInitDataSign({
      ...props.initDataSign,
      supportId: selectedRowData.data?.id,
      support: {
        ...props.initDataSign.support,
        supportId: selectedRowData.data?.supportId,
      },
    });
  }

  async function onOpenPopUp() {
    if (!props.isLocked) {
      setShowSupportPicker(true);
    }
  }

  function go2SupportDetails() {
    history("/sign/supportDetails/" + props.initDataSign?.support.id);
  }

  return (
    <React.Fragment>
      <label onClick={() => onOpenPopUp()}>
        <TextBox
          style={{ marginBottom: 20 }}
          label={props.label}
          labelMode="floating"
          value={props.initDataSign?.support?.supportId}
          disabled={props.isLocked}
          buttons={[
            {
              name: "search",
              location: "after",
              options: {
                icon: "fas fa-location-dot",
                stylingMode: "text",
                hint: t("go2supportDetails"),
                onClick: (x) => {
                  go2SupportDetails();
                },
              },
            },
          ]}
        />
      </label>
      <Popup
        width={"60%"}
        visible={showSupportPicker}
        resizeEnabled={true}
        showTitle={true}
        title={t("supportPicker")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowSupportPicker(false)}
      >
        <div style={{ height: "100%" }}>
          <DataGrid
            ref={dataGridRef}
            dataSource={dataSource}
            style={{ height: "100%" }}
            rowAlternationEnabled={true}
            showBorders={true}
            selection={{ mode: "single" }}
            onRowClick={onRowClick}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={OnExporting}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />

            <HeaderFilter visible={true} />

            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />

            <Column
              alignment="left"
              dataField="id"
              allowSorting={true}
              caption={t("id")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>

            <Column
              dataField="supportId"
              defaultSortOrder="asc"
              caption={t("supportId")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="supportCodeTitle" caption={t("code")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              dataField="supportDescriptionTitle"
              caption={t("description")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="geoId" caption={t("geoId")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="locationDescription" caption={t("location")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              dataField="supportLocationTypeTitle"
              caption={t("locationType")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="xCoord"
              caption={t("xCoord")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="yCoord"
              caption={t("yCoord")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="height"
              caption={t("height")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="supportPositionTitle"
              caption={t("position")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="supportConditionTitle"
              caption={t("condition")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="dateInstalled"
              dataType="date"
              caption={t("installationDate")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="dateExpired"
              dataType="date"
              caption={t("expirationDate")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="materialCost"
              caption={t("materialCost")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="labourCost"
              caption={t("labourCost")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              dataField="installationCost"
              caption={t("installationCost")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </div>
      </Popup>
    </React.Fragment>
  );
};
export default SupportValuePicker;
