import { DropDownButton } from "devextreme-react";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { NameValue } from "../../../../types/general/generalTypes";
import { AADTPredictionProjectType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { AADTPredictionProject } from "../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import MaxDropdownItemWidthCalculator from "../../../../utils/dropDownWidthCalculator";
import { Enum2Array } from "../../../../utils/enumTools";
import { TesGet } from "../../../../utils/rest";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";

const CountStations = () => {
  const [initData, setInitData] = useState<AADTPredictionProject[]>([
    new AADTPredictionProject(),
  ]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { user } = useAuth();
  const { t } = useTranslation();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [lstProjectType, setLstProjectType] = useState<NameValue[]>([]);
  const dropDownOptions = { width: 215 };
  const { generalSetting, customerDivisions } = useClientSetting();

  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstProjectType(Enum2Array(AADTPredictionProjectType));

        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function getInitData() {
  //   const postObj  = {
  //       customerId: localStorage.getItem("selectedCustomerId"),
  //       divisionId: localStorage.getItem("selectedDivisionId"),
  //   }
  //   setInitData(
  //     await TesPost(
  //       TrafficApiUrl() +
  //       "/api/AADTPrediction/GetCustomerProjects",
  //       postObj,
  //       true
  //     )
  //   );
  // }

  async function getInitData() {
    setInitData(
      await TesGet(
        TrafficApiUrl() +
          "/api/AADTPrediction/GetCustomerProjects/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const navigateToAADTPredictionDetails = useCallback(
    (d: any) => {
      history(
        `/trafficStudy/tools/AADTPredictionDetails/${d.itemData.id}/AddNew`
      );
    },
    [history]
  );

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/trafficStudy/tools/AADTPredictionDetails/${e.data.projectType}/${e.data.id}`,
          "_blank"
        );
      } else {
        history(
          `/trafficStudy/tools/AADTPredictionDetails/${e.data.projectType}/${e.data.id}`
        );
      }
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_E_AadtPrediction,
        TrafficStudyPermissions.TrafficStudy_V_AadtPrediction,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        {/* <ContextMenu
        dataSource={items}
        width={100}
        target=".dx-data-row"
        onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/trafficStudy/tools/AADTPredictionDetails/${AADTPredictionProjectType.RoadSegment}/`)}
      /> */}

        {/* <ContextMenu
        dataSource={items}
        width={100}
        target=".dx-data-row"
        onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/trafficStudy/tools/AADTPredictionDetails/`, selectedRowData.row.data.projectType, selectedRowData.row.data.id)}
      /> */}
        <h2 className={"content-block"}>{t("aadtPrediction")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <Permission
                allowed={[
                  TrafficStudyPermissions.TrafficStudy_E_AadtPrediction,
                ]}
                hasFeedBackElement={false}
              >
                <div className="rightColumn">
                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    text={t("add")}
                    icon="fa-solid fa-circle-plus"
                    items={SortObjectByPropName(
                      [
                        {
                          name: t("intersection"),
                          id: AADTPredictionProjectType.Intersection,
                        },
                        {
                          name: t("roadSegment"),
                          id: AADTPredictionProjectType.RoadSegment,
                        },
                      ],
                      "name"
                    )}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator(
                        SortObjectByPropName(
                          [
                            {
                              name: t("intersection"),
                              id: AADTPredictionProjectType.Intersection,
                            },
                            {
                              name: t("roadSegment"),
                              id: AADTPredictionProjectType.RoadSegment,
                            },
                          ],
                          "name"
                        )
                      ),
                    }}
                    displayExpr="name"
                    onItemClick={(d) => navigateToAADTPredictionDetails(d)}
                  />
                </div>
              </Permission>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initData}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              onRowClick={onRowClicked}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              onContextMenuPreparing={(e) => {
                setSelectedRowData(e);
              }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="title" caption={t("title")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="description"
                caption={t("description")}
                visible={true}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="projectType"
                caption={t("type")}
                visible={true}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(lstProjectType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
              <Column
                dataField="DivisionId"
                allowSorting={true}
                visible={false}
                caption={t("division")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(customerDivisions!, "name")}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default CountStations;
