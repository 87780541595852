import {
  Button,
  ContextMenu,
  DataGrid,
  ScrollView,
  Tooltip as TooltipBox,
} from "devextreme-react";
import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Export,
  Legend,
  Series,
  Size,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import PieChart, { Connector, Label } from "devextreme-react/pie-chart";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import {
  CollisionApiUrl,
  TrafficApiUrl,
} from "../../../../../environment/routeSettings";
import {
  FromMapRequestCollisionDTO,
  FromMapResponseCollisionDTO,
  GetCollisionDatePeriodRequestDTO,
  ToMapRequestCollisionDTO,
} from "../../../../../types/collision/dto/collisionDtos";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../../types/general/generalTypes";
import { VMLocation } from "../../../../../types/infrastructure/dto/locationdDto";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import {
  FromMapRequestTrafficDTO,
  FromMapResponseTrafficDTO,
} from "../../../../../types/trafficStudy/dtos/studyDto";
import {
  ApproachType,
  StudyCategory,
  StudyType,
} from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import {
  SortObjectByPropName,
  sortArrayOfObjectByDate,
} from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array, EnumFlag2Array } from "../../../../../utils/enumTools";
import { useScreenSize } from "../../../../../utils/media-query";
import { RequestErrorHandling, TesPost } from "../../../../../utils/rest";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";
import TransformToSpeedAndCompliance from "../../../../../utils/speedAndCompliance";
import design from "./collision.module.scss";
import LoadingScreen, {
  CheckLoadingStates,
} from "../../../../../components/loadingScreen/loadingScreen";
const pinIcon = "/images/pinIcon.svg";
const unpinIcon = "/images/unpinIcon.svg";
// props
interface IPros {
  selectedLoc?: VMLocation;
  setCollisionToMapData: React.Dispatch<React.SetStateAction<any>>;
  setCollisionToMapReq: React.Dispatch<React.SetStateAction<any>>;
  collisionToMapReq: ToMapRequestCollisionDTO;
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>;
  pinned: boolean;
  setPinned: React.Dispatch<React.SetStateAction<boolean>>;
  datePeriod: GetCollisionDatePeriodRequestDTO | undefined;
}

const Data = (props: IPros) => {
  const [selectedDataCollision, setSelectedDataCollision] =
    useState<FromMapResponseCollisionDTO>();
  const [selectedDataTraffic, setSelectedDataTraffic] =
    useState<FromMapResponseTrafficDTO>();
  const [fromMapReqCol] = useState<FromMapRequestCollisionDTO>({
    fromDate:
      props.datePeriod?.endDT === undefined || props.datePeriod?.endDT === null
        ? new Date(new Date().getFullYear() - 4, 0, 1)
        : new Date(new Date(props.datePeriod?.endDT).getFullYear() - 4, 0, 1),
    toDate:
      props.datePeriod?.endDT === undefined || props.datePeriod?.endDT === null
        ? new Date(new Date().getFullYear(), 11, 31)
        : new Date(new Date(props.datePeriod?.endDT).getFullYear(), 11, 31),
    customerId: localStorage.getItem("selectedCustomerId") as string,
    locationId: props.selectedLoc?.id as string,
  });
  const [lstApproachType, setLstApproachType] = useState<NameValue[]>();
  const [fromMapReqTra, setFromMapReqTra] = useState<FromMapRequestTrafficDTO>({
    fromDate: new Date(new Date().getFullYear() - 5, 0, 1),
    toDate: new Date(new Date().getFullYear(), 11, 31),
    customerId: localStorage.getItem("selectedCustomerId") as string,
    locationId: props.selectedLoc?.id as string,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMapReqCol, setLoadingMapReqCol] = useState<boolean>(false);
  const [loadingMapReqTra, setLoadingMapReqTra] = useState<boolean>(false);
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { isLarge, isXLarge, is2xLarge } = useScreenSize();
  const [chartWidth, setChartWidth] = useState<number>(200);
  const [chartHeight, setChartHeight] = useState<number>(250);
  const [pieChartSize, setPieChartSize] = useState<number>(300);
  const googlMapIcon = require("../../../../../assets/img/icons/google-maps.png");
  const googlStreetIcon = require("../../../../../assets/img/icons/street-view.png");
  const bingMapIcon = require("../../../../../assets/img/icons/bing-map.png");
  const bingStreetIcon = require("../../../../../assets/img/icons/bing-street.png");
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    //   { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];

  useEffect(() => {
    if (isLarge) {
      setChartWidth(300);
      setChartHeight(350);
    } else if (isXLarge) {
      setChartWidth(700);
      setChartHeight(450);
      setPieChartSize(500);
    } else if (is2xLarge) {
      setChartWidth(300);
      setChartHeight(350);
      setPieChartSize(300);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      setLoading(true);
      setLstApproachType(Enum2Array(ApproachType));
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.selectedLoc?.id !== null &&
      props.selectedLoc?.id !== undefined &&
      props.selectedLoc?.id !== ""
    ) {
      fetchMyAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLoc]);

  async function fetchMyAPI() {
    try {
      setLoadingMapReqCol(true);
      setLstStudyType(Enum2Array(StudyType));
      var postObjCol = fromMapReqCol;
      postObjCol.locationId = props.selectedLoc?.id as string;
      var resCollision = (await TesPost(
        CollisionApiUrl() + "/api/reports/GetDataFromMap",
        postObjCol,
        true
      )) as RequestResponseResult<FromMapResponseCollisionDTO>;
      if (resCollision.responseCode === ResponseCode.OK) {
        setSelectedDataCollision(resCollision.results);
        setLoadingMapReqCol(false);
      } else if (resCollision.responseCode === ResponseCode.IdNotExists) {
        setLoadingMapReqCol(false);
        notify(t("enum-IdNotExists"), "error", 5000);
      }
      await RequestErrorHandling(resCollision);
    } catch (ex) {
      setLoadingMapReqCol(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }

    try {
      setLoadingMapReqTra(true);
      setLstStudyType(Enum2Array(StudyType));
      var postObjTra = fromMapReqTra;
      postObjTra.locationId = props.selectedLoc?.id as string;
      var resTraffic = (await TesPost(
        TrafficApiUrl() + "/api/report/GetDataFromMap",
        postObjTra,
        true
      )) as RequestResponseResult<FromMapResponseTrafficDTO>;
      if (resTraffic.responseCode === ResponseCode.OK) {
        setLoadingMapReqTra(false);
        setSelectedDataTraffic(resTraffic.results);
      } else if (resTraffic.responseCode === ResponseCode.IdNotExists) {
        setLoadingMapReqTra(false);
        notify(t("enum-IdNotExists"), "error", 5000);
      }
      await RequestErrorHandling(resTraffic);
    } catch (ex) {
      setLoadingMapReqTra(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function toggleVisibility(item: any) {
    item.isVisible() ? item.hide() : item.show();
  }

  function legendClickHandler(e: any) {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
    toggleVisibility(item);
  }

  function pointClickHandler(e: any) {
    toggleVisibility(e.target);
  }

  //on row click for traffic study data grid records
  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.studyId !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.studyId}`,
          "_blank"
        );
      } else {
        history(
          `/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.studyId}`
        );
      }
    }
  }

  //location description onClick
  function go2Location() {
    if (props.selectedLoc?.locationType === LocationType.Midblock) {
      window.open(
        "/infrastructure/roadSegmentDetails/" + props.selectedLoc?.id,
        "_blank"
      );
    } else {
      window.open(
        "/infrastructure/intersectionDetails/" + props.selectedLoc?.id,
        "_blank"
      );
    }
  }

  function go2GoogleMap(lat: number, lng: number) {
    console.log(props.selectedLoc);
    if (lat !== 0 && lng !== 0) {
      const url = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(url, "_blank");
    }
  }

  function onOpenGoogleMap() {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${props.selectedLoc?.latitude},${props.selectedLoc?.longitude}`
    );
  }
  function onOpenGoogleStreetView() {
    window.open(
      `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${props.selectedLoc?.latitude},${props.selectedLoc?.longitude}&heading=0&pitch=0&fov=0`
    );
  }
  function onOpenBingMap() {
    window.open(
      `https://www.bing.com/maps?v=2&cp=32.040313~34.823399&lvl=15.0&sp=point.${props.selectedLoc?.latitude}_${props.selectedLoc?.longitude}_Location`
    );
  }
  function onOpenBingStreetView() {
    window.open(
      `https://www.bing.com/mapspreview?cp=${props.selectedLoc?.latitude}~${props.selectedLoc?.longitude}&lvl=17&dir=113.8911&pi=-0.284&style=x&v=2&sV=1`
    );
  }

  return (
    <React.Fragment>
      {CheckLoadingStates([loading, loadingMapReqCol, loadingMapReqTra]) && (
        <LoadingScreen />
      )}
      <ContextMenu
        dataSource={items}
        width={100}
        target=".dx-data-row"
        onItemClick={(e) =>
          RightClickMenu(
            e,
            selectedRowData.row.data.studyId,
            `/trafficStudy/studies/studyDetails/${selectedRowData.row.data.studyType}/`
          )
        }
      />
      <div className="row TrafficOperation_Data" style={{ padding: 10 }}>
        {/* floating button */}
        <div
          id="pinButton"
          className={`${design.floatingButton}`}
          onClick={() => props.setPinned(!props.pinned)}
        >
          <img
            src={props.pinned ? unpinIcon : pinIcon}
            alt="Pin"
            className={`${design.pins}`}
          />
          <TooltipBox
            target="#pinButton"
            showEvent="mouseenter"
            hideEvent="mouseleave"
            hideOnOutsideClick={false}
          >
            <div style={{ maxHeight: "200px", overflow: "hidden" }}>
              {props.pinned ? t("unpinColumn") : t("pinColumn")}
            </div>
          </TooltipBox>
        </div>
        <ScrollView showScrollbar={"always"}>
          {/* location description */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <div className={`${design.locationColumn}`}> */}
            <p style={{ display: "inline" }}>
              <span>{t("location")}: </span>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  props.selectedLoc?.locationType === LocationType.Midblock
                    ? "/infrastructure/roadSegmentDetails/" +
                      props.selectedLoc?.id
                    : "/infrastructure/intersectionDetails/" +
                      props.selectedLoc?.id
                }
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {props.selectedLoc?.description}
              </a>
              <span> [{props.selectedLoc?.geoId}]</span>
            </p>
            {/* </div> */}
            {/* <div className={` ${design.locationColumn} ${design.justifyRight}`} > */}
            {props.selectedLoc?.description !== undefined && (
              <Button
                icon="fa-solid fa-arrow-up-right-from-square"
                onClick={go2Location}
              />
            )}

            {/* </div> */}
          </div>
          {/* <div className={`${design.iconMainBox}`}>
                            <div className={`${design.mapIcons}`}>
                                <Button icon={googlMapIcon} id="googlMapIcon" 
                                    onClick={onOpenGoogleMap}
                                />
                                <TooltipBox
                                    target="#googlMapIcon"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                >
                                    <div>{t("googleMap")}</div>
                                </TooltipBox>
                            </div>
                            <div className={`${design.mapIcons}`}>
                                <Button icon={googlStreetIcon} id="googlStreetIcon" 
                                    onClick={onOpenGoogleStreetView}
                                />
                                <TooltipBox
                                    target="#googlStreetIcon"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                >
                                    <div>{t("googleStreet")}</div>
                                </TooltipBox>
                            </div>
                            <div className={`${design.mapIcons}`}>
                                <Button icon={bingMapIcon} id="bingMapIcon" 
                                    onClick={onOpenBingMap}
                                />
                                <TooltipBox
                                    target="#bingMapIcon"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                >
                                    <div>{t("bingMap")}</div>
                                </TooltipBox>
                            </div>
                            <div className={`${design.mapIcons}`}>
                                <Button icon={bingStreetIcon} id="bingStreetIcon" 
                                    onClick={onOpenBingStreetView}
                                />
                                <TooltipBox
                                    target="#bingStreetIcon"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                >
                                    <div>{t("bingStreet")}</div>
                                </TooltipBox>
                            </div>
                        </div> */}
          {/* traffic statistics */}
          <div className={`${design.line}`} />
          <p className={`${design.bigLabel} ${design.bolder}`}>
            {t("trafficStudy")}
          </p>
          <div>
            {/* traffic aadt chart */}
            {selectedDataTraffic?.aadt?.length !== 0 &&
            selectedDataTraffic?.aadt !== undefined &&
            selectedDataTraffic?.studies.length !== 1 ? (
              <div style={{ justifyContent: "center", padding: "0 1rem" }}>
                <Chart
                  id="chart"
                  dataSource={selectedDataTraffic?.aadt}
                  paletteExtensionMode="alternate"
                >
                  <Size height={chartHeight} />
                  <CommonSeriesSettings argumentField="year" type="line" />
                  <ArgumentAxis
                    allowDecimals={false}
                    argumentType={"string"}
                    title={t("year")}
                  />
                  <Series
                    valueField="volume"
                    name={t("aadt")}
                    axis="aadt"
                    color="#008ac5"
                  />
                  {/*y axis */}
                  <ValueAxis
                    name="aadt"
                    position="top"
                    allowDecimals={false}
                    type={"continuous"}
                    title={t("aadt")}
                    visualRange={[0]}
                  ></ValueAxis>

                  <Export enabled={true} printingEnabled={false} />
                  <Tooltip enabled={true} location="edge" />
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="bottom"
                    visible={false}
                  />
                </Chart>
              </div>
            ) : (
              <p className={`${design.labels}`}>
                {t("thereIsNoDataForTrafficStudy")}
              </p>
            )}

            {
              selectedDataTraffic?.aadt !== undefined &&
                selectedDataTraffic?.studies.length !== 1 &&
                selectedDataTraffic?.studies?.filter(
                  (x) => x.studyType === StudyType.TMC
                )?.length > 0 && (
                  <div style={{ justifyContent: "center", padding: "0 1rem" }}>
                    <Chart
                      id="chart"
                      dataSource={selectedDataTraffic?.aadt}
                      paletteExtensionMode="alternate"
                    >
                      <Size height={chartHeight} />
                      <CommonSeriesSettings
                        argumentField="year"
                        type="line"
                        barWidth={50}
                      />
                      <Series
                        valueField="majorVolume"
                        name={t("major")}
                        color="gray"
                      />
                      <Series
                        valueField="minorVolume"
                        name={t("minor")}
                        color="#000080"
                      />
                      <Series
                        valueField="volume"
                        name={t("total")}
                        type="line"
                        color="black"
                      />

                      {/* x axis */}
                      <ArgumentAxis
                        //position={"center"}
                        title={t("year")}
                        allowDecimals={false}
                        argumentType={"string"}
                      />

                      <Export enabled={true} printingEnabled={false} />
                      <Tooltip enabled={true} location="edge" />
                      <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        itemTextPosition="bottom"
                      />
                    </Chart>
                  </div>
                )
              // :
              // <p className={`${design.labels}`}>{t('thereIsNoDataForAADTMinorMajor')}</p>
            }

            {/* traffic data grids */}

            {/* volume */}
            {selectedDataTraffic?.studies !== undefined &&
              selectedDataTraffic?.studies?.filter(
                (x) => x.studyType === StudyType.Volume
              )?.length > 0 && (
                <div>
                  <p className={`${design.bigLabel} ${design.bolder}`}>
                    {t("volumeStudies")}
                  </p>
                  <div className={design.line} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridRef}
                      dataSource={sortArrayOfObjectByDate(
                        selectedDataTraffic.studies.filter(
                          (x) => x.studyType === StudyType.Volume
                        )
                      )}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      width={"95%"}
                      style={{ margin: "0 0 0 1rem" }}
                      onRowClick={onRowClicked}
                      hoverStateEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      showColumnLines={true}
                      className="dashboard_data_grid"
                      onExporting={OnExporting}
                      onContextMenuPreparing={(e) => {
                        setSelectedRowData(e);
                      }}
                    >
                      {/* <SortByGroupSummaryInfo
                                            summaryItem="startDT"
                                            sortOrder="desc"
                                        /> */}

                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>
                      <Column
                        alignment="left"
                        dataField="id"
                        allowSorting={true}
                        caption={t("id")}
                        visible={false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        alignment="left"
                        dataField="startDT"
                        visible={true}
                        dataType="date"
                        format={generalSetting?.dateFormat}
                        caption={t("startDT")}
                      ></Column>
                      <Column
                        alignment="left"
                        dataField="roadSegmentAADT.totalVolume"
                        visible={true}
                        caption={t("aadt")}
                      ></Column>
                      <Column
                        alignment="left"
                        dataField="studyType"
                        visible={true}
                        caption={t("type")}
                      >
                        <Lookup
                          dataSource={SortObjectByPropName(
                            lstStudyType,
                            "name"
                          )}
                          valueExpr="value"
                          displayExpr="name"
                        />
                      </Column>
                      <Column
                        alignment="left"
                        dataField="studyCategory"
                        visible={true}
                        caption={t("category")}
                        calculateDisplayValue={function (rowData: any) {
                          var values = EnumFlag2Array(
                            StudyCategory,
                            rowData.studyCategory
                          );
                          var res = [];
                          if (values !== undefined) {
                            for (let i = 0; i < values.length; i++) {
                              res.push(t("enum-" + StudyCategory[values[i]]));
                            }
                          }
                          return res.join(",");
                        }}
                      ></Column>
                    </DataGrid>
                  </div>
                </div>
              )}

            {/* speed */}
            {selectedDataTraffic?.studies !== undefined &&
              selectedDataTraffic?.studies?.filter(
                (x) => x.studyType === StudyType.Speed
              )?.length > 0 && (
                <div style={{ marginBottom: "1rem" }}>
                  <p className={`${design.bigLabel} ${design.bolder}`}>
                    {t("speedStudies")}
                  </p>
                  <div className={`${design.line}`} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <DataGrid
                      ref={dataGridRef}
                      dataSource={sortArrayOfObjectByDate(
                        selectedDataTraffic.studies.filter(
                          (x) => x.studyType === StudyType.Speed
                        )
                      )}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      width={"95%"}
                      style={{ margin: "0 0 0 1rem" }}
                      onRowClick={onRowClicked}
                      hoverStateEnabled={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      showColumnLines={true}
                      className="dashboard_data_grid"
                      onExporting={OnExporting}
                      onContextMenuPreparing={(e) => {
                        setSelectedRowData(e);
                      }}
                    >
                      {/* <SortByGroupSummaryInfo
                                        summaryItem="Total Count"
                                        sortOrder="desc"
                                    /> */}

                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                      />

                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>

                      <Column
                        alignment="left"
                        dataField="startDT"
                        visible={true}
                        dataType="date"
                        format={generalSetting?.dateFormat}
                        caption={t("startDT")}
                      ></Column>

                      <Column
                        dataField="volumeSummary.totalVolume"
                        visible={false}
                        caption={t("totalVol")}
                      ></Column>

                      <Column
                        alignment="left"
                        dataField="id"
                        visible={false}
                        caption={t("id")}
                      ></Column>

                      <Column
                        alignment="left"
                        dataField="speedStatisticsSummary.average"
                        format="#.0"
                        caption={t("avg")}
                      ></Column>
                      <Column
                        alignment="left"
                        dataField="speedStatisticsSummary.speed85thPercentile"
                        format="#.0"
                        caption={t("85thPercentile")}
                      ></Column>

                      <Column
                        alignment="left"
                        dataField="speedStatisticsSummary.compliance"
                        format="percent"
                        visible={true}
                        caption={t("compliance")}
                      ></Column>

                      <Column
                        alignment="left"
                        dataField="speedStatisticsSummary.postedSpeed"
                        caption={t("postedSpeed")}
                      ></Column>

                      <Column
                        alignment="left"
                        dataField="roadSegmentAADT.totalVolume"
                        visible={true}
                        caption={t("aadt")}
                      ></Column>
                    </DataGrid>

                    {
                      selectedDataTraffic.studies !== undefined &&
                        selectedDataTraffic?.studies.length > 0 &&
                        selectedDataTraffic?.studies.filter(
                          (x) => x.studyType === 4
                        ).length > 1 && (
                          // !selectedDataTraffic?.studies.some(x => x.speedStatisticsSummary === null)  &&
                          // !selectedDataTraffic?.studies.some(x => x.volumeSummary === null)  &&
                          <div
                            style={{
                              justifyContent: "center",
                              padding: "1rem 1rem 0 1rem",
                            }}
                          >
                            <Chart
                              id="chart"
                              dataSource={TransformToSpeedAndCompliance(
                                selectedDataTraffic?.studies
                              )}
                              paletteExtensionMode="alternate"
                            >
                              <Size height={chartHeight} />
                              <CommonSeriesSettings
                                argumentField="startDT"
                                type="line"
                              />
                              <Series
                                valueField="average"
                                name={t("average")}
                                type="line"
                                axis="leftAxis"
                                color="#0b1d78"
                              />
                              <Series
                                valueField="speed85thPercentile"
                                name={t("85th%")}
                                type="line"
                                axis="leftAxis"
                                color="#FFC300"
                              />
                              <Series
                                valueField="postedSpeed"
                                name={t("postedSpeed")}
                                type="line"
                                axis="leftAxis"
                                color="#D81E5B"
                              />
                              <Series
                                valueField="compliance"
                                name={t("compliance")}
                                type="line"
                                axis="rightAxis"
                                color="#8E44AD"
                              />

                              <ArgumentAxis title={t("Date")} />

                              <ValueAxis
                                name="leftAxis"
                                position="left"
                                showZero={true}
                                valueMarginsEnabled={false}
                                allowDecimals={false}
                              >
                                <Title text={t("speed") + " (km/h)"} />
                              </ValueAxis>

                              <ValueAxis
                                name="rightAxis"
                                position="right"
                                showZero={true}
                                valueMarginsEnabled={false}
                                allowDecimals={false}
                                visualRange={[0, 100]}
                                valueType="numeric"
                              >
                                <Title text={t("compliance") + " (%)"} />
                              </ValueAxis>

                              <Export enabled={true} printingEnabled={false} />
                              <Tooltip enabled={true} location="edge" />
                              <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                              ></Legend>
                            </Chart>
                          </div>
                        )

                      // <div style={{ justifyContent: "center", padding: "1rem 1rem 0 1rem"}}>
                      //     <Chart id="chart"
                      //         dataSource={TransformToSpeedAndCompliance(selectedDataTraffic?.studies)}
                      //         paletteExtensionMode="alternate"
                      //     >
                      //         <Size height={chartHeight} />
                      //         <CommonSeriesSettings argumentField="startDT" type="line"/>
                      //         <Series
                      //             valueField="average"
                      //             name={t("average")}
                      //             type="line"
                      //             visible={isAvgVisible}
                      //             axis="leftAxis"
                      //             color="#0b1d78"
                      //         />
                      //         <Series
                      //             valueField="speed85thPercentile"
                      //             name={t("85th%")}
                      //             type="line"
                      //             visible={is85thVisible}
                      //             axis="leftAxis"
                      //             color="#0061A7"
                      //         />
                      //         <Series
                      //             valueField="postedSpeed"
                      //             name={t("postedSpeed")}
                      //             type="line"
                      //             visible={isPostedSpeedVisible}
                      //             axis="leftAxis"
                      //             color="#D81E5B"
                      //         />
                      //         <Series
                      //             valueField="compliance"
                      //             name={t("compliance")}
                      //             type="line"
                      //             visible={isComplianceVisible}
                      //             axis="leftAxis"
                      //             color="#8E44AD"
                      //         />
                      //         <Series
                      //             valueField="totalVolume"
                      //             name={t("totalVolume")}
                      //             type="line"
                      //             visible={isTotalVisible}
                      //             axis="rightAxis"
                      //             color="#FFC300"
                      //         />
                      //         <Series
                      //             valueField="northApproachVolume"
                      //             name={t("northApproachVolume")}
                      //             type="line"
                      //             visible={isNVisible}
                      //             axis="rightAxis"
                      //             color="#FF5733 "
                      //         />
                      //         <Series
                      //             valueField="eastApproachVolume"
                      //             name={t("eastApproachVolume")}
                      //             type="line"
                      //             visible={isEVisible}
                      //             axis="rightAxis"
                      //             color="#14A76C"
                      //         />
                      //         <Series
                      //             valueField="westApproachVolume"
                      //             name={t("westApproachVolume")}
                      //             type="line"
                      //             visible={isWVisible}
                      //             axis="rightAxis"
                      //             color="#333333"
                      //         />
                      //         <Series
                      //             valueField="southApproachVolume"
                      //             name={t("southApproachVolume")}
                      //             type="line"
                      //             visible={isSVisible}
                      //             axis="rightAxis"
                      //             color="#009688"
                      //         />

                      //         <ArgumentAxis
                      //             title={t('Date')}
                      //         />

                      //         <ValueAxis
                      //             name="leftAxis"
                      //             position="left"
                      //             showZero={true}
                      //             valueMarginsEnabled={false}
                      //             allowDecimals={false}
                      //         >
                      //             <Title text={t("speedStatistics")} />
                      //         </ValueAxis>

                      //         <ValueAxis
                      //             name="rightAxis"
                      //             position="right"
                      //             showZero={true}
                      //             valueMarginsEnabled={false}
                      //             allowDecimals={false}
                      //         >
                      //             <Title text={t("VolumeDetails")} />
                      //         </ValueAxis>

                      //         <Export enabled={true} />
                      //         <Tooltip
                      //             enabled={true}
                      //             location="edge"
                      //         />
                      //         <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                      //     </Chart>
                      //     <div>
                      //         <CheckBox
                      //           text={t('average')}
                      //           value={isAvgVisible}
                      //           onValueChanged={(e) => setIsAvgVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('postedSpeed')}
                      //           value={isPostedSpeedVisible}
                      //           onValueChanged={(e) => setIsPostedSpeedVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('compliance')}
                      //           value={isComplianceVisible}
                      //           onValueChanged={(e) => setIsComplianceVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('total')}
                      //           value={isTotalVisible}
                      //           onValueChanged={(e) => setIsTotalVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('85th%')}
                      //           value={is85thVisible}
                      //           onValueChanged={(e) => setIs85thVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('north')}
                      //           value={isNVisible}
                      //           onValueChanged={(e) => setIsNVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('east')}
                      //           value={isEVisible}
                      //           onValueChanged={(e) => setIsEVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('south')}
                      //           value={isSVisible}
                      //           onValueChanged={(e) => setIsSVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //         <CheckBox
                      //           text={t('west')}
                      //           value={isWVisible}
                      //           onValueChanged={(e) => setIsWVisible(e.value)}
                      //           style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                      //         />
                      //     </div>
                      // </div>
                    }
                  </div>
                </div>
              )}

            {/* tmc */}
            {selectedDataTraffic?.studies !== undefined &&
              (selectedDataTraffic?.studies?.filter(
                (x) => x.studyType === StudyType.TMC
              )).length > 0 && (
                <div>
                  <p className={`${design.bigLabel} ${design.bolder}`}>
                    {t("tmcStudies")}
                  </p>
                  <div className={design.line} />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridRef}
                      dataSource={selectedDataTraffic.studies.filter(
                        (x) => x.studyType === StudyType.TMC
                      )}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      onRowClick={onRowClicked}
                      hoverStateEnabled={true}
                      allowColumnReordering={true}
                      width={"95%"}
                      style={{ margin: "0 0 0 1rem" }}
                      allowColumnResizing={true}
                      // columnAutoWidth={true}
                      onExporting={OnExporting}
                      onContextMenuPreparing={(e) => {
                        setSelectedRowData(e);
                      }}
                    >
                      <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                      />

                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                      />

                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>

                      {/* <Column alignment="left" dataField="geoId" width={75}
                                                caption={t('geoId')}>
                                            </Column> */}
                      <Column
                        alignment="left"
                        dataField="id"
                        allowSorting={true}
                        caption={t("id")}
                        visible={false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>

                      <Column
                        alignment="left"
                        dataField="startDT"
                        visible={true}
                        dataType="date"
                        format={generalSetting?.dateFormat}
                        caption={t("startDT")}
                      ></Column>

                      <Column
                        dataField="volumeSummary.northApproachVolume"
                        visible={true}
                        caption={t("northApproachVolume")}
                      ></Column>
                      <Column
                        dataField="volumeSummary.southApproachVolume"
                        visible={false}
                        caption={t("southApproachVolume")}
                      ></Column>
                      <Column
                        dataField="volumeSummary.eastApproachVolume"
                        visible={true}
                        caption={t("eastApproachVolume")}
                      ></Column>
                      <Column
                        dataField="volumeSummary.westApproachVolume"
                        visible={false}
                        caption={t("westApproachVolume")}
                      ></Column>

                      <Column
                        dataField="volumeSummary.totalVolume"
                        visible={false}
                        caption={t("totalVolume")}
                      ></Column>
                    </DataGrid>
                  </div>
                </div>
              )}
          </div>

          {/* collision statistics */}
          <div className={`${design.line}`} />
          <p className={`${design.bigLabel} ${design.bolder}`}>
            {t("collision")}
          </p>
          {selectedDataCollision?.severity?.length !== 0 ||
            selectedDataCollision?.severity !== undefined ||
            selectedDataCollision?.impactTypeClient?.length !== 0 ||
            selectedDataCollision?.impactTypeClient !== undefined ||
            (selectedDataCollision !== undefined && (
              <div>
                <p className={`${design.labels}`}>
                  {t("thereIsNoDataForSeverity")}
                </p>
              </div>
            ))}
          {/* collision charts */}
          {
            selectedDataCollision?.severity?.length !== 0 &&
              selectedDataCollision?.severity !== undefined &&
              selectedDataCollision !== undefined && (
                <div style={{ justifyContent: "center", padding: "0 1rem" }}>
                  <Chart
                    id="chart"
                    dataSource={selectedDataCollision?.severity}
                    paletteExtensionMode="alternate"
                  >
                    <Size height={chartHeight} />
                    <CommonSeriesSettings
                      argumentField="description"
                      type={"stackedbar"}
                      barWidth={60}
                    />
                    <Series
                      valueField="injury"
                      name={t("injury")}
                      axis="pdoInjury"
                      color="#008ac5"
                    />

                    <Series
                      valueField="pdo"
                      name={t("PDO")}
                      axis="pdoInjury"
                      color="#00a9b5"
                    />

                    <Series
                      valueField="fatal"
                      name={t("fatal")}
                      type="line"
                      axis="fatalAxix"
                      color="#0b1d78"
                    />

                    <ArgumentAxis title={t("year")} />

                    <ValueAxis
                      name="fatalAxix"
                      position="right"
                      // valueType="fatal"
                      showZero={true}
                      valueMarginsEnabled={false}
                      allowDecimals={false}
                    >
                      <Title text={t("numberOfFatalCollisions")} />
                    </ValueAxis>

                    <ValueAxis
                      name="pdoInjury"
                      position="left"
                      allowDecimals={false}
                      type={"continuous"}
                    >
                      <Title text={t("collisionFrequency")} />
                    </ValueAxis>

                    <Export enabled={true} printingEnabled={false} />
                    <Tooltip
                      enabled={true}
                      location="edge"
                      //  customizeTooltip={this.customizeTooltip}
                    />
                    <Legend
                      verticalAlignment="bottom"
                      horizontalAlignment="center"
                    ></Legend>
                  </Chart>
                </div>
              )
            // :
            // <div>
            //     <p className={`${design.labels}`}>{t('thereIsNoDataForSeverity')}</p>
            // </div>
          }
          {/* TODO:Bahador isXL is  removed, does not show in 15inch */}
          {
            selectedDataCollision?.impactTypeClient?.length !== 0 &&
              selectedDataCollision?.impactTypeClient !== undefined &&
              selectedDataCollision !== undefined && (
                <div style={{ justifyContent: "center", padding: "0 1rem" }}>
                  <PieChart
                    id="pie"
                    dataSource={
                      selectedDataCollision.impactTypeClient
                        ? selectedDataCollision?.impactTypeClient
                        : []
                    }
                    //palette="Ocean"

                    paletteExtensionMode="alternate"
                    title={t("initialImpactType")}
                    onPointClick={pointClickHandler}
                    onLegendClick={legendClickHandler}
                  >
                    <Series argumentField="name" valueField="value">
                      <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>

                    <Size height={chartHeight} />
                    <Export enabled={true} printingEnabled={false} />
                  </PieChart>
                </div>
              )
            // :
            // <div>
            //     <p className={`${design.labels}`}>{t('thereIsNoDataForInitialImpactType')}</p>
            // </div>
          }

          {
            selectedDataCollision !== undefined &&
              selectedDataCollision?.roadSurfaceCondition !== undefined &&
              selectedDataCollision?.roadSurfaceCondition?.length !== 0 && (
                <div style={{ justifyContent: "center", padding: "0 1rem" }}>
                  <PieChart
                    id="pie"
                    dataSource={selectedDataCollision?.roadSurfaceCondition}
                    //palette="Ocean"

                    paletteExtensionMode="alternate"
                    title={t("roadSurfaceCondition")}
                    onPointClick={pointClickHandler}
                    onLegendClick={legendClickHandler}
                  >
                    <Series argumentField="name" valueField="value">
                      <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>

                    <Size height={chartHeight} />
                    <Export enabled={true} printingEnabled={false} />
                  </PieChart>
                </div>
              )
            // :
            // <div>
            //     <p className={`${design.labels}`}>{t('thereIsNoDataForRoadSurface')}</p>
            // </div>
          }

          {
            selectedDataCollision?.lightingCondition?.length !== 0 &&
              selectedDataCollision?.lightingCondition !== undefined &&
              selectedDataCollision !== undefined && (
                <div style={{ justifyContent: "center", padding: "0 1rem" }}>
                  <PieChart
                    id="pie"
                    dataSource={selectedDataCollision?.lightingCondition}
                    //palette="Ocean"
                    paletteExtensionMode="alternate"
                    title={t("lightingCondition")}
                    onPointClick={pointClickHandler}
                    onLegendClick={legendClickHandler}
                  >
                    <Series argumentField="name" valueField="value">
                      <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>

                    <Size height={chartHeight} />
                    <Export enabled={true} printingEnabled={false} />
                  </PieChart>
                </div>
              )
            // :
            // <div>
            //     <p className={`${design.labels}`}>{t('thereIsNoDataForLighteningCondition')}</p>
            // </div>
          }

          {/* <SimpleItem colSpan={2}>
                                <div className={design.filterContainer}>
                                    <FilterBuilder
                                        //fields={fields}
                                        value={filterValue}
                                        onValueChanged={onValueChanged} />
                                </div>
                            </SimpleItem>  */}
        </ScrollView>
      </div>
    </React.Fragment>
  );
};
export default Data;
