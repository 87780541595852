import { VMWebSign } from "./dto/signDto";
import { SupportSetupType } from "./enums/supportEnums";
import { LocationType } from "../infrastructure/enums/infrastructureEnums";
import { ChangeLog, Patrol, TesSign, Attachment } from "./signTypes";
import { StringLengthRule } from "devextreme-react/data-grid";
import { VMWebSupport } from "./dto/supportDto";

export interface ISupportParams {
  [key: string]: any;
  supportId?: string;
}

export class SupportSetup {
  [key: string]: any;
  id!: string;
  customerId!: string;
  setupType!: SupportSetupType;
  index!: number;
  code!: string;
  description!: string;
  tesDefault!: boolean;
  note!: string;
  supportCodeTypes!: SupportCode[];
  supportCodeMaterials!: SupportCode[];
  supportCodeDescriptions!: SupportCode[];
  supportLocationTypes!: Support[];
  supportPositions!: Support[];
  supportConditions!: Support[];
  isDeleted!: boolean;
  deleteDate!: Date;
}

export class SupportCode {
  id!: string;
  customerId!: string;
  tesApplicationId!: string;
  index!: number;
  code!: string;
  supportType!: SupportSetup;
  supportTypeId!: typeof StringLengthRule;
  supportMaterial!: SupportSetup;
  supportMaterialId!: string;
  supportDescription!: string;
  SupportDescriptionId!: string;
  labourCost!: number;
  installationCost!: number;
  materialCost!: number;
  supports!: Support[];
  note!: string;
  isDeleted!: boolean;
  deleteDate!: Date;
}

export class Support {
  constructor() {
    this.signs = [];
  }
  [key: string]: any;
  id!: string;
  customerId!: string;
  version!: number;
  changeLogs!: ChangeLog[];
  supportId!: string;
  locationId!: string;
  locationDescription!: string;
  locationType!: LocationType;
  geoId!: string;
  signs!: TesSign[];
  supportLocationType!: SupportSetup[];
  supportLocationTypeId!: string;
  latitude!: number;
  longitude!: number;
  xCoord!: number;
  yCoord!: number;
  supportCode!: SupportCode;
  supportCodeId!: string;
  height!: string;
  supportPosition!: SupportSetup;
  supportPositionId!: string;
  dateInstalled!: Date;
  dateExpired!: Date;
  supportCondition!: SupportSetup;
  supportConditionId!: string;
  note!: string;
  patrols!: Patrol[];
  attachments!: Attachment[];
  materialCost!: number;
  labourCost!: number;
  total!: number;
  installationCost!: number;
  isDeleted!: boolean;
  deleteDate!: Date;
}

export class SignSupportList {
  signs!: VMWebSign[];
  supports!: VMWebSupport[];
}
