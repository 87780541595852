import React, { useMemo, useCallback, useState, useContext } from "react";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import "./user-panel.scss";
import { useNavigate } from "react-router-dom";
import { useClientSetting } from "../../contexts/clientSetting";
import useClaims from "../../apis/claims";
import i18n from "../../react-i18next";
import { useTranslations } from "../../contexts/translations";
import { useTranslationHelper } from "../../contexts/translations/tranlationHelper";
import { TMSPermissions } from "../../constants/Permissions";

interface IProps {
  menuMode: "context" | "list";
}

export default function UserPanel({ menuMode }: IProps) {
  const { user, logOut } = useAuth();
  const { cleanGeneralSetting } = useClientSetting();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: claims, isLoading } = useClaims();
  const history = useNavigate();
  const { toggleFeature, isFeatureActive } = useTranslationHelper();

  const {
    selectedLanguage,
    availableTranslations,
    onChangeLanguage,
    clearStoredTranslations,
  } = useTranslations();

  const navigateToClient = useCallback(() => {
    history("/switchClient");
  }, [history]);

  const navigateToProfile = useCallback(() => {
    history("/profile");
  }, [history]);

  const removeAllContext = useCallback(() => {
    clearStoredTranslations();
    if (cleanGeneralSetting) cleanGeneralSetting();
    if (logOut) logOut();
    // window.location.replace(localStorage.getItem('logoutUrl')!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut, cleanGeneralSetting]);

  const handleLanguageSelection = useCallback((language: string) => {
    onChangeLanguage(language);
  }, []);

  const viewHandler = useCallback((e: any) => {
    if (e.itemData.text === "Compact View") {
      localStorage.setItem(
        "GeneralUISetting",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("GeneralUISetting")!),
          viewMode: "compact",
        })
      );
    } else if (e.itemData.text === "Normal View") {
      localStorage.setItem(
        "GeneralUISetting",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("GeneralUISetting")!),
          viewMode: "normal",
        })
      );
    }
    window.location.reload();
  }, []);

  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  const getLanguageItemsMenu = () => {
    const langMenuItems: any[] = [];

    if (availableTranslations) {
      availableTranslations.map((item) => {
        langMenuItems.push({
          text: item === "defaultEn" ? "en" : item,
          icon:
            selectedLanguage === item
              ? "fa-solid fa-check check-icon"
              : "check-icon",
          onClick: () => handleLanguageSelection(item),
        });
      });
    }
    return langMenuItems;
  };

  const onTranslationHelperClick = () => {
    toggleFeature();
  };

  const menuItems = useMemo(
    () => [
      {
        text: i18n.t("profile"),
        icon: "fa-solid fa-user",
        onClick: navigateToProfile,
      },
      {
        text: compactViewModel ? i18n.t("normalView") : i18n.t("compactView"),
        icon: compactViewModel
          ? "fa-solid fa-maximize"
          : "fa-solid fa-minimize",
        onClick: viewHandler,
      },
      {
        text: i18n.t("language"),
        icon: "fa-solid fa-language",
        items: getLanguageItemsMenu(),
      },
      ...(user && user.permissions?.includes(TMSPermissions.TMS_D_Translation)
        ? [
            {
              text: isFeatureActive
                ? i18n.t("deactivateTranslationHelper")
                : i18n.t("activateTranslationHelper"),
              icon: "fa-solid fa-language",
              onClick: onTranslationHelperClick,
            },
          ]
        : []),
      {
        text: i18n.t("client"),
        icon: "fa-solid fa-user-group",
        onClick: navigateToClient,
      },
      {
        text: i18n.t("logout"),
        icon: "fa-solid fa-right-from-bracket",
        onClick: removeAllContext,
      },
    ],
    [
      handleLanguageSelection,
      navigateToProfile,
      navigateToClient,
      removeAllContext,
      viewHandler,
      selectedLanguage,
      availableTranslations,
      isFeatureActive,
    ]
  );

  return (
    <div className="user-panel">
      <div className="user-info">
        <div className="image-container">
          <div
            style={{
              backgroundSize: "cover",
            }}
            className="user-image"
          />
        </div>
        <div className="user-name">{user?.userName}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target=".user-button"
          showEvent="dxclick"
          width="100%"
          cssClass="user-menu"
          itemRender={(data) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <i className={`fa-fw ${data.icon}`} />
                <span>{data.text}</span>
              </div>
            );
          }}
        >
          <Position
            my={{ x: "top", y: "center" }}
            at={{ x: "bottom", y: "center" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className="dx-toolbar-menu-action" items={menuItems} />
      )}
    </div>
  );
}
