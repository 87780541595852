import { TextBox } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, SimpleItem } from "devextreme-react/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { GeoCodeGroup } from "../../../../../../types/collision/collisionTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";

// props
interface IPros {
  onValueChange: (key: string, value: any) => void;
  initData: GeoCodeGroup;
  setInitData: React.Dispatch<React.SetStateAction<GeoCodeGroup>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <React.Fragment>
      {/* <ValidationGroup
                ref={props.validationRef}
            > */}
      <div className="row">
        <Form colCount={2}>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t("title")}
              labelMode="floating"
              value={props.initData?.title}
              onValueChange={(e) => props.onValueChange("title", e)}
              disabled={props.isLocked}
              showClearButton={true}
            />
          </SimpleItem>
        </Form>
      </div>
      {/* </ValidationGroup> */}
    </React.Fragment>
  );
};

export default Details;
