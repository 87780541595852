import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Lookup,
  MasterDetail,
  Export,
  SortByGroupSummaryInfo,
  Summary,
  GroupItem,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import VolumeDetailTemplate from "./volumeDetailTemplate";
import { NameValue } from "../../../../../../../types/general/generalTypes";
import { Enum2Array } from "../../../../../../../utils/enumTools";
import { ApproachType } from "../../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { OnExporting } from "../../../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../../../utils/arrayTools";

// props
interface IPros {
  data: any;
}
const DetailTemplate = (props: IPros) => {
  const [lstApproachType, setLstApproachType] = useState<NameValue[]>([]);
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  useEffect(() => {
    async function fetchMyAPI() {
      setLstApproachType(Enum2Array(ApproachType));
    }
    fetchMyAPI();
  }, []);

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={props.data.data.details}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />

          <HeaderFilter visible={true} />

          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <Column
            alignment="left"
            dataField="id"
            allowSorting={true}
            caption={t("id")}
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
          <Summary>
            <GroupItem summaryType="count" alignByColumn name="Total Count" />
          </Summary>

          <Column dataField="approach" caption={t("approach")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(lstApproachType, "name")}
              valueExpr="value"
              displayExpr="name"
            />
          </Column>
          <Column dataField="lane" caption={t("lane")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="totalPedestrian"
            caption={t("totalPedestrian")}
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="total" caption={t("total")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <MasterDetail enabled={true} component={VolumeDetailTemplate} />
        </DataGrid>
      </div>
    </React.Fragment>
  );
};
export default DetailTemplate;
