import { ApplicationName } from "../log/enums/logEnums";

export class AppVersion {
  id!: string;
  applicationName!: ApplicationName;
  currentVersion!: number;
  minVersion!: number;
  downloadURL!: string;
  iosDownloadURL!: string;
  androidDownloadURl!: string;
}
