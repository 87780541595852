import {
  Button,
  ContextMenu,
  Popup,
  ScrollView,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../../contexts/clientSetting";
import { CollisionApiUrl } from "../../../../../../environment/routeSettings";
import { GeoCodeGroup } from "../../../../../../types/collision/collisionTypes";
import {
  ApprovalStatus,
  CollisionDataSourceType,
  GeoCodeStatus,
} from "../../../../../../types/collision/enums/collisionEnums";
import {
  FieldLocation2Show,
  FieldType,
} from "../../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../../types/field/fieldType";
import {
  GridType,
  ResponseCode,
} from "../../../../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  RequestResponseResult,
} from "../../../../../../types/general/generalTypes";
import { TesCodeValue } from "../../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { GridActualDateCalculator } from "../../../../../../utils/dateTimeTools";
import { Enum2Array, EnumFlag2Array } from "../../../../../../utils/enumTools";
import { RequestErrorHandling, TesGet } from "../../../../../../utils/rest";
import { RightClickMenu } from "../../../../../../utils/rightClickMenu";

// props
interface IPros {
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
  initData: GeoCodeGroup;
  setInitData: React.Dispatch<React.SetStateAction<GeoCodeGroup>>;
  setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
  initDataCollision: any[];
  initDataFields: TesField[];
  dataSource: CustomStore<any, any>;
  lstLocationType: NameValue[];
  initDataTesCodeValues: TesCodeValue;
  initDataDivisions: INameId[];
  generalSetting: GeneralSetting | null;
}

const Collisions = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridRefModal = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { activeLoading } = useAuth();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const [lstCollisionDataSourceType, setLstCollisionDataSourceType] = useState<
    NameValue[]
  >([]);
  const [lstApprovalStatus, setLstApprovalStatus] = useState<NameValue[]>([]);
  const [lstOriginalSourceType, setLstOriginalSourceType] = useState<
    NameValue[]
  >([]);

  const items = [
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
    { text: t("openCollision"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('geoCode'), icon:  "fa-solid fa-eye"},
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
        setLstCollisionDataSourceType(Enum2Array(CollisionDataSourceType));
        setLstApprovalStatus(Enum2Array(ApprovalStatus));
        setLstOriginalSourceType(Enum2Array(CollisionDataSourceType));

        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onOpenPopUp() {
    setShowModal(true);
  }

  function onCloseModal() {
    dataGridRefModal.current?.instance().deselectAll();
    setShowModal(false);
  }

  async function onAdd() {
    var gridData = await dataGridRefModal.current
      ?.instance()
      .getSelectedRowsData();
    // console.log(gridData)
    // if (gridData?.length !== 0) {
    //     props.setInitData({
    //         ...props.initData,
    //         generals: gridData as any[],
    //         lstCollisionIds: gridData?.map(x => x.id) as string[]
    //     })
    // }
    props.setInitDataCollision([
      ...props.initDataCollision,
      ...(gridData as any[]),
    ]);
    onCloseModal();
  }

  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            console.log(props.initDataCollision);
            props.setInitDataCollision([
              ...props.initDataCollision.filter((x) => x.Id !== d.row.data.Id),
            ]);
            dataGridRef.current?.instance().refresh();
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  const startGeoCoding = useCallback(async () => {
    if (activeLoading) activeLoading(true);
    const res = (await TesGet(
      CollisionApiUrl() +
        "/api/xmlGeoCode/GetFirstCollision2GeoCode/" +
        props.initData.id,
      true
    )) as RequestResponseResult<string>;
    if (activeLoading) activeLoading(false);
    if (res.responseCode === ResponseCode.OK) {
      history("/collision/geoCoding/" + res.results);
    } else if (res.responseCode === ResponseCode.Collision4GeoCodeNotExists) {
      notify(t("collision4GeoCodeNotExists"), "error", 5000);
    } else {
      await RequestErrorHandling(res);
    }
  }, [history, props.initData?.id]);

  return (
    <div
      className={`geoCodeGroupDetails-collisions ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData?.text === "Open Location") {
              RightClickMenu(
                e,
                selectedRowData.row.data.Id,
                "",
                selectedRowData.row.data.LocationType,
                selectedRowData.row.data.LocationId
              );
            }
            if (e.itemData?.text === "Open Collision") {
              window.open(
                `/collision/collisionDetails/${selectedRowData.row.data._id}`,
                "_blank"
              );
            }
            // if (e.itemData?.text === "GeoCode") {}
          }}
        />
        <ValidationGroup ref={props.validationRef}>
          <div className="row">
            <div
              className="rightColumn"
              style={{ marginTop: "1rem", marginRight: "1.5rem" }}
            >
              {/* <Permission
                  allowed={[
                ]}
                  hasFeedBackElement={false}
                > */}
              <Button
                onClick={() => onOpenPopUp()}
                //onClick={() => onAdd()}
                icon="fa-solid fa-link"
                text={t("collision")}
              />
              {/* </Permission> */}
            </div>
            <div
              className="leftColumn"
              style={{ marginTop: "1rem", marginLeft: "1.5rem" }}
            >
              <Button
                onClick={() => startGeoCoding()}
                icon="fa-solid fa-location"
                hint={t("startGeoCoding")}
                text={t("startGeoCoding")}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: 20, padding: 10 }}>
            {props.initDataFields && props.initDataFields.length > 0 && (
              <DataGrid
                id="collisionsGridContainer"
                ref={dataGridRef}
                dataSource={props.initDataCollision}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                // remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                style={{ margin: "0 1rem" }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                {/* <StateStoring
                                    enabled={true}
                                    type="localStorage"
                                    storageKey={GridType.Collision.toString()}
                                    savingTimeout={500}
                                /> */}
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="_id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="GeoId"
                  allowSorting={true}
                  caption={t("geoId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="LocationDescription"
                  allowHeaderFiltering={false}
                  width={300}
                  caption={t("locationDescription")}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="MapLocation.Latitude"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("latitude")}
                  format={{ type: "fixedPoint", precision: 4 }}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="MapLocation.Longitude"
                  allowSorting={true}
                  allowHeaderFiltering={false}
                  caption={t("longitude")}
                  format={{ type: "fixedPoint", precision: 4 }}
                  visible={false}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="LocationType"
                  allowHeaderFiltering={true}
                  caption={t("locationType")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      props.lstLocationType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="MunicipalityId"
                  visible={
                    props.initDataTesCodeValues?.municipalities?.length !== 0 &&
                    props.initDataTesCodeValues.municipalities !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("municipality")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      props.initDataTesCodeValues?.municipalities,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={props.initDataTesCodeValues?.municipalities?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="JurisdictionId"
                  visible={
                    props.initDataTesCodeValues.jurisdictions?.length !== 0 &&
                    props.initDataTesCodeValues.jurisdictions !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("jurisdiction")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      props.initDataTesCodeValues?.jurisdictions,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={props.initDataTesCodeValues?.jurisdictions?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="GeoCodeStatus" caption={t("geoCodeStatus")}>
                  <Lookup
                    dataSource={SortObjectByPropName(lstGeoCodeStatus, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="EditedSubmissionDT"
                  caption={t("lastEditDT")}
                  visible={true}
                  dataType="datetime"
                  format={props.generalSetting?.dateFormat}
                ></Column>
                <Column
                  dataField="Year"
                  dataType="number"
                  alignment="left"
                  sortOrder={"desc"}
                  allowHeaderFiltering={true}
                  caption={t("accidentYear")}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DataSourceType"
                  caption={t("dataSourceType")}
                  visible={false}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstCollisionDataSourceType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                {props.initDataDivisions &&
                  props.initDataDivisions.length > 0 && (
                    <Column dataField="DivisionId" caption={t("division")}>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          props.initDataDivisions,
                          "name"
                        )}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  )}
                {props.initDataFields.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(
                            d.fieldValues,
                            "name"
                          )}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, props.generalSetting)
                        }
                        format={props.generalSetting?.dateFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, props.generalSetting)
                        }
                        format={props.generalSetting?.timeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter visible={false}>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, props.generalSetting)
                        }
                        format={props.generalSetting?.dateTimeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowHeaderFiltering={false}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
                <Column
                  dataField="ApproveLevel"
                  caption={t("approveLevel")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="ApprovalStatus"
                  caption={t("approvalStatus")}
                  visible={false}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstApprovalStatus, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="GeoCodeGroupId"
                  caption={t("geoCodeGroupId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="LocationId"
                  caption={t("locationId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="OriginalSourcetype"
                  caption={t("originalSourceType")}
                  visible={false}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstOriginalSourceType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  width={85}
                  fixed={true}
                  fixedPosition="right"
                >
                  {!props.isLocked && (
                    <DevExpressButton
                      hint={t("delete")}
                      onClick={onDelete}
                      icon="fa-solid fa-trash-can"
                    ></DevExpressButton>
                  )}
                  {!props.isLocked && (
                    <DevExpressButton
                      hint={t("openCollision")}
                      onClick={(e) =>
                        window.open(
                          `/collision/collisionDetails/${selectedRowData.row.data._id}`,
                          "_blank"
                        )
                      }
                      icon="fa-solid fa-car-burst"
                    ></DevExpressButton>
                  )}
                </Column>
              </DataGrid>
            )}
          </div>
        </ValidationGroup>

        {/* Popup for collisions to geo code*/}
        <Popup
          width={"80%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("add")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            {props.initDataFields && props.initDataFields.length > 0 && (
              <DataGrid
                id="gridContainer"
                key="_id"
                ref={dataGridRefModal}
                dataSource={props.dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRefModal?.current?.instance().deselectAll()}}
                style={{ margin: "0 1rem" }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.CollisionAssignGeoCode.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                  deferred={true}
                  // @ts-ignore
                  maxFilterLengthInRequest={10000}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="_id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="GeoId"
                  allowSorting={true}
                  caption={t("geoId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="LocationDescription"
                  allowHeaderFiltering={false}
                  width={300}
                  caption={t("locationDescription")}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="SubmissionDT"
                  caption={t("submissionDT")}
                  dataType="datetime"
                  visible={true}
                  format={props.generalSetting?.dateTimeFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="LocationType"
                  allowHeaderFiltering={true}
                  caption={t("locationType")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      props.lstLocationType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="GeoCodeStatus"
                  caption={t("geoCodeStatus")}
                  visible={false}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstGeoCodeStatus, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="MunicipalityId"
                  visible={
                    props.initDataTesCodeValues?.municipalities?.length !== 0 &&
                    props.initDataTesCodeValues.municipalities !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("municipality")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      props.initDataTesCodeValues?.municipalities,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={props.initDataTesCodeValues?.municipalities?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="JurisdictionId"
                  visible={
                    props.initDataTesCodeValues.jurisdictions?.length !== 0 &&
                    props.initDataTesCodeValues.jurisdictions !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("jurisdiction")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      props.initDataTesCodeValues?.jurisdictions,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={props.initDataTesCodeValues?.jurisdictions?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Year"
                  dataType="number"
                  alignment="left"
                  sortOrder={"desc"}
                  allowHeaderFiltering={true}
                  caption={t("accidentYear")}
                >
                  <Search enabled />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                {props.initDataDivisions &&
                  props.initDataDivisions.length > 0 && (
                    <Column dataField="DivisionId" caption={t("division")}>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          props.initDataDivisions,
                          "name"
                        )}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  )}
                {props.initDataFields.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(
                            d.fieldValues,
                            "name"
                          )}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, props.generalSetting)
                        }
                        format={props.generalSetting?.dateFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        allowHeaderFiltering={false}
                        allowGrouping={false}
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, props.generalSetting)
                        }
                        format={props.generalSetting?.timeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter visible={false}>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, props.generalSetting)
                        }
                        format={props.generalSetting?.dateTimeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowHeaderFiltering={false}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
              </DataGrid>
            )}
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20, marginTop: "10px" }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                <Button
                  style={{ marginTop: "10px" }}
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </div>
  );
};
export default Collisions;
