import {
  FieldType,
  SimplifiedFieldCategory,
  SimplifiedTesField,
} from "../../../../types/field/enums/fieldEnums";

const data = [
  {
    name: "GeoId",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.GeoId,
  },
  {
    name: "LocationDescription",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.LocationDescription,
  },
  {
    name: "IsIntersection",
    fieldType: FieldType.Boolean,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.IsIntersection,
  },
  {
    name: "SectionType",
    fieldType: FieldType.TesEnum,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.SectionType,
    values: [
      { name: "OneWayRoad", value: 3 },
      { name: "TwoWayRoad", value: 4 },
    ],
  },
  {
    name: "SectionDetailType",
    fieldType: FieldType.TesEnum,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.SectionDetailType,
    values: [
      { name: "NorthOneWayRoad", value: 6 },
      { name: "EastOneWayRoad", value: 7 },
      { name: "SouthOneWayRoad", value: 8 },
      { name: "WestOneWayRoad", value: 9 },
      { name: "NorthSouthTwoWayRoad", value: 10 },
      { name: "EastWestTwoWayRoad", value: 11 },
    ],
  },
  {
    name: "PostedSpeed",
    fieldType: FieldType.Number,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.PostedSpeed,
  },
  {
    name: "Length",
    fieldType: FieldType.Number,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.Length,
  },
  {
    name: "RIN",
    fieldType: FieldType.String,
    simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedRoadSegment,
    simplifiedTesField: SimplifiedTesField.RIN,
  },
];

export default data;
