import {
  SelectBox,
  TextBox,
  ValidationGroup,
  Validator,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../../constants/Permissions/SafetyPermissions";
import { SafetyApiUrl } from "../../../../../../environment/routeSettings";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { SiteSubTypeDTO } from "../../../../../../types/safety/dto/safetyDtos";
import {
  SiteSubTypeAnalysisType,
  SiteSubTypeServerityType,
} from "../../../../../../types/safety/enums/siteTypeEnums";
import { SpfFunction } from "../../../../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { TesGet } from "../../../../../../utils/rest";

// props
interface IPros {
  initData: SiteSubTypeDTO;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const General = (props: IPros) => {
  const { t } = useTranslation();
  const [lstAnalysisType, setAnalysisLstType] = useState<NameValue[]>([]);
  const [lstSeverityType, setLstSeverityType] = useState<NameValue[]>([]);
  const [SPFFunctions, setSPFFunctions] = useState<SpfFunction[]>([
    new SpfFunction(),
  ]);
  const [rendered, setRendered] = useState<boolean>(true);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (rendered) {
          await getSPFFunctions();
          setAnalysisLstType(Enum2Array(SiteSubTypeAnalysisType));
          setLstSeverityType(Enum2Array(SiteSubTypeServerityType));
        }
        setRendered(false);
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
  });

  async function getSPFFunctions() {
    setSPFFunctions(
      await TesGet(
        SafetyApiUrl() +
          "/api/Settings/GetCustomerSpfFunction/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function onValueChange(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value });
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <ValidationGroup ref={props.validationRef}>
            <Form colCount={2}>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("description")}
                  disabled={props.isLocked}
                  labelMode="floating"
                  value={props.initData.description}
                  onValueChange={(e) => onValueChange("description", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </TextBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("analysisType")}
                  labelMode="floating"
                  disabled={props.isLocked}
                  value={props.initData.type}
                  onValueChange={(e) => onValueChange("type", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstAnalysisType, "name")}
                  valueExpr={"value"}
                  displayExpr={"name"}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              {/* <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("serverityType")}
                labelMode='floating'
                value={props.initData.serverityType}
                onValueChange={(e) => onValueChange("serverityType", e)}
                className="modalInput"
                valueExpr={"value"}
                displayExpr={"name"}
                items={lstSeverityType}
                showClearButton={true}
                searchEnabled={true}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </SelectBox>
            </SimpleItem> */}
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("fatalInjurySpfFunction")}
                  disabled={props.isLocked}
                  labelMode="floating"
                  value={props.initData.fatalInjurySpfFunctionId}
                  onValueChange={(e) =>
                    onValueChange("fatalInjurySpfFunctionId", e)
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(SPFFunctions, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("pdoSpfFunctionId")}
                  disabled={props.isLocked}
                  labelMode="floating"
                  value={props.initData.pdoSpfFunctionId}
                  onValueChange={(e) => onValueChange("pdoSpfFunctionId", e)}
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(SPFFunctions, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
            </Form>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default General;
