import React from "react";
import "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  Lookup,
  ColumnChooser,
  Export,
  Search,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { t } from "i18next";
import { OnExporting } from "../../utils/dataGridTools";
import { SortObjectByPropName } from "../../utils/arrayTools";

const displayData = () => (
  <React.Fragment>
    <h2 className={"content-block"}>{t("displayData")}</h2>

    <DataGrid
      className={"dx-card wide-card"}
      dataSource={dataSource as any}
      showBorders={false}
      focusedRowEnabled={true}
      defaultFocusedRowIndex={0}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      onExporting={OnExporting}
    >
      <Export enabled={true} allowExportSelectedData={true} />
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector={true} showInfo={true} />
      <FilterRow visible={true} />
      <ColumnChooser
        width={350}
        height={400}
        enabled={true}
        mode="select"
        sortOrder="asc"
      >
        <Search enabled />
      </ColumnChooser>
      <Column
        alignment="left"
        dataField="id"
        allowSorting={true}
        caption={t("id")}
        visible={false}
      >
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField={"Task_ID"} width={90} hidingPriority={2} />
      <Column
        dataField={"Task_Subject"}
        width={190}
        caption={t("subject")}
        hidingPriority={8}
      />
      <Column
        dataField={"Task_Status"}
        caption={t("status")}
        hidingPriority={6}
      />
      <Column
        dataField={"Task_Priority"}
        caption={t("priority")}
        hidingPriority={5}
      >
        <Lookup
          dataSource={SortObjectByPropName(priorities, "name")}
          valueExpr={"value"}
          displayExpr={"name"}
        />
      </Column>
      <Column
        dataField={"ResponsibleEmployee.Employee_Full_Name"}
        caption={t("assignedTo")}
        allowSorting={false}
        hidingPriority={7}
      />
      <Column
        dataField={"Task_Start_Date"}
        caption={t("startDT")}
        dataType={"date"}
        hidingPriority={3}
      />
      <Column
        dataField={"Task_Due_Date"}
        caption={t("dueDT")}
        dataType={"date"}
        hidingPriority={4}
      />
      <Column
        dataField={"Task_Priority"}
        caption={t("startDT")}
        hidingPriority={1}
      />
      <Column
        dataField={"Task_Completion"}
        caption={t("completion")}
        hidingPriority={0}
      />
    </DataGrid>
  </React.Fragment>
);

const dataSource = {
  store: {
    type: "odata",
    key: "Task_ID",
    url: "https://js.devexpress.com/Demos/DevAV/odata/Tasks",
  },
  expand: "ResponsibleEmployee",
  select: [
    "Task_ID",
    "Task_Subject",
    "Task_Start_Date",
    "Task_Due_Date",
    "Task_Status",
    "Task_Priority",
    "Task_Completion",
    "ResponsibleEmployee/Employee_Full_Name",
  ],
};

const priorities = [
  { name: "High", value: 4 },
  { name: "Urgent", value: 3 },
  { name: "Normal", value: 2 },
  { name: "Low", value: 1 },
];

export default displayData;
