import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "general",
    icon: "fa-solid fa-info",
    content: "General tab content",
  },
  {
    id: 1,
    text: "divisions",
    icon: "fa-solid fa-layer-group",
    content: "Divisions tab content",
  },
];

const withoutDivisionTabTitles = [
  {
    id: 0,
    text: "general",
    icon: "fa-solid fa-info",
    content: "General tab content",
  },
];

export { tabTitles, withoutDivisionTabTitles };
