import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MessageType,
  ResponseCode,
} from "../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../types/general/generalTypes";
import { EmpiricalBayesAnalysis } from "../../../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../utils/enumTools";

// props
interface IPros {
  isLocked: boolean;
  initData: EmpiricalBayesAnalysis;
}
const Results = (props: IPros) => {
  const [lstErrorType, setLstErrorType] = useState<NameValue[]>([]);
  const [lstResponseCode, setResponseCode] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setLstErrorType(Enum2Array(MessageType));
    setResponseCode(Enum2Array(ResponseCode));
  }, []);

  return (
    <React.Fragment>
      <Form colCount={2}>
        <SimpleItem colSpan={2}>
          <div className="row" style={{ marginTop: 10, padding: 10 }}>
            <h6
              className={"content-block"}
              style={{ margin: "0rem 1rem 1rem 1rem" }}
            >
              {t("errors")}
            </h6>
            <DataGrid
              id="grid"
              ref={dataGridRef}
              dataSource={props.initData.errors?.messages ?? []}
              allowColumnReordering={true}
              rowAlternationEnabled={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              showBorders={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column minWidth={90} dataField="type" caption={t("type")}>
                <Lookup
                  dataSource={SortObjectByPropName(lstErrorType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>

              <Column
                minWidth={90}
                dataField="messageCode"
                caption={t("messageCode")}
              >
                <Lookup
                  dataSource={SortObjectByPropName(lstResponseCode, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
              <Column
                minWidth={90}
                dataField="message"
                caption={t("message")}
              ></Column>
            </DataGrid>
          </div>
        </SimpleItem>
      </Form>
    </React.Fragment>
  );
};
export default Results;
