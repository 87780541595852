import { TabTitle } from "../../../../../types/general/generalTypes";
import i18n from "../../../../../react-i18next";
i18n.loadNamespaces("sidebar");
export default function TabsData(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 0,
        text: i18n.t("filter"),
        icon: "fa-solid fa-filter",
        content: "Filter tab content",
      },
    ])
  );
}
