import i18n from "../../../../react-i18next";
import { TabTitle } from "../../../../types/general/generalTypes";
export default function TabsDataUpdate(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 0,
        text: "summary",
        icon: "fa-solid fa-list-ul",
        content: "General tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 1,
        text: "general",
        icon: "fa-solid fa-circle-info",
        content: "General tab content",
      },
      {
        id: 2,
        text: "location",
        icon: "fa-solid fa-map",
        content: "Location tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 3,
        text: "details",
        icon: "fa-solid fa-road",
        content: "Details tab content",
      },
      {
        id: 4,
        text: "approachDetails",
        icon: "fa-solid fa-compress",
        content: "Approach Details tab content",
      },
      {
        id: 5,
        text: "operation",
        icon: "fa-solid fa-sliders",
        content: "Operation tab content",
      },
      {
        id: 6,
        text: "collisions",
        icon: "fa-solid fa-car-burst",
        content: "Collisions tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 7,
        text: "trafficStudies",
        icon: "fa-solid fa-traffic-light",
        content: "Traffic Studies tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 8,
        text: "aadt",
        icon: "fa-solid fa-calendar",
        content: "aadt tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 9,
        text: "signs",
        icon: "fa-solid fa-triangle-exclamation",
        content: "Signs tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 10,
        text: "supports",
        icon: "fa-solid fa-sign-hanging",
        content: "Supports tab content",
        disabled: id === "AddNew" ? true : false,
      },
    ])
  );
}

export function TabsDataAdd(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 1,
        text: "general",
        icon: "fa-solid fa-circle-info",
        content: "General tab content",
      },
      {
        id: 3,
        text: "details",
        icon: "fa-solid fa-road",
        content: "Details tab content",
      },
      {
        id: 4,
        text: "approachDetails",
        icon: "fa-solid fa-compress",
        content: "Approach Details tab content",
      },
      {
        id: 5,
        text: "operation",
        icon: "fa-solid fa-sliders",
        content: "Operation tab content",
      },
    ])
  );
}
