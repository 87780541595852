import {
  NumberBox,
  SelectBox,
  TextArea,
  TextBox,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DateBox from "devextreme-react/date-box";
import { Form, SimpleItem } from "devextreme-react/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { VMAppSetups } from "../../../../../types/sign/dto/signDto";
import { Support } from "../../../../../types/sign/supportTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";

// props
interface IPros {
  initDataSetups: VMAppSetups;
  initDataSupport: Support;
  onValueChange: (name: string, value: any) => void;
  onChangeFields: (name: string, value: string) => void;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={2} className="popupFields">
            <SimpleItem colSpan={1}>
              <TextBox
                style={{ marginBottom: 20 }}
                label={t("supportId")}
                labelMode="floating"
                value={props.initDataSupport.supportId}
                onValueChange={(e) => props.onValueChange("supportId", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("supportCode")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataSupport.supportCodeId}
                onValueChange={(e) => props.onValueChange("supportCodeId", e)}
                className="modalInput"
                items={SortObjectByPropName(
                  props.initDataSetups?.supportCode,
                  "name"
                )}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>

            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("position")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataSupport.supportPositionId}
                onValueChange={(e) =>
                  props.onValueChange("supportPositionId", e)
                }
                className="modalInput"
                items={SortObjectByPropName(
                  props.initDataSetups?.supportPosition,
                  "name"
                )}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>

            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("condition")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataSupport.supportConditionId}
                onValueChange={(e) =>
                  props.onValueChange("supportConditionId", e)
                }
                className="modalInput"
                items={SortObjectByPropName(
                  props.initDataSetups?.supportCondition,
                  "name"
                )}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("locationType")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataSupport.supportLocationTypeId}
                onValueChange={(e) =>
                  props.onValueChange("supportLocationTypeId", e)
                }
                className="modalInput"
                items={SortObjectByPropName(
                  props.initDataSetups?.supportLocationType,
                  "name"
                )}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                style={{ marginBottom: 20 }}
                label={t("height")}
                labelMode="floating"
                value={props.initDataSupport.height}
                onValueChange={(e) => props.onValueChange("height", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>

            <SimpleItem colSpan={1}>
              <DateBox
                label={t("dateInstalled")}
                defaultValue={props.initDataSupport.dateInstalled}
                type="date"
                onValueChange={(e) => props.onValueChange("dateInstalled", e)}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                disabled={props.isLocked}
                showClearButton={true}
                style={{ marginBottom: "1rem" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("assignDate")}
                defaultValue={props.initDataSupport.dateExpired}
                type="date"
                onValueChange={(e) => props.onValueChange("dateExpired", e)}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                disabled={props.isLocked}
                showClearButton={true}
                style={{ marginBottom: "1rem" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("materialCost")}
                labelMode="floating"
                defaultValue={props.initDataSupport.materialCost}
                onValueChange={(e) => props.onValueChange("materialCost", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>

            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("labourCost")}
                labelMode="floating"
                value={props.initDataSupport.labourCost}
                onValueChange={(e) => props.onValueChange("labourCost", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("installationCost")}
                labelMode="floating"
                value={props.initDataSupport.installationCost}
                onValueChange={(e) =>
                  props.onValueChange("installationCost", e)
                }
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("totalCost")}
                labelMode="floating"
                value={
                  (props.initDataSupport.installationCost
                    ? props.initDataSupport.installationCost
                    : 0) +
                  (props.initDataSupport.labourCost
                    ? props.initDataSupport.labourCost
                    : 0) +
                  (props.initDataSupport.materialCost
                    ? props.initDataSupport.materialCost
                    : 0)
                }
                disabled={props.isLocked}
                onValueChange={(e) => props.onValueChange("totalCost", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextArea
                label={t("note")}
                labelMode="floating"
                height={50}
                value={props.initDataSupport.note}
                onValueChange={(e) => props.onValueChange("note", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
          </Form>
        </div>
      </ValidationGroup>
    </React.Fragment>
  );
};
export default Details;
