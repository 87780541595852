//#region imports
import { ScrollView, SelectBox, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import DateBox from "devextreme-react/date-box";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useAuth } from "../../../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";
import {
  ModalMode,
  ResponseCode,
} from "../../../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../../../types/general/generalTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";
import {
  ReportingPeriodDTO,
  StudyBaseDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import { ReportingPeriodType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { RequestErrorHandling, TesGet } from "../../../../../../utils/rest";
//#endregion imports

// props
interface IPros {
  initDataBase: StudyBaseDTO;
  reportingPeriodValidation: React.RefObject<ValidationGroupRef>;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const ReportingPeriod = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<ReportingPeriodDTO>(
    new ReportingPeriodDTO()
  );
  const [lstReportingPeriodType, setLstReportingPeriodType] = useState<
    NameValue[]
  >([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstReportingPeriodType(Enum2Array(ReportingPeriodType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onNew() {
    setSelectedData(new ReportingPeriodDTO());
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    setShowModal(false);
  }

  function onCancel() {
    props.setDataChanged(false);
    setShowModal(false);
  }

  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value });
    props.setDataChanged(true);
  }

  function onAdd() {
    props.setInitDataBase({
      ...props.initDataBase,
      reportingPeriods: [
        ...props.initDataBase.reportingPeriods,
        { ...selectedData, id: uuid() },
      ],
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setSelectedData(d.row.data);
    setShowModal(true);
  }

  function onUpdate() {
    const reportingPeriods = props.initDataBase.reportingPeriods;
    const selectedDataIndex: number = reportingPeriods.findIndex(
      (x: ReportingPeriodDTO) => x.id === selectedData.id
    );
    reportingPeriods[selectedDataIndex] = selectedData;
    props.setInitDataBase({
      ...props.initDataBase,
      reportingPeriods: reportingPeriods,
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  async function onReset() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TrafficApiUrl() +
          "/api/Study/GetReportingPeriod/" +
          props.initDataBase.customerId +
          "/" +
          props.initDataBase.studyType,
        true
      )) as RequestResponseResult<ReportingPeriodDTO[]>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        props.setInitDataBase({
          ...props.initDataBase,
          reportingPeriods: res.results,
        });
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  //#endregion functions
  return (
    <div
      className={`studyDetails-reportingPeriod ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ margin: "1rem" }}>
          <ValidationGroup ref={props.validationRef}>
            <DataGrid
              id="reportingPeriodGridContainer"
              ref={dataGridRef}
              dataSource={SortObjectByPropName(
                props.initDataBase.reportingPeriods,
                "type"
              )}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem 1rem" }}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="customerId"
                caption={t("customerId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="label" caption={t("label")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="startTime"
                caption={t("startTime")}
                dataType="datetime"
                visible={true}
                format={props.generalSettings?.isAmPm ? "shortTime" : "HH:mm"}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="endTime"
                caption={t("endTime")}
                dataType="datetime"
                visible={true}
                format={props.generalSettings?.isAmPm ? "shortTime" : "HH:mm"}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="type" caption={t("type")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(
                    lstReportingPeriodType,
                    "name"
                  )}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>

              <Column
                type="buttons"
                caption={t("actions")}
                width={110}
                visible={!props.isLocked}
                fixed={true}
                fixedPosition="right"
              >
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                {/* <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                    </DevExpressButton> */}
              </Column>
            </DataGrid>

            {/* Popup for adding new Report Period */}
            <Popup
              width={"50%"}
              height={"auto"}
              visible={showModal}
              resizeEnabled={true}
              showTitle={true}
              title={modalMode === ModalMode.Add ? t("add") : t("update")}
              hideOnOutsideClick={false}
              showCloseButton={true}
              onHiding={() => onCloseModal()}
            >
              <ScrollView width="100%" height="100%">
                <ValidationGroup ref={props.reportingPeriodValidation}>
                  <Form colCount={2} className="popupFields">
                    <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("label")}
                        labelMode="floating"
                        value={selectedData.label}
                        onValueChange={(e) => onValueChange("label", e)}
                        className="modalInput"
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </TextBox>
                    </SimpleItem>
                    <SimpleItem>
                      <DateBox
                        type="time"
                        label={t("startTime")}
                        labelMode="floating"
                        value={selectedData.startTime}
                        onValueChange={(e) => onValueChange("startTime", e)}
                        pickerType="rollers"
                        placeholder={props.generalSettings?.timeFormat}
                        displayFormat={props.generalSettings?.timeFormat}
                        useMaskBehavior={true}
                        openOnFieldClick={true}
                        showClearButton={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </DateBox>
                    </SimpleItem>
                    <SimpleItem>
                      <DateBox
                        type="time"
                        label={t("endTime")}
                        labelMode="floating"
                        value={selectedData.endTime}
                        onValueChange={(e) => onValueChange("endTime", e)}
                        pickerType="rollers"
                        placeholder={props.generalSettings?.timeFormat}
                        displayFormat={props.generalSettings?.timeFormat}
                        useMaskBehavior={true}
                        openOnFieldClick={true}
                        showClearButton={true}
                      >
                        <Validator>
                          <RequiredRule message={t("fieldIsRequired")} />
                        </Validator>
                      </DateBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("type")}
                        labelMode="floating"
                        value={selectedData.type}
                        onValueChange={(e) => onValueChange("type", e)}
                        items={SortObjectByPropName(
                          lstReportingPeriodType,
                          "name"
                        )}
                        valueExpr="value"
                        displayExpr="name"
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </SimpleItem>
                  </Form>
                </ValidationGroup>
                <div>
                  <div className="rightColumn">
                    <Button
                      className="tes-modal-btn-cancel"
                      style={{ marginRight: 20, marginTop: "10px" }}
                      onClick={() => onCancel()}
                      text={t("cancel")}
                    />
                    {modalMode === ModalMode.Add ? (
                      <Button
                        style={{ marginTop: "10px" }}
                        className="tes-modal-btn-add"
                        onClick={onAdd}
                        text={t("add")}
                      />
                    ) : (
                      <Button
                        style={{ marginTop: "10px" }}
                        className="tes-modal-btn-add"
                        onClick={onUpdate}
                        text={t("update")}
                      />
                    )}
                  </div>
                </div>
              </ScrollView>
            </Popup>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default ReportingPeriod;
