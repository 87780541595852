import { useEffect, useState } from "react";
import "./Rule.scss";
import {
  Popup,
  ScrollView,
  SelectBox,
  Button,
  TextBox,
  TextArea,
} from "devextreme-react";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  Button as DevExpressButton,
  Export,
  Search,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { TesGet } from "../../utils/rest";
import { VMField } from "../../types/field/dto/fieldDTO";
import { INameId } from "../../types/general/generalTypes";
import FieldConstants from "../../types/field/constants/fieldConstants";
import notify from "devextreme/ui/notify";
import {
  FieldCategoryType,
  FieldServiceType,
  FieldType,
  SimplifiedFieldCategory,
} from "../../types/field/enums/fieldEnums";
import { Rule2View } from "../../utils/simplifiedFieldTools";
import { useAuth } from "../../contexts/auth";
import { FieldApiUrl } from "../../environment/routeSettings";
import { OnExporting } from "../../utils/dataGridTools";
import { SortObjectByPropName } from "../../utils/arrayTools";
interface IPros {
  fieldServiceType?: FieldServiceType;
  simplifiedFieldCategory?: SimplifiedFieldCategory;
  defaultRuleValue?: string;
  name: string;
  onValueChange: (name: string, value: any) => void;
  //children: any
}

const Rule = (props: IPros) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rule, setRule] = useState<string>("");
  const [initDataFields, setInitDataFields] = useState<VMField[]>([]);
  const [in_, setIn_] = useState<string>("");
  const [category, setCategory] = useState<FieldCategoryType>();
  const [categoryText, setCategoryText] = useState<string>("");
  const [field, setField] = useState<string>("");
  const [selectedFieldType, setSelectedFieldType] = useState<FieldType>(1);
  const [operation, setOperation] = useState<string>("");
  const [lstFields, setLstFields] = useState<VMField[]>([]);
  const [value, setValue] = useState<string>("");
  const [lstFieldValues, setLstFieldValues] = useState<INameId[]>([]);
  const [ruleView, setRuleView] = useState<string>("");
  const { activeLoading } = useAuth();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialFieldData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    if (showModal) {
      fetchMyAPI();
      setRule(props.defaultRuleValue ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const getInitialFieldData = async () => {
    let res = await TesGet(
      FieldApiUrl() +
        "/api/TesFields/GetFieldsByServiceType/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        props.fieldServiceType,
      true
    );
    setInitDataFields(res);
  };
  const onChangeCategory = (value: FieldCategoryType) => {
    setValue("");
    setLstFields([]);
    setCategory(value);
    if (value === FieldCategoryType.CollisionGeneral) {
      setCategoryText("General");
      setLstFields(
        initDataFields.filter(
          (x) => x.fieldCategoryType === FieldCategoryType.CollisionGeneral
        )
      );
    }
    if (value === FieldCategoryType.CollisionRoad) {
      setCategoryText("Road");
      setLstFields(
        initDataFields.filter(
          (x) => x.fieldCategoryType === FieldCategoryType.CollisionRoad
        )
      );
    }
    if (value === FieldCategoryType.CollisionVehicle) {
      setCategoryText("Vehicle");
      setLstFields(
        initDataFields.filter(
          (x) => x.fieldCategoryType === FieldCategoryType.CollisionVehicle
        )
      );
    }
    if (value === FieldCategoryType.CollisionDriver) {
      setCategoryText("Driver");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType === FieldCategoryType.CollisionDriver ||
            x.fieldCategoryType === FieldCategoryType.CollisionPerson
        )
      );
    }
    if (value === FieldCategoryType.CollisionPassenger) {
      setCategoryText("Passenger");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType === FieldCategoryType.CollisionPassenger ||
            x.fieldCategoryType === FieldCategoryType.CollisionPerson
        )
      );
    }

    if (value === FieldCategoryType.CollisionPedestrian) {
      setCategoryText("Pedestrian");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType === FieldCategoryType.CollisionPedestrian ||
            x.fieldCategoryType === FieldCategoryType.CollisionPerson
        )
      );
    }

    if (value === FieldCategoryType.CollisionPerson) {
      setCategoryText("Person");
      setLstFields(
        initDataFields.filter(
          (x) => x.fieldCategoryType === FieldCategoryType.CollisionPerson
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureIntersectionGeneral) {
      setCategoryText("General");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureIntersectionGeneral
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureIntersectionApproachDetails) {
      setCategoryText("ApproachDetails");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureIntersectionApproachDetails
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureIntersectionDetails) {
      setCategoryText("Details");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureIntersectionDetails
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureIntersectionOperation) {
      setCategoryText("Operation");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureIntersectionOperation
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureMidBlockGeneral) {
      setCategoryText("General");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureMidBlockGeneral
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureMidBlockCrossSection) {
      setCategoryText("CrossSection");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureMidBlockCrossSection
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureMidBlockDetails) {
      setCategoryText("Details");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureMidBlockDetails
        )
      );
    }
    if (value === FieldCategoryType.InfrastructureMidBlockOperation) {
      setCategoryText("Operation");
      setLstFields(
        initDataFields.filter(
          (x) =>
            x.fieldCategoryType ===
            FieldCategoryType.InfrastructureMidBlockOperation
        )
      );
    }
  };

  function refreshFields() {
    setIn_("");
    setCategory(0);
    setSelectedFieldType(1);
    setCategoryText("");
    setField("");
    setValue("");
    setLstFieldValues([]);
    setOperation("");
    setLstFields([]);
  }

  const onAddRule = () => {
    if (in_ === "" || categoryText === "" || field === "") {
      notify(t("fillAllInputs") + ".", "error", 5000);
      return;
    }

    if (
      rule !== "" &&
      !rule.endsWith(" OR ") &&
      !rule.endsWith(" AND ") &&
      !rule.endsWith(" AND (") &&
      !rule.endsWith(" OR (")
    ) {
      notify(t("addCondition") + ".", "error", 5000);
      return;
    }

    setRule(
      rule +
        "[" +
        in_ +
        "." +
        categoryText +
        "." +
        field +
        "]" +
        operation +
        value
    );
    refreshFields();
  };

  useEffect(() => {
    onRecalculate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rule]);

  const onRecalculate = () => {
    setRuleView(Rule2View(rule, initDataFields));
  };

  const onAndRule = () => {
    if (rule.endsWith(" AND ") || rule.endsWith(" OR ") || rule === "") {
      notify(t("addRuleAfterCondition") + ".", "error", 5000);
      return;
    }
    setRule(`${rule.replace(/  +/g, " ")} AND `);
  };

  const onOrRule = () => {
    if (rule.endsWith(" AND ") || rule.endsWith(" OR ") || rule === "") {
      notify(t("addRuleAfterCondition") + ".", "error", 5000);
      return;
    }
    setRule(`${rule.replace(/  +/g, " ")} OR `);
  };

  const onChangeField = (value: string) => {
    setField(value);
    var res = lstFields.find((x) => x.name === value);
    if (res !== undefined) {
      setSelectedFieldType(res.fieldType);
      setLstFieldValues(res.fieldValues);
    }
  };

  function onCloseModal() {
    refreshFields();
    setShowModal(false);
  }

  const onSave = () => {
    var noOpenParenthesis = (rule.match(/\(/g) || []).length;
    var noCloseParenthesis = (rule.match(/\)/g) || []).length;
    if (noOpenParenthesis !== noCloseParenthesis) {
      notify(t("noParenthesisError") + ".", "error", 5000);
      return;
    }
    var finalRule = rule;

    if (finalRule.endsWith(" AND ")) {
      var lastIndex = finalRule.lastIndexOf(" AND ");
      finalRule = finalRule
        .substring(0, lastIndex)
        .replace(/\(\s+/g, "(")
        .replace(/\s\)+/g, ")")
        .replace(/  +/g, " ")
        .trim();
      setRule(finalRule);
    } else if (finalRule.endsWith(" OR ")) {
      let lastIndex = finalRule.lastIndexOf(" OR ");
      finalRule = finalRule
        .substring(0, lastIndex)
        .replace(/\(\s+/g, "(")
        .replace(/\s\)+/g, ")")
        .replace(/  +/g, " ")
        .trim();
      setRule(finalRule);
    } else {
      finalRule = finalRule
        .replace(/\(\s+/g, "(")
        .replace(/\s\)+/g, ")")
        .replace(/  +/g, " ")
        .trim();
      setRule(finalRule);
    }

    props.onValueChange(props.name, finalRule);
    onCloseModal();

    refreshFields();
  };

  return (
    <div>
      <Button
        onClick={() => setShowModal(true)}
        text={t("addRule")}
        className="tes-modal-btn-add"
      />

      <Popup
        visible={showModal}
        width={"60%"}
        height={"75%"}
        resizeEnabled={true}
        showTitle={true}
        title={t("ruleEditor")}
        // hideOnOutsideClick={false}
        // hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={13}>
            <SimpleItem colSpan={3}>
              <SelectBox
                placeholder=""
                label={t("in")}
                labelMode="floating"
                value={in_}
                onValueChange={(e) => setIn_(e)}
                items={SortObjectByPropName(FieldConstants().in_, "name")}
                valueExpr="id"
                displayExpr="name"
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={5}>
              <SelectBox
                placeholder=""
                label={t("category")}
                labelMode="floating"
                value={category}
                onValueChange={(e) => onChangeCategory(e)}
                items={
                  props.simplifiedFieldCategory ===
                  SimplifiedFieldCategory.SimplifiedIntersection
                    ? SortObjectByPropName(
                        FieldConstants().IntersectionCategory,
                        "name"
                      )
                    : props.simplifiedFieldCategory ===
                      SimplifiedFieldCategory.SimplifiedRoadSegment
                    ? SortObjectByPropName(
                        FieldConstants().RoadSegmentCategory,
                        "name"
                      )
                    : SortObjectByPropName(
                        FieldConstants().collisionCategory,
                        "name"
                      )
                }
                valueExpr="id"
                displayExpr="name"
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={5}>
              <SelectBox
                placeholder=""
                label={t("field")}
                labelMode="floating"
                valueExpr="name"
                displayExpr="name"
                value={field}
                onValueChange={(e) => onChangeField(e)}
                items={SortObjectByPropName(lstFields, "name")}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={3}>
              <SelectBox
                placeholder=""
                label={t("operation")}
                labelMode="floating"
                value={operation}
                onValueChange={(e) => setOperation(e)}
                items={SortObjectByPropName(FieldConstants().operation, "name")}
                valueExpr="id"
                displayExpr="name"
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem
              colSpan={5}
              visible={
                selectedFieldType !== FieldType.List &&
                !(in_ === "SortAsc" || in_ === "SortDesc")
              }
            >
              <TextBox
                label={t("value")}
                labelMode="floating"
                value={value}
                onValueChange={(e) => setValue(e)}
              />
            </SimpleItem>
            <SimpleItem
              colSpan={5}
              visible={
                selectedFieldType === FieldType.List &&
                !(in_ === "SortAsc" || in_ === "SortDesc")
              }
            >
              <SelectBox
                placeholder=""
                label={t("value")}
                labelMode="floating"
                value={value}
                onValueChange={(e) => setValue(e)}
                items={SortObjectByPropName(lstFieldValues, "name")}
                displayExpr="name"
                valueExpr="id"
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>

            <SimpleItem
              colSpan={5}
              visible={in_ === "SortAsc" || in_ === "SortDesc"}
            >
              <SelectBox
                placeholder=""
                label={t("value")}
                labelMode="floating"
                value={value}
                onValueChange={(e) => setValue(e)}
                items={SortObjectByPropName(lstFields, "name")}
                displayExpr="name"
                valueExpr="name"
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={5} />
            <SimpleItem colSpan={2}>
              <Button width={"100%"} onClick={() => onAddRule()}>
                {t("add")}
              </Button>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <Button width={"100%"} onClick={() => onAndRule()}>
                {t("and")}
              </Button>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <Button width={"100%"} onClick={() => onOrRule()}>
                {t("or")}
              </Button>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <Button width={"100%"} onClick={() => onRecalculate()}>
                {t("recalculate")}
              </Button>
            </SimpleItem>
          </Form>

          <Form colCount={15}>
            <SimpleItem colSpan={1}>
              <div className="center label">{t("tesRule")}:</div>
            </SimpleItem>
            <SimpleItem colSpan={14}>
              <TextArea
                autoResizeEnabled={true}
                minHeight={70}
                value={rule}
                onValueChange={(e) => {
                  setRule(
                    e
                      .replace(/\(\s+/g, "(")
                      .replace(/\s\)+/g, ")")
                      .replace(/  +/g, " ")
                  );
                }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div className="center label">{t("rule")}:</div>
            </SimpleItem>
            <SimpleItem colSpan={14}>
              <TextArea
                autoResizeEnabled={true}
                minHeight={70}
                readOnly={true}
                value={ruleView}
              />
            </SimpleItem>
          </Form>

          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ margin: "20px 10px 0px 10px" }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              <Button
                className="tes-modal-btn-add"
                style={{ margin: "20px 10px 0px 10px" }}
                onClick={() => onSave()}
                text={t("save")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>

      <Popup
        width={"95%"}
        height="auto"
        // visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("edit")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <DataGrid
            // dataSource={initDataCodeGroups}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={OnExporting}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} /> {/* or "auto" */}
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={"top"} />
            <Paging enabled={true} defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 100]}
              showNavigationButtons={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={285} placeholder={t("search")} />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <Column
              alignment="left"
              dataField="id"
              allowSorting={true}
              caption={t("id")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="name" caption={t("name")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {/* <Column dataField="fieldValue" caption={t("fieldValue")} visible={true}>
                <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                {selectedRowData.fieldType === FieldType.TesEnum && <Lookup
                  dataSource={selectedRowData.values}
                  valueExpr="value"
                  displayExpr="name"
                />}
              </Column>
              <Column caption={t("rule")} visible={true}
                calculateCellValue={(x: { rule: string }) =>
                  Rule2View(x.rule, initDataFields)
                }
              >
                <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
              </Column> */}
            <Column
              type="buttons"
              caption={t("actions")}
              fixed={true}
              fixedPosition="right"
              width={110}
            >
              <DevExpressButton
                hint={t("edit")}
                //onClick={onSecondModalEdit}
                icon="fa-solid fa-pencil"
              ></DevExpressButton>
            </Column>
          </DataGrid>
          <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
            <Button
              className="tes-modal-btn-cancel"
              style={{ marginRight: 20 }}
              onClick={() => onCloseModal()}
              text={t("cancel")}
            />
            <Button
              className="tes-modal-btn-add"
              //onClick={onSave}
              text={t("save")}
            />
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
};

export default Rule;
