import { LoadPanel } from "devextreme-react/load-panel";

const LoadingScreen = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.4)",
        backdropFilter: "blur(4px)",
        zIndex: 2000,
      }}
    >
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        // onHiding={}
        visible={true}
        showIndicator={true}
        shading={true}
        showPane={true}
        // hideOnOutsideClick={hideOnOutsideClick}
      />
      {/* <p
        style={{
          fontSize: '1.2rem',
          fontWeight: 'bold',
          pointerEvents: 'auto',
          margin: '1rem',
          backgroundImage: 'linear-gradient(90deg, #555, #F0B70D, #555)',
          backgroundSize: '200% 200%', // Larger size for animation
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          animation: 'gradientAnimation 2s infinite ease-in-out',
        }}
      >
        Loading...
      </p> */}
      {/* <style>
        {`
          @keyframes gradientAnimation {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}
      </style> */}
    </div>
  );
};

export function CheckLoadingStates(values: boolean[]): boolean {
  return values.some((value) => value === true);
}

export default LoadingScreen;
