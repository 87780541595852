import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import ShareURL from "../../../../components/shareURL/shareURL";
import {
  SignPermissions,
  TMSPermissions,
} from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import {
  CollisionApiUrl,
  FieldApiUrl,
} from "../../../../environment/routeSettings";
import {
  IXMLSettingParam,
  XmlImportSetting,
} from "../../../../types/collision/collisionTypes";
import { XMLImportType } from "../../../../types/collision/enums/collisionEnums";
import { FieldServiceType } from "../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../types/field/fieldType";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import { Enum2Array } from "../../../../utils/enumTools";
import {
  RequestErrorHandling,
  TesDelete,
  TesGet,
  TesPost,
} from "../../../../utils/rest";
import Details from "./components/details/details";
import FieldSettings from "./components/fieldSettings/fieldSettings";
import tabTitles from "./data/index";
import "./xmlImportSettingDetails.scss";
import TesTabs from "../../../../components/tesTab/tesTab";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const XLImportSettingDetails = () => {
  const history = useNavigate();
  const params = useParams<IXMLSettingParam>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initData, setInitData] = useState<XmlImportSetting>(
    new XmlImportSetting()
  );
  const [lstXMLImportTypes, setLstXMLImportTypes] = useState<NameValue[]>([]);

  const validationRef = useRef<ValidationGroupRef>(null);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const [dataChanged, setDataChanged] = useState(false);
  const [backPermission, setBackPermission] = useState(false);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const [fields, setFields] = useState<TesField[]>([]);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);

  useBrowserTitle({
    pathname: "/collision/import/xml/xmlImportSettingDetails",
    additionalText: `${initData.title}`,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getFields();
        setLstXMLImportTypes(Enum2Array(XMLImportType));
        if (params.xmlSettingId !== "AddNew") {
          await getInitData(params.xmlSettingId!);
        } else {
          setIsLocked(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.xmlSettingId]);

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        CollisionApiUrl() + "/api/XMLImport/GetXMLImportSettingDetails/" + id,
        true
      )) as RequestResponseResult<XmlImportSetting>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  const onValueChange = (name: string, value: any) => {
    setInitData({ ...initData, [name]: value });
  };

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.xmlSettingId !== "AddNew") {
                  //updateSign();
                  setDataChanged(false);
                } else {
                  //addSign();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }
  async function getFields() {
    setFields(
      await TesGet(
        FieldApiUrl() +
          "/api/TesFields/GetAllFields/" +
          localStorage.getItem("selectedCustomerId") +
          "/" +
          FieldServiceType.Collision,
        true
      )
    );
  }

  async function onUpdate() {
    // const validationRes = validationRef.current?.instance().validate();
    // if (validationRes?.isValid) {
    if (activeLoading) activeLoading(true);
    let postObj = {
      ...initData,
    };
    console.log(postObj);
    const res = (await TesPost(
      CollisionApiUrl() + "/api/XMLImport/UpdateXMLImportSetting",
      postObj,
      true
    )) as RequestResponseResult<XmlImportSetting>;
    if (activeLoading) activeLoading(false);
    if (res.responseCode === ResponseCode.OK) {
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      setDataChanged(false);
    } else if (res.responseCode === ResponseCode.WorkOrderExist) {
      notify(t("workOrderExist"), "error", 5000);
    } else {
      await RequestErrorHandling(res);
    }
  }
  async function onAdd() {
    // const validationRes = validationRef.current?.instance().validate();
    // if (validationRes?.isValid) {
    try {
      const postObj: XmlImportSetting = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId") as string,
      };
      if (activeLoading) activeLoading(true);
      const res: RequestResponseResult<XmlImportSetting> = await TesPost(
        CollisionApiUrl() + "/api/XMLImport/AddXMLImportSetting",
        postObj,
        true
      );
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        await getInitData(params.xmlSettingId!);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
        return;
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
      // }
    }
    setDataChanged(false);
  }

  async function onDelete() {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                CollisionApiUrl() +
                  "/api/XMLImport/DeleteXMLImportSetting/" +
                  initData.id,
                true
              );
              history(-1);
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
              return;
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  //Detail Functions

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div
        className={`xmlImportSettingDetails ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.xmlSettingId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p
                    ref={headingParagraphRef}
                    className={"detailsHeading"}
                    style={{ display: "inline" }}
                  >
                    <span>
                      {t("title")}: {initData?.title}
                    </span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={goBackPermission}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[SignPermissions.Sign_E]}
                    hasFeedBackElement={false}
                  >
                    {params.xmlSettingId !== "AddNew" && <ShareURL url={url} />}
                    <Button
                      onClick={() => LockHandler()}
                      icon={
                        isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                      }
                      hint={isLocked ? t("unlock") : t("lock")}
                    />
                  </Permission>
                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[SignPermissions.Sign_D]}
                    hasFeedBackElement={false}
                  >
                    {params.xmlSettingId === "AddNew" && (
                      <Button
                        onClick={onAdd}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    )}
                  </Permission>
                  <Permission
                    allowed={[SignPermissions.Sign_E]}
                    hasFeedBackElement={false}
                  >
                    {params.xmlSettingId !== "AddNew" && isLocked === false && (
                      <Button
                        onClick={onUpdate}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    )}
                    <Permission
                      allowed={[TMSPermissions.TMS_Admin]}
                      hasFeedBackElement={false}
                    >
                      {!isLocked &&
                        !window.location.href.includes("AddNew") && (
                          <Button
                            onClick={onDelete}
                            icon="fa-solid fa-trash"
                            hint={t("delete")}
                          />
                        )}
                    </Permission>
                  </Permission>
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />

              {selectedIndex === 0 && (
                <Details
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  validationRef={validationRef}
                  onValueChange={onValueChange}
                  initData={initData}
                  setInitData={setInitData}
                  lstXMLImportTypes={lstXMLImportTypes}
                />
              )}
              {selectedIndex === 1 && (
                <FieldSettings
                  isLocked={isLocked}
                  validationRef={validationRef}
                  initData={initData}
                  setInitData={setInitData}
                  fields={fields}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default XLImportSettingDetails;
