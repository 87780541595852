import { ValidationGroup } from "devextreme-react";
import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import reportLocations from "../../../../../../constants/reportLocations";
import { GeneralSetting } from "../../../../../../contexts/clientSetting";
import {
  StudyBaseDTO,
  StudyTmcWebDetailsDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import Diagram from "./components/diagram";
import Details from "./components/details";
import tabTitles from "./data/data";
import TesTabs from "../../../../../../components/tesTab/tesTab";
import ReportSelector from "../../../../../../components/reportSelector";
import { getSelectedRows } from "../../../../../../components/tesGrid/utils";
import { TRecord } from "../../../../../../components/reportSelector/types";

// props
interface IPros {
  initDataBase: StudyBaseDTO;
  initDataTmc: StudyTmcWebDetailsDTO;
  generalSettings: GeneralSetting | null;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}
const TMCResults = (props: IPros) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [sortedInitDataTMC, setSortedInitDataTMC] =
    useState<StudyTmcWebDetailsDTO>(props.initDataTmc);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    // const sortedData = {
    //   ...props.initDataTmc,
    //   result: SortObjectByPropName(props.initDataTmc.result, "periodType")
    // };

    // const updatedResult = [
    //   { ...(sortedData.result![0] as any), details: sortedData.result![0].details.filter((x: { startDT: string }) => new Date(x.startDT).getHours() >= new Date(sortedData.result![0].startDT).getHours()) },
    //   { ...(sortedData.result![1] as any), details: sortedData.result![1].details.filter((x: { startDT: string }) => new Date(x.startDT).getHours() >= new Date(sortedData.result![1].startDT).getHours()) },
    //   { ...(sortedData.result![2] as any), details: sortedData.result![2].details.filter((x: { startDT: string }) => new Date(x.startDT).getHours() >= new Date(sortedData.result![2].startDT).getHours()) },
    // ];

    // setSortedInitDataTMC({
    //   ...sortedData,
    //   result: updatedResult
    // });

    setSortedInitDataTMC({
      ...props.initDataTmc,
      result: SortObjectByPropName(props.initDataTmc.result, "periodType"),
    });
  }, []);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }
  function onCloseReportModal() {
    dataGridRef.current?.instance().deselectAll();
  }

  const getSelectedRowsData = async () => {
    const selectedRows = await getSelectedRows(dataGridRef.current);
    const records: TRecord[] = selectedRows.map((item) => {
      console.log(item);
      return {
        id: props.initDataBase.id!,
        endDT: item.endDT,
        startDT: item.startDT,
      };
    });
    if (records.length === 0) {
      return [
        {
          id: props.initDataBase.id!,
        },
      ];
    }
    return records;
  };

  return (
    <React.Fragment>
      <div className="row" style={{ margin: "1rem" }}>
        <ValidationGroup ref={props.validationRef}>
          <div
            className={"dx-card"}
            style={
              compactViewModel
                ? { margin: "0rem 1rem -0.75rem 1rem" }
                : { margin: "0rem 1rem 2rem 1rem" }
            }
          >
            <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
              <div className="leftColumn">
                {selectedIndex === 1 && (
                  <ReportSelector
                    reportLocations={[
                      reportLocations.TrafficStudy_StudyDetails_TmcResults,
                    ]}
                    getRecords={getSelectedRowsData}
                    onCloseReporterModal={onCloseReportModal}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={"dx-card "} style={{ margin: "1rem" }}>
            <TesTabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onTabChange={handleTabChange}
            />
            {selectedIndex === 0 && (
              <Diagram
                generalSettings={props.generalSettings}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataTmc={sortedInitDataTMC}
              />
            )}
            {selectedIndex === 1 && (
              <Details
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
                generalSettings={props.generalSettings}
                initDataTmc={sortedInitDataTMC}
                dataGridRef={dataGridRef}
              />
            )}
          </div>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default TMCResults;
