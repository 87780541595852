//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  //Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
  Button as DevExpressButton,
} from "devextreme-react/data-grid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { InfrastructureApiUrl } from "../../../environment/routeSettings";
import {
  GridType,
  ResponseCode,
} from "../../../types/general/enums/generalEnums";
import { RequestErrorHandling, TesDelete, TesPost } from "../../../utils/rest";
import "./area.scss";
//import { Area } from "../../../types/infrastructure/infrastructureTypes";
import { ContextMenu, Lookup } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { useClientSetting } from "../../../contexts/clientSetting";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import { custom } from "devextreme/ui/dialog";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const SiteList = () => {
  const [initData, setInitData] = useState({});
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  useBrowserTitle({ pathname: "/infrastructure/siteList" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    const postObj = {
      customerId: localStorage.getItem("selectedCustomerId"),
      divisionId: localStorage.getItem("selectedDivisionId"),
    };
    const res = await TesPost(
      InfrastructureApiUrl() + "/api/areas/",
      postObj,
      true
    );
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const navigateToSiteListDetails = useCallback(() => {
    history("/infrastructure/siteListDetails/AddNew");
  }, [history]);

  // function onEdit(d: any) {
  //   let newData = new Area();
  //   Object.assign(newData, d.row.data);
  // }

  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            const res = await TesDelete(
              InfrastructureApiUrl() + "/api/Areas/DeleteArea/" + d.row.data.id,
              true
            );

            if (res.responseCode === ResponseCode.OK) {
              await getInitData();
              dataGridRef.current?.instance().refresh();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            }

            await RequestErrorHandling(res);

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(`/infrastructure/siteListDetails/${e.data.id}`, "_blank");
    } else {
      history(`/infrastructure/siteListDetails/${e.data.id}`);
    }
  }

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Area]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.key.id,
                "/infrastructure/siteListDetails/"
              )
            }
          />
          <h2 className={"content-block"}>{t("siteList")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToSiteListDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("siteList")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.SiteList.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                {/* <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton> 
              </Column> */}
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      customerDivisions!,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  width={110}
                  fixed={true}
                  fixedPosition="right"
                >
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SiteList;
