import { Form, SelectBox, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { SimpleItem } from "devextreme-react/form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfrastructureLocationPicker from "../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { VMMapLocation } from "../../../../../types/collision/dto/collisionDtos";
import { TesMapModule } from "../../../../../types/general/enums/generalEnums";
import { VMLocation } from "../../../../../types/infrastructure/dto/locationdDto";
import { LocationPickerMode } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { VMAppSetups } from "../../../../../types/sign/dto/signDto";
import { Support } from "../../../../../types/sign/supportTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";

// props
interface IPros {
  initDataSupport: Support;
  initDataSetups: VMAppSetups;
  onChangeFields: (name: string, value: any) => void;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  onValueChange: (name: string, value: any) => void;
  setInitDataSupport: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>;
  tempMapLocation: VMMapLocation;
  mapLocation: VMMapLocation;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Location = (props: IPros) => {
  const [initDataLocation, setInitDataLocation] = useState<VMLocation>();

  const { t } = useTranslation();

  useEffect(() => {
    async function fetchMyAPI() {
      setInitDataLocation({
        customerId: props.initDataSupport.customerId,
        id: props.initDataSupport.locationId,
      });
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setInfrastructureData(inData: VMLocation) {
    props.setInitDataSupport({
      ...props.initDataSupport,
      locationId: inData.id,
      locationDescription: inData.description,
      locationType: inData.locationType,
      geoId: inData.geoId,
      jurisdictionId: inData.jurisdictionId,
      municipalityId: inData.municipalityId,
    });
  }
  function handleLocationChange(loc: VMMapLocation) {
    props.setMapLocation(loc);
  }

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <div style={{ marginTop: "-0.5rem" }}>
                <InfrastructureLocationPicker
                  didMount={true}
                  renderMap={true}
                  setInfrastructureData={setInfrastructureData}
                  initLocationData={initDataLocation}
                  tesModule={TesMapModule.CollisionDetails}
                  handleLocationChange={handleLocationChange}
                  tempMapLocation={props.tempMapLocation}
                  selectedMapLocation={props.mapLocation}
                  componentMode={LocationPickerMode.TextBox}
                  renderCoordinate={true}
                  showIcons={true}
                />
              </div>
            </SimpleItem>
            <SimpleItem>
              <SelectBox
                placeholder=""
                label={t("locationType")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataSupport.supportLocationTypeId}
                onValueChange={(e) =>
                  props.onChangeFields("supportLocationTypeId", e)
                }
                className="modalInput"
                items={SortObjectByPropName(
                  props.initDataSetups?.supportLocationType,
                  "name"
                )}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
          </Form>
        </div>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Location;
