import i18n from "../../../../../../react-i18next";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: "diagram",
    icon: "fa-solid fa-magnifying-glass-chart",
    content: "diagram",
  },
];

export default tabTitles;
