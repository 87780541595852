import { t } from "i18next";

const tabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details",
  },
  {
    id: 1,
    text: "password",
    icon: "fa-solid fa-key",
    content: "Password",
  },
  {
    id: 2,
    text: "roles",
    icon: "fa-solid fa-user-gear",
    content: "Roles",
  },
  {
    id: 3,
    text: "divisions",
    icon: "fa-solid fa-table-cells",
    content: "Divisions",
  },
  {
    id: 4,
    text: "clients",
    icon: "fa-solid fa-users",
    content: "Clients",
  },
  {
    id: 5,
    text: "permissions",
    icon: "fa-solid fa-user-lock",
    content: "Permissions",
  },
  {
    id: 6,
    text: "fieldPermissions",
    icon: "fa-solid fa-file-circle-minus",
    content: "Field Permissions",
  },
];
export default tabTitles;
