import { Form } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import React from "react";
import InfrastructureLocationPicker from "../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { CustomerCollision } from "../../../../../types/collision/collisionTypes";
import { VMMapLocation } from "../../../../../types/collision/dto/collisionDtos";
import { TesMapModule } from "../../../../../types/general/enums/generalEnums";
import { VMLocation } from "../../../../../types/infrastructure/dto/locationdDto";
import { LocationPickerMode } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { CountStationDTO } from "../../../../../types/infrastructure/dto/infrstructureDtos";

// props
interface IPros {
  didMount: boolean;
  initDataCountStation: CountStationDTO;
  tempMapLocation: VMMapLocation;
  validationRef: React.RefObject<ValidationGroupRef>;
  mapLocation: VMMapLocation;
  setInitDataCountStation: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>;
  setTempMapLocation: React.Dispatch<React.SetStateAction<VMMapLocation>>;
  isLocked: boolean;
}

const Locations = (props: IPros) => {
  //#region consts

  function handleLocationChange(loc: VMMapLocation) {
    props.setMapLocation(loc);
  }

  function setInfrastructureData(inData: VMLocation) {
    props.setInitDataCountStation({
      ...props.initDataCountStation,
      locationId: inData.id,
      description: inData.description,
      divisionId: inData.divisionId,
      longitude: inData.latitude,
      latitude: inData.latitude,
    });
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 10, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <InfrastructureLocationPicker
                renderMap={true}
                didMount={props.didMount}
                setInfrastructureData={setInfrastructureData}
                initLocationData={{
                  customerId: props.initDataCountStation.customerId,
                  id: props.initDataCountStation.locationId,
                }}
                tesModule={TesMapModule.CollisionDetails}
                handleLocationChange={handleLocationChange}
                tempMapLocation={props.tempMapLocation}
                componentMode={LocationPickerMode.TextBox}
                selectedMapLocation={props.mapLocation}
                renderCoordinate={true}
                isLocked={props.isLocked}
                showIcons={true}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default Locations;
