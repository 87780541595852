import {
  EnvironmentType,
  OracleConnectionType,
  ReleaseChannel,
  TesAppVersionType,
} from "./enums/phoenixEnums";

export class CustomerEnvironment {
  id!: string;
  customerId!: string;
  name!: string;
  appVersion!: AppVersion;
  appVersionId!: string;
  getLastVersion!: boolean;
  environmentVersion!: number;
  isDefault!: boolean;
  environmentType!: EnvironmentType;
  username!: string;
  password!: string;
  oracleConnectionType!: OracleConnectionType;
  serverAddress!: string;
  isStartMap!: boolean;
  cloudVersion!: number;
}
export class VMCustomerEnvironment extends CustomerEnvironment {
  port!: number;
  serviceName!: string;
  sid!: string;
  databaseName!: string;
}
export class AppVersion {
  id!: string;
  channel!: ReleaseChannel;
  tesAppVersionType!: TesAppVersionType;
  fileAddress!: string;
  version!: string;
  CustomerEnvironments!: CustomerEnvironment[];
}
