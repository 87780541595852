import React, { useEffect, useRef, useState } from "react";

import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

import Chart, {
  AdaptiveLayout,
  ChartRef,
  CommonSeriesSettings,
  Export,
  Size,
  Tooltip,
} from "devextreme-react/chart";

import PivotGrid, {
  FieldChooser,
  PivotGridRef,
} from "devextreme-react/pivot-grid";

import { Button, DateRangeBox, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Form, { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../../components/permission/permision";
import RowDataManager from "../../../../../../components/rowDataManager/rowDataManager";
import { TrafficStudyPermissions } from "../../../../../../constants/Permissions";
import { useAuth } from "../../../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../../types/general/generalTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";
import {
  FlatRawDataDTO,
  FlatRawDataReq,
  StudyTmcWebDetailsDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import {
  ApproachType,
  MovementType,
  StudyCategory,
} from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { onExportingPivotGrid } from "../../../../../../utils/dataGridTools";
import {
  AddDays2Date,
  CreateDateAsUTC,
  GetHourDiff,
} from "../../../../../../utils/dateTimeTools";
import { RequestErrorHandling, TesPost } from "../../../../../../utils/rest";

//import  saveAs  from 'file-saver';
interface IPros {
  initDataBase: StudyTmcWebDetailsDTO;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const VolumeRawData = (props: IPros) => {
  const { activeLoading } = useAuth();
  let chartRef = useRef<ChartRef>(null);
  let pivotGridRef = useRef<PivotGridRef>(null);
  const [flatRawData, setFlatRawData] = useState<FlatRawDataDTO[]>([]);

  const [maxDate, setMaxDate] = useState<Date>(new Date(2012, 0, 1));
  const [minDate, setMinDate] = useState<Date>(new Date(2011, 0, 1));

  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [showDateRangePicker, setShowDateRangePicker] =
    useState<boolean>(false);
  const [ds, setDs] = useState<PivotGridDataSource>();
  const [applyCheckDateRange, setApplyCheckDateRange] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);

  const { t } = useTranslation();

  useEffect(() => {
    // console.log("DATA " + JSON.stringify(props.initDataBase))
    var startDT = new Date(props.initDataBase.rawDataStartDT!);
    var endDT = new Date(props.initDataBase.rawDataEndDT!);
    var duration = GetHourDiff(startDT, endDT);
    if (duration > 24) {
      setShowDateRangePicker(true);
    }
    if (duration === 0) {
      endDT = new Date();
    }
    var startYear = startDT.getFullYear() ?? new Date().getFullYear();
    var startMonth = startDT.getMonth() ?? new Date().getMonth();
    var startDay = startDT.getDate() ?? new Date().getDate();

    var endYear = endDT.getFullYear() ?? new Date().getFullYear();
    var endMonth = endDT.getMonth() ?? new Date().getDate();
    var endDay = endDT.getDate() ?? new Date().getFullYear();
    //if (startYear === endYear) endYear++;

    // console.log("TIME " + endDT)

    setMinDate(new Date(startYear, startMonth, startDay));
    setMaxDate(new Date(endYear, endMonth, endDay));

    //setTimeOfStartDate(startDT)
    if (duration > 72) {
      endDT = AddDays2Date(startDT, 1);
      // setTimeOfEndDate(endDT)
    } else {
      //setTimeOfEndDate(endDT)
    }
    setDateRange([startDT, endDT]);
    setApplyCheckDateRange([startDT, endDT]);
  }, [props.initDataBase.rawDataStartDT, props.initDataBase.rawDataEndDT]);

  useEffect(() => {
    if (dataLoaded === false && dateRange !== undefined) {
      applyPeriodFilter();
      setDataLoaded(true);
    }
  }, [dataLoaded, dateRange]);

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t("approach"),
          dataField: "approach",
          width: 150,
          area: "column",
          areaIndex:
            props.initDataBase.studyCategory === StudyCategory.Volume ? 0 : 1,
          customizeText: function (cellInfo) {
            return t(
              "approach-" + ApproachType[parseInt(cellInfo.value?.toString()!)]
            );
          },
        },
        {
          caption: t("movement"),
          width: 120,
          dataField: "movement",
          area: "column",
          areaIndex: 2,
          sortBySummaryField: "Total",
          customizeText: function (cellInfo) {
            return t(
              "movement-" + MovementType[parseInt(cellInfo.value?.toString()!)]
            );
          },
        },
        {
          caption: t("vehicleClassName"),
          dataField: "vehicleClassName",
          width: 150,
          areaIndex:
            props.initDataBase.studyCategory ===
            (StudyCategory.Volume | StudyCategory.Classification)
              ? 0
              : 1,
          area: "column",
        },
        {
          caption: t("lane"),
          width: 120,
          dataField: "lane",
          areaIndex: 3,
          area: "column",
        },
        {
          caption: t("startDT"),
          dataField: "startDT",
          dataType: "date",
          area: "row",
          format: props.generalSettings?.dateTimeFormat,
        },
        {
          caption: t("total"),
          dataField: "count",
          dataType: "number",
          summaryType: "sum",
          area: "data",
        },
      ],
      store: flatRawData,
      // store: props.initDataBase.rawData,
      retrieveFields: false,
    });
    setDs(dataSource);
    pivotGridRef.current?.instance().bindChart(chartRef.current?.instance(), {
      dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false,
    });
  }, [flatRawData, props.generalSettings?.dateTimeFormat, t]);

  function isValidDate(dateObject: Date) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  async function applyPeriodFilter() {
    if (!isValidDate(dateRange![1])) {
      return;
    }

    if (activeLoading) activeLoading(true);
    var postObj: FlatRawDataReq = {
      endDT: CreateDateAsUTC(dateRange![1]),
      startDT: CreateDateAsUTC(dateRange![0]),
      studyId: props.initDataBase.id,
    };

    const res = (await TesPost(
      TrafficApiUrl() + "/api/Study/GetFlatRawData",
      postObj,
      true
    )) as RequestResponseResult<FlatRawDataDTO[]>;
    if (res.responseCode === ResponseCode.OK) {
      setFlatRawData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
    if (activeLoading) activeLoading(false);
  }

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        {dateRange !== undefined &&
          isValidDate(dateRange![1]) &&
          showDateRangePicker && (
            <div>
              <Form
                colCount={16}
                style={{ marginBottom: 20, marginLeft: "2.5rem" }}
              >
                <SimpleItem colSpan={16}>
                  <p style={{ marginTop: 5, fontSize: 16 }}>
                    The raw data is accessible for the duration spanning from{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {props.initDataBase.rawDataStartDT !== undefined
                        ? DateTime.fromISO(
                            props.initDataBase.rawDataStartDT.toString(),
                            { zone: "utc" }
                          ).toFormat(props.generalSettings?.dateTimeFormat!)
                        : ""}
                    </span>{" "}
                    to{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {props.initDataBase.rawDataEndDT !== undefined
                        ? DateTime.fromISO(
                            props.initDataBase.rawDataEndDT?.toString(),
                            { zone: "utc" }
                          ).toFormat(props.generalSettings?.dateTimeFormat!)
                        : ""}
                    </span>
                    . The report below has been generated for the specific dates
                    specified in the fields.
                  </p>
                </SimpleItem>
                {dateRange !== undefined && (
                  <SimpleItem colSpan={8}>
                    <DateRangeBox
                      focusStateEnabled={true}
                      startDateLabel={t("from")}
                      endDateLabel={t("to")}
                      displayFormat={props.generalSettings?.dateTimeFormat}
                      useMaskBehavior={true}
                      openOnFieldClick={true}
                      showClearButton={true}
                      min={props.initDataBase.rawDataStartDT}
                      max={props.initDataBase.rawDataEndDT}
                      startDate={dateRange[0] || null}
                      endDate={dateRange[1] || null}
                      onValueChanged={(e) => {
                        const newDateRange = e.value;
                        setApplyCheckDateRange(newDateRange);
                        if (newDateRange && newDateRange.length === 2) {
                          const diff = Math.round(
                            (newDateRange[1].getTime() -
                              newDateRange[0].getTime()) /
                              (1000 * 60 * 60 * 24)
                          );
                          if (diff < 0) {
                            notify(
                              t("fromDateShouldBeBeforeEndDate"),
                              "error",
                              2500
                            );
                          } else if (diff > 3) {
                            notify(
                              t("dateIntervalShouldntBeBiggerThan3Days"),
                              "error",
                              2500
                            );
                          } else {
                            setDateRange(newDateRange);
                          }
                        }
                      }}
                    />
                  </SimpleItem>
                )}
                <SimpleItem colSpan={2}>
                  <Button
                    onClick={() => applyPeriodFilter()}
                    className="tes-modal-btn-add"
                    text={t("apply")}
                    style={{ marginTop: 10 }}
                    disabled={
                      !applyCheckDateRange![1] ||
                      !applyCheckDateRange![0] ||
                      Math.round(
                        (applyCheckDateRange![1].getTime() -
                          applyCheckDateRange![0].getTime()) /
                          (1000 * 60 * 60 * 24)
                      ) < 0 ||
                      Math.round(
                        (applyCheckDateRange![1].getTime() -
                          applyCheckDateRange![0].getTime()) /
                          (1000 * 60 * 60 * 24)
                      ) > 3
                    }
                  />
                </SimpleItem>
              </Form>
            </div>
          )}
        <Permission
          allowed={[TrafficStudyPermissions.TrafficStudy_E_Study]}
          hasFeedBackElement={false}
        >
          <RowDataManager
            arrangement={["0.25rem", "auto", "auto", "2.5rem"]}
            studyId={props.initDataBase.id!}
          />
        </Permission>
        <Chart ref={chartRef}>
          <Size height={200} />
          <Tooltip
            enabled={true}
            //   customizeTooltip={customizeTooltip}
          />
          <CommonSeriesSettings type="bar" />
          <AdaptiveLayout width={450} />
          <Export enabled={true} printingEnabled={false} />
        </Chart>

        <PivotGrid
          id="pivotgrid"
          dataSource={ds}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={true}
          showRowTotals={false}
          showRowGrandTotals={true}
          onExporting={onExportingPivotGrid}
          fieldPanel={{
            showColumnFields: true,
            showDataFields: true,
            showFilterFields: true,
            showRowFields: true,
            allowFieldDragging: true,
            visible: true,
          }}
          allowSorting={true}
          allowExpandAll={true}
          //  onExporting={onExporting}
          ref={pivotGridRef}
        >
          <Export enabled={true} printingEnabled={false} />

          <FieldChooser enabled={true} height={400} />
        </PivotGrid>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default VolumeRawData;
