import {
  NumberBox,
  SelectBox,
  TagBox,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DateBox from "devextreme-react/date-box";
import { Form, SimpleItem } from "devextreme-react/form";
import { RequiredRule, Validator } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TMSPermissions } from "../../../../../../constants/Permissions";
import { useAuth } from "../../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../../contexts/clientSetting";
import { VMMapLocation } from "../../../../../../types/collision/dto/collisionDtos";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { VMLocation } from "../../../../../../types/infrastructure/dto/locationdDto";
import { SectionDetailType } from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import { StudyBaseDTO } from "../../../../../../types/trafficStudy/dtos/studyDto";
import { SimpleTrafficCounterDTO } from "../../../../../../types/trafficStudy/dtos/TrafficCounterDTO";
import { RawDataDataSourceType } from "../../../../../../types/trafficStudy/enums/importEnums";
import {
  AADTCalculationType,
  AggregationInterval,
  StudyCategory,
  StudyStatus,
  StudyType,
} from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { IStudyParams } from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { Enum2Array, EnumFlag2Array } from "../../../../../../utils/enumTools";

// props
interface IPros {
  initDataBase: StudyBaseDTO;
  params: Partial<IStudyParams>;
  didMount: boolean;
  tempMapLocation: VMMapLocation;
  mapLocation: VMMapLocation;
  generalSettings: GeneralSetting | null;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  lstTrafficCounter: SimpleTrafficCounterDTO[];
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const [lstAADTCalculationType, setLstAADTCalculationType] = useState<
    NameValue[]
  >([]);
  const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
  const [lstSectionType, setLstSectionType] = useState<NameValue[]>([]);
  const [lstStudyCategory, setLstStudyCategory] = useState<NameValue[]>([]);
  const [lstDataSourceType, setLstDataSourceType] = useState<NameValue[]>([]);
  const [lstAggregationInterval, setLstAggregationInterval] = useState<
    NameValue[]
  >([]);
  const { activeLoading, user } = useAuth();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstStudyType(Enum2Array(StudyType));
        setLstStudyStatus(Enum2Array(StudyStatus));
        setLstAADTCalculationType(Enum2Array(AADTCalculationType));
        setLstSectionType(Enum2Array(SectionDetailType));
        setLstAggregationInterval(Enum2Array(AggregationInterval));
        setLstStudyCategory(Enum2Array(StudyCategory));
        setLstDataSourceType(Enum2Array(RawDataDataSourceType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onValueChange(name: string, value: any) {
    props.setInitDataBase({ ...props.initDataBase, [name]: value });
    props.setDataChanged(true);
  }

  function setInfrastructureData(inData: VMLocation) {
    props.setInitDataBase({
      ...props.initDataBase,
      locationId: inData.id,
      locationDescription: inData.description,
      locationType: inData.locationType,
      geoId: inData.geoId,
      speedLimit:
        props.initDataBase.speedLimit === 0
          ? inData.postedSpeed
          : props.initDataBase.speedLimit,
      jurisdictionId: inData.jurisdictionId,
      municipalityId: inData.municipalityId,
      // speedLimit: inData.postedSpeed
    });
  }
  function handleLocationChange(loc: VMMapLocation) {
    props.setMapLocation(loc);
  }

  return (
    <div
      className={`studyDetails-details ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <ValidationGroup ref={props.validationRef}>
            <Form colCount={2} className="popupFields">
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("studyType")}
                  valueExpr="value"
                  defaultValue={parseInt(props.params.studyType!)}
                  displayExpr="name"
                  labelMode="floating"
                  value={parseInt(props.params.studyType!)}
                  onValueChange={(e) => onValueChange("studyType", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstStudyType, "name")}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>

              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("status")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.status}
                  onValueChange={(e) => onValueChange("status", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstStudyStatus, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              {props.params.studyId !== "AddNew" && (
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    label={t("studyNo")}
                    labelMode="floating"
                    value={props.initDataBase.studyNo}
                    onValueChange={(e) => onValueChange("studyNo", e)}
                    className="modalInput"
                    disabled={props.isLocked}
                  />
                </SimpleItem>
              )}
              {props.initDataBase.studyType !== StudyType.TMC && (
                <SimpleItem colSpan={1}>
                  <TagBox
                    id="categoryTagBox"
                    items={SortObjectByPropName(lstStudyCategory, "name")}
                    searchEnabled={true}
                    label={t("studyCategory")}
                    valueExpr="value"
                    displayExpr="name"
                    value={EnumFlag2Array(
                      StudyCategory,
                      props.initDataBase?.studyCategory ?? 0
                    )}
                    disabled={true}
                  ></TagBox>
                </SimpleItem>
              )}
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("sectionType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.sectionType}
                  onValueChange={(e) => onValueChange("sectionType", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstSectionType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("aggregationInterval")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.aggregationInterval}
                  onValueChange={(e) => onValueChange("aggregationInterval", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstAggregationInterval, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <DateBox
                  type="datetime"
                  label={t("rawDataStartDT")}
                  value={props.initDataBase.rawDataStartDT}
                  onValueChange={(e) => onValueChange("rawDataStartDT", e)}
                  pickerType="calendar"
                  placeholder={props.generalSettings?.dateTimeFormat}
                  displayFormat={props.generalSettings?.dateTimeFormat}
                  useMaskBehavior={true}
                  openOnFieldClick={true}
                  disabled={true}
                  showClearButton={true}
                  style={{ marginBottom: "1.25rem" }}
                ></DateBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <DateBox
                  type="datetime"
                  label={t("rawDataEndDT")}
                  value={props.initDataBase.rawDataEndDT}
                  onValueChange={(e) => onValueChange("rawDataEndDT", e)}
                  pickerType="calendar"
                  placeholder={props.generalSettings?.dateTimeFormat}
                  displayFormat={props.generalSettings?.dateTimeFormat}
                  useMaskBehavior={true}
                  openOnFieldClick={true}
                  disabled={true}
                  showClearButton={true}
                  style={{ marginBottom: "1.25rem" }}
                ></DateBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("speedLimit")}
                  labelMode="floating"
                  value={props.initDataBase.speedLimit}
                  onValueChange={(e) => onValueChange("speedLimit", e)}
                  className="modalInput"
                  disabled={props.isLocked}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("dataSourceType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.dataSourceType}
                  onValueChange={(e) => onValueChange("dataSourceType", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstDataSourceType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("trafficCounter")}
                  valueExpr="id"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.trafficCounterId}
                  className="modalInput"
                  items={SortObjectByPropName(props.lstTrafficCounter, "name")}
                  onValueChange={(e) => onValueChange("trafficCounterId", e)}
                  disabled={
                    props.initDataBase.dataSourceType ===
                      RawDataDataSourceType.MobileApp ||
                    user?.permissions?.includes(TMSPermissions.TMS_Admin)
                      ? props.isLocked
                      : true
                  }
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("binMaps")}
                  valueExpr="id"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.binMapId}
                  className="modalInput"
                  onValueChange={(e) => onValueChange("binMapId", e)}
                  items={SortObjectByPropName(
                    props.lstTrafficCounter
                      ?.filter(
                        (x) => x.id === props.initDataBase.trafficCounterId
                      )
                      .flatMap((x) => x.binMaps),
                    "name"
                  )}
                  disabled={
                    props.initDataBase.dataSourceType ===
                      RawDataDataSourceType.MobileApp ||
                    user?.permissions?.includes(TMSPermissions.TMS_Admin)
                      ? props.isLocked
                      : true
                  }
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>

              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("aadtCalculationType")}
                  valueExpr="value"
                  defaultValue={props.initDataBase.aadtCalculationType}
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initDataBase.aadtCalculationType}
                  onValueChange={(e) => onValueChange("aadtCalculationType", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstAADTCalculationType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default Details;
