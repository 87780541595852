const data = {
    "Name": "test",
    "divisionIds": [],
    "jurisdictionIds": [],
    "municipalityIds": [],
    "vruScoring": {
      "collisions": {
        "score": 10,
        "vruTargetPercentiles": [
          { "from": 0, "to": 5, "value": 0 },
          { "from": 40, "to": null, "value": 1 },
          { "from": 60, "to": 120, "value": 2 },
          { "from": 75, "to": null, "value": 4 },
          { "from": 85, "to": 170, "value": 6 },
          { "from": 95, "to": null, "value": 10 }
        ]
      },
      "psi": {
        "score": 10,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 0 },
          { "from": 40, "to": 80, "value": 1 },
          { "from": 60, "to": null, "value": 2.74 },
          { "from": 75, "to": 150, "value": 10.27 },
          { "from": 85, "to": null, "value": 22.3 },
          { "from": 95, "to": 190, "value": 50.47 }
        ]
      },
      "pedVol": {
        "score": 6,
        "vruTargetPercentiles": [
          { "from": 0, "to": 10, "value": 0 },
          { "from": 40, "to": null, "value": 47 },
          { "from": 60, "to": 130, "value": 119 },
          { "from": 75, "to": null, "value": 236 },
          { "from": 85, "to": null, "value": 391 },
          { "from": 95, "to": 900, "value": 847 }
        ]
      },
      "transit": {
        "score": 5,
        "vruTargetPercentiles": [
          { "from": 0, "to": 20, "value": 0 },
          { "from": 40, "to": null, "value": 1 },
          { "from": 60, "to": null, "value": 1 },
          { "from": 75, "to": 150, "value": 2 },
          { "from": 85, "to": null, "value": 100 },
          { "from": 95, "to": null, "value": 200 }
        ]
      },
      "attractors": {
        "score": 5,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 0 },
          { "from": 40, "to": 80, "value": 2 },
          { "from": 60, "to": null, "value": 3 },
          { "from": 75, "to": 150, "value": 4 },
          { "from": 85, "to": null, "value": 5 },
          { "from": 95, "to": null, "value": 7 }
        ]
      },
      "popDensity": {
        "score": 4,
        "vruTargetPercentiles": [
          { "from": 0, "to": 60, "value": 31 },
          { "from": 40, "to": null, "value": 1479 },
          { "from": 60, "to": 2500, "value": 2130 },
          { "from": 75, "to": null, "value": 2653 },
          { "from": 85, "to": null, "value": 3072 },
          { "from": 95, "to": 5000, "value": 4248 }
        ]
      },
      "vehVol": {
        "score": 3,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 1 },
          { "from": 40, "to": 80000, "value": 16167 },
          { "from": 60, "to": null, "value": 20477 },
          { "from": 75, "to": 90000, "value": 23545 },
          { "from": 85, "to": null, "value": 27251 },
          { "from": 95, "to": 150000, "value": 33974 }
        ]
      },
      "vehTurns": {
        "score": 5,
        "vruTargetPercentiles": [
          { "from": 0, "to": 20, "value": 0 },
          { "from": 40, "to": null, "value": 2959 },
          { "from": 60, "to": 5000, "value": 4342 },
          { "from": 75, "to": null, "value": 6263 },
          { "from": 85, "to": 9000, "value": 8516 },
          { "from": 95, "to": null, "value": 10723 }
        ]
      },
      "speed": {
        "score": 3,
        "vruTargetPercentiles": [
          { "from": 0, "to": 50, "value": 43.33 },
          { "from": 40, "to": null, "value": 52.5 },
          { "from": 60, "to": 70, "value": 55 },
          { "from": 75, "to": null, "value": 60 },
          { "from": 85, "to": null, "value": 60 },
          { "from": 95, "to": 90, "value": 70 }
        ]
      },
      "lanes": {
        "score": 4,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 5 },
          { "from": 40, "to": 50, "value": 16 },
          { "from": 60, "to": null, "value": 19 },
          { "from": 75, "to": 60, "value": 20 },
          { "from": 85, "to": null, "value": 22 },
          { "from": 95, "to": null, "value": 25 }
        ]
      },
      "rightTurnLanes": {
        "score": 4,
        "vruTargetPercentiles": [
          { "from": 0, "to": 10, "value": 0 },
          { "from": 40, "to": null, "value": 1 },
          { "from": 60, "to": 5, "value": 2 },
          { "from": 75, "to": null, "value": 3 },
          { "from": 85, "to": 8, "value": 4 }
        ]
      },
      "rtor": {
        "score": 0.33,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 1 },
          { "from": 40, "to": 2, "value": 0 }
        ]
      },
      "leftTurnPhasing": {
        "score": 0.33,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 1 },
          { "from": 40, "to": 3, "value": 0 }
        ]
      },
      "lpi": {
        "score": 0.33,
        "vruTargetPercentiles": [
          { "from": 0, "to": null, "value": 1 },
          { "from": 40, "to": 2.5, "value": 0 }
        ]
      }
    }
  }
 export default data; 