import { RequestResponseResult } from "../../types/general/generalTypes";
import {
  LanguageDetailsDto,
  LanguageTranslationDto,
} from "../../types/translation/dtos/translationDtos";
import { TesGet } from "../../utils/rest";
import { TranslationApiUrl } from "../../environment/routeSettings";
import { LanguageType } from "../../types/translation/enums/translationEnums";
import { TTranslation } from "./index";

export const storeTranslationsKeyName = "TESTranslations";
export const storeSelectedLangKeyName = "TESLanguage";
export const storeTranslationHelperWidgetStateLangKeyName = "TESTrHelper";

export const fetchLanguagesList = async () => {
  try {
    const customerId = localStorage.getItem("selectedCustomerId");
    if (!customerId) return [];
    const res: RequestResponseResult<LanguageDetailsDto[]> = await TesGet(
      `${TranslationApiUrl()}/api/Languages/GetCustomerLanguages/${customerId}`,
      true,
      false
    );
    return res.results;
  } catch (error) {
    console.error("Error fetching languages:", error);
  }
};
export const fetchTranslation = async (
  languageType: LanguageType,
  languageId: string
) => {
  try {
    const customerId = localStorage.getItem("selectedCustomerId");
    if (!customerId) return;
    const res: RequestResponseResult<LanguageTranslationDto> = await TesGet(
      `${TranslationApiUrl()}/api/Languages/GetTranslations/${customerId}/${languageType}/${languageId}`,
      true,
      false
    );
    return res.results;
  } catch (error) {
    console.error("Error fetching translations:", error);
  }
};

export const fetchTranslationsByLangId = async (
  languageType: LanguageType,
  languageId: string
) => {
  try {
    const res: RequestResponseResult<any> = await TesGet(
      `${TranslationApiUrl()}/api/ManageTranslations/GetTranslationOfLanguage?languageId=${languageId}&languageType=${languageType}`,
      true,
      false
    );
    return res.results;
  } catch (error) {
    console.error("Error fetching translations by lang Id:", error);
  }
};

export const getStoredSelectedLanguage = () => {
  return localStorage.getItem(storeSelectedLangKeyName);
};
export const getStoredTranslations = (): TTranslation[] | [] => {
  return JSON.parse(localStorage.getItem(storeTranslationsKeyName) || "[]");
};
export const storeSelectedLanguage = (lang: string) => {
  localStorage.setItem(storeSelectedLangKeyName, lang);
};
export const storeTranslations = (translations: TTranslation[]) => {
  localStorage.setItem(storeTranslationsKeyName, JSON.stringify(translations));
};
export const storeTranslationsHelperWidgetState = (isActive: boolean) => {
  localStorage.setItem(
    storeTranslationHelperWidgetStateLangKeyName,
    JSON.stringify(isActive)
  );
};
export const getStoredTranslationsHelperWidgetState = (): boolean => {
  return JSON.parse(
    localStorage.getItem(storeTranslationHelperWidgetStateLangKeyName) ||
      "false"
  );
};
