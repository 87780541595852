import React, { useEffect, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { NameValue } from "../../../../../../types/general/generalTypes";
import {
  AADTProviderType,
  AADTQualityFactor,
  AADTSourceType,
  AADTTesStatus,
  AADTType,
  FacilityType,
} from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { AADTDetails } from "../../../../../../types/trafficStudy/dtos/studyDto";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";

// props
interface IPros {
  initData: AADTDetails;
  aadtType: string;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}

const VolumeHistory = (props: IPros) => {
  const { t } = useTranslation();
  const [lstSourceType, setLstSourceType] = useState<NameValue[]>([]);
  const [lstProviderType, setLstProviderType] = useState<NameValue[]>([]);
  const [lstQualityFactor, setLstQualityFactor] = useState<NameValue[]>([]);
  const [lstTesStatus, setLstTesStatus] = useState<NameValue[]>([]);
  const [lstFacilityType, setLstFacilityType] = useState<NameValue[]>([]);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    setLstSourceType(Enum2Array(AADTSourceType));
    setLstQualityFactor(Enum2Array(AADTQualityFactor));
    setLstTesStatus(Enum2Array(AADTTesStatus));
    setLstFacilityType(Enum2Array(FacilityType));
    setLstProviderType(Enum2Array(AADTProviderType));
  }, []);

  function onValueChangeIntersection(name: string, value: any) {
    props.setInitData({
      ...props.initData,
      intersectionAADT: { ...props.initData.intersectionAADT, [name]: value },
    });
    props.setDataChanged(true);
  }

  function onValueChangeRoadSegment(name: string, value: any) {
    props.setInitData({
      ...props.initData,
      roadSegmentAADT: { ...props.initData.roadSegmentAADT, [name]: value },
    });
    props.setDataChanged(true);
  }

  function onValueChangeMIS(name: string, value: any) {
    props.setInitData({
      ...props.initData,
      misAADT: { ...props.initData.misAADT, [name]: value },
    });
    props.setDataChanged(true);
  }

  return (
    <div
      className={`aadtDetails-aadt ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          {props.aadtType.toString() === AADTType.Intersection.toString() && (
            <Form colCount={3}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("year")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.year}
                  onValueChange={(e) => onValueChangeIntersection("year", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("majorVolumeAadt")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.majorVolume}
                  onValueChange={(e) =>
                    onValueChangeIntersection("majorVolume", e)
                  }
                  className="modalInput"
                  disabled={props.isLocked}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("minorVolumeAadt")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.minorVolume}
                  onValueChange={(e) =>
                    onValueChangeIntersection("minorVolume", e)
                  }
                  className="modalInput"
                  disabled={props.isLocked}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("northApproachAADT")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.northApproachAADT}
                  onValueChange={(e) =>
                    onValueChangeIntersection("northApproachAADT", e)
                  }
                  className="modalInput"
                  disabled={props.isLocked}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("eastApproachAADT")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.eastApproachAADT}
                  onValueChange={(e) =>
                    onValueChangeIntersection("eastApproachAADT", e)
                  }
                  className="modalInput"
                  disabled={props.isLocked}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("southApproachAADT")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.southApproachAADT}
                  onValueChange={(e) =>
                    onValueChangeIntersection("southApproachAADT", e)
                  }
                  className="modalInput"
                  disabled={props.isLocked}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("westApproachAADT")}
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.westApproachAADT}
                  onValueChange={(e) =>
                    onValueChangeIntersection("westApproachAADT", e)
                  }
                  className="modalInput"
                  disabled={props.isLocked}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("sourceType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.sourceType}
                  onValueChange={(e) =>
                    onValueChangeIntersection("sourceType", e)
                  }
                  className="modalInput"
                  items={SortObjectByPropName(lstSourceType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("providerType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.providerType}
                  onValueChange={(e) =>
                    onValueChangeIntersection("providerType", e)
                  }
                  className="modalInput"
                  items={SortObjectByPropName(lstProviderType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("totalVolumeAadt")}
                  labelMode="floating"
                  value={
                    props.initData?.intersectionAADT?.majorVolume +
                    props.initData?.intersectionAADT?.minorVolume
                  }
                  onValueChange={(e) =>
                    onValueChangeIntersection("totalVolume", e)
                  }
                  className="modalInput"
                  disabled={true}
                />
              </SimpleItem>
            </Form>
          )}
          {props.aadtType.toString() === AADTType.RoadSegment.toString() && (
            <Form colCount={3}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("year")}
                  labelMode="floating"
                  value={props.initData?.roadSegmentAADT?.year}
                  onValueChange={(e) => onValueChangeRoadSegment("year", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("totalVolumeAadt")}
                  labelMode="floating"
                  value={props.initData?.roadSegmentAADT?.segmentVolume}
                  onValueChange={(e) =>
                    onValueChangeRoadSegment("segmentVolume", e)
                  }
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("sourceType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.roadSegmentAADT?.sourceType}
                  onValueChange={(e) =>
                    onValueChangeRoadSegment("sourceType", e)
                  }
                  className="modalInput"
                  items={SortObjectByPropName(lstSourceType, "name")}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("providerType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.providerType}
                  onValueChange={(e) =>
                    onValueChangeIntersection("providerType", e)
                  }
                  className="modalInput"
                  items={SortObjectByPropName(lstProviderType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
          )}
          {props.aadtType.toString() === AADTType.Mis.toString() && (
            <Form colCount={3}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("year")}
                  labelMode="floating"
                  value={props.initData?.misAADT?.year}
                  onValueChange={(e) => onValueChangeMIS("year", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("segmentAADT")}
                  labelMode="floating"
                  value={props.initData?.misAADT?.segmentAADT}
                  onValueChange={(e) => onValueChangeMIS("segmentAADT", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  format="#,###"
                  step={0}
                  label={t("totalVolumeAadt")}
                  labelMode="floating"
                  value={props.initData?.misAADT?.totalVolume}
                  onValueChange={(e) => onValueChangeMIS("totalVolume", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("trafficControl")}
                  labelMode="floating"
                  value={props.initData?.misAADT?.trafficControl}
                  onValueChange={(e) => onValueChangeMIS("trafficControl", e)}
                  className="modalInput"
                  disabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("sourceType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.misAADT?.sourceType}
                  onValueChange={(e) => onValueChangeMIS("sourceType", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstSourceType, "name")}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("providerType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.intersectionAADT?.providerType}
                  onValueChange={(e) =>
                    onValueChangeIntersection("providerType", e)
                  }
                  className="modalInput"
                  items={SortObjectByPropName(lstProviderType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("qualityFactor")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.misAADT?.qualityFactor}
                  onValueChange={(e) => onValueChangeMIS("qualityFactor", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstQualityFactor, "name")}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                  style={{ marginBottom: "0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("tesStatus")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.misAADT?.tesStatus}
                  onValueChange={(e) => onValueChangeMIS("tesStatus", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstTesStatus, "name")}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("facilityType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={props.initData?.misAADT?.facilityType}
                  onValueChange={(e) => onValueChangeMIS("facilityType", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstFacilityType, "name")}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
          )}
        </div>
      </React.Fragment>
    </div>
  );
};
export default VolumeHistory;
