function MaxDropdownItemWidthCalculator(items: any[]): number {
  const charWidths = [
    { char: "A", width: 12 },
    { char: "B", width: 12 },
    { char: "C", width: 11 },
    { char: "D", width: 12 },
    { char: "E", width: 10 },
    { char: "F", width: 9 },
    { char: "G", width: 12 },
    { char: "H", width: 12 },
    { char: "I", width: 5 },
    { char: "J", width: 6 },
    { char: "K", width: 11 },
    { char: "L", width: 9 },
    { char: "M", width: 14 },
    { char: "N", width: 12 },
    { char: "O", width: 12 },
    { char: "P", width: 10 },
    { char: "Q", width: 12 },
    { char: "R", width: 11 },
    { char: "S", width: 10 },
    { char: "T", width: 11 },
    { char: "U", width: 11 },
    { char: "V", width: 11 },
    { char: "W", width: 15 },
    { char: "X", width: 11 },
    { char: "Y", width: 11 },
    { char: "Z", width: 11 },
    { char: "a", width: 10 },
    { char: "b", width: 10 },
    { char: "c", width: 9 },
    { char: "d", width: 10 },
    { char: "e", width: 10 },
    { char: "f", width: 7 },
    { char: "g", width: 10 },
    { char: "h", width: 10 },
    { char: "i", width: 5 },
    { char: "j", width: 6 },
    { char: "k", width: 10 },
    { char: "l", width: 5 },
    { char: "m", width: 15 },
    { char: "n", width: 10 },
    { char: "o", width: 10 },
    { char: "p", width: 10 },
    { char: "q", width: 10 },
    { char: "r", width: 7 },
    { char: "s", width: 8 },
    { char: "t", width: 7 },
    { char: "u", width: 10 },
    { char: "v", width: 9 },
    { char: "w", width: 12 },
    { char: "x", width: 9 },
    { char: "y", width: 9 },
    { char: "z", width: 8 },
    { char: "0", width: 10 },
    { char: "1", width: 10 },
    { char: "2", width: 10 },
    { char: "3", width: 10 },
    { char: "4", width: 10 },
    { char: "5", width: 10 },
    { char: "6", width: 10 },
    { char: "7", width: 10 },
    { char: "8", width: 10 },
    { char: "9", width: 10 },
    { char: "!", width: 7 },
    { char: "@", width: 15 },
    { char: "#", width: 13 },
    { char: "$", width: 10 },
    { char: "%", width: 14 },
    { char: "^", width: 13 },
    { char: "&", width: 12 },
    { char: "*", width: 8 },
    { char: "(", width: 6 },
    { char: ")", width: 6 },
    { char: "_", width: 10 },
    { char: "+", width: 13 },
    { char: "-", width: 6 },
    { char: "=", width: 13 },
    { char: "[", width: 6 },
    { char: "]", width: 6 },
    { char: "{", width: 10 },
    { char: "}", width: 10 },
    { char: "|", width: 6 },
    { char: ";", width: 6 },
    { char: ":", width: 6 },
    { char: "'", width: 5 },
    { char: ",", width: 5 },
    { char: ".", width: 5 },
    { char: "<", width: 13 },
    { char: ">", width: 13 },
    { char: "?", width: 8 },
    { char: "/", width: 6 },
    { char: "\\", width: 6 },
    { char: '"', width: 7 },
  ];

  const charWidthMap = new Map(charWidths.map((cw) => [cw.char, cw.width]));

  const getStringWidth = (str: string): number => {
    if (str === null || str === "" || str === undefined) return 50;
    return Array.from(str).reduce((totalWidth, char) => {
      return totalWidth + (charWidthMap.get(char) || 0);
    }, 0);
  };

  let maxWidth = 0;
  for (const obj of items) {
    const width = getStringWidth(obj.name);
    if (width > maxWidth) {
      maxWidth = width;
    }
  }
  return items.length !== 0 ? maxWidth : 140;
}

export default MaxDropdownItemWidthCalculator;
