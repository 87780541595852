import { INameId } from "../../general/generalTypes";
import {
  DivisionSystem,
  DivisionsPermissionTypes,
  PasswordMode,
  SingleDivisionAccess,
} from "../enums/identityEnums";
import { IdentityGeneralSetting, SignOnMethod } from "../identityTypes";

export class IChagePassword {
  userId!: string;
  currentPass!: string;
  newPass!: string;
}

export class VMFindUser {
  id!: string;
  firstName!: string;
  lastName!: string;
  email!: string;
  userName!: string;
  defaultCustomerName!: string;
  defaultCustomerId!: string;
  isActive!: boolean;
  createDate!: string;
}

export class VMAddUser {
  constructor() {
    this.listDivisionIds = [];
    this.listGroupIds = [];
  }

  id!: string;
  lastName!: string;
  firstName!: string;
  email!: string;
  userName!: string;
  defaultCustomerId!: string;
  password!: string;
  isCreateFolder!: boolean;
  emailSetPassword!: boolean;
  emailUsernamePassword!: boolean;
  listGroupIds!: string[];
  listDivisionIds!: string[];
  setUsernameManually!: boolean;
  passwordMode!: PasswordMode;
  signOnMethod!: SignOnMethod;
  sendNotificationEmail!: boolean;
  singleDivisionId!: string;
}

export class VMAssignUsers2Division {
  divisionId!: string;
  userIds!: string[] | undefined;
}

export class VMAssignUsers2Group {
  groupId!: string;
  userIds!: string[] | undefined;
}
export class VMAssignUser2Groups {
  userId!: string;
  groupIds!: string[] | undefined;
}
export class VMAssignUser2Divisions {
  userId!: string;
  divisions: VMDivisionXUser[] | undefined;
}

export class VMUpdateUserPermissions {
  id!: string;
  role!: string[];
}

export class VMRoleStatus {
  name!: string;
  active!: boolean;
}

export class VMGroupProfile {
  id!: string;
  name!: string;
  customerId!: string;
  groupPermissions!: string[];
  users: VMFindUser[] = [];
  allPermissions!: VMAllPermissions;
  fms!: boolean;
  tms!: boolean;
  tcbOnline!: boolean;
  tcbOffline!: boolean;
  tmc!: boolean;
  sign!: boolean;
  infrastructure!: boolean;
  maintenance!: boolean;
  collision!: boolean;
  safety!: boolean;
  dashboard!: boolean;
  trafficStudy!: boolean;
  moduleOfModule!: boolean;
  systematicScreening!: boolean;
}

export class VMAllPermissions {
  customerGeneral!: string[];
  tms!: string[];
  fms!: string[];
  tmc!: string[];
  trafficStudy!: string[];
  moduleOfModule!: string[];
  tcbOnline!: string[];
  tcbOffline!: string[];
  sign!: string[];
  infrastructure!: string[];
  collision!: string[];
  maintenance!: string[];
  user!: string[];
  safety!: string[];
  dashboard!: string[];
  systematicScreening!: string[];
}

export class VMUserProfileDetails {
  id!: string;
  email!: string;
  userName!: string;
  firstName!: string;
  lastName!: string;
  isActive!: boolean;
  defaultCustomerId!: string;
  defaultCustomerName!: string;
  isInheritFromGroup!: boolean;
  isFieldInheritFromGroup!: boolean;
  skipCaptcha!: boolean;
  twoFactorEnabled!: boolean;
  generalSetting!: VMIdentityGeneralSetting;
  singleDivision!: INameId;
  singleDivisionId!: string;
  singleDivisionAccess!: SingleDivisionAccess;
  divisions!: VMDivisionXUser[];
  customers!: INameId[];
  groups!: INameId[];
  userPermission!: string[];
  allPermissions!: VMAllPermissions;
  tms!: string[];
  fms!: string[];
  tmc!: string[];
  tcbOnline!: string[];
  tcbOffline!: string[];
  sign!: string[];
  infrastructure!: string[];
  collision!: string[];
  safety!: string[];
  dashboard!: string[];
  maintenance!: string[];
  trafficStudy!: string[];
  moduleOfModule!: string[];
  systematicScreening!: string[];
  signOnMethod!: SignOnMethod;
  lockoutEnabled!: boolean;
  lockoutEnd!: Date;
}

export class VMChangePassword {
  username!: string;
  password!: string;
  confirmPassword!: string;
}

export class VMDivisionXUser {
  id!: string;
  divisionId!: string;
  userId!: string;
  name!: string;
  city!: string;
  rolesArray!: DivisionsPermissionTypes[];
}

export interface IUser {
  id?: string;
  email?: string;
  userName?: string;
  avatarUrl?: string;
  permissions?: string[];
  signOnMethod?: SignOnMethod;
  singleDivisionAccess?: SingleDivisionAccess;
  singleDivision?: INameId;
  divisionSystem?: DivisionSystem;
}

export class VMAddUserData {
  groups!: INameId[];
  divisions!: INameId[];
  divisionSystem!: DivisionSystem;
  passwordExpression!: string;
}

export class VMUserProfile {
  id!: string;
  lastName!: string;
  firstName!: string;
  email!: string;
  userName!: string;
  rd!: string;
  defaultCustomerId!: string | null;
  defaultCustomerName!: string;
  permissions!: string[];
  signOnMethod!: SignOnMethod;
  singleDivisionAccess!: SingleDivisionAccess;
  singleDivision!: INameId;
  divisionSystem!: DivisionSystem;
}

export class VMIdentityGeneralSetting {
  passwordExpression!: string;
  divisionSystem!: DivisionSystem;
}
