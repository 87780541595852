import {
  SimplifiedStudy,
  SpeedAndCompliance,
} from "../types/trafficStudy/trafficStudyTypes";

function TransformToSpeedAndCompliance(
  studies: SimplifiedStudy[] | undefined
): SpeedAndCompliance[] {
  const result: SpeedAndCompliance[] = [];

  if (studies) {
    for (const study of studies) {
      if (study.studyType === 4) {
        const speedAndCompliance = new SpeedAndCompliance();

        speedAndCompliance.startDT = study.startDT.toString().split("T")[0];
        speedAndCompliance.average = parseFloat(
          (study.speedStatisticsSummary?.average || 0).toFixed(2)
        );
        speedAndCompliance.speed85thPercentile = parseFloat(
          (study.speedStatisticsSummary?.speed85thPercentile || 0).toFixed(2)
        );
        speedAndCompliance.postedSpeed = parseFloat(
          (study.speedStatisticsSummary?.postedSpeed || 0).toFixed(2)
        );
        speedAndCompliance.northApproachVolume = parseFloat(
          (study.volumeSummary?.northApproachVolume || 0).toFixed(2)
        );
        speedAndCompliance.eastApproachVolume = parseFloat(
          (study.volumeSummary?.eastApproachVolume || 0).toFixed(2)
        );
        speedAndCompliance.westApproachVolume = parseFloat(
          (study.volumeSummary?.westApproachVolume || 0).toFixed(2)
        );
        speedAndCompliance.southApproachVolume = parseFloat(
          (study.volumeSummary?.southApproachVolume || 0).toFixed(2)
        );
        speedAndCompliance.totalVolume = parseFloat(
          (study.volumeSummary?.totalVolume || 0).toFixed(2)
        );
        speedAndCompliance.compliance =
          parseFloat(
            (study.speedStatisticsSummary?.compliance || 0).toFixed(2)
          ) * 100;

        result.push(speedAndCompliance);
      }
    }
  }

  return result;
}

export default TransformToSpeedAndCompliance;
