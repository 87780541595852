import {
  Button,
  DataGrid,
  Form,
  NumberBox,
  Popup,
  ScrollView,
  SelectBox,
  TagBox,
  TextBox,
  ValidationGroup,
  Validator,
} from "devextreme-react";
import notify from "devextreme/ui/notify";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalMode } from "../../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import {
  TrafficCounter,
  VehicleClassification,
} from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { Enum2Array, EnumFlag2Array } from "../../../../../../utils/enumTools";
import { VehicleClassificationType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { ArmadilloVehicleType } from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import React from "react";
import {
  Column,
  ColumnChooser,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  Summary,
  Button as DevExpressButton,
  RequiredRule,
  SortByGroupSummaryInfo,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import {
  SortObjectByPropName,
  SumIntArray,
} from "../../../../../../utils/arrayTools";
import { v4 as uuid } from "uuid";
import { custom } from "devextreme/ui/dialog";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";

// props
interface IPros {
  initData: TrafficCounter;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const VehicleClassifications = (props: IPros) => {
  //#region consts
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<VehicleClassification>(
    new VehicleClassification()
  );
  const [lstVehicleClassificationType, setLstVehicleClassificationType] =
    useState<NameValue[]>([]);
  const [lstArmadilloVehicleType, setLstArmadilloVehicleType] = useState<
    NameValue[]
  >([]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();

  //#endregion

  //#region functions

  useEffect(() => {
    setLstVehicleClassificationType(Enum2Array(VehicleClassificationType));
    setLstArmadilloVehicleType(Enum2Array(ArmadilloVehicleType));
  }, []);

  function onValueChanged(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value });
    props.setDataChanged(true);
  }

  function onCloseModal() {
    setShowModal(false);
    setSelectedData(new VehicleClassification());
  }

  function onNew(e: any) {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onAdd() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      props.setInitData({
        ...props.initData,
        vehicleClassifications: [
          ...props.initData.vehicleClassifications,
          {
            id: uuid(),
            in: props.initData.vehicleClassifications.length + 1,
            trafficCounterId: props.initData.id,
            name: selectedData.name,
            vehicleClassificationTypes: selectedData.vehicleClassificationTypes,
            armadilloVehicleType: selectedData.armadilloVehicleType,
            order: selectedData.order,
            fromLength: selectedData.fromLength,
            toLength: selectedData.toLength,
            nameInImport: selectedData.nameInImport,
          },
        ],
      });
      onCloseModal();
    } else {
      notify(t("fieldIsRequired"), "error", 5000);
    }
  }

  function onUpdate() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      var index = props.initData.vehicleClassifications.findIndex(
        (x) => x.id === selectedData.id
      );
      if (index !== -1) {
        var currentVehicleClassification =
          props.initData.vehicleClassifications;
        currentVehicleClassification[index].name = selectedData.name;
        currentVehicleClassification[index].order = selectedData.order;
        currentVehicleClassification[index].fromLength =
          selectedData.fromLength;
        currentVehicleClassification[index].toLength = selectedData.toLength;
        currentVehicleClassification[index].vehicleClassificationTypes =
          selectedData.vehicleClassificationTypes;
        currentVehicleClassification[index].armadilloVehicleType =
          selectedData.armadilloVehicleType;
        currentVehicleClassification[index].nameInImport =
          selectedData.nameInImport;

        props.setInitData({
          ...props.initData,
          vehicleClassifications: currentVehicleClassification,
        });
      }
      dataGridRef.current?.instance().refresh();
      setSelectedData(new VehicleClassification());
      onCloseModal();
    } else {
      notify(t("fieldIsRequired"), "error", 5000);
    }
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setShowModal(true);
    setSelectedData(d.row.data);
  }

  function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitData({
                ...props.initData,
                vehicleClassifications: [
                  ...props.initData.vehicleClassifications.filter(
                    (x) => x.id !== d.row.data.id
                  ),
                ],
              });
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("vehicleClassifications")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="row">
            <div className="rightColumn">
              <Button
                onClick={onNew}
                icon="fa-solid fa-circle-plus"
                text={t("vehicleClassification")}
                disabled={props.isLocked}
              />
            </div>
          </div>
          <ValidationGroup ref={props.validationRef}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.vehicleClassifications}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column dataField="name" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="vehicleClassificationTypes"
                caption={t("vehicleClassificationTypes")}
                calculateDisplayValue={function (rowData: any) {
                  var values = EnumFlag2Array(
                    VehicleClassificationType,
                    rowData.vehicleClassificationTypes
                  );
                  var res = [];
                  if (values !== undefined) {
                    for (let i = 0; i < values.length; i++) {
                      res.push(VehicleClassificationType[values[i]]);
                    }
                  }
                  return res.join(",");
                }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="order" caption={t("order")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="armadilloVehicleType"
                caption={t("armadilloVehicleType")}
                calculateDisplayValue={function (rowData: any) {
                  var values = EnumFlag2Array(
                    ArmadilloVehicleType,
                    rowData.armadilloVehicleType
                  );
                  var res = [];
                  if (values !== undefined) {
                    for (let i = 0; i < values.length; i++) {
                      res.push(ArmadilloVehicleType[values[i]]);
                    }
                  }
                  return res.join(",");
                }}
              ></Column>
              <Column dataField="fromLength" caption={t("fromLength")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="toLength" caption={t("toLength")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="nameInImport" caption={t("nameInImport")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="in" caption={t("integerId")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="customerId"
                caption={t("customerId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="divisionId"
                caption={t("divisionId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                type="buttons"
                caption={t("actions")}
                width={110}
                visible={!props.isLocked}
                fixed={true}
                fixedPosition="right"
              >
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </ValidationGroup>
        </div>
      </div>

      {/* Popup for add/edit */}
      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <ValidationGroup ref={validationRef}>
            <Form colCount={2} className="popupFields">
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("name")}
                  labelMode="floating"
                  value={selectedData.name}
                  onValueChange={(e) => onValueChanged("name", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("name")} />
                  </Validator>
                </TextBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("order")}
                  labelMode="floating"
                  value={selectedData.order}
                  onValueChange={(e) => onValueChanged("order", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("order")} />
                  </Validator>
                </NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  label={t("vehicleClassificationTypes")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={selectedData.vehicleClassificationTypes}
                  onValueChange={(e) =>
                    onValueChanged("vehicleClassificationTypes", e)
                  }
                  className="modalInput"
                  items={SortObjectByPropName(
                    lstVehicleClassificationType,
                    "name"
                  )}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TagBox
                  label={t("armadilloVehicleType")}
                  labelMode="floating"
                  className="modalInput"
                  dataSource={SortObjectByPropName(
                    lstArmadilloVehicleType,
                    "name"
                  )}
                  value={EnumFlag2Array(
                    ArmadilloVehicleType,
                    selectedData.armadilloVehicleType
                  )}
                  valueExpr="value"
                  displayExpr="name"
                  onValueChange={(e) =>
                    onValueChanged("armadilloVehicleType", SumIntArray(e))
                  }
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("fromLength")}
                  labelMode="floating"
                  value={selectedData.fromLength}
                  onValueChange={(e) => onValueChanged("fromLength", e)}
                  className="modalInput"
                ></NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("toLength")}
                  labelMode="floating"
                  value={selectedData.toLength}
                  onValueChange={(e) => onValueChanged("toLength", e)}
                  className="modalInput"
                ></NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("nameInImport")}
                  labelMode="floating"
                  value={selectedData.nameInImport}
                  onValueChange={(e) => onValueChanged("nameInImport", e)}
                  className="modalInput"
                ></TextBox>
              </SimpleItem>
            </Form>
          </ValidationGroup>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ? (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              ) : (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onUpdate}
                  text={t("update")}
                />
              )}
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

export default VehicleClassifications;
