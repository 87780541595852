import React, { useEffect, useRef, useState } from "react";

import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

import Chart, {
  AdaptiveLayout,
  ChartRef,
  CommonSeriesSettings,
  Export,
  Size,
  Tooltip,
} from "devextreme-react/chart";

import PivotGrid, {
  FieldChooser,
  PivotGridRef,
} from "devextreme-react/pivot-grid";

import { Button } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { SignalWarrantAnalysisDetails } from "../../../../../types/systematicScreening/systematicScreeningTypes";
import {
  ApproachType,
  MovementType,
} from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { onExportingPivotGrid } from "../../../../../utils/dataGridTools";
//import  saveAs  from 'file-saver';
interface IPros {
  initData: SignalWarrantAnalysisDetails;
  generalSettings: GeneralSetting | null;
}

const VolumeRawData = (props: IPros) => {
  let chartRef = useRef<ChartRef>(null);
  let pivotGridRef = useRef<PivotGridRef>(null);
  const [ds, setDs] = useState<PivotGridDataSource>(new PivotGridDataSource());
  const { t } = useTranslation();

  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t("approach"),
          dataField: "approach",
          width: 150,
          area: "column",
          customizeText: function (cellInfo) {
            return t(
              "approach-" + ApproachType[parseInt(cellInfo.value?.toString()!)]
            );
          },
        },
        {
          caption: t("movement"),
          width: 120,
          dataField: "movement",
          area: "column",
          sortBySummaryField: "Total",
          customizeText: function (cellInfo) {
            return t(
              "movement-" + MovementType[parseInt(cellInfo.value?.toString()!)]
            );
          },
        },
        {
          caption: t("vehicleClassName"),
          dataField: "vehicleClassName",
          width: 150,
          area: "column",
        },
        {
          caption: t("lane"),
          width: 120,
          dataField: "lane",
          area: "column",
        },
        {
          caption: t("startDT"),
          dataField: "startDT",
          dataType: "date",
          area: "row",
          format: props.generalSettings?.dateTimeFormat,
        },
        {
          caption: t("total"),
          dataField: "count",
          dataType: "number",
          summaryType: "sum",
          area: "data",
        },
      ],
      store: props.initData?.flatRawDatas,
      // store: props.initDataBase.rawData,
      retrieveFields: false,
    });
    setDs(dataSource);
    pivotGridRef.current?.instance().bindChart(chartRef.current?.instance(), {
      dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false,
    });
  }, [props.initData, props.generalSettings?.dateTimeFormat, t]);

  return (
    <React.Fragment>
      {props.initData?.analysisResult?.notPredicted === false ? (
        <div>
          <Chart ref={chartRef}>
            <Size height={200} />
            <Tooltip
              enabled={true}
              //   customizeTooltip={customizeTooltip}
            />
            <CommonSeriesSettings type="bar" />
            <AdaptiveLayout width={450} />
            <Export enabled={true} printingEnabled={false} />
          </Chart>

          <PivotGrid
            id="pivotgrid"
            dataSource={ds}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={false}
            showColumnGrandTotals={true}
            showRowTotals={false}
            showRowGrandTotals={true}
            onExporting={onExportingPivotGrid}
            fieldPanel={{
              showColumnFields: true,
              showDataFields: true,
              showFilterFields: true,
              showRowFields: true,
              allowFieldDragging: true,
              visible: true,
            }}
            allowSorting={true}
            allowExpandAll={true}
            //  onExporting={onExporting}
            ref={pivotGridRef}
          >
            <Export enabled={true} printingEnabled={false} />

            <FieldChooser enabled={true} height={400} />
          </PivotGrid>
        </div>
      ) : (
        <div style={{ margin: "2rem 0rem" }}>
          <span style={{ margin: "0 2rem 0 1rem", fontWeight: "bolder" }}>
            {t("latestStudyGuide")}
          </span>
          <Button
            className="tes-modal-btn-add"
            onClick={(e) =>
              window
                .open(
                  `/trafficStudy/studies/studyDetails/2/${props.initData.analysisResult.latestStudyId}`,
                  "_blank"
                )
                ?.focus()
            }
            text={t("openLatestStudy")}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default VolumeRawData;
