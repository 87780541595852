import { NewAttachmentType } from "../types/sign/enums/signEnums";

function FileType2AttachmentType(file: any): number {
  if (file.type?.includes("image")) return 1;
  else if (file.type?.includes("application")) return 2;
  else return 0;
}

function FileKeyCutterWithExtension(key: string, length: number): string {
  let name: string = key.split("/").pop() || "undefined";
  let extension: string = name.split(".").pop() || "undefined";
  let extLength: number = extension.length;

  if (name.startsWith("(")) {
    const betweenParenthesis = name.substring(
      name.indexOf("(") + 1,
      name.indexOf(")", name.indexOf("("))
    );
    name = betweenParenthesis + "." + extension;
  }

  if (name.length > length) {
    name = name.substring(0, length - extLength - 3) + "..." + extension;
  }

  return name;
}

function FileExtensionExtractor(fileName: any) {
  if (fileName !== null && fileName !== undefined) {
    const lastDotIndex = fileName?.lastIndexOf(".");
    const extension =
      lastDotIndex !== -1
        ? fileName?.substring(lastDotIndex + 1)?.toLowerCase()
        : fileName?.toLowerCase();
    return extension;
  }
}

function AttachmentTypeFinder(fileName: any) {
  const ext = FileExtensionExtractor(fileName);
  if (ext === "7z" || ext === "part" || ext === "rar") {
    return NewAttachmentType.compressed;
  } else if (
    ext === "avi" ||
    ext === "flv" ||
    ext === "mkv" ||
    ext === "mp4" ||
    ext === "mpeg" ||
    ext === "mpg" ||
    ext === "vob" ||
    ext === "vol" ||
    ext === "wmv"
  ) {
    return NewAttachmentType.video;
  } else if (ext === "atx" || ext === "gpx" || ext === "sbn") {
    return NewAttachmentType.location;
  } else if (ext === "bin" || ext === "iso") {
    return NewAttachmentType.clone;
  } else if (
    ext === "bmp" ||
    ext === "gif" ||
    ext === "jpg" ||
    ext === "jpeg" ||
    ext === "png" ||
    ext === "tif"
  ) {
    return NewAttachmentType.image;
  } else if (ext === "doc" || ext === "docx") {
    return NewAttachmentType.word;
  } else if (ext === "htm" || ext === "html" || ext === "xml") {
    return NewAttachmentType.html;
  } else if (ext === "log" || ext === "txt") {
    return NewAttachmentType.note;
  } else if (ext === "pdf") {
    return NewAttachmentType.pdf;
  } else if (ext === "mp3" || ext === "spx") {
    return NewAttachmentType.music;
  } else if (ext === "ppt" || ext === "pptx") {
    return NewAttachmentType.powerpoint;
  } else if (ext === "xls" || ext === "xlsx") {
    return NewAttachmentType.excel;
  } else {
    return NewAttachmentType.unknown;
  }
}

function IsImage(fileName: any) {
  if (
    FileExtensionExtractor(fileName) === "bmp" ||
    FileExtensionExtractor(fileName) === "gif" ||
    FileExtensionExtractor(fileName) === "jpg" ||
    FileExtensionExtractor(fileName) === "png" ||
    FileExtensionExtractor(fileName) === "tif"
  ) {
    return true;
  } else return false;
}

function IsPDF(fileName: any) {
  if (FileExtensionExtractor(fileName) === "pdf") {
    return true;
  } else return false;
}

export {
  FileKeyCutterWithExtension,
  FileType2AttachmentType,
  FileExtensionExtractor,
  AttachmentTypeFinder,
  IsImage,
  IsPDF,
};
