import { TabTitle } from "../../../../types/general/generalTypes";
export default function TabsData(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 0,
        text: "Details",
        icon: "fa-solid fa-ellipsis",
        content: "Details tab content",
      },
      {
        id: 1,
        text: "Location",
        icon: "fa-solid fa-location-dot",
        content: "Location tab content",
      },
      {
        id: 2,
        text: "Attachments",
        icon: "fa-solid fa-paperclip",
        content: "Attachments tab content",
      },
      {
        id: 3,
        text: "Change Logs",
        icon: "fa-solid fa-list-check",
        content: "Change Logs tab content",
      },
      {
        id: 3,
        text: "Maintenance",
        icon: "fa-solid fa-screwdriver-wrench",
        content: "Maintenance tab content",
        disabled: id === "AddNew" ? true : false,
      },
    ])
  );
}
