import { Button, NumberBox, Tabs, TextBox } from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CollapsibleCard from "../../../../components/collapsibleCard/collapsibleCard";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { MatchingDetailDTO } from "../../../../types/trafficStudy/dtos/matchingDto";
import { IMatchingProjectParams } from "../../../../types/trafficStudy/trafficStudyTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import Holidays from "./components/holidays";
import Result from "./components/results/results";
import tabTitles from "./data";
import TesTabs from "../../../../components/tesTab/tesTab";

const MatchingProjectDetails = () => {
  const [initData, setInitData] = useState<MatchingDetailDTO>(
    new MatchingDetailDTO()
  );
  const [dataChanged, setDataChanged] = useState(false);
  const params = useParams<IMatchingProjectParams>();
  const [isLocked, setIsLocked] = useState(true);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const didMount = useRef(false);
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { generalSetting } = useClientSetting();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          if (params.projectId !== "AddNew") {
            await GetInitData();
          } else {
            setIsLocked(false);
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function GetInitData() {
    try {
      const res = (await TesGet(
        TrafficApiUrl() +
          "/api/Matching/GetMatchingProjectDetails/" +
          params.projectId,
        true
      )) as RequestResponseResult<MatchingDetailDTO>;
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function onValueChange(key: string, value: any) {
    setInitData({
      ...initData,
      [key]: value,
    });
    setDataChanged(true);
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function onRun() {
    try {
      if (activeLoading) activeLoading(true);

      const url =
        TrafficApiUrl() +
        (params.projectId === "AddNew"
          ? "/api/......./AddRequest"
          : "/api/......./UpdateRequest");

      const res = (await TesPost(
        url,
        {
          ...initData,
          customerId: localStorage.getItem("selectedCustomerId"),
          divisionId: localStorage.getItem("selectedDivisionId"),
        },
        true
      )) as RequestResponseResult<any>;

      if (activeLoading) activeLoading(false);

      if (res.responseCode === ResponseCode.OK) {
        setDataChanged(false);
        notify(t("analysisMessage"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }

      setDataChanged(false);
      history(-1);
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function goBackPermission() {
    if (dataChanged) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                onRun();
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("matchingProjectDetails")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card"}>
          <div className="row" style={{ marginTop: 15 }}>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            <div className="rightColumn">
              {!isLocked && (
                <Button
                  onClick={onRun}
                  icon="fa-solid fa-play"
                  hint={t("run")}
                />
              )}
              {params.projectId !== "AddNew" && (
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              )}
            </div>
          </div>
        </div>
        <div className={"dx-card "}>
          <div className="row" style={{ marginTop: 20, padding: 10 }}>
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <div style={{ marginTop: "-2rem", marginBottom: "-2rem" }}>
                  <CollapsibleCard title={t("settings")}>
                    <Form colCount={9}>
                      <SimpleItem colSpan={3}>
                        <TextBox
                          placeholder=""
                          label={t("name")}
                          labelMode="floating"
                          value={initData.name}
                          onValueChange={(e) => onValueChange("name", e)}
                          disabled={isLocked}
                          showClearButton={true}
                          //   style={{ margin: "-0.75rem 0 0 0" }}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={3}>
                        <NumberBox
                          label={t("year")}
                          labelMode="floating"
                          value={initData.year}
                          onValueChange={(e) => onValueChange("year", e)}
                          disabled={isLocked}
                          //   style={{ margin: "-0.75rem 0 0 0" }}
                        />
                      </SimpleItem>
                    </Form>
                  </CollapsibleCard>
                </div>
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <TesTabs
                  width={"100%"}
                  dataSource={tabTitles}
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />

                {selectedIndex === 0 && (
                  <Result initDataResults={initData.results} />
                )}

                {selectedIndex === 1 && (
                  <Holidays
                    initDataHolidays={initData.holidays}
                    generalSettings={generalSetting}
                  />
                )}
              </SimpleItem>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MatchingProjectDetails;
