import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "patients",
    icon: "fa-solid fa-user-injured",
    content: "Patients tab content",
  },
];
export default tabTitles;
