import {
  Button,
  NumberBox,
  SelectBox,
  TagBox,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../../components/collapsibleCard/collapsibleCard";
import reportLocations from "../../../../../../constants/reportLocations";
import { useAuth } from "../../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../../types/general/enums/generalEnums";
import {
  NameValue,
  NameValueNumber,
  RequestResponseResult,
} from "../../../../../../types/general/generalTypes";
import {
  SectionDetailType,
  SectionType,
} from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import {
  JustificationDTO,
  StudyBaseDTO,
  StudySignalWarrantRecalculationReqDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import {
  ApproachLaneNo,
  ApproachType,
  FlowConditionType,
} from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import {
  SortObjectByPropName,
  SumIntArray,
} from "../../../../../../utils/arrayTools";
import { Enum2Array, EnumFlag2Array } from "../../../../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../../../../utils/rest";
import TabOne from "./components/1";
import TabTwo from "./components/2";
import TabThree from "./components/3";
import TabFour from "./components/4";
import TabFive from "./components/5";
import TabSix from "./components/6";
import tabTitles from "./data/data";
import "./signalWarrants.scss";
import TesTabs from "../../../../../../components/tesTab/tesTab";
import ReportSelector from "../../../../../../components/reportSelector";

// props
interface IPros {
  initDataBase: StudyBaseDTO;
  generalSettings: GeneralSetting | null;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const SignalWarrantsTab = (props: IPros) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lstApproachLaneOnMajor, setApproachLaneOnMajor] = useState<
    NameValue[]
  >([]);
  const [lstApproachLaneOnMinor, setLstApproachLaneOnMinor] = useState<
    NameValue[]
  >([]);
  const [lstYears, setLstYears] = useState<NameValueNumber[]>([]);
  const [lstSiteType, setLstSiteType] = useState<NameValue[]>([]);
  const [lstFlowCondition, setLstFlowCondition] = useState<NameValue[]>([]);
  const [
    lstChannelizedRightTurnsApproaches,
    setLstChannelizedRightTurnsApproaches,
  ] = useState<NameValue[]>([]);
  const [justificationName, setJustificationName] = useState<string>("");
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstApproachLaneOnMinor(Enum2Array(ApproachLaneNo));
        setApproachLaneOnMajor(Enum2Array(ApproachLaneNo));
        setLstSiteType(Enum2Array(SectionType));
        setLstFlowCondition(Enum2Array(FlowConditionType));
        setLstChannelizedRightTurnsApproaches(Enum2Array(ApproachType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    setJustificationName(
      props.initDataBase?.signalWarrants?.justification1[0]?.name ?? ""
    );
    var yearRes: NameValueNumber[] = [];
    for (let year = new Date().getFullYear(); year > 1980; year--) {
      let newYew: NameValueNumber = {
        name: year.toString(),
        value: year,
      };
      yearRes.push(newYew);
    }
    setLstYears(yearRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onValueChange(name: string, value: any) {
    props.setInitDataBase({
      ...props.initDataBase,
      signalWarrantConfigChanged: true,
      signalWarrantConfig: {
        ...props.initDataBase.signalWarrantConfig,
        [name]: value,
      },
    });
    props.setDataChanged(true);
  }

  function onJustificationDateTimeChange(value: any) {
    setJustificationName(value);
  }
  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function onRecalculate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: StudySignalWarrantRecalculationReqDTO = {
        studyId: props.initDataBase.id!,
        signalWarrantConfig: props.initDataBase.signalWarrantConfig,
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Study/ReCalculateSignalWarrants",
        postObj,
        true
      )) as RequestResponseResult<JustificationDTO>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        props.setInitDataBase({
          ...props.initDataBase,
          signalWarrantConfigChanged: false,
          signalWarrants: res.results,
          justificationSummary: res.results.justificationSummary,
        });
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.RawDataNotExist) {
        notify(t("rawDataNotExist"), "error", 5000);
      } else if (
        res.responseCode === ResponseCode.SignalWarrantSettingsNotExist
      ) {
        notify(t("signalWarrantSettingsNotExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <div
      className={`studyDetails-signalWarrants ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row signal" style={{ margin: "1rem" }}>
          <ValidationGroup ref={props.validationRef}>
            <div
              className={"dx-card"}
              id="iconsCard"
              style={{ margin: "0rem 1rem 2rem 1rem" }}
            >
              <div className="row" style={{ margin: "0.7rem 0 -0.7rem 0" }}>
                <div className="leftColumn">
                  <Button
                    icon="fa-solid fa-calculator"
                    style={{ marginRight: 10 }}
                    hint={t("recalculate")}
                    onClick={onRecalculate}
                    disabled={props.isLocked}
                  />
                  <ReportSelector
                    reportLocations={[
                      reportLocations.TrafficStudy_StudyDetails_SignalWarrants,
                    ]}
                    getRecords={() => [{ id: props.initDataBase.id! }]}
                  />
                </div>
                <div className="rightColumn">
                  <p style={{ fontWeight: "bold", fontSize: 16 }}>
                    {t("thisStudyIs")}
                    <span>
                      {props.initDataBase?.signalWarrants?.isJustified
                        ? t("justified")
                        : t("notJustifiedLower")}
                    </span>
                    ({props.initDataBase.justificationSummary}).
                  </p>
                </div>
              </div>
            </div>
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <div className="title" style={{ fontWeight: "bolder" }}>
                  {t("period")}
                </div>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  id="period"
                  placeholder=""
                  label={t("dateTime")}
                  valueExpr="name"
                  displayExpr="name"
                  labelMode="floating"
                  value={justificationName}
                  onValueChange={(e) => onJustificationDateTimeChange(e)}
                  className="modalInput"
                  items={SortObjectByPropName(
                    props.initDataBase?.signalWarrants?.justification1,
                    "name"
                  )}
                  //disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1} />
              <SimpleItem colSpan={2}>
                <div id="configurationsCollapse">
                  <CollapsibleCard
                    title={t("configsRelatedToPeriodSelection")}
                    isOpen={false}
                  >
                    <Form colCount={3} className="popupFields">
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("sectionType")}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode="floating"
                          value={
                            props.initDataBase?.sectionType ===
                            SectionDetailType.FourLegIntersection
                              ? SectionType.FourLegIntersection
                              : SectionType.TIntersection
                          }
                          //onValueChange={e => onValueChange("sectionType", e)}
                          className="modalInput"
                          items={SortObjectByPropName(lstSiteType, "name")}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("approachLaneOnMajor")}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode="floating"
                          value={
                            props.initDataBase?.signalWarrantConfig
                              ?.approachLaneOnMajor
                          }
                          onValueChange={(e) =>
                            onValueChange("approachLaneOnMajor", e)
                          }
                          className="modalInput"
                          items={SortObjectByPropName(
                            lstApproachLaneOnMajor,
                            "name"
                          )}
                          disabled={props.isLocked}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("approachLaneOnMinor")}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode="floating"
                          value={
                            props.initDataBase?.signalWarrantConfig
                              ?.approachLaneOnMinor
                          }
                          onValueChange={(e) =>
                            onValueChange("approachLaneOnMinor", e)
                          }
                          className="modalInput"
                          items={SortObjectByPropName(
                            lstApproachLaneOnMinor,
                            "name"
                          )}
                          disabled={props.isLocked}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <TagBox
                          label={t("channelizesRightTurnsApproaches")}
                          labelMode="floating"
                          className="modalInput"
                          dataSource={SortObjectByPropName(
                            lstChannelizedRightTurnsApproaches.filter(
                              (x) => x.value !== "16"
                            ),
                            "name"
                          )}
                          defaultValue={EnumFlag2Array(
                            ApproachType,
                            props.initDataBase?.signalWarrantConfig
                              ?.channelizesRightTurnsApproaches
                          )}
                          valueExpr="value"
                          displayExpr="name"
                          onValueChange={(e) =>
                            onValueChange(
                              "channelizesRightTurnsApproaches",
                              SumIntArray(e)
                            )
                          }
                          disabled={props.isLocked}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("flowCondition")}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode="floating"
                          value={
                            props.initDataBase?.signalWarrantConfig
                              ?.flowCondition
                          }
                          onValueChange={(e) =>
                            onValueChange("flowCondition", e)
                          }
                          className="modalInput"
                          items={SortObjectByPropName(lstFlowCondition, "name")}
                          disabled={props.isLocked}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          step={0}
                          label={t("hoursNeededForJustification")}
                          labelMode="floating"
                          value={
                            props.initDataBase?.signalWarrantConfig
                              ?.hoursNeededForJustification
                          }
                          onValueChange={(e) =>
                            onValueChange("hoursNeededForJustification", e)
                          }
                          className="modalInput"
                          disabled={props.isLocked}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          step={0}
                          label={t("pedestrianDelayVolume")}
                          labelMode="floating"
                          value={
                            props.initDataBase?.signalWarrantConfig
                              ?.pedestrianDelayVolume
                          }
                          onValueChange={(e) =>
                            onValueChange("pedestrianDelayVolume", e)
                          }
                          className="modalInput"
                          disabled={props.isLocked}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <TagBox
                          label={t("years")}
                          labelMode="floating"
                          className="modalInput"
                          dataSource={SortObjectByPropName(lstYears, "name")}
                          value={props.initDataBase?.signalWarrantConfig?.years}
                          valueExpr="value"
                          displayExpr="name"
                          onValueChange={(e) => onValueChange("years", e)}
                          disabled={props.isLocked}
                        />
                      </SimpleItem>
                    </Form>
                  </CollapsibleCard>
                </div>
              </SimpleItem>
            </Form>
            <div className={"dx-card "} style={{ marginTop: "1rem" }}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />
              {selectedIndex === 0 && (
                <TabOne
                  justificationName={justificationName}
                  signalWarrants={props.initDataBase?.signalWarrants}
                />
              )}
              {selectedIndex === 1 && (
                <TabTwo
                  justificationName={justificationName}
                  signalWarrants={props.initDataBase?.signalWarrants}
                />
              )}
              {selectedIndex === 2 && (
                <TabThree
                  justificationName={justificationName}
                  signalWarrants={props.initDataBase?.signalWarrants}
                />
              )}
              {selectedIndex === 3 && (
                <TabFour
                  justificationName={justificationName}
                  signalWarrants={props.initDataBase?.signalWarrants}
                />
              )}
              {selectedIndex === 4 && (
                <TabFive
                  justificationName={justificationName}
                  signalWarrants={props.initDataBase?.signalWarrants}
                />
              )}
              {selectedIndex === 5 && (
                <TabSix
                  justificationName={justificationName}
                  signalWarrants={props.initDataBase?.signalWarrants}
                />
              )}
            </div>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default SignalWarrantsTab;
