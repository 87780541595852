import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import notify from "devextreme/ui/notify";
import axios from "axios";
import {
  GridType,
  PagePermission,
  Permission,
  ResponseCode,
} from "../types/general/enums/generalEnums";
import { useNavigate, useLocation } from "react-router-dom";
import { ILoginResponse } from "../types/identity/identityTypes";
import { t } from "i18next";
import { INameId } from "../types/general/generalTypes";
import { AuthApiUrl } from "../environment/routeSettings";
import { IUser } from "../types/identity/dto/identityDTO";
import { Enum2Array } from "../utils/enumTools";
import { RemoveData4LogOut } from "../utils/authTools";
import {
  DivisionSystem,
  SingleDivisionAccess,
} from "../types/identity/enums/identityEnums";
import { TMSPermissions } from "../constants/Permissions";
import { ITesBase } from "../types/general/generalInterfaces";

type AuthContextType = {
  user: IUser | null;
  selectedClineNameId: INameId | null;
  loading: boolean;
  localLoading: boolean;
  logIn?: (
    email: string,
    password: string,
    captchaToken: string
  ) => Promise<void>;
  twoStepLogIn?: (authCode: string, token: string) => Promise<void>;
  activeLoading?: (status: boolean) => void;
  logOut?: () => Promise<void>;
  getUserProfile?: () => Promise<void>;
  setClientNameId?: (id: string, name: string) => Promise<void>;
  getPagePermission?: <T extends ITesBase>(
    editRoles: string[],
    indata: T
  ) => PagePermission;
};
const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  localLoading: false,
  selectedClineNameId: null,
});
const useAuth = () => useContext(AuthContext);

function AuthProvider(props: any) {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const history = useNavigate();
  const [selectedClineNameId, setSelectedClineNameId] = useState<INameId>();
  const location = useLocation();

  const activeLoading = useCallback((status: any) => {
    setLocalLoading(status);
  }, []);

  const getPagePermission = useCallback(
    <T extends ITesBase>(editRoles: string[], indata: T): PagePermission => {
      try {
        if (user?.permissions?.some((x) => x === TMSPermissions.TMS_Admin)) {
          return PagePermission.Edit;
        }

        var hasWritePrm = user?.permissions?.some(
          (a) => editRoles.indexOf(a) >= 0
        );

        if (user?.divisionSystem === DivisionSystem.None) {
          if (hasWritePrm) {
            return PagePermission.Edit;
          } else {
            return PagePermission.View;
          }
        }

        if (user?.divisionSystem === DivisionSystem.SingleOrAll) {
          if (
            user.singleDivisionAccess === SingleDivisionAccess.WriteAll &&
            hasWritePrm
          ) {
            return PagePermission.Edit;
          }
          if (user.singleDivision?.id === indata.divisionId && hasWritePrm) {
            return PagePermission.Edit;
          }
          return PagePermission.View;
        }

        if (user?.divisionSystem === DivisionSystem.Multiple) {
          if (hasWritePrm) {
            return PagePermission.Edit;
          } else {
            return PagePermission.View;
          }
        }

        return PagePermission.Deny;
      } catch (ex) {
        notify(t("getPagePermission"), "error", 5000);
        return PagePermission.Deny;
      }
    },
    [user]
  );

  const twoStepLogIn = useCallback(async (authCode: any, token: any) => {
    var postObj = {
      AuthCode: authCode,
      Token: token,
    };
    try {
      const response = await axios.post(
        AuthApiUrl() + "/api/user/SetupTwoFactor",
        postObj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const res = await response;

      if (res.status === 200) {
        const loginResponse: ILoginResponse = res.data;
        if (loginResponse.responseCode === ResponseCode.TwoFactorIsNotValid) {
          notify(t("twoStepCodeNotValid"), "error", 5000);
          return {
            isOk: false,
          };
        }
        if (loginResponse.responseCode === ResponseCode.OK) {
          localStorage.setItem("tesToken", loginResponse.token);
        }
      }
    } catch (ex) {
      notify(t("usernamePassworsNotValid"), "error", 5000);
      return {
        isOk: false,
      };
    }
  }, []);

  const logIn = useCallback(
    async (email: any, password: any, captchaToken: any) => {
      var postObj = {
        Username: email,
        Password: password,
        CaptchaToken: captchaToken,
      };

      try {
        const response = await axios.post(
          AuthApiUrl() + "/api/auth/Login",
          postObj,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response;
        if (res.status === 200) {
          const loginResponse: ILoginResponse = res.data;

          if (loginResponse.responseCode === ResponseCode.CaptchaIsNotValid) {
            notify(t("captchaIsNotValid"), "error", 5000);
            return {
              isOk: false,
            };
          }

          if (
            loginResponse.responseCode === ResponseCode.UsernamePasswordNotValid
          ) {
            notify(t("usernamePassworsNotValid"), "error", 5000);
            return {
              isOk: false,
            };
          }

          if (loginResponse.responseCode === ResponseCode.OK) {
            if (loginResponse.setupTwoFactor === true) {
              //Go to Setup Two Step
              history(
                "/setupTwoStep/" +
                  loginResponse.token +
                  "/" +
                  encodeURIComponent(loginResponse.qrCodeSetupImageUrl) +
                  "/" +
                  loginResponse.manualEntryKey
              );
            }
            if (loginResponse.resetPassword === true) {
              //Go to ResetPassword
              history("/updatePassword/" + loginResponse.account);
            }
            if (
              loginResponse.setupTwoFactor === false &&
              loginResponse.twoFactorEnabled === true
            ) {
              //Go to Two Step Form
              history("/twoStep/" + loginResponse.token);
            }
            if (
              loginResponse.setupTwoFactor === false &&
              loginResponse.twoFactorEnabled === false
            ) {
              localStorage.setItem("tesToken", loginResponse.token);
            }
          }
        }
      } catch (ex) {
        notify(ex, "error", 5000);
        return {
          isOk: false,
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const getUserProfile = useCallback(async () => {
    try {
      const response = await axios.get(
        AuthApiUrl() + "/api/User/GetUserProfile",
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRF": "1",
          },
        }
      );
      const data = await response;
      if (data.status === 401) {
        logOut();
      }
      if (data.status === 200 && data.data.userName !== undefined) {
        setUser(data.data);
        if (!localStorage.getItem("selectedCustomerId")) {
          localStorage.setItem(
            "selectedCustomerName",
            data.data.defaultCustomerName
          );
          localStorage.setItem(
            "selectedCustomerId",
            data.data.defaultCustomerId
          );
          setSelectedClineNameId({
            id: data.data.defaultCustomerId,
            name: data.data.defaultCustomerName,
          });
          localStorage.setItem(
            "singleDivision",
            JSON.stringify({
              id: data.data.singleDivision.id,
              name: data.data.singleDivision.name,
            })
          );
          localStorage.setItem(
            "defaultDivisionId",
            data?.data?.singleDivision?.id ?? ""
          );
          localStorage.setItem("divisionSystem", data.data.divisionSystem);
          localStorage.setItem(
            "selectedDivisionId",
            data?.data?.singleDivision?.id ?? ""
          );
        }
      } else {
        logOut();
      }
    } catch {
      logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setClientNameId = useCallback(async (id: string, name: string) => {
    setSelectedClineNameId({ name: name, id: id });
  }, []);

  const logOut = useCallback(() => {
    RemoveData4LogOut();
    setUser(null);
  }, []);

  useEffect(() => {
    (async function () {
      if (
        !location.pathname.includes("updatePassword") &&
        !location.pathname.includes("setPassword")
      ) {
        await getUserProfile();
      }

      setSelectedClineNameId({
        id: localStorage.getItem("selectedCustomerId")!,
        name: localStorage.getItem("selectedCustomerName")!,
      });
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        logIn,
        logOut,
        getUserProfile,
        activeLoading,
        twoStepLogIn,
        getPagePermission,
        localLoading,
        loading,
        setClientNameId,
        selectedClineNameId,
      }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
