import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { t } from "i18next";
import { GridType } from "../../../../../../types/general/enums/generalEnums";
import { MMSResStatus } from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import {
  MmsRes,
  TesCodeValue,
} from "../../../../../../types/infrastructure/infrastructureTypes";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { useEffect, useRef, useState } from "react";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import {
  LazyLoadingRequest,
  NameValue,
} from "../../../../../../types/general/generalTypes";
import CustomStore from "devextreme/data/custom_store";
import { TesPost } from "../../../../../../utils/rest";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";

interface IProps {
  mmsProjectId: string;
  // resGridData: MmsRes[]
  // dataGridResRef: React.RefObject<DataGridRef<any, any>>
  initDataTesCodeValues: TesCodeValue;
}
//var dataSource: CustomStore<any, any> | null = null;
const Results = (props: IProps) => {
  const [lstStatus, setLstStatus] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  const dataSource = new CustomStore({
    key: "id",
    load: async (loadOption) => {
      return await TesPost(
        `${TrafficApiUrl()}/api/Mms/GetMmsResultByRequestId`,
        {
          customerId: localStorage.getItem("selectedCustomerId"),
          divisionId: localStorage.getItem("selectedDivisionId"),
          loadOptions: loadOption,
          filter: { id: props.mmsProjectId },
        } as LazyLoadingRequest,
        true
      );
    },
  });

  useEffect(() => {
    setLstStatus(Enum2Array(MMSResStatus));
  }, []);

  const headerFilterData = [
    {
      text: t("calculated"),
      value: ["status", "=", 1],
    },
    {
      text: t("aadtNotAvailable"),
      value: ["status", "=", 2],
    },
    {
      text: t("postedSpeedNotAvailable"),
      value: ["status", "=", 4],
    },
    {
      text: t("aadtAndPostedSpeedNotAvailable"),
      value: ["status", "=", 6],
    },
  ];

  const headerLookup = [
    {
      name: t("calculated"),
      value: 1,
    },
    {
      name: t("aadtNotAvailable"),
      value: 2,
    },
    {
      name: t("postedSpeedNotAvailable"),
      value: 4,
    },
    {
      name: t("aadtAndPostedSpeedNotAvailable"),
      value: 6,
    },
  ];

  return (
    <DataGrid
      id="gridContainer"
      width={"100%"}
      ref={dataGridRef}
      dataSource={dataSource}
      rowAlternationEnabled={true}
      showBorders={true}
      hoverStateEnabled={true}
      remoteOperations={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      style={{ margin: "1rem 1rem 0 1rem" }}
      onExporting={OnExporting}
    >
      <Export enabled={true} allowExportSelectedData={true} />
      <Grouping contextMenuEnabled={true} autoExpandAll={false} />
      <GroupPanel visible={true} /> {/* or "auto" */}
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={"top"} />
      <StateStoring
        enabled={true}
        type="localStorage"
        storageKey={GridType.MMSDetailsResults.toString()}
        savingTimeout={500}
      />
      <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
      <Summary>
        <GroupItem summaryType="count" alignByColumn name="Total Count" />
      </Summary>
      <Paging enabled={true} defaultPageSize={100} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[100, 200, 300, 400, 500]}
        showNavigationButtons={true}
        showInfo={true}
        visible={true}
      />
      <FilterRow visible={true} applyFilter="auto" />
      <HeaderFilter visible={true} />
      <SearchPanel visible={true} width={285} placeholder={t("search...")} />
      <ColumnChooser
        width={350}
        height={400}
        enabled={true}
        mode="select"
        sortOrder="asc"
      >
        <Search enabled />
      </ColumnChooser>
      <Column
        dataField="locationDescription"
        caption={t("locationDescription")}
        width={400}
      >
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="geoId" caption={t("geoId")} width={120}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column
        dataField="municipalityId"
        allowHeaderFiltering={true}
        caption={t("municipality")}
        width={170}
      >
        <Search enabled />
        <Lookup
          dataSource={SortObjectByPropName(
            props.initDataTesCodeValues?.municipalities,
            "name"
          )}
          valueExpr="id"
          displayExpr="name"
        />
        <HeaderFilter
          dataSource={props.initDataTesCodeValues?.municipalities?.map((x) => ({
            text: x.name,
            value: x.id,
          }))}
        >
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column
        dataField="jurisdictionId"
        allowHeaderFiltering={true}
        caption={t("jurisdiction")}
        width={170}
      >
        <Search enabled />
        <Lookup
          dataSource={SortObjectByPropName(
            props.initDataTesCodeValues?.jurisdictions,
            "name"
          )}
          valueExpr="id"
          displayExpr="name"
        />
        <HeaderFilter
          dataSource={props.initDataTesCodeValues?.jurisdictions?.map((x) => ({
            text: x.name,
            value: x.id,
          }))}
        >
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="postedSpeed" caption={t("postedSpeed")} width={100}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="aadt" caption={t("aadt")} width={90}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="smoothMMS" caption={t("smoothMMS")} width={100}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="mms" caption={t("mmsClassification")} width={140}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="rin" caption={t("RIN")} width={100}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="status" caption={t("status")}>
        <HeaderFilter dataSource={headerFilterData}>
          <Search enabled />
        </HeaderFilter>
        <Lookup
          dataSource={headerLookup}
          valueExpr="value"
          displayExpr="name"
        />
      </Column>
      <Column dataField="id" caption={t("id")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column
        dataField="mmsRequestId"
        caption={t("mmsRequestId")}
        visible={false}
      >
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="customerId" caption={t("customerId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="divisionId" caption={t("divisionId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="locationId" caption={t("locationId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
    </DataGrid>
  );
};

export default Results;
