import { Button, ScrollView, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Popup } from "devextreme-react/popup";
import { Tooltip } from "devextreme-react/tooltip";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useState } from "react";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";
import { AttachmentDownloadURL } from "../../../../../../types/general/generalTypes";
import { NewAttachmentType } from "../../../../../../types/sign/enums/signEnums";
import { StudyBaseDTO } from "../../../../../../types/trafficStudy/dtos/studyDto";
import {
  AttachmentTypeFinder,
  FileKeyCutterWithExtension,
  IsImage,
  IsPDF,
} from "../../../../../../utils/fileTools";
import { TesPost } from "../../../../../../utils/rest";
import "./file.scss";
import PdfViewer from "../../../../../../components/pdfViewer/pdfViewer";

interface IPros {
  files: any[];
  initDataBase: StudyBaseDTO;
  isLocked: boolean;
  onDeleteNewFile: (key: string) => void;
  onDeleteExistingFile: (key: string) => void;
  handleChangeFile: (files: any[] | undefined) => void;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Files = (props: IPros) => {
  const [selectedFileName, setSelectedFileName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false);
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const [downloadIconColor, setDownloadIconColor] = useState<string>("#F0B70D");
  const [trashIconColor, setTrashIconColor] = useState<string>("#F0B70D");
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  const iconMap: Record<NewAttachmentType, string> = {
    [NewAttachmentType.compressed]: "fa-file-zipper",
    [NewAttachmentType.video]: "fa-youtube",
    [NewAttachmentType.location]: "fa-location-dot",
    [NewAttachmentType.clone]: "fa-compact-disk",
    [NewAttachmentType.image]: "fa-image",
    [NewAttachmentType.word]: "fa-file-word",
    [NewAttachmentType.html]: "fa-file-code",
    [NewAttachmentType.note]: "fa-file-lines",
    [NewAttachmentType.pdf]: "fa-file-pdf",
    [NewAttachmentType.music]: "fa-headphones",
    [NewAttachmentType.powerpoint]: "fa-file-powerpoint",
    [NewAttachmentType.excel]: "fa-file-excel",
    [NewAttachmentType.unknown]: "fa-file-circle-question",
  };

  const onMouseIn = (event: any, key: string) => {
    setSelectedFileName(key);
  };

  const onMouseOutDelete = (event: any, key: string) => {
    setSelectedFileName("");
  };

  async function showAttachment(fileKey: string) {
    var res = (await TesPost(
      TrafficApiUrl() + "/api/Import/DownloadFile",
      { key: fileKey },
      true
    )) as AttachmentDownloadURL;
    setUrl(res.downloadUrl);
    if (IsImage(fileKey)) {
      setShowImageModal(true);
    } else if (IsPDF(fileKey)) {
      setShowPDFModal(true);
    } else {
      setShowURLModal(true);
    }
  }

  async function onDelete(key: string, mode: number) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            if (mode === 1) {
              props.onDeleteExistingFile(key);
            }
            if (mode === 2) {
              props.onDeleteNewFile(key);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  return (
    <div
      className={`collision-attachments ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          {props.initDataBase?.files === null ? (
            <div className="row" style={{ marginTop: 20, padding: 10 }}>
              <div className="halfColumn">
                <p style={{ fontWeight: "bold" }}>
                  {t("thereIsNoDataInThisTab")}
                </p>
              </div>
            </div>
          ) : (
            <div className={"content-block"}>
              <div className={" responsive-paddings"}>
                <div className="row">
                  <div className="rightColumn">
                    {/* <Button
                                            onClick={() => setShowUploadModal(true)}
                                            icon="fa-solid fa-circle-plus"
                                            text={t("add")}
                                            disabled={props.isLocked}
                                        /> */}
                  </div>
                </div>
                <div className="row" style={{ marginTop: "-4.75rem" }}>
                  {props.initDataBase?.files !== null && (
                    <h5 style={{ marginBottom: "1rem" }}>{t("attachments")}</h5>
                  )}
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {props.initDataBase?.files &&
                      props.initDataBase?.files?.map((f, index) => (
                        <div
                          style={{
                            width: "12%",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "0 1.5rem -1rem 0",
                          }}
                          onMouseLeave={(e) => onMouseOutDelete(e, f.fileName)}
                          onMouseOver={(e) => onMouseIn(e, f.fileName)}
                        >
                          <div
                            style={{
                              height: "8rem",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div style={{ width: "40%" }}>
                                <i
                                  style={{ cursor: "pointer" }}
                                  className={`fa-solid ${iconMap[AttachmentTypeFinder(f.fileName)]} ${!compactViewModel ? "fa-3x" : "fa-2x"}`}
                                  onClick={() => showAttachment(f.key)}
                                />
                              </div>
                              {selectedFileName === f.fileName && (
                                <div
                                  style={{
                                    width: "60%",
                                    display: "flex",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                  }}
                                >
                                  <i
                                    className={`fa-solid fa-download ${!compactViewModel ? "fa-xl" : "fa-lg"}`}
                                    onClick={() => showAttachment(f.key)}
                                    style={{
                                      display: "block",
                                      margin: "1rem 0.5rem 0 0",
                                      cursor: "pointer",
                                      color: downloadIconColor,
                                    }}
                                    onMouseOver={(e) =>
                                      setDownloadIconColor("#212121")
                                    }
                                    onMouseLeave={(e) =>
                                      setDownloadIconColor("#F0B70D")
                                    }
                                  ></i>
                                  {!props.isLocked && (
                                    <i
                                      className={`fa-solid fa-trash-can ${!compactViewModel ? "fa-xl" : "fa-lg"}`}
                                      onClick={() => onDelete(f.fileName, 1)}
                                      style={{
                                        display: "block",
                                        margin: "1rem 0 0 0",
                                        cursor: "pointer",
                                        color: trashIconColor,
                                      }}
                                      onMouseOver={(e) =>
                                        setTrashIconColor("#212121")
                                      }
                                      onMouseLeave={(e) =>
                                        setTrashIconColor("#F0B70D")
                                      }
                                    ></i>
                                  )}
                                </div>
                              )}
                            </div>
                            <p
                              style={{
                                width: "100px",
                                marginTop: "0rem",
                                cursor: "default",
                              }}
                              id={`previousFile${index}`}
                            >
                              {FileKeyCutterWithExtension(f.fileName, 15)}
                            </p>
                            {f.fileName.length > 15 && (
                              <Tooltip
                                target={`#previousFile${index}`}
                                showEvent="mouseenter"
                                hideEvent="mouseleave"
                                hideOnOutsideClick={false}
                              >
                                <div>{f.fileName}</div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>

                  {props.files?.length !== 0 && (
                    <h5 style={{ margin: "1rem 0" }}>{t("newAttachments")}</h5>
                  )}
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {props.files?.map((f, index) => (
                      <div
                        style={{
                          width: "12%",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "0 1.5rem -1rem 0",
                        }}
                        onMouseLeave={(e) => onMouseOutDelete(e, f.name)}
                        onMouseOver={(e) => onMouseIn(e, f.name)}
                      >
                        <div
                          style={{
                            height: "8rem",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div style={{ width: "40%" }}>
                              <i
                                style={{ cursor: "pointer" }}
                                className={`fa-solid ${iconMap[AttachmentTypeFinder(f.name)]} ${!compactViewModel ? "fa-3x" : "fa-2x"}`}
                                // onClick={() => showAttachment(f.key)}
                              />
                            </div>
                            {selectedFileName === f.name && (
                              <div
                                style={{
                                  width: "60%",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "center",
                                }}
                              >
                                {/* <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i> */}
                                {!props.isLocked && (
                                  <i
                                    className={`fa-solid fa-trash-can ${!compactViewModel ? "fa-xl" : "fa-lg"}`}
                                    onClick={() => onDelete(f.name, 2)}
                                    style={{
                                      display: "block",
                                      margin: "1rem 0 0 0",
                                      cursor: "pointer",
                                      color: trashIconColor,
                                    }}
                                    onMouseOver={(e) =>
                                      setTrashIconColor("#212121")
                                    }
                                    onMouseLeave={(e) =>
                                      setTrashIconColor("#F0B70D")
                                    }
                                  ></i>
                                )}
                              </div>
                            )}
                          </div>
                          <p
                            style={{
                              width: "100px",
                              marginTop: "0rem",
                              cursor: "default",
                            }}
                            id={`recentFile${index}`}
                          >
                            {FileKeyCutterWithExtension(f.name, 15)}
                          </p>
                          {f.name.length > 15 && (
                            <Tooltip
                              target={`#recentFile${index}`}
                              showEvent="mouseenter"
                              hideEvent="mouseleave"
                              hideOnOutsideClick={false}
                            >
                              <div>{f.name}</div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </ValidationGroup>
      </React.Fragment>

      {/* Image modal */}
      <Popup
        width={"50%"}
        height={"auto"}
        visible={showImageModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("image")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowImageModal(false)}
      >
        <ScrollView width="100%" height="100%">
          <div style={{ height: "90%" }}>
            <div className="row">
              <div className="col-md-12">
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img
                  src={url}
                  id="pic"
                  style={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="rightColumn" style={{ marginTop: 10 }}>
              <Button
                className="tes-modal-btn-add"
                style={{ marginRight: 20 }}
                onClick={() => window.open(url, "_blank")}
                text={t("download")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  notify(t("linkCopied"), "success", 5000);
                }}
                text={t("copyLink")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>

      {/* PDF modal */}
      <div>
        {showPDFModal && (
          <div className="pdfModalOverlay">
            <div className="pdfModalContent">
              <div className="pdfModalHeader">
                <h2>PDF</h2>
                <div style={{ marginRight: "20px" }}>
                  <Button
                    onClick={(e) => setShowPDFModal(false)}
                    icon="dx-icon dx-icon-close"
                  />
                </div>
              </div>
              <div className="pdfModalBody">
                <PdfViewer url={url} />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* URL modal */}
      <Popup
        width={"50%"}
        height={"auto"}
        visible={showURLModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("url")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowURLModal(false)}
      >
        <ScrollView width="100%" height="100%">
          <div style={{ height: "90%" }}>
            <div className="row">
              <div className="col-md-6">
                <span style={{ color: "#F0B70D", fontWeight: "bold" }}>
                  {t("link")}:{" "}
                </span>
                <a
                  href={url}
                  style={{ textDecoration: "underline", color: "#404040" }}
                >
                  {url}
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="rightColumn" style={{ marginTop: 10 }}>
              <Button
                className="tes-modal-btn-add"
                style={{ marginRight: 20 }}
                onClick={() => window.open(url, "_blank")}
                text={t("download")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={() => {
                  navigator.clipboard.writeText(url);
                  notify(t("linkCopied"), "success", 5000);
                }}
                text={t("copyLink")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
};

export default Files;
