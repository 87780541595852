import i18n from "../../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "monthlyAdjustmentFactor",
    icon: "fa-regular fa-calendar-days",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "dayOfWeekAdjustmentFactor",
    icon: "fa-solid fa-calendar-day",
    content: "Location tab content",
  },
  {
    id: 2,
    text: "hourAdjustmentFactor",
    icon: "fa-solid fa-clock",
    content: "Location tab content",
  },
];
export default tabTitles;
