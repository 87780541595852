import React, { useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import { t } from "i18next";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import { useAuth } from "../../contexts/auth";
import notify from "devextreme/ui/notify";
import { RequestErrorHandling, TesPut } from "../../utils/rest";
import { AuthApiUrl } from "../../environment/routeSettings";
import { RequestResponseResult } from "../../types/general/generalTypes";
import { ChangePassword } from "../../types/identity/identityTypes";

export default function ChangePasswordForm() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ password: "", confirmPass: "" });
  const { userId } = useParams<{ userId: string }>();
  const { activeLoading } = useAuth();

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const { password, confirmPass } = formData.current;
      setLoading(true);

      try {
        if (activeLoading) activeLoading(true);
        const regex =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

        if (!regex.test(password ?? "")) {
          notify(t("passwordIsNotComplex"), "error", 5000);
          setLoading(false);
          if (activeLoading) activeLoading(false);
          return;
        }

        const postObj: ChangePassword = {
          currentPass: password,
          newPass: confirmPass,
          userId: userId as string,
        };

        const res = (await TesPut(
          AuthApiUrl() + "/api/user/ResetPassword/" + userId,
          postObj,
          true
        )) as RequestResponseResult<ChangePassword>;

        if (res.responseCode === ResponseCode.OK) {
          notify(t("passwordChangedSuccessfully"), "success", 5000);
          history("/login");
        } else {
          handleResponseCode(res.responseCode);
        }
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      } finally {
        setLoading(false);
        if (activeLoading) activeLoading(false);
      }
    },
    [history, userId, activeLoading]
  );

  const handleResponseCode = (responseCode: ResponseCode) => {
    if (activeLoading) activeLoading(false);
    switch (responseCode) {
      case ResponseCode.TokenExpired:
        notify(t("enum-TokenExpired"), "error", 5000);
        break;
      case ResponseCode.LoginBlocked:
        notify(t("enum-LoginBlocked"), "error", 5000);
        break;
      case ResponseCode.PasswordIsNotComplex:
        notify(t("enum-PasswordIsNotComplex"), "error", 5000);
        break;
      case ResponseCode.UpdatingError:
        notify(t("enum-UpdatingError"), "error", 5000);
        break;
      default:
        notify(t("someError"), "error", 5000);
        break;
    }
  };

  const confirmPassword = useCallback((options: { value: string | number }) => {
    return options.value === formData.current.confirmPass;
  }, []);

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmPass"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={"Passwords do not match"}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                t("continue")
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Password",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Confirm Password",
  mode: "password",
};
