import { TClientReport } from "../../types/report/reportTypes";
import reportLocations, {
  TReportLocation,
} from "../../constants/reportLocations";
import { TReportType, TReportTypeName } from "./types";

export const STATIC_REPORTS_OPTIONS: Partial<{
  [key in TReportLocation]: TClientReport[];
}> = {
  Collision_Collisions: [
    {
      id: "validationReport",
      name: "validationReport",
      reportLocations: [
        {
          name: reportLocations.Collision_Collisions,
          clientReportId: "",
          id: "",
        },
      ],
      reportFile: [],
      customerId: "",
      tesReportType: {
        id: "",
        name: "",
        endPointURL: "",
      },
      tesReportTypeId: "",
    },
  ],
  Maintenance_jobs: [
    {
      id: "validationReport",
      name: "validationReport",
      reportLocations: [
        {
          name: reportLocations.Collision_Collisions,
          clientReportId: "",
          id: "",
        },
      ],
      reportFile: [],
      customerId: "",
      tesReportType: {
        id: "",
        name: "",
        endPointURL: "",
      },
      tesReportTypeId: "",
    },
  ],
};

export const REPORT_TYPES: Record<TReportTypeName, TReportType> = {
  ValidationReport: "validationReport",
  AllWayStopWarrantsDetails: "reportViewer_payload",
  TmcFullDiagram: "reportViewer_payload",
  TmcFullAADTDiagram: "reportViewer_payload",
  TmcDiagram: "reportViewer_payload",
  TmcPhDiagram: "reportViewer_payload",
  TMCPhsAndFullDiagram: "reportViewer_payload",
  "15min": "reportViewer_payload",
  SignalWarrantsDetails: "reportViewer_payload",
  SpeedRangeRawDataPerHour: "reportViewer_payload",
  SpeedRangeRawDataWithSpeedStatistics: "reportViewer_payload",
  TotalVolumePerHour: "reportViewer_payload",
  VolumeRawData: "reportViewer_payload",
  DetailVolumeRawData4Day: "reportViewer_payload",
  VolumeMovement: "reportViewer_payload",
  DailyClassReport: "reportViewer_payload",
  WeeklyVolumeSummary: "reportViewer_payload",
  DailySpeedReport: "reportViewer_payload",

  //TODO: Need to be sure about the types
  CollisionAnnulaReport: "reportViewer_ids",
  CollisionChangeLog: "reportViewer_ids",
  CollisionDetails: "reportViewer_ids",
  IntersectionAADT: "reportViewer_ids",
  RoadSegmentAADT: "reportViewer_ids",
  SignalWarrantsDetailsWithMovement: "reportViewer_ids",
  SpeedStatistics: "reportViewer_ids",
  SupportReport: "reportViewer_ids",
  TmcDetails: "reportViewer_ids",
  TmcFullPhDiagram: "reportViewer_ids",
  TotalVolumeRawData4Day: "reportViewer_ids",
  VolumeApproach: "reportViewer_ids",
  VolumeApproach4Day: "reportViewer_ids",
  VolumeApproach4Hour: "reportViewer_ids",
  VolumeMovement4Day: "reportViewer_ids",
  VolumeMovement4Hour: "reportViewer_ids",
  SignReport: "reportViewer_ids",
};
