//#region import
import {
  SelectBox,
  TagBox,
  TextArea,
  TextBox,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { DateBox } from "devextreme-react/date-box";
import { Form, SimpleItem } from "devextreme-react/form";
import { NumberBox } from "devextreme-react/number-box";
import notify from "devextreme/ui/notify";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../contexts/auth";
import { VMJob } from "../../../../../types/maintenance/dto/maintenanceDto";
import {
  JobStatus,
  JobType,
  Tag,
} from "../../../../../types/maintenance/maintenanceTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
//#endregion import
//#region props
interface IPros {
  initDataJob: VMJob;
  initDataTags: Tag[];
  initDataJobStatuses: JobStatus[];
  initDataJobTypes: JobType[];
  generalSettings: any;
  durationString: string;
  isLocked: boolean;
  onValueChange: (name: string, value: any) => void;
  onChangeFields: (name: string, value: string) => void;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  setDurationString: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

//#endregion props
const Details = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await durationDisplay();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initDataJob.duration]);

  //#region
  function generateRandom(name: string) {
    var d = new Date();
    const res =
      d.getFullYear() +
      String(d.getMonth() + 1).padStart(2, "0") +
      String(d.getDate()).padStart(2, "0") +
      String(d.getHours()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getSeconds()).padStart(2, "0") +
      String(d.getMilliseconds()).padStart(2, "0");
    props.onChangeFields(name, res);
    return res;
  }

  async function durationDisplay() {
    if (
      props.initDataJob.duration !== "" &&
      props.initDataJob.duration !== undefined
    ) {
      const hh = parseInt(
        (parseInt(props.initDataJob.duration) / 60).toString()
      )
        .toString()
        .padStart(2, "0");
      const mm = (parseInt(props.initDataJob.duration) % 60)
        .toString()
        .padStart(2, "0");
      props.setDurationString(hh + mm);
    }
  }

  //#endregion
  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={2} className="popupFields">
            <SimpleItem colSpan={1}>
              <TextBox
                style={{ marginBottom: 20 }}
                label={t("jobId")}
                labelMode="floating"
                value={
                  props.initDataJob.jobId != null
                    ? props.initDataJob.jobId
                    : generateRandom("jobId")
                }
                disabled={true}
                onValueChange={(e) => props.onValueChange("jobId", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                style={{ marginBottom: 20 }}
                label={t("name")}
                labelMode="floating"
                value={props.initDataJob.name}
                onValueChange={(e) => props.onValueChange("name", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("jobType")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataJob.jobTypeId}
                onValueChange={(e) => props.onValueChange("jobTypeId", e)}
                className="modalInput"
                items={SortObjectByPropName(props.initDataJobTypes, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("jobStatus")}
                valueExpr="id"
                displayExpr="name"
                labelMode="floating"
                value={props.initDataJob.jobStatusId}
                onValueChange={(e) => props.onValueChange("jobStatusId", e)}
                className="modalInput"
                items={SortObjectByPropName(props.initDataJobStatuses, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("materialCost")}
                labelMode="floating"
                value={props.initDataJob.materialCost}
                onValueChange={(e) => props.onValueChange("materialCost", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("labourCost")}
                labelMode="floating"
                value={props.initDataJob.labourCost}
                onValueChange={(e) => props.onValueChange("labourCost", e)}
                disabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("equipmentCost")}
                labelMode="floating"
                value={props.initDataJob.equipmentCost}
                onValueChange={(e) => props.onValueChange("equipmentCost", e)}
                disabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("otherCost")}
                labelMode="floating"
                value={props.initDataJob.otherCost}
                onValueChange={(e) => props.onValueChange("otherCost", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                style={{ marginBottom: 20 }}
                label={t("totalCost")}
                labelMode="floating"
                value={props.initDataJob.total}
                disabled={true}
                onValueChange={(e) => props.onValueChange("totalCost", e)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                style={{ marginBottom: 20 }}
                labelMode="floating"
                label={t("duration")}
                mask="00:00"
                //maskRules={}
                maskInvalidMessage="The input value does not match"
                value={props.durationString}
                onValueChange={(e) => props.onChangeFields("duration", e)}
                disabled={props.isLocked}
              ></TextBox>
            </SimpleItem>

            <SimpleItem colSpan={1}>
              <DateBox
                label={t("assignDate")}
                value={props.initDataJob.assignDate}
                type="date"
                onValueChange={(e) => props.onValueChange("assignDate", e)}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                disabled={props.isLocked}
                showClearButton={true}
                style={{ marginBottom: "1rem" }}
              ></DateBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("completionDate")}
                value={props.initDataJob.completionDate}
                type="date"
                onValueChange={(e) => props.onValueChange("completionDate", e)}
                id="completionDate"
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                disabled={props.isLocked}
                showClearButton={true}
                style={{ marginBottom: "1rem" }}
              ></DateBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TagBox
                items={SortObjectByPropName(props.initDataTags, "name")}
                searchEnabled={true}
                label={t("tags")}
                valueExpr="id"
                displayExpr="name"
                value={props.initDataJob.tags}
                onValueChange={(e: any) => props.onValueChange("tags", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                style={{ marginBottom: 20 }}
                label={t("accountNo")}
                labelMode="floating"
                onValueChange={(e) => props.onValueChange("accountNo", e)}
                value={props.initDataJob.accountNo}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextArea
                label={t("note")}
                labelMode="floating"
                height={50}
                value={props.initDataJob.note}
                onValueChange={(e) => props.onValueChange("note", e)}
                disabled={props.isLocked}
              />
            </SimpleItem>
          </Form>
        </div>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Details;
