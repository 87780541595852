import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useClientSetting } from "../../contexts/clientSetting";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
} from "../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../types/field/enums/fieldEnums";
import { TesField } from "../../types/field/fieldType";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import {
  INameId,
  LazyLoadingRequest,
  RequestResponseResult,
} from "../../types/general/generalTypes";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../utils/dateTimeTools";
import { EnumFlag2Array } from "../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../utils/rest";

interface IProps {
  label: string;
  value: string;
  name: string;
  onChangeFields: (name: string, value: any, isUpperCase: boolean) => void;
  isTextBox?: boolean;
  showEditButton?: boolean;
  isLocked: boolean;
}

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()}/api/streets/getStreets`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("defaultDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const StreetValuePicker = (props: IProps) => {
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [initDataFieldsValue, setInitDataFieldsValue] = useState<INameId[]>();
  const [name, setName] = useState("");
  const [showStreetPicker, setShowStreetPicker] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getStreetName(props.value);
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [FieldCategoryType.InfrastructureStreetGeneral],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }

  async function getStreetName(id: string) {
    if (id === undefined || id === null || id === "") return;
    const res = (await TesGet(
      InfrastructureApiUrl() + "/api/streets/GetStreetName/" + id,
      true
    )) as RequestResponseResult<INameId>;
    if (res.responseCode === ResponseCode.OK) {
      setName(res?.results?.name);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataFielddValue() {
    setInitDataFieldsValue(
      await TesGet(
        FieldApiUrl() +
          "/api/CodeValues/GetAllCustomerCodeValue/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function onRowClick(selectedRowData: any) {
    setShowStreetPicker(false);
    setName(selectedRowData.data?.Name);
    props.onChangeFields(props.name, selectedRowData.data?._id, false);
  }

  function filedValueId2Name(id: string) {
    if (initDataFieldsValue) {
      const index = initDataFieldsValue.findIndex((x) => x.id === id);
      if (index !== -1) {
        return initDataFieldsValue[index].name;
      } else {
        return id;
      }
    }
  }

  function listHeaderFilter(data: any) {
    data.dataSource.postProcess = (results: any) => {
      results.forEach((element: any) => {
        if (element.key !== null) element.text = filedValueId2Name(element.key);
      });
      return results;
    };
  }
  async function onOpenPopUp() {
    if (!props.isLocked) {
      setShowStreetPicker(true);
      await getInitialDataFields();
      await getInitialDataFielddValue();
    }
  }

  return (
    <React.Fragment>
      {props.isTextBox ? (
        <label onClick={() => onOpenPopUp()}>
          <TextBox
            // style={{ marginBottom: 20 }}
            label={props.label}
            labelMode="floating"
            value={name}
            disabled={props.isLocked}
          />
        </label>
      ) : (
        <div style={{ padding: "0 0.5rem" }}>
          <div>
            <label
              style={{
                paddingRight: "0.5rem",
                fontWeight: "700",
                fontSize: "15px",
              }}
            >
              {props.label}
            </label>
            {props.showEditButton && (
              <i
                className="fa-solid fa-pencil"
                onClick={() => onOpenPopUp()}
                style={{
                  color: "#F0B70D",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              ></i>
            )}
          </div>
          <label
            style={{ fontWeight: "500", marginTop: "0.3rem", display: "block" }}
          >
            {name}
          </label>
        </div>
      )}

      <Popup
        width={"60%"}
        visible={showStreetPicker}
        resizeEnabled={true}
        showTitle={true}
        title={t("streetPicker")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowStreetPicker(false)}
      >
        <div style={{ height: "100%" }}>
          {initDataFields && initDataFieldsValue && (
            <DataGrid
              ref={dataGridRef}
              dataSource={dataSource}
              style={{ height: "100%" }}
              rowAlternationEnabled={true}
              showBorders={true}
              selection={{ mode: "single" }}
              onRowClick={onRowClick}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />

              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>

              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="Name" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              {initDataFields.map((d) => {
                if (d.fieldType === FieldType.List) {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      allowFiltering={true}
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <Search enabled />
                      <Lookup
                        dataSource={SortObjectByPropName(d.fieldValues, "name")}
                        valueExpr="id"
                        displayExpr="name"
                      />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Number) {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="number"
                      alignment="left"
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Date) {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="date"
                      calculateCellValue={(e: any) =>
                        GridActualDateCalculator(e, d, generalSetting)
                      }
                      format={generalSetting?.dateFormat}
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Time) {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="datetime"
                      calculateCellValue={(e: any) =>
                        GridActualDateCalculator(e, d, generalSetting)
                      }
                      format={generalSetting?.timeFormat}
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.DateTime) {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="datetime"
                      calculateCellValue={(e: any) =>
                        GridActualDateCalculator(e, d, generalSetting)
                      }
                      format={generalSetting?.dateTimeFormat}
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Boolean) {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="boolean"
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      dataField={d.name}
                      caption={d.labelText}
                      allowHeaderFiltering={false}
                      visible={
                        EnumFlag2Array(
                          FieldLocation2Show,
                          d.fieldLocation2Show
                        ).some((x) => x === FieldLocation2Show.GridView)
                          ? true
                          : false
                      }
                    >
                      <Search enabled />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              })}
            </DataGrid>
          )}
        </div>
      </Popup>
    </React.Fragment>
  );
};
export default StreetValuePicker;
