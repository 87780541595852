import { ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../../components/permission/permision";
import { MaintenancePermissions } from "../../../../../constants/Permissions";
import { MaintenanceApiUrl } from "../../../../../environment/routeSettings";
import { Job } from "../../../../../types/maintenance/maintenanceTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { TesPut } from "../../../../../utils/rest";

// props
interface IPros {
  initDataMaintenance: Job[];
  addMaintenace: (lstMaintenaces: Job[]) => void;
  onDeleteMaintenace: (deletedMaintenance: Job) => void;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Maintenance = (props: IPros) => {
  const [showModalAddMaintenance, setShowModalAssignMaintenance] =
    useState(false);
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridRefAddMaintenance = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();

  useEffect(() => {
    async function fetchMyAPI() {}
    fetchMyAPI();
  });

  const dataSourceAssignMaintenance = new CustomStore({
    load: async (loadOption) => {
      return await TesPut(
        `${MaintenanceApiUrl()}/api/Jobs/LazyLoading/` +
          localStorage.getItem("selectedCustomerId"),
        JSON.stringify(loadOption),
        true
      );
    },
  });

  function onCloseModalAddMaintenance() {
    setShowModalAssignMaintenance(false);
  }

  // function onDeleteMaintenance(d: any) {
  //   let myDialog = custom({
  //     title: t("warning"),
  //     messageHtml: t("deleteWarningMessage"),
  //     buttons: [
  //       {
  //         text: t("yes"),
  //         onClick: async (e) => {
  //           try {
  //             props.onDeleteMaintenace(d);
  //             notify(t("dataSuccessfullyDeleted"), "success", 5000);
  //           } catch {
  //             notify(t("someErrorOccurred"), "error", 5000);
  //           }
  //           return { buttonText: e.component.option("text") };
  //         },
  //       },
  //       {
  //         text: t("no"),
  //         onClick: (e) => {
  //           return { buttonText: e.component.option("text") };
  //         },
  //       },
  //     ],
  //   });
  //   myDialog.show();
  // }

  function onAddMaintenance() {
    var lstMaintenaces = dataGridRefAddMaintenance.current
      ?.instance()
      .getSelectedRowKeys() as Job[];
    props.addMaintenace(lstMaintenaces);
    setShowModalAssignMaintenance(false);
    props.setDataChanged(true);
  }
  function navigateToMaintenancePage() {
    history("/maintenance/jobDetails/AddNew");
  }

  return (
    <React.Fragment>
      <ValidationGroup ref={props.validationRef}>
        <div className={"content-block"}>
          <div className={" responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  onClick={navigateToMaintenancePage}
                  icon="fa-solid fa-circle-plus"
                  text={t("add")}
                  disabled={props.isLocked}
                />
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={() => setShowModalAssignMaintenance(true)}
                  icon="fa-solid fa-link"
                  text={t("assign")}
                  disabled={props.isLocked}
                />
              </div>
            </div>
            {/* Maintenance datagrid */}
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initDataMaintenance}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />

              <Export enabled={true} allowExportSelectedData={true} />

              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="jobId" caption={t("jobId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="name" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="jobTypeName" caption={t("jobType")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column dataField="jobStatusName" caption={t("jobStatus")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              {/* <Column dataField="assignDate" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                            caption={t("assignDate")}>
                            <HeaderFilter>
                  <Search enabled/>
                </HeaderFilter>
                        </Column>

                        <Column dataField="completionDate" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                            caption={t("completionDate")}>
                            <HeaderFilter>
                  <Search enabled/>
                </HeaderFilter>
                        </Column> */}

              <Column
                alignment="left"
                dataField="duration"
                caption={t("duration")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="labourCost"
                caption={t("labourCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="materialCost"
                caption={t("materialCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="equipmentCost"
                caption={t("equipmentCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="total"
                caption={t("totalCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="note" caption={t("note")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              {/* {!this.props.isLocked && */}
              {/* <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
              <DevExpressButton
                hint={t("delete")}
                onClick={onDeleteMaintenance}
                icon="trafa-solid fa-trash-cansh"
              ></DevExpressButton>
            </Column> */}
              {/* } */}
            </DataGrid>
          </div>
        </div>

        {/* Assign MAintenance Modal */}
        <Popup
          width={"65%"}
          visible={showModalAddMaintenance}
          resizeEnabled={true}
          showTitle={true}
          title={t("assignMaintenance")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalAddMaintenance()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRefAddMaintenance}
              dataSource={dataSourceAssignMaintenance}
              rowAlternationEnabled={true}
              showBorders={true}
              style={{ height: "100%" }}
              keyExpr="id"
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRefAddMaintenance?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="name" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="jobTypeName" caption={t("jobType")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="jobStatusName" caption={t("jobStatus")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              {/* <Column dataField="assignDate" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                        caption={t("assignDate")}>
                        <HeaderFilter>
                  <Search enabled/>
                </HeaderFilter>
                     </Column>

                     <Column dataField="completionDate" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                        caption={t("completionDate")}>
                        <HeaderFilter>
                  <Search enabled/>
                </HeaderFilter>
                     </Column> */}
              <Column
                alignment="left"
                dataField="duration"
                visible={false}
                caption={t("duration")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="labourCost"
                visible={false}
                caption={t("labourCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="materialCost"
                visible={false}
                caption={t("materialCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="equipmentCost"
                visible={false}
                caption={t("equipmentCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="total"
                visible={false}
                caption={t("totalCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="note" visible={false} caption={t("note")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>

          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={onCloseModalAddMaintenance}
                text={t("cancel")}
              />
              <Permission
                allowed={[MaintenancePermissions.Maintenance_D_Job]}
                hasFeedBackElement={false}
              >
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAddMaintenance}
                  text={t("add")}
                />
              </Permission>
            </div>
          </div>
        </Popup>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Maintenance;
