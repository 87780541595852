//#region imports
import { ContextMenu } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/auth";
import { ReportApiUrl } from "../../../environment/routeSettings";
import {
  TClientReport,
  TesReportType,
} from "../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { TesDelete, TesGet } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./clientReports.scss";
import { custom } from "devextreme/ui/dialog";

//#endregion imports

const ClientReports = () => {
  //#region consts
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [initData, setInitData] = useState<TClientReport[]>([]);
  const [initDataReportType, setInitDataReportType] = useState<TesReportType[]>(
    [new TesReportType()]
  );
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    //   { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#endregion

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        await getInitDataReportTypes();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        ReportApiUrl() +
          "/api/ClientReports/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitDataReportTypes() {
    setInitDataReportType(
      await TesGet(ReportApiUrl() + "/api/TesReportTypes", true)
    );
  }

  function onNew() {
    history("/report/clientReportDetails/AddNew");
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(`/report/clientReportDetails/${e.data.id}`, "_blank");
      } else {
        history(`/report/clientReportDetails/${e.data.id}`);
      }
    }
  }

  async function onDeleteAll() {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              if (activeLoading) activeLoading(true);
              await TesDelete(
                ReportApiUrl() +
                  "/api/ClientReports/DeleteAllReportsOfClient/" +
                  localStorage.getItem("selectedCustomerId"),
                true
              );
              if (activeLoading) activeLoading(false);
              getInitData();
              dataGridRef.current?.instance().refresh();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  return (
    <div className={compactViewModel ? "compactStyle" : ""}>
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={(e) =>
            RightClickMenu(
              e,
              selectedRowData.row.data.id,
              "/report/clientReportDetails/"
            )
          }
        />
        <h2 className={"content-block"}>{t("clientReports")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  onClick={onDeleteAll}
                  text={t("deleteAllReports")}
                  style={{ backgroundColor: "#E14F57", marginRight: "0.5rem" }}
                />
                <Button
                  onClick={onNew}
                  icon="fa-solid fa-circle-plus"
                  text={t("Report")}
                />
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initData}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onRowClick={onRowClicked}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              onContextMenuPreparing={(e) => {
                setSelectedRowData(e);
              }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.Street.toString()} savingTimeout={500} /> */}
              <Summary>
                <TotalItem column="name" summaryType="count" />
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                dataField="id"
                alignment="left"
                visible={false}
                caption={t("id")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="name" alignment="left" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="tesReportTypeId"
                alignment="left"
                caption={t("tesReportType")}
              >
                <Lookup
                  dataSource={SortObjectByPropName(initDataReportType, "name")}
                  valueExpr="id"
                  displayExpr="name"
                />

                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
export default ClientReports;
