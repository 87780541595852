function MakeId(length: number) {
  var result = "";
  var lowercaseCharacters = "abcdefghijklmnopqrstuvwxyz";
  var uppercaseCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var charactersLength = lowercaseCharacters.length;
  var uppercaseIndex = Math.floor(Math.random() * length); // Index to place the uppercase character

  for (var i = 0; i < length; i++) {
    if (i === uppercaseIndex) {
      result += uppercaseCharacters.charAt(
        Math.floor(Math.random() * uppercaseCharacters.length)
      );
    } else {
      result += lowercaseCharacters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
  }

  return result;
}

function MakeInt(length: number) {
  var result = "";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function MakeSpecialChar(length: number) {
  var result = "";
  var characters = "#$%&!";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function UpperCaseFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function LowerCaseFirstLetter(string: string) {
  return string.charAt(0).toLocaleLowerCase() + string.slice(1);
}

function IsNullOrEmpty(txt: string | null | undefined): boolean {
  if (
    txt === null ||
    txt === undefined ||
    txt == "" ||
    txt === "00000000-0000-0000-0000-000000000000"
  ) {
    return true;
  } else {
    return false;
  }
}

export {
  MakeId,
  MakeInt,
  MakeSpecialChar,
  UpperCaseFirstLetter,
  LowerCaseFirstLetter,
  IsNullOrEmpty,
};
