import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Margin,
  Point,
  Series,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  HeaderFilter,
  Search,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import {
  SignalWarrantAnalysisDetails,
  SummayGrid,
} from "../../../../../types/systematicScreening/systematicScreeningTypes";

// Props
interface IProps {
  initData: SignalWarrantAnalysisDetails;
  chartInitData: SignalWarrantAnalysisDetails;
  generalSetting: GeneralSetting | null;
}

const Details: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  function customizeArgumentAxisLabels({ value }: { value: any }) {
    return value;
  }

  function customizeTooltip({
    argument,
    seriesName,
    value,
  }: {
    argument: any;
    seriesName: any;
    value: any;
  }) {
    return {
      text: `${value}`,
    };
  }

  const griData: SummayGrid[] = [
    {
      interval: t("latestStudy"),
      summary: props.initData?.analysisResult?.latestJustificationSummary,
      year: props.initData?.analysisResult?.latestStudyYear,
      isJustified: props.initData?.analysisResult?.latestIsJustified,
    },
    {
      interval: t("currentYear"),
      summary: props.initData?.analysisResult?.currentYearJustificationSummary,
      year: props.initData?.analysisResult?.currentYear,
      isJustified: props.initData?.analysisResult?.currentYearIsJustified,
    },
    {
      interval: t("prediction"),
      summary: props.initData?.analysisResult?.predictionJustificationSummary,
      year: props.initData?.analysisResult?.predictionYear,
      isJustified:
        props.initData?.analysisResult?.signalWarrantPredictionRes?.isJustified,
    },
  ];

  return (
    <div
      className={`signDetails-details ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <Form colCount={6} style={{ marginTop: "1rem" }}>
          <SimpleItem colSpan={6}>
            <DataGrid
              ref={dataGridRef}
              dataSource={griData}
              style={{ height: "100%", margin: "0 2rem" }}
              rowAlternationEnabled={true}
              showBorders={true}
              selection={{ mode: "single" }}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
            >
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="interval"
                caption={t("interval")}
              ></Column>
              <Column
                alignment="left"
                dataField="summary"
                caption={t("summary")}
              ></Column>
              <Column
                alignment="left"
                dataField="year"
                caption={t("year")}
              ></Column>
              <Column
                alignment="center"
                dataField="isJustified"
                caption={t("isJustified")}
              ></Column>
            </DataGrid>
          </SimpleItem>
          <SimpleItem colSpan={6}>
            <div style={{ marginTop: "-1rem" }}>
              <Chart id="chart" dataSource={props.initData.yearVolumes}>
                <Margin top={0} bottom={10} left={20} right={20} />
                <CommonSeriesSettings argumentField="year" type="line" />
                <Series
                  valueField="totalVolume"
                  name={t("total")}
                  color="#F0B70D"
                  axis="leftAxis"
                >
                  <Point visible={true} symbol="circle" size={12} />
                </Series>
                <ValueAxis
                  name="leftAxis"
                  position="left"
                  showZero={true}
                  valueMarginsEnabled={false}
                  allowDecimals={false}
                >
                  <Title text={t("total_Volume")} />
                </ValueAxis>
                <ArgumentAxis tickInterval={1}>
                  <Label customizeText={customizeArgumentAxisLabels} />
                </ArgumentAxis>
                <Legend position="inside" horizontalAlignment="right" />
                <Export enabled={true} printingEnabled={false} />
                <Tooltip enabled={true} location="edge" />
                <Tooltip
                  enabled={true}
                  location="edge"
                  customizeTooltip={customizeTooltip}
                />
              </Chart>
            </div>
          </SimpleItem>
          {props.initData?.analysisResult?.notPredicted === false && (
            <SimpleItem colSpan={6}>
              <div style={{ marginTop: "-1rem" }}>
                <Chart
                  id="chart"
                  dataSource={props.initData?.signalWarrantPercentages}
                >
                  <Margin top={0} bottom={10} left={20} right={20} />
                  <CommonSeriesSettings argumentField="year" type="line" />
                  <Series
                    valueField="justification1Percentage"
                    name={t("justification1")}
                    color="#F0B70D"
                    axis="leftAxis"
                  >
                    <Point visible={true} symbol="circle" size={12} />
                  </Series>
                  <Series
                    valueField="justification2Percentage"
                    name={t("justification2")}
                    color="#0b1d78"
                    axis="leftAxis"
                  >
                    <Point visible={true} symbol="circle" size={12} />
                  </Series>
                  <Series
                    valueField="justification4Percentage"
                    name={t("justification4")}
                    color="#ab006f"
                    axis="leftAxis"
                  >
                    <Point visible={true} symbol="circle" size={12} />
                  </Series>
                  <ValueAxis
                    name="leftAxis"
                    position="left"
                    showZero={true}
                    valueMarginsEnabled={false}
                    allowDecimals={false}
                  >
                    <Title text={t("justification%")} />
                  </ValueAxis>
                  <ArgumentAxis tickInterval={1}>
                    <Label customizeText={customizeArgumentAxisLabels} />
                  </ArgumentAxis>
                  <Legend position="inside" horizontalAlignment="right" />
                  <Export enabled={true} printingEnabled={false} />
                  <Tooltip enabled={true} location="edge" />
                  <Tooltip
                    enabled={true}
                    location="edge"
                    customizeTooltip={customizeTooltip}
                  />
                </Chart>
              </div>
            </SimpleItem>
          )}
        </Form>
      </React.Fragment>
    </div>
  );
};

export default Details;
