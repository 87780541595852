import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import ShareURL from "../../../../components/shareURL/shareURL";
import SafetyPermissions from "../../../../constants/Permissions/SafetyPermissions";
import { useAuth } from "../../../../contexts/auth";
import { InfrastructureApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { Location } from "../../../../types/infrastructure/infrastructureTypes";
import { SiteSubTypeDTO } from "../../../../types/safety/dto/safetyDtos";
import {
  ISiteSubTypeDetailsParam,
  SiteSubType,
} from "../../../../types/safety/safetyTypes";
import { RequestErrorHandling, TesGet, TesPut } from "../../../../utils/rest";
import General from "./components/general/general";
import Locations from "./components/locations/locations";
import tabTitles from "./data/index";
import "./siteSubTypeDetails.scss";
import TesTabs from "../../../../components/tesTab/tesTab";

const SiteSubTypeDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initData, setInitData] = useState<SiteSubTypeDTO>(
    new SiteSubTypeDTO()
  );
  const params = useParams<ISiteSubTypeDetailsParam>();
  // const [backPermission, setBackPermission] = useState(false);
  const generalValidationRef = useRef<ValidationGroupRef>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);
  const [intersections, setIntersections] = useState<Location[]>([]);
  const [roadSegments, setRoadSegments] = useState<Location[]>([]);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (params.subTypeId !== "AddNew") {
          await getInitData(params.subTypeId!);
        } else {
          setIsLocked(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.subTypeId]);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitData(id: string) {
    try {
      let res = (await TesGet(
        InfrastructureApiUrl() + "/api/SiteTypes/GetSiteSubTypeDetails/" + id,
        true
      )) as RequestResponseResult<SiteSubTypeDTO>;
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000);
        history(-1);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      notify(t("errorInFetchData") + ex, "error", 5000);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.AADTPatternId !== "AddNew") {
                  update(true);
                  setDataChanged(false);
                } else {
                  add();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              // setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function add() {
    try {
      // const validationRes = generalValidationRef.current?.instance().validate();
      //if (validationRes?.isValid) {

      let postObj: SiteSubType = {
        description: initData.description,
        fatalInjurySpfFunctionId: initData.fatalInjurySpfFunctionId,
        pdoSpfFunctionId: initData.pdoSpfFunctionId,
        serverityType: initData.serverityType,
        type: initData.type,
        locations: initData.locations.map((x) => {
          var loc = new Location();
          loc.id = x.id as string;
          return loc;
        }),
        customerId: localStorage.getItem("selectedCustomerId"),
      };
      if (activeLoading) activeLoading(true);
      let res = (await TesPut(
        InfrastructureApiUrl() +
          "/api/SiteTypes/AddSiteSubType/" +
          params.siteTypeId,
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (res.responseCode === ResponseCode.OK) {
        history(-1);
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
      //}
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function update(backPermission: boolean) {
    try {
      const postObj: SiteSubType = {
        ...initData,
        id: params.subTypeId as string,
        locations: initData.locations.map((x) => {
          var loc = new Location();
          loc.id = x.id as string;
          return loc;
        }),
        customerId: localStorage.getItem("selectedCustomerId"),
      };
      if (activeLoading) activeLoading(true);
      let res = (await TesPut(
        InfrastructureApiUrl() +
          "/api/SiteTypes/UpdateSiteSubType/" +
          params.siteTypeId,
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (res.responseCode === ResponseCode.OK) {
        if (backPermission === true) {
          history(-1);
        }
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  function updateIntersection(lstIntersections: Location[] | undefined) {
    if (lstIntersections !== undefined) {
      setIntersections(lstIntersections);
    }
  }

  function updateRoadSegment(lstRoadSegments: Location[] | undefined) {
    if (lstRoadSegments !== undefined) {
      setRoadSegments(lstRoadSegments);
    }
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.subTypeId !== "AddNew" && (
              <div>
                <p className={"detailsHeading"}>
                  {t("youAreNowWorkingOn")} {t("siteSubType")}
                  <span> {initData.description}</span>.
                </p>
                <hr className="line"></hr>
              </div>
            )}
            <div className="row">
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.siteTypeId !== "AddNew" && <ShareURL url={url} />}
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <div className="rightColumn">
                {params.subTypeId === "AddNew" && (
                  <Button
                    onClick={add}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("save")}
                  />
                )}
                {params.subTypeId !== "AddNew" && isLocked === false && (
                  <Button
                    onClick={() => update(false)}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("update")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={"dx-card"}>
            <TesTabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onTabChange={handleTabChange}
            />
            {selectedIndex === 0 && (
              <General
                initData={initData}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={generalValidationRef}
              />
            )}
            {selectedIndex === 1 && (
              <Locations
                initData={initData}
                setInitData={setInitData}
                areaId={params.areaId!}
                updateIntersection={updateIntersection}
                updateRoadSegment={updateRoadSegment}
                intersections={intersections !== undefined ? intersections : []}
                roadSegments={roadSegments !== undefined ? roadSegments : []}
                setDataChanged={setDataChanged}
                isLocked={isLocked}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default SiteSubTypeDetails;
