import i18n from "../../../../../../../react-i18next";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: "diagram",
    content: "diagram",
  },
  {
    id: 1,
    text: "details",
    content: "detail",
  },
];

export default tabTitles;
