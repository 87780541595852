import { VMField } from "../../field/dto/fieldDTO";
import { TabType } from "../enums/moduleOfModulesEnums";

export class DataDetailsDTO {
  detailsData?: any;
  listData?: any[];
  tabs?: ModuleTabDTO[];
}

export class ModuleTabDTO {
  id?: string;
  name?: string;
  defaultTab?: boolean;
  type?: TabType;
  order?: number;
  fields?: VMField[];
}
