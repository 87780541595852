import { Button } from "devextreme-react";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import Permission from "../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../constants/Permissions/SafetyPermissions";
import { OnExporting } from "../../../../../utils/dataGridTools";
import DiagnosisPopup from "../diagnosisPopup/dignosisPopup";

interface IProps {
  isLocked: boolean;
}

function Diagnosis(props: IProps) {
  const [initData, setInitData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  useEffect(() => {
    setInitData([
      { diagnosis: "diagnosis1" },
      { diagnosis: "diagnosis2" },
      { diagnosis: "diagnosis3" },
      { diagnosis: "diagnosis4" },
    ]);
  }, []);

  function onNew() {
    setShowModal(true);
  }

  async function onDelete(d: any) {
    setInitData(initData.filter((x) => x.diagnosis !== d.row.data.diagnosis));
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("diagnosis")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="row">
            <div className="rightColumn">
              <Permission
                allowed={[
                  SafetyPermissions.Safety_V_Workbook,
                  SafetyPermissions.Safety_D_Workbook,
                ]}
                hasFeedBackElement={false}
              >
                <Button
                  onClick={onNew}
                  icon="fa-solid fa-link"
                  text={t("diagnosis")}
                  disabled={props.isLocked}
                />
              </Permission>
            </div>
          </div>
          <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={initData}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={OnExporting}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} /> {/* or "auto" */}
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={"top"} />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <Column
              alignment="left"
              dataField="id"
              allowSorting={true}
              caption={t("id")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="diagnosis"
              allowSorting={false}
              caption={t("diagnosis")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              type="buttons"
              caption={t("actions")}
              width={110}
              visible={!props.isLocked}
              fixed={true}
              fixedPosition="right"
            >
              <DevExpressButton
                hint={t("delete")}
                onClick={onDelete}
                icon="fa-solid fa-trash-can"
              ></DevExpressButton>
            </Column>
          </DataGrid>
        </div>
      </div>
      <DiagnosisPopup showModal={showModal} setShowModal={setShowModal} />
    </React.Fragment>
    // </Permission>
  );
}

export default Diagnosis;
