import React, { useEffect } from "react";
import "./home.scss";
import { useClientSetting } from "../../contexts/clientSetting";
import Permission from "../../components/permission/permision";
import { CustomerPermissions } from "../../constants/Permissions";
import { useAuth } from "../../contexts/auth";
import useBrowserTitle from "../../hooks/browserTitle/useBrowserTitle";

let selectedCustomerId = localStorage.getItem("selectedCustomerId")!;
const Home = () => {
  const { generalSetting, getClientGeneralSetting } = useClientSetting();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedClineNameId, setClientNameId } = useAuth();
  if (selectedCustomerId === null) {
    window.location.reload();
  }
  selectedCustomerId = localStorage.getItem("selectedCustomerId")!;

  useBrowserTitle({ pathname: "/home" });

  useEffect(() => {
    (async function () {
      if (getClientGeneralSetting) {
        await getClientGeneralSetting(
          localStorage.getItem("selectedCustomerId")
        );
      }
    })();
  }, [getClientGeneralSetting, setClientNameId]);

  useEffect(() => {
    (async function () {
      if (setClientNameId)
        setClientNameId(
          localStorage.getItem("selectedCustomerId")!,
          localStorage.getItem("selectedCustomerName")!
        );
    })();
  }, [setClientNameId]);

  return (
    <Permission
      allowed={[CustomerPermissions.Customer_V_Web]}
      hasFeedBackElement
    >
      <React.Fragment>
        <div
          className={" homeImage"}
          style={{
            backgroundImage:
              "url(" +
              (generalSetting && generalSetting?.webHomeImage
                ? generalSetting?.webHomeImage
                : "http://staticfile.tes.ca/tes/home.jpg") +
              ")",
          }}
        />
      </React.Fragment>
    </Permission>
  );
};

export default Home;
