import React, { useEffect, useRef, useState } from "react";

import design from "./profile.module.scss";
import { t } from "i18next";
import { Tabs } from "devextreme-react";
import tabTitles from "./data";
import MyProfile from "./components/myProfile/myProfile";
import Password from "./components/password/password";
import { useAuth } from "../../contexts/auth";
import { VMUserProfileDetails } from "../../types/identity/dto/identityDTO";
import notify from "devextreme/ui/notify";
import { AuthApiUrl } from "../../environment/routeSettings";
import { TesGet } from "../../utils/rest";
import TesTabs from "../../components/tesTab/tesTab";

const TesProfile = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initData, setInitData] = useState<VMUserProfileDetails>(
    new VMUserProfileDetails()
  );
  const { activeLoading, user } = useAuth();
  const didMount = useRef(false);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
      return (didMount.current = true);
    }
    fetchMyAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialData() {
    setInitData(
      await TesGet(AuthApiUrl() + "/api/user/GetUserProfile/" + user?.id, true)
    );
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("profile")}</h2>
      <div className={"content-block dx-card"}>
        <div className={`${design.banner}`}>
          <div className={`${design.pic} ${design.icon}`}>
            <i className="fa-solid fa-user"></i>
          </div>
          <div className={design.info}>
            <div className={`${design.user}`}>{user?.userName}</div>
            <div className={`${design.email}`}>{user?.email}</div>
          </div>
        </div>
      </div>

      <div className={"dx-card content-block"}>
        <TesTabs
          width={"100%"}
          dataSource={tabTitles}
          selectedIndex={selectedIndex}
          onTabChange={handleTabChange}
        />

        {selectedIndex === 0 && (
          <MyProfile initData={initData} setInitData={setInitData} />
        )}

        {selectedIndex === 1 && (
          <Password initData={initData} setInitData={setInitData} />
        )}
      </div>
    </React.Fragment>
  );
};

export default TesProfile;
