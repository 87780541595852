//#region imports
import { ScrollView, SelectBox, Switch, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useAuth } from "../../../../../../contexts/auth";
import {
  FieldCategoryType,
  FieldType,
} from "../../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../../types/field/fieldType";
import { ModalMode } from "../../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import {
  CastType,
  LocationType,
  MatchByType,
} from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import {
  ColumnMap,
  FieldDataSource,
} from "../../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";

//#endregion imports

// props
interface IPros {
  initDataFieldDataSource: FieldDataSource;
  setInitDataFieldDataSource: React.Dispatch<
    React.SetStateAction<FieldDataSource>
  >;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  fieldIdData: TesField[];
  validationRef: React.RefObject<ValidationGroupRef>;
}

const ColumnMaps = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<ColumnMap>(new ColumnMap());
  const [lstCastType, setLstCastType] = useState<NameValue[]>([]);
  const [lstMatchByType, setLstMatchByType] = useState<NameValue[]>([]);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  const lstConstFields = [
    { name: "Description", value: "Description" },
    { name: "GeoId", value: "GeoId" },
    { name: "StreetId", value: "StreetId" },
    { name: "Limit1IntersectionId", value: "Limit1IntersectionId" },
    { name: "Limit2IntersectionId", value: "Limit2IntersectionId" },
    { name: "Limit1StreetId", value: "Limit1StreetId" },
    { name: "Limit2StreetId", value: "Limit2StreetId" },
    { name: "Latitude", value: "Latitude" },
    { name: "Longitude", value: "Longitude" },
    { name: "XCoord", value: "XCoord" },
    { name: "YCoord", value: "YCoord" },
    { name: "Street1Id", value: "Street1Id" },
    { name: "Street2Id", value: "Street2Id" },
    { name: "Street3Id", value: "Street3Id" },
    { name: "Street4Id", value: "Street4Id" },
    {
      name: "IntersectionNorthApproachId",
      value: "IntersectionNorthApproachId",
    },
    {
      name: "IntersectionSouthApproachId",
      value: "IntersectionSouthApproachId",
    },
    { name: "IntersectionWestApproachId", value: "IntersectionWestApproachId" },
    { name: "IntersectionEastApproachId", value: "IntersectionEastApproachId" },
    { name: "Street Name", value: "Name" },
  ];
  const lstLookupConstantField = [
    { name: "StreetId", value: "StreetId" },
    { name: "Limit1IntersectionId", value: "Limit1IntersectionId" },
    { name: "Limit2IntersectionId", value: "Limit2IntersectionId" },
    { name: "Limit1StreetId", value: "Limit1StreetId" },
    { name: "Limit2StreetId", value: "Limit2StreetId" },
    { name: "Latitude", value: "Latitude" },
    { name: "Longitude", value: "Longitude" },
    { name: "XCoord", value: "XCoord" },
    { name: "YCoord", value: "YCoord" },
    { name: "Street1Id", value: "Street1Id" },
    { name: "Street2Id", value: "Street2Id" },
    { name: "Street3Id", value: "Street3Id" },
    { name: "Street4Id", value: "Street4Id" },
    {
      name: "IntersectionNorthApproachId",
      value: "IntersectionNorthApproachId",
    },
    {
      name: "IntersectionSouthApproachId",
      value: "IntersectionSouthApproachId",
    },
    { name: "IntersectionWestApproachId", value: "IntersectionWestApproachId" },
    { name: "IntersectionEastApproachId", value: "IntersectionEastApproachId" },
  ];
  const lstDateFormat = [
    { name: "date", value: "date" },
    { name: "time", value: "time" },
    { name: "dateTime", value: "dateTime" },
  ];
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [totalCountValue, setTotalCountValue] = useState<number>(-1);

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstCastType(Enum2Array(CastType));
        setLstMatchByType(Enum2Array(MatchByType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onNew() {
    setSelectedData(new ColumnMap());
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    setShowModal(false);
  }

  function onCancel() {
    props.setDataChanged(false);
    setShowModal(false);
  }

  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value });
    props.setDataChanged(true);
  }

  function onAdd() {
    props.setInitDataFieldDataSource({
      ...props.initDataFieldDataSource,
      lstColumnMap: [
        ...props.initDataFieldDataSource.lstColumnMap,
        { ...selectedData, id: uuid() },
      ],
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setSelectedData(d.row.data);
    setShowModal(true);
  }

  function onDelete(d: any) {
    console.log(props.initDataFieldDataSource.lstColumnMap);
    console.log(d.row.data.clientFieldName);
    props.setInitDataFieldDataSource({
      ...props.initDataFieldDataSource,
      lstColumnMap: [
        ...props.initDataFieldDataSource.lstColumnMap.filter(
          (x) => x.id !== d.row.data.id
        ),
      ],
    });
  }

  function onUpdate() {
    if (
      props.initDataFieldDataSource.lstColumnMap.some((x) => x.isForeignKey) &&
      selectedData.isForeignKey === true
    ) {
      notify(t("maxNumberExceeded"), "error", 5000);
      return;
    }
    const columnMap = props.initDataFieldDataSource?.lstColumnMap;
    const selectedDataIndex: number = columnMap.findIndex(
      (x: ColumnMap) => x.id === selectedData.id
    );
    columnMap[selectedDataIndex] = selectedData;
    props.setInitDataFieldDataSource({
      ...props.initDataFieldDataSource,
      lstColumnMap: columnMap,
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  //#endregion functions
  return (
    <div
      className={`fieldDataSourceDetails-columnMap ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <div className="row popupFields" style={{ marginTop: 20, padding: 10 }}>
          <div className="rightColumn">
            <Button
              onClick={onNew}
              icon="fa-solid fa-circle-plus"
              text={t("columnMap")}
              disabled={props.isLocked}
            />
          </div>
        </div>
        <DataGrid
          id="columnMapGridContainer"
          ref={dataGridRef}
          dataSource={props.initDataFieldDataSource.lstColumnMap}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          style={{ margin: "0 1rem 1rem" }}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />

          <HeaderFilter visible={true} />

          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
          <Summary>
            <GroupItem summaryType="count" alignByColumn name="Total Count" />
          </Summary>
          <Column dataField="id" caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="clientFieldName"
            caption={t("clientFieldName")}
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="clientFieldIndex"
            caption={t("clientFieldIndex")}
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="fieldId" caption={t("field")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(props.fieldIdData, "name")}
              valueExpr="id"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="constantField"
            caption={t("constantField")}
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(lstConstFields, "name")}
              valueExpr="value"
              displayExpr="name"
            />
          </Column>
          <Column dataField="castType" caption={t("castType")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(lstCastType, "name")}
              valueExpr="value"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="isForeignKey"
            caption={t("isForeignKey")}
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            type="buttons"
            caption={t("actions")}
            width={110}
            fixed={true}
            fixedPosition="right"
          >
            <DevExpressButton
              hint={t("edit")}
              onClick={onEdit}
              icon="fa-solid fa-pencil"
            ></DevExpressButton>
            <DevExpressButton
              hint={t("delete")}
              onClick={onDelete}
              icon="fa-solid fa-trash-can"
            ></DevExpressButton>
          </Column>
        </DataGrid>

        {/* Popup for adding/editing */}
        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <ValidationGroup ref={props.validationRef}>
              <Form colCount={2} className="popupFields">
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("clientFieldName")}
                    labelMode="floating"
                    value={selectedData.clientFieldName}
                    onValueChange={(e) => onValueChange("clientFieldName", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </TextBox>
                </SimpleItem>

                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("field")}
                    labelMode="floating"
                    value={selectedData.fieldId}
                    onValueChange={(e) => onValueChange("fieldId", e)}
                    items={SortObjectByPropName(
                      props.fieldIdData.filter(
                        (x) =>
                          (props.initDataFieldDataSource.infrastructureType ===
                            LocationType.Intersection &&
                            (x.fieldCategoryType ===
                              FieldCategoryType.InfrastructureIntersectionApproachDetails ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureIntersectionDetails ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureIntersectionGeneral ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureIntersectionOperation ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureIntersectionRoot)) ||
                          (props.initDataFieldDataSource.infrastructureType ===
                            LocationType.Midblock &&
                            (x.fieldCategoryType ===
                              FieldCategoryType.InfrastructureMidBlockCrossSection ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureMidBlockDetails ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureMidBlockGeneral ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureMidBlockOperation ||
                              x.fieldCategoryType ===
                                FieldCategoryType.InfrastructureMidBlockRoot)) ||
                          (props.initDataFieldDataSource.infrastructureType ===
                            LocationType.Street &&
                            x.fieldCategoryType ===
                              FieldCategoryType.InfrastructureStreetGeneral)
                      ),
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                    showClearButton={true}
                    searchEnabled={true}
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </SelectBox>
                </SimpleItem>
                {props.fieldIdData.find((x) => x.id === selectedData.fieldId)
                  ?.fieldType === FieldType.Number && (
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("castType")}
                      labelMode="floating"
                      value={selectedData.castType}
                      onValueChange={(e) => onValueChange("castType", e)}
                      items={SortObjectByPropName(lstCastType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>
                )}
                {props.fieldIdData.find((x) => x.id === selectedData.fieldId)
                  ?.fieldType === FieldType.Date && (
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("dateFormat")}
                      labelMode="floating"
                      value={selectedData.dateTimeFormat}
                      onValueChange={(e) => onValueChange("dateTimeFormat", e)}
                      items={SortObjectByPropName(lstDateFormat, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>
                )}

                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("constantField")}
                    labelMode="floating"
                    value={selectedData.constantField}
                    onValueChange={(e) => onValueChange("constantField", e)}
                    items={SortObjectByPropName(lstConstFields, "name")}
                    valueExpr="value"
                    displayExpr="name"
                    showClearButton={true}
                    searchEnabled={true}
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </SelectBox>
                </SimpleItem>
                {lstLookupConstantField.some(
                  (x) => x.name === selectedData.constantField
                ) && (
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("matchByType")}
                      labelMode="floating"
                      value={selectedData.matchByType}
                      onValueChange={(e) => onValueChange("matchByType", e)}
                      items={SortObjectByPropName(lstMatchByType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>
                )}
                <SimpleItem colSpan={1}>
                  <div>{t("isForeignKey")}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: "bold" }}
                    value={selectedData.isForeignKey}
                    onValueChange={(e) => onValueChange("isForeignKey", e)}
                    hint={t("defaultTab")}
                  />
                </SimpleItem>
              </Form>
            </ValidationGroup>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20, marginTop: "10px" }}
                  onClick={() => onCancel()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    style={{ marginTop: "10px" }}
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    style={{ marginTop: "10px" }}
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </div>
  );
};
export default ColumnMaps;
