import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { AuthApiUrl } from "../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import {
  INameId,
  RequestResponseResult,
} from "../../../../../types/general/generalTypes";
import {
  VMAssignUser2Groups,
  VMUserProfileDetails,
} from "../../../../../types/identity/dto/identityDTO";
import { OnExporting } from "../../../../../utils/dataGridTools";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../../utils/rest";

// props
interface IPros {
  initData: VMUserProfileDetails;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const Roles = (props: IPros) => {
  const [showModal, setShowModal] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridModalRef = useRef<DataGridRef<any, any>>(null);
  const [initDataModal, setInitDataModal] = useState<INameId[]>();
  const { activeLoading } = useAuth();

  //modal functions
  function onCloseModal() {
    setShowModal(false);
  }
  //modal mode functions
  async function onNew() {
    var getRes = (await TesGet(
      AuthApiUrl() + "/api/groups/" + props.initData.defaultCustomerId,
      true
    )) as INameId[];
    setInitDataModal(
      getRes.filter(
        (x) => !props.initData.groups.map((a) => a.id).includes(x.id)
      )
    );
    dataGridModalRef.current?.instance().deselectAll();
    setShowModal(true);
  }

  async function onAdd() {
    try {
      var selectedData = initDataModal?.filter((x) =>
        dataGridModalRef.current?.instance().getSelectedRowKeys().includes(x.id)
      );
      if (props.initData !== undefined && selectedData !== undefined) {
        props.setInitData({
          ...props.initData,
          groups: [...props.initData.groups, ...selectedData],
        });
      }
      onCloseModal();
      dataGridRef.current?.instance().refresh();
    } catch (ex) {
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  //delete functions
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitData({
                ...props.initData,
                groups: props.initData.groups.filter(
                  (x) => x.id !== d.row.data.id
                ),
              });
              dataGridRef.current?.instance().refresh();
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function onSave() {
    try {
      if (activeLoading) activeLoading(true);

      const postObj: VMAssignUser2Groups = {
        userId: props.initData.id,
        groupIds: props.initData.groups.map((x) => x.id),
      };
      const res = (await TesPost(
        AuthApiUrl() + "/api/Groups/AssignUser2Groups",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (res.responseCode === ResponseCode.OK) {
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.UserNotPermitted) {
        notify(t("userNotPermitted"), "error", 5000);
      } else if (res.responseCode === ResponseCode.NotPermitted) {
        notify(t("notPermitted"), "error", 5000);
      } else if (res.responseCode === ResponseCode.UpdatingError) {
        notify(t("deletingError"), "error", 5000);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <div className="row">
          <div className="rightColumn">
            <Button
              text={t("add")}
              onClick={onNew}
              icon="fa-solid fa-circle-plus"
            />
          </div>
        </div>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={props.initData.groups}
          rowAlternationEnabled={true}
          showBorders={true}
          style={{ height: "100%", margin: "0 1rem" }}
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
          //onContentReady={e => {e.component.deselectAll()}}
          //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Grouping contextMenuEnabled={true} autoExpandAll={false} />
          <GroupPanel visible={true} /> {/* or "auto" */}
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />
          <FilterRow visible={true} applyFilter="auto" />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <Column dataField="id" caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="name" caption={t("name")} defaultSortOrder="asc">
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            type="buttons"
            caption={t("actions")}
            width={110}
            fixed={true}
            fixedPosition="right"
          >
            <DevExpressButton
              hint={t("delete")}
              onClick={onDelete}
              icon="fa-solid fa-trash-can"
            ></DevExpressButton>
          </Column>
        </DataGrid>

        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
      <Popup
        width={"65%"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("add")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <div style={{ height: "90%" }}>
          <DataGrid
            id="gridContainer"
            ref={dataGridModalRef}
            dataSource={initDataModal}
            rowAlternationEnabled={true}
            showBorders={true}
            style={{ height: "100%" }}
            keyExpr="id"
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridModalRef?.current?.instance().deselectAll()}}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} /> {/* or "auto" */}
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50, 100]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"always"}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <Column dataField="id" caption={t("id")} visible={false}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="name" caption={t("name")} defaultSortOrder="asc">
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {/* <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                                <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column> */}
          </DataGrid>
        </div>

        <div style={{ marginTop: 20 }}>
          <div className="rightColumn">
            <Button
              className="tes-modal-btn-cancel"
              style={{ marginRight: 20 }}
              onClick={() => onCloseModal()}
              text={t("cancel")}
            />
            <Button
              className="tes-modal-btn-add"
              onClick={onAdd}
              text={t("add")}
            />
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};
export default Roles;
