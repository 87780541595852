import { Button, DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import {
  AuthApiUrl,
  TranslationApiUrl,
} from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { INameId } from "../../../types/general/generalTypes";
import {
  LanguageDetailsDto,
  LanguageDetailsDtoListRequestResponseResult,
} from "../../../types/translation/dtos/translationDtos";
import { LanguageType } from "../../../types/translation/enums/translationEnums";
import { TesGet } from "../../../utils/rest";

const CustomerLanguages = () => {
  const { activeLoading } = useAuth();
  const [initDataBaseLangs, setInitDataBaseLangs] = useState<
    LanguageDetailsDto[]
  >([]);
  const [initDataClient, setInitDataClient] = useState<INameId[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        await getInitDataClient();
        // setLstLanguageType(Enum2Array(LanguageType))
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TranslationApiUrl() +
          "/api/Languages/GetAllBaseLanguages" +
          `?languageType=${LanguageType.Customized}`,
        true
      )) as LanguageDetailsDtoListRequestResponseResult;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitDataBaseLangs(res.results);
      } else {
        //await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  async function getInitDataClient() {
    const res = await TesGet(
      AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser",
      true
    );
    setInitDataClient(res);
  }

  const navigateToDetails = useCallback(() => {
    history("/translation/customerLanguageDetails/AddNew");
  }, [history]);

  const onRowClick = (d: any) => {
    history(`/translation/customerLanguageDetails/${d.data.id}`);
  };

  return (
    <Permission
      allowed={[TMSPermissions.TMS_D_Translation]}
      hasFeedBackElement={true}
    >
      {/* <div className={compactViewModel ? "compactStyle" : ""}> */}
      <React.Fragment>
        <h2 className={"content-block"}>{t("customerLanguages")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  onClick={navigateToDetails}
                  icon="fa-solid fa-circle-plus"
                  text={t("customerLanguage")}
                />
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initDataBaseLangs}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={(e) => onRowClick(e)}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              // onExporting={OnExporting}
              // onContextMenuPreparing={e => { setSelectedRowData(e) }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column dataField="languageName" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="baseLanguageId"
                caption={t("baseLanguageId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="languageCode" caption={t("languageCode")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="parentLanguageName" caption={t("parentGroup")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="imageAddress" caption={t("imageUrl")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="description" caption={t("description")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="isDefault" caption={t("isDefault")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="customerId" caption={t("customer")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={initDataClient}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
      {/* </div> */}
    </Permission>
  );
};

export default CustomerLanguages;
