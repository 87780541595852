//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import { TMSPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { CollisionApiUrl } from "../../../../environment/routeSettings";
import { XMLRedaction } from "../../../../types/collision/collisionTypes";
import {
  GridType,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { OnExporting } from "../../../../utils/dataGridTools";
import { RequestErrorHandling, TesGet } from "../../../../utils/rest";
import "./xmlReduction.scss";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const XMLRedactions = () => {
  const [initData, setInitData] = useState<XMLRedaction[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  useBrowserTitle({ pathname: "/collision/settings/xmlRedaction" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    const res = (await TesGet(
      CollisionApiUrl() +
        "/api/XmlFileManager/GetReductionFieldsByCustomerId/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<XMLRedaction[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const navigateXmlRedactionDetails = useCallback(() => {
    history("/collision/settings/xmlRedactionDetails/AddNew");
  }, [history]);

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(
        `/collision/settings/xmlRedactionDetails/${e.data.id}`,
        "_blank"
      );
    } else {
      history(`/collision/settings/xmlRedactionDetails/${e.data.id}`);
    }
  }

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("xmlRedaction")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateXmlRedactionDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("xmlRedaction")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.CollisionXMLRedaction.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="id"
                  allowSorting={true}
                  visible={false}
                  caption={t("id")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};

export default XMLRedactions;
