import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import reportLocations from "../../../../constants/reportLocations";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import {
  PagePermission,
  ResponseCode,
  TesMapModule,
} from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { VMLocation } from "../../../../types/infrastructure/dto/locationdDto";
import {
  LocationPickerMode,
  LocationType,
} from "../../../../types/infrastructure/enums/infrastructureEnums";
import { AADTDetails } from "../../../../types/trafficStudy/dtos/studyDto";
import { AADTType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { IAADTDetailsParams } from "../../../../types/trafficStudy/trafficStudyTypes";
import MaxDropdownItemWidthCalculator from "../../../../utils/dropDownWidthCalculator";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import "./aadtDetails.scss";
import Map from "./components/map/map";
import StudiesAADT from "./components/studiesAADT/studiesAADT";
import tabTitles from "./data/index";
import VolumeHistory from "./components/volumeHistory/volumeHistory";
import TesTabs from "../../../../components/tesTab/tesTab";
import ReportSelector from "../../../../components/reportSelector";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const AADTDetailsComponent = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initData, setInitData] = useState<AADTDetails>(new AADTDetails());
  const [initDataLocation, setInitDataLocation] = useState<VMLocation>(
    new VMLocation()
  );
  const params = useParams<IAADTDetailsParams>();
  const validationRef = useRef<ValidationGroupRef>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);

  const didMount = useRef(false);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const yearRef = useRef<HTMLSpanElement>(null);
  const locDescriptionRef = useRef<HTMLSpanElement>(null);
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);
  const { getPagePermission } = useAuth();
  const [locationColor, setLocationColor] = useState<string>("#424242");
  const [locationCursor, setLocationCursor] = useState<string>("default");

  useBrowserTitle({
    pathname: "/trafficStudy/aadts/aadtDetails",
    additionalText: `(${params.aadtType === "1" ? t("intersection") : params.aadtType === "2" ? t("roadSegment") : t("mis")}) 
    [${params.aadtType === "1" ? initData?.intersectionAADT?.geoId : params.aadtType === "2" ? initData?.roadSegmentAADT?.geoId : initData?.misAADT?.geoId}],
    ${t("year")}: ${params.aadtType === "1" ? initData?.intersectionAADT?.year : params.aadtType === "2" ? initData?.roadSegmentAADT?.year : initData?.misAADT?.year} 
    ${t("andLocation")}: ${params.aadtType === "1" ? initData?.intersectionAADT?.locationDescription : params.aadtType === "2" ? initData?.roadSegmentAADT?.locationDescription : initData?.misAADT?.locationDescription}`,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (params.aadtId !== "AddNew") {
            await getInitData(params.aadtId!, params.aadtType!);
          } else {
            setIsLocked(false);
          }
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.aadtId]);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  //General Functions
  async function getInitData(id: string, aadtType: string) {
    try {
      let res = (await TesGet(
        TrafficApiUrl() + "/api/AADT/GetAADTDetails/" + aadtType + "/" + id,
        true
      )) as RequestResponseResult<AADTDetails>;
      if (res.responseCode === ResponseCode.OK) {
        if (getPagePermission)
          setPagePermissionStatus(
            getPagePermission(
              [
                TrafficStudyPermissions.TrafficStudy_D_Study,
                TrafficStudyPermissions.TrafficStudy_E_Study,
              ],
              res.results
            )
          );

        // res.results.selectedStudyTypes=EnumFlag2Array(StudyType, res.results.studyTypes)
        setInitData(res.results);
        if (params.aadtType === AADTType.Intersection.toString()) {
          setInitDataLocation({
            id: res.results.intersectionAADT?.locationId,
            customerId: res.results.intersectionAADT?.customerId,
            description: res.results.intersectionAADT?.locationDescription,
            geoId: res.results.intersectionAADT?.geoId,
            locationType: LocationType.Intersection,
          });
        }
        if (params.aadtType === AADTType.RoadSegment.toString()) {
          setInitDataLocation({
            id: res.results.roadSegmentAADT?.locationId,
            customerId: res.results.roadSegmentAADT?.customerId,
            description: res.results.roadSegmentAADT?.locationDescription,
            geoId: res.results.roadSegmentAADT?.geoId,
            locationType: LocationType.Midblock,
          });
        }
      } else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000);
        history(-1);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      notify(t("errorInFetchData") + ex, "error", 5000);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.aadtId !== "AddNew") {
                  onUpdate(true);
                  setDataChanged(false);
                } else {
                  onAdd();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              // setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }
  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //ask15
  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...initData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() + "/api/Setups/AddUpdateAADTPattern",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          history(-1);
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate(backPermission: boolean) {
    try {
      const postObj = {
        ...initData,
      };

      if (activeLoading) activeLoading(true);
      let res = (await TesPost(
        TrafficApiUrl() + "/api/AADT/update",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        if (backPermission === true) {
          history(-1);
        }
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.AADTSourceIsWrong) {
        notify(t("aadtSourceIsWrong"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  async function onRecalculate() {
    if (activeLoading) activeLoading(true);
    var res = (await TesGet(
      TrafficApiUrl() +
        "/api/AADT/RecalculateAADT/" +
        params.aadtType +
        "/" +
        (initData.intersectionAADT?.customerId ??
          initData.roadSegmentAADT?.customerId) +
        "/" +
        (initData.intersectionAADT?.id ?? initData.roadSegmentAADT?.id),
      true
    )) as RequestResponseResult<null>;
    if (activeLoading) activeLoading(false);
    if (res.responseCode === ResponseCode.OK) {
      await getInitData(params.aadtId!, params.aadtType!);
      notify(t("dataSuccessfullyUpdatedAndRecalculated"), "success", 5000);
    } else {
      await RequestErrorHandling(res);
    }
    if (activeLoading) activeLoading(false);
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && yearRef.current && locDescriptionRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else if (option === "Year") {
        textToCopy = yearRef.current?.innerText!;
      } else {
        textToCopy = locDescriptionRef.current?.innerText!;
      }
      textToCopy = textToCopy.trim().replace(/^\[(.*?)\]\s*-?$/, "$1");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  function onLocationDetails() {
    if (initDataLocation?.locationType === LocationType.Intersection) {
      const newTabUrl =
        "/infrastructure/intersectionDetails/" + initDataLocation.id;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    } else if (initDataLocation?.locationType === LocationType.Midblock) {
      const newTabUrl =
        "/infrastructure/roadSegmentDetails/" + initDataLocation.id;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
        TrafficStudyPermissions.TrafficStudy_E_AADT,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`aadtDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"} style={{ marginBottom: "-1rem" }}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>
                      {params.aadtType === "1"
                        ? t("intersection")
                        : params.aadtType === "2"
                          ? t("roadSegment")
                          : t("mis")}
                      :{" "}
                    </span>
                    <span ref={geoIdRef}>
                      [
                      {params.aadtType === "1"
                        ? initData?.intersectionAADT?.geoId
                        : params.aadtType === "2"
                          ? initData?.roadSegmentAADT?.geoId
                          : initData?.misAADT?.geoId}
                      ] -
                    </span>
                    <span> {t("year")}: </span>
                    <span ref={yearRef}>
                      {params.aadtType === "1"
                        ? initData?.intersectionAADT?.year
                        : params.aadtType === "2"
                          ? initData?.roadSegmentAADT?.year
                          : initData?.misAADT?.year}
                    </span>
                    <span> - {t("andLocation")}: </span>
                    <span
                      ref={locDescriptionRef}
                      onMouseOver={(e) => {
                        setLocationColor("#F0B70d");
                        setLocationCursor("pointer");
                      }}
                      onMouseLeave={(e) => {
                        setLocationColor("#424242");
                        setLocationCursor("default");
                      }}
                      style={{ color: locationColor, cursor: locationCursor }}
                      onClick={onLocationDetails}
                    >
                      {params.aadtType === "1"
                        ? initData?.intersectionAADT?.locationDescription
                        : params.aadtType === "2"
                          ? initData?.roadSegmentAADT?.locationDescription
                          : initData?.misAADT?.locationDescription}
                    </span>
                  </p>
                  <DropDownButton
                    style={{ marginLeft: 10, width: "6rem" }}
                    icon="fa-solid fa-copy"
                    items={[
                      { name: t("geoId"), value: t("geoId") },
                      { name: t("year"), value: t("year") },
                      { name: t("location"), value: t("location") },
                    ]}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator([
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("year"), value: t("year") },
                        { name: t("location"), value: t("location") },
                      ]),
                    }}
                    displayExpr="name"
                    onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row">
                <div className="leftColumn" style={{ gap: "6px" }}>
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                  {params.aadtId !== "AddNew" && (
                    <ReportSelector
                      reportLocations={
                        initData.intersectionAADT &&
                        initData.intersectionAADT?.year !== 0
                          ? [
                              reportLocations.TrafficStudy_IntersectionAadtDetails_VolumeHistory,
                            ]
                          : [
                              reportLocations.TrafficStudy_RoadSegmentAadtDetails_VolumeHistory,
                            ]
                      }
                      getRecords={() => [{ id: params.aadtId || "" }]}
                    />
                  )}
                </div>
                <div className="rightColumn">
                  {params.aadtId === "AddNew" && (
                    <Button
                      onClick={() => onAdd()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  )}
                  {params.aadtId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={() => onUpdate(false)}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                  {isLocked === false && (
                    <Button
                      onClick={() => onRecalculate()}
                      icon="fa-solid fa-calculator"
                      hint={t("recalculate")}
                    />
                  )}
                  {pagePermissionStatus === PagePermission.Edit &&
                    params.aadtId !== "AddNew" && (
                      <Permission
                        allowed={[TrafficStudyPermissions.TrafficStudy_E_AADT]}
                        hasFeedBackElement={false}
                      >
                        <Button
                          onClick={() => LockHandler()}
                          icon={
                            isLocked
                              ? "fa-solid fa-lock"
                              : "fa-solid fa-lock-open"
                          }
                          hint={isLocked ? t("unlock") : t("lock")}
                        />
                      </Permission>
                    )}
                </div>
              </div>
            </div>
            <div className={"dx-card"}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />
              {selectedIndex === 0 && (
                <VolumeHistory
                  initData={initData}
                  aadtType={params.aadtType!}
                  setDataChanged={setDataChanged}
                  setInitData={setInitData}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 1 && (
                <Map
                  didMount={didMount.current}
                  renderMap={true}
                  aadtType={params.aadtType!}
                  initLocationData={initDataLocation}
                  tesModule={TesMapModule.AADTDetails}
                  componentMode={LocationPickerMode.TextBox}
                  renderCoordinate={false}
                  validationRef={validationRef}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 2 && (
                <StudiesAADT
                  initData={initData}
                  aadtType={params.aadtType!}
                  setDataChanged={setDataChanged}
                  setInitData={setInitData}
                  isLocked={isLocked}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default AADTDetailsComponent;
