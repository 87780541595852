//imports
import {
  Button,
  Form,
  NumberBox,
  Popup,
  ScrollView,
  SelectBox,
  Tabs,
  TextBox,
} from "devextreme-react";
import { SimpleItem } from "devextreme-react/cjs/form";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../../components/collapsibleCard/collapsibleCard";
import { useAuth } from "../../../../../../contexts/auth";
import {
  FieldApiUrl,
  TrafficApiUrl,
} from "../../../../../../environment/routeSettings";
import {
  GridType,
  ResponseCode,
} from "../../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../../types/general/generalTypes";
import { MatchingResultsDTO } from "../../../../../../types/trafficStudy/dtos/matchingDto";
import {
  MatchingAlgorithmResultType,
  MatchingAlgorithmStatus,
  MatchingPatternChangeStatus,
  MatchingQualityRankType,
  PCSPatternType,
} from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { RequestErrorHandling, TesGet } from "../../../../../../utils/rest";
import MatchingCurveStats from "./components/matchingCurveStats";
import VolumeHistory from "./components/volumeHistory";
import tabTitles from "./data";
import { TesCodeValue } from "../../../../../../types/infrastructure/infrastructureTypes";
import TesTabs from "../../../../../../components/tesTab/tesTab";
import useBrowserTitle from "../../../../../../hooks/browserTitle/useBrowserTitle";

interface IPros {
  initDataResults: MatchingResultsDTO[];
}

const Results = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const lstPatternType = Enum2Array(PCSPatternType);
  const lstPatternChangeStatus = Enum2Array(MatchingPatternChangeStatus);
  const lstQualityRankType = Enum2Array(MatchingQualityRankType);
  const lstAlgorithmResultType = Enum2Array(MatchingAlgorithmResultType);
  const lstAlgorithmStatus = Enum2Array(MatchingAlgorithmStatus);
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(new MatchingResultsDTO());
  const [selectedIndexInPopup, setSelectedIndexInPopup] = useState(0);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const { activeLoading } = useAuth();

  useBrowserTitle({ pathname: "/trafficStudy/tools/matchingProjects" });

  async function onRowClick(e: any) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TrafficApiUrl() + "/api/Matching/GetMatchingResultDetails/" + e.data.id,
        true
      )) as RequestResponseResult<MatchingResultsDTO>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        await getInfrastructureTesCodeValues();
        setRowData(res.results);
        setShowModal(true);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function onCloseModal() {
    setShowModal(false);
  }

  function handleTabChange(index: number) {
    setSelectedIndexInPopup(index);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ScrollView width="100%" height="100%">
          <Form colCount={3}>
            <SimpleItem colSpan={3}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={props.initDataResults}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={(e) => {
                  onRowClick(e);
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.MatchingResults.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="trafficSectionName"
                  caption={t("trafficSectionName")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="countStationName"
                  caption={t("countStationName")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="historicalPatternType"
                  allowSorting={false}
                  caption={t("historicalPatternType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstPatternType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="mpAadt" caption={t("mpAadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="assignedPatternType"
                  allowSorting={false}
                  caption={t("assignedPatternType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstPatternType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="patternChangeStatus"
                  allowSorting={false}
                  caption={t("patternChangeStatus")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstPatternChangeStatus,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="qualityScore" caption={t("qualityScore")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="qualityRankType"
                  allowSorting={false}
                  caption={t("qualityRankType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstQualityRankType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="algorithmResultType"
                  allowSorting={false}
                  caption={t("algorithmResultType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstAlgorithmResultType,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="algorithmStatus"
                  allowSorting={false}
                  caption={t("algorithmStatus")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstAlgorithmStatus,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </SimpleItem>
          </Form>
        </ScrollView>
      </div>

      <Popup
        width={"90%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("result")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <CollapsibleCard title={t("details")} isOpen={true}>
                <Form colCount={42}>
                  <SimpleItem colSpan={12}>
                    <TextBox
                      placeholder=""
                      label={t("trafficSectionName")}
                      labelMode="floating"
                      value={rowData.trafficSectionName}
                      showClearButton={true}
                      style={{ margin: "-0.75rem 0 0 0" }}
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={12}>
                    <TextBox
                      placeholder=""
                      label={t("countStationName")}
                      labelMode="floating"
                      value={rowData.countStationName}
                      showClearButton={true}
                      style={{ margin: "-0.75rem 0 0 0" }}
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={6}>
                    <NumberBox
                      placeholder=""
                      label={t("mpAadt")}
                      labelMode="floating"
                      value={rowData.mpAadt}
                      showClearButton={true}
                      style={{ margin: "-0.75rem 0 0 0" }}
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={6}>
                    <NumberBox
                      placeholder=""
                      label={t("mpAadt")}
                      labelMode="floating"
                      value={rowData.mpAadt}
                      showClearButton={true}
                      style={{ margin: "-0.75rem 0 0 0" }}
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={6}>
                    <NumberBox
                      placeholder=""
                      label={t("qualityScore")}
                      labelMode="floating"
                      value={rowData.qualityScore}
                      showClearButton={true}
                      style={{ margin: "-0.75rem 0 0 0" }}
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={7}>
                    <SelectBox
                      labelMode="floating"
                      label={t("historicalPatternType")}
                      value={rowData.historicalPatternType}
                      items={lstPatternType}
                      showClearButton={true}
                      searchEnabled={true}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={7}>
                    <SelectBox
                      labelMode="floating"
                      label={t("assignedPatterType")}
                      value={rowData.assignedPatternType}
                      items={lstPatternType}
                      showClearButton={true}
                      searchEnabled={true}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={7}>
                    <SelectBox
                      labelMode="floating"
                      label={t("patternChangeStatus")}
                      value={rowData.patternChangeStatus}
                      items={lstPatternChangeStatus}
                      showClearButton={true}
                      searchEnabled={true}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={7}>
                    <SelectBox
                      labelMode="floating"
                      label={t("qualityRankType")}
                      value={rowData.qualityRankType}
                      items={lstQualityRankType}
                      showClearButton={true}
                      searchEnabled={true}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={7}>
                    <SelectBox
                      labelMode="floating"
                      label={t("algorithmResultType")}
                      value={rowData.algorithmResultType}
                      items={lstAlgorithmResultType}
                      showClearButton={true}
                      searchEnabled={true}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={7}>
                    <SelectBox
                      labelMode="floating"
                      label={t("algorithmStatus")}
                      value={rowData.algorithmStatus}
                      items={lstAlgorithmStatus}
                      showClearButton={true}
                      searchEnabled={true}
                      valueExpr="value"
                      displayExpr="name"
                      disabled={true}
                    />
                  </SimpleItem>
                </Form>
              </CollapsibleCard>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndexInPopup}
                onTabChange={handleTabChange}
              />
              {selectedIndexInPopup === 0 && (
                <VolumeHistory
                  gridData={rowData.volumeHistory}
                  initDataTesCodeValues={initDataTesCodeValues}
                />
              )}
              {selectedIndexInPopup === 1 && (
                <MatchingCurveStats gridData={rowData.curveStats} />
              )}
            </SimpleItem>
          </Form>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};
export default Results;
