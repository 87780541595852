import {
  Button,
  DataGrid,
  Form,
  Popup,
  ScrollView,
  TextBox,
} from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import Permission from "../../../../../components/permission/permision";
import Stepper from "../../../../../components/stepper/stepper";
import SafetyPermissions from "../../../../../constants/Permissions/SafetyPermissions";
import { OnExporting } from "../../../../../utils/dataGridTools";

interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function DiagnosisPopup(props: IProps) {
  const [step, setStep] = useState<number>(1);
  const [step1GridData, setStep1GridData] = useState<any[]>([]);
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [showCustomDefineModal, setShowCustomDefineModal] = useState(false);
  const counterMeasureGridRef = useRef<DataGridRef<any, any>>(null);
  const [lstCounterMeasures, setLstCounterMeasures] = useState<any[]>([]);
  const LibraryGridRef = useRef<DataGridRef<any, any>>(null);
  const [counterMeasureLibrary, setCounterMeasureLibrary] = useState<any[]>([]);
  const [selectedCounterMeasureRows, setSelectedCounterMeasureRows] = useState<
    any[]
  >([]);
  const [customCounterMeasure, setCustomCounterMeasure] = useState<any>({});
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  useEffect(() => {
    setStep1GridData([
      { col1: "1", col2: "2" },
      { col1: "3", col2: "4" },
    ]);
    setLstCounterMeasures([{ counterMeasure: "1" }, { counterMeasure: "3" }]);
    setCounterMeasureLibrary([
      { counterMeasure: "CMLib1" },
      { counterMeasure: "CMLib2" },
    ]);
    // async function fetchMyAPI() {
    //   try {
    //     if (activeLoading) activeLoading(true);
    //     await getInitialDataFields();
    //     await getInitialDataFieldValue();
    //     if (activeLoading) activeLoading(false);
    //   } catch (ex) {
    //     if (activeLoading) activeLoading(false);
    //     notify(t("someErrorOccurred") + ex, "error", 5000);
    //   }
    // }
    // fetchMyAPI();
  }, []);

  function onCloseModal() {
    props.setShowModal(false);
    setStep(1);
  }

  function onCounterMeasureDelete(d: any) {
    setLstCounterMeasures(
      lstCounterMeasures.filter(
        (x) => x.counterMeasure !== d.row.data.counterMeasure
      )
    );
  }

  function onRunCalculation() {}

  function onAddFromLibrary() {
    setLstCounterMeasures([
      ...lstCounterMeasures,
      ...selectedCounterMeasureRows,
    ]);
    setShowLibraryModal(false);
  }

  function onValueChanged(name: string, value: any) {
    setCustomCounterMeasure({ [name]: value });
  }

  function onAddCustomCounterMeasure() {
    setLstCounterMeasures([...lstCounterMeasures, customCounterMeasure]);
    setShowCustomDefineModal(false);
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_V_Workbook]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <Popup
          width={"80%"}
          height={"auto"}
          visible={props.showModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("assign")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
          animation={undefined}
          position="center"
        >
          <ScrollView width="100%" height="100%">
            <div>
              <Stepper selectedStep={step} stepNumber={2} />
              {step === 1 && (
                <div>
                  <h5
                    style={{
                      marginLeft: "0.5rem",
                      marginTop: "-3rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {t("results")}
                  </h5>
                  <div className={"dx-card responsive-paddings"}>
                    <DataGrid
                      id="grid"
                      ref={dataGridRef}
                      dataSource={step1GridData}
                      allowColumnReordering={true}
                      showBorders={true}
                      rowAlternationEnabled={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      onExporting={OnExporting}
                      //onContentReady={e => {e.component.deselectAll()}}
                      //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Grouping
                        contextMenuEnabled={true}
                        autoExpandAll={false}
                      />
                      <GroupPanel visible={true} /> {/* or "auto" */}
                      <FilterPanel visible={true} />
                      <FilterBuilderPopup position={"top"} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />
                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"always"}
                      />
                      <FilterRow visible={true} applyFilter="auto" />
                      <HeaderFilter visible={true} />
                      <SearchPanel
                        visible={true}
                        width={285}
                        placeholder={t("search...")}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>
                      <Column
                        minWidth={90}
                        dataField="col1"
                        caption={t("col1")}
                      ></Column>
                      <Column
                        minWidth={90}
                        dataField="col2"
                        caption={t("col2")}
                      ></Column>
                    </DataGrid>
                  </div>
                  <Button
                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                    onClick={(e) => setStep(2)}
                    className="tes-modal-btn-add rightColumn"
                    text={t("next")}
                  />
                  <Button
                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                    onClick={onCloseModal}
                    className="tes-modal-btn-cancel rightColumn"
                    text={t("cancel")}
                  />
                </div>
              )}
              {step === 2 && (
                <div>
                  <h5
                    className={"content-block"}
                    style={{
                      marginLeft: "0.5rem",
                      marginTop: "-3rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {t("counterMeasures")}
                  </h5>
                  <div className={"dx-card responsive-paddings"}>
                    <div className="row">
                      <div className="rightColumn">
                        <Button
                          style={{ marginBottom: "-1rem" }}
                          onClick={(e) => setShowLibraryModal(true)}
                          icon="fa-solid fa-link"
                          text={t("addFromLibrary")}
                        />
                        <Button
                          style={{
                            marginBottom: "-1rem",
                            marginLeft: "1.5rem",
                          }}
                          onClick={(e) => setShowCustomDefineModal(true)}
                          icon="fa-solid fa-user-gear"
                          text={t("defineCustom")}
                        />
                      </div>
                    </div>
                    <DataGrid
                      id="gridContainer"
                      ref={counterMeasureGridRef}
                      dataSource={lstCounterMeasures}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      onExporting={OnExporting}
                      //onContentReady={e => {e.component.deselectAll()}}
                      //onFilterValueChange={e => {counterMeasureGridRef?.current?.instance().deselectAll()}}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Grouping
                        contextMenuEnabled={true}
                        autoExpandAll={false}
                      />
                      <GroupPanel visible={true} /> {/* or "auto" */}
                      <FilterPanel visible={true} />
                      <FilterBuilderPopup position={"top"} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />
                      <Selection
                        mode="multiple"
                        selectAllMode={"allPages"}
                        showCheckBoxesMode={"always"}
                      />
                      <FilterRow visible={true} applyFilter="auto" />
                      <HeaderFilter visible={true} />
                      <SearchPanel
                        visible={true}
                        width={285}
                        placeholder={t("search...")}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>
                      <Column
                        dataField="counterMeasure"
                        allowSorting={false}
                        caption={t("counterMeasure")}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        type="buttons"
                        caption={t("actions")}
                        width={110}
                        fixed={true}
                        fixedPosition="right"
                      >
                        <DevExpressButton
                          hint={t("delete")}
                          onClick={onCounterMeasureDelete}
                          icon="fa-solid fa-trash-can"
                        ></DevExpressButton>
                      </Column>
                    </DataGrid>
                  </div>
                  <Button
                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                    onClick={onRunCalculation}
                    className="tes-modal-btn-add rightColumn"
                    text={t("runCalculation")}
                  />
                  <Button
                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                    onClick={(e) => setStep(1)}
                    className="tes-modal-btn-add rightColumn"
                    text={t("back")}
                  />
                  <Button
                    style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
                    onClick={onCloseModal}
                    className="tes-modal-btn-cancel rightColumn"
                    text={t("cancel")}
                  />
                </div>
              )}
            </div>
          </ScrollView>
        </Popup>

        {/* Library Modal */}
        <Popup
          width={"80%"}
          height={"auto"}
          visible={showLibraryModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("assign")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowLibraryModal(false)}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <DataGrid
                  id="gridContainer"
                  ref={LibraryGridRef}
                  dataSource={counterMeasureLibrary}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onSelectedRowKeysChange={(e) =>
                    setSelectedCounterMeasureRows(e)
                  }
                  onExporting={OnExporting}
                  //onContentReady={e => {e.component.deselectAll()}}
                  //onFilterValueChange={e => {LibraryGridRef?.current?.instance().deselectAll()}}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    width={285}
                    placeholder={t("search...")}
                  />
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    dataField="counterMeasure"
                    allowSorting={false}
                    caption={t("counterMeasure")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </DataGrid>
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn" style={{ marginBottom: "0.5rem" }}>
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => setShowLibraryModal(false)}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAddFromLibrary}
                  text={t("assign")}
                />
              </div>
            </div>
          </ScrollView>
        </Popup>

        {/* Custom Define Modal */}
        <Popup
          width={"80%"}
          height={"auto"}
          visible={showCustomDefineModal}
          resizeEnabled={true}
          showTitle={true}
          title={t("assign")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowCustomDefineModal(false)}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem>
                <TextBox
                  label={t("title")}
                  labelMode="floating"
                  onValueChange={(e) => onValueChanged("counterMeasure", e)}
                  className="modalInput"
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn" style={{ marginBottom: "0.5rem" }}>
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => setShowCustomDefineModal(false)}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAddCustomCounterMeasure}
                  text={t("assign")}
                />
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </Permission>
  );
}

export default DiagnosisPopup;
