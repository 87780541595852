//#region imports
import {
  Button,
  ContextMenu,
  Form,
  Popup,
  SelectBox,
  TextBox,
} from "devextreme-react";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import ValidationGroup, {
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import {
  AuthApiUrl,
  CollisionApiUrl,
  FieldApiUrl,
} from "../../../../../environment/routeSettings";
import { CustomerCollision } from "../../../../../types/collision/collisionTypes";
import {
  CollisionMergeDecisionDTO,
  CollisionMergeUserActionDTO,
} from "../../../../../types/collision/dto/collisionDtos";
import {
  CollisionCleaningMode,
  CollisionDataSourceType,
  CollisionMergeAction,
  CollisionMergeStatus,
  CollisionSingleAction,
  CollisionTesStatus,
  GeoCodeStatus,
} from "../../../../../types/collision/enums/collisionEnums";
import {
  TableFieldRequestDTO,
  VMCollisionGroupedField,
} from "../../../../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../types/field/fieldType";
import {
  GridSetting,
  INameId,
  NameValue,
} from "../../../../../types/general/generalTypes";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../../../types/infrastructure/infrastructureTypes";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../../../../utils/dateTimeTools";
import { Enum2Array, EnumFlag2Array } from "../../../../../utils/enumTools";
import { TesGet, TesPost } from "../../../../../utils/rest";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";
//#endregion imports
//#region props

interface IPros {
  initDataFields: VMCollisionGroupedField;
  initDataCollision: CustomerCollision;
  generalSettings: GeneralSetting | null;
  validationRef: React.RefObject<ValidationGroupRef>;
  isLocked: boolean;
  lstCollisionTesStatus: NameValue[];
  setInitDataCollision: React.Dispatch<React.SetStateAction<CustomerCollision>>;
}
//#endregion props
const MergeLogs = (props: IPros) => {
  //#region consts
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const [lstCleanMode, setLstCleanMode] = useState<NameValue[]>([]);
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
  const [lstCollisionDataSourceType, setLstCollisionDataSourceType] = useState<
    NameValue[]
  >([]);
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const [lstTesStatus, setLstTesStatus] = useState<NameValue[]>([]);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const [lstAllCodeValues, setLstAllCodeValues] = useState<INameId[]>([]);
  const [lstActions, setLstActions] = useState<NameValue[]>([]);
  const [lstSingleActions, setLstSingleActions] = useState<NameValue[]>([]);

  const [gridSetting, setGridSetting] = useState<GridSetting>(
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting()
  );
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
  ];
  const [showMessagePopup, setShowMessagePopup] = useState<boolean>(false);
  var postDataAction: CollisionMergeDecisionDTO =
    new CollisionMergeDecisionDTO();
  const { activeLoading } = useAuth();
  const history = useNavigate();

  //#endregion consts
  //#region functions

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        getAllCodeValeus();
        await getInfrastructureTesCodeValues();
        await getInitDataDivisions();
        await getInitialDataFields();
        setLstLocationType(Enum2Array(LocationType));
        setLstCleanMode(Enum2Array(CollisionCleaningMode));
        setLstCollisionDataSourceType(Enum2Array(CollisionDataSourceType));
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
        setLstTesStatus(Enum2Array(CollisionTesStatus));
        setLstActions(Enum2Array(CollisionMergeAction));
        setLstSingleActions(Enum2Array(CollisionSingleAction));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getAllCodeValeus() {
    var generalFields = props.initDataFields.generalFields
      .filter((x) => x.fieldValues !== null)
      .flatMap((x) => x.fieldValues);
    var roadFields = props.initDataFields.roadFields
      .filter((x) => x.fieldValues !== null)
      .flatMap((x) => x.fieldValues);
    var vehicleFields = props.initDataFields.vehicleFields
      .filter((x) => x.fieldValues !== null)
      .flatMap((x) => x.fieldValues);
    var driverFields = props.initDataFields.driverFields
      .filter((x) => x.fieldValues !== null)
      .flatMap((x) => x.fieldValues);
    var passengerFields = props.initDataFields.passengerFields
      .filter((x) => x.fieldValues !== null)
      .flatMap((x) => x.fieldValues);
    var personFields = props.initDataFields.personFields
      .filter((x) => x.fieldValues !== null)
      .flatMap((x) => x.fieldValues);
    setLstAllCodeValues([
      ...generalFields,
      ...roadFields,
      ...vehicleFields,
      ...driverFields,
      ...passengerFields,
      ...personFields,
    ]);
  }
  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [FieldCategoryType.CollisionGeneral],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }
  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  async function getInitDataDivisions() {
    setInitDataDivisions(
      await TesGet(
        AuthApiUrl() +
          "/api/divisions/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  function calFromValue(c: any) {
    if (c.fromValue === null || c.fromValue === undefined) {
      return "";
    }
    if (c.fieldName.includes("New")) {
      return "";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue != null &&
      c.fromValue == null
    ) {
      return "";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue == null &&
      c.fromValue != null
    ) {
      return "";
    }

    if (c.fieldName === "GeoId") {
      return c.fromValue;
    }
    if (c.fieldName === "LocationDescription") {
      return c.fromValue;
    }
    if (c.fieldName === "LocationId") {
      return c.fromValue;
    }

    if (c.fieldName === "YCoord") {
      return c.fromValue;
    }
    if (c.fieldName === "XCoord") {
      return c.fromValue;
    }
    if (c.fieldName === "Longitude") {
      return c.fromValue;
    }
    if (c.fieldName === "Latitude") {
      return c.fromValue;
    }

    const v = lstAllCodeValues?.find((x) => x.id === c?.fromValue);
    if (v !== null && v !== undefined) {
      return v?.name;
    } else {
      return c.fromValue;
    }
  }
  function calToValue(c: any) {
    if (c.toValue === null || c.toValue === undefined) {
      return "";
    }
    if (c.fieldName.includes("New")) {
      return "";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue != null &&
      c.fromValue == null
    ) {
      return "Add Attachment";
    }
    if (
      c.fieldName === "Attachment" &&
      c.toValue == null &&
      c.fromValue != null
    ) {
      return "Remove Attachment";
    }

    if (c.fieldName === "GeoId") {
      return c.toValue;
    }
    if (c.fieldName === "LocationDescription") {
      return c.toValue;
    }
    if (c.fieldName === "LocationId") {
      return c.toValue;
    }

    if (c.fieldName === "YCoord") {
      return c.toValue;
    }
    if (c.fieldName === "XCoord") {
      return c.toValue;
    }
    if (c.fieldName === "Longitude") {
      return c.toValue;
    }
    if (c.fieldName === "Latitude") {
      return c.toValue;
    }

    const v = lstAllCodeValues?.find((x) => x.id === c?.toValue);
    if (v !== null && v !== undefined) {
      return v?.name;
    } else {
      return c.toValue;
    }
  }
  function onRowClicked(e: any) {
    if (e.data._id !== undefined) {
      const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
      if (isCtrlKeyPressed) {
        window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
      }
    }
  }

  function onChangeAction(value: any, data: any) {
    //data is the grid data, value is the action value
    var currentRecord: CollisionMergeUserActionDTO = {
      collisionId: data.data._id,
      collisionMergeAction: value,
    };
    postDataAction.collisionMergeUserActions.push(currentRecord);
  }

  async function onApplyActions() {
    try {
      if (activeLoading) activeLoading(true);
      postDataAction.collisionId2MoveFile2 = props.initDataCollision?.id;
      await TesPost(
        CollisionApiUrl() + "/api/AdminTools/DeleteCollisionsFromUserAction",
        postDataAction,
        true
      );
      setShowMessagePopup(true);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function actionColumnRenderer(d: any) {
    return (
      <div
        style={{ maxWidth: "150px", overflow: "hidden", whiteSpace: "nowrap" }}
      >
        <SelectBox
          placeholder=""
          displayExpr="name"
          valueExpr="value"
          style={{ height: "2rem" }}
          items={SortObjectByPropName(lstActions, "name")}
          onValueChange={(e) => onChangeAction(e, d)}
          showClearButton={true}
          searchEnabled={true}
        />
      </div>
    );
  }

  function onClosePopup() {
    setShowMessagePopup(false);
    history(-1);
  }

  async function onChangeSingleCollisionAction(action: any) {
    if (action === CollisionSingleAction.Keep) {
      props.setInitDataCollision({
        ...props.initDataCollision,
        collisionMergeStatus: CollisionMergeStatus.Done,
      });
    } else if (action === CollisionSingleAction.Delete) {
      await TesGet(
        CollisionApiUrl() +
          "/api/AdminTools/DeleteSingleCollisionFromUserAction/" +
          props.initDataCollision.id,
        true
      );
      history(`/collision/collisions`);
    }
  }
  //#endregion functions
  return (
    <div
      className={`collisionDetails-changeLogs ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data._id,
                "/collision/collisionDetails/",
                selectedRowData.row.data.locationType,
                selectedRowData.row.data.locationId
              )
            }
          />
          <div className="row" style={{ marginTop: 20, padding: 10 }}>
            <Form colCount={2}>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("collisionTesStatus")}
                  valueExpr={"value"}
                  displayExpr={"name"}
                  labelMode="floating"
                  value={props.initDataCollision?.collisionTesStatus}
                  items={SortObjectByPropName(
                    props.lstCollisionTesStatus,
                    "name"
                  )}
                  disabled={true}
                  showClearButton={true}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("conflictedFields")}
                  labelMode="floating"
                  value={props.initDataCollision?.xmlImportMessage}
                  disabled={true}
                />
              </SimpleItem>
            </Form>

            <CollapsibleCard title={t("relatedCollisions")}>
              {props.initDataCollision?.relatedCollisions.length > 0 ? (
                <div style={{ padding: "1rem" }}>
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onApplyActions}
                    text={t("apply")}
                    disabled={props.isLocked}
                  />
                </div>
              ) : (
                <div>
                  <Form colCount={4}>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("action")}
                        labelMode="floating"
                        displayExpr="name"
                        valueExpr="value"
                        items={SortObjectByPropName(lstSingleActions, "name")}
                        onValueChange={(e) => onChangeSingleCollisionAction(e)}
                        showClearButton={true}
                        searchEnabled={true}
                        disabled={props.isLocked}
                      />
                    </SimpleItem>
                  </Form>
                </div>
              )}
              {initDataFields && initDataFields.length > 0 && (
                <DataGrid
                  id="gridContainer"
                  key={"_id"}
                  ref={dataGridRef}
                  height={1000}
                  dataSource={props.initDataCollision?.relatedCollisions}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  onRowClick={onRowClicked}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  onContextMenuPreparing={(e) => {
                    setSelectedRowData(e);
                  }}
                  onExporting={OnExporting}
                  style={{ margin: "0 1rem" }}
                  columnAutoWidth={gridSetting.horizontalScrolling}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  {/* <Selection
                                        mode="multiple"
                                        selectAllMode={"allPages"}
                                        showCheckBoxesMode={"always"}
                                        deferred={true}
                                        // @ts-ignore
                                        maxFilterLengthInRequest={10000}
                                    /> */}
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="_id" caption={t("id")} visible={false}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="ParentId"
                    caption={t("ParentId")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="GeoId"
                    allowSorting={true}
                    caption={t("geoId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationDescription"
                    allowHeaderFiltering={false}
                    width={300}
                    caption={t("locationDescription")}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="LocationType"
                    allowHeaderFiltering={true}
                    caption={t("locationType")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(lstLocationType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="MunicipalityId"
                    visible={
                      initDataTesCodeValues.municipalities?.length !== 0 &&
                      initDataTesCodeValues.municipalities !== undefined
                    }
                    allowHeaderFiltering={true}
                    caption={t("municipality")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(
                        initDataTesCodeValues?.municipalities,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                    <HeaderFilter
                      dataSource={initDataTesCodeValues?.municipalities?.map(
                        (x) => ({ text: x.name, value: x.id })
                      )}
                    >
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="JurisdictionId"
                    visible={
                      initDataTesCodeValues.jurisdictions?.length !== 0 &&
                      initDataTesCodeValues.jurisdictions !== undefined
                    }
                    allowHeaderFiltering={true}
                    caption={t("jurisdiction")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(
                        initDataTesCodeValues?.jurisdictions,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                    <HeaderFilter
                      dataSource={initDataTesCodeValues?.jurisdictions?.map(
                        (x) => ({ text: x.name, value: x.id })
                      )}
                    >
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="Year"
                    dataType="number"
                    alignment="left"
                    sortOrder={"desc"}
                    allowHeaderFiltering={true}
                    caption={t("accidentYear")}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="SubmissionDT"
                    caption={t("submissionDT")}
                    dataType="datetime"
                    visible={false}
                    format={props.generalSettings?.dateTimeFormat}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="DataSourceType"
                    caption={t("dataSourceType")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstCollisionDataSourceType,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  {initDataDivisions && initDataDivisions.length > 0 && (
                    <Column dataField="DivisionId" caption={t("division")}>
                      <Lookup
                        dataSource={SortObjectByPropName(
                          initDataDivisions,
                          "name"
                        )}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  )}
                  <Column
                    dataField="GeoCodeStatus"
                    caption={t("geoCodeStatus")}
                    visible={false}
                  >
                    <Lookup
                      dataSource={SortObjectByPropName(
                        lstGeoCodeStatus,
                        "name"
                      )}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  {TMSPermissions.TMS_Admin && (
                    <Column
                      dataField="CollisionTesStatus"
                      caption={t("collisionTesStatus")}
                      visible={false}
                    >
                      <Lookup
                        dataSource={SortObjectByPropName(lstTesStatus, "name")}
                        valueExpr="value"
                        displayExpr="name"
                      />
                    </Column>
                  )}
                  <Column
                    dataField="EditedSubmissionDT"
                    caption={t("lastEditDT")}
                    visible={false}
                    dataType="datetime"
                    format={props.generalSettings?.dateFormat}
                  ></Column>
                  {initDataFields.map((d) => {
                    if (d.fieldType === FieldType.List) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowFiltering={true}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <Search enabled />
                          <Lookup
                            dataSource={SortObjectByPropName(
                              d.fieldValues,
                              "name"
                            )}
                            valueExpr="id"
                            displayExpr="name"
                          />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.String) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="string"
                          alignment="left"
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Number) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="number"
                          alignment="left"
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Date) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="date"
                          calculateCellValue={(e: any) =>
                            GridActualDateCalculator(
                              e,
                              d,
                              props.generalSettings
                            )
                          }
                          format={props.generalSettings?.dateFormat}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Time) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          allowHeaderFiltering={false}
                          allowGrouping={false}
                          calculateCellValue={(e: any) =>
                            GridActualDateCalculator(
                              e,
                              d,
                              props.generalSettings
                            )
                          }
                          format={props.generalSettings?.timeFormat}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter visible={false}>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.DateTime) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="datetime"
                          calculateCellValue={(e: any) =>
                            GridActualDateCalculator(
                              e,
                              d,
                              props.generalSettings
                            )
                          }
                          format={props.generalSettings?.dateTimeFormat}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else if (d.fieldType === FieldType.Boolean) {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          dataType="boolean"
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    } else {
                      return (
                        <Column
                          key={d.name}
                          dataField={d.name}
                          caption={d.labelText}
                          allowHeaderFiltering={false}
                          visible={
                            EnumFlag2Array(
                              FieldLocation2Show,
                              d.fieldLocation2Show
                            ).some((x) => x === FieldLocation2Show.GridView)
                              ? true
                              : false
                          }
                        >
                          <Search enabled />
                          <HeaderFilter>
                            <Search enabled />
                          </HeaderFilter>
                        </Column>
                      );
                    }
                  })}
                  {!props.isLocked && (
                    <Column
                      alignment="left"
                      cellRender={actionColumnRenderer}
                      caption={t("action")}
                      fixed={true}
                      width={200}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  )}
                </DataGrid>
              )}
            </CollapsibleCard>
            <CollapsibleCard title={t("mergeLogs")}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={props.initDataCollision?.mergeLogs}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={false}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              >
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />

                <Export enabled={true} allowExportSelectedData={true} />

                <FilterRow visible={true} applyFilter="auto" />

                <HeaderFilter visible={true} />

                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>

                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column
                  alignment="left"
                  dataField="id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField={"fieldName"} caption={t("fieldName")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField={"changedDate"}
                  caption={t("dateTime")}
                  dataType="datetime"
                  format={props.generalSettings?.dateTimeFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField={"fromValue"}
                  caption={t("fromValue")}
                  calculateDisplayValue={(c: any) => calFromValue(c)}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField={"toValue"}
                  caption={t("toValue")}
                  calculateDisplayValue={(c: any) => calToValue(c)}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField={"note"} caption={t("note")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField={"cleanMode"} caption={t("cleanMode")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={lstCleanMode}
                    displayExpr={"name"}
                    valueExpr={"value"}
                  />
                </Column>
              </DataGrid>
            </CollapsibleCard>
          </div>
        </ValidationGroup>

        <Popup
          visible={showMessagePopup}
          width={"40%"}
          height={"35%"}
          resizeEnabled={true}
          showTitle={true}
          title={t("actionResult")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onClosePopup()}
        >
          <div>
            <p>{t("applyUserActionMessage")}</p>
            <div className="rightColumn" style={{ marginTop: 30 }}>
              <Button
                className="tes-modal-btn-add"
                onClick={() => onClosePopup()}
                text={t("close")}
              />
            </div>
          </div>
        </Popup>
      </React.Fragment>
    </div>
  );
};
export default MergeLogs;
