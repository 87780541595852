import { ScrollView } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../../contexts/auth";
import { AuthApiUrl } from "../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { INameId } from "../../../../../types/general/generalTypes";
import { VMUserProfileDetails } from "../../../../../types/identity/dto/identityDTO";
import { CustomerXUser } from "../../../../../types/identity/identityTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { useScreenSize } from "../../../../../utils/media-query";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../../utils/rest";

// props
interface IPros {
  initData: VMUserProfileDetails;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const Clients = (props: IPros) => {
  const [showModal, setShowModal] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridModalRef = useRef<DataGridRef<any, any>>(null);
  const [initDataModal, setInitDataModal] = useState<INameId[]>();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [gridHeight, setGridHeight] = useState<number>(420);
  const [popupHeight, setPopupHeight] = useState<string>("85%");

  useEffect(() => {
    if (isXLarge) {
      setPopupHeight("80%");
      setGridHeight(530);
    } else if (is2xLarge) {
      setPopupHeight("60%");
      setGridHeight(1020);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //modal functions
  function onCloseModal() {
    setShowModal(false);
  }
  //modal mode functions
  async function onNew() {
    var res = (await TesGet(
      AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser",
      true
    )) as INameId[];
    setInitDataModal(
      res.filter(
        (x) => !props.initData.customers.map((a) => a.id).includes(x.id)
      )
    );
    setShowModal(true);
  }

  async function onAssign() {
    var selectedData = initDataModal?.filter((x) =>
      dataGridModalRef.current
        ?.instance()
        .getSelectedRowKeys()
        .map((a) => a.id)
        .includes(x.id)
    );
    if (props.initData !== undefined && selectedData !== undefined) {
      props.setInitData({
        ...props.initData,
        customers: [...props.initData.customers, ...selectedData],
      });
    }
    onCloseModal();
    dataGridRef.current?.instance().refresh();
  }

  //delete functions
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitData({
                ...props.initData,
                customers: props.initData.customers.filter(
                  (x) => x.id !== d.row.data.id
                ),
              });
              dataGridRef.current?.instance().refresh();
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function onSave() {
    try {
      if (activeLoading) activeLoading(true);
      const postObj: CustomerXUser[] = props.initData.customers.map(
        (x: INameId) => {
          var res: CustomerXUser = {
            userId: props.initData.id,
            customerId: x.id,
          };
          return res;
        }
      );
      const res = await TesPost(
        AuthApiUrl() + "/api/CustomerXUsers",
        postObj,
        true
      );
      if (res.responseCode === ResponseCode.OK) {
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.UserNotPermitted) {
        notify(t("userNotPermitted"), "error", 5000);
      } else if (res.responseCode === ResponseCode.UpdatingError) {
        notify(t("deletingError"), "error", 5000);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <div className="row">
          <div className="rightColumn">
            <Button
              text={t("assign")}
              onClick={onNew}
              icon="fa-solid fa-link"
            />
          </div>
        </div>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={props.initData.customers}
          rowAlternationEnabled={true}
          showBorders={true}
          style={{ height: "100%", margin: "0 1rem" }}
          //keyExpr="id"
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Grouping contextMenuEnabled={true} autoExpandAll={false} />
          <GroupPanel visible={true} /> {/* or "auto" */}
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <Column dataField="id" caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="name" caption={t("name")} defaultSortOrder="asc">
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            type="buttons"
            caption={t("actions")}
            width={110}
            fixed={true}
            fixedPosition="right"
          >
            <DevExpressButton
              hint={t("delete")}
              onClick={onDelete}
              icon="fa-solid fa-trash-can"
            ></DevExpressButton>
          </Column>
        </DataGrid>

        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
      <Popup
        width={"50%"}
        height={popupHeight}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("add")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <div style={{ height: "90%" }}>
          <ScrollView width="100%" height="100%">
            <DataGrid
              id="gridContainer"
              ref={dataGridModalRef}
              dataSource={initDataModal}
              rowAlternationEnabled={true}
              showBorders={true}
              style={{ height: "100%" }}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              height={gridHeight}
              onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridModalRef?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="name"
                caption={t("name")}
                defaultSortOrder="asc"
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                type="buttons"
                caption={t("actions")}
                width={110}
                fixed={true}
                fixedPosition="right"
              >
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </ScrollView>
        </div>
        <div className="rightColumn" style={{ marginTop: 10 }}>
          <Button
            className="tes-modal-btn-cancel"
            style={{ marginRight: 20 }}
            onClick={() => onCloseModal()}
            text={t("cancel")}
          />
          <Button
            className="tes-modal-btn-add"
            onClick={onAssign}
            text={t("add")}
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};
export default Clients;
