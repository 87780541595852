import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import { Template } from "devextreme-react/core/template";
import UserPanel from "../user-panel/user-panel";
import { Link } from "react-router-dom";
import "./header.scss";
import { INameId } from "../../types/general/generalTypes";
import { useTranslation } from "react-i18next";
import { EnvConfig } from "../../environment/environment-model";
import { DivisionSystem } from "../../types/identity/enums/identityEnums";
import { DropDownButton } from "devextreme-react";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { useEffect, useState } from "react";
import { VMAddUserData } from "../../types/identity/dto/identityDTO";
import { TesGet } from "../../utils/rest";
import { AuthApiUrl } from "../../environment/routeSettings";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../contexts/auth";
import { use } from "i18next";
import MaxDropdownItemWidthCalculator from "../../utils/dropDownWidthCalculator";

interface IProps {
  menuToggleEnabled: boolean;
  title?: string;
  toggleMenu: (e: any) => void;
  userMenuItems?: any;
  selectedClineNameId?: INameId | null;
}

const logo = "/images/logoWithoutFooter.png";

const TesHeader = ({
  menuToggleEnabled,
  title,
  toggleMenu,
  userMenuItems,
  selectedClineNameId,
}: IProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  let globalEnvConfig = (window as any).globalEnvConfig! as EnvConfig;
  const uiSetting = globalEnvConfig.UiSettings;
  const cssClass = "header-component " + uiSetting.HeaderColorCssClass;
  const divisionSystem = parseInt(localStorage.getItem("divisionSystem")!);
  const [lstUserDivisionRole, setLstUserDivisionRole] = useState<INameId>();
  const [selectedDivision, setSelectedDivision] = useState(
    JSON.parse(localStorage.getItem("selectedDivision")!)?.name ?? ""
  );

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (user) {
          setLstUserDivisionRole(user.singleDivision);
        }
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 2500);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onDivisionDropdown(e: any) {
    localStorage.setItem("selectedDivision", JSON.stringify(e.itemData));
    e.itemData.id === "All"
      ? localStorage.setItem("selectedDivisionId", "")
      : localStorage.setItem("selectedDivisionId", e.itemData?.id ?? "");
    setSelectedDivision(
      JSON.parse(localStorage.getItem("selectedDivision")!).name
    );
    window.location.reload();
  }
  return (
    <header className={cssClass}>
      <Toolbar className={"header-toolbar"}>
        <Item
          visible={menuToggleEnabled}
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu-button"}
        >
          <Button
            icon="fa-solid fa-bars"
            stylingMode="text"
            onClick={toggleMenu}
            className="menuIcon"
          />
        </Item>
        <Item location={"before"}>
          <Link to="/">
            <img src={logo} alt="Tes logo" className="logo" />
          </Link>
        </Item>
        <Item location={"before"}>
          <Link to="/switchClient" style={{ textDecoration: "none" }}>
            <div className="client">
              <span>{selectedClineNameId?.name}</span>
            </div>
          </Link>
        </Item>
        {divisionSystem === DivisionSystem.SingleOrAll &&
          lstUserDivisionRole !== null &&
          lstUserDivisionRole !== undefined && (
            <Item location={"before"}>
              <div>
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  -{" "}
                </span>
                <DropDownButton
                  id="customDropDown"
                  style={{ marginLeft: 5, width: "10rem" }}
                  text={selectedDivision || lstUserDivisionRole?.name}
                  items={SortObjectByPropName(
                    [lstUserDivisionRole].concat({
                      id: "All",
                      name: t("region-Wide"),
                    })!,
                    "name"
                  )}
                  dropDownOptions={{
                    width: MaxDropdownItemWidthCalculator(
                      SortObjectByPropName(
                        [lstUserDivisionRole].concat({
                          id: "All",
                          name: t("region-Wide"),
                        })!,
                        "name"
                      )
                    ),
                  }}
                  displayExpr="name"
                  onItemClick={(e) => onDivisionDropdown(e)}
                />
              </div>
            </Item>
          )}
        <Item
          location={"before"}
          cssClass={"header-title"}
          text={title}
          visible={!!title}
        />
        <Item
          location={"after"}
          locateInMenu={"auto"}
          menuItemTemplate={"userPanelTemplate"}
        >
          <Button
            className={"user-button authorization"}
            width={"100%"}
            height={"100%"}
            stylingMode={"text"}
          >
            <UserPanel menuMode={"context"} />
          </Button>
        </Item>
        <Template name={"userPanelTemplate"}>
          <UserPanel menuMode={"list"} />
        </Template>
      </Toolbar>
    </header>
  );
};

export default TesHeader;
