//#region imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../components/permission/permision";
import { LogPermissions } from "../../constants/Permissions";
import { useAuth } from "../../contexts/auth";
import { LogAppApiUrl } from "../../environment/routeSettings";
import { NameValue } from "../../types/general/generalTypes";
import { ApplicationName, LogType } from "../../types/log/enums/logEnums";
import { Log } from "../../types/log/logTypes";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { Enum2Array } from "../../utils/enumTools";
import { TesGet } from "../../utils/rest";
import "./logs.scss";
//#endregion imports

const Logs = () => {
  //#region consts
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [initData, setInitData] = useState<Log[]>([new Log()]);
  const [lstLogTypes, setLstLogTypes] = useState<NameValue[]>([]);
  const [lstAppNames, setLstAppNames] = useState<NameValue[]>([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  //#endregion

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstLogTypes(Enum2Array(LogType));
        setLstAppNames(Enum2Array(ApplicationName));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region functions
  async function getInitData() {
    setInitData(await TesGet(LogAppApiUrl() + "/api/tesLogs", true));
  }
  function calculateLogTypeCell(d: any) {
    if (d.data.logType === 1) {
      return (
        <Button
          height={40}
          text={t("error")}
          className="tes-modal-btn-cancel"
          // stylingMode="outlined"
          // type="danger"
        />
      );
    }
    if (d.data.logType === 2) {
      return (
        <Button
          height={40}
          text={t("warning")}
          className="tes-modal-btn-cancel"
          // stylingMode="outlined"
          // type="default"
        />
      );
    }
    if (d.data.logType === 3) {
      return (
        <Button
          height={40}
          text={t("info")}
          className="tes-modal-btn-add"
          // stylingMode="outlined"
          // type="normal"
        />
      );
    }
  }
  //#endregion functions
  return (
    <Permission allowed={[LogPermissions.Log_V]} hasFeedBackElement={true}>
      <React.Fragment>
        <h2 className={"content-block"}>{t("logs")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initData}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.Street.toString()} savingTimeout={500} /> */}
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="logType"
                cellRender={calculateLogTypeCell}
                caption={t("logType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(lstLogTypes, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
              <Column
                alignment="left"
                dataField="applicationName"
                caption={t("applicationName")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(lstAppNames, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
              <Column
                alignment="left"
                dataField="logDate"
                dataType="datetime"
                format="M/d/yyyy, HH:mm"
                caption={t("date")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="userName"
                caption={t("username")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="ip" caption={t("ip")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="pcName" caption={t("pcName")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="tesClientName"
                caption={t("clientName")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="errorMessage"
                caption={t("errorMessage")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="codePlace"
                caption={t("codePlace")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default Logs;
