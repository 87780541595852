import React, { useEffect, useState } from "react";
import { DataGrid } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  Export,
  HeaderFilter,
  Search,
} from "devextreme-react/data-grid";
import "./2.scss";
import { t } from "i18next";
import {
  Justification,
  Justification2,
} from "../../../../../../../../types/trafficStudy/dtos/studyDto";
import {
  ApproachLaneNo,
  FlowConditionType,
} from "../../../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { OnExporting } from "../../../../../../../../utils/dataGridTools";

// props
interface IPros {
  justificationName: string;
  signalWarrants: Justification;
}

const TabTwo = (props: IPros) => {
  const [gridAData, setGridAData] = useState<any[]>([]);
  const [gridBData, setGridBData] = useState<any[]>([]);
  const [selectedJustification, setSelectedJustification] =
    useState<Justification2>();

  useEffect(() => {
    async function fetchMyAPI() {
      var justification1 = props.signalWarrants.justification2.find(
        (x) => x.name === props.justificationName
      );
      setSelectedJustification(justification1);
      let allApproachVolumeObj: any = {};
      allApproachVolumeObj["rowTitle"] = t("volume");
      allApproachVolumeObj["flow100"] = justification1?.majorStreet100Volume;
      allApproachVolumeObj["flow80"] = justification1?.majorStreet80Volume;
      allApproachVolumeObj["total"] = 0;
      let allApproachComplianceObj: any = {};
      allApproachComplianceObj["rowTitle"] = t("compliance");
      allApproachComplianceObj["flow100"] = "100";
      allApproachComplianceObj["flow80"] = "80";
      allApproachComplianceObj["total"] = 0;

      let minorApproachVolumeObj: any = {};
      minorApproachVolumeObj["rowTitle"] = t("volume");
      minorApproachVolumeObj["flow100"] =
        justification1?.crossingMajorStreet100Volume;
      minorApproachVolumeObj["flow80"] =
        justification1?.crossingMajorStreet80Volume;
      minorApproachVolumeObj["total"] = 0;

      let minorApproachComplianceObj: any = {};
      minorApproachComplianceObj["rowTitle"] = t("compliance");
      minorApproachComplianceObj["flow100"] = "100";
      minorApproachComplianceObj["flow80"] = "80";
      minorApproachComplianceObj["total"] = 0;

      justification1?.results.forEach((el) => {
        allApproachVolumeObj[el.hour] = el.majorStreetVolume;
        allApproachVolumeObj["total"] += el.majorStreetVolume;
        allApproachComplianceObj[el.hour] = el.majorApproachCompliance;
        allApproachComplianceObj["total"] += el.majorApproachCompliance;

        minorApproachVolumeObj[el.hour] = el.crossingMajorStreetVolume;
        minorApproachVolumeObj["total"] += el.crossingMajorStreetVolume;

        minorApproachComplianceObj[el.hour] = el.crossingMajorStreetCompliance;
        minorApproachComplianceObj["total"] += el.crossingMajorStreetCompliance;
      });
      setGridAData([allApproachVolumeObj, allApproachComplianceObj]);
      setGridBData([minorApproachVolumeObj, minorApproachComplianceObj]);
    }
    fetchMyAPI();
  }, [props.justificationName, props.signalWarrants]);

  return (
    <React.Fragment>
      <h2 className={"content-block"} style={{ margin: "1rem" }}>
        {t("justification2DelayToCrossTraffic")}
      </h2>
      {selectedJustification?.note && selectedJustification.note !== "" && (
        <p
          style={{
            fontSize: "1rem",
            padding: "0",
            margin: "1.5rem 0 -1.25rem 1rem",
          }}
        >
          <span style={{ fontWeight: "bolder" }}>{t("note")}: </span>
          {selectedJustification?.note}
        </p>
      )}
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <h6
          className={"content-block"}
          style={{ margin: "-1rem 1rem -0.5rem 0.5rem" }}
        >
          {t("aMajorRoadBothApproaches")}
        </h6>
        <div className="upperSummery">
          <label
            style={{ margin: "0rem 1rem 0rem 1.5rem" }}
            className="content-block"
          >
            {t("100satisfied")}:{" "}
            {selectedJustification?.is100Justified ? t("yes") : t("no")}
          </label>
          <label
            style={{ margin: "0rem 1rem 0rem 1.5rem" }}
            className="content-block"
          >
            {t("80satisfied")}:{" "}
            {selectedJustification?.is80Justified ? t("yes") : t("no")}
          </label>
          <label
            style={{ margin: "0rem 1rem 0rem 1.5rem" }}
            className="content-block"
          >
            {t("Justification1And2Fullfiled")}:{" "}
            {selectedJustification?.finalFullfilled}%
          </label>
        </div>
        <DataGrid
          id="grid"
          dataSource={gridAData}
          allowColumnReordering={true}
          showBorders={true}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <Column
            alignment="left"
            dataField="id"
            allowSorting={true}
            caption={t("id")}
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column caption="">
            <Column caption={t("approachLane")}>
              <Column
                minWidth={150}
                dataField="rowTitle"
                caption={t("flowCondition")}
              ></Column>
            </Column>
          </Column>
          <Column caption={t("minRequirements")}>
            <Column
              caption={
                selectedJustification?.approachLaneOnMajor ===
                ApproachLaneNo.One
                  ? "1"
                  : t("twoOrMore")
              }
            >
              <Column
                minWidth={120}
                dataField="flow100"
                caption={
                  selectedJustification?.flowCondition ===
                  FlowConditionType.Free
                    ? t("freeFlow100")
                    : t("restrFlow100")
                }
                format="#,###"
              ></Column>
              <Column
                minWidth={120}
                dataField="flow80"
                caption={
                  selectedJustification?.flowCondition ===
                  FlowConditionType.Free
                    ? t("freeFlow80")
                    : t("restrFlow80")
                }
                format="#,###"
              ></Column>
            </Column>
          </Column>
          <Column caption={t("percentageWarrant")}>
            <Column caption={t("hourEnding")}>
              {selectedJustification?.results?.map((x, i) => {
                return (
                  <Column
                    key={i}
                    dataField={x.hour.toString()}
                    minWidth={65}
                    caption={x.hour.toString()}
                    format="#,###"
                  ></Column>
                );
              })}
            </Column>
          </Column>
          <Column caption="">
            <Column caption="">
              <Column
                minWidth={100}
                dataField="total"
                caption={t("total")}
                format="#,###"
              ></Column>
            </Column>
          </Column>
        </DataGrid>
        <label className="summary">
          {t("partBFullfilled")}: {selectedJustification?.majorStreetFullfilled}
          %
        </label>
      </div>
      <div className="row" style={{ padding: 10 }}>
        <h6
          className={"content-block"}
          style={{ margin: "0rem 1rem 0rem 0rem" }}
        >
          {t("bTrafficCrossingMajorRoad")}
        </h6>
        <DataGrid
          id="grid"
          dataSource={gridBData}
          allowColumnReordering={true}
          showBorders={true}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <Column
            alignment="left"
            dataField="id"
            allowSorting={true}
            caption={t("id")}
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            minWidth={150}
            dataField="rowTitle"
            caption={t("bMinorStreetBothApproaches")}
          ></Column>
          <Column
            minWidth={120}
            dataField="flow100"
            caption={
              selectedJustification?.flowCondition === FlowConditionType.Free
                ? t("freeFlow100")
                : t("restrFlow100")
            }
            format="#,###"
          ></Column>
          <Column
            minWidth={120}
            dataField="flow80"
            caption={
              selectedJustification?.flowCondition === FlowConditionType.Free
                ? t("freeFlow80")
                : t("restrFlow80")
            }
            format="#,###"
          ></Column>
          {selectedJustification?.results?.map((x, i) => {
            return (
              <Column
                key={i}
                dataField={x.hour.toString()}
                minWidth={65}
                caption={x.hour.toString()}
                format="#,###"
              ></Column>
            );
          })}
          <Column
            minWidth={100}
            dataField="total"
            caption={t("total")}
            format="#,###"
          ></Column>
        </DataGrid>
        <label className="summary">
          {t("partBFullfilled")}:{" "}
          {selectedJustification?.crossingMajorStreetFullfilled}%
        </label>
      </div>
    </React.Fragment>
  );
};
export default TabTwo;
