import { useEffect, useMemo, useState } from "react";
import "devexpress-reporting/dx-webdocumentviewer";
import { TClientReport } from "../../../types/report/reportTypes";
import { ReportApiUrl } from "../../../environment/routeSettings";
import { fetchSetup } from "@devexpress/analytics-core/analytics-utils";
import DevexpressReportViewer, {
  RequestOptions,
  Callbacks,
} from "devexpress-reporting-react/dx-report-viewer";
import { Popup } from "devextreme-react/popup";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../../utils/media-query";

interface IPros {
  selectedReport: TClientReport;
  lstSelectedIds: string[];
  payload?: string | null;
  isOpen: boolean;
  endPointURL?: string;
  onClose: () => void;
}
const ReportViewer_v1 = (props: IPros) => {
  const {
    isOpen,
    selectedReport,
    lstSelectedIds,
    payload,
    endPointURL,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { is2xLarge } = useScreenSize();
  const [reportUrl, setReportUrl] = useState("");
  //TODO check the need for height calculation
  const [reportPopupHeight, setReportPopupHeight] = useState<string>("80%");

  fetchSetup.fetchSettings = {
    headers: { "X-CSRF": "1" },
  };

  useEffect(() => {
    if (is2xLarge) {
      setReportPopupHeight("55%");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generatedReportUrl = useMemo(() => {
    if (
      isOpen &&
      selectedReport.id !== undefined &&
      (lstSelectedIds.length > 0 || payload !== undefined)
    ) {
      const url = {
        ReportName: selectedReport.name,
        ReportId: selectedReport.id,
        CustomerId: selectedReport.customerId,
        Token: `Bearer ${localStorage.getItem("tesToken")}`,
        ListIds: lstSelectedIds,
        Payload: payload,
        IsDesigner: false,
        EndPointURL: endPointURL,
      };
      return JSON.stringify(url);
    }
  }, [isOpen, selectedReport, lstSelectedIds, payload]);

  useEffect(() => {
    generatedReportUrl && setReportUrl(generatedReportUrl);
  }, [generatedReportUrl]);

  return (
    <Popup
      visible={isOpen}
      width={"auto"}
      height={reportPopupHeight}
      resizeEnabled={true}
      showTitle={true}
      title={t("reportViewer")}
      hideOnOutsideClick={false}
      showCloseButton={true}
      onHiding={onClose}
    >
      <div>
        {reportUrl && (
          <DevexpressReportViewer reportUrl={reportUrl}>
            <RequestOptions
              host={ReportApiUrl()! + "/"}
              invokeAction="DXXRDV"
            />
            <Callbacks />
          </DevexpressReportViewer>
        )}
      </div>
    </Popup>
  );
};

export default ReportViewer_v1;
