import { MatchingCurveStat, PCSPatternType } from "../enums/trafficStudyEnums";
import {
  MatchingHolidays,
  MatchingResult,
  TrafficSectionAADT,
  TrendingResult,
} from "../trafficStudyTypes";

export class MatchingDetailDTO {
  id!: string;
  name!: string;
  year!: number;
  results!: MatchingResultsDTO[];
  holidays!: MatchingHolidaysDTO[];
}

export class MatchingResultsDTO extends MatchingResult {
  trafficSectionName!: string;
  countStationId!: String;
  countStationName!: string;
  historicalPatternType!: PCSPatternType;
  volumeHistory!: TrafficSectionAADT[];
  curveStats!: MatchingCurveStatDTO[];
  static trafficSectionName: string | undefined;
}

export class MatchingHolidaysDTO extends MatchingHolidays {
  dayOfWeek!: string;
}

export class MatchingCurveStatDTO extends MatchingCurveStat {
  pcsName!: string;
}
