import { t } from "i18next";

const tabTitles = [
  {
    id: 0,
    text: "users",
    icon: "fa-solid fa-users",
    content: "Users",
  },
];
export default tabTitles;
