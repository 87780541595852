import React, { useEffect, useState } from "react";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import notify from "devextreme/ui/notify";
import { Button, NumberBox, TextBox } from "devextreme-react";
import "./signalWarrants.scss";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import { SignalWarrantsSetting } from "../../../../types/trafficStudy/trafficStudyTypes";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import Form, { SimpleItem } from "devextreme-react/form";
import {
  LazyLoadingRequestBase,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const SignalWarrantSettings = () => {
  const [initData, setInitData] = useState<SignalWarrantsSetting>(
    new SignalWarrantsSetting()
  );
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useBrowserTitle({ pathname: "/trafficStudy/setups/signalWarrantSetting" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      var res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/GetCustomerSignalWarrantSetting",
        {
          customerId: localStorage.getItem("selectedCustomerId"),
        } as LazyLoadingRequestBase,
        true
      )) as RequestResponseResult<SignalWarrantsSetting>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  function onValueChange(name: string, value: any) {
    setInitData({ ...initData, [name]: value });
  }

  async function onUpdate() {
    console.log(initData);
    try {
      if (activeLoading) activeLoading(true);
      let postObj: SignalWarrantsSetting = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId")!,
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/AddUpdateSignalWarrantSetting",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.WorkOrderExist) {
        notify(t("workOrderExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`signalWarrants ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <h2 className={"content-block"}>{t("signalWarrants")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div className="row" style={{ marginTop: 15 }}>
                <div className="rightColumn">
                  <Button
                    id="rowButtons"
                    onClick={() => LockHandler()}
                    icon={
                      isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                    }
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">
                  {isLocked === false && (
                    <Button
                      id="rowButtons"
                      onClick={() => onUpdate()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    className="firstLine"
                    label={t("name")}
                    labelMode="floating"
                    value={initData?.name}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("name", e)}
                  ></TextBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="firstLine"
                    label={t("freeFlow1All")}
                    labelMode="floating"
                    value={initData?.freeFlow1All}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("freeFlow1All", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("restrFlow1All")}
                    labelMode="floating"
                    value={initData?.restrFlow1All}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("restrFlow1All", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("freeFlow2All")}
                    labelMode="floating"
                    value={initData?.freeFlow2All}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("freeFlow2All", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("restrFlow2All")}
                    labelMode="floating"
                    value={initData?.restrFlow2All}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("restrFlow2All", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("freeFlow1Minor")}
                    labelMode="floating"
                    value={initData?.freeFlow1Minor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("freeFlow1Minor", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("restrFlow1Minor")}
                    labelMode="floating"
                    value={initData?.restrFlow1Minor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("restrFlow1Minor", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("freeFlow2Minor")}
                    labelMode="floating"
                    value={initData?.freeFlow2Minor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("freeFlow2Minor", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("restrFlow2Minor")}
                    labelMode="floating"
                    value={initData?.restrFlow2Minor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("restrFlow2Minor", e)}
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("freeFlow1CrossingMajorStreet")}
                    labelMode="floating"
                    value={initData?.freeFlow1CrossingMajorStreet}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("freeFlow1CrossingMajorStreet", e)
                    }
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("restrFlow1CrossingMajorStreet")}
                    labelMode="floating"
                    value={initData?.restrFlow1CrossingMajorStreet}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("restrFlow1CrossingMajorStreet", e)
                    }
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("freeFlow2CrossingMajorStreet")}
                    labelMode="floating"
                    value={initData?.freeFlow2CrossingMajorStreet}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("freeFlow2CrossingMajorStreet", e)
                    }
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="lastLine"
                    label={t("restrFlow2CrossingMajorStreet")}
                    labelMode="floating"
                    value={initData?.restrFlow2CrossingMajorStreet}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("restrFlow2CrossingMajorStreet", e)
                    }
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="lastLine"
                    label={t("collisionNoWarrant5")}
                    labelMode="floating"
                    value={initData?.collisionNoWarrant5}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("collisionNoWarrant5", e)
                    }
                  />
                </SimpleItem>

                <SimpleItem>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="lastLine"
                    label={t("hoursNeeded4Justification4")}
                    labelMode="floating"
                    value={initData?.hoursNeeded4Justification4}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("hoursNeeded4Justification4", e)
                    }
                  />
                </SimpleItem>
              </Form>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SignalWarrantSettings;
