import { t } from "i18next";

const tabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "fields",
    icon: "fa-solid fa-location-dot",
    content: "Location tab content",
  },
];
export default tabTitles;
