import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StudyBaseDTO,
  StudyVolumeWebDetailsDTO,
} from "../../../../../../../types/trafficStudy/dtos/studyDto";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  MasterDetail,
  Export,
  SortByGroupSummaryInfo,
  Summary,
  GroupItem,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import { GeneralSetting } from "../../../../../../../contexts/clientSetting";
import DetailTemplate from "./detailTemplate";
import { OnExporting } from "../../../../../../../utils/dataGridTools";

// props
interface IPros {
  isLocked: boolean;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  initDataBase: StudyBaseDTO;
  initDataTmc: StudyVolumeWebDetailsDTO;
  generalSettings: GeneralSetting | null;
}
const Details = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {}
    fetchMyAPI();
  }, [props.initDataBase]);

  return (
    <div
      className={`studyDetails-volume-resultSummary ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <DataGrid
            id="DetailsGrid"
            ref={dataGridRef}
            dataSource={props.initDataTmc.result}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={OnExporting}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />

            <HeaderFilter visible={true} />

            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <SortByGroupSummaryInfo
              summaryItem="Total Count"
              sortOrder="desc"
            />
            <Summary>
              <GroupItem summaryType="count" alignByColumn name="Total Count" />
            </Summary>
            <Column dataField="id" caption={t("id")} visible={false}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="periodName" caption={t("period")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="total" caption={t("total")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <MasterDetail enabled={true} component={DetailTemplate} />
          </DataGrid>
        </div>
      </React.Fragment>
    </div>
  );
};
export default Details;
