//imports
import {
  Button,
  DateBox,
  DropDownButton,
  Form,
  NumberBox,
  SelectBox,
  TextArea,
  TextBox,
  Validator,
} from "devextreme-react";
import { DataGridRef } from "devextreme-react/data-grid";
import { RequiredRule, SimpleItem } from "devextreme-react/form";
import { Switch } from "devextreme-react/switch";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CollapsibleCard from "../../../components/collapsibleCard/collapsibleCard";
import InfrastructureLocationPicker from "../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import Permission from "../../../components/permission/permision";
import {
  CollisionPermissions,
  TMSPermissions,
} from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  AuthApiUrl,
  CollisionApiUrl,
  FieldApiUrl,
} from "../../../environment/routeSettings";
import {
  CustomerCollision,
  ICollisionParams,
  NetworkDriveFiles,
} from "../../../types/collision/collisionTypes";
import { VMMapLocation } from "../../../types/collision/dto/collisionDtos";
import {
  CollisionTesStatus,
  GeoCodeStatus,
  InvolvedAsType,
} from "../../../types/collision/enums/collisionEnums";
import { VMField } from "../../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldPermission,
  FieldServiceType,
  FieldType,
} from "../../../types/field/enums/fieldEnums";
import {
  ModalMode,
  ResponseCode,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { VMLocation } from "../../../types/infrastructure/dto/locationdDto";
import { LocationPickerMode } from "../../../types/infrastructure/enums/infrastructureEnums";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import {
  DatePickerDateCalculator,
  FormatLabelDT,
} from "../../../utils/dateTimeTools";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { Enum2Array } from "../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import Attachments from "./components/attachments/attachments";
import Grids from "./components/grids/grids";
import GridsObject from "./components/gridsObject/gridsObject";
import design from "./geoCoding.module.scss";

const GeoCoding = () => {
  const [initDataFields, setInitDataFields] = useState<VMField[]>([]);
  const [initDataUserDivisions, setInitDataUserDivisions] = useState<INameId[]>(
    []
  );
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);

  const [currentCollision, setCurrentCollision] = useState<CustomerCollision>(
    new CustomerCollision()
  );
  const [tempMapLocation, setTempMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const [tempLocation, setTempLocation] = useState<VMLocation>(
    new VMLocation()
  );
  const [mapLocation, setMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const [relatedCollision, setRelatedCollision] = useState<any>();

  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<ICollisionParams>();
  const { generalSetting } = useClientSetting();
  const dataGridNetworkRef = useRef<DataGridRef<any, any>>(null);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<NetworkDriveFiles>(
    new NetworkDriveFiles()
  );

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));

        await getInitDataCollision(params.collisionId!);
        await getInitialDataFields();
        await getInitDataUserDivisions();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 2500);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitDataCollision(id: string) {
    try {
      const res = (await TesGet(
        CollisionApiUrl() + "/api/Collisions/GetCollisionData/" + id,
        true
      )) as RequestResponseResult<CustomerCollision>;
      if (res.responseCode === ResponseCode.OK) {
        setCurrentCollision(res.results);
        if (res.results?.relatedCollisions !== null) {
          if (
            res.results.relatedCollisions[0] !== null &&
            res.results.relatedCollisions[0] !== undefined &&
            res.results.relatedCollisions[0].CollisionTesStatus ===
              CollisionTesStatus.AmendmentInDB
          ) {
            setShowWarningMessage(true);
            //find the amendment collision (if any)
            setRelatedCollision(res?.results?.relatedCollisions[0]);
          }
        }
        if (res.results.mapLocation != null) {
          setTempMapLocation(res.results.mapLocation);
          setMapLocation(res.results.mapLocation);
          setTempLocation(res.results);
        }
      } else {
        RequestErrorHandling(res);
      }
    } catch (error) {
      notify(t("errorInFetchData"), "error", 5000);
    }
  }

  async function getInitialDataFields() {
    setInitDataFields(
      await TesGet(
        FieldApiUrl() +
          "/api/TesFields/GetFieldsByServiceType/" +
          localStorage.getItem("selectedCustomerId") +
          "/" +
          FieldServiceType.Collision,
        true
      )
    );
  }

  async function getInitDataUserDivisions() {
    const res = await TesGet(
      AuthApiUrl() +
        "/api/divisions/GetUserDivisionUI/" +
        localStorage.getItem("selectedCustomerId"),
      true
    );
    setInitDataUserDivisions(res);
  }

  function onChangeField(name: string, value: any, isUpperCase: boolean) {
    if (name === "divisionId") {
      setCurrentCollision({
        ...currentCollision,
        [name]: value,
      });
    } else {
      setCurrentCollision({
        ...currentCollision,
        general: {
          ...currentCollision.general,
          [name]: isUpperCase ? value.toString().toUpperCase() : value,
        },
      });
    }
  }

  function generateRandom(name: string) {
    var d = new Date();
    const res =
      d.getFullYear() +
      String(d.getMonth() + 1).padStart(2, "0") +
      String(d.getDate()).padStart(2, "0") +
      String(d.getHours()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getSeconds()).padStart(2, "0") +
      String(d.getMilliseconds()).padStart(2, "0");
    onChangeField(name, res, false);
    return res;
  }

  function value2Name(fieldValues: INameId[], value: any) {
    return fieldValues?.find((x) => x.id === value)?.name ?? "";
  }

  function setInfrastructureData(inData: VMLocation) {
    //TODO: Check why it doesn't work with setCurrentCollision it bring the privous value of collision after location update
    setTempLocation(inData);
  }

  async function onSave(geoCodeStatus: GeoCodeStatus) {
    try {
      //const validationRes = validationRef.current?.instance().validate();
      // if (validationRes?.isValid) {
      if (activeLoading) activeLoading(true);
      //refresh the map selectedgeoIds
      if (geoCodeStatus === GeoCodeStatus.Previous) {
        await getPreviousCollision(currentCollision.id);
        if (activeLoading) activeLoading(false);
        return;
      }
      if (geoCodeStatus === GeoCodeStatus.Delete) {
        await TesGet(
          CollisionApiUrl() +
            "/api/Collisions/DeleteCollisionInGeoCoding/" +
            currentCollision.id,
          true
        );
        if (activeLoading) activeLoading(false);
        return;
      }
      var postObj: CustomerCollision = {
        ...currentCollision,
        mapLocation: mapLocation,
        customerId: localStorage.getItem("selectedCustomerId") as string,
        locationId: tempLocation.id,
        locationDescription: tempLocation.description,
        locationType: tempLocation.locationType,
        divisionId: tempLocation.divisionId!,
        geoId: tempLocation.geoId,
        geoCodeStatus: geoCodeStatus,
        updateApproveLevel: false,
        //add the other things
      };
      var res = (await TesPost(
        CollisionApiUrl() + "/api/Collisions/UpdateCollision",
        postObj,
        true
      )) as RequestResponseResult<CustomerCollision>;
      if (res.responseCode === ResponseCode.OK) {
        await getNextCollision(currentCollision.id);
        //  await uploadImages(initDataCollision.id);
      } else {
        setRelatedCollision(new CustomerCollision());
        await RequestErrorHandling(res);
      }
      if (activeLoading) activeLoading(false);
      // }
    } catch (ex) {
      setRelatedCollision(new CustomerCollision());
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function getNextCollision(collisionId: string) {
    var res = (await TesGet(
      CollisionApiUrl() +
        "/api/xmlGeoCode/GetNextCollision2GeoCode/" +
        collisionId,
      true
    )) as RequestResponseResult<CustomerCollision>;
    if (res.responseCode === ResponseCode.OK) {
      setCurrentCollision(res.results);
      if (res.results?.relatedCollisions !== null) {
        if (
          res.results.relatedCollisions[0] !== null &&
          res.results.relatedCollisions[0] !== undefined &&
          res.results.relatedCollisions[0].CollisionTesStatus ===
            CollisionTesStatus.AmendmentInDB
        ) {
          setShowWarningMessage(true);
          //find the amendment collision (if any)
          setRelatedCollision(res?.results?.relatedCollisions[0]);
        }
      }

      if (res.results.mapLocation != null) {
        setTempMapLocation(res.results.mapLocation);
        setMapLocation(res.results.mapLocation);
      }
      window.history.replaceState(
        null,
        "New Page Title",
        "/collision/geoCoding/" + res.results.id
      );
    } else if (res.responseCode === ResponseCode.Collision4GeoCodeNotExists) {
      notify(t("collision4GeoCodeNotExists"), "error", 5000);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getPreviousCollision(collisionId: string) {
    var res = (await TesGet(
      CollisionApiUrl() +
        "/api/xmlGeoCode/GetPreviousCollision2GeoCode/" +
        collisionId,
      true
    )) as RequestResponseResult<CustomerCollision>;
    if (res.responseCode === ResponseCode.OK) {
      setCurrentCollision(res.results);
      if (res.results?.relatedCollisions !== null) {
        if (
          res.results.relatedCollisions[0] !== null &&
          res.results.relatedCollisions[0] !== undefined &&
          res.results.relatedCollisions[0].CollisionTesStatus ===
            CollisionTesStatus.AmendmentInDB
        ) {
          setShowWarningMessage(true);
          //find the amendment collision (if any)
          setRelatedCollision(res?.results?.relatedCollisions[0]);
        }
      }
      if (res.results.mapLocation != null) {
        setTempMapLocation(res.results.mapLocation);
        setMapLocation(res.results.mapLocation);
      }
      window.history.replaceState(
        null,
        "New Page Title",
        "/collision/geoCoding/" + res.results.id
      );
    } else if (res.responseCode === ResponseCode.Collision4GeoCodeNotExists) {
      notify(t("collision4GeoCodeNotExists"), "error", 5000);
    } else {
      await RequestErrorHandling(res);
    }
  }

  return (
    <Permission
      allowed={[
        TMSPermissions.TMS_Admin,
        CollisionPermissions.Collision_GeoCode,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div
          className={`${design.fixedDiv}`}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Button
            icon="fa-solid fa-arrow-up-right-from-square"
            className="tes-modal-btn-add"
            style={{ marginRight: "1rem" }}
            onClick={() =>
              window.open(
                `/collision/collisionDetails/${params.collisionId!}`,
                "_blank"
              )
            }
            hint={t("openCollision")}
          />
          <DropDownButton
            className="tes-modal-btn-add"
            splitButton={true}
            useSelectMode={false}
            text={t("enum-GeoCoded")}
            items={SortObjectByPropName(lstGeoCodeStatus, "name")}
            dropDownOptions={{
              width: MaxDropdownItemWidthCalculator(
                SortObjectByPropName(lstGeoCodeStatus, "name")
              ),
            }}
            displayExpr="name"
            keyExpr="id"
            style={{ borderRadius: "4px", width: "15rem", marginRight: "1rem" }}
            onButtonClick={(x) => onSave(GeoCodeStatus.GeoCoded)}
            onItemClick={(e) => onSave(e.itemData.value)}
          />

          <Button
            style={{ borderRadius: "4px", width: "12rem" }}
            className="tes-modal-btn-add"
            text={t("enum-PrivateProperty")}
            hint={t("enum-PrivateProperty")}
            onClick={(e: any) => onSave(GeoCodeStatus.PrivateProperty)}
          />
        </div>

        <h2 className={`${design.pageTitle} `}>{t("geoCoding")}</h2>
        {showWarningMessage && (
          <div
            style={{
              margin: "-0.5rem 3.25rem 2rem 3.25rem",
              fontWeight: "bolder",
              padding: "0.5rem",
              borderRadius: "0.4rem",
              backgroundColor: "#f7d7d7", // Light red background
              border: "2px solid #E84134", // Dark red border
            }}
          >
            <div>
              <span
                className="fa-solid fa-triangle-exclamation"
                style={{ color: "#E84134", fontSize: "18px" }}
              />
              <span>
                {" "}
                {t(
                  "thisCollisionPossiblyServesAsAnAmendmentToTheFollowingCollision"
                )}
                :{" "}
              </span>
              <span
                style={{ color: "blue", cursor: "pointer" }}
                title={t("click2ViewCollisionDetails")}
                onClick={() =>
                  window.open(
                    `/collision/collisionDetails/${relatedCollision?._id}`,
                    "_blank"
                  )
                }
              >
                {relatedCollision?.AccNumber ? relatedCollision?.AccNumber : ""}{" "}
                {relatedCollision?.LocationDescription &&
                  `- ${relatedCollision?.LocationDescription}`}
                {relatedCollision?.GeoId && `[${relatedCollision?.GeoId}]`}
              </span>
            </div>
            <div>
              <span>{t("conflictingFieldsAreAsFollows")}:</span>
              {currentCollision?.xmlImportMessage !== null ? (
                <span> {currentCollision.xmlImportMessage}</span>
              ) : (
                <span> {t("NoConflictedFields")}</span>
              )}
              <br />
              <span>
                {t(
                  "pleaseReviewTheContentCarefullyAsAnyDataEnteredOnThisPageWillOverwriteExistingInformation"
                )}
              </span>
            </div>
          </div>
        )}
        <div className={"content-block"}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <div
                className={`${design.leftLocationPicker} 'dx-card responsive-paddings'`}
              >
                <InfrastructureLocationPicker
                  renderMap={true}
                  didMount={true}
                  setInfrastructureData={setInfrastructureData}
                  initLocationData={{
                    customerId: currentCollision.customerId,
                    id: currentCollision.locationId,
                  }}
                  tesModule={TesMapModule.CollisionGeoCode}
                  handleLocationChange={setMapLocation}
                  tempMapLocation={tempMapLocation}
                  componentMode={LocationPickerMode.TextBox}
                  selectedMapLocation={mapLocation}
                  renderCoordinate={true}
                  isLocked={false}
                  showIcons={true}
                  DataChanger={currentCollision.id}
                />
              </div>
            </SimpleItem>
            <SimpleItem>
              <div className={`${design.rightCollapsibleCardContainer}`}>
                <CollapsibleCard compact={"condensed"} title={t("general")}>
                  {/* {initDataUserDivisions && initDataUserDivisions.length !== 0 &&
                            <SimpleItem colSpan={1}>
                                <SelectBox
                                    placeholder=""
                                    label={t("division")}
                                    valueExpr={"id"}
                                    displayExpr={"name"}
                                    labelMode='floating'
                                    value={currentCollision.divisionId}
                                    items={SortObjectByPropName(initDataUserDivisions, "name")}
                                    onValueChange={(e) => onChangeField("divisionId", e, false)}
                                    showClearButton={true}
                                    searchEnabled={true}
                                >
                                    <Validator>
                                        <RequiredRule message={t("fieldIsRequired")} />
                                    </Validator>
                                </SelectBox>
                            </SimpleItem>
                        } */}
                  <Form colCount={2}>
                    {(
                      initDataFields &&
                      initDataFields
                        ?.filter(
                          (x) =>
                            (x.fieldCategoryType ===
                              FieldCategoryType.CollisionGeneral ||
                              x.fieldCategoryType ===
                                FieldCategoryType.CollisionRemark) &&
                            x.fieldLocation2Show ===
                              (x.fieldLocation2Show! |
                                FieldLocation2Show.GeoCodingPage)
                        )
                        .sort(
                          (firstItem, secondItem) =>
                            firstItem.geocodingIndex - secondItem.geocodingIndex
                        )
                    ).map(
                      // eslint-disable-next-line array-callback-return
                      (f: VMField, i: number) => {
                        if (f.fieldType === FieldType.String) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <TextBox
                                  className={`${design.fields}`}
                                  label={f.labelText}
                                  labelMode="floating"
                                  key={i}
                                  value={
                                    currentCollision.general &&
                                    currentCollision.general[f.name]
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, f.isUpperCase)
                                  }
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </TextBox>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {currentCollision.general &&
                                      currentCollision.general[f.name]}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }

                        if (f.fieldType === FieldType.Number) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <NumberBox
                                  className={`${design.fields}`}
                                  step={0}
                                  label={f.labelText}
                                  labelMode="floating"
                                  key={i}
                                  value={
                                    currentCollision.general &&
                                    currentCollision.general[f.name]
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, f.isUpperCase)
                                  }
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </NumberBox>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {currentCollision.general &&
                                      currentCollision.general[f.name]}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }
                        if (f.fieldType === FieldType.Boolean) {
                          return (
                            <SimpleItem
                              colSpan={1}
                              cssClass="geoCodingSwitchBox"
                            >
                              <div className={`${design.switchTitle}`}>
                                {f.labelText}
                              </div>
                              <Switch
                                value={
                                  currentCollision.general &&
                                  currentCollision.general[f.name]
                                }
                                onValueChange={(e) =>
                                  onChangeField(f.name, e, f.isUpperCase)
                                }
                                hint={f.labelText}
                              />
                            </SimpleItem>
                          );
                        }
                        if (f.fieldType === FieldType.Date) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <DateBox
                                  className={`${design.fields}`}
                                  type="date"
                                  label={f.labelText}
                                  value={
                                    currentCollision.general &&
                                    (f.exactDT === true
                                      ? DatePickerDateCalculator(
                                          currentCollision.general[f.name]
                                        )
                                      : currentCollision.general[f.name])
                                  }
                                  dateSerializationFormat={
                                    f.exactDT === true
                                      ? "yyyy-MM-ddTHH:mm:ss"
                                      : undefined
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, false)
                                  }
                                  pickerType="calendar"
                                  placeholder={generalSetting?.dateFormat}
                                  displayFormat={generalSetting?.dateFormat}
                                  useMaskBehavior={true}
                                  openOnFieldClick={true}
                                  showClearButton={true}
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </DateBox>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {currentCollision.general &&
                                      FormatLabelDT(
                                        currentCollision.general[f.name],
                                        f,
                                        generalSetting
                                      )}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }
                        if (f.fieldType === FieldType.Time) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <DateBox
                                  className={`${design.fields}`}
                                  type="time"
                                  label={f.labelText}
                                  value={
                                    currentCollision.general &&
                                    (f.exactDT === true
                                      ? DatePickerDateCalculator(
                                          currentCollision.general[f.name]
                                        )
                                      : currentCollision.general[f.name])
                                  }
                                  dateSerializationFormat={
                                    f.exactDT === true
                                      ? "yyyy-MM-ddTHH:mm:ss"
                                      : undefined
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, false)
                                  }
                                  pickerType="rollers"
                                  placeholder={generalSetting?.timeFormat}
                                  displayFormat={generalSetting?.timeFormat}
                                  useMaskBehavior={true}
                                  openOnFieldClick={true}
                                  showClearButton={true}
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </DateBox>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {currentCollision.general &&
                                      FormatLabelDT(
                                        currentCollision.general[f.name],
                                        f,
                                        generalSetting
                                      )}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }
                        if (f.fieldType === FieldType.DateTime) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <DateBox
                                  className={`${design.fields}`}
                                  type="datetime"
                                  label={f.labelText}
                                  value={
                                    currentCollision.general &&
                                    (f.exactDT === true
                                      ? DatePickerDateCalculator(
                                          currentCollision.general[f.name]
                                        )
                                      : currentCollision.general[f.name])
                                  }
                                  dateSerializationFormat={
                                    f.exactDT === true
                                      ? "yyyy-MM-ddTHH:mm:ss"
                                      : undefined
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, false)
                                  }
                                  pickerType="calendar"
                                  placeholder={generalSetting?.dateTimeFormat}
                                  displayFormat={generalSetting?.dateTimeFormat}
                                  useMaskBehavior={true}
                                  openOnFieldClick={true}
                                  showClearButton={true}
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </DateBox>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {currentCollision.general &&
                                      FormatLabelDT(
                                        currentCollision.general[f.name],
                                        f,
                                        generalSetting
                                      )}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }
                        if (f.fieldType === FieldType.List) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <SelectBox
                                  className={`${design.fields}`}
                                  placeholder=""
                                  label={f.labelText}
                                  valueExpr="id"
                                  displayExpr="name"
                                  value={
                                    currentCollision.general &&
                                    currentCollision.general[f.name]
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, f.isUpperCase)
                                  }
                                  items={SortObjectByPropName(
                                    f.fieldValues,
                                    "name"
                                  )}
                                  showClearButton={true}
                                  searchEnabled={true}
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </SelectBox>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {value2Name(
                                      f.fieldValues,
                                      currentCollision.general &&
                                        currentCollision.general[f.name]
                                    )}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }
                        if (f.fieldType === FieldType.AutoGenerate) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <TextBox
                                  className={`${design.fields}`}
                                  label={f.labelText}
                                  value={
                                    currentCollision.general != null
                                      ? currentCollision.general[f.name]
                                      : generateRandom(f.name)
                                  }
                                />
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <SimpleItem colSpan={1}>
                                <p className={`${design.filedParagraph}`}>
                                  {f.labelText}:
                                  <span className={`${design.fieldSpan}`}>
                                    {" "}
                                    {currentCollision.general &&
                                      currentCollision.general[f.name]}
                                  </span>
                                </p>
                              </SimpleItem>
                            );
                          }
                        }
                        if (f.fieldType === FieldType.TextArea) {
                          if (
                            !(
                              f.permission === FieldPermission.View ||
                              f.readOnlyField
                            )
                          ) {
                            return (
                              <SimpleItem colSpan={1}>
                                <TextArea
                                  className={`${design.fields}`}
                                  label={f.labelText}
                                  minHeight={200}
                                  value={
                                    currentCollision.general &&
                                    currentCollision.general[f.name]
                                  }
                                  onValueChange={(e) =>
                                    onChangeField(f.name, e, f.isUpperCase)
                                  }
                                >
                                  {f.isRequired && (
                                    <Validator>
                                      <RequiredRule
                                        message={t("fieldIsRequired")}
                                      />
                                    </Validator>
                                  )}
                                </TextArea>
                              </SimpleItem>
                            );
                          } else {
                            return (
                              <p className={`${design.filedParagraph}`}>
                                {f.labelText}:
                                <span className={`${design.fieldSpan}`}>
                                  {" "}
                                  {currentCollision.general &&
                                    currentCollision.general[f.name]}
                                </span>
                              </p>
                            );
                          }
                        }
                      }
                    )}
                  </Form>
                </CollapsibleCard>
              </div>
            </SimpleItem>
          </Form>

          <CollapsibleCard title={t("attachments")} compact={"textCompact"}>
            <div className={`${design.attachments}`}>
              <Attachments initDataCollision={currentCollision} />
            </div>
          </CollapsibleCard>
          {currentCollision?.roads !== null &&
            currentCollision?.roads !== undefined &&
            currentCollision?.roads.length != 0 && (
              <CollapsibleCard compact={"condensedGrid"} title={t("roads")}>
                <Grids
                  initData={currentCollision.roads}
                  initDataCollision={currentCollision}
                  initDataFields={initDataFields.filter(
                    (x) =>
                      x.fieldCategoryType === FieldCategoryType.CollisionRoad
                  )}
                  generalSettings={generalSetting}
                  fieldSection={"roads"}
                  title={t("road")}
                  lstFieldName={"roadFields"}
                  setInitDataCollision={setCurrentCollision}
                />
              </CollapsibleCard>
            )}
          <CollapsibleCard compact={"condensedGrid"} title={t("vehicles")}>
            <Grids
              initData={currentCollision.vehicles}
              initDataCollision={currentCollision}
              initDataFields={initDataFields.filter(
                (x) =>
                  x.fieldCategoryType === FieldCategoryType.CollisionVehicle
              )}
              generalSettings={generalSetting}
              fieldSection={"vehicles"}
              lstFieldName={"vehicleFields"}
              title={t("vehicle")}
              setInitDataCollision={setCurrentCollision}
            />
          </CollapsibleCard>
          <CollapsibleCard compact={"condensedGrid"} title={t("drivers")}>
            <Grids
              initData={currentCollision.persons.filter(
                (x) => x.InvolvedAs === InvolvedAsType.Driver
              )}
              initDataCollision={currentCollision}
              initDataFields={initDataFields.filter(
                (x) =>
                  x.fieldCategoryType === FieldCategoryType.CollisionPerson &&
                  x.isShowInDriver
              )}
              generalSettings={generalSetting}
              fieldSection={"persons"}
              involvesAs={InvolvedAsType.Driver}
              lstFieldName={"driverFields"}
              title={t("driver")}
              setInitDataCollision={setCurrentCollision}
            />
          </CollapsibleCard>
          <CollapsibleCard compact={"condensedGrid"} title={t("passengers")}>
            <Grids
              initData={currentCollision.persons.filter(
                (x) => x.InvolvedAs === InvolvedAsType.Passenger
              )}
              initDataCollision={currentCollision}
              initDataFields={initDataFields.filter(
                (x) =>
                  x.fieldCategoryType === FieldCategoryType.CollisionPerson &&
                  x.isShowInPassenger
              )}
              generalSettings={generalSetting}
              fieldSection={"persons"}
              lstFieldName={"passengerFields"}
              title={t("passenger")}
              involvesAs={InvolvedAsType.Passenger}
              setInitDataCollision={setCurrentCollision}
            />
          </CollapsibleCard>
          <CollapsibleCard compact={"condensedGrid"} title={t("pedestrians")}>
            <Grids
              initData={currentCollision.persons.filter(
                (x) => x.InvolvedAs === InvolvedAsType.Pedestrian
              )}
              initDataCollision={currentCollision}
              initDataFields={initDataFields.filter(
                (x) =>
                  x.fieldCategoryType ===
                    FieldCategoryType.CollisionPedestrian &&
                  x.isShowInPedestrian
              )}
              generalSettings={generalSetting}
              fieldSection={"persons"}
              lstFieldName={"pedestrianFields"}
              title={t("pedestrian")}
              involvesAs={InvolvedAsType.Pedestrian}
              setInitDataCollision={setCurrentCollision}
            />
          </CollapsibleCard>
          <CollapsibleCard compact={"condensedGrid"} title={t("people")}>
            <Grids
              initData={currentCollision.persons}
              initDataCollision={currentCollision}
              initDataFields={initDataFields.filter(
                (x) => x.fieldCategoryType === FieldCategoryType.CollisionPerson
              )}
              generalSettings={generalSetting}
              fieldSection={"persons"}
              lstFieldName={"personFields"}
              title={t("person")}
              setInitDataCollision={setCurrentCollision}
            />
          </CollapsibleCard>
          <CollapsibleCard
            compact={"condensedGrid"}
            title={t("networkDriveFiles")}
          >
            <GridsObject
              initDataCollision={currentCollision}
              generalSettings={generalSetting}
              setInitDataCollision={setCurrentCollision}
              title={t("networkDriveFiles")}
            />
          </CollapsibleCard>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default GeoCoding;
