import { Button, DateBox, Form, SelectBox, TagBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useClientSetting } from "../../../../contexts/clientSetting";
import {
  FieldApiUrl,
  TrafficApiUrl,
} from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../types/general/generalTypes";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import {
  AggregationInterval,
  StudyCategory,
  StudyStatus,
  StudyType,
} from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { TrafficIntegrationRequestExport } from "../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";

const Export = () => {
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const [exportData, setExportData] = useState<TrafficIntegrationRequestExport>(
    {
      ...new TrafficIntegrationRequestExport(),
      fromDate: new Date(),
      toDate: new Date(),
      paging: {
        pageSize: 50,
        pageNumber: 1,
      },
    }
  );
  const { generalSetting } = useClientSetting();
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const [lstStudyCategory, setLstStudyCategory] = useState<NameValue[]>([]);
  const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
  const [lstIntervals, setLstIntervals] = useState<NameValue[]>([]);
  const { customerDivisions } = useClientSetting();
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        getInfrastructureTesCodeValues();
        setLstLocationType(Enum2Array(LocationType));
        setLstStudyType(Enum2Array(StudyType));
        setLstStudyCategory(Enum2Array(StudyCategory));
        setLstStudyStatus(Enum2Array(StudyStatus));
        setLstIntervals(Enum2Array(AggregationInterval));
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function onExport() {
    if (!exportData.fromDate || !exportData.toDate) {
      notify(t("dateFieldsCantBeEmpty"), "error", 5000);
      return;
    }
    if (exportData.fromDate >= exportData.toDate) {
      notify(t("fromDateMustBeLessThanToDate"), "error", 5000);
      return;
    }

    const res = await TesPost(
      TrafficApiUrl() + "/api/ExportIntegratedDatas/ExportTrafficData2Excel",
      exportData,
      true
    );
    if (res.responseCode === ResponseCode.OK) {
      notify(t("exportResMessage"), "success", 5000);
      setExportData({
        ...new TrafficIntegrationRequestExport(),
        fromDate: new Date(),
        toDate: new Date(),
      });
    } else {
      await RequestErrorHandling(res);
    }
  }

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <div className={`copySetting ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        <h2 className={"content-block"}>{t("exportTrafficIntegration")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card"} style={{ padding: "0.5rem 1rem" }}>
            <Form colCount={3} className="popupFields">
              <SimpleItem colSpan={1}>
                <TagBox
                  label={t("divisions")}
                  items={SortObjectByPropName(customerDivisions!, "name")}
                  valueExpr="id"
                  displayExpr="name"
                  value={exportData.divisionIds}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      divisionIds: e,
                    });
                  }}
                  searchEnabled={true}
                  style={{ marginBottom: "5px" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TagBox
                  label={t("municipalities")}
                  dataSource={SortObjectByPropName(
                    initDataTesCodeValues?.municipalities,
                    "name"
                  )}
                  valueExpr="id"
                  displayExpr="name"
                  value={exportData.municipalityIds}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      municipalityIds: e,
                    });
                  }}
                  searchEnabled={true}
                  style={{ marginBottom: "5px" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TagBox
                  label={t("jurisdictions")}
                  dataSource={SortObjectByPropName(
                    initDataTesCodeValues?.jurisdictions,
                    "name"
                  )}
                  valueExpr="id"
                  displayExpr="name"
                  value={exportData.jurisdictionIds}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      jurisdictionIds: e,
                    });
                  }}
                  searchEnabled={true}
                  style={{ marginBottom: "5px" }}
                />
              </SimpleItem>
              {/* <SimpleItem colSpan={1}>
                                    LocationIdsPicker
                                </SimpleItem> */}
              <SimpleItem colSpan={1}>
                <DateBox
                  label={t("fromDate")}
                  type="date"
                  value={exportData.fromDate}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      fromDate: e as Date,
                    });
                  }}
                  pickerType="calendar"
                  placeholder={generalSetting?.dateFormat}
                  displayFormat={generalSetting?.dateFormat}
                  useMaskBehavior={true}
                  openOnFieldClick={true}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <DateBox
                  label={t("toDate")}
                  type="date"
                  value={exportData.toDate}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      toDate: e as Date,
                    });
                  }}
                  pickerType="calendar"
                  placeholder={generalSetting?.dateFormat}
                  displayFormat={generalSetting?.dateFormat}
                  useMaskBehavior={true}
                  openOnFieldClick={true}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("locationType")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={exportData.locationType}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      locationType: e,
                    });
                  }}
                  items={SortObjectByPropName(lstLocationType, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("studyType")}
                  labelMode="floating"
                  value={exportData.studyType}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      studyType: e,
                    });
                  }}
                  items={SortObjectByPropName(lstStudyType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                  showClearButton={true}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("category")}
                  labelMode="floating"
                  value={exportData.category}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      category: e,
                    });
                  }}
                  items={SortObjectByPropName(lstStudyCategory, "name")}
                  valueExpr="value"
                  displayExpr="name"
                  showClearButton={true}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TagBox
                  label={t("emails")}
                  placeholder={t("enterEmails")}
                  value={exportData.emails}
                  acceptCustomValue={true} // Allows custom email input
                  applyValueMode="instantly" // Updates immediately
                  searchEnabled={true} // Enables filtering entered emails
                  hideSelectedItems={false}
                  onValueChange={(e) => {
                    console.log("User entered:", e); // Debugging line

                    // Filter valid emails
                    const validEmails = e.filter((email) =>
                      validateEmail(email)
                    );

                    // Notify user if some emails are invalid
                    if (validEmails.length !== e.length) {
                      notify(t("invalidEmailMessage"), "error", 3000);
                    }

                    setExportData({
                      ...exportData,
                      emails: validEmails,
                    });
                  }}
                />
              </SimpleItem>

              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("status")}
                  labelMode="floating"
                  value={exportData.status}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      status: e,
                    });
                  }}
                  items={SortObjectByPropName(lstStudyStatus, "name")}
                  valueExpr="value"
                  displayExpr="name"
                  showClearButton={true}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("interval")}
                  valueExpr="value"
                  displayExpr="name"
                  labelMode="floating"
                  value={exportData.interval}
                  onValueChange={(e) => {
                    setExportData({
                      ...exportData,
                      interval: e,
                    });
                  }}
                  items={SortObjectByPropName(lstIntervals, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
            </Form>
            <Button
              style={{ marginTop: "0.5rem" }}
              className="tes-modal-btn-add rightColumn"
              onClick={(e) => onExport()}
              text={t("export")}
            />
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Export;
