//imports
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";

import { TextArea } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import TextBox from "devextreme-react/text-box";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import {
  FieldServiceType,
  FieldType,
  SimplifiedFieldCategory,
} from "../../../../types/field/enums/fieldEnums";
import {
  SimplifiedField,
  TesFieldRowType,
} from "../../../../types/field/fieldType";
import { ModalMode } from "../../../../types/general/enums/generalEnums";
import { TesGet, TesPost } from "../../../../utils/rest";
import "./tesFields.scss";

import Permission from "../../../../components/permission/permision";
import Rule from "../../../../components/rule/Rule";
import { InfrastructurePermissions } from "../../../../constants/Permissions";
import { FieldApiUrl } from "../../../../environment/routeSettings";
import { VMField } from "../../../../types/field/dto/fieldDTO";
import { NameValue } from "../../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { useScreenSize } from "../../../../utils/media-query";
import { Rule2View } from "../../../../utils/simplifiedFieldTools";
import data from "./data";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const RoadSegmentTesFields = () => {
  const staticData = data;
  const [initData, setInitData] = useState<SimplifiedField[]>([]);
  const [addObj, setAddObj] = useState<SimplifiedField>(new SimplifiedField());
  const [updateObj, setUpdateObj] = useState<SimplifiedField>(
    new SimplifiedField()
  );
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dataGridEditRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
  const [secondModalMode, setSecondModalMode] = useState<ModalMode>(
    ModalMode.Add
  );
  const [selectedData, setSelectedData] = useState<SimplifiedField[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<TesFieldRowType>(
    new TesFieldRowType()
  );
  const [selectedRowIndexOnList, setSelectedRowIndexOnList] = useState(0);
  const [lstFiledType, setLstFiledType] = useState<NameValue[]>([]);
  const [lstSimplifiedFieldCategory, setLstSimplifiedFieldCategory] = useState<
    NameValue[]
  >([]);
  const [initDataFields, setInitDataFields] = useState<VMField[]>([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [TesEnumPopupHeight, setTesEnumPopupHeight] = useState<string>("85%");
  const [TesEnumGridHeight, setTesEnumGridHeight] = useState<number>(380);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useBrowserTitle({
    pathname: "/infrastructure/settings/roadSegmentTesFields",
  });

  useEffect(() => {
    if (isXLarge) {
      setTesEnumPopupHeight("80%");
      setTesEnumGridHeight(530);
    } else if (is2xLarge) {
      setTesEnumPopupHeight("auto");
      setTesEnumGridHeight(900);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstFiledType(Enum2Array(FieldType));
        setLstSimplifiedFieldCategory(Enum2Array(SimplifiedFieldCategory));
        await getInitData();
        await getInitialFieldData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    setInitData(
      await TesGet(
        FieldApiUrl() +
          "/api/SimplifiedFields/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const getInitialFieldData = async () => {
    let res = await TesGet(
      FieldApiUrl() +
        "/api/TesFields/GetFieldsByServiceType/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.Infrastructure,
      true
    );
    setInitDataFields(res);
  };

  const onValueChanged = (name: string, value: any) => {
    let items = [...selectedData];
    let item = { ...selectedData[selectedRowIndexOnList] };
    item = { ...item, rule: value };
    items[selectedRowIndexOnList] = item;
    setSelectedData(items);
  };

  const onValueChangedAddObj = (name: string, value: any) => {
    secondModalMode === ModalMode.Add
      ? setAddObj({ ...addObj, rule: value })
      : setUpdateObj({ ...updateObj, rule: value });
  };

  function onCloseModal() {
    setShowModal(false);
  }

  async function onSave() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: SimplifiedField[] = [];
      selectedData.forEach((x, index) => {
        let newObj: SimplifiedField = {
          ...x,
          customerId: localStorage.getItem("selectedCustomerId")!,
          simplifiedTesField: selectedRowData.simplifiedTesField,
          fieldType: selectedRowData.fieldType,
          simplifiedFieldCategory: selectedRowData.simplifiedFieldCategory,
        };
        postObj.push(newObj);
      });
      await TesPost(
        FieldApiUrl() + "/api/SimplifiedFields/AddUpdateSimplifiedField",
        postObj,
        true
      );
      await getInitData();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
      console.log("Post PBH" + JSON.stringify(postObj));
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function clickRow(e: any) {
    setShowModal(true);
    setSelectedRowData(e.data);
    if (e.data?.fieldType !== FieldType.TesEnum) {
      setSelectedData(
        initData.filter(
          (x) => x.simplifiedTesField === e.data.simplifiedTesField
        )
      );
    } else {
      let newArr: SimplifiedField[] = [];
      staticData
        .filter(
          (x) =>
            x.fieldType === FieldType.TesEnum &&
            x.simplifiedTesField === e.data.simplifiedTesField
        )[0]
        .values?.forEach((y) => {
          let newObj = new SimplifiedField();
          newObj.id =
            initData.find(
              (x) =>
                x.fieldValue === y.value.toString() &&
                x.simplifiedTesField === e.data.simplifiedTesField
            )?.id ?? "";
          newObj.fieldValue = y.value.toString();
          newObj.displayFieldValue = y.name;
          newObj.rule =
            initData.find(
              (x) =>
                x.fieldValue === y.value.toString() &&
                x.simplifiedTesField === e.data.simplifiedTesField
            )?.rule ?? "";
          newArr.push(newObj);
        });
      setSelectedData(newArr);
    }
    setSelectedRowIndexOnList(0);
  }

  function onAdd() {
    setAddObj({
      ...addObj,
      customerId: localStorage.getItem("selectedCustomerId")!,
      simplifiedTesField: selectedRowData.simplifiedTesField,
      fieldType: selectedRowData.fieldType,
      simplifiedFieldCategory: selectedRowData.simplifiedFieldCategory,
    });
    setSelectedData([...selectedData, addObj]);
    setAddObj(new SimplifiedField());
    setShowAddUpdateModal(false);
  }

  function onSecondModalEdit(e: any) {
    setUpdateObj(e.row.data);
    setSecondModalMode(ModalMode.Edit);
    setShowAddUpdateModal(true);
  }

  function onSecondModalUpdate() {
    if (selectedRowData.fieldType === FieldType.TesEnum) {
      setSelectedData(
        selectedData.map((x) => {
          return x.fieldValue === updateObj.fieldValue ? updateObj : x;
        })
      );
    } else {
      setSelectedData(
        selectedData.map((x) => {
          return x.id === updateObj.id ? updateObj : x;
        })
      );
    }
    setUpdateObj(new SimplifiedField());
    setShowAddUpdateModal(false);
  }

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Setups]}
      hasFeedBackElement={true}
    >
      <div
        className={`roadSegmentTesFiled ${
          compactViewModel ? "compactStyle" : ""
        }`}
      >
        <React.Fragment>
          <h2 className={"content-block"}>{t("roadSegmentTesFields")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={staticData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={(e) => clickRow(e)}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <TotalItem column="GeoId" summaryType="count" />
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  allowSorting={true}
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="name" caption={t("name")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fieldType"
                  alignment="left"
                  caption={t("fieldType")}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstFiledType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="simplifiedFieldCategory"
                  alignment="left"
                  caption={t("simplifiedFieldCategory")}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(
                      lstSimplifiedFieldCategory,
                      "name"
                    )}
                    valueExpr="value"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                {/* <Column
                type="buttons"
                caption={t("actions")}
                fixed={true}
                fixedPosition="right"
                width={110}
              >
                <DevExpressButton
                  hint={t("edit")}
                   onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
              </Column> */}
              </DataGrid>
            </div>
          </div>

          <Popup
            width={selectedRowData?.fieldType < 7 ? "55%" : "80%"}
            height={
              selectedRowData?.fieldType < 7 ? "auto" : TesEnumPopupHeight
            }
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("edit")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              {selectedRowData?.fieldType < 7 ? (
                <div>
                  <Form colCount={15}>
                    <SimpleItem colSpan={is2xLarge ? 14 : 12}>
                      <label style={{ fontSize: "14px" }}>{t("rule")}:</label>
                      <TextArea
                        style={{ marginTop: "1rem" }}
                        autoResizeEnabled={true}
                        minHeight={70}
                        readOnly={true}
                        value={Rule2View(selectedData[0]?.rule, initDataFields)}
                      />
                    </SimpleItem>
                    {!is2xLarge && <SimpleItem colSpan={2}></SimpleItem>}
                    <SimpleItem colSpan={1}>
                      <div
                        className="rightColumn"
                        style={{ marginTop: "3.25rem" }}
                      >
                        <Rule
                          name="rule"
                          onValueChange={onValueChanged}
                          defaultRuleValue={selectedData[0]?.rule}
                          fieldServiceType={FieldServiceType.Infrastructure}
                          simplifiedFieldCategory={
                            SimplifiedFieldCategory.SimplifiedRoadSegment
                          }
                        />
                      </div>
                    </SimpleItem>
                  </Form>
                </div>
              ) : (
                <div style={{ marginBottom: "1rem" }}>
                  <div className="rightColumn">
                    {selectedRowData?.fieldType !== FieldType.TesEnum && (
                      <Button
                        className="tes-modal-btn-add rightColumn"
                        onClick={() => {
                          setAddObj(new SimplifiedField());
                          setShowAddUpdateModal(true);
                          setSecondModalMode(ModalMode.Add);
                        }}
                        icon="fa-solid fa-circle-plus"
                        text={t("add")}
                        hint={t("add")}
                      ></Button>
                    )}
                    <Form colCount={2}>
                      <SimpleItem colSpan={2}>
                        <DataGrid
                          dataSource={selectedData}
                          ref={dataGridEditRef}
                          rowAlternationEnabled={true}
                          showBorders={true}
                          hoverStateEnabled={true}
                          remoteOperations={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          columnAutoWidth={true}
                          height={
                            selectedRowData?.fieldType < 7
                              ? "auto"
                              : TesEnumGridHeight
                          }
                          onExporting={OnExporting}
                        >
                          <Export
                            enabled={true}
                            allowExportSelectedData={true}
                          />
                          <Grouping
                            contextMenuEnabled={true}
                            autoExpandAll={false}
                          />
                          <GroupPanel visible={true} /> {/* or "auto" */}
                          <FilterPanel visible={true} />
                          <FilterBuilderPopup position={"top"} />
                          <Paging enabled={true} defaultPageSize={100} />
                          <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                          />
                          <FilterRow visible={true} applyFilter="auto" />
                          <HeaderFilter visible={true} />
                          <SearchPanel
                            visible={true}
                            width={285}
                            placeholder={t("search")}
                          />
                          <SortByGroupSummaryInfo
                            summaryItem="Total Count"
                            sortOrder="desc"
                          />
                          <Summary>
                            <GroupItem
                              summaryType="count"
                              alignByColumn
                              name="Total Count"
                            />
                          </Summary>
                          <ColumnChooser
                            width={350}
                            height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                          >
                            <Search enabled />
                          </ColumnChooser>
                          <Column
                            dataField="fieldValue"
                            caption={t("fieldValue")}
                            visible={true}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                            {selectedRowData.fieldType ===
                              FieldType.TesEnum && (
                              <Lookup
                                dataSource={SortObjectByPropName(
                                  selectedRowData.values,
                                  "name"
                                )}
                                valueExpr="value"
                                displayExpr="name"
                              />
                            )}
                          </Column>
                          <Column
                            caption={t("rule")}
                            visible={true}
                            calculateCellValue={(x: { rule: string }) =>
                              Rule2View(x.rule, initDataFields)
                            }
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            type="buttons"
                            caption={t("actions")}
                            fixed={true}
                            fixedPosition="right"
                            width={110}
                          >
                            <DevExpressButton
                              hint={t("edit")}
                              onClick={onSecondModalEdit}
                              icon="fa-solid fa-pencil"
                            ></DevExpressButton>
                          </Column>
                        </DataGrid>
                      </SimpleItem>
                    </Form>
                  </div>
                </div>
              )}
              <div className="rightColumn" style={{ marginTop: "0.5rem" }}>
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onSave}
                  text={t("save")}
                />
              </div>
            </ScrollView>
          </Popup>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showAddUpdateModal}
            resizeEnabled={true}
            showTitle={true}
            title={secondModalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowAddUpdateModal(false)}
          >
            <ScrollView width="100%" height="100%">
              <div>
                <Form colCount={11}>
                  <SimpleItem colSpan={9}>
                    <label style={{ fontSize: "14px" }}>{t("rule")}:</label>
                    <TextArea
                      style={{ marginTop: "1rem" }}
                      autoResizeEnabled={true}
                      minHeight={70}
                      readOnly={true}
                      value={
                        secondModalMode === ModalMode.Add
                          ? Rule2View(addObj.rule, initDataFields)
                          : Rule2View(updateObj.rule, initDataFields)
                      }
                      onChange={() =>
                        secondModalMode === ModalMode.Add
                          ? setAddObj({
                              ...addObj,
                              rule: Rule2View(addObj.rule, initDataFields),
                            })
                          : setAddObj({
                              ...updateObj,
                              rule: Rule2View(updateObj.rule, initDataFields),
                            })
                      }
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={2}>
                    <div
                      className="leftColumn "
                      style={{ marginTop: "3.25rem" }}
                    >
                      <Rule
                        name="rule"
                        onValueChange={onValueChangedAddObj}
                        defaultRuleValue={
                          secondModalMode === ModalMode.Add
                            ? addObj.rule
                            : updateObj.rule
                        }
                        fieldServiceType={FieldServiceType.Infrastructure}
                        simplifiedFieldCategory={
                          SimplifiedFieldCategory.SimplifiedRoadSegment
                        }
                      />
                    </div>
                  </SimpleItem>
                  <SimpleItem colSpan={5}>
                    <TextBox
                      label={t("fieldValue")}
                      labelMode="floating"
                      value={
                        secondModalMode === ModalMode.Add
                          ? addObj.fieldValue
                          : updateObj.fieldValue
                      }
                      onValueChange={(e) => {
                        secondModalMode === ModalMode.Add
                          ? setAddObj({ ...addObj, fieldValue: e })
                          : setUpdateObj({ ...updateObj, fieldValue: e });
                      }}
                      className="modalInput"
                      disabled={
                        secondModalMode === ModalMode.Edit &&
                        updateObj.fieldType === FieldType.TesEnum
                          ? true
                          : false
                      }
                    />
                  </SimpleItem>
                </Form>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => setShowAddUpdateModal(false)}
                    text={t("cancel")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={
                      secondModalMode === ModalMode.Add
                        ? onAdd
                        : onSecondModalUpdate
                    }
                    text={
                      secondModalMode === ModalMode.Add ? t("add") : t("update")
                    }
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default RoadSegmentTesFields;
