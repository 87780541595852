import React, { useState } from "react";
import ScrollView from "devextreme-react/scroll-view";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button, Popup } from "devextreme-react";
import { t } from "i18next";

interface IPros {
  url: string;
}

const ShareURL = (props: IPros) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Button
        className="abc"
        onClick={() => setShowModal(true)}
        icon={"fa-solid fa-share-nodes"}
        hint={t("shareURL")}
        style={{ marginRight: 10, marginTop: 3 }}
      />
      <Popup
        visible={showModal}
        width={"40%"}
        height={"auto"}
        resizeEnabled={true}
        showTitle={true}
        title={t("shareURL")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowModal(false)}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <p className="title">
                {t("linkSharingInfo")} <a href={props.url}>{props.url}</a>
              </p>
            </SimpleItem>
          </Form>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => setShowModal(false)}
                text={t("Close")}
              />

              <Button
                className="tes-modal-btn-add"
                onClick={() => navigator.clipboard.writeText(props.url)}
                text={t("Copy")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};

export default ShareURL;
