//#region imports
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TesGet, TesPost, TesPut, TesPutUploadFile } from "../../../utils/rest";
import Details from "./components/details/details";
import tabTitles from "./data/index";

import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { MaintenancePermissions } from "../../../constants/Permissions";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { MaintenanceApiUrl } from "../../../environment/routeSettings";
import {
  PersonnelName,
  VMCost,
  VMEquipment,
  VMJob,
  VMPersonnel,
} from "../../../types/maintenance/dto/maintenanceDto";
import {
  IJobParams,
  JobStatus,
  JobType,
  NewJob,
  SignStatus,
  SupportStatus,
  Tag,
  VMAsset,
} from "../../../types/maintenance/maintenanceTypes";
import { VMWebSign } from "../../../types/sign/dto/signDto";
import { VMWebSupport } from "../../../types/sign/dto/supportDto";
import { Attachment } from "../../../types/sign/signTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import Attachments from "./components/attachments/attachments";
import Equipments from "./components/equipments/equipments";
import Personnels from "./components/personnels/personnels";
import Signs from "./components/signs/signs";
import Supports from "./components/supports/supports";
import "./jobDetails.scss";
import TesTabs from "../../../components/tesTab/tesTab";
import ReportSelector from "../../../components/reportSelector";

//#endregion
const JobDetails = () => {
  //#region consts
  const history = useNavigate();
  const params = useParams<IJobParams>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initDataJob, setInitDataJob] = useState<VMJob>(new VMJob());
  const [initDataEquipments, setInitDataEquipments] = useState<VMEquipment[]>(
    []
  );
  const [initDataPersonnels, setInitDataPersonnels] = useState<PersonnelName[]>(
    []
  );
  const [initDataTags, setInitDataTags] = useState<Tag[]>([]);
  const [initDataSigns, setInitDataSigns] = useState<VMWebSign[]>([]);
  const [initDataSupports, setInitDataSupports] = useState<VMWebSupport[]>([]);
  const [initDataJobStatuses, setInitDataJobStatuses] = useState<JobStatus[]>(
    []
  );
  const [initdataJobTypes, setInitDataJobTypes] = useState<JobType[]>([
    new JobType(),
  ]);
  const [signStatus, setSignStatus] = useState<SignStatus>(new SignStatus());
  const [supportStatus, setSupportStatus] = useState<SupportStatus>(
    new SupportStatus()
  );
  const [isInstallation, setIsInstallation] = useState<boolean>(false);
  const [existingFiles, setExistingFiles] = useState<Attachment[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [removedFile, setRemovedFile] = useState<string[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [backPermission, setBackPermission] = useState(false);
  const { generalSetting } = useClientSetting();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const [durationString, setDurationString] = useState("");
  const validationRef = useRef<ValidationGroupRef>(null);
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const jobIdRef = useRef<HTMLSpanElement>(null);

  //#endregion consts
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (params.jobId !== "AddNew") {
          await getInitDataJob(params.jobId!);
        } else {
          setIsLocked(false);
        }
        await getInitDataJobStatus();
        await getInitDataJobTypes();
        await getinitDataTags();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.jobId]);

  useEffect(() => {
    calculateAllCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initDataPersonnels,
    initDataEquipments,
    initDataJob.duration,
    initDataJob.materialCost,
  ]);

  // useEffect(() => {
  //   calculateLabourCost()
  // }, [initDataPersonnels]);

  useEffect(() => {
    calculateTotalCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initDataJob.materialCost,
    initDataJob.otherCost,
    initDataJob.labourCost,
    initDataJob.equipmentCost,
  ]);

  //#region functions
  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }
  async function getInitDataJob(id: string) {
    const res = await TesGet(
      MaintenanceApiUrl() + "/api/jobs/jobDetails/" + id,
      true
    );
    var calculationDate: Date | null = null;
    if (res.assignDate !== null && res.assignDate !== "0001-01-01T00:00:00") {
      calculationDate = new Date(res.assignDate);
    }
    if (
      res.completionDate != null &&
      res.completionDate !== "0001-01-01T00:00:00"
    ) {
      calculationDate = new Date(res.completionDate);
    }
    if (calculationDate == null) {
      calculationDate = new Date();
    }
    calculationDate.setHours(0, 0, 0, 0);

    res.personnels.forEach((p: VMPersonnel) => {
      p.costs.forEach((c: VMCost) => {
        var fromDate = new Date(c.fromDate);
        fromDate.setHours(0, 0, 0, 0);
        var toDate = new Date(c.toDate);
        toDate.setHours(0, 0, 0, 0);
        if (
          calculationDate !== null &&
          fromDate <= calculationDate &&
          toDate >= calculationDate
        ) {
          p.costForJob = c.price;
        }
      });
      if (!p.costForJob) {
        p.costForJob = 0;
      }
    });

    res.equipments.forEach((e: VMEquipment) => {
      e.costs.forEach((c: VMCost) => {
        var fromDate = new Date(c.fromDate);
        fromDate.setHours(0, 0, 0, 0);
        var toDate = new Date(c.toDate);
        toDate.setHours(0, 0, 0, 0);
        if (
          calculationDate !== null &&
          fromDate.getTime() <= calculationDate.setHours(0, 0, 0, 0) &&
          toDate.getTime() >= calculationDate.setHours(0, 0, 0, 0)
        ) {
          e.costForJob = c.price;
        }
      });
      if (!e.costForJob) {
        e.costForJob = 0;
      }
    });

    setInitDataJob(res);
    setInitDataEquipments(res.equipments);
    setInitDataPersonnels(res.personnels);
    setInitDataSigns(res.signs);
    setInitDataSupports(res.supports);
    setExistingFiles(res.attachments);
  }

  async function getinitDataTags() {
    setInitDataTags(
      await TesGet(
        MaintenanceApiUrl() +
          "/api/tags/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  async function getInitDataJobStatus() {
    setInitDataJobStatuses(
      await TesGet(
        MaintenanceApiUrl() +
          "/api/JobStatus/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  async function getInitDataJobTypes() {
    setInitDataJobTypes(
      await TesGet(
        MaintenanceApiUrl() +
          "/api/JobTypes/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.jobId !== "AddNew") {
                  updateJob();
                  setDataChanged(false);
                } else {
                  addJob();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function calculateTotalCost() {
    //if (initDataJob.total !== undefined && initDataJob.total !== 0) {
    const total = (
      (initDataJob.equipmentCost ? initDataJob.equipmentCost : 0) +
      (initDataJob.labourCost ? initDataJob.labourCost : 0) +
      (initDataJob.materialCost ? initDataJob.materialCost : 0) +
      (initDataJob.otherCost ? initDataJob.otherCost : 0)
    ).toFixed(2);
    setInitDataJob({ ...initDataJob, total: parseFloat(total) });
    //}
  }

  function getCalculationDate() {
    var calculationDate: Date | null = null;
    if (
      initDataJob.assignDate !== null &&
      initDataJob.assignDate !== undefined
    ) {
      calculationDate = new Date(initDataJob.assignDate);
    }
    if (calculationDate === null) {
      calculationDate = new Date();
    }
    calculationDate.setHours(0, 0, 0, 0);
    return calculationDate;
  }

  //add and update functions
  async function updateJob() {
    let postObj: NewJob = {
      ...initDataJob,
      customerId: localStorage.getItem("selectedCustomerId") as string,
      equipments: initDataEquipments.map((x) => x.id),
      personnels: initDataPersonnels.map((x) => x.id),
      assets: [
        ...initDataSigns.map((x) => {
          var signs = new VMAsset();
          signs.assetId = x.id;
          signs.statusId = x.statusId;
          signs.note = x.note;
          signs.type = 1;
          return signs;
        }),
        ...initDataSupports.map((x) => {
          var supports = new VMAsset();
          supports.assetId = x.id;
          supports.statusId = x.statusId;
          supports.note = x.note;
          supports.type = 2;
          return supports;
        }),
      ],
      deletedAttachments: removedFile,
      tags: initDataJob.tags,
    };
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesPut(
        MaintenanceApiUrl() + "/api/jobs/" + initDataJob.id,
        postObj,
        true
      );
      if (res.isChanged) {
        await uploadImages(initDataJob.id);
      }
      if (activeLoading) activeLoading(false);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }
  async function addJob() {
    let postObj: NewJob = {
      ...initDataJob,
      customerId: localStorage.getItem("selectedCustomerId") as string,
      equipments: initDataEquipments.map((x) => x.id),
      personnels: initDataPersonnels.map((x) => x.id),
      assets: [
        ...initDataSigns.map((x) => {
          var signs = new VMAsset();
          signs.assetId = x.id;
          signs.statusId = x.statusId;
          signs.note = x.note;
          signs.type = 1;
          return signs;
        }),
        ...initDataSupports.map((x) => {
          var supports = new VMAsset();
          supports.assetId = x.id;
          supports.statusId = x.statusId;
          supports.note = x.note;
          supports.type = 2;
          return supports;
        }),
      ],
      tags: initDataJob.tags,
    };
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesPost(
        MaintenanceApiUrl() + "/api/jobs",
        postObj,
        true
      );
      if (res.isChanged) {
        await uploadImages(res.id);
      }
      if (activeLoading) activeLoading(false);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  function calculateAllCost() {
    var personnelTotalCost = 0;
    initDataPersonnels
      .filter((x) => x.costForJob !== undefined)
      .forEach((p: PersonnelName) => {
        personnelTotalCost = personnelTotalCost + p.costForJob;
      });
    const personnelCost = (
      (personnelTotalCost / 60) *
      parseInt(initDataJob.duration)
    ).toFixed(2);
    var equipmentsTotalCost = 0;
    initDataEquipments.forEach((e) => {
      equipmentsTotalCost = equipmentsTotalCost + e.costForJob;
    });
    const equipmentCost =
      (equipmentsTotalCost / 60) * parseFloat(initDataJob.duration);
    var materialCost = initDataJob.materialCost;
    if (isInstallation === true) {
      materialCost =
        initDataSigns.reduce((a, b) => +a + +b.materialCost, 0) +
        initDataSupports.reduce((a, b) => +a + +b.materialCost, 0);
    }
    setInitDataJob({
      ...initDataJob,
      labourCost: parseFloat(personnelCost),
      equipmentCost: equipmentCost,
      materialCost: materialCost,
    });
  }
  //Attachments

  async function uploadImages(id: string) {
    try {
      const data = new FormData();
      // eslint-disable-next-line array-callback-return
      files.map((a: any) => {
        data.append("file", a);
      });
      data.append(id, id);
      await TesPutUploadFile(
        MaintenanceApiUrl() + "/api/Attachments/job",
        data,
        true
      );

      if (params.jobId !== "AddNew") {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        if (backPermission === true) {
          history(-1);
        }
      } else {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
      }
    } catch (ex) {}
  }

  function handleChangeFile(files: any) {
    setFiles(files);
    setDataChanged(true);
  }

  function onDeleteNewFile(name: string) {
    setFiles([...files.filter((x) => x.name !== name)]);
    setDataChanged(true);
  }

  function onDeleteExistingFile(id: string) {
    setInitDataJob({
      ...initDataJob,
      attachments: initDataJob.attachments.filter((x) => x.id !== id),
    });
    setRemovedFile([...removedFile, id]);
    setDataChanged(true);
  }

  //Details Functions
  const onValueChange = (name: string, value: any) => {
    setInitDataJob({ ...initDataJob, [name]: value });
    setDataChanged(true);
  };

  function onChangeDetailsFields(name: string, value: string) {
    if (name !== "jobId") {
      setDataChanged(true);
    } else {
      setInitDataJob({ ...initDataJob, jobId: value });
    }
    if (name === "jobTypeId") {
      if (
        initdataJobTypes.findIndex(
          (x: JobType) => x.id === value && x.isInstallation === true
        ) !== -1
      ) {
        setIsInstallation(true);
      } else {
        setIsInstallation(false);
      }
    }
    if (name === "duration") {
      let hour,
        minutes = 0;
      hour = parseInt((parseFloat(value) / 100).toFixed(2));
      minutes = parseFloat(value) % 100;
      setInitDataJob({
        ...initDataJob,
        duration: (hour * 60 + minutes).toString(),
      });
    }
  }

  //Signs Functions
  function assignSign(lstSign: VMWebSign[]) {
    var signs = lstSign.filter(
      (x) => !initDataSigns.map((a) => a.id).includes(x.id)
    );
    setInitDataSigns([...initDataSigns, ...signs]);
  }
  function onDeleteSign(sign: VMWebSign) {
    setInitDataSigns(initDataSigns.filter((x) => x.id !== sign.id));
    setDataChanged(true);
  }
  function assignSignStatus(id: string) {
    const sign = initDataSigns.find((x) => x.id === id);
    setSignStatus({
      statusId: sign?.statusId,
      note: sign?.note,
    });
  }
  function onValueChangeSignStatus(name: string, value: any) {
    setSignStatus({ ...signStatus, [name]: value });
    setDataChanged(true);
  }
  function addNewSignStatus(selectedSign: VMWebSign) {
    var currentSigns = initDataSigns;
    var sIndex = currentSigns.findIndex((x) => x.id === selectedSign.id);
    currentSigns[sIndex] = {
      ...currentSigns[sIndex],
      statusId: signStatus.statusId,
      note: signStatus.note,
    };
    setInitDataSigns(currentSigns);
    setDataChanged(true);
  }
  //Supports Functions
  function assignSupport(lstSupports: VMWebSupport[]) {
    var supports = lstSupports.filter(
      (x: VMWebSupport) => !initDataSupports.map((a) => a.id).includes(x.id)
    );
    setInitDataSupports([...initDataSupports, ...supports]);
    setDataChanged(true);
  }
  function onDeleteSupport(support: VMWebSupport) {
    setInitDataSupports(initDataSupports.filter((x) => x.id !== support.id));
    setDataChanged(true);
  }
  function onValueChangeSupportStatus(name: string, value: any) {
    setSupportStatus({ ...supportStatus, [name]: value });
    setDataChanged(true);
  }
  function addNewSupportStatus(selectedSupport: VMWebSupport) {
    var currentSupports = initDataSupports;
    var sIndex = currentSupports.findIndex((x) => x.id === selectedSupport.id);
    currentSupports[sIndex] = {
      ...currentSupports[sIndex],
      statusId: supportStatus.statusId,
      note: supportStatus.note,
    };
    setInitDataSupports(currentSupports);
    setDataChanged(true);
  }
  function assignSupportStatus(id: string) {
    const support = initDataSupports.find((x) => x.id === id);
    setSupportStatus({
      statusId: support?.statusId,
      note: support?.note,
    });
  }

  //Personnels function
  function assignPersonnel(lstPersonnel: PersonnelName[]) {
    const calculationDate = getCalculationDate();
    lstPersonnel.forEach((p: PersonnelName) => {
      p.costs.forEach((c: VMCost) => {
        var fromDate = new Date(c.fromDate);
        var toDate = new Date(c.toDate);
        if (fromDate <= calculationDate && toDate >= calculationDate) {
          p.costForJob = c.price;
        }
      });
      if (!p.costForJob) {
        p.costForJob = 0;
      }
    });
    setInitDataPersonnels([
      ...initDataPersonnels,
      ...lstPersonnel.filter(
        (p: PersonnelName) =>
          !initDataPersonnels.map((x) => x.id).includes(p.id)
      ),
    ]);
  }

  //Equipment Functions
  function assignEquipments(lstEquipments: VMEquipment[]) {
    const calculationDate = getCalculationDate();

    lstEquipments.forEach((p: VMEquipment) => {
      p.costs.forEach((c: VMCost) => {
        var fromDate = new Date(c.fromDate);
        var toDate = new Date(c.toDate);
        if (fromDate <= calculationDate && toDate >= calculationDate) {
          p.costForJob = c.price;
        }
      });
      if (!p.costForJob) {
        p.costForJob = 0;
      }
    });
    setInitDataEquipments([
      ...initDataEquipments,
      ...lstEquipments.filter(
        (x: VMEquipment) => !initDataEquipments.map((a) => a.id).includes(x.id)
      ),
    ]);
  }

  //#endregion funstions

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && jobIdRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = jobIdRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  const copyOneTextToClipboard = () => {
    if (jobIdRef.current) {
      const textToCopy = jobIdRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        MaintenancePermissions.Maintenance_D_Job,
        MaintenancePermissions.Maintenance_E_Job,
        MaintenancePermissions.Maintenance_R_Job,
        MaintenancePermissions.Maintenance_V_AllJob,
        MaintenancePermissions.Maintenance_CompleteJob,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t("job")}: </span>
                  {initDataJob?.geoId && initDataJob?.geoId !== "" && (
                    <span ref={geoIdRef}>[{initDataJob?.geoId}]</span>
                  )}
                  <span> {t("withJobId")}: </span>
                  <span ref={jobIdRef}>{initDataJob?.jobId}</span>
                </p>
                {initDataJob?.geoId && initDataJob?.geoId !== "" ? (
                  <DropDownButton
                    style={{ marginLeft: 10, width: "5.5rem" }}
                    text={t("copy")}
                    items={[
                      { name: t("geoId"), value: t("geoId") },
                      { name: t("jobId"), value: t("jobId") },
                    ]}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator([
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("jobId"), value: t("jobId") },
                      ]),
                    }}
                    displayExpr="name"
                    onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                ) : (
                  <Button
                    onClick={() => copyOneTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                )}
                <hr
                  className="line"
                  style={{ display: "block", marginTop: "1rem" }}
                ></hr>
              </div>
            )}
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={goBackPermission}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
                <ReportSelector
                  reportLocations={[reportLocations.Maintenance_jobDetails]}
                  getRecords={() => [{ id: initDataJob.id }]}
                />
              </div>
              <div className="rightColumn">
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <div className="rightColumn">
                <Permission
                  allowed={[MaintenancePermissions.Maintenance_D_Job]}
                  hasFeedBackElement={false}
                >
                  {params.jobId === "AddNew" && (
                    <Button
                      onClick={addJob}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  )}
                </Permission>
                <Permission
                  allowed={[MaintenancePermissions.Maintenance_E_Job]}
                  hasFeedBackElement={false}
                >
                  {params.jobId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={updateJob}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}

                  <ShareURL url={url} />
                </Permission>
              </div>
            </div>
          </div>
          <div className={"dx-card "}>
            <TesTabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onTabChange={handleTabChange}
            />
            {selectedIndex === 0 && (
              <Details
                initDataJob={initDataJob}
                initDataTags={initDataTags}
                generalSettings={generalSetting}
                initDataJobStatuses={initDataJobStatuses}
                initDataJobTypes={initdataJobTypes}
                durationString={durationString}
                setDurationString={setDurationString}
                onValueChange={onValueChange}
                onChangeFields={onChangeDetailsFields}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}

            {selectedIndex === 1 && (
              <Signs
                initDataJobStatuses={initDataJobStatuses}
                initDataSigns={initDataSigns}
                generalSettings={generalSetting}
                signStatus={signStatus}
                assignSign={assignSign}
                onDeleteSign={onDeleteSign}
                assignSignStatus={assignSignStatus}
                onValueChangeSignStatus={onValueChangeSignStatus}
                addNewSignStatus={addNewSignStatus}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 2 && (
              <Supports
                initDataJobStatuses={initDataJobStatuses}
                initDataSupports={initDataSupports}
                generalSettings={generalSetting}
                supportStatus={supportStatus}
                assignSupport={assignSupport}
                onDeleteSupport={onDeleteSupport}
                assignSupportStatus={assignSupportStatus}
                onValueChangeSupportStatus={onValueChangeSupportStatus}
                addNewSupportStatus={addNewSupportStatus}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 3 && (
              <Personnels
                currentPersonnels={initDataPersonnels}
                generalSettings={generalSetting}
                assignPersonnel={assignPersonnel}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 4 && (
              <Equipments
                currentEquipments={initDataEquipments}
                generalSettings={generalSetting}
                assignEquipment={assignEquipments}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 5 && (
              <Attachments
                handleChangeFile={handleChangeFile}
                onDeleteExistingFile={onDeleteExistingFile}
                onDeleteNewFile={onDeleteNewFile}
                files={files}
                isLocked={isLocked}
                validationRef={validationRef}
                initDataJob={initDataJob}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default JobDetails;
