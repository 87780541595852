import React from "react";
import { useLocation } from "react-router-dom";
import "./footer.scss";

const TesFooter = ({ ...rest }) => {
  const location = useLocation();
  const isTrafficOperationPage = location.pathname.includes("trafficOperation");
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  return (
    <footer
      className={`commonFooter ${compactViewModel ? "compactFooter" : ""}`}
      style={{ display: isTrafficOperationPage ? "none" : "block" }}
      {...rest}
    />
  );
};

export default TesFooter;
