import {
  Button,
  DataGrid,
  Form,
  ScrollView,
  SelectBox,
  TagBox,
  TextBox,
  ValidationGroup,
  Validator,
} from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/cjs/data-grid";
import { RequiredRule, SimpleItem } from "devextreme-react/cjs/form";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { TMSPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { FieldApiUrl, SafetyApiUrl } from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValueNumber,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { SectionDetailType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../types/infrastructure/infrastructureTypes";
import { VRUResultCalculationStatus } from "../../../types/systematicScreening/enums/systematicScreeningEnums";
import {
  IProjectParam,
  SimpleWorkBook,
  VRUProjectDetailDto,
  VRUProjectInputDto,
  VRUScoring,
  VRUTargetPercentile,
} from "../../../types/systematicScreening/systematicScreeningTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { Enum2Array } from "../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import styles from "./style.module.scss";
import { useClientSetting } from "../../../contexts/clientSetting";
import SystematicScreeningPermissions from "../../../constants/Permissions/systematicScreeningPermissions";

type Group = {
  name: string;
  rows: (number | null)[][];
  score: number;
};

type Groups = {
  [key: string]: Group[];
};

type VRUScoringKey = keyof VRUScoring;

const VRUDetails = () => {
  const [fieldsDataChanged, setFieldsDataChanged] = useState(false);
  const [initData, setInitData] = useState<Partial<VRUProjectDetailDto>>();
  const params = useParams<IProjectParam>();
  const [isLocked, setIsLocked] = useState(
    params.projectId === "AddNew" ? false : true
  );
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const history = useNavigate();
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const lstYears: NameValueNumber[] = Array.from(
    { length: new Date().getFullYear() - 1980 },
    (_, index) => {
      const year = new Date().getFullYear() - index;
      return { name: year.toString(), value: year };
    }
  );

  const lstSectionType = Enum2Array(SectionDetailType);
  const lstCalculationStatus = Enum2Array(VRUResultCalculationStatus);
  const [workBooks, setWorkBooks] = useState<SimpleWorkBook[]>([]);
  const [networkScreeningOptions, setNetworkScreeningOptions] = useState<
    INameId[]
  >([]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const { generalSetting } = useClientSetting();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);

        await getInfrastructureTesCodeValues();
        await getWorkBooks();

        if (params.projectId === "AddNew") {
          await getAndSetDefaultScoring();

          // Set initData with last 10 years
          setInitData({
            ...initData,
            years: lstYears.slice(-10).map((y) => y.value),
          });
        } else {
          await getInitData();
        }

        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initData?.workBookId && workBooks.length > 0) {
      const selectedWorkBook = workBooks.find(
        (wb) => wb.id === initData.workBookId
      );

      if (selectedWorkBook) {
        setNetworkScreeningOptions(selectedWorkBook.analysis);
      }
    }
  }, [initData, workBooks]);

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function getInitData() {
    try {
      if (activeLoading) activeLoading(true);

      const res = (await TesGet(
        SafetyApiUrl() +
          "/api/VRU/GetVRUProjectDetail?projectId=" +
          params.projectId,
        true
      )) as RequestResponseResult<VRUProjectDetailDto>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function getWorkBooks() {
    try {
      if (activeLoading) activeLoading(true);

      const res = (await TesGet(
        SafetyApiUrl() +
          "/api/Workbooks/GetSimpleWorkBooks/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )) as RequestResponseResult<SimpleWorkBook[]>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setWorkBooks(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function getAndSetDefaultScoring() {
    try {
      if (activeLoading) activeLoading(true);

      const res = (await TesGet(
        SafetyApiUrl() + "/api/VRU/DefaultScoring",
        true
      )) as RequestResponseResult<VRUScoring>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData({ ...initData, vruScoring: res.results });
      } else {
        await RequestErrorHandling(res);
      }
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onRun() {
    if (!initData) {
      notify(t("someErrorOccurred"), "error", 5000);
      return;
    }

    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      const postObj: VRUProjectInputDto = {
        customerId: localStorage.getItem("selectedCustomerId") ?? "",
        name: initData.name ?? "",
        years: initData.years ?? [],
        vruScoring: convertGroupsToVRUScoring(groups),
        jurisdictionIds: initData.jurisdictionIds ?? [],
        municipalityIds: initData.municipalityIds ?? [],
        divisionIds: initData.divisionIds ?? [],
        workBookId: initData.workBookId ?? "",
        networkScreeningId: initData.networkScreeningId ?? "",
        id: initData.id,
      };

      try {
        if (activeLoading) activeLoading(true);

        const res = (await TesPost(
          SafetyApiUrl() + "/api/VRU/run",
          postObj,
          true
        )) as RequestResponseResult<any>;

        if (activeLoading) activeLoading(false);

        if (res.responseCode === ResponseCode.OK) {
          setFieldsDataChanged(false);
          notify(t("analysisMessage"), "success", 5000);
        } else {
          await RequestErrorHandling(res);
        }

        setFieldsDataChanged(false);
        history(-1);
      } catch (error) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred"), "error", 5000);
      }
    } else {
      notify(t("fieldIsRequired"), "error", 5000);
    }
  }

  function goBackPermission() {
    if (fieldsDataChanged) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                onRun();
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const onValueChangeHandler = <K extends keyof VRUProjectDetailDto>(
    key: K,
    value: VRUProjectDetailDto[K]
  ) => {
    setInitData((prevState) => {
      const updatedData = { ...prevState, [key]: value };

      // When WorkBook changes, reset NetworkScreening and update options
      if (key === "workBookId") {
        const selectedWorkBook = workBooks.find((wb) => wb.id === value);
        updatedData.networkScreeningId = ""; // Reset the selected networkScreeningId
        setNetworkScreeningOptions(selectedWorkBook?.analysis || []); // Update available options
      }

      return updatedData;
    });

    setFieldsDataChanged(true); // Mark fields as changed
  };

  //Scoring Table Functions
  const [groups, setGroups] = useState<Groups>({});

  useEffect(() => {
    if (
      initData &&
      initData.vruScoring &&
      Object.keys(initData.vruScoring).length > 0 &&
      Object.keys(groups).length === 0 // Only initialize once!
    ) {
      const processedGroups: Groups = {};
      (Object.keys(initData.vruScoring) as VRUScoringKey[]).forEach((key) => {
        const scoringData = initData.vruScoring?.[key];
        if (scoringData) {
          processedGroups[key] = [
            {
              name: key,
              rows: scoringData.vruTargetPercentiles.map((item) => [
                item.from,
                item.value,
              ]),
              score: scoringData.score,
            },
          ];
        }
      });
      setGroups(processedGroups);
      setEditingRows(processedGroups);
    }
  }, [initData]);

  const [editingRows, setEditingRows] = useState<Groups>(groups);

  const handleEditToggle = () => {
    if (!isLocked) {
      setGroups({ ...editingRows });
    } else {
      setEditingRows({ ...groups });
    }
  };

  useEffect(() => {
    handleEditToggle();
  }, [isLocked]);

  function handleEditChange(
    groupKey: string,
    groupIndex: number,
    rowIndex: number,
    cellIndex: number,
    value: string
  ) {
    const numericValue = value === "" ? null : parseFloat(value);

    setEditingRows((prevRows) => {
      const updatedRows = { ...prevRows };
      updatedRows[groupKey][groupIndex].rows[rowIndex][cellIndex] =
        numericValue;
      return updatedRows;
    });

    setFieldsDataChanged(true); // Mark data as changed
  }

  const addRow = (groupKey: VRUScoringKey, groupIndex: number) => {
    setGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };
      updatedGroups[groupKey][groupIndex].rows.push([0, 0, 0]);
      return updatedGroups;
    });

    setFieldsDataChanged(true); // Mark data as changed
  };

  const handleDelete = (
    groupKey: VRUScoringKey,
    groupIndex: number,
    rowIndex: number
  ) => {
    setGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };
      updatedGroups[groupKey][groupIndex].rows = updatedGroups[groupKey][
        groupIndex
      ].rows.filter((_, i) => i !== rowIndex);
      return updatedGroups;
    });

    setFieldsDataChanged(true); // Mark data as changed
  };

  const convertGroupsToVRUScoring = (groups: Groups): VRUScoring => {
    const vruScoring: Partial<VRUScoring> = {};

    (Object.keys(groups) as VRUScoringKey[]).forEach((key) => {
      const groupData = groups[key];

      if (groupData && groupData.length > 0) {
        vruScoring[key] = {
          score: groupData[0].score,
          vruTargetPercentiles: groupData[0].rows.map(
            ([from, value]): VRUTargetPercentile => ({
              from: from ?? 0,
              value: value ?? 0,
            })
          ),
        };
      }
    });

    return vruScoring as VRUScoring;
  };

  const handleScoreChange = (
    groupKey: VRUScoringKey,
    groupIndex: number,
    value: string
  ) => {
    // Convert empty value to 0 to ensure score remains a number
    const numericValue = value.trim() === "" ? 0 : parseFloat(value);

    setEditingRows((prevRows) => {
      const updatedRows = { ...prevRows };
      updatedRows[groupKey][groupIndex].score = isNaN(numericValue)
        ? 0
        : numericValue;
      return updatedRows;
    });

    setFieldsDataChanged(true); // Mark data as changed
  };

  //End Scoring Table Functions

  return (
    <Permission
      allowed={[
        SystematicScreeningPermissions.SystematicScreening_D_VRU,
        SystematicScreeningPermissions.SystematicScreening_V_VRU,
      ]}
      hasFeedBackElement={true}
    >
      <h2 className={"content-block"}>{t("vruDetails")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card"}>
          <div className="row" style={{ marginTop: 15 }}>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            <Permission
              allowed={[
                SystematicScreeningPermissions.SystematicScreening_D_VRU,
              ]}
              hasFeedBackElement={true}
            >
              <div className="rightColumn">
                {!isLocked && (
                  <Button
                    onClick={onRun}
                    icon="fa-solid fa-play"
                    hint={t("run")}
                  />
                )}
                {params.projectId !== "AddNew" && <ShareURL url={url} />}
                {params.projectId !== "AddNew" && (
                  <Button
                    onClick={() => LockHandler()}
                    icon={
                      isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                    }
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                )}
              </div>
            </Permission>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h6 style={{ margin: "0.5rem 0", flex: 1, textAlign: "center" }}>
            {t("scoring")}
          </h6>
          <h6 style={{ margin: "0.5rem 0", flex: 1, textAlign: "center" }}>
            {t("details")}
          </h6>
        </div>
        <div className={`${styles["details-div"]} dx-card`}>
          <ScrollView className={styles["cols"]} showScrollbar="always">
            <table className={styles["bordered-table"]}>
              <tbody>
                {(Object.keys(groups) as VRUScoringKey[]).map((groupKey) =>
                  groups[groupKey].map((group, groupIndex) => (
                    <React.Fragment key={groupKey + groupIndex}>
                      <tr>
                        <td rowSpan={group.rows.length + 1}>
                          {t(group.name)}
                          <br />
                          {isLocked ? (
                            ` (out of ${group.score})`
                          ) : (
                            <>
                              (out of
                              <input
                                type="number"
                                value={group.score ?? ""}
                                onChange={(e) =>
                                  handleScoreChange(
                                    groupKey,
                                    groupIndex,
                                    e.target.value
                                  )
                                }
                                onFocus={(e) => e.target.select()}
                                className={styles["input-no-border"]}
                                step="any"
                              />
                              )
                            </>
                          )}
                          <br />
                          <Button
                            icon="fa-solid fa-plus"
                            onClick={() => addRow(groupKey, groupIndex)}
                            disabled={isLocked}
                            style={{ margin: "0.5rem 0 0 0", padding: "0" }}
                          />
                        </td>
                        <th>{t("targetPercentile")}</th>
                        <th>{t("from")}</th>
                        <th>{t("delete")}</th>
                      </tr>
                      {group.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          <td>
                            {!isLocked ? (
                              <input
                                type="number"
                                value={row[1] ?? ""}
                                onChange={(e) =>
                                  handleEditChange(
                                    groupKey,
                                    groupIndex,
                                    rowIndex,
                                    1,
                                    e.target.value
                                  )
                                }
                                onFocus={(e) => e.target.select()}
                                className={styles["input-no-border"]}
                                step="any"
                              />
                            ) : (
                              row[1]
                            )}
                          </td>
                          <td>
                            {!isLocked ? (
                              <input
                                type="number"
                                value={row[0] ?? ""}
                                onChange={(e) =>
                                  handleEditChange(
                                    groupKey,
                                    groupIndex,
                                    rowIndex,
                                    0,
                                    e.target.value
                                  )
                                }
                                onFocus={(e) => e.target.select()}
                                className={styles["input-no-border"]}
                                step="any"
                              />
                            ) : (
                              row[0]
                            )}
                          </td>
                          <td>
                            <Button
                              icon="fa-solid fa-trash"
                              onClick={() =>
                                handleDelete(groupKey, groupIndex, rowIndex)
                              }
                              style={{ margin: "0", padding: "0" }}
                              disabled={isLocked}
                            />
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          </ScrollView>
          <div className={`dx-card ${styles["details-col"]}`}>
            <ScrollView className={styles["cols"]} showScrollbar="always">
              <ValidationGroup ref={validationRef}>
                <Form colCount={2}>
                  <SimpleItem colSpan={1}>
                    <TextBox
                      placeholder=""
                      label={t("name")}
                      labelMode="floating"
                      value={initData?.name || ""}
                      onValueChange={(e) => onValueChangeHandler("name", e)}
                      disabled={isLocked}
                      showClearButton={true}
                    >
                      <Validator>
                        <RequiredRule message={t("name")} />
                      </Validator>
                    </TextBox>
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("workBook")}
                      valueExpr="id"
                      displayExpr="name"
                      labelMode="floating"
                      items={workBooks}
                      value={initData?.workBookId}
                      onValueChange={(e) =>
                        onValueChangeHandler("workBookId", e)
                      }
                      className="modalInput"
                      showClearButton={true}
                      searchEnabled={true}
                      disabled={isLocked}
                    >
                      <Validator>
                        <RequiredRule message={t("workBook")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("networkScreening")}
                      valueExpr="id" // Store the ID
                      displayExpr="name" // Display the name
                      labelMode="floating"
                      items={networkScreeningOptions} // Dynamically updated options
                      value={initData?.networkScreeningId || ""}
                      onValueChange={(e) =>
                        onValueChangeHandler("networkScreeningId", e || "")
                      }
                      className="modalInput"
                      showClearButton={true}
                      searchEnabled={true}
                      disabled={isLocked}
                      style={{ marginBottom: "-0.75rem", marginTop: "-2rem" }}
                    >
                      <Validator>
                        <RequiredRule message={t("networkScreening")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("municipalities")}
                      dataSource={SortObjectByPropName(
                        initDataTesCodeValues.municipalities,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                      value={initData?.municipalityIds || []}
                      onValueChange={(e) =>
                        onValueChangeHandler("municipalityIds", e)
                      }
                      searchEnabled={true}
                      disabled={isLocked}
                      style={{ marginBottom: "-0.75rem", marginTop: "-2rem" }}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("jurisdictions")}
                      dataSource={SortObjectByPropName(
                        initDataTesCodeValues.jurisdictions,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                      value={initData?.jurisdictionIds || []}
                      onValueChange={(e) =>
                        onValueChangeHandler("jurisdictionIds", e)
                      }
                      searchEnabled={true}
                      disabled={isLocked}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("years")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstYears, "name")}
                      value={initData?.years || []}
                      valueExpr="value"
                      displayExpr="name"
                      onValueChange={(e) => onValueChangeHandler("years", e)}
                      disabled={isLocked}
                    >
                      <Validator>
                        <RequiredRule message={t("years")} />
                      </Validator>
                    </TagBox>
                  </SimpleItem>
                </Form>
              </ValidationGroup>
            </ScrollView>
          </div>
        </div>
        <h6 style={{ margin: "1.5rem 0" }}>{t("results")}</h6>
        <div style={{ margin: "-1.5rem 0 0 0" }}>
          <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={initData?.vruResults || []}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ margin: "0 1rem" }}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} />
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={"top"} />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <Column
              alignment="left"
              dataField="locationDescription"
              allowSorting={true}
              caption={t("locationDescription")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              alignment="left"
              dataField="geoId"
              allowSorting={true}
              caption={t("geoId")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {initDataTesCodeValues.municipalities?.length !== 0 &&
              initDataTesCodeValues.municipalities !== undefined && (
                <Column
                  dataField="municipalityId"
                  allowHeaderFiltering={true}
                  caption={t("municipality")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataTesCodeValues.municipalities,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              )}
            {initDataTesCodeValues.jurisdictions?.length !== 0 &&
              initDataTesCodeValues.jurisdictions !== undefined && (
                <Column
                  dataField="jurisdictionId"
                  allowHeaderFiltering={true}
                  caption={t("jurisdiction")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataTesCodeValues.jurisdictions,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              )}
            <Column
              alignment="left"
              dataField="trafficControl"
              allowSorting={true}
              caption={t("trafficControl")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="sectionDetailType" caption={t("sectionType")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              <Lookup
                dataSource={SortObjectByPropName(lstSectionType, "name")}
                valueExpr="value"
                displayExpr="name"
              />
            </Column>
            <Column
              dataField="calculationStatus"
              caption={t("calculationStatus")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              <Lookup
                dataSource={SortObjectByPropName(lstCalculationStatus, "name")}
                valueExpr="value"
                displayExpr="name"
              />
            </Column>
            <Column alignment="left" caption={t("safety")}>
              <Column
                alignment="left"
                dataField="cycCollisions"
                allowSorting={true}
                caption={t("cycCollisions")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="pedCollisions"
                allowSorting={true}
                caption={t("pedCollisions")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="sumCollisions"
                allowSorting={true}
                caption={t("sumCollisions")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="psi"
                allowSorting={true}
                caption={t("psi")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </Column>

            <Column alignment="left" caption={t("demand")}>
              <Column
                alignment="left"
                dataField="tmcCountDate"
                allowSorting={true}
                caption={t("tmcCountDate")}
                dataType="date"
                format={generalSetting?.dateFormat}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="tmcVeh"
                allowSorting={true}
                caption={t("tmcVeh")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="tmcPeds"
                allowSorting={true}
                caption={t("tmcPeds")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="tmcTurn"
                allowSorting={true}
                caption={t("tmcTurn")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </Column>

            <Column alignment="left" caption={t("attractors")}>
              <Column
                alignment="left"
                dataField="postSecondaryCount"
                allowSorting={true}
                caption={t("postSecondaryCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="schoolsCount"
                allowSorting={true}
                caption={t("schoolsCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="hospitalCount"
                allowSorting={true}
                caption={t("hospitalCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="sumAttractors"
                allowSorting={true}
                caption={t("sumAttractors")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </Column>

            <Column alignment="left" caption={t("existingConditions")}>
              <Column
                alignment="left"
                dataField="transitRoutesCount"
                allowSorting={true}
                caption={t("transitRoutesCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="vivaCount"
                allowSorting={true}
                caption={t("vivaCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="transitCount"
                allowSorting={true}
                caption={t("transitCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="sumLanes"
                allowSorting={true}
                caption={t("sumLanes")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="avgSpeedCorr"
                allowSorting={true}
                caption={t("avgSpeedCorr")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="rtLaneCount"
                allowSorting={true}
                caption={t("rtLaneCount")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="hasRTOR"
                allowSorting={true}
                caption={t("hasRTOR")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="hasProtectedLT"
                allowSorting={true}
                caption={t("hasProtectedLT")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="hasLPI"
                allowSorting={true}
                caption={t("hasLPI")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="popDensity"
                allowSorting={true}
                caption={t("popDensity")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </Column>

            <Column alignment="left" caption={t("scoring")}>
              <Column
                alignment="left"
                dataField="scoreCrashes"
                allowSorting={true}
                caption={t("scoreCrashes")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scorePSI"
                allowSorting={true}
                caption={t("scorePSI")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scorePedVol"
                allowSorting={true}
                caption={t("scorePedVol")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreTransit"
                allowSorting={true}
                caption={t("scoreTransit")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreAttractors"
                allowSorting={true}
                caption={t("scoreAttractors")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scorePopDensity"
                allowSorting={true}
                caption={t("scorePopDensity")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreVehVol"
                allowSorting={true}
                caption={t("scoreVehVol")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreVehTurns"
                allowSorting={true}
                caption={t("scoreVehTurns")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreSpeed"
                allowSorting={true}
                caption={t("scoreSpeed")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreLanes"
                allowSorting={true}
                caption={t("scoreLanes")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreRTLanes"
                allowSorting={true}
                caption={t("scoreRTLanes")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreRTOR"
                allowSorting={true}
                caption={t("scoreRTOR")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreLTPhasing"
                allowSorting={true}
                caption={t("scoreLTPhasing")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="scoreLPI"
                allowSorting={true}
                caption={t("scoreLPI")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </Column>

            <Column alignment="left" caption={t("factorScores")}>
              <Column
                alignment="left"
                dataField="factorScoreSafety"
                allowSorting={true}
                caption={t("factorScoreSafety")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="factorScoreDemand"
                allowSorting={true}
                caption={t("factorScoreDemand")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="factorScoreExConditions"
                allowSorting={true}
                caption={t("factorScoreExConditions")}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </Column>
          </DataGrid>
        </div>
      </div>
    </Permission>
  );
};

export default VRUDetails;
