import i18n from "../../../../../react-i18next";

const speedTabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "location",
    icon: "fa-solid fa-map",
    content: "Location tab content",
  },
  {
    id: 2,
    text: "workOrder",
    icon: "fa-solid fa-hashtag",
    content: "Work Order tab content",
  },
  {
    id: 3,
    text: "reportingPeriods",
    icon: "fa-solid fa-calendar-days",
    content: "Reporting Periods tab content",
  },
  {
    id: 4,
    text: "volumeRawData",
    icon: "fa-solid fa-square-poll-vertical",
    content: "Volume Raw Data tab content",
  },

  {
    id: 4,
    text: "speedRawData",
    icon: "fa-solid fa-square-poll-vertical",
    content: "Speed Raw Data tab content",
  },

  {
    id: 10,
    text: "resultSummary",
    icon: "fa-solid fa-hashtag",
    content: "result tab content",
  },
  {
    id: 5,
    text: "speedStatistics",
    icon: "fa-solid fa-clipboard-check",
    content: "Speed Results Data tab content",
  },

  {
    id: 9,
    text: "roadSegmentAADT",
    icon: "fa-solid fa-road",
    content: "All-Way Stop Warrants tab content",
  },

  {
    id: 11,
    text: "attachments",
    icon: "fa-solid fa-paperclip",
    content: "attachments tab content",
  },
  {
    id: 12,
    text: "validations",
    icon: "fa-solid fa-check-double",
    content: "logs tab content",
  },
  {
    id: 13,
    text: "relatedStudies",
    icon: "fa-solid fa-circle-nodes",
    content: "logs tab content",
  },
];

const volumeTabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "location",
    icon: "fa-solid fa-map",
    content: "Location tab content",
  },
  {
    id: 2,
    text: "workOrder",
    icon: "fa-solid fa-hashtag",
    content: "Work Order tab content",
  },
  {
    id: 3,
    text: "reportingPeriods",
    icon: "fa-solid fa-calendar-days",
    content: "Reporting Periods tab content",
  },
  {
    id: 4,
    text: "volumeRawData",
    icon: "fa-solid fa-square-poll-vertical",
    content: "Volume Raw Data tab content",
  },
  {
    id: 10,
    text: "resultSummary",
    icon: "fa-solid fa-hashtag",
    content: "result tab content",
  },
  {
    id: 9,
    text: "roadSegmentAADT",
    icon: "fa-solid fa-road",
    content: "All-Way Stop Warrants tab content",
  },
  {
    id: 11,
    text: "attachments",
    icon: "fa-solid fa-paperclip",
    content: "attachment tab content",
  },
  {
    id: 12,
    text: "validations",
    icon: "fa-solid fa-check-double",
    content: "logs tab content",
  },
  {
    id: 13,
    text: "relatedStudies",
    icon: "fa-solid fa-circle-nodes",
    content: "logs tab content",
  },
];

const tmcTabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "location",
    icon: "fa-solid fa-map",
    content: "Location tab content",
  },
  {
    id: 2,
    text: "workOrder",
    icon: "fa-solid fa-hashtag",
    content: "Work Order tab content",
  },
  {
    id: 3,
    text: "reportingPeriods",
    icon: "fa-solid fa-calendar-days",
    content: "Reporting Periods tab content",
  },
  {
    id: 4,
    text: "volumeRawData",
    icon: "fa-solid fa-square-poll-vertical",
    content: "Volume Raw Data tab content",
  },
  {
    id: 10,
    text: "resultSummary",
    icon: "fa-solid fa-hashtag",
    content: "result tab content",
  },
  {
    id: 6,
    text: "signalWarrants",
    icon: "fa-solid fa-traffic-light",
    content: "Signal Warrants tab content",
  },
  {
    id: 7,
    text: "allWayStopWarrant",
    icon: "fa-solid fa-arrows-to-circle",
    content: "All-Way Stop Warrants tab content",
  },
  {
    id: 8,
    text: "intersectionAADT",
    icon: "fa-solid fa-compress",
    content: "All-Way Stop Warrants tab content",
  },

  {
    id: 11,
    text: "attachments",
    icon: "fa-solid fa-paperclip",
    content: "attachment tab content",
  },
  {
    id: 12,
    text: "validations",
    icon: "fa-solid fa-check-double",
    content: "logs tab content",
  },
  {
    id: 13,
    text: "relatedStudies",
    icon: "fa-solid fa-circle-nodes",
    content: "logs tab content",
  },
];

export { speedTabTitles, tmcTabTitles, volumeTabTitles };
