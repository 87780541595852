//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import ValidationGroup, {
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../components/permission/permision";
import {
  InfrastructurePermissions,
  TMSPermissions,
} from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import {
  InfrastructureApiUrl,
  TrafficApiUrl,
} from "../../../../../environment/routeSettings";
import {
  GridType,
  ModalMode,
  ResponseCode,
} from "../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import { MatchingDataCollectionCycle } from "../../../../../types/trafficStudy/trafficStudyTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../../utils/rest";
import {
  DateBox,
  Form,
  Lookup,
  NumberBox,
  Popup,
  ScrollView,
  SelectBox,
  TextBox,
} from "devextreme-react";
import { SimpleItem } from "devextreme-react/cjs/form";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import useBrowserTitle from "../../../../../hooks/browserTitle/useBrowserTitle";

const DataCollectionCycle = () => {
  const [initData, setInitData] = useState<MatchingDataCollectionCycle[]>([]);
  const [selectedData, setSelectedData] = useState<MatchingDataCollectionCycle>(
    new MatchingDataCollectionCycle()
  );
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const validationRef = useRef<ValidationGroupRef>(null);
  const { generalSetting } = useClientSetting();
  const { customerDivisions } = useClientSetting();

  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useBrowserTitle({ pathname: "/trafficStudy/matching/dataCollectionCycle" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    var res = (await TesGet(
      TrafficApiUrl() +
        "/api/MatchingSettings/GetCustomerMatchingDataCollectionCycle/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<MatchingDataCollectionCycle[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setSelectedData(d.row.data);
    setShowModal(true);
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new MatchingDataCollectionCycle());
    } else {
      setSelectedData(new MatchingDataCollectionCycle());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...selectedData,
          customerId: localStorage.getItem("selectedCustomerId"),
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() +
            "/api/MatchingSettings/AddMatchingDataCollectionCycle",
          postObj,
          true
        )) as RequestResponseResult<MatchingDataCollectionCycle>;
        if (res.responseCode === ResponseCode.OK) {
          dataGridRef.current?.instance().refresh();
          await getInitData();
          onCloseModal();
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);
          if (activeLoading) activeLoading(false);
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() +
            "/api/MatchingSettings/UpdateMatchingDataCollectionCycle",
          selectedData,
          true
        )) as RequestResponseResult<MatchingDataCollectionCycle>;
        if (res.responseCode === ResponseCode.OK) {
          await getInitData();
          onCloseModal();
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
          if (activeLoading) activeLoading(false);
        } else {
          await RequestErrorHandling(res);
          if (activeLoading) activeLoading(false);
        }
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("dataCollectionCycle")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("add")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.DataCollectionCycle.toString()}
                  savingTimeout={500}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      customerDivisions!,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="startDT"
                  caption={t("startDT")}
                  dataType="date"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="endDT"
                  caption={t("endDT")}
                  dataType="date"
                  format={generalSetting?.dateFormat}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="weekDaysWeight"
                  caption={t("weekDaysWeight")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="weekendsWeight"
                  caption={t("weekendsWeight")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="maxScore" caption={t("maxScore")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  {/* <DevExpressButton
                    hint={t("delete")}
                      onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton> */}
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={validationRef}>
                <Form colCount={2}>
                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("name")}
                      labelMode="floating"
                      value={selectedData.name}
                      onValueChange={(e) => onValueChanged("name", e)}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      label={t("division")}
                      labelMode="floating"
                      displayExpr="name"
                      valueExpr="id"
                      value={selectedData?.divisionId}
                      onValueChange={(e) => onValueChanged("divisionId", e)}
                      items={SortObjectByPropName(customerDivisions!, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <DateBox
                      type="date"
                      label={t("startDT")}
                      onValueChange={(e) => onValueChanged("startDT", e)}
                      value={selectedData.startDT}
                      pickerType="calendar"
                      placeholder={generalSetting?.dateFormat}
                      displayFormat={generalSetting?.dateFormat}
                      useMaskBehavior={true}
                      openOnFieldClick={true}
                      showClearButton={true}
                    ></DateBox>
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <DateBox
                      type="date"
                      label={t("endDT")}
                      onValueChange={(e) => onValueChanged("endDT", e)}
                      value={selectedData.endDT}
                      pickerType="calendar"
                      placeholder={generalSetting?.dateFormat}
                      displayFormat={generalSetting?.dateFormat}
                      useMaskBehavior={true}
                      openOnFieldClick={true}
                      showClearButton={true}
                    ></DateBox>
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      step={0}
                      label={t("weekDaysWeight")}
                      labelMode="floating"
                      value={selectedData.weekDaysWeight}
                      onValueChange={(e) => onValueChanged("weekDaysWeight", e)}
                      className="modalInput"
                      style={{ marginBottom: "0" }}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      step={0}
                      label={t("weekendsWeight")}
                      labelMode="floating"
                      value={selectedData.weekendsWeight}
                      onValueChange={(e) => onValueChanged("weekendsWeight", e)}
                      className="modalInput"
                      style={{ marginBottom: "0" }}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      step={0}
                      label={t("maxScore")}
                      labelMode="floating"
                      value={selectedData.maxScore}
                      onValueChange={(e) => onValueChanged("maxScore", e)}
                      className="modalInput"
                      style={{ marginBottom: "0" }}
                    />
                  </SimpleItem>
                </Form>
              </ValidationGroup>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default DataCollectionCycle;
