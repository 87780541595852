const tabTitles = [
  {
    id: 0,
    text: "1",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "2",
    content: "Details tab content",
  },
  {
    id: 2,
    text: "3",
    content: "Details tab content",
  },
  {
    id: 3,
    text: "4",
    content: "Details tab content",
  },
  {
    id: 4,
    text: "5",
    content: "Details tab content",
  },
  {
    id: 5,
    text: "6",
    content: "Details tab content",
  },
];
export default tabTitles;
