import { Button, DataGrid, Form, NumberBox, Popup } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Editing,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  AADTPredictionGeneralSettingType,
  AADTPredictionWarningLevel,
} from "../../../../types/trafficStudy/trafficStudyTypes";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";

const AADTPredictionGeneralSetting = () => {
  const [initData, setInitData] = useState<AADTPredictionGeneralSettingType>(
    new AADTPredictionGeneralSettingType()
  );
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] =
    useState<AADTPredictionWarningLevel>(new AADTPredictionWarningLevel());

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      var res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/GetCustomerAADTPredictionSetting",
        {
          customerId: localStorage.getItem("selectedCustomerId"),
        } as LazyLoadingRequestBase,
        true
      )) as RequestResponseResult<AADTPredictionGeneralSettingType>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  function onAdd() {
    setShowModal(true);
  }

  function onCloseModal() {
    setShowModal(false);
    setSelectedRowData(new AADTPredictionWarningLevel());
  }

  function onPopupValueChange(name: string, value: any) {
    setSelectedRowData({ ...selectedRowData, [name]: value });
  }

  function onPopupAdd(): void {
    if (initData && selectedRowData) {
      setInitData({
        ...initData,
        warningLevels: [...initData?.warningLevels, selectedRowData],
      });
      dataGridRef.current?.instance().refresh();
      onCloseModal();
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: AADTPredictionGeneralSettingType = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId")!,
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/AddUpdateAADTPredictionSetting",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        getInitData();
        dataGridRef.current?.instance().refresh();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.WorkOrderExist) {
        notify(t("workOrderExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`signalWarrants ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <h2 className={"content-block"}>
            {t("AADTPredictionGeneralSetting")}
          </h2>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div className="row" style={{ marginTop: 15 }}>
                <div className="rightColumn">
                  <Button
                    id="rowButtons"
                    onClick={() => LockHandler()}
                    icon={
                      isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                    }
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">
                  {isLocked === false && (
                    <Button
                      id="rowButtons"
                      onClick={() => onUpdate()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={onAdd}
                    icon="fa-solid fa-circle-plus"
                    text={t("add")}
                    style={{ margin: "0.5rem 0 -1rem 0" }}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData?.warningLevels}
                rowAlternationEnabled={true}
                showBorders={true}
                //   onRowClick={e => onRowClick(e)}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                // onExporting={OnExporting}
                // onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                {!isLocked && (
                  <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={true}
                  />
                )}
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="fromValue" caption={t("fromValue")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="toValue" caption={t("toValue")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="growth" caption={t("growth")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"60%"}
            height="auto"
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("export")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <div>
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    label={t("fromValue")}
                    labelMode="floating"
                    value={selectedRowData.fromValue}
                    onValueChange={(e) => onPopupValueChange("fromValue", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    label={t("toValue")}
                    labelMode="floating"
                    value={selectedRowData.toValue}
                    onValueChange={(e) => onPopupValueChange("toValue", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    label={t("growth")}
                    labelMode="floating"
                    value={selectedRowData.growth}
                    onValueChange={(e) => onPopupValueChange("growth", e)}
                  />
                </SimpleItem>
              </Form>
            </div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              <Button
                className="tes-modal-btn-add"
                style={{ marginRight: 20 }}
                onClick={() => onPopupAdd()}
                text={t("add")}
              />
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default AADTPredictionGeneralSetting;
