import { GridType } from "../types/general/enums/generalEnums";
import { Enum2Array } from "./enumTools";
import {
  storeDefaultGridSettingsKeyName,
  storeUserGridSettingsKeyName,
} from "../contexts/gridSettings/utils";
import {
  storeSelectedLangKeyName,
  storeTranslationsKeyName,
} from "../contexts/translations/utils";
import { storeInfrastructureDataKeyName } from "../contexts/infrastructure/utils";
import { storeFieldsDataKeyName } from "../contexts/fields/utils";

async function RemoveData4LogOut() {
  window.location.replace(localStorage.getItem("logoutUrl")!);
  localStorage.removeItem("tesToken");
  localStorage.removeItem("selectedCustomerId");
  localStorage.removeItem("selectedDivisionId");
  localStorage.removeItem("singleDivision");
  localStorage.removeItem("selectedCustomerName");
  localStorage.removeItem("divisionSystem");
  localStorage.removeItem("selectedDivision");
  localStorage.removeItem("defaultDivisionId");
  localStorage.removeItem(storeUserGridSettingsKeyName);
  localStorage.removeItem(storeDefaultGridSettingsKeyName);
  localStorage.removeItem(storeTranslationsKeyName);
  localStorage.removeItem(storeSelectedLangKeyName);
  localStorage.removeItem(storeInfrastructureDataKeyName);
  localStorage.removeItem(storeFieldsDataKeyName);
  Enum2Array(GridType).forEach((element) => {
    localStorage.removeItem(element.value);
  });
}

export { RemoveData4LogOut };
