import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
  TesPostUploadFile,
} from "../../../utils/rest";
import General from "./components/general/general";
import Location from "./components/location/location";
import Roads from "./components/roads/roads";
import Vehicles from "./components/vehicles/vehicles";
import tabTitles from "./data/index";

import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import {
  CollisionPermissions,
  TMSPermissions,
} from "../../../constants/Permissions";
import reportLocations from "../../../constants/reportLocations";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  AuthApiUrl,
  CollisionApiUrl,
  FieldApiUrl,
} from "../../../environment/routeSettings";
import {
  CustomerCollision,
  ICollisionParams,
} from "../../../types/collision/collisionTypes";
import { VMMapLocation } from "../../../types/collision/dto/collisionDtos";
import {
  CollisionStatus,
  CollisionTesStatus,
  GeoCodeStatus,
} from "../../../types/collision/enums/collisionEnums";
import { VMCollisionGroupedField } from "../../../types/field/dto/fieldDTO";
import {
  PagePermission,
  ResponseCode,
} from "../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { Enum2Array } from "../../../utils/enumTools";
import "./collisionDetails.scss";
import Attachments from "./components/attachments/attachments";
import ChangeLogs from "./components/changeLogs/changeLogs";
import Drivers from "./components/drivers/drivers";
import MergeLogs from "./components/mergeLogs/mergeLogs";
import Passengers from "./components/passengers/passengers";
import Pedestrians from "./components/pedestrians/pedestrians";
import People from "./components/people/people";
import Remarks from "./components/remarks/remarks";
import Validations from "./components/validations/validations";
import TesTabs from "../../../components/tesTab/tesTab";
import ReportSelector from "../../../components/reportSelector";
import style from "./style.module.scss";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const CollisionDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initDataCollision, setInitDataCollision] = useState<CustomerCollision>(
    new CustomerCollision()
  );
  const [initDataFields, setInitDataFields] = useState<VMCollisionGroupedField>(
    new VMCollisionGroupedField()
  );
  const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
  const [tempMapLocation, setTempMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const [mapLocation, setMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const params = useParams<ICollisionParams>();
  const [backPermission, setBackPermission] = useState(false);
  const validationRef = useRef<ValidationGroupRef>(null);
  const didMount = useRef(false);
  const { generalSetting, customerDivisions } = useClientSetting();
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);
  const [files, setFiles] = useState<any[]>([]);
  const [removedFile, setRemovedFile] = useState<string[]>([]);
  const [initDataUserDivisions, setInitDataUserDivisions] = useState<INameId[]>(
    []
  );
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const [lstGeoCodeStatus, setLstGeoCodeStatus] = useState<NameValue[]>([]);
  const [lstCollisionTesStatus, setLstCollisionTesStatus] = useState<
    NameValue[]
  >([]);
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const collisionNoRef = useRef<HTMLSpanElement>(null);
  const descRef = useRef<HTMLSpanElement>(null);
  const { user, getPagePermission } = useAuth();
  useBrowserTitle({
    pathname: "/collision/collisionDetails/",
    additionalText: `[${initDataCollision.geoId}] ${t("CollisionNo")}: ${
      initDataCollision?.general?.AccidentNum
    }`,
  });
  const [locationColor, setLocationColor] = useState<string>("#424242");
  const [locationCursor, setLocationCursor] = useState<string>("default");
  const [showValidationError, setShowValidationError] =
    useState<boolean>(false);
  const [collisionRes, setCollisionRes] = useState<
    RequestResponseResult<CustomerCollision>
  >(new RequestResponseResult<CustomerCollision>());

  const filteredTabTitles = useMemo(() => {
    return tabTitles.filter((x) =>
      user?.permissions?.includes(TMSPermissions.TMS_Admin) ? true : x.id !== 12
    );
  }, [user?.permissions, tabTitles]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstGeoCodeStatus(Enum2Array(GeoCodeStatus));
        setLstCollisionTesStatus(Enum2Array(CollisionTesStatus));
        setInitDataDivisions(
          await TesGet(
            AuthApiUrl() +
              "/api/divisions/" +
              localStorage.getItem("selectedCustomerId"),
            true
          )
        );
        await getInitialDataFields();
        await getInitDataUserDivisions();
        if (params.collisionId !== "AddNew") {
          await getInitDataCollision(params.collisionId!);
        } else {
          setIsLocked(false);
          setDataChanged(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.collisionId]);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitialDataFields() {
    setInitDataFields(
      await TesGet(
        FieldApiUrl() +
          "/api/TesFields/WebCollisionFields/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  //General Functions
  async function getInitDataCollision(id: string) {
    try {
      const res = (await TesGet(
        CollisionApiUrl() + "/api/Collisions/GetCollisionData/" + id,
        true
      )) as RequestResponseResult<CustomerCollision>;

      if (res.responseCode === ResponseCode.OK) {
        setInitDataCollision(res.results);
        if (getPagePermission)
          setPagePermissionStatus(
            getPagePermission(
              [
                CollisionPermissions.Collision_D,
                CollisionPermissions.Collision_E,
              ],
              res.results
            )
          );
        if (res.results.mapLocation != null) {
          setTempMapLocation(res.results.mapLocation);
          setMapLocation(res.results.mapLocation);
        }
      }
    } catch (error) {
      notify(t("errorInFetchData"), "error", 5000);
    }
  }

  async function getInitDataUserDivisions() {
    const res = await TesGet(
      AuthApiUrl() +
        "/api/divisions/GetUserDivisionUI/" +
        localStorage.getItem("selectedCustomerId"),
      true
    );
    setInitDataUserDivisions(res);
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.collisionId !== "AddNew") {
                  updateCollision();
                  setDataChanged(false);
                } else {
                  addCollision();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function addCollision() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        var postObj: CustomerCollision = {
          ...initDataCollision,
          customerId: localStorage.getItem("selectedCustomerId") as string,
          //It's coming from location picker
          //divisionId:  localStorage.getItem("defaultDivisionId")!,
        };
        var res = (await TesPost(
          CollisionApiUrl() + "/api/Collisions/AddNewCollision",
          postObj,
          true
        )) as RequestResponseResult<CustomerCollision>;

        if (res.responseCode === ResponseCode.OK) {
          await uploaAttachments(res.results.id);
        } else if (res.responseCode == ResponseCode.CollisionValidationError) {
          setCollisionRes(res);
          setShowValidationError(true);
        } else {
          await RequestErrorHandling(res);
        }
        if (activeLoading) activeLoading(false);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false);
  }

  async function updateCollision() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        var postObj: CustomerCollision = {
          ...initDataCollision,
          mapLocation: mapLocation,
          //customerId: localStorage.getItem("selectedCustomerId") as string
          //add the other things
        };
        var res = (await TesPost(
          CollisionApiUrl() + "/api/Collisions/UpdateCollision",
          postObj,
          true
        )) as RequestResponseResult<CustomerCollision>;
        if (res.responseCode === ResponseCode.OK) {
          await uploaAttachments(initDataCollision.id);
        } else if (res.responseCode == ResponseCode.CollisionValidationError) {
          setCollisionRes(res);
          setShowValidationError(true);
        } else {
          await RequestErrorHandling(res);
        }
        if (activeLoading) activeLoading(false);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    setDataChanged(false);
  }

  //Attachment
  async function uploaAttachments(id: string) {
    try {
      const data = new FormData();
      // eslint-disable-next-line array-callback-return
      files.map((a: any) => {
        data.append("file", a);
      });
      data.append(id, id);
      await TesPostUploadFile(
        CollisionApiUrl() + "/api/Collisions/AddAttachments",
        data
      );
      if (params.collisionId !== "AddNew") {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        if (backPermission === true) {
          history(-1);
        } else {
          await getInitDataCollision(params.collisionId!);
          setFiles([]);
        }
      } else {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
      }
    } catch (ex) {
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function handleChangeFile(lstFiles: any) {
    setFiles([...files, ...lstFiles]);
    setDataChanged(true);
  }

  function onDeleteNewFile(name: string) {
    setFiles([...files.filter((x) => x.name !== name)]);
    setDataChanged(true);
  }

  function onDeleteExistingFile(key: string) {
    setInitDataCollision({
      ...initDataCollision,
      attachments: initDataCollision.attachments.filter(
        (x) => x.fileName !== key
      ),
    });
    setRemovedFile([...removedFile, key]);
    setDataChanged(true);
  }

  function onOpenConfirmation() {
    setShowConfirmation(true);
  }
  async function onConfirmation(status: CollisionStatus) {
    try {
      var postObj = {
        status: status,
        collisionIds: [initDataCollision.id],
      };
      var res = (await TesPost(
        CollisionApiUrl() + "/api/Collisions/UpdateApprovalLevel",
        postObj,
        true
      )) as RequestResponseResult<object>;
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.UserNotPermitted) {
        notify(t("userNotPermitted"), "error", 5000);
      } else if (res.responseCode === ResponseCode.CollisionValidationError) {
        notify(t("collisionValidationError"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
      setShowConfirmation(false);
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + error), "error", 5000);
    }
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && collisionNoRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else if (option === "Location") {
        textToCopy = descRef.current?.innerText!;
      } else {
        textToCopy = collisionNoRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  const copyOneTextToClipboard = () => {
    if (collisionNoRef.current) {
      const textToCopy = collisionNoRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  function onLocationDetails() {
    if (initDataCollision?.locationType === LocationType.Intersection) {
      const newTabUrl =
        "/infrastructure/intersectionDetails/" + initDataCollision.locationId;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    } else if (initDataCollision?.locationType === LocationType.Midblock) {
      const newTabUrl =
        "/infrastructure/roadSegmentDetails/" + initDataCollision.locationId;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    }
  }

  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_V,
        CollisionPermissions.Collision_V_MyCollision,
        CollisionPermissions.Collision_D,
        CollisionPermissions.Collision_E,
        CollisionPermissions.Collision_Approve,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`collisionDetails ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t("collision")}: </span>
                    {initDataCollision?.general?.AccidentNum &&
                      initDataCollision?.general?.AccidentNum !== "" && (
                        <span ref={collisionNoRef}>
                          {initDataCollision.general.AccidentNum}
                        </span>
                      )}
                    {initDataCollision?.geoId &&
                      initDataCollision?.geoId !== "" && (
                        <>
                          <span> - </span>
                          <span
                            ref={descRef}
                            onMouseOver={(e) => {
                              setLocationColor("#F0B70d");
                              setLocationCursor("pointer");
                            }}
                            onMouseLeave={(e) => {
                              setLocationColor("#424242");
                              setLocationCursor("default");
                            }}
                            style={{
                              color: locationColor,
                              cursor: locationCursor,
                            }}
                            onClick={onLocationDetails}
                          >
                            {initDataCollision.locationDescription}
                          </span>
                          <span> - </span>
                          <span ref={geoIdRef}>
                            [{initDataCollision.geoId}]
                          </span>
                        </>
                      )}
                  </p>
                  {initDataCollision?.geoId &&
                  initDataCollision?.geoId !== "" ? (
                    <DropDownButton
                      style={{ marginLeft: 10, width: "6rem" }}
                      icon="fa-solid fa-copy"
                      items={[
                        { name: t("collisionNo"), value: t("collisionNo") },
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("location"), value: t("location") },
                      ]}
                      dropDownOptions={{
                        width: MaxDropdownItemWidthCalculator([
                          { name: t("collisionNo"), value: t("collisionNo") },
                          { name: t("geoId"), value: t("geoId") },
                          { name: t("location"), value: t("location") },
                        ]),
                      }}
                      displayExpr="name"
                      onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                      stylingMode="text"
                      hoverStateEnabled={false}
                      focusStateEnabled={false}
                    />
                  ) : (
                    <Button
                      onClick={() => copyOneTextToClipboard()}
                      icon="fa-solid fa-copy"
                      hint={t("copy")}
                    />
                  )}
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 10 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                  {params.collisionId !== "AddNew" && (
                    <ReportSelector
                      reportLocations={[
                        reportLocations.Collision_CollisionDetails,
                      ]}
                      getRecords={() => [{ id: initDataCollision.id }]}
                      className={style.reportSelector}
                    />
                  )}
                  {params.collisionId !== "AddNew" && (
                    <Permission
                      allowed={[CollisionPermissions.Collision_Approve]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        icon="fa-solid fa-check-double"
                        style={{ marginLeft: 15 }}
                        hint={t("confirmation")}
                        onClick={onOpenConfirmation}
                        disabled={isLocked}
                      />
                    </Permission>
                  )}
                </div>
                {pagePermissionStatus === PagePermission.Edit &&
                  params.collisionId !== "AddNew" && (
                    <Permission
                      allowed={[CollisionPermissions.Collision_E]}
                      hasFeedBackElement={false}
                    >
                      <div className="rightColumn">
                        <Button
                          onClick={() => LockHandler()}
                          icon={
                            isLocked
                              ? "fa-solid fa-lock"
                              : "fa-solid fa-lock-open"
                          }
                          hint={isLocked ? t("unlock") : t("lock")}
                        />
                      </div>
                    </Permission>
                  )}
                <div className="rightColumn">
                  {params.collisionId !== "AddNew" && <ShareURL url={url} />}
                  {params.collisionId === "AddNew" && (
                    <Permission
                      allowed={[CollisionPermissions.Collision_D]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => addCollision()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    </Permission>
                  )}
                  {params.collisionId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={() => updateCollision()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card"}>
              <TesTabs
                width={"100%"}
                dataSource={filteredTabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />
              {selectedIndex === 0 && (
                <General
                  didMount={didMount.current}
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  tempMapLocation={tempMapLocation}
                  mapLocation={mapLocation}
                  setMapLocation={setMapLocation}
                  validationRef={validationRef}
                  generalSettings={generalSetting}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                  initDataUserDivisions={initDataUserDivisions}
                  params={params}
                  lstGeoCodeStatus={lstGeoCodeStatus}
                  lstCollisionTesStatus={lstCollisionTesStatus}
                  user={user}
                  customerDivisions={customerDivisions}
                  initDataDivisions={initDataDivisions}
                />
              )}
              {selectedIndex === 1 && (
                <Location
                  didMount={didMount.current}
                  initDataCollision={initDataCollision}
                  tempMapLocation={tempMapLocation}
                  mapLocation={mapLocation}
                  setMapLocation={setMapLocation}
                  validationRef={validationRef}
                  setInitDataCollision={setInitDataCollision}
                  setTempMapLocation={setTempMapLocation}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 2 && (
                <Roads
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 3 && (
                <Vehicles
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 4 && (
                <Drivers
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 5 && (
                <Passengers
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 6 && (
                <Pedestrians
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 7 && (
                <People
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 8 && (
                <Attachments
                  isLocked={isLocked}
                  initDataCollision={initDataCollision}
                  files={files}
                  handleChangeFile={handleChangeFile}
                  onDeleteExistingFile={onDeleteExistingFile}
                  onDeleteNewFile={onDeleteNewFile}
                  validationRef={validationRef}
                  setInitDataCollision={setInitDataCollision}
                />
              )}
              {selectedIndex === 9 && (
                <Remarks
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  validationRef={validationRef}
                  setDataChanged={setDataChanged}
                  setInitDataCollision={setInitDataCollision}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 10 && (
                <ChangeLogs
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  validationRef={validationRef}
                />
              )}
              {selectedIndex === 11 && (
                <Validations
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  validationRef={validationRef}
                />
              )}
              {selectedIndex === 12 && (
                <MergeLogs
                  initDataCollision={initDataCollision}
                  initDataFields={initDataFields}
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  validationRef={validationRef}
                  lstCollisionTesStatus={lstCollisionTesStatus}
                  setInitDataCollision={setInitDataCollision}
                />
              )}
            </div>
          </div>

          {/* Confirmation Popup */}
          <Popup
            width={"50%"}
            height="auto"
            visible={showConfirmation}
            resizeEnabled={true}
            showTitle={true}
            title={t("confirmation")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowConfirmation(false)}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <p className="title">
                    {t(
                      "areYouSureYouWanToConfirmRejectTheSelectedCollisionsThisActionCanNotBeUndone"
                    )}
                  </p>
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onConfirmation(CollisionStatus.Rejected)}
                    text={t("reject")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={() => onConfirmation(CollisionStatus.Approved)}
                    text={t("confirm")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>

          <Popup
            visible={showValidationError}
            width={"50%"}
            height={"auto"}
            resizeEnabled={true}
            showTitle={true}
            title={t("validationErrors")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowValidationError(false)}
          >
            <div style={{ padding: "20px", fontSize: "16px" }}>
              <ul style={{ margin: 0, paddingLeft: "20px", listStyle: "none" }}>
                {collisionRes?.messages?.map((message, index) => (
                  <li
                    key={index}
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <span
                      style={{
                        color: "red",
                        marginRight: "8px",
                        lineHeight: "1.2",
                        verticalAlign: "top",
                      }}
                    >
                      •
                    </span>
                    <span style={{ lineHeight: "1.2" }}>{message.message}</span>
                  </li>
                ))}
              </ul>
            </div>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default CollisionDetails;
