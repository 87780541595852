import React, { useMemo, useState } from "react";
import { useTranslations } from "../../contexts/translations";
import { t } from "i18next";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { DropDownButtonTypes } from "devextreme-react/drop-down-button";
import { TClientReport } from "../../types/report/reportTypes";
import { TReportLocation } from "../../constants/reportLocations";
import MaxDropdownItemWidthCalculator from "../../utils/dropDownWidthCalculator";
import DropDownButton from "devextreme-react/drop-down-button";
import { useReports } from "../../contexts/reports";
import notify from "devextreme/ui/notify";
import reportSettings from "../../constants/report-settings";
import { TRecord, TReportType, TReportTypeName } from "./types";
import ReportViewer_v1 from "./ReportViewer_v1";
import { REPORT_TYPES, STATIC_REPORTS_OPTIONS } from "./constants";
import ValidationReport_v1 from "./ValidationReport_v1/validationReport";
import { TStudyReportRequest } from "../../types/trafficStudy/dtos/studyDto";
import { CreateDateAsUTC } from "../../utils/dateTimeTools";

type TProps = {
  reportLocations: TReportLocation[];
  className?: string;
  getRecords: () => Promise<TRecord[]> | TRecord[];
  onCloseReporterModal?: () => void;
  disabled?: boolean;
};

const ReportSelector = (props: TProps) => {
  const {
    className,
    reportLocations,
    getRecords,
    onCloseReporterModal,
    disabled = false,
  } = props;
  const { reportsData, reportsLoading } = useReports();
  const { translateObjectKeys } = useTranslations();

  const [selectedReport, setSelectedReport] = useState<{
    records: string[];
    data: TClientReport;
    type: TReportType;
    payload?: string;
  } | null>(null);

  const reportOptions = useMemo(() => {
    let options: TClientReport[] = [];
    if (reportsData && reportsData.length > 0) {
      options = reportsData.filter((item) =>
        item.reportLocations?.some((loc) =>
          reportLocations.includes(loc.name as TReportLocation)
        )
      );
    } else {
      options = [];
    }

    const staticReportOptions: TClientReport[] = [];
    reportLocations.forEach((loc) => {
      staticReportOptions.push(...(STATIC_REPORTS_OPTIONS[loc] ?? []));
    });
    if (staticReportOptions) {
      options.push(
        ...(translateObjectKeys(staticReportOptions, [
          "name",
        ]) as unknown as TClientReport[])
      );
    }
    return SortObjectByPropName(options, "name");
  }, [reportsData, reportLocations]);

  const maxWidthOfOptions = useMemo(() => {
    return MaxDropdownItemWidthCalculator(reportOptions);
  }, [reportOptions]);

  const getReportPayload = (
    reportTypeName: TReportTypeName,
    records: TRecord[]
  ): TStudyReportRequest[] | undefined => {
    let payload: TStudyReportRequest[] | undefined = [];

    if (reportTypeName === "AllWayStopWarrantsDetails") {
      payload = [
        {
          ReportTitle: t("allWayStopWarrant"),
          StudyId: records[0].id,
          TesReportTypeName: reportTypeName,
        },
      ];
    }
    if (
      reportTypeName === "TmcFullDiagram" ||
      reportTypeName === "TmcFullAADTDiagram"
    ) {
      payload = [
        {
          StudyId: records[0].id,
          TesReportTypeName: reportTypeName,
        },
      ];
    }
    if (
      reportTypeName === "TmcDiagram" ||
      reportTypeName === "TmcPhDiagram" ||
      reportTypeName === "TMCPhsAndFullDiagram" ||
      reportTypeName === "15min"
    ) {
      if (records[0].endDT === undefined || records[0].startDT === undefined) {
        payload = undefined;
      } else {
        payload = records.map((item) => {
          return {
            EndDT: CreateDateAsUTC(item.endDT!),
            ReportTitle: t("tmc"),
            StartDT: CreateDateAsUTC(item.startDT!),
            StudyId: item.id,
            TesReportTypeName: reportTypeName,
          };
        });
      }
    }
    if (reportTypeName === "SignalWarrantsDetails") {
      payload = [
        {
          ReportTitle: t("signalWarrants"),
          StudyId: records[0].id,
          TesReportTypeName: reportTypeName,
        },
      ];
    }
    if (
      reportTypeName === "SpeedRangeRawDataPerHour" ||
      reportTypeName === "SpeedRangeRawDataWithSpeedStatistics"
    ) {
      if (
        // records[0].endDT === undefined ||
        records[0].startDT === undefined ||
        records[0].approachType === undefined
      ) {
        payload = undefined;
      } else {
        payload = records.map((item) => {
          return {
            EndDT: item.endDT,
            ReportTitle: t("speedStatistics"),
            StartDT: item.startDT,
            StudyId: item.id,
            TesReportTypeName: reportTypeName,
            ApproachType: item.approachType!,
          };
        });
      }
    }
    if (
      reportTypeName === "TotalVolumePerHour" ||
      reportTypeName === "VolumeRawData" ||
      reportTypeName === "DetailVolumeRawData4Day" ||
      reportTypeName === "VolumeMovement"
    ) {
      payload = records.map((item) => {
        return {
          EndDT: item.endDT,
          StartDT: item.startDT,
          StudyId: item.id,
          TesReportTypeName: reportTypeName,
        };
      });
    }
    if (
      reportTypeName === "DailyClassReport" ||
      reportTypeName === "DailySpeedReport" ||
      reportTypeName === "WeeklyVolumeSummary"
    ) {
      payload = records.map((item) => {
        return {
          EndDT: item.endDT,
          StartDT: item.startDT,
          StudyId: item.id,
          TesReportTypeName: reportTypeName,
          ApproachType: item.approachType!,
        };
      });
    }

    return payload;
  };

  const onItemClick = async (option: DropDownButtonTypes.ItemClickEvent) => {
    const records = await getRecords();
    if (records.length === 0) {
      notify(t("pleaseSelectSomeData"), "warning", 5000);
      return;
    } else if (records.length > reportSettings.noMaxItems) {
      notify(t("selectCorrectNumberOfGrid"), "warning", 5000);
      return;
    }

    console.log("!!", option);

    if (option) {
      const selectedReportTypeName: TReportTypeName =
        option.itemData?.tesReportType?.name;
      const reportType = REPORT_TYPES[selectedReportTypeName];
      const payload = getReportPayload(selectedReportTypeName, records);
      if (reportType === "reportViewer_payload" && payload === undefined) {
        notify(t("pleaseSelectSomeData"), "warning", 5000);
        return;
      }

      const data = {
        records: records.map((item) => item.id),
        data: option.itemData,
        type: reportType,
        payload: JSON.stringify(payload),
      };
      setSelectedReport(data);
      console.log({
        records: records.map((item) => item.id),
        data: option.itemData,
        type: reportType,
        payload: getReportPayload(selectedReportTypeName, records),
      });
    }
  };

  const onCloseModalClick = () => {
    setSelectedReport(null);
    onCloseReporterModal?.();
  };
  return (
    <>
      <DropDownButton
        stylingMode={"text"}
        showArrowIcon={false}
        // width={50}
        className={className}
        icon="fa-solid fa-chart-line"
        hint={t("report")}
        items={reportOptions}
        dropDownOptions={{
          width: maxWidthOfOptions,
        }}
        displayExpr="name"
        onItemClick={onItemClick}
        disabled={disabled}
      />
      {((selectedReport && selectedReport.type === "reportViewer_ids") ||
        (selectedReport && selectedReport.type === "reportViewer_payload")) && (
        <ReportViewer_v1
          selectedReport={selectedReport.data}
          lstSelectedIds={selectedReport.records}
          isOpen={
            selectedReport.type === "reportViewer_ids" ||
            selectedReport.type === "reportViewer_payload"
          }
          onClose={onCloseModalClick}
          payload={selectedReport.payload}
        />
      )}
      {selectedReport && selectedReport.type === "validationReport" && (
        <ValidationReport_v1
          onClose={onCloseModalClick}
          isOpen={selectedReport.type === "validationReport"}
          collisionIds={selectedReport.records}
        />
      )}
    </>
  );
};

export default ReportSelector;
