import i18n from "../../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "hourFactors",
    icon: "fa-solid fa-clock",
    content: "Details tab content",
  },
  {
    id: 2,
    text: "dayFactors",
    icon: "fa-solid fa-sun",
    content: "Details tab content",
  },
  {
    id: 3,
    text: "monthFactors",
    icon: "fa-solid fa-calendar-days",
    content: "Details tab content",
  },
  {
    id: 4,
    text: "seasonalAdjustmentFactor",
    icon: "fa-solid fa-cloud-sun-rain",
    content: "Details tab content",
  },
  {
    id: 5,
    text: "locations",
    icon: "fa-solid fa-map",
    content: "locations tab content",
  },
];
export default tabTitles;
