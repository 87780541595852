//#region imports
import { ContextMenu } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../../../components/gisMap/generalGisMapManager";
import GridFilter from "../../../../../components/gridFilter/gridFilter";
import Permission from "../../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../../constants/Permissions";
import reportLocations from "../../../../../constants/reportLocations";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import {
  FieldApiUrl,
  TrafficApiUrl,
} from "../../../../../environment/routeSettings";
import {
  GridType,
  ResponseCode,
  TesMapModule,
} from "../../../../../types/general/enums/generalEnums";
import {
  GridSetting,
  LazyLoadingRequest,
  NameValue,
  RequestResponseResult,
} from "../../../../../types/general/generalTypes";
import { TesCodeValue } from "../../../../../types/infrastructure/infrastructureTypes";
import {
  AADTSourceType,
  AADTType,
} from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../../utils/rest";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";
import "./roadSegmentAADTs.scss";
import ReportSelector from "../../../../../components/reportSelector";
import { getSelectedRows } from "../../../../../components/tesGrid/utils";
import { TRecord } from "../../../../../components/reportSelector/types";
import useBrowserTitle from "../../../../../hooks/browserTitle/useBrowserTitle";
//#endregion imports

interface IPros {
  roadSegmentId: string;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
  disabled: boolean;
}

const AADT = (props: IPros) => {
  //#region consts
  const [showMap, setShowMap] = useState(false);
  const [lstSourceType, setLstSourceType] = useState<NameValue[]>([]);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const [showFilter, setShowFilter] = useState(false);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
  ];
  const [gridSetting, setGridSetting] = useState<GridSetting>(
    JSON.parse(localStorage.getItem("GridSetting")!) || new GridSetting()
  );
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { customerDivisions } = useClientSetting();
  //#endregion consts

  //#region functions

  useBrowserTitle({ pathname: "/trafficStudy/aadts/roadSegmentAADTs" });

  const dataSource = new CustomStore({
    load: async (loadOption) => {
      return await TesPost(
        `${TrafficApiUrl()}/api/AADT/GetLocationAADTLazyLoading`,
        {
          customerId: localStorage.getItem("selectedCustomerId"),
          divisionId: localStorage.getItem("selectedDivisionId"),
          loadOptions: loadOption,
          filter: { type: AADTType.RoadSegment, id: props.roadSegmentId },
        } as LazyLoadingRequest,
        true
      );
    },
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInfrastructureTesCodeValues();
        setLstSourceType(Enum2Array(AADTSourceType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/trafficStudy/aadts/aadtDetails/${AADTType.RoadSegment}/${e.data.id}`,
          "_blank"
        );
      } else {
        history(
          `/trafficStudy/aadts/aadtDetails/${AADTType.RoadSegment}/${e.data.id}`
        );
      }
    }
  }

  function onCloseReportModal() {
    dataGridRef.current?.instance().deselectAll();
  }

  async function onRecalculate() {
    if (activeLoading) activeLoading(true);
    var res = (await TesGet(
      TrafficApiUrl() +
        "/api/AADT/RecalculateAllAADTs/" +
        AADTType.RoadSegment +
        "/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<any>;
    if (res.responseCode === ResponseCode.OK) {
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
    } else {
      await RequestErrorHandling(res);
    }
    dataGridRef.current?.instance().refresh();
    if (activeLoading) activeLoading(false);
  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const getSelectedRowsData = async () => {
    const selectedRows = await getSelectedRows(dataGridRef.current);
    const records: TRecord[] = selectedRows.map((item) => {
      return {
        id: item?.id,
        fromDate: item?.fromDate,
        toDate: item?.toDate,
      };
    });
    return records;
  };

  //#endregion functions
  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.key.id,
                `/trafficStudy/aadts/aadtDetails/${AADTType.RoadSegment}/`,
                selectedRowData.row.data.locationType,
                selectedRowData.row.data.locationId
              )
            }
          />
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div
                className="row"
                style={{ marginTop: "-1.5rem", marginBottom: "-1rem" }}
              >
                <div
                  className="leftColumn"
                  style={{ display: "flex", paddingLeft: "10px" }}
                >
                  <ReportSelector
                    reportLocations={[
                      reportLocations.TrafficStudy_RoadSegmentAadtDetails_VolumeHistory,
                    ]}
                    getRecords={getSelectedRowsData}
                    onCloseReporterModal={onCloseReportModal}
                    disabled={props.isLocked}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                columnAutoWidth={gridSetting.horizontalScrolling}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.RoadSegmentAADTs.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="id"
                  visible={false}
                  caption={t("id")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="customerId"
                  visible={false}
                  caption={t("customerId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="geoId"
                  caption={t("geoId")}
                  visible={true}
                  visibleIndex={1}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="locationDescription"
                  caption={t("locationDescription")}
                  width={300}
                  visible={true}
                  visibleIndex={0}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="municipalityId"
                  visible={
                    initDataTesCodeValues.municipalities?.length !== 0 &&
                    initDataTesCodeValues.municipalities !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("municipality")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataTesCodeValues?.municipalities,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={initDataTesCodeValues?.municipalities?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="jurisdictionId"
                  visible={
                    initDataTesCodeValues.jurisdictions?.length !== 0 &&
                    initDataTesCodeValues.jurisdictions !== undefined
                  }
                  allowHeaderFiltering={true}
                  caption={t("jurisdiction")}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(
                      initDataTesCodeValues?.jurisdictions,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter
                    dataSource={initDataTesCodeValues?.jurisdictions?.map(
                      (x) => ({ text: x.name, value: x.id })
                    )}
                  >
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="year"
                  caption={t("year")}
                  visible={true}
                  sortOrder="desc"
                  sortIndex={0}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                {/* <Column dataField="segmentVolume" caption={t("segmentVolume")} visible={true} format="#,###">
                            <HeaderFilter>
                                <Search enabled/>
                            </HeaderFilter>
                            </Column> */}
                <Column
                  dataField="totalVolume"
                  caption={t("totalVolumeAadt")}
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="percentSummary"
                  caption={t("directionSplit")}
                  visible={true}
                  format="#.00"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="northPercent"
                  caption={t("northPercent")}
                  visible={false}
                  format="#.00"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="eastPercent"
                  caption={t("eastPercent")}
                  visible={false}
                  format="#.00"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="westPercent"
                  caption={t("westPercent")}
                  visible={false}
                  format="#.00"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="southPercent"
                  caption={t("southPercent")}
                  visible={false}
                  format="#.00"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="sourceType" caption={t("sourceType")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstSourceType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="divisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      customerDivisions!,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.AADT}
              dataGridRef={dataGridRef}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="locationId"
            />
          </Popup>

          {/*Grid Filter*/}
          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Study}
          />
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default AADT;
