import React, { useEffect, useState, useRef } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
//component imports
import { TesGet } from "../../../utils/rest";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import { t } from "i18next";
import { custom } from "devextreme/ui/dialog";
import "./userdetails.scss";
import { IUserDetailsParams } from "../../../types/identity/identityTypes";
import { VMUserProfileDetails } from "../../../types/identity/dto/identityDTO";
import { useAuth } from "../../../contexts/auth";
import {
  ApprovalLevel,
  ApprovalLevelXUser,
} from "../../../types/collision/collisionTypes";
import { VMAllFieldPermission } from "../../../types/field/dto/fieldDTO";
import templateSettings from "../../../constants/template-settings";
import Details from "./components/details/details";
import Password from "./components/password/password";
import Roles from "./components/roles/roles";
import Divisions from "./components/divisions/divisions";
import Permissions from "./components/permissions/permissions";
import FieldPermissions from "./components/fieldPermissions/fieldPermissions";
import Permission from "../../../components/permission/permision";
import {
  CustomerPermissions,
  UserPermissions,
} from "../../../constants/Permissions";
import {
  AuthApiUrl,
  CollisionApiUrl,
  FieldApiUrl,
} from "../../../environment/routeSettings";
import Clients from "./components/clients/clients";
import ShareURL from "../../../components/shareURL/shareURL";
import TesTabs from "../../../components/tesTab/tesTab";

const UserDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged] = useState(false);
  const [, setBackPermission] = useState(false);
  const [initData, setInitData] = useState<VMUserProfileDetails>(
    new VMUserProfileDetails()
  );
  const [initDataLevel, setInitDataLevel] = useState<ApprovalLevel[]>([]);
  const [initDataUserLevel, setInitDataUserLevel] = useState<
    Partial<ApprovalLevelXUser>
  >(new ApprovalLevelXUser());
  const [initDataFieldPermissions, setInitDataFieldPermissions] = useState<
    VMAllFieldPermission[]
  >([new VMAllFieldPermission()]);
  const params = useParams<IUserDetailsParams>();
  const { activeLoading, user } = useAuth();
  const didMount = useRef(false);
  const userRef = useRef<HTMLSpanElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
      return (didMount.current = true);
    }
    fetchMyAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      if (initData.id !== undefined) {
        getInitialDataLevels();
        getInitialUserLevels();
        getInitialDataFieldPermissions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initData]);

  async function getInitialData() {
    await setInitData(
      await TesGet(
        AuthApiUrl() + "/api/user/GetUserProfile/" + params.userId,
        true
      )
    );
  }

  async function getInitialDataLevels() {
    setInitDataLevel(
      await TesGet(
        CollisionApiUrl() + "/api/ApprovalLevels/" + initData.defaultCustomerId,
        true
      )
    );
  }
  async function getInitialUserLevels() {
    setInitDataUserLevel(
      await TesGet(
        CollisionApiUrl() +
          "/api/ApprovalLevels/GetUserLevel/" +
          params.userId +
          "/" +
          initData.defaultCustomerId,
        true
      )
    );
  }

  async function getInitialDataFieldPermissions() {
    setInitDataFieldPermissions(
      await TesGet(
        FieldApiUrl() +
          "/api/FieldPermissions/GetUserFields/" +
          params.userId +
          "/" +
          initData.defaultCustomerId,
        true
      )
    );
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  //permission functions
  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                //add();
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  const copyTextToClipboard = () => {
    if (userRef.current) {
      const textToCopy = userRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[UserPermissions.User_E, CustomerPermissions.Customer_E_User]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t("username")}: </span>
                  <span ref={userRef}>{initData.userName}</span>
                </p>
                <Button
                  onClick={() => copyTextToClipboard()}
                  icon="fa-solid fa-copy"
                  hint={t("copy")}
                />
                <hr
                  className="line"
                  style={{ display: "block", marginTop: "1rem" }}
                ></hr>
              </div>
            )}
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.userId !== "AddNew" && <ShareURL url={url} />}
              </div>
            </div>
          </div>
          <div className={"dx-card "}>
            <TesTabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onTabChange={handleTabChange}
            />
            {selectedIndex === 0 && (
              <Details
                initData={initData}
                userId={params.userId!}
                setInitData={setInitData}
                user={user}
              />
            )}
            {selectedIndex === 1 && (
              <Password initData={initData} user={user} />
            )}

            {selectedIndex === 2 && (
              <Roles initData={initData} setInitData={setInitData} />
            )}
            {selectedIndex === 3 && (
              <Divisions initData={initData} setInitData={setInitData} />
            )}
            {selectedIndex === 4 && (
              <Clients initData={initData} setInitData={setInitData} />
            )}
            {selectedIndex === 5 && (
              <Permissions
                initData={initData}
                initDataLevel={initDataLevel}
                initDataUserLevel={initDataUserLevel}
                setInitDataUserLevel={setInitDataUserLevel}
                setInitData={setInitData}
                getInitialData={getInitialData}
                getInitialUserLevels={getInitialUserLevels}
              />
            )}
            {selectedIndex === 6 && (
              <FieldPermissions
                initData={initData}
                setInitData={setInitData}
                initDataFieldPermissions={initDataFieldPermissions}
                setInitDataFieldPermissions={setInitDataFieldPermissions}
                getInitialDataFieldPermissions={getInitialDataFieldPermissions}
              />
            )}
          </div>

          <div></div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default UserDetails;
