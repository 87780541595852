//#region imports
import { ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import reportLocations from "../../../../../../constants/reportLocations";
import { useAuth } from "../../../../../../contexts/auth";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";
import {
  ExtraPercentile,
  SpeedStatisticsResult,
  StudySpeedWebDetailsDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import { ApproachType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import ReportSelector from "../../../../../../components/reportSelector";
import { getSelectedRows } from "../../../../../../components/tesGrid/utils";
import { TRecord } from "../../../../../../components/reportSelector/types";

//#endregion imports

// props
interface IPros {
  initDataSpeed: StudySpeedWebDetailsDTO;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const SpeedStatistics = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const summaryDataGridRef = useRef<DataGridRef<any, any>>(null);
  const [lstApproachType, setLstApproachType] = useState<NameValue[]>();
  const [flatDataSource, setFlatDataSource] =
    useState<SpeedStatisticsResult[]>();
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [filteredSummaryDataSource, setFilteredSummaryDataSource] =
    useState<SpeedStatisticsResult[]>();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstApproachType(Enum2Array(ApproachType));
        convert2Flat(props.initDataSpeed?.speedStatisticsResults);
        const SummaryDataSource = [
          props.initDataSpeed.speedStatisticsSummary,
          props.initDataSpeed.speedStatisticsSummaryDirection1,
          props.initDataSpeed.speedStatisticsSummaryDirection2,
        ];
        setFilteredSummaryDataSource(
          SummaryDataSource.filter(
            (element) => element.approachType !== ApproachType.Unknown
          )
        );
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function convert2Flat(data: SpeedStatisticsResult[]) {
    var objRes: SpeedStatisticsResult[] = [];
    data?.forEach((s) => {
      var obj: SpeedStatisticsResult = new SpeedStatisticsResult();
      obj.approachType = s.approachType;
      obj.startD = s.startD;
      obj.average = s.average;
      obj.compliance = s.compliance;
      obj.maximumSpeed = s.maximumSpeed;
      obj.minimumSpeed = s.minimumSpeed;
      obj.speed85thPercentile = s.speed85thPercentile;
      obj.speed95thPercentile = s.speed95thPercentile;
      obj.standardDeviation = s.standardDeviation;
      obj.median = s.median;
      obj.postedSpeed = s.postedSpeed;
      obj.pace = s.pace;
      obj.pacePercent = s.pacePercent;
      obj.total = s.total;
      if (s.extraPercentile) {
        s.extraPercentile.forEach((e) => {
          obj[e.percentile] = e.percentileValue;
        });
      }
      objRes.push(obj);
    });
    setFlatDataSource(objRes);
  }

  function onCloseReportModal() {
    dataGridRef.current?.instance().deselectAll();
  }

  const getSelectedRowsData = async () => {
    const selectedRows = await getSelectedRows(dataGridRef.current);
    const records: TRecord[] = selectedRows.map((item) => {
      return {
        id: props.initDataSpeed.id!,
        endDT: item.endDT,
        startDT: item.startD,
        approachType: item.approachType,
      };
    });
    return records;
  };

  //#endregion functions
  return (
    <div
      className={`studyDetails-speedStatistics ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <h5
            style={
              compactViewModel
                ? { margin: "0rem 0 -0.2rem 1rem" }
                : { margin: "2rem 0 -0.2rem 1rem" }
            }
          >
            {t("summary")}
          </h5>
          <DataGrid
            id="speedStatisticsGrid"
            ref={summaryDataGridRef}
            dataSource={filteredSummaryDataSource}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ margin: "0 1rem 1rem" }}
            onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {summaryDataGridRef?.current?.instance().deselectAll()}}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"always"}
            />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />

            <HeaderFilter visible={true} />

            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <SortByGroupSummaryInfo
              summaryItem="Total Count"
              sortOrder="desc"
            />
            <Summary>
              <GroupItem summaryType="count" alignByColumn name="Total Count" />
            </Summary>
            <Column
              alignment="left"
              dataField="id"
              allowSorting={true}
              caption={t("id")}
              visible={false}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="summary"
              caption={t("summary")}
              dataType="date"
              format={props.generalSettings?.dateFormat}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="approachType" caption={t("approachType")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              {lstApproachType !== undefined && (
                <Lookup
                  dataSource={lstApproachType}
                  valueExpr="value"
                  displayExpr="name"
                />
              )}
            </Column>
            <Column
              dataField="average"
              caption={t("average")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="median"
              caption={t("median")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="speed85thPercentile"
              caption={t("speed85thPercentile")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="speed95thPercentile"
              caption={t("speed95thPercentile")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {props.initDataSpeed?.speedStatisticsSummary?.length > 0 &&
              props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile &&
              props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile.map(
                (e: ExtraPercentile) => {
                  return (
                    <Column
                      dataField={e.percentile}
                      caption={e.percentile + t("thPercentile")}
                      format="#.00"
                      visible={true}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              )}
            <Column
              dataField="compliance"
              caption={t("compliance")}
              format="percent"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="postedSpeed"
              caption={t("postedSpeed")}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="standardDeviation"
              caption={t("standardDeviation")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="maximumSpeed"
              caption={t("maximumSpeed")}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="minimumSpeed"
              caption={t("minimumSpeed")}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="pace" caption={t("pace")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              dataField="pacePercent"
              caption={t("pacePercent")}
              visible={true}
              format="percent"
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column dataField="total" caption={t("total")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
          </DataGrid>

          <h5
            style={
              compactViewModel
                ? { margin: "0rem 0 -0.2rem 1rem" }
                : { margin: "2rem 0 -0.2rem 1rem" }
            }
          >
            {t("details")}
          </h5>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="leftColumn">
              <ReportSelector
                reportLocations={[
                  reportLocations.TrafficStudy_StudyDetails_SpeedStatistics,
                ]}
                getRecords={getSelectedRowsData}
                onCloseReporterModal={onCloseReportModal}
              />
            </div>
          </div>
          <DataGrid
            id="speedStatisticsGrid"
            ref={dataGridRef}
            dataSource={flatDataSource}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ margin: "0 1rem 1rem" }}
            onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"always"}
            />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />

            <HeaderFilter visible={true} />

            <SearchPanel
              visible={true}
              width={285}
              placeholder={t("search...")}
            />
            <ColumnChooser
              width={350}
              height={400}
              enabled={true}
              mode="select"
              sortOrder="asc"
            >
              <Search enabled />
            </ColumnChooser>
            <SortByGroupSummaryInfo
              summaryItem="Total Count"
              sortOrder="desc"
            />
            <Summary>
              <GroupItem summaryType="count" alignByColumn name="Total Count" />
            </Summary>
            <Column dataField="id" caption={t("id")} visible={false}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              dataField="startD"
              caption={t("startDT")}
              dataType="date"
              format={props.generalSettings?.dateFormat}
              sortOrder="desc"
              sortIndex={0}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="approachType" caption={t("approachType")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              {lstApproachType !== undefined && (
                <Lookup
                  dataSource={lstApproachType}
                  valueExpr="value"
                  displayExpr="name"
                />
              )}
            </Column>

            <Column
              dataField="average"
              caption={t("average")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="median"
              caption={t("median")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="speed85thPercentile"
              caption={t("speed85thPercentile")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="speed95thPercentile"
              caption={t("speed95thPercentile")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {props.initDataSpeed?.speedStatisticsResults?.length > 0 &&
              props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile &&
              props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile.map(
                (e: ExtraPercentile) => {
                  return (
                    <Column
                      dataField={e.percentile}
                      caption={e.percentile + t("thPercentile")}
                      format="#.00"
                      visible={true}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              )}
            <Column
              dataField="compliance"
              caption={t("compliance")}
              format="percent"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="postedSpeed"
              caption={t("postedSpeed")}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="standardDeviation"
              caption={t("standardDeviation")}
              format="#.00"
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="maximumSpeed"
              caption={t("maximumSpeed")}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column
              dataField="minimumSpeed"
              caption={t("minimumSpeed")}
              visible={true}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="pace" caption={t("pace")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              dataField="pacePercent"
              caption={t("pacePercent")}
              visible={true}
              format="percent"
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="total" caption={t("total")} visible={true}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </ValidationGroup>
      </React.Fragment>
    </div>
  );
};
export default SpeedStatistics;
