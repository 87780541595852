import { Form, NumberBox } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { SimpleItem } from "devextreme-react/form";
import { SelectBox } from "devextreme-react/select-box";
import { Switch } from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import { t } from "i18next";
import React, { useEffect } from "react";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import {
  FieldCategoryType,
  FieldPermission,
  FieldType,
} from "../../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../../types/field/fieldType";
import { PageMode } from "../../../../../types/general/enums/generalEnums";
import { TrafficSection } from "../../../../../types/infrastructure/infrastructureTypes";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { DatePickerDateCalculator } from "../../../../../utils/dateTimeTools";
import CountStationPicker from "../../../../../components/countStationPicker/countStationPicker";
import { NameValue } from "../../../../../types/general/generalTypes";
import TrafficSectionPicker from "../../../../../components/trafficSectionPicker/trafficSectionPicker";

interface IPros {
  initDataFields: TesField[] | undefined;
  initDataTrafficSection: TrafficSection;
  setInitDataTrafficSection: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  pageMode: number;
  validationRef: React.RefObject<ValidationGroupRef>;
  lstCountStationTypes: NameValue[];
}

const General = (props: IPros) => {
  const { customerDivisions } = useClientSetting();
  const { user } = useAuth();

  function onChangeFields(name: string, value: any, isUpperCase: boolean) {
    props.setInitDataTrafficSection({
      ...props.initDataTrafficSection,
      [name]: value,
    });
    if (props.pageMode === PageMode.Update) {
      props.setDataChanged(true);
    }
  }

  function onChangeDynamicFields(
    name: string,
    value: any,
    isUpperCase: boolean
  ) {
    props.setInitDataTrafficSection({
      ...props.initDataTrafficSection,
      general: {
        ...props.initDataTrafficSection.general,
        [name]: value,
      },
    });
    if (props.pageMode === PageMode.Update) {
      props.setDataChanged(true);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <CountStationPicker
                label={t("countStation")}
                name={"countStationId"}
                onChangeFields={onChangeFields}
                value={props.initDataTrafficSection.countStationId}
                isLocked={props.isLocked}
                lstCountStationTypes={props.lstCountStationTypes}
                valueChanged={(e) => {
                  props.setInitDataTrafficSection({
                    ...props.initDataTrafficSection,
                    countStationId: e,
                  });
                }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                // style={{ marginBottom: 20 }}
                label={t("name")}
                labelMode="floating"
                value={props.initDataTrafficSection.name}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                // style={{ marginBottom: 20 }}
                label={t("geoId")}
                labelMode="floating"
                value={props.initDataTrafficSection.geoId}
                onValueChange={(e) => onChangeFields("geoId", e, false)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TextBox>
            </SimpleItem>
            <SimpleItem
              colSpan={1}
              visible={user?.permissions?.some(
                (x) => x === TMSPermissions.TMS_Admin
              )}
            >
              <SelectBox
                placeholder=""
                // style={{ marginBottom: 20 }}
                label={t("division")}
                labelMode="floating"
                displayExpr="name"
                valueExpr="id"
                value={props.initDataTrafficSection.divisionId}
                onValueChange={(e) => onChangeFields("divisionId", e, false)}
                items={SortObjectByPropName(customerDivisions!, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t("lhrs")}
                labelMode="floating"
                value={props.initDataTrafficSection.lhrs}
                onValueChange={(e) => onChangeFields("lhrs", e, false)}
                className="modalInput"
                style={{ marginBottom: "0" }}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t("offset")}
                labelMode="floating"
                value={props.initDataTrafficSection.offset}
                onValueChange={(e) => onChangeFields("offset", e, false)}
                className="modalInput"
                style={{ marginBottom: "0" }}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TrafficSectionPicker
                label={t("overlapTrafficSection")}
                name={t("overlapTrafficSectionId")}
                onChangeFields={onChangeFields}
                value={props.initDataTrafficSection.overlapTrafficSectionId!}
                isLocked={props.isLocked}
                valueChanged={(e) => {
                  props.setInitDataTrafficSection({
                    ...props.initDataTrafficSection,
                    overlapTrafficSectionId: e,
                  });
                }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TrafficSectionPicker
                label={t("previousTrafficSection")}
                name={t("previousTrafficSectionId")}
                onChangeFields={onChangeFields}
                value={props.initDataTrafficSection.previousTrafficSectionId!}
                isLocked={props.isLocked}
                valueChanged={(e) => {
                  props.setInitDataTrafficSection({
                    ...props.initDataTrafficSection,
                    previousTrafficSectionId: e,
                  });
                }}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TrafficSectionPicker
                label={t("nextTrafficSection")}
                name={t("nextTrafficSectionId")}
                onChangeFields={onChangeFields}
                value={props.initDataTrafficSection.nextTrafficSectionId!}
                isLocked={props.isLocked}
                valueChanged={(e) => {
                  props.setInitDataTrafficSection({
                    ...props.initDataTrafficSection,
                    nextTrafficSectionId: e,
                  });
                }}
              />
            </SimpleItem>

            {/* eslint-disable-next-line array-callback-return */}
            {props.initDataFields &&
              props.initDataFields
                .filter(
                  (x) =>
                    x.fieldCategoryType ===
                      FieldCategoryType.InfrastructureTrafficSectionGeneral &&
                    (props.isLocked ? true : x.readOnlyField === false)
                )
                .map((f, i) => {
                  if (f.fieldType === FieldType.String) {
                    return (
                      <SimpleItem colSpan={1}>
                        <TextBox
                          // style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="floating"
                          value={
                            props.initDataTrafficSection.general &&
                            props.initDataTrafficSection.general[f.name]
                          }
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }
                  if (
                    f.fieldType === FieldType.Number ||
                    f.fieldType === FieldType.DecimalNumber ||
                    f.fieldType === FieldType.DoubleNumber
                  ) {
                    return (
                      <SimpleItem colSpan={1}>
                        <TextBox
                          // style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="floating"
                          value={
                            props.initDataTrafficSection.general &&
                            props.initDataTrafficSection.general[f.name]
                          }
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Boolean) {
                    return (
                      <SimpleItem colSpan={1}>
                        <Switch
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          value={
                            props.initDataTrafficSection.general &&
                            props.initDataTrafficSection.general[f.name]
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Date) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.initDataTrafficSection.general[f.name]
                                )
                              : props.initDataTrafficSection.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="date"
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          pickerType="calendar"
                          placeholder={props.generalSettings?.dateFormat}
                          displayFormat={props.generalSettings?.dateFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Time) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.initDataTrafficSection.general[f.name]
                                )
                              : props.initDataTrafficSection.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="time"
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          pickerType="rollers"
                          placeholder={props.generalSettings?.timeFormat}
                          displayFormat={props.generalSettings?.timeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.DateTime) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.initDataTrafficSection.general[f.name]
                                )
                              : props.initDataTrafficSection.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="datetime"
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          pickerType="calendar"
                          placeholder={props.generalSettings?.dateTimeFormat}
                          displayFormat={props.generalSettings?.dateTimeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.List) {
                    return (
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          items={SortObjectByPropName(f.fieldValues, "name")}
                          displayExpr="name"
                          valueExpr="id"
                          labelMode="floating"
                          label={f.labelText}
                          value={
                            props.initDataTrafficSection.general &&
                            props.initDataTrafficSection.general[f.name]
                          }
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    );
                  }
                })}
            <SimpleItem colSpan={1}>
              <div>{t("excludeMatchingTrending")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("excludeMatchingTrending")}
                value={props.initDataTrafficSection.excludeMatchingTrending}
                onValueChange={(e) =>
                  onChangeFields("excludeMatchingTrending", e, false)
                }
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div>{t("isNonAssumedRoad")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("isNonAssumedRoad")}
                value={props.initDataTrafficSection.isNonAssumedRoad}
                onValueChange={(e) =>
                  onChangeFields("isNonAssumedRoad", e, false)
                }
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div>{t("isBorderCrossing")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("isBorderCrossing")}
                value={props.initDataTrafficSection.isBorderCrossing}
                onValueChange={(e) =>
                  onChangeFields("isBorderCrossing", e, false)
                }
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div>{t("isOverLap")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("isOverLap")}
                value={props.initDataTrafficSection.isOverLap}
                onValueChange={(e) => onChangeFields("isOverLap", e, false)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div>{t("isPDCS")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("isPDCS")}
                value={props.initDataTrafficSection.isPDCS}
                onValueChange={(e) => onChangeFields("isPDCS", e, false)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div>{t("isDeleted")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("isDeleted")}
                value={props.initDataTrafficSection.isDeleted}
                onValueChange={(e) => onChangeFields("isDeleted", e, false)}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div style={{ color: "#424242" }}>{t("inactive")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("inactive")}
                value={props.initDataTrafficSection.inactive}
                onValueChange={(e) =>
                  onChangeDynamicFields("inactive", e, false)
                }
                disabled={props.isLocked}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default General;
