import { Button, Form, SelectBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  AuthApiUrl,
  CollisionApiUrl,
  FieldApiUrl,
  ReportApiUrl,
  SyncApiUrl,
} from "../../../environment/routeSettings";
import { CopySetting } from "../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { TesGet, TesPost } from "../../../utils/rest";

const CopySettings = () => {
  const [initData, setInitData] = useState([]);
  const [syncCopy, setSyncCopy] = useState<CopySetting>(new CopySetting());
  const [fieldCopy, setFieldCopy] = useState<CopySetting>(new CopySetting());
  const [identityCopy, setIdentityCopy] = useState<CopySetting>(
    new CopySetting()
  );
  const [reportCopy, setReportCopy] = useState<CopySetting>(new CopySetting());
  const [collisionCopy, setCollisionCopy] = useState<CopySetting>(
    new CopySetting()
  );
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    GetClients();
  }, []);

  async function GetClients() {
    setInitData(
      await TesGet(AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser", true)
    );
  }

  async function onCopySync() {
    await TesPost(SyncApiUrl() + "/api/Admin/CopyTesSyncs", syncCopy, true);
    notify(t("dataSuccessfullyAdded"), "success", 2500);
  }

  async function onCopyField() {
    await TesPost(FieldApiUrl() + "/api/Admin/CopyFields", fieldCopy, true);
    notify(t("dataSuccessfullyAdded"), "success", 2500);
  }

  async function onCopyIdentity() {
    await TesPost(
      AuthApiUrl() + "/api/adminTools/CopyIdentity",
      identityCopy,
      true
    );
    notify(t("dataSuccessfullyAdded"), "success", 2500);
  }

  async function onCopyReport() {
    var res = await TesPost(
      ReportApiUrl() + "/api/admin/CopyReports",
      reportCopy,
      true
    );
    notify(t("dataSuccessfullyAdded"), "success", 2500);
  }

  async function onCopyCollision() {
    var res = await TesPost(
      CollisionApiUrl() + "/api/AdminTools/CopyCollisionSettings",
      collisionCopy,
      true
    );
    notify(t("dataSuccessfullyAdded"), "success", 2500);
  }

  return (
    <div className={`copySetting ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        <h2 className={"content-block"}>{t("copySettings")}</h2>
        <div className={"content-block"}>
          <div
            className={"dx-card responsive-paddings"}
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Note
            </p>
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              The sequence of copying data matters.
            </p>
          </div>
          <div
            className={"dx-card responsive-paddings"}
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("sync")}
            </p>
            <Form colCount={20}>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("fromClient")}
                  labelMode="floating"
                  value={syncCopy?.fromCustomerId}
                  onValueChange={(e) =>
                    setSyncCopy({ ...syncCopy, fromCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("toClient")}
                  labelMode="floating"
                  value={syncCopy?.toCustomerId}
                  onValueChange={(e) =>
                    setSyncCopy({ ...syncCopy, toCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <Button
                  className="tes-modal-btn-add"
                  id="copyButton"
                  text={t("copy")}
                  style={{ marginTop: "0.5rem" }}
                  onClick={onCopySync}
                />
              </SimpleItem>
            </Form>
          </div>
          <div
            className={"dx-card responsive-paddings"}
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("identity")}
            </p>
            <Form colCount={20}>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("fromClient")}
                  labelMode="floating"
                  value={identityCopy?.fromCustomerId}
                  onValueChange={(e) =>
                    setIdentityCopy({ ...identityCopy, fromCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("toClient")}
                  labelMode="floating"
                  value={identityCopy?.toCustomerId}
                  onValueChange={(e) =>
                    setIdentityCopy({ ...identityCopy, toCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <Button
                  className="tes-modal-btn-add"
                  id="copyButton"
                  text={t("copy")}
                  style={{ marginTop: "0.5rem" }}
                  onClick={onCopyIdentity}
                />
              </SimpleItem>
            </Form>
          </div>
          <div
            className={"dx-card responsive-paddings"}
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("field")}
            </p>
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
              }}
            >
              Make sure the code groups are synchronized before copying the
              field.
            </p>

            <Form colCount={20}>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("fromClient")}
                  labelMode="floating"
                  value={fieldCopy?.fromCustomerId}
                  onValueChange={(e) =>
                    setFieldCopy({ ...fieldCopy, fromCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("toClient")}
                  labelMode="floating"
                  value={fieldCopy?.toCustomerId}
                  onValueChange={(e) =>
                    setFieldCopy({ ...fieldCopy, toCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <Button
                  className="tes-modal-btn-add"
                  id="copyButton"
                  text={t("copy")}
                  style={{ marginTop: "0.5rem" }}
                  onClick={onCopyField}
                />
              </SimpleItem>
            </Form>
          </div>
          <div
            className={"dx-card responsive-paddings"}
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("collision")}
            </p>
            <Form colCount={20}>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("fromClient")}
                  labelMode="floating"
                  value={collisionCopy?.fromCustomerId}
                  onValueChange={(e) =>
                    setCollisionCopy({ ...collisionCopy, fromCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("toClient")}
                  labelMode="floating"
                  value={collisionCopy?.toCustomerId}
                  onValueChange={(e) =>
                    setCollisionCopy({ ...collisionCopy, toCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <Button
                  className="tes-modal-btn-add"
                  id="copyButton"
                  text={t("copy")}
                  style={{ marginTop: "0.5rem" }}
                  onClick={onCopyCollision}
                />
              </SimpleItem>
            </Form>
          </div>
          <div
            className={"dx-card responsive-paddings"}
            style={{
              paddingLeft: "1.5rem",
              paddingRight: "1rem",
              paddingBottom: "0.5rem",
            }}
          >
            <p
              style={{
                marginTop: compactViewModel ? "0" : "-1rem",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("report")}
            </p>
            <Form colCount={20}>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("fromClient")}
                  labelMode="floating"
                  value={reportCopy?.fromCustomerId}
                  onValueChange={(e) =>
                    setReportCopy({ ...reportCopy, fromCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={9}>
                <SelectBox
                  label={t("toClient")}
                  labelMode="floating"
                  value={reportCopy?.toCustomerId}
                  onValueChange={(e) =>
                    setReportCopy({ ...reportCopy, toCustomerId: e })
                  }
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(initData, "name")}
                  searchEnabled={true}
                ></SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <Button
                  className="tes-modal-btn-add"
                  id="copyButton"
                  text={t("copy")}
                  style={{ marginTop: "0.5rem" }}
                  onClick={onCopyReport}
                />
              </SimpleItem>
            </Form>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default CopySettings;
