//#region imports
import { NumberBox, SelectBox, ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../../components/collapsibleCard/collapsibleCard";
import { useAuth } from "../../../../../../contexts/auth";
import { useClientSetting } from "../../../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";
import { FieldType } from "../../../../../../types/field/enums/fieldEnums";
import { ResponseCode } from "../../../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../../../types/general/generalTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";
import {
  StudyAADTCalculationResult,
  StudyAadtRecalculationReqDTO,
  StudySpeedWebDetailsDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import { AADTSourceType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { FormatDT } from "../../../../../../utils/dateTimeTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../../../../utils/rest";
//#endregion imports

// props
interface IPros {
  initDataSpeed: StudySpeedWebDetailsDTO;
  setInitDataSpeed: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const RoadSegmentAADT = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [lstSourceType, setLstSourceType] = useState<NameValue[]>();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isTotalVisible, setIsTotalVisible] = useState(true);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstSourceType(Enum2Array(AADTSourceType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onRecalculate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: StudyAadtRecalculationReqDTO = {
        studyId: props.initDataSpeed.id!,
        sourceType: AADTSourceType.Count,
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Study/ReCalculateAADT",
        postObj,
        true
      )) as RequestResponseResult<StudyAADTCalculationResult>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        props.setInitDataSpeed({
          ...props.initDataSpeed,
          aadTs: res.results.roadSegmentAADTs,
          aadt: res.results.roadSegmentAADT,
        });
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.CalculationError) {
        notify(t("calculationError"), "error", 5000);
      } else if (res.responseCode === ResponseCode.AADTFactorsNotExist) {
        notify(t("aadtfactorsNotExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function customizeArgumentAxisLabels({ value }: { value: any }) {
    const date = new Date(value);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function customizeTooltip({
    argument,
    seriesName,
    value,
  }: {
    argument: any;
    seriesName: any;
    value: any;
  }) {
    return {
      text: `${FormatDT(argument, FieldType.Date, true, props.generalSettings)}, ${seriesName}, ${value}`,
    };
  }

  //#endregion functions
  return (
    <div
      className={`studyDetails-roadSegmentAADT ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ margin: "1rem" }}>
          <ValidationGroup ref={props.validationRef}>
            <div
              className={"dx-card"}
              id="iconsCard"
              style={{ margin: "0rem 1rem 2rem 1rem" }}
            >
              <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
                <div className="leftColumn">
                  <Button
                    icon="fa-solid fa-calculator"
                    style={{ marginRight: 10 }}
                    hint={t("recalculate")}
                    onClick={onRecalculate}
                    disabled={props.isLocked}
                  />
                </div>
              </div>
            </div>
            <Form colCount={1}>
              <SimpleItem colSpan={1}>
                <div id="studyAADTCollapse">
                  <CollapsibleCard title={t("studyAADT")} isOpen={true}>
                    <Form colCount={3}>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          step={0}
                          label={t("segmentAADT")}
                          labelMode="floating"
                          value={props.initDataSpeed?.aadt?.segmentVolume}
                          // onValueChange={(e) => onValueChange("majorVolume", e)}
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>

                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("sourceType")}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode="floating"
                          value={props.initDataSpeed?.aadt?.sourceType}
                          //   onValueChange={(e) => onValueChange("sourceType", e)}
                          className="modalInput"
                          items={lstSourceType}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    </Form>
                  </CollapsibleCard>
                </div>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <Form colCount={1}>
                  <SimpleItem colSpan={1}>
                    <DataGrid
                      id="roadSegmentGrid"
                      ref={dataGridRef}
                      dataSource={props.initDataSpeed?.aadTs ?? []}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      onExporting={OnExporting}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />
                      <FilterRow visible={true} applyFilter="auto" />
                      <HeaderFilter visible={true} />

                      <SearchPanel
                        visible={true}
                        width={285}
                        placeholder={t("search...")}
                      />
                      <ColumnChooser
                        width={350}
                        height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>
                      <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                      />
                      <Summary>
                        <GroupItem
                          summaryType="count"
                          alignByColumn
                          name="Total Count"
                        />
                      </Summary>
                      <Column dataField="id" caption={t("id")} visible={false}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        dataField="aadtRawDataDT"
                        caption={t("date")}
                        visible={true}
                        dataType="datetime"
                        format={generalSetting?.dateFormat}
                        sortOrder="desc"
                        sortIndex={0}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>

                      <Column
                        dataField="segmentVolume"
                        caption={t("segmentAADT")}
                        visible={true}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>

                      <Column dataField="sourceType" caption={t("sourceType")}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                        {lstSourceType !== undefined && (
                          <Lookup
                            dataSource={SortObjectByPropName(
                              lstSourceType,
                              "name"
                            )}
                            valueExpr="value"
                            displayExpr="name"
                          />
                        )}
                      </Column>
                    </DataGrid>
                  </SimpleItem>
                  {/* {props.initDataSpeed?.aadTs.length > 1 &&
                                <SimpleItem colSpan={1}>
                                    <div style={{marginTop:"-1rem"}}>
                                    <Chart id="chart"
                                        dataSource={props.initDataSpeed?.aadTs ?? []}
                                        >
                                        <Margin top={0} bottom={10} left={20} right={20} />
                                        <CommonSeriesSettings argumentField="aadtRawDataDT" type="line"/>
                                      <Series
                                        valueField="totalVolume"
                                        name={t('total')}
                                        color="#0b1d78"
                                        axis="leftAxis"
                                        // visible={isTotalVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <ValueAxis
                                        name="leftAxis"
                                        position="left"
                                        // valueType="startDT"
                                        showZero={true}
                                        valueMarginsEnabled={false}
                                        allowDecimals={false}
                                      >
                                        <Title text={t("totalVolume")} />
                                      </ValueAxis>
                                      <ArgumentAxis title={t('year')} >
                                        <Label customizeText={customizeArgumentAxisLabels}/>
                                      </ArgumentAxis>
                                      <Legend position="inside" horizontalAlignment="right" />
                                      <Export enabled={true} />
                                      <Tooltip enabled={true} location="edge" />
                                      <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip} />
                                    </Chart>
                                </div>
                                </SimpleItem>} */}
                </Form>
              </SimpleItem>
            </Form>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default RoadSegmentAADT;
