import { DataGrid, NumberBox, SelectBox } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  HeaderFilter,
  Pager,
  Paging,
  Search,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../../../components/collapsibleCard/collapsibleCard";
import { NameValue } from "../../../../../../../types/general/generalTypes";
import {
  SectionDetailType,
  SectionType,
} from "../../../../../../../types/infrastructure/enums/infrastructureEnums";
import {
  AllWayStopMinimumVolumeWarrant,
  AllWayStopWarrants,
  StudyBaseDTO,
} from "../../../../../../../types/trafficStudy/dtos/studyDto";
import { SortObjectByPropName } from "../../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../../utils/enumTools";

// props
interface IPros {
  allWayStopWarrants: AllWayStopWarrants;
  isLocked: boolean;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  initDataBase: StudyBaseDTO;
}
const Local = (props: IPros) => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] =
    useState<AllWayStopMinimumVolumeWarrant>(
      new AllWayStopMinimumVolumeWarrant()
    );
  const [gridAData, setGridAData] = useState<any[]>([]);
  const [gridBData, setGridBData] = useState<any[]>([]);
  const [gridCData, setGridCData] = useState<any[]>([]);
  const [lstSiteType, setLstSiteType] = useState<NameValue[]>([]);
  const gridARef = useRef<DataGridRef<any, any>>(null);
  const gridBRef = useRef<DataGridRef<any, any>>(null);
  const gridCRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      var res = props.allWayStopWarrants?.local[0];
      if (res) {
        setSelectedData(res);
        setLstSiteType(Enum2Array(SectionType));
        generateGridADataAData(res);
        generateGridBDataAData(res);
        generateGridCDataAData(res);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initDataBase]);

  function onValueChange(name: string, value: any) {
    let items = [...props.allWayStopWarrants?.local];
    var index = props.allWayStopWarrants?.local.findIndex(
      (x) => x.name === selectedData.name
    );
    let item = { ...items[index] };
    item = { ...item, [name]: value };
    items[index] = item;
    props.setInitDataBase({
      ...props.initDataBase,
      allWayStopWarrantConfigChanged: true,
      allWayStopWarrants: {
        ...props.initDataBase.allWayStopWarrants,
        local: items,
      },
    });
    setSelectedData(item);
  }

  function onJustificationDateTimeChange(value: any) {
    var res = props.allWayStopWarrants?.local.find((x) => x.name === value)!;
    generateGridADataAData(res);
    generateGridBDataAData(res);
    generateGridCDataAData(res);
    setSelectedData(res);
  }

  function generateGridADataAData(data: AllWayStopMinimumVolumeWarrant) {
    let approachingAlllegs: any = {};
    approachingAlllegs["title"] = t("approachingAlllegs");
    let TotalSum = 0;
    data.results?.forEach((element) => {
      approachingAlllegs[element.hour] = element.allApproachVolume;
      TotalSum += element.allApproachVolume;
    });
    approachingAlllegs["total"] = TotalSum;
    approachingAlllegs["average"] = TotalSum / 8;

    let totalSatisfied: any = {};

    totalSatisfied["title"] =
      t("totalVolumeAllLegs") + " > " + data.totalVolumeAllLegs;
    data.results?.forEach((element) => {
      totalSatisfied[element.hour] = element.allApproachVolumeSatisfied;
    });

    setGridAData([approachingAlllegs, totalSatisfied]);
  }

  function generateGridBDataAData(data: AllWayStopMinimumVolumeWarrant) {
    //Minor Approach
    let minorApproach: any = {};
    minorApproach["title"] = t("approaching:Minor");
    let minorTotalSum = 0;
    data.results?.forEach((element) => {
      minorApproach[element.hour] = element.minorApproachVolume;
      minorTotalSum += element.minorApproachVolume;
    });
    minorApproach["total"] = minorTotalSum;
    minorApproach["average"] = minorTotalSum / 8;

    //Pedestrian Crossing
    let pedestrianApproach: any = {};

    pedestrianApproach["title"] = t("pedestrianCrossingMajorRoad");
    let pedestrianTotalSum = 0;
    data.results?.forEach((element) => {
      pedestrianApproach[element.hour] =
        element.pedestrianCrossingOnMajorVolume;
      pedestrianTotalSum += element.pedestrianCrossingOnMajorVolume;
    });
    pedestrianApproach["total"] = pedestrianTotalSum;
    pedestrianApproach["average"] = pedestrianTotalSum / 8;

    //Pedestrian + Vehicle Crossing
    let pedestrianVehicleApproach: any = {};

    pedestrianVehicleApproach["title"] = t(
      "pedestrianVehicleCrossingMajorRoad"
    );
    let pedestrianVehicleTotalSum = 0;
    data.results?.forEach((element) => {
      pedestrianVehicleApproach[element.hour] = element.totalVolumeMinor;
      pedestrianVehicleTotalSum += element.totalVolumeMinor;
    });
    pedestrianVehicleApproach["total"] = pedestrianVehicleTotalSum;
    pedestrianVehicleApproach["average"] = pedestrianVehicleTotalSum / 8;

    //Last Row
    let totalSatisfied: any = {};

    totalSatisfied["title"] =
      t("totalPedVehCrossingMajor") +
      " > " +
      data.totalVehPedVolumeCrossingMajor;
    data.results?.forEach((element) => {
      totalSatisfied[element.hour] = element.minorApproachVolumeSatisfied;
    });

    setGridBData([
      minorApproach,
      pedestrianApproach,
      pedestrianVehicleApproach,
      totalSatisfied,
    ]);
  }

  function generateGridCDataAData(data: AllWayStopMinimumVolumeWarrant) {
    //Minor Approach
    let approachMajor: any = {};
    approachMajor["title"] = t("approach:Major");
    let totalApproachOnMajorSum = 0;
    data.results?.forEach((element) => {
      approachMajor[element.hour] = element.majorApproachVolume;
      totalApproachOnMajorSum += element.majorApproachVolume;
    });
    approachMajor["total"] = totalApproachOnMajorSum;
    approachMajor["average"] = totalApproachOnMajorSum / 8;

    //Pedestrian Crossing
    let approachMajorPercent: any = {};

    approachMajorPercent["title"] = t("%TotalApproachOnMajor");
    data.results?.forEach((element) => {
      approachMajorPercent[element.hour] = element.totalPercentOnMajor;
    });

    //Last Row
    let totalSatisfied: any = {};

    totalSatisfied["title"] =
      t("%SplitOnMajor") +
      " < " +
      (data.sectionType === SectionType.FourLegIntersection
        ? data.splitOnMajor4Leg
        : data.splitOnMajor3Leg);
    data.results?.forEach((element) => {
      totalSatisfied[element.hour] = element.totalPercentOnMajorSatisfied;
    });

    setGridCData([approachMajor, approachMajorPercent, totalSatisfied]);
  }

  return (
    <div
      className={`studyDetails-allWay-local ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <div
                id="period"
                style={{ fontWeight: "bold", fontSize: 16, marginTop: "1rem" }}
              >
                {t("period")}
              </div>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div className="rightColumn">
                <p id="period" style={{ fontWeight: "bold", fontSize: 16 }}>
                  {t("thisStudyIs")}
                  <span>
                    {selectedData.isJustified
                      ? t("justified")
                      : t("notJustifiedLower")}
                  </span>
                </p>
              </div>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("dateTime")}
                valueExpr="name"
                displayExpr="name"
                labelMode="floating"
                value={selectedData.name}
                onValueChange={(e) => onJustificationDateTimeChange(e)}
                className="modalInput"
                items={SortObjectByPropName(
                  props.allWayStopWarrants?.local,
                  "name"
                )}
                // disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1} />
            <SimpleItem colSpan={2}>
              <div id="configurationsCollapse">
                <CollapsibleCard
                  title={t("configsRelatedToPeriodSelection")}
                  isOpen={false}
                >
                  <Form colCount={3} className="popupFields">
                    <SimpleItem colSpan={1}>
                      <SelectBox
                        placeholder=""
                        label={t("sectionType")}
                        valueExpr="value"
                        displayExpr="name"
                        labelMode="floating"
                        value={
                          props.initDataBase?.sectionType ===
                          SectionDetailType.FourLegIntersection
                            ? SectionType.FourLegIntersection
                            : SectionType.TIntersection
                        }
                        // onValueChange={e => onValueChange("sectionType", e)}
                        className="modalInput"
                        items={SortObjectByPropName(lstSiteType, "name")}
                        disabled={true}
                        showClearButton={true}
                        searchEnabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <NumberBox
                        format="#,###"
                        step={0}
                        label={t("totalVolumeAllLegs")}
                        labelMode="floating"
                        value={selectedData.totalVolumeAllLegs}
                        onValueChange={(e) =>
                          onValueChange("totalVolumeAllLegs", e)
                        }
                        className="modalInput"
                        disabled={props.isLocked}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <NumberBox
                        format="#,###"
                        step={0}
                        label={t("splitOnMajor4way")}
                        labelMode="floating"
                        value={selectedData.splitOnMajor4Leg}
                        onValueChange={(e) =>
                          onValueChange("splitOnMajor4Leg", e)
                        }
                        className="modalInput"
                        disabled={props.isLocked}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <NumberBox
                        format="#,###"
                        step={0}
                        label={t("totalVehPedCrossingMajor")}
                        labelMode="floating"
                        value={selectedData.totalVehPedVolumeCrossingMajor}
                        onValueChange={(e) =>
                          onValueChange("totalVehPedVolumeCrossingMajor", e)
                        }
                        className="modalInput"
                        disabled={props.isLocked}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <NumberBox
                        format="#,###"
                        step={0}
                        label={t("splitOnMajor3way")}
                        labelMode="floating"
                        value={selectedData.splitOnMajor3Leg}
                        onValueChange={(e) =>
                          onValueChange("splitOnMajor3Leg", e)
                        }
                        className="modalInput"
                        disabled={props.isLocked}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <NumberBox
                        format="#,###"
                        step={0}
                        label={t("hoursNeededForJustification")}
                        labelMode="floating"
                        value={selectedData.hoursNeededForJustification}
                        onValueChange={(e) =>
                          onValueChange("hoursNeededForJustification", e)
                        }
                        className="modalInput"
                        disabled={props.isLocked}
                      />
                    </SimpleItem>
                  </Form>
                </CollapsibleCard>
              </div>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <div className="row" style={{ marginTop: 20, padding: 10 }}>
                <h6
                  className={"content-block"}
                  style={
                    !compactViewModel
                      ? { margin: "0rem 1rem 1rem 1rem" }
                      : { margin: "-1rem 1rem 0rem 1rem" }
                  }
                >
                  {t("analysis")}
                </h6>
                <DataGrid
                  ref={gridARef}
                  id="analysisGrid grid"
                  dataSource={gridAData}
                  allowColumnReordering={true}
                  showBorders={true}
                  onExporting={OnExporting}
                >
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Export enabled={true} allowExportSelectedData={true} />
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    alignment="left"
                    dataField="id"
                    allowSorting={true}
                    caption={t("id")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    minWidth={270}
                    dataField="title"
                    caption={t("")}
                  ></Column>

                  {selectedData.results?.map((x) => {
                    return (
                      <Column
                        minWidth={75}
                        dataField={x.hour}
                        caption={x.hour}
                        format="#,###"
                      ></Column>
                    );
                  })}
                  <Column
                    minWidth={90}
                    dataField="total"
                    caption={t("total")}
                    format="#,###"
                  ></Column>
                  <Column
                    minWidth={90}
                    dataField="average"
                    caption={t("average")}
                    format="#,###"
                  ></Column>
                </DataGrid>

                <DataGrid
                  ref={gridBRef}
                  id="grid"
                  dataSource={gridBData}
                  allowColumnReordering={true}
                  showBorders={true}
                  onExporting={OnExporting}
                >
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Export enabled={true} allowExportSelectedData={true} />
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    alignment="left"
                    dataField="id"
                    allowSorting={true}
                    caption={t("id")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    minWidth={270}
                    dataField="title"
                    caption={t("")}
                  ></Column>

                  {selectedData.results?.map((x) => {
                    return (
                      <Column
                        minWidth={75}
                        dataField={x.hour}
                        caption={x.hour}
                        format="#,###"
                      ></Column>
                    );
                  })}
                  <Column
                    minWidth={90}
                    dataField="total"
                    caption={t("total")}
                    format="#,###"
                  ></Column>
                  <Column
                    minWidth={90}
                    dataField="average"
                    caption={t("average")}
                    format="#,###"
                  ></Column>
                </DataGrid>

                <DataGrid
                  ref={gridCRef}
                  id="grid"
                  dataSource={gridCData}
                  allowColumnReordering={true}
                  showBorders={true}
                  onExporting={OnExporting}
                >
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Export enabled={true} allowExportSelectedData={true} />
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    alignment="left"
                    dataField="id"
                    allowSorting={true}
                    caption={t("id")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    minWidth={270}
                    dataField="title"
                    caption={t("")}
                  ></Column>

                  {selectedData.results?.map((x) => {
                    return (
                      <Column
                        minWidth={75}
                        dataField={x.hour}
                        caption={x.hour}
                        format="#,###"
                      ></Column>
                    );
                  })}
                  <Column
                    minWidth={90}
                    dataField="total"
                    caption={t("total")}
                    format="#,###"
                  ></Column>
                  <Column
                    minWidth={90}
                    dataField="average"
                    caption={t("average")}
                    format="#,###"
                  ></Column>
                </DataGrid>
              </div>
            </SimpleItem>
          </Form>
        </div>
      </React.Fragment>
    </div>
  );
};
export default Local;
