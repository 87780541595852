import { ContextMenu } from "devextreme-react";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import ValidationGroup, {
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../../components/permission/permision";
import { SignPermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { SignApiUrl } from "../../../../../environment/routeSettings";
import { TesField } from "../../../../../types/field/fieldType";
import { GridType } from "../../../../../types/general/enums/generalEnums";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { TesGet } from "../../../../../utils/rest";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";

// props
interface IPros {
  intersectionId: string;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Sign = (props: IPros) => {
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialDataFields() {
    const res = await TesGet(
      SignApiUrl() + "/api/TesSigns/GetSignOfLocation/" + props.intersectionId,
      true
    );
    setInitDataFields(res);
  }

  function replaceStr(str: string) {
    str = str.replaceAll("/", "");
    str = str.replaceAll(":", "");
    str = str.replaceAll("*", "");
    str = str.replaceAll("?", "");
    str = str.replaceAll(">", "");
    str = str.replaceAll("<", "");
    str = str.replaceAll("|", "");
    str = str.replaceAll("\\", "");
    str = str.replaceAll(" ", "-");
    return str;
  }

  function calculateImageTypeCell(d: any) {
    const data = d.data;
    if (data.signCode) {
      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              "http://staticfile.tes.ca/sign/" +
              localStorage.getItem("selectedCustomerName") +
              "/" +
              replaceStr(data.signCode.code) +
              ".png"
            }
            width="50"
            height="50"
          />
        </div>
      );
    }
    if (data.signCodeTitle) {
      return (
        <div>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img
            src={
              "http://staticfile.tes.ca/sign/" +
              localStorage.getItem("selectedCustomerName") +
              "/" +
              replaceStr(data.signCodeTitle) +
              ".png"
            }
            width="50"
            height="50"
          />
        </div>
      );
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data._id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(`/sign/signDetails/${e.row.data._id}`, "_blank");
      } else {
        history(`/sign/signDetails/${e.row.data._id}`);
      }
    }
  }

  return (
    <Permission
      allowed={[
        SignPermissions.Sign_D,
        SignPermissions.Sign_R,
        SignPermissions.Sign_V,
        SignPermissions.Sign_E,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`intersectionDetails-signs ${
          compactViewModel ? "compactStyle" : ""
        }`}
      >
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data._id,
                "/sign/signDetails/"
              )
            }
          />
          <ValidationGroup ref={props.validationRef}>
            <div className={"content-block"}>
              <div className={"dx-card responsive-paddings"}>
                <div className="row">
                  <div className="leftColumn">
                    {/* <CollisionDiagram
                                getCollisionDiagramData={this.getCollisionDiagramData.bind(this)}
                                lstCollisionIds={this.state.lstCollisionIds}
                            /> */}
                  </div>

                  {/* <div className="rightColumn">
                <Button
                  onClick={navigateToSignDetails}
                  icon="fa-solid fa-circle-plus"
                  text={t("add")}
                  disabled={props.isLocked}
                />
              </div> */}
                </div>

                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  key="id"
                  dataSource={initDataFields}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  onRowClick={onRowClicked}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  style={{ margin: "0 1rem" }}
                  onExporting={OnExporting}
                  onContextMenuPreparing={(e) => {
                    setSelectedRowData(e);
                  }}
                  //onContentReady={e => {e.component.deselectAll()}}
                  //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.SignsInIntersectionDetails.toString()}
                    savingTimeout={500}
                  />

                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />

                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                  />

                  <FilterRow visible={true} applyFilter="auto" />

                  <HeaderFilter visible={true} />

                  <SearchPanel
                    visible={true}
                    width={285}
                    placeholder={t("search...")}
                  />
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>

                  <Column
                    alignment="left"
                    dataField="id"
                    allowSorting={true}
                    caption={t("id")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="ChangeStatus"
                    cellRender={calculateImageTypeCell.bind(this)}
                    caption={t("symbol")}
                    width={100}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="geoId" caption={t("geoId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    dataField="supportLocationTypeTitle"
                    caption={t("locationType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="xCoord"
                    caption={t("xCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="yCoord"
                    caption={t("yCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="latitude"
                    dataType="number"
                    caption={t("latitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="longitude"
                    dataType="number"
                    caption={t("longitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportId"
                    caption={t("supportId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="supportCodeTitle"
                    caption={t("code")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="height"
                    caption={t("height")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="supportPositionTitle"
                    caption={t("position")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="supportConditionTitle"
                    caption={t("condition")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="dateInstalled"
                    dataType="date"
                    format={props.generalSettings?.dateFormat}
                    caption={t("installationDate")}
                    sortOrder="desc"
                    sortIndex={0}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="dateExpired"
                    dataType="date"
                    format={props.generalSettings?.dateFormat}
                    caption={t("expirationDate")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="materialCost"
                    caption={t("materialCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="labourCost"
                    caption={t("labourCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="installationCost"
                    caption={t("installationCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  {/* <Column alignment="left" dataField="dateInstalled" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                            caption={t("installationDate")}>
                            <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                        </Column>

                        <Column alignment="left" dataField="dateExpired" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                            caption={t("expirationDate")}>
                            <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                        </Column> */}

                  <Column
                    alignment="left"
                    dataField="materialCost"
                    caption={t("materialCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="labourCost"
                    caption={t("labourCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="installationCost"
                    caption={t("installationCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="latitude"
                    dataType="number"
                    caption={t("latitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="longitude"
                    dataType="number"
                    caption={t("longitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="xCoord"
                    dataType="number"
                    caption={t("xCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="yCoord"
                    dataType="number"
                    caption={t("yCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </DataGrid>
              </div>
            </div>
          </ValidationGroup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Sign;
