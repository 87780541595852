import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
  ReportApiUrl,
  TrafficApiUrl,
} from "../../../environment/routeSettings";
import { VMMapLocation } from "../../../types/collision/dto/collisionDtos";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { FieldCategoryType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import {
  PageMode,
  PagePermission,
  ResponseCode,
} from "../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { CountStationDTO } from "../../../types/infrastructure/dto/infrstructureDtos";
import { CountStationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import {
  CountStation,
  IStationParams,
} from "../../../types/infrastructure/infrastructureTypes";
import { Enum2Array } from "../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import AdjFactor from "./components/AdjustmentFactor/AdjustmentFactors";
import General from "./components/general/general";
import Locations from "./components/locations/locations";
import { tabTitles, tabTitlesWithAdjFactor } from "./data";
import LoadingScreen, {
  CheckLoadingStates,
} from "../../../components/loadingScreen/loadingScreen";
import DirectionalSplits from "./components/directionalSplits/directionalSplits";
import { useTranslations } from "../../../contexts/translations";
import TesTabs from "../../../components/tesTab/tesTab";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const CountStationDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [initDataCountStation, setInitDataCountStation] =
    useState<CountStationDTO>(new CountStationDTO());
  const params = useParams<IStationParams>();
  const [backPermission, setBackPermission] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { generalSetting } = useClientSetting();
  const [isLocked, setIsLocked] = useState(true);
  const [pageMode, setPageMode] = useState<number>(0);
  const validationRef = useRef<ValidationGroupRef>(null);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const [lstCountStationTypes, setLstCountStationTypes] = useState<NameValue[]>(
    []
  );
  const didMount = useRef(false);
  const [tempMapLocation, setTempMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const [mapLocation, setMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const { getPagePermission } = useAuth();

  useBrowserTitle({
    pathname: "/infrastructure/countStationDetails",
    additionalText: `[${initDataCountStation.locationId}] ${initDataCountStation.name}`,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setLoading(true);
        await getInitialDataFields();
        await getInitDataSiteSubTypes();
        setLstCountStationTypes(Enum2Array(CountStationType));
        if (params.stationId! !== "AddNew") {
          setPageMode(PageMode.Update);
          await getCountStationData(params.stationId!);
          setMapLocation({
            ...mapLocation,
            latitude: initDataCountStation.latitude,
            longitude: initDataCountStation.longitude,
          });
        } else {
          setIsLocked(false);
          setPageMode(PageMode.Add);
        }
        setLoading(false);
      } catch (ex) {
        setLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.stationId]);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.CountingStationGeneral,
        FieldCategoryType.PermanentCountingStationGeneral,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.detailViewIndex - b.detailViewIndex;
    });
    setInitDataFields(res);
  }

  async function getCountStationData(id: string) {
    const res = (await TesGet(
      TrafficApiUrl() + "/api/CountStation/GetCountStationDetails/" + id,
      true
    )) as RequestResponseResult<CountStation>;
    if (res.responseCode === ResponseCode.OK) {
      if (res == null) {
        notify("Section does not exist.", "error", 5000);
        return;
      }
      setInitDataCountStation(res.results as CountStationDTO);
      if (getPagePermission)
        setPagePermissionStatus(
          getPagePermission(
            [InfrastructurePermissions.Infrastructure_D_Site],
            res.results
          )
        );
    }
    await RequestErrorHandling(res);
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.stationId! !== "AddNew") {
                  // updateCountStation();
                  setDataChanged(false);
                } else {
                  // addCountStation();
                  setDataChanged(false);
                }
                // notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_E_CountStation,
        InfrastructurePermissions.Infrastructure_V_CountStation,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`roadSegmentDetails ${compactViewModel ? "compactStyle" : ""}`}
      >
        {CheckLoadingStates([loading]) && <LoadingScreen />}
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t("countStation")}: </span>
                    <span ref={headingParagraphRef}>
                      {initDataCountStation?.name}
                    </span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                {params.stationId !== "AddNew" && (
                  <div className="rightColumn">
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_E_CountStation,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => LockHandler()}
                        icon={
                          isLocked
                            ? "fa-solid fa-lock"
                            : "fa-solid fa-lock-open"
                        }
                        hint={isLocked ? t("unlock") : t("lock")}
                      />
                    </Permission>
                  </div>
                )}
                <div className="rightColumn">
                  {params.stationId !== "AddNew" && <ShareURL url={url} />}
                  {params.stationId === "AddNew" && (
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_E_CountStation,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        // onClick={() => addCountStation()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    </Permission>
                  )}
                  {params.stationId !== "AddNew" && isLocked === false && (
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_E_CountStation,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        // onClick={() => updateCountStation()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    </Permission>
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                dataSource={
                  initDataCountStation.countStationType ===
                  CountStationType.PermanentCountingStation
                    ? tabTitlesWithAdjFactor
                    : tabTitles
                }
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />
              {selectedIndex === 0 && (
                <General
                  validationRef={validationRef}
                  pageMode={pageMode}
                  initDataFields={initDataFields}
                  initDataCountStation={initDataCountStation}
                  setInitDataCountStation={setInitDataCountStation}
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  setDataChanged={setDataChanged}
                  lstCountStationTypes={lstCountStationTypes}
                />
              )}
              {selectedIndex === 1 && (
                <Locations
                  didMount={didMount.current}
                  initDataCountStation={initDataCountStation}
                  tempMapLocation={tempMapLocation}
                  mapLocation={mapLocation}
                  setMapLocation={setMapLocation}
                  validationRef={validationRef}
                  setInitDataCountStation={setInitDataCountStation}
                  setTempMapLocation={setTempMapLocation}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 2 &&
                initDataCountStation.countStationType ===
                  CountStationType.PermanentCountingStation && (
                  <AdjFactor
                    adjustmentFactors={initDataCountStation.adjustmentFactors}
                  />
                )}
              {selectedIndex === 2 &&
                initDataCountStation.countStationType !==
                  CountStationType.PermanentCountingStation && (
                  <DirectionalSplits
                    dirSplit={initDataCountStation.dirSplit}
                    getCountStationData={getCountStationData}
                  />
                )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default CountStationDetails;
