import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  PropsWithChildren,
  useCallback,
} from "react";

import {
  getReportsData,
  getStoredReportsData,
  storeReportsData,
  storeReportsDataKeyName,
} from "./utils";

import { TClientReport } from "../../types/report/reportTypes";

type TReportsContext = {
  reportsLoading: boolean;
  reportsData: TClientReport[] | undefined;
  clearStoredReportsData: () => void;
};
const initialData: TReportsContext = {
  reportsLoading: true,
  reportsData: undefined,
  clearStoredReportsData: () => {},
};

export const ReportsContext = createContext(initialData);

const useReports = () => useContext(ReportsContext);

function ReportsProvider({ children }: PropsWithChildren) {
  const [reportsData, setReportsData] = useState<TClientReport[]>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchAndStoreInfrastructureData = async () => {
    setIsLoading(true);
    //To prevent getting data on refresh, uncomment code below
    // const storedData = getStoredReportsData();
    // if (storedData) {
    //   setReportsData(storedData);
    //   setIsLoading(false);
    //   return;
    // }
    const data = await getReportsData();
    if (data) {
      setReportsData(data);
      storeReportsData(data);
    }
    setIsLoading(false);
  };

  const clearStoredReportsData = useCallback(() => {
    localStorage.removeItem(storeReportsDataKeyName);
    setReportsData(undefined);
  }, []);

  useEffect(() => {
    fetchAndStoreInfrastructureData();
  }, []);

  const value: TReportsContext = {
    reportsLoading: isLoading,
    reportsData,
    clearStoredReportsData,
  };
  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
}

export { ReportsProvider, useReports };
