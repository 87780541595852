import { Button, Popup } from "devextreme-react";
import { useTranslation } from "react-i18next";
import { TTranslation } from "../../index";
import styles from "./translationHelperDialog.module.scss";
import React, { useState } from "react";
import EditDialog from "./editDialog";
import { GetTranslationDto } from "../../../../types/translation/dtos/translationDtos";

type TProps = {
  capturedText: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  errorText: string;
  items: GetTranslationDto[];
  onEditSucceed: () => void;
  selectedLanguageDetails?: TTranslation;
  onRefresh: () => void;
  isRefreshLoading?: boolean;
};
export default function TranslationHelperDialog({
  capturedText,
  isOpen,
  onClose,
  isLoading,
  errorText,
  onEditSucceed,
  items,
  selectedLanguageDetails,
  onRefresh,
  isRefreshLoading,
}: TProps) {
  const { t } = useTranslation();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<GetTranslationDto>();

  const onEditClick = (item: GetTranslationDto) => {
    setSelectedItem(item);
    setIsEditDialogOpen(true);
  };

  const renderRows = (list: GetTranslationDto[]) => {
    return (
      <>
        {list.map((item, index) => (
          <div key={index} className={styles.row}>
            <div className={styles.rowContentContainer}>
              <span>{item.key}</span> <span>{item.value}</span>
            </div>
            <div className={styles.rowActionContainer}>
              <i
                className="fa-solid fa-pencil"
                onClick={() => onEditClick(item)}
                style={{
                  color: "#F0B70D",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ))}
      </>
    );
  };
  return (
    <>
      <Popup
        height={"auto"}
        width={"60%"}
        visible={isOpen}
        resizeEnabled={true}
        showTitle={true}
        title={t("translationHelper")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={onClose}
        className={"translationHelper"}
      >
        <div className={`translationHelper`}>
          <p className={styles.captureTextTitle}>{t("capturedText")}:</p>
          <p className={styles.captureText}>{capturedText}</p>
          <p className={styles.tableTitle}>{t("relatedTranslations")}:</p>

          <div className={styles.headerRow}>
            <p>{t("key")}</p>
            <p>{t("translation")}</p>
          </div>
          <div className={styles.tableBodyContainer}>
            {isLoading ? (
              <p className={styles.loadingText}>{t("loading")}...</p>
            ) : (
              <>{errorText ? <p>{errorText}</p> : <>{renderRows(items)}</>}</>
            )}
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              className={`translationHelper ${styles.closeButton}`}
              onClick={onClose}
            >
              {t("close")}
            </Button>
            <Button
              type={"default"}
              className={`translationHelper ${styles.closeButton}`}
              onClick={onRefresh}
              disabled={isRefreshLoading}
            >
              {isRefreshLoading ? t("loading") : t("refresh")}
            </Button>
          </div>
        </div>
      </Popup>
      {isEditDialogOpen && selectedItem && (
        <EditDialog
          isOpen={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
          }}
          onEditSucceed={() => {
            setIsEditDialogOpen(false);
            onEditSucceed();
          }}
          initData={selectedItem}
          selectedLanguageDetails={selectedLanguageDetails}
        />
      )}
    </>
  );
}
