import {
  NumberBox,
  SelectBox,
  Switch,
  TagBox,
  TextBox,
  ValidationGroup,
  Validator,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useClientSetting } from "../../../../../../contexts/clientSetting";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { TrafficCounterType } from "../../../../../../types/trafficStudy/enums/importEnums";
import { StudyType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { TrafficCounter } from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../../utils/enumTools";

// props
interface IPros {
  initData: TrafficCounter;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();
  const [lstStudyTypes, setLstStudyTypes] = useState<NameValue[]>([]);
  const [lstTrafficCounterTypes, setLstTrafficCounterTypes] = useState<
    NameValue[]
  >([]);
  const { customerDivisions } = useClientSetting();

  useEffect(() => {
    setLstStudyTypes(Enum2Array(StudyType));
    setLstTrafficCounterTypes(Enum2Array(TrafficCounterType));
  }, []);

  function onValueChange(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value });
    props.setDataChanged(true);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2} className="popupFields">
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("name")}
                labelMode="floating"
                value={props.initData.name}
                onValueChange={(e) => onValueChange("name", e)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TextBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t("maxBinNo")}
                labelMode="floating"
                value={props.initData.maxBinNo}
                onValueChange={(e) => onValueChange("maxBinNo", e)}
                className="modalInput"
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TagBox
                items={SortObjectByPropName(lstStudyTypes, "name")}
                searchEnabled={true}
                label={t("studyTypes")}
                valueExpr="value"
                displayExpr="name"
                value={props.initData.selectedStudyTypes}
                onValueChange={(e) => onValueChange("selectedStudyTypes", e)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TagBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                label={t("trafficCounterType")}
                labelMode="floating"
                value={props.initData.trafficCounterType}
                onValueChange={(e) => onValueChange("trafficCounterType", e)}
                className="modalInput"
                valueExpr={"value"}
                displayExpr={"name"}
                items={SortObjectByPropName(lstTrafficCounterTypes, "name")}
                searchEnabled={true}
                showClearButton={true}
              ></SelectBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                // style={{ marginBottom: 20 }}
                label={t("division")}
                labelMode="floating"
                displayExpr="name"
                valueExpr="id"
                value={props.initData.divisionId}
                onValueChange={(e) => onValueChange("divisionId", e)}
                items={SortObjectByPropName(customerDivisions!, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <div>{t("isDisabled")}</div>
              <Switch
                style={{ marginTop: 5, fontWeight: "bold" }}
                value={props.initData.isDisabled}
                onValueChange={(e) => onValueChange("isDisabled", e)}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default Details;
