import {
  Button,
  DataGrid,
  Form,
  NumberBox,
  Popup,
  ScrollView,
} from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/cjs/data-grid";
import { SimpleItem } from "devextreme-react/cjs/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import LoadingScreen, {
  CheckLoadingStates,
} from "../../../../../components/loadingScreen/loadingScreen";
import { TrafficApiUrl } from "../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import { DirSplitCalculationStatus } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import {
  CountStationDirSplit,
  ICountStationParams,
} from "../../../../../types/infrastructure/infrastructureTypes";
import {
  ApproachType,
  StudyType,
} from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../../../utils/rest";
import { useParams } from "react-router-dom";

interface IPros {
  dirSplit: CountStationDirSplit[];
  getCountStationData: (id: string) => Promise<void>;
}

const DirectionalSplit = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const lstStudyType = Enum2Array(StudyType);
  const lstApproachType = Enum2Array(ApproachType);
  const lstDirSplitStatus = Enum2Array(DirSplitCalculationStatus);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [calcPostObj, setCalcPostObj] = useState<{
    customerId: string;
    year: number;
    pcsIds: string[];
  }>({
    customerId: localStorage.getItem("selectedCustomerId")!,
    year: new Date().getFullYear(),
    pcsIds: [],
  });
  const [recalculateLoading, setRecalculateLoading] = useState<boolean>(false);
  const params = useParams<ICountStationParams>();

  async function onRecalculate() {
    try {
      setRecalculateLoading(true);
      const res: RequestResponseResult<null> = await TesPost(
        TrafficApiUrl() + "/api/DirSplit/CalculateDirSplit",
        calcPostObj,
        true
      );
      setRecalculateLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyRecalculated"), "success", 5000);
        onCloseModal();
        props.getCountStationData(params.stationId!);
        dataGridRef.current?.instance().refresh();
        return;
      } else if (res.responseCode === ResponseCode.WorkOrderExist) {
        notify(t("workOrderExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      setRecalculateLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onCloseModal() {
    setCalcPostObj({
      ...calcPostObj,
      year: new Date().getFullYear(),
      pcsIds: [],
    });
    setShowModal(false);
  }

  async function onCalculatorClick() {
    setCalcPostObj({ ...calcPostObj, pcsIds: [params.stationId!] });
    setShowModal(true);
  }

  return (
    <div>
      {CheckLoadingStates([recalculateLoading]) && <LoadingScreen />}
      <div className="row" style={{ marginBottom: "-1.25rem" }}>
        <div className="leftColumn" style={{ display: "flex" }}>
          <Button
            onClick={() => onCalculatorClick()}
            icon={"fa-solid fa-calculator"}
            style={{ marginLeft: 5 }}
            hint={t("recalculateCountStation")}
          />
        </div>
      </div>
      <React.Fragment>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={props.dirSplit}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          remoteOperations={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
          style={{ margin: "0 0.5rem" }}
          // onRowClick={e => onRowClick(e)}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />

          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
          <Summary>
            <GroupItem summaryType="count" alignByColumn name="Total Count" />
          </Summary>
          <Column
            dataField="id"
            caption={t("id")}
            alignment="left"
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="countStationId"
            caption={t("countStationId")}
            alignment="left"
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="customerId"
            caption={t("customerId")}
            alignment="left"
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="studyId"
            caption={t("studyId")}
            alignment="left"
            visible={false}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="year"
            caption={t("year")}
            alignment="left"
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField="studyType" caption={t("studyType")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(lstStudyType, "name")}
              valueExpr="value"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="splitPercentage"
            caption={t("splitPercentage")}
            alignment="left"
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column
            dataField="approachWithHigherVolume"
            caption={t("approachWithHigherVolume")}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(lstApproachType, "name")}
              valueExpr="value"
              displayExpr="name"
            />
          </Column>
          <Column dataField="status" caption={t("status")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
            <Lookup
              dataSource={SortObjectByPropName(lstDirSplitStatus, "name")}
              valueExpr="value"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="note"
            caption={t("note")}
            alignment="left"
            visible={true}
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
        </DataGrid>

        <Popup
          visible={showModal}
          width={"40%"}
          height={"auto"}
          resizeEnabled={true}
          showTitle={true}
          title={t("recalculateCountStations")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowModal(false)}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("year")}
                  labelMode="floating"
                  value={calcPostObj.year}
                  onValueChange={(e) =>
                    setCalcPostObj({ ...calcPostObj, year: e })
                  }
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <Button
                  onClick={onRecalculate}
                  icon={"fa-solid fa-calculator"}
                  text={t("recalculate")}
                  style={{ marginTop: "0.5rem" }}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn" style={{ marginTop: 30 }}>
                <Button
                  className="tes-modal-btn-add"
                  onClick={() => onCloseModal()}
                  text={t("close")}
                />
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </div>
  );
};

export default DirectionalSplit;
