import { Button, DropDownButton, Popup } from "devextreme-react";
import Form, { SimpleItem } from "devextreme-react/form";
import { ValidationGroupRef } from "devextreme-react/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
  ReportApiUrl,
} from "../../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { FieldCategoryType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import {
  PageMode,
  PagePermission,
  ResponseCode,
} from "../../../types/general/enums/generalEnums";
import {
  INameId,
  RequestResponseResult,
  TabTitle,
} from "../../../types/general/generalTypes";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import {
  IRoadSegmentParams,
  Location,
  RoadSegmentDescription,
} from "../../../types/infrastructure/infrastructureTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import AADT from "./components/aadt";
import Collision from "./components/collision/collision";
import CrossSection from "./components/crossSection/crossSection";
import Details from "./components/details/details";
import General from "./components/general/general";
import LocationMap from "./components/location/location";
import Operation from "./components/operation/operation";
import OverView from "./components/overView/overView";
import Sign from "./components/sign/sign";
import Support from "./components/support/support";
import TrafficStudy from "./components/trafficStudy/trafficStudy";
import TabsDataUpdate, { TabsDataAdd } from "./data";
import "./roadSegmentDetails.scss";
import TesTabs from "../../../components/tesTab/tesTab";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const RoadSegmentDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [description, setDescription] = useState<RoadSegmentDescription>(
    new RoadSegmentDescription()
  );
  const [dataChanged, setDataChanged] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const [location, setLocation] = useState<Location>(new Location());
  const params = useParams<IRoadSegmentParams>();
  const [backPermission, setBackPermission] = useState(false);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const [isLocked, setIsLocked] = useState(true);
  const [pageMode, setPageMode] = useState<number>(0);
  const validationRef = useRef<ValidationGroupRef>(null);
  const [titles, setTitles] = useState<TabTitle[]>([]);
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const locDescRef = useRef<HTMLSpanElement>(null);
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);
  const { getPagePermission } = useAuth();

  useBrowserTitle({
    pathname: "/infrastructure/roadSegmentDetails",
    additionalText: `[${location.geoId}] ${location.description}`,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitDataSiteSubTypes();
        if (params.roadSegmentId !== "AddNew") {
          setTitles(TabsDataUpdate(params.roadSegmentId!));
          setPageMode(PageMode.Update);
          await getMidBlockData(params.roadSegmentId!);
        } else {
          setTitles(TabsDataAdd(params.roadSegmentId!));
          setIsLocked(false);
          setPageMode(PageMode.Add);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.roadSegmentId]);

  useEffect(() => {
    const fetchData = async () => {
      if (location.overwriteAutoGenerateDescription) {
        Promise.all([
          getStreetName(location?.streetId),
          getStreetName(location?.limit1StreetId),
          getStreetName(location?.limit2StreetId),
        ]).then((e) => {
          onChangeDescription(e[0], e[1], e[2]);
        });
      }
    };
    fetchData();
  }, [location.streetId, location.limit1StreetId, location.limit2StreetId]);

  function onChangeDescription(
    streetName1: string,
    streetName2: string,
    streetName3: string
  ) {
    if (location.overwriteAutoGenerateDescription) {
      let description =
        streetName1.trim() +
        " btwn " +
        streetName2.trim() +
        " & " +
        streetName3.trim();
      setLocation((prev) => {
        return { ...prev, description };
      });
    }
    setDataChanged(true);
  }

  async function getStreetName(id: string) {
    if (id === undefined || id === null || id === "") return "";
    const res = (await TesGet(
      InfrastructureApiUrl() + "/api/streets/GetStreetName/" + id,
      true
    )) as RequestResponseResult<INameId>;
    if (res.responseCode === ResponseCode.OK) {
      return res?.results?.name ?? "";
    } else {
      await RequestErrorHandling(res);
    }
    return "";
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.InfrastructureMidBlockGeneral,
        FieldCategoryType.InfrastructureMidBlockDetails,
        FieldCategoryType.InfrastructureMidBlockCrossSection,
        FieldCategoryType.InfrastructureMidBlockOperation,
        FieldCategoryType.InfrastructureMidBlockRoot,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.detailViewIndex - b.detailViewIndex;
    });
    setInitDataFields(res);
  }

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getMidBlockData(Id: string) {
    const postObj = {
      Id: Id,
      CustomerId: localStorage.getItem("selectedCustomerId"),
    };
    const res = (await TesPost(
      InfrastructureApiUrl() + "/api/Locations/GetMidblockData",
      postObj,
      true
    )) as RequestResponseResult<any>;
    if (res.responseCode === ResponseCode.OK) {
      if (res == null) {
        notify("Road segment does not exist.", "error", 5000);
        return;
      }
      setLocation(res.results as Location);
      if (getPagePermission)
        setPagePermissionStatus(
          getPagePermission(
            [InfrastructurePermissions.Infrastructure_D_Site],
            res.results
          )
        );
    } else {
      await RequestErrorHandling(res.results);
    }
    //mapLocation
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.roadSegmentId !== "AddNew") {
                  updateRoadSegment();
                  setDataChanged(false);
                } else {
                  addRoadSegment();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function updateRoadSegment() {
    try {
      if (activeLoading) activeLoading(true);

      var postObj = {
        ...location,
        Id: params.roadSegmentId,
        customerId: localStorage.getItem("selectedCustomerId"),
        LocationType: LocationType.Midblock,
      };
      const res = (await TesPost(
        InfrastructureApiUrl() + "/api/Locations/UpdateMidBlock",
        postObj,
        true
      )) as RequestResponseResult<Location>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    setDataChanged(false);
  }

  async function addRoadSegment() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        const postObj = {
          ...location,
          customerId: localStorage.getItem("selectedCustomerId"),
          divisionId:
            location.divisionId === "" ||
            location.divisionId === undefined ||
            location.divisionId === null
              ? localStorage.getItem("defaultDivisionId")!
              : location.divisionId,
          LocationType: LocationType.Midblock,
          //...this.state.mapLocation,
        };
        const res = (await TesPost(
          InfrastructureApiUrl() + "/api/Locations/AddNewMidBlock",
          postObj,
          true
        )) as RequestResponseResult<Location>;
        if (activeLoading) activeLoading(false);
        if (res.responseCode === ResponseCode.OK) {
          notify(t("dataSuccessfullyAdded"), "success", 5000);
          history(-1);
        }
        if (dataChanged === false) {
          history("/infrastructure/roadSegments");
          return;
        } else {
          await RequestErrorHandling(res);
        }
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    setDataChanged(false);
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && locDescRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = locDescRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_D_Site,
        InfrastructurePermissions.Infrastructure_R_Site,
        InfrastructurePermissions.Infrastructure_V_Site,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`roadSegmentDetails ${
          compactViewModel ? "compactStyle" : ""
        }`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t("roadSegment")}: </span>
                    <span ref={locDescRef}>{location?.description}</span>
                    <span> </span>
                    <span ref={geoIdRef}>[{location?.geoId}]</span>
                  </p>
                  <DropDownButton
                    style={{ marginLeft: 10, width: "6rem" }}
                    icon="fa-solid fa-copy"
                    items={[
                      { name: t("geoId"), value: t("geoId") },
                      { name: t("location"), value: t("location") },
                    ]}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator([
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("location"), value: t("location") },
                      ]),
                    }}
                    displayExpr="name"
                    onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                {pagePermissionStatus === PagePermission.Edit &&
                  params.roadSegmentId !== "AddNew" && (
                    <div className="rightColumn">
                      <Permission
                        allowed={[
                          InfrastructurePermissions.Infrastructure_D_Site,
                        ]}
                        hasFeedBackElement={false}
                      >
                        <Button
                          onClick={() => LockHandler()}
                          icon={
                            isLocked
                              ? "fa-solid fa-lock"
                              : "fa-solid fa-lock-open"
                          }
                          hint={isLocked ? t("unlock") : t("lock")}
                        />
                      </Permission>
                    </div>
                  )}
                <div className="rightColumn">
                  {params.roadSegmentId !== "AddNew" && <ShareURL url={url} />}
                  {params.roadSegmentId === "AddNew" && (
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_D_Site,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => addRoadSegment()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    </Permission>
                  )}
                  {params.roadSegmentId !== "AddNew" && isLocked === false && (
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_D_Site,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => updateRoadSegment()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    </Permission>
                  )}
                </div>
              </div>
            </div>
            {params.roadSegmentId !== "AddNew" ? (
              <div className={"dx-card "}>
                <TesTabs
                  width={"100%"}
                  dataSource={titles}
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />

                {selectedIndex === 0 && (
                  <OverView
                    roadSegmentId={params.roadSegmentId!}
                    isLocked={isLocked}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 1 && (
                  <General
                    validationRef={validationRef}
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                  />
                )}
                {selectedIndex === 2 && (
                  <LocationMap
                    validationRef={validationRef}
                    pageMode={pageMode}
                    location={location}
                    setLocation={setLocation}
                    isLocked={isLocked}
                  />
                )}
                {selectedIndex === 3 && (
                  <Details
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 4 && (
                  <CrossSection
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 5 && (
                  <Operation
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                    siteSubeTypes={initDataSiteSubTypes}
                  />
                )}
                {selectedIndex === 6 && (
                  <Collision
                    roadSegmentId={params.roadSegmentId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 7 && (
                  <TrafficStudy
                    roadSegmentId={params.roadSegmentId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 8 && (
                  <AADT
                    roadSegmentId={params.roadSegmentId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                    disabled={isLocked}
                  />
                )}
                {selectedIndex === 9 && (
                  <Sign
                    roadSegmentId={params.roadSegmentId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 10 && (
                  <Support
                    roadSegmentId={params.roadSegmentId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                  />
                )}
              </div>
            ) : (
              <div className={"dx-card "}>
                <TesTabs
                  width={"100%"}
                  dataSource={titles}
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />
                {selectedIndex === 0 && (
                  <General
                    validationRef={validationRef}
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                  />
                )}
                {selectedIndex === 1 && (
                  <Details
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 2 && (
                  <CrossSection
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 3 && (
                  <Operation
                    pageMode={pageMode}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                    siteSubeTypes={initDataSiteSubTypes}
                  />
                )}
              </div>
            )}
          </div>

          <Popup
            width={"50%"}
            height={"50%"}
            visible={backPermission}
            resizeEnabled={true}
            showTitle={true}
            title={t("warning")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setBackPermission(false)}
          >
            <Form colCount={3}>
              <SimpleItem>hi</SimpleItem>
            </Form>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default RoadSegmentDetails;
