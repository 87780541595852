//#region imports
import { ScrollView, SelectBox, TagBox, Tooltip } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Editing,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
  Button as DevExpressButton,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FilePicker from "../../../components/filePicker/filePicker";
import Permission from "../../../components/permission/permision";
import Stepper from "../../../components/stepper/stepper";
import WorkOrderPicker from "../../../components/workOrderPicker/workOrderPicker";
import { TrafficStudyPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { AuthApiUrl, TrafficApiUrl } from "../../../environment/routeSettings";
import { VMStorage } from "../../../types/fileManagement/dto/fileManagementDTO";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  NameValue,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import { VMFindUser } from "../../../types/identity/dto/identityDTO";
import {
  ImportCheckRequest,
  ImportCheckResponse,
  ImportCheckResponseStatusAction,
  ImportRequest,
} from "../../../types/trafficStudy/dtos/importDto";
import {
  SimpleTrafficCounterDTO,
  TrafficCounterImportDataGridDTO,
} from "../../../types/trafficStudy/dtos/TrafficCounterDTO";
import {
  ImportActionType,
  ImportCheckResponseStatus,
} from "../../../types/trafficStudy/enums/importEnums";
import { StudyType } from "../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import {
  OnExporting,
  calculateFileTypeCell,
} from "../../../utils/dataGridTools";
import { Enum2Array } from "../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import LocationPopup from "../importLogs/locationPopup/locationPupop";
import design from "./importData.module.scss";
import { custom } from "devextreme/ui/dialog";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

//#endregion imports
const ImportTrafficStudyData = () => {
  //#region consts
  const [selectedStep, setSelectedStep] = useState(1);
  const [initData, setInitData] = useState<SimpleTrafficCounterDTO[]>([]);
  const [selectedData, setSelectedData] = useState<SimpleTrafficCounterDTO>();
  const [modalData, setModalData] = useState<SimpleTrafficCounterDTO>();
  const [trafficCounterBinMapObj, setTrafficCounterBinMapObj] =
    useState<TrafficCounterImportDataGridDTO>(
      new TrafficCounterImportDataGridDTO()
    );
  const [modalTrafficCounterBinMapObj, setModalTrafficCounterBinMapObj] =
    useState<TrafficCounterImportDataGridDTO>(
      new TrafficCounterImportDataGridDTO()
    );
  const [lstLastFiles, setLstLastFiles] = useState<
    TrafficCounterImportDataGridDTO[]
  >([]);
  const [filePickerPermission, setFilePickerPermission] =
    useState<boolean>(true);
  const [showResolveModal, setShowResolveModal] = useState<boolean>(false);
  const [importRequest, setImportRequest] = useState<ImportRequest>(
    new ImportRequest()
  );
  const [selectedRow, setSelectedRow] = useState<ImportCheckResponse>(
    new ImportCheckResponse()
  );
  const [responseStatus, setResponseStatus] = useState<NameValue[]>([]);
  const [actionTypes, setActionTypes] = useState<NameValue[]>([]);
  const dataGridResolveRef = useRef<DataGridRef<any, any>>(null);
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const dataGridTrafficCounterRef = useRef<DataGridRef<any, any>>(null);
  const step2GridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [initDataUsers, setInitDataUser] = useState<VMFindUser[]>([]);
  const [userIds, setUserIds] = useState<string[]>([]);
  const [selectedCheckResponse, setSelectedCheckResponse] =
    useState<ImportCheckResponse>();
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [showWorkOrderModal, setShowWorkOrderModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editingFile, setEditingFile] =
    useState<TrafficCounterImportDataGridDTO>(
      new TrafficCounterImportDataGridDTO()
    );
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  const errorResolves = [
    {
      checkStatus: ImportCheckResponseStatus.FileNotFound,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.BinMapIsEmpty,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.BinMapNotFound,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.BinNoIsNotCorrect,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.FileBinFormatIsWrong,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.FileSheetNotExist,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.MaxSpeedConvertingError,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.MinSpeedConvertingError,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.FileFormatIsWrong,
      resolves: [ImportActionType.Ignore],
    },
    {
      checkStatus: ImportCheckResponseStatus.StudyAlreadyHasData,
      resolves: [ImportActionType.OverWriteData, ImportActionType.AppendData],
    },
  ];

  //#endregion consts

  //#region functions

  useBrowserTitle({ pathname: "/trafficStudy/import/importData" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();

        setResponseStatus(Enum2Array(ImportCheckResponseStatus));
        setActionTypes(Enum2Array(ImportActionType));
        setLstStudyType(Enum2Array(StudyType));
        getInitDataUsers();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    const postObj: LazyLoadingRequestBase = {
      customerId: localStorage.getItem("selectedCustomerId") as string,
      divisionId: localStorage.getItem("defaultDivisionId") as string,
      filter: { returnAll: false },
    };
    const res = (await TesPost(
      TrafficApiUrl() + "/api/Import/GetCustomerSimplifiedTrafficCounter",
      postObj,
      true
    )) as RequestResponseResult<SimpleTrafficCounterDTO[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const onValueChangeTrafficCounter = (value: any) => {
    let selectedFindData = initData?.find((x) => x.id === value);
    setSelectedData(selectedFindData);
    let newObj = {
      ...trafficCounterBinMapObj,
      trafficCounterId: selectedFindData?.id!,
    };
    setTrafficCounterBinMapObj(newObj);
  };

  const onValueChangeBinMap = (value: any) => {
    let newObj = {
      ...trafficCounterBinMapObj,
      binMapId: selectedData?.binMaps.find((x) => x.id === value)?.id!,
    };
    setTrafficCounterBinMapObj(newObj);
    setFilePickerPermission(false);
  };

  const onModalValueChangeTrafficCounter = (value: any) => {
    let selectedFindData = initData?.find((x) => x.id === value);
    setModalData(selectedFindData);
    let newObj = {
      ...modalTrafficCounterBinMapObj,
      trafficCounterId: selectedFindData?.id!,
    };
    setModalTrafficCounterBinMapObj(newObj);
  };

  const onModalValueChangeBinMap = (value: any) => {
    let newObj = {
      ...modalTrafficCounterBinMapObj,
      binMapId: modalData?.binMaps.find((x) => x.id === value)?.id!,
    };
    setModalTrafficCounterBinMapObj(newObj);
    setFilePickerPermission(false);
  };

  function addFromFileStorage(files: VMStorage[]) {
    setSelectedData(new SimpleTrafficCounterDTO());
    var res: TrafficCounterImportDataGridDTO[] = [];
    files.forEach((x) => {
      let newObj: TrafficCounterImportDataGridDTO = {
        trafficCounterId: trafficCounterBinMapObj.trafficCounterId,
        binMapId: trafficCounterBinMapObj.binMapId,
        fileKey: x.filePath,
        fileType: x.fileType,
      };
      res.push(newObj);
    });
    setLstLastFiles([...lstLastFiles, ...res]);
  }

  async function onImportCheck() {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: ImportCheckRequest[] = [];
      lstLastFiles.forEach((t: TrafficCounterImportDataGridDTO) => {
        if (t.binMapId && t.fileKey) {
          postObj.push({
            binMapId: t.binMapId,
            fileKey: t.fileKey,
            trafficCounterId: t.trafficCounterId,
            customerId: localStorage.getItem("selectedCustomerId") as string,
            divisionId: localStorage.getItem("defaultDivisionId") as string,
          });
        }
      });
      setImportRequest(
        await TesPost(
          TrafficApiUrl() + "/api/import/importCheck",
          postObj,
          true
        )
      );
      setSelectedStep(2);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function resolveModalOpener(d: any) {
    setShowResolveModal(true);
    setSelectedRow(d);
  }

  function onCloseResolveModal() {
    setShowResolveModal(false);
  }

  function errResolver(d: any) {
    return (
      <SelectBox
        placeholder=""
        displayExpr="name"
        defaultValue={d.data.actionType}
        valueExpr="value"
        items={SortObjectByPropName(
          actionTypes.filter((x) =>
            errorResolves
              .find((x) => x.checkStatus === d.data.checkStatus)
              ?.resolves.includes(x.value as unknown as ImportActionType)
          ),
          "name"
        )}
        onValueChange={(e) => onChangeStatusAction(d.data.checkStatus, e)}
        showClearButton={true}
        searchEnabled={true}
      />
    );
  }

  function onChangeStatusAction(
    checkStatus: ImportCheckResponseStatus,
    value: any
  ) {
    var arr = selectedRow.statusAction;
    var index = arr.findIndex((x) => x.checkStatus === checkStatus);
    arr[index].actionType = value;
    setSelectedRow({ ...selectedRow, statusAction: arr });
  }
  function onApplyResolve() {
    var importReq = importRequest;
    var index = importReq.importRequests.findIndex(
      (x) => x.fileKey === selectedRow.fileKey
    );
    importReq.importRequests[index].statusAction = selectedRow.statusAction;
    setImportRequest(importReq);
    setShowResolveModal(false);
  }

  function updateWorkOrder(
    fileKey: string,
    workOrderNo: string,
    studyId: string,
    binMapId: string,
    hasData: boolean
  ) {
    var importReq = importRequest;
    var index = importReq?.importRequests?.findIndex(
      (x) => x.fileKey === fileKey && x.binMapId === binMapId
    );
    //console.log("Index" + index + " Work NO " + workOrderNo)
    importReq.importRequests[index].workOrderNo = workOrderNo;
    importReq.importRequests[index].studyId = studyId;
    //console.log("DATA " + JSON.stringify(importReq))

    if (hasData) {
      var overRiredReq: ImportCheckResponseStatusAction = {
        actionType: ImportActionType.AppendData,
        checkStatus: ImportCheckResponseStatus.StudyAlreadyHasData,
      };
      importReq.importRequests[index].statusAction.push(overRiredReq);
    }
    setImportRequest(importReq);
    step2GridRef?.current?.instance().refresh();
  }

  function go2FinalStep() {
    if (
      importRequest?.importRequests?.some(
        (x) =>
          x.workOrderNo === null ||
          x.studyId === "00000000-0000-0000-0000-000000000000"
      )
    ) {
      notify(t("wordOrderNoIsMandatory"), "error", 5000);
      return;
    }
    try {
      setSelectedStep(3);
    } catch (ex) {
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function startImport() {
    if (
      importRequest?.importRequests?.some(
        (x) =>
          x.workOrderNo === null ||
          x.studyId === "00000000-0000-0000-0000-000000000000"
      )
    ) {
      notify(t("wordOrderNoIsMandatory"), "error", 5000);
      return;
    }
    importRequest.importCheckResponses = [];
    try {
      var postData = importRequest;
      postData.emails = userIds;
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        TrafficApiUrl() + "/api/import/startImport",
        postData,
        true
      )) as RequestResponseResult<ImportRequest>;
      if (activeLoading) activeLoading(false);
      setSelectedStep(4);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function getInitDataUsers() {
    try {
      var res = (await TesGet(
        AuthApiUrl() + "/api/user/findUser",
        true
      )) as VMFindUser[];
      setInitDataUser(
        res.filter(
          (x) =>
            x.defaultCustomerId === localStorage.getItem("selectedCustomerId")
        )
      );
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUserChange(e: any) {
    const values = e.value;
    setUserIds(values);
  }

  function onDelete(e: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async () => {
            try {
              setLstLastFiles([
                ...lstLastFiles.filter((x) => x.fileKey !== e.row.data.fileKey),
              ]);
              dataGridTrafficCounterRef.current?.instance().refresh();
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  function onDeleteStep2(data: any) {
    if (!data || !data.data || !data.data.fileKey) {
      console.error("Invalid data passed to onDeleteStep2:", data);
      notify(t("someErrorOccurred"), "error", 5000);
      return;
    }

    const selectedFileKey = data.data.fileKey;

    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async () => {
            try {
              setImportRequest((prev) => ({
                ...prev,
                importRequests: prev.importRequests.filter(
                  (d) => d.fileKey !== selectedFileKey
                ),
                importCheckRequests: prev.importCheckRequests.filter(
                  (d) => d.fileKey !== selectedFileKey
                ),
                importCheckResponses: prev.importCheckResponses.filter(
                  (d) => d.fileKey !== selectedFileKey
                ),
              }));

              setLstLastFiles((prev) =>
                prev.filter((d) => d.fileKey !== selectedFileKey)
              );
              step2GridRef.current?.instance().refresh();

              notify(t("fileDeleted"), "success", 3000);
            } catch (error) {
              console.error("Error deleting file:", error);
              notify(t("someErrorOccurred"), "error", 5000);
            }
          },
        },
        {
          text: t("no"),
          onClick: () => {
            console.log("Delete canceled");
          },
        },
      ],
    });

    myDialog.show();
  }

  async function onStep1Edit(e: any) {
    onModalValueChangeTrafficCounter(e.row.data.trafficCounterId);
    setEditingFile(e.row.data);
    setShowEditModal(true);
  }

  async function onStep1Update() {
    setLstLastFiles(
      lstLastFiles.map((x) =>
        x.fileKey === editingFile.fileKey ? editingFile : x
      )
    );
    dataGridTrafficCounterRef.current?.instance().refresh();
    notify(t("dataSuccessfullyUpdated"), "success", 5000);
    onCloseModal();
  }

  async function onCloseModal() {
    setEditingFile(new TrafficCounterImportDataGridDTO());
    setShowEditModal(false);
  }

  //#endregion functions
  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`trafficStudy-importData ${
          compactViewModel ? "compactStyle" : ""
        }`}
      >
        <React.Fragment>
          <h2 className={"content-block"}>{t("importData")}</h2>
          <div className={"content-block"}>
            <div
              className={"dx-card responsive-paddings"}
              style={{ padding: "2rem 2rem 0rem 2rem" }}
            >
              <div className="row">
                <Stepper selectedStep={selectedStep} stepNumber={4} />

                {selectedStep === 1 && (
                  <div>
                    <div style={{ marginLeft: "1rem" }} className="steps">
                      <p style={{ fontWeight: "bold" }}>
                        {t("step1")} {t("step1Text")}
                      </p>
                      <p style={{ margin: "-0.5rem 0 0.5rem 48px" }}>
                        {t("step1SubText")}
                      </p>
                    </div>
                    <Form colCount={7}>
                      <SimpleItem colSpan={3}>
                        <SelectBox
                          placeholder=""
                          label={t("trafficCounter")}
                          valueExpr="id"
                          displayExpr="name"
                          labelMode="floating"
                          value={selectedData?.id}
                          onValueChange={(e) => onValueChangeTrafficCounter(e)}
                          className="modalInput"
                          items={SortObjectByPropName(initData, "name")}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={3}>
                        <SelectBox
                          placeholder=""
                          label={t("binMaps")}
                          valueExpr="id"
                          displayExpr="name"
                          labelMode="floating"
                          onValueChange={(e) => onValueChangeBinMap(e)}
                          className="modalInput"
                          items={selectedData?.binMaps}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <FilePicker
                          isLocked={false}
                          label={t("filePicker")}
                          addFromFileStorage={addFromFileStorage}
                          disable={filePickerPermission}
                          lstLastFiles={lstLastFiles}
                          multiFileMode={true}
                          AddFolderAllowed={false}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={7}>
                        <DataGrid
                          id="gridContainer"
                          ref={dataGridTrafficCounterRef}
                          dataSource={lstLastFiles}
                          rowAlternationEnabled={true}
                          showBorders={true}
                          selection={{ mode: "single" }}
                          hoverStateEnabled={true}
                          remoteOperations={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          columnAutoWidth={true}
                          onExporting={OnExporting}
                        >
                          <Export
                            enabled={true}
                            allowExportSelectedData={true}
                          />
                          <Paging enabled={true} defaultPageSize={100} />
                          <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                          />
                          <FilterRow visible={true} applyFilter="auto" />
                          <HeaderFilter visible={true} />
                          <SearchPanel
                            visible={true}
                            width={285}
                            placeholder={t("search...")}
                          />
                          <ColumnChooser
                            width={350}
                            height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                          >
                            <Search enabled />
                          </ColumnChooser>
                          <SortByGroupSummaryInfo
                            summaryItem="Total Count"
                            sortOrder="desc"
                          />
                          <Summary>
                            <GroupItem
                              summaryType="count"
                              alignByColumn
                              name="Total Count"
                            />
                          </Summary>
                          {/* <Editing
                            mode="row"
                            allowDeleting={true}
                          /> */}
                          <Column
                            alignment="left"
                            dataField="id"
                            allowSorting={true}
                            caption={t("id")}
                            visible={false}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>

                          <Column
                            alignment="left"
                            dataField="trafficCounterId"
                            caption={t("trafficCounter")}
                          >
                            <Lookup
                              dataSource={SortObjectByPropName(
                                initData,
                                "name"
                              )}
                              valueExpr="id"
                              displayExpr="name"
                            />
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            alignment="left"
                            dataField="binMapId"
                            caption={t("binMap")}
                          >
                            <Lookup
                              dataSource={SortObjectByPropName(
                                initData?.flatMap((x) => x.binMaps),
                                "name"
                              )}
                              valueExpr="id"
                              displayExpr="name"
                            />
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            alignment="left"
                            dataField="fileType"
                            cellRender={calculateFileTypeCell}
                            caption={t("fileType")}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            alignment="left"
                            dataField="fileKey"
                            caption={t("fileKey")}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            type="buttons"
                            caption={t("actions")}
                            fixed={true}
                            fixedPosition="right"
                            width={90}
                          >
                            <DevExpressButton
                              hint={t("edit")}
                              onClick={onStep1Edit}
                              icon="fa-solid fa-pencil"
                            ></DevExpressButton>
                            <DevExpressButton
                              hint={t("delete")}
                              onClick={onDelete}
                              icon="fa-solid fa-trash-can"
                            ></DevExpressButton>
                          </Column>
                        </DataGrid>
                      </SimpleItem>
                    </Form>
                    <Button
                      onClick={onImportCheck}
                      className="tes-modal-btn-add rightColumn"
                      text={t("next")}
                    />
                  </div>
                )}

                {showEditModal && (
                  <Popup
                    width={"50%"}
                    height={"auto"}
                    visible={showEditModal}
                    resizeEnabled={true}
                    showTitle={true}
                    title={t("edit")}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHidden={onCloseModal}
                  >
                    <ScrollView width="100%" height="100%">
                      <Form colCount={2}>
                        <SimpleItem colSpan={3}>
                          <SelectBox
                            placeholder=""
                            label={t("trafficCounter")}
                            valueExpr="id"
                            displayExpr="name"
                            labelMode="floating"
                            value={editingFile.trafficCounterId}
                            onValueChange={(e) => {
                              onModalValueChangeTrafficCounter(e);
                              setEditingFile({
                                ...editingFile,
                                trafficCounterId: e,
                              });
                            }}
                            className="modalInput"
                            items={SortObjectByPropName(initData, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={3}>
                          <SelectBox
                            placeholder=""
                            label={t("binMaps")}
                            valueExpr="id"
                            displayExpr="name"
                            labelMode="floating"
                            value={editingFile.binMapId}
                            onValueChange={(e) => {
                              onModalValueChangeBinMap(e);
                              setEditingFile({ ...editingFile, binMapId: e });
                            }}
                            className="modalInput"
                            items={modalData?.binMaps}
                            showClearButton={true}
                            searchEnabled={true}
                          />
                        </SimpleItem>
                      </Form>
                      <div>
                        <div className="rightColumn">
                          <Button
                            className="tes-modal-btn-cancel"
                            style={{ marginRight: 20, marginTop: "10px" }}
                            onClick={() => onCloseModal()}
                            text={t("cancel")}
                          />
                          <Button
                            style={{ marginTop: "10px" }}
                            className="tes-modal-btn-add"
                            onClick={onStep1Update}
                            text={t("update")}
                          />
                        </div>
                      </div>
                    </ScrollView>
                  </Popup>
                )}

                {selectedStep === 2 && (
                  <div>
                    <div style={{ marginLeft: "1rem" }}>
                      <p style={{ fontWeight: "bold" }}>
                        {t("step2")} {t("step2Text")}
                      </p>
                      <p style={{ margin: "-0.5rem 0 0.5rem 48px" }}>
                        {t("step2SubText")}
                      </p>
                    </div>

                    <Form>
                      <SimpleItem>
                        <div className="row">
                          <DataGrid
                            id="gridContainer2"
                            ref={step2GridRef}
                            dataSource={importRequest?.importRequests}
                            showBorders={true}
                            remoteOperations={true}
                            keyExpr="fileKey"
                            rowAlternationEnabled={true}
                            selection={{ mode: "single" }}
                            hoverStateEnabled={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                          >
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                              showPageSizeSelector={true}
                              allowedPageSizes={[100, 200, 300, 400, 500]}
                              showNavigationButtons={true}
                              showInfo={true}
                              visible={true}
                            />
                            <ColumnChooser
                              width={350}
                              height={400}
                              enabled={true}
                              mode="select"
                              sortOrder="asc"
                            >
                              <Search enabled />
                            </ColumnChooser>
                            <Column
                              alignment="left"
                              dataField="id"
                              allowSorting={true}
                              caption={t("id")}
                              visible={false}
                            >
                              <HeaderFilter>
                                <Search enabled />
                              </HeaderFilter>
                            </Column>
                            <Column dataField="fileKey" caption={t("file")} />
                            <Column
                              dataField="studyType"
                              caption={t("studyType")}
                              calculateDisplayValue={(data) =>
                                t("enum-" + StudyType[data.studyType])
                              }
                            />
                            <Column
                              caption={t("fileErrors")}
                              cellRender={(data) => {
                                const statusActionLength =
                                  importRequest?.importCheckResponses.find(
                                    (x) => x.fileKey === data.data.fileKey
                                  )?.statusAction.length;

                                return statusActionLength === 0 ? (
                                  <i
                                    className={`fa-solid fa-check ${design.checkIcon} ${design.centeredIcon}`}
                                  />
                                ) : (
                                  <Button
                                    onClick={() =>
                                      resolveModalOpener(data.data)
                                    }
                                    text={t("troubleshoot")}
                                    className={`tes-button-error`}
                                  />
                                );
                              }}
                            />
                            <Column
                              dataField="workOrderNo"
                              caption={t("workOrderNo")}
                              cellRender={(data) => (
                                <div>
                                  {data.data.studyId ===
                                  "00000000-0000-0000-0000-000000000000" ? (
                                    <label
                                      className="fa-solid fa-warning"
                                      style={{ color: "red", fontSize: "20px" }}
                                    ></label>
                                  ) : (
                                    <label>{data.data.workOrderNo}</label>
                                  )}
                                </div>
                              )}
                            />
                            <Column
                              caption={t("assignWorkOrder")}
                              cellRender={(data) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* Assign Location Button */}
                                  <Button
                                    id="assignLocation"
                                    icon="fa-solid fa-link"
                                    onClick={() => {
                                      setSelectedCheckResponse(data.data);
                                      setShowWorkOrderModal(true);
                                    }}
                                    className="leftColumn"
                                  ></Button>
                                  <Tooltip
                                    target="#assignLocation"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                  >
                                    <div>{t("assignLocation")}</div>
                                  </Tooltip>

                                  {/* Add Location Button */}
                                  <Button
                                    id="addLocation"
                                    icon="fa-solid fa-circle-plus"
                                    onClick={() => {
                                      setSelectedCheckResponse(data.data);
                                      setShowLocationModal(true);
                                    }}
                                    style={{ marginLeft: "0.5rem" }}
                                  ></Button>
                                  <Tooltip
                                    target="#addLocation"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                  >
                                    <div>{t("aadLocation")}</div>
                                  </Tooltip>

                                  {/* Delete Button */}
                                  <Button
                                    id="delete"
                                    icon="fa-solid fa-trash"
                                    onClick={() => onDeleteStep2(data)} // Pass correct row data
                                    style={{ marginLeft: "0.5rem" }}
                                  ></Button>
                                  <Tooltip
                                    target="#delete"
                                    showEvent="mouseenter"
                                    hideEvent="mouseleave"
                                    hideOnOutsideClick={false}
                                  >
                                    <div>{t("delete")}</div>
                                  </Tooltip>
                                </div>
                              )}
                            />
                          </DataGrid>

                          {selectedCheckResponse !== undefined &&
                            selectedCheckResponse !== null && (
                              <WorkOrderPicker
                                label={t("workOrder")}
                                importResponse={selectedCheckResponse!}
                                updateWorkOrder={updateWorkOrder}
                                step2GridRef={step2GridRef}
                                setShowModal={setShowWorkOrderModal}
                                showModal={showWorkOrderModal}
                              />
                            )}
                          {selectedCheckResponse !== undefined &&
                            selectedCheckResponse !== null && (
                              <LocationPopup
                                label={t("workOrder")}
                                importResponse={selectedCheckResponse!}
                                updateWorkOrder={updateWorkOrder}
                                step2GridRef={step2GridRef}
                                setShowModal={setShowLocationModal}
                                showModal={showLocationModal}
                              />
                            )}
                        </div>
                      </SimpleItem>
                    </Form>
                    <Button
                      onClick={() => go2FinalStep()}
                      className="tes-modal-btn-add rightColumn"
                      text={t("next")}
                    />
                    <Button
                      onClick={() => setSelectedStep(1)}
                      className="tes-modal-btn-cancel rightColumn"
                      text={t("back")}
                    />

                    {/* popup for resolve */}
                    <Popup
                      width={"50%"}
                      height="auto"
                      visible={showResolveModal}
                      resizeEnabled={true}
                      showTitle={true}
                      title={t("troubleshoot")}
                      hideOnOutsideClick={false}
                      showCloseButton={true}
                      onHiding={() => onCloseResolveModal()}
                    >
                      <ScrollView
                        width="100%"
                        height="100%"
                        showScrollbar="onScroll"
                      >
                        <DataGrid
                          ref={dataGridResolveRef}
                          dataSource={selectedRow?.statusAction}
                          rowAlternationEnabled={true}
                          showBorders={true}
                          hoverStateEnabled={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          columnAutoWidth={true}
                          onExporting={OnExporting}
                        >
                          <Export
                            enabled={true}
                            allowExportSelectedData={true}
                          />
                          <Paging enabled={true} defaultPageSize={100} />
                          <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                          />
                          <FilterRow visible={true} applyFilter="auto" />
                          <HeaderFilter visible={true} />
                          <SearchPanel
                            visible={true}
                            width={285}
                            placeholder={t("search...")}
                          />
                          <ColumnChooser
                            width={350}
                            height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                          >
                            <Search enabled />
                          </ColumnChooser>
                          <Column
                            alignment="left"
                            dataField="id"
                            allowSorting={true}
                            caption={t("id")}
                            visible={false}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          {/* <Editing
                            mode="row"
                            allowDeleting={true}
                          /> */}
                          {showResolveModal && (
                            <Column
                              alignment="left"
                              dataField="checkStatus"
                              caption={t("errors")}
                            >
                              <Lookup
                                dataSource={SortObjectByPropName(
                                  responseStatus,
                                  "name"
                                )}
                                valueExpr="value"
                                displayExpr="name"
                              />
                              <HeaderFilter>
                                <Search enabled />
                              </HeaderFilter>
                            </Column>
                          )}

                          <Column
                            alignment="left"
                            cellRender={errResolver}
                            caption={t("troubleshoot")}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                        </DataGrid>

                        <Button
                          onClick={onApplyResolve}
                          className="tes-modal-btn-add rightColumn"
                          text={t("apply")}
                          style={{ margin: "1rem 1rem 1rem 0.5rem" }}
                        />
                        <Button
                          onClick={() => setShowResolveModal(false)}
                          className="tes-modal-btn-cancel rightColumn"
                          text={t("cancel")}
                          style={{ margin: "1rem 0.5rem" }}
                        />
                      </ScrollView>
                    </Popup>
                  </div>
                )}
                {selectedStep === 3 && (
                  <div>
                    <div style={{ marginLeft: "0.7rem" }} className="steps">
                      <p style={{ fontWeight: "bold" }}>
                        {t("step3")} {t("step3Text")}
                      </p>
                    </div>
                    <Form colCount={2}>
                      <SimpleItem colSpan={2}>
                        <TagBox
                          dataSource={SortObjectByPropName(
                            initDataUsers.filter(
                              (x) =>
                                x.defaultCustomerId ===
                                localStorage.getItem("selectedCustomerId")
                            ),
                            "userName"
                          )}
                          valueExpr="email"
                          displayExpr="userName"
                          onValueChanged={onUserChange}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    </Form>
                    <Button
                      onClick={startImport}
                      className="tes-modal-btn-add rightColumn"
                      text={t("start")}
                    />
                    <Button
                      onClick={() => setSelectedStep(2)}
                      className="tes-modal-btn-cancel rightColumn"
                      text={t("back")}
                    />
                  </div>
                )}

                {selectedStep === 4 && (
                  <div>
                    <div style={{ marginLeft: "0.7rem" }} className="steps">
                      <p style={{ fontWeight: "bold" }}>
                        {t("step4")} {t("step4Text")}
                      </p>
                    </div>
                    <Form colCount={2}>
                      <SimpleItem colSpan={2}>
                        <p style={{ textAlign: "center", fontSize: 16 }}>
                          {t("importResMessage")}
                        </p>
                      </SimpleItem>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default ImportTrafficStudyData;
