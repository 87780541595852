import { GridType } from "../../../../types/general/enums/generalEnums";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { TESGrid } from "../../tesGrid";
import CustomStore from "devextreme/data/custom_store";
import { TesGet, TesPost } from "../../../../utils/rest";
import {
  CollisionApiUrl,
  FieldApiUrl,
} from "../../../../environment/routeSettings";
import { LazyLoadingRequest } from "../../../../types/general/generalTypes";
import { DataGridRef } from "devextreme-react/data-grid";
import { useColumnDef } from "../../hooks/useColumnsDef";
import { FieldCategoryType } from "../../../../types/field/enums/fieldEnums";
import { useFields } from "../../../../contexts/fields";
import {
  TContextMenuAction,
  TDataTableColumn,
  TGridRowContextMenuItem,
} from "../../types";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { Enum2Array } from "../../../../utils/enumTools";
import {
  LocationType,
  MapUpdateStatus,
} from "../../../../types/infrastructure/enums/infrastructureEnums";
import {
  ApprovalStatus,
  CollisionDataSourceType,
  CollisionMergeStatus,
  CollisionOriginalSourceType,
  CollisionTesStatus,
  GeoCodeStatus,
} from "../../../../types/collision/enums/collisionEnums";
import { TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import {
  attachmentTypeFilterDataSource,
  attachmentTypeHeaderLookupDataSource,
} from "../constants";
import { useTranslations } from "../../../../contexts/translations";
import { RowClickEvent } from "devextreme/ui/data_grid";
import { ContextMenuItemClick } from "../../utils";
import { useNavigate } from "react-router-dom";

type TProps = {};
export const CollisionGrid = forwardRef<DataGridRef, TProps>((props, ref) => {
  const history = useNavigate();
  const { fieldsData } = useFields();
  const { translateObjectKeys } = useTranslations();

  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  useEffect(() => {
    getInfrastructureTesCodeValues();
  }, []);

  const STATIC_COLLISIONS_GRID_COLUMNS: TDataTableColumn[] = [
    {
      dataField: "_id",
      caption: "id",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "ParentId",
      caption: "parentId",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "GeoId",
      caption: "geoId",
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "CloneTargetGeoId",
      caption: "cloneTargetGeoId",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "xMLImportMessage",
      caption: "conflictedFields",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "LocationDescription",
      caption: "locationDescription",
      allowHeaderFiltering: false,
      width: 300,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "LocationType",
      caption: "locationType",
      headerFilter: {
        allowSearch: true,
      },
      lookup: {
        dataSource: SortObjectByPropName(Enum2Array(LocationType), "name"),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "MapLocation.Latitude",
      caption: "latitude",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
      format: { type: "fixedPoint", precision: 4 },
    },
    {
      dataField: "MapLocation.Longitude",
      caption: "longitude",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
      format: { type: "fixedPoint", precision: 4 },
    },
    {
      dataField: "MunicipalityId",
      caption: "municipality",
      visible:
        initDataTesCodeValues?.municipalities &&
        initDataTesCodeValues.municipalities?.length > 0,
      headerFilter: {
        dataSource: initDataTesCodeValues?.municipalities?.map((x) => ({
          text: x.name,
          value: x.id,
        })),
        allowSearch: true,
      },
      lookup: {
        dataSource: SortObjectByPropName(
          initDataTesCodeValues?.municipalities,
          "name"
        ),
        valueExpr: "id",
        displayExpr: "name",
      },
    },
    {
      dataField: "JurisdictionId",
      caption: "jurisdiction",
      visible:
        initDataTesCodeValues?.jurisdictions &&
        initDataTesCodeValues.jurisdictions.length > 0,
      headerFilter: {
        dataSource: initDataTesCodeValues?.jurisdictions?.map((x) => ({
          text: x.name,
          value: x.id,
        })),
        allowSearch: true,
      },
      lookup: {
        dataSource: SortObjectByPropName(
          initDataTesCodeValues?.jurisdictions,
          "name"
        ),
        valueExpr: "id",
        displayExpr: "name",
      },
    },
    {
      dataField: "Year",
      caption: "accidentYear",
      dataType: "number",
      alignment: "left",
      sortOrder: "desc",
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "SubmissionDT",
      caption: "submissionDT",
      dataType: "datetime",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "DataSourceType",
      caption: "dataSourceType",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(
          Enum2Array(CollisionDataSourceType),
          "name"
        ),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "GeoCodeStatus",
      caption: "geoCodeStatus",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(Enum2Array(GeoCodeStatus), "name"),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "CollisionTesStatus",
      caption: "collisionTesStatus",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(
          Enum2Array(CollisionTesStatus),
          "name"
        ),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "CollisionAttachmentType",
      caption: "collisionAttachmentType",
      visible: false,
      headerFilter: {
        allowSearch: true,
        dataSource: translateObjectKeys(attachmentTypeFilterDataSource, [
          "text",
        ]),
      },
      lookup: {
        dataSource: translateObjectKeys(attachmentTypeHeaderLookupDataSource, [
          "name",
        ]),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "CollisionMergeStatus",
      caption: "collisionMergeStatus",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(
          Enum2Array(CollisionMergeStatus),
          "name"
        ),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "MapUpdateStatus",
      caption: "mapUpdateStatus",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(Enum2Array(MapUpdateStatus), "name"),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "EditedSubmissionDT",
      caption: "lastEditDT",
      visible: false,
      dataType: "date",
    },
    {
      dataField: "LastEditedUserName",
      caption: "lastEditedUserName",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "ApproveLevel",
      caption: "approveLevel",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "ApprovalStatus",
      caption: "approvalStatus",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(Enum2Array(ApprovalStatus), "name"),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
    {
      dataField: "DivisionId",
      caption: "DivisionId",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "GeoCodeGroupId",
      caption: "geoCodeGroupId",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "LocationId",
      caption: "locationId",
      visible: false,
      headerFilter: {
        allowSearch: true,
      },
    },
    {
      dataField: "OriginalSourcetype",
      caption: "originalSourceType",
      visible: false,
      lookup: {
        dataSource: SortObjectByPropName(
          Enum2Array(CollisionOriginalSourceType),
          "name"
        ),
        valueExpr: "value",
        displayExpr: "name",
      },
    },
  ];
  const ROW_CONTEXT_MENU_ITEMS = translateObjectKeys(
    [
      { text: "openInNewTab", icon: "fa-solid fa-up-right-from-square" },
      { text: "openLocation", icon: "fa-solid fa-location-dot" },
    ],
    ["text"]
  );

  const { columns } = useColumnDef({
    staticColumns: STATIC_COLLISIONS_GRID_COLUMNS,
    fieldsData: fieldsData[FieldCategoryType.CollisionGeneral],
  });

  const dataSource = useMemo(() => {
    return new CustomStore({
      key: "_id",
      load: async (loadOption) => {
        let visibleColumns = [];
        if (ref && typeof ref === "object" && ref.current) {
          visibleColumns = ref.current.instance().getVisibleColumns();
          const fields: string[] = [];
          visibleColumns.forEach((item) => {
            if (item.dataField) {
              fields.push(item.dataField);
            }
          });
          return await TesPost(
            `${CollisionApiUrl()}/api/Collisions/GetCollisions`,
            {
              customerId: localStorage.getItem("selectedCustomerId"),
              divisionId: localStorage.getItem("selectedDivisionId"),
              loadOptions: loadOption,
              fields: fields,
            } as LazyLoadingRequest,
            true
          );
        }
      },
    });
  }, []);

  const onContextMenuItemClick = (action: TContextMenuAction, data: any) => {
    ContextMenuItemClick(
      action,
      data._id,
      "/collision/collisionDetails/",
      data.LocationType,
      data.LocationId
    );
  };

  function onRowClick(e: RowClickEvent) {
    if (e.data._id !== undefined) {
      const isCtrlKeyPressed = e?.event?.ctrlKey || e?.event?.metaKey;
      if (isCtrlKeyPressed) {
        window.open(`/collision/collisionDetails/${e.data._id}`, "_blank");
      } else {
        history(`/collision/collisionDetails/${e.data._id}`);
      }
    }
  }

  return (
    <TESGrid
      ref={ref}
      dataSource={dataSource}
      gridKey={"_id"}
      storageKey={GridType.Collision}
      hasSearchPanel={false}
      columns={columns}
      onRowClick={onRowClick}
      rowContextMenu={{
        items: ROW_CONTEXT_MENU_ITEMS as TGridRowContextMenuItem[],
        width: 100,
        onItemClick: onContextMenuItemClick,
      }}
    />
  );
});
