import { TPathName } from "./types";

export const TITLE_TEXT: Record<
  TPathName,
  {
    tTag: string;
    condition: "startWith" | "exact";
  }
> = {
  "/home": {
    tTag: "home",
    condition: "exact",
  },
  "/dashboard/trafficOperation": {
    tTag: "trafficOperation",
    condition: "exact",
  },
  "/dashboard/transportationSafety": {
    tTag: "transportationSafety",
    condition: "exact",
  },
  "/infrastructure/intersections": {
    tTag: "intersections",
    condition: "exact",
  },
  "/infrastructure/intersectionDetails": {
    tTag: "intersectionDetails",
    condition: "startWith",
  },
  "/infrastructure/roadSegments": {
    tTag: "roadSegments",
    condition: "exact",
  },
  "/infrastructure/roadSegmentDetails": {
    tTag: "roadSegmentDetails",
    condition: "startWith",
  },
  "/infrastructure/streets": {
    tTag: "streets",
    condition: "exact",
  },
  "/infrastructure/siteList": {
    tTag: "siteList",
    condition: "exact",
  },
  "/infrastructure/siteListDetails": {
    tTag: "siteListDetails",
    condition: "startWith",
  },
  "/infrastructure/countStation": {
    tTag: "countStation",
    condition: "exact",
  },
  "/infrastructure/countStationDetails": {
    tTag: "countStationDetails",
    condition: "startWith",
  },
  "/infrastructure/trafficSection": {
    tTag: "trafficSection",
    condition: "exact",
  },
  "/infrastructure/trafficSectionDetails": {
    tTag: "trafficSectionDetails",
    condition: "startWith",
  },
  "/infrastructure/updateImport/fieldDataSource": {
    tTag: "fieldDataSource",
    condition: "exact",
  },
  "/infrastructure/updateImport/fieldDataSourceDetails": {
    tTag: "fieldDataSourceDetails",
    condition: "startWith",
  },
  "/infrastructure/updateImport/loadData": {
    tTag: "loadData",
    condition: "exact",
  },
  "/infrastructure/settings/fields": {
    tTag: "fields",
    condition: "exact",
  },
  "/infrastructure/settings/codeGroups": {
    tTag: "codeGroups",
    condition: "exact",
  },
  "/infrastructure/settings/municipalityPopulation": {
    tTag: "municipality",
    condition: "exact",
  },
  "/infrastructure/settings/intersectionTesFields": {
    tTag: "intersectionTesFields",
    condition: "exact",
  },
  "/infrastructure/settings/roadSegmentTesFields": {
    tTag: "roadSegmentTesFields",
    condition: "exact",
  },
  "/collision/collisions": {
    tTag: "collisions",
    condition: "exact",
  },
  "/collision/collisionDetails/": {
    tTag: "collisionDetails",
    condition: "startWith",
  },
  "/collision/peopleInvolved": {
    tTag: "peopleInvolved",
    condition: "exact",
  },
  "/collision/vehicles": {
    tTag: "vehicles",
    condition: "exact",
  },
  "/collision/drafts": {
    tTag: "drafts",
    condition: "exact",
  },
  "/collision/import/xml/xmlImportSettings": {
    tTag: "xmlImportSettings",
    condition: "exact",
  },
  "/collision/import/xml/xmlImportSettingDetails": {
    tTag: "xmlImportSettingDetails",
    condition: "startWith",
  },
  "/collision/import/xml/xmlImport": {
    tTag: "xmlImport",
    condition: "exact",
  },
  "/collision/import/xml/xmlImportLogs": {
    tTag: "xmlImportLogs",
    condition: "exact",
  },
  "/collision/import/xml/xmlImportLogDetails": {
    tTag: "xmlImportLogDetails",
    condition: "startWith",
  },
  "/collision/geoCodeGroup/geoCodeGroups": {
    tTag: "geoCodeGroups",
    condition: "exact",
  },
  "/collision/geoCodeGroup/geoCodeGroupDetails": {
    tTag: "geoCodeGroupDetails",
    condition: "startWith",
  },
  "/collision/simplifiedCollisions": {
    tTag: "simplifiedCollisions",
    condition: "exact",
  },
  "/collision/settings/fields": {
    tTag: "fields",
    condition: "exact",
  },
  "/collision/settings/codeGroups": {
    tTag: "codeGroups",
    condition: "exact",
  },
  "/collision/settings/tesFields": {
    tTag: "tesFields",
    condition: "exact",
  },
  "/collision/settings/validationRules": {
    tTag: "validationRules",
    condition: "exact",
  },
  "/collision/settings/confirmationLevels": {
    tTag: "confirmationLevels",
    condition: "exact",
  },
  "/hospitalization/hospitalizations": {
    tTag: "hospitalizations",
    condition: "exact",
  },
  "/hospitalization/hospitalizationDetails": {
    tTag: "hospitalizationDetails",
    condition: "startWith",
  },
  "/trafficStudy/aadts/intersectionAADTs": {
    tTag: "intersectionAADTs",
    condition: "exact",
  },
  "/trafficStudy/aadts/roadSegmentAADTs": {
    tTag: "roadSegmentAADTs",
    condition: "exact",
  },
  "/trafficStudy/aadts/aadtDetails": {
    tTag: "aadtDetails",
    condition: "startWith",
  },
  "/trafficStudy/aadts/trafficSectionAADTs": {
    tTag: "trafficSectionAADTs",
    condition: "startWith",
  },
  "/trafficStudy/aadts/trafficSectionAADTDetails": {
    tTag: "trafficSectionAADTDetails",
    condition: "startWith",
  },
  "/trafficStudy/studies/allStudies": {
    tTag: "allStudies",
    condition: "exact",
  },
  "/trafficStudy/studies/tmc": {
    tTag: "tmc",
    condition: "exact",
  },
  "/trafficStudy/studies/volume": {
    tTag: "volume",
    condition: "exact",
  },
  "/trafficStudy/studies/speed": {
    tTag: "speed",
    condition: "exact",
  },
  "/trafficStudy/studies/studyDetails": {
    tTag: "studyDetails",
    condition: "startWith",
  },
  "/trafficStudy/import/importData": {
    tTag: "importData",
    condition: "exact",
  },
  "/trafficStudy/import/numetricConvertor": {
    tTag: "numetricConvertor",
    condition: "exact",
  },
  "/trafficStudy/import/importLogs": {
    tTag: "importLogs",
    condition: "exact",
  },
  "/trafficStudy/setups/reportingPeriod": {
    tTag: "reportingPeriod",
    condition: "exact",
  },
  "/trafficStudy/setups/trafficCounters": {
    tTag: "trafficCounters",
    condition: "exact",
  },
  "/trafficStudy/setups/trafficCountersDetails": {
    tTag: "trafficCountersDetails",
    condition: "startWith",
  },
  "/trafficStudy/setups/signalWarrantSetting": {
    tTag: "signalWarrantSetting",
    condition: "exact",
  },
  "/trafficStudy/setups/allWayStopWarrantSetting": {
    tTag: "allWayStopWarrantSetting",
    condition: "exact",
  },
  "/trafficStudy/setups/AADTPatterns": {
    tTag: "AADTPatterns",
    condition: "exact",
  },
  "/trafficStudy/setups/AADTPatternDetails/": {
    tTag: "AADTPatternDetails",
    condition: "startWith",
  },
  "/trafficStudy/setups/countDurations": {
    tTag: "countDurations",
    condition: "exact",
  },
  "/collision/settings/xmlRedaction": {
    tTag: "xmlRedaction",
    condition: "startWith",
  },
  "/infrastructure/mis": {
    tTag: "mis",
    condition: "startWith",
  },

  "/infrastructure/misDetails": {
    tTag: "misDetails",
    condition: "startWith",
  },
  "/infrastructure/tools/mms": {
    tTag: "mms",
    condition: "startWith",
  },
  "/trafficStudy/aadts/misAADTs": {
    tTag: "misAADTs",
    condition: "startWith",
  },
  "/trafficStudy/matching/dataCollectionCycle": {
    tTag: "dataCollectionCycle",
    condition: "startWith",
  },
  "/trafficStudy/matching/setups/settings": {
    tTag: "settings",
    condition: "startWith",
  },
  "/trafficStudy/trending/setups/settings": {
    tTag: "settings",
    condition: "startWith",
  },
  "/trafficStudy/tools/matchingProjects": {
    tTag: "matchingProjects",
    condition: "startWith",
  },
  "/trafficStudy/tools/trendingProjects": {
    tTag: "trendingProjects",
    condition: "startWith",
  },
};
