import { Button, DropDownButton, Popup } from "devextreme-react";
import Form, { SimpleItem } from "devextreme-react/form";
import { ValidationGroupRef } from "devextreme-react/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  FieldApiUrl,
  TrafficApiUrl,
  InfrastructureApiUrl,
} from "../../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { FieldCategoryType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import {
  PageMode,
  PagePermission,
  ResponseCode,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  RequestResponseResult,
} from "../../../types/general/generalTypes";
import {
  ISectionParams,
  TrafficSection,
} from "../../../types/infrastructure/infrastructureTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import General from "./components/general/general";
import tabTitles from "./data";
import Locations from "./components/locations/locations";
import {
  CountStationType,
  LocationType,
} from "../../../types/infrastructure/enums/infrastructureEnums";
import { Enum2Array } from "../../../utils/enumTools";
import TesTabs from "../../../components/tesTab/tesTab";
import { GisMap } from "./components/gisMap";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const TrafficSectionDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [initDataTrafficSection, setInitDataTrafficSection] =
    useState<TrafficSection>(new TrafficSection());
  const params = useParams<ISectionParams>();
  const [backPermission, setBackPermission] = useState(false);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const [isLocked, setIsLocked] = useState(true);
  const [pageMode, setPageMode] = useState<number>(0);
  const validationRef = useRef<ValidationGroupRef>(null);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const locDescRef = useRef<HTMLSpanElement>(null);
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [lstCountStationTypes, setLstCountStationTypes] = useState<NameValue[]>(
    []
  );

  const { getPagePermission } = useAuth();

  useBrowserTitle({
    pathname: "/infrastructure/trafficSectionDetails",
    additionalText: `[${initDataTrafficSection.geoId}] ${initDataTrafficSection.name}`,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitDataSiteSubTypes();
        setLstLocationType(Enum2Array(LocationType));
        setLstCountStationTypes(Enum2Array(CountStationType));
        if (params.sectionId! !== "AddNew") {
          setPageMode(PageMode.Update);
          await getTrafficSectionData(params.sectionId!);
        } else {
          setIsLocked(false);
          setPageMode(PageMode.Add);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }

    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sectionId]);

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [FieldCategoryType.InfrastructureTrafficSectionGeneral],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.detailViewIndex - b.detailViewIndex;
    });
    setInitDataFields(res);
  }

  async function getTrafficSectionData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TrafficApiUrl() + "/api/TrafficSections/GetTrafficSectionDetails/" + id,
        true
      )) as RequestResponseResult<TrafficSection>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        if (res == null) {
          notify("Section does not exist.", "error", 5000);
          return;
        }
        setInitDataTrafficSection(res.results as TrafficSection);
        if (getPagePermission)
          setPagePermissionStatus(
            getPagePermission(
              [InfrastructurePermissions.Infrastructure_D_Site],
              res.results
            )
          );
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            disabled: true,
            onClick: (e) => {
              try {
                if (params.sectionId! !== "AddNew") {
                  // updateTrafficSection();
                  setDataChanged(false);
                } else {
                  // addTrafficSection();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && locDescRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = locDescRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_E_TrafficSection,
        InfrastructurePermissions.Infrastructure_V_TrafficSection,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`roadSegmentDetails ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t("trafficSection")}: </span>
                    <span ref={locDescRef}>{initDataTrafficSection?.name}</span>
                    <span> </span>
                    <span ref={geoIdRef}>
                      [{initDataTrafficSection?.geoId}]
                    </span>
                  </p>
                  <DropDownButton
                    style={{ marginLeft: 10, width: "6rem" }}
                    icon="fa-solid fa-copy"
                    items={[
                      { name: t("geoId"), value: t("geoId") },
                      { name: t("name"), value: t("name") },
                    ]}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator([
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("location"), value: t("location") },
                      ]),
                    }}
                    displayExpr="name"
                    onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                {params.sectionId !== "AddNew" && (
                  <div className="rightColumn">
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_E_TrafficSection,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => LockHandler()}
                        icon={
                          isLocked
                            ? "fa-solid fa-lock"
                            : "fa-solid fa-lock-open"
                        }
                        hint={isLocked ? t("unlock") : t("lock")}
                      />
                    </Permission>
                  </div>
                )}
                <div className="rightColumn">
                  {params.sectionId !== "AddNew" && <ShareURL url={url} />}
                  {/* {params.sectionId === "AddNew" && (
                    <Permission
                      allowed={[InfrastructurePermissions.Infrastructure_E_TrafficSection]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        // onClick={() => addTrafficSection()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    </Permission>
                  )} */}
                  {/* {params.sectionId !== "AddNew" && isLocked === false && (
                    <Permission
                      allowed={[InfrastructurePermissions.Infrastructure_E_TrafficSection]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        // onClick={() => updateTrafficSection()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    </Permission>
                  )} */}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />
              {selectedIndex === 0 && (
                <General
                  validationRef={validationRef}
                  pageMode={pageMode}
                  initDataFields={initDataFields}
                  initDataTrafficSection={initDataTrafficSection}
                  setInitDataTrafficSection={setInitDataTrafficSection}
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  setDataChanged={setDataChanged}
                  lstCountStationTypes={lstCountStationTypes}
                />
              )}
              {selectedIndex === 1 && (
                <Locations
                  validationRef={validationRef}
                  initDataFields={initDataFields}
                  initDataTrafficSection={initDataTrafficSection}
                  isLocked={isLocked}
                  initDataSiteSubTypes={initDataSiteSubTypes}
                  lstLocationType={lstLocationType}
                />
              )}
              {selectedIndex === 2 && (
                <GisMap
                  tesModule={TesMapModule.TrafficSectionDetails}
                  locations={initDataTrafficSection.locations}
                />
              )}
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"50%"}
            visible={backPermission}
            resizeEnabled={true}
            showTitle={true}
            title={t("warning")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setBackPermission(false)}
          >
            <Form colCount={3}>
              <SimpleItem>hi</SimpleItem>
            </Form>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default TrafficSectionDetails;
