import { TextBox } from "devextreme-react";
import { t } from "i18next";
import React from "react";
import { AreaDTO } from "../../../../../types/infrastructure/dto/locationdDto";

// props
interface IPros {
  initDataSiteList: AreaDTO;
  setInitDataSiteList: React.Dispatch<React.SetStateAction<AreaDTO>>;
  onChangeDetailsFields: (name: string, value: any) => void;
  isLocked: boolean;
}

const Details = (props: IPros) => {
  function onChangeValue(name: string, value: any) {
    props.setInitDataSiteList({ ...props.initDataSiteList, [name]: value });
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <div className="halfColumn" style={{ marginBottom: 20 }}>
          <TextBox
            label={t("title")}
            labelMode="floating"
            value={props.initDataSiteList.name}
            onValueChange={(e) => onChangeValue("name", e)}
            disabled={props.isLocked}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Details;
