export enum AnalysisType {
  SignalWarrantsPrediction = 1,
}

export enum SignalWarrantsPredictionStatus {
  Success = 1,
  Failed = 2,
}

export enum SWPProximityCheckRes {
  ProximityCheckPassed = 0,
  ProximityCheckNotPassed = 1,
  ErrorNoMidblockLengthIsGiven = 2,
  EndOfCorridor = 3,
  ErrorNoLocationAvailable = 4, // no intersection is available
}

export enum VRUResultCalculationStatus {
  None = 0,
  Done = 1,
}
