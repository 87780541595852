import {
  Button,
  Form,
  Popup,
  SelectBox,
  TextArea,
  TextBox,
} from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useState } from "react";
import Permission from "../../../../../components/permission/permision";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { TranslationApiUrl } from "../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import {
  EditTranslationDto,
  GetTranslationDto,
} from "../../../../../types/translation/dtos/translationDtos";
import { TranslationValueStatus } from "../../../../../types/translation/enums/translationEnums";
import { Enum2Array } from "../../../../../utils/enumTools";
import { RequestErrorHandling, TesPut } from "../../../../../utils/rest";
import { useTranslation } from "react-i18next";
import { TTranslation } from "../../../index";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onEditSucceed: () => void;
  initData: GetTranslationDto;
  selectedLanguageDetails?: TTranslation;
}

const EditDialog = (props: IProps) => {
  const { isOpen, onClose, onEditSucceed, initData, selectedLanguageDetails } =
    props;
  const { t } = useTranslation();
  const [data, setData] = useState<GetTranslationDto>(initData);
  const [isLoading, setIsLoading] = useState(false);

  function onPopupValueChange(variable: string, value: any) {
    if (variable === "value") {
      setData((prev) => {
        return {
          ...prev,
          value: value,
        };
      });
    } else if (variable === "note") {
      setData((prev) => {
        return {
          ...prev,
          note: value,
        };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          status: value,
        };
      });
    }
  }

  const onSubmitEditDialog = async () => {
    setIsLoading(true);
    try {
      const putObj: Partial<EditTranslationDto> = {
        languageType: selectedLanguageDetails?.languageType,
        languageId: selectedLanguageDetails?.id,
        translations: [
          {
            key: data.key,
            value: data.value,
            keyId: data.keyId,
            valueId: data.valueId,
            status: data.status,
            note: data.note,
          },
        ],
      };
      const res = (await TesPut(
        TranslationApiUrl() + "/api/ManageTranslations/EditTranslation",
        putObj,
        true
      )) as RequestResponseResult<null>;
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        onEditSucceed();
      } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
        notify(t("error"), "error", 5000);
      }
      setIsLoading(false);
      await RequestErrorHandling(res);
    } catch (ex) {
      setIsLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  };

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <React.Fragment>
        <Popup
          width={"60%"}
          height="auto"
          visible={isOpen}
          resizeEnabled={true}
          showTitle={true}
          title={t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onClose()}
        >
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                placeholder=""
                label={t("value")}
                labelMode="floating"
                value={data.value}
                onValueChange={(e) => onPopupValueChange("value", e)}
                showClearButton={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("status")}
                valueExpr="value"
                displayExpr="name"
                labelMode="floating"
                value={data.status}
                onValueChange={(e) => onPopupValueChange("status", e)}
                items={Enum2Array(TranslationValueStatus)}
                showClearButton={true}
                searchEnabled={true}
              ></SelectBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextArea
                label={t("note")}
                labelMode="floating"
                value={data.note}
                onValueChange={(e) => onPopupValueChange("note", e)}
              />
            </SimpleItem>
          </Form>

          <div className="rightColumn" style={{ margin: "2rem -0.5rem 0 0" }}>
            <Button
              className="tes-modal-btn-cancel"
              style={{ marginRight: 20 }}
              onClick={onClose}
              text={t("cancel")}
            />
            <Button
              disabled={isLoading}
              className="tes-modal-btn-add"
              style={{ marginRight: 20 }}
              onClick={onSubmitEditDialog}
              text={t("update")}
            />
          </div>
        </Popup>
      </React.Fragment>
    </Permission>
  );
};

export default EditDialog;
