import { TabTitle } from "../../../../types/general/generalTypes";
export default function TabsDataUpdate(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 0,
        text: "Summary",
        icon: "fa-solid fa-list-ul",
        content: "General tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 1,
        text: "General",
        icon: "fa-solid fa-circle-info",
        content: "General tab content",
      },
      {
        id: 2,
        text: "Location",
        icon: "fa-solid fa-map",
        content: "General tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 3,
        text: "Details",
        icon: "fa-solid fa-road",
        content: "Details tab content",
      },
      {
        id: 4,
        text: "Cross Section",
        icon: "fa-solid fa-road",
        content: "Cross Section tab content",
      },
      {
        id: 5,
        text: "Operation",
        icon: "fa-solid fa-sliders",
        content: "Operation tab content",
      },
      {
        id: 6,
        text: "Collisions",
        icon: "fa-solid fa-car-burst",
        content: "Collisions tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 7,
        text: "Traffic Studies",
        icon: "fa-solid fa-traffic-light",
        content: "Traffic Studies tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 8,
        text: "AADT",
        icon: "fa-solid fa-calendar",
        content: "AADT tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 9,
        text: "Signs",
        icon: "fa-solid fa-triangle-exclamation",
        content: "Signs tab content",
        disabled: id === "AddNew" ? true : false,
      },
      {
        id: 10,
        text: "Supports",
        icon: "fa-solid fa-sign-hanging",
        content: "Supports tab content",
        disabled: id === "AddNew" ? true : false,
      },
    ])
  );
}

export function TabsDataAdd(id: string) {
  var tabTitles: TabTitle[];
  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (tabTitles = [
      {
        id: 1,
        text: "General",
        icon: "fa-solid fa-circle-info",
        content: "General tab content",
      },
      {
        id: 3,
        text: "Details",
        icon: "fa-solid fa-road",
        content: "Details tab content",
      },
      {
        id: 4,
        text: "Cross Section",
        icon: "fa-solid fa-road",
        content: "Cross Section tab content",
      },
      {
        id: 5,
        text: "Operation",
        icon: "fa-solid fa-sliders",
        content: "Operation tab content",
      },
    ])
  );
}
