//#region imports
import { NumberBox, SelectBox, ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../../components/collapsibleCard/collapsibleCard";
import { useAuth } from "../../../../../../contexts/auth";
import { useClientSetting } from "../../../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../../../environment/routeSettings";
import { FieldType } from "../../../../../../types/field/enums/fieldEnums";
import { ResponseCode } from "../../../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../../../types/general/generalTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";
import {
  StudyAADTCalculationResult,
  StudyAadtRecalculationReqDTO,
  StudyTmcWebDetailsDTO,
} from "../../../../../../types/trafficStudy/dtos/studyDto";
import { AADTSourceType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { FormatDT } from "../../../../../../utils/dateTimeTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { RequestErrorHandling, TesPost } from "../../../../../../utils/rest";

//#endregion imports

// props
interface IPros {
  initDataTMC: StudyTmcWebDetailsDTO;
  setInitDataTMC: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const IntersectionAADT = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [lstSourceType, setLstSourceType] = useState<NameValue[]>([]);
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isTotalVisible, setIsTotalVisible] = useState(true);
  const [isMajorVisible, setIsMajorVisible] = useState(true);
  const [isMinorVisible, setIsMinorVisible] = useState(true);
  const [isNorthVisible, setIsNorthVisible] = useState(false);
  const [isEastVisible, setIsEastVisible] = useState(false);
  const [isSouthVisible, setIsSouthVisible] = useState(false);
  const [isWestVisible, setIsWestVisible] = useState(false);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstSourceType(Enum2Array(AADTSourceType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onRecalculate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: StudyAadtRecalculationReqDTO = {
        studyId: props.initDataTMC.id!,
        sourceType: AADTSourceType.Count,
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Study/ReCalculateAADT",
        postObj,
        true
      )) as RequestResponseResult<StudyAADTCalculationResult>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        props.setInitDataTMC({
          ...props.initDataTMC,
          aadTs: res.results.intersectionAADTs,
          aadt: res.results.intersectionAADT,
        });
        notify(t("dataSuccessfullyRecalculated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.CalculationError) {
        notify(t("calculationError"), "error", 5000);
      } else if (res.responseCode === ResponseCode.AADTFactorsNotExist) {
        notify(t("aadtfactorsNotExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  function onValueChange(name: string, value: any) {
    props.setInitDataTMC({
      ...props.initDataTMC,
      aadt: { ...props.initDataTMC.aadt, [name]: value },
    });
    props.setDataChanged(true);
  }

  function customizeArgumentAxisLabels({ value }: { value: any }) {
    const date = new Date(value);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function customizeTooltip({
    argument,
    seriesName,
    value,
  }: {
    argument: any;
    seriesName: any;
    value: any;
  }) {
    return {
      text: `${FormatDT(argument, FieldType.Date, true, props.generalSettings)}, ${seriesName}, ${value}`,
    };
  }

  const mockData = [
    {
      aadtRawDataDT: "2020-02-27T00:00:00Z",
      aadtType: 1,
      customerId: "00000000-0000-0000-0000-000000000000",
      divisionId: "00000000-0000-0000-0000-000000000000",
      eastApproachAADT: 6047,
      geoId: "N-639",
      id: "00000000-0000-0000-0000-000000000000",
      jurisdictionId: null,
      locationDescription: "MILDRED DOBBS BLVD N @ 26 AVE N",
      locationId: "8b4cd00a-7ea2-406b-bd4c-1e0261238a35",
      locationType: 1,
      majorVolume: 8949,
      minorVolume: 7550,
      municipalityId: null,
      northApproachAADT: 2921,
      sourceType: 1,
      southApproachAADT: 6028,
      totalVolume: 16499,
      westApproachAADT: 1501,
      year: 2020,
    },
    {
      aadtRawDataDT: "2020-02-28T00:00:00Z",
      aadtType: 2,
      customerId: "11111111-1111-1111-1111-111111111111",
      divisionId: "11111111-1111-1111-1111-111111111111",
      eastApproachAADT: 7000,
      geoId: "N-640",
      id: "11111111-1111-1111-1111-111111111111",
      jurisdictionId: null,
      locationDescription: "Main Street @ 5th Avenue",
      locationId: "9a5de1b7-8bc2-4e64-9e8d-2e993b63a0b5",
      locationType: 1,
      majorVolume: 9500,
      minorVolume: 8200,
      municipalityId: null,
      northApproachAADT: 3200,
      sourceType: 1,
      southApproachAADT: 6800,
      totalVolume: 15800,
      westApproachAADT: 1400,
      year: 2020,
    },
    {
      aadtRawDataDT: "2020-03-01T00:00:00Z",
      aadtType: 1,
      customerId: "22222222-2222-2222-2222-222222222222",
      divisionId: "22222222-2222-2222-2222-222222222222",
      eastApproachAADT: 5000,
      geoId: "N-641",
      id: "22222222-2222-2222-2222-222222222222",
      jurisdictionId: null,
      locationDescription: "Elm Street @ 10th Avenue",
      locationId: "a1b2c3d4-5e6f-7a8b-9c0d-1e2f3a4b5c6d",
      locationType: 1,
      majorVolume: 7200,
      minorVolume: 6500,
      municipalityId: null,
      northApproachAADT: 2800,
      sourceType: 1,
      southApproachAADT: 5900,
      totalVolume: 13100,
      westApproachAADT: 2200,
      year: 2020,
    },
  ];

  //#endregion functions
  return (
    <div
      className={`studyDetails-intersectionAADT ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ margin: "1rem" }}>
          <ValidationGroup ref={props.validationRef}>
            <div
              className={"dx-card"}
              id="iconsCard"
              style={{ margin: "0rem 1rem 2rem 1rem" }}
            >
              <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
                <div className="leftColumn">
                  <Button
                    icon="fa-solid fa-calculator"
                    style={{ marginRight: 10 }}
                    hint={t("recalculate")}
                    onClick={onRecalculate}
                    disabled={props.isLocked}
                  />
                </div>
              </div>
            </div>

            <Form colCount={1}>
              <SimpleItem colSpan={1}>
                <div id="studyAADTCollapse">
                  <CollapsibleCard title={t("studyAADT")} isOpen={true}>
                    <Form colCount={3} className="popupFields">
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("majorVolumeAadt")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.majorVolume}
                          onValueChange={(e) => onValueChange("majorVolume", e)}
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("minorVolumeAadt")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.minorVolume}
                          onValueChange={(e) => onValueChange("minorVolume", e)}
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("totalVolume")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.totalVolume}
                          onValueChange={(e) => onValueChange("totalVolume", e)}
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("northApproachAADT")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.northApproachAADT}
                          onValueChange={(e) =>
                            onValueChange("northApproachAADT", e)
                          }
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("eastApproachAADT")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.eastApproachAADT}
                          onValueChange={(e) =>
                            onValueChange("eastApproachAADT", e)
                          }
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("southApproachAADT")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.southApproachAADT}
                          onValueChange={(e) =>
                            onValueChange("southApproachAADT", e)
                          }
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <NumberBox
                          format="#,###"
                          step={0}
                          label={t("westApproachAADT")}
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.westApproachAADT}
                          onValueChange={(e) =>
                            onValueChange("westApproachAADT", e)
                          }
                          className="modalInput"
                          disabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t("sourceType")}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode="floating"
                          value={props.initDataTMC?.aadt?.sourceType}
                          onValueChange={(e) => onValueChange("sourceType", e)}
                          className="modalInput"
                          items={SortObjectByPropName(lstSourceType, "name")}
                          disabled={true}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    </Form>
                  </CollapsibleCard>
                </div>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <div id="studyAADTCollapse">
                  <CollapsibleCard title={t("studyDayAADT")} isOpen={true}>
                    <Form colCount={1}>
                      <SimpleItem colSpan={1}>
                        <DataGrid
                          id="gridContainer studyAADTGrid"
                          ref={dataGridRef}
                          dataSource={props.initDataTMC?.aadTs ?? []}
                          rowAlternationEnabled={true}
                          showBorders={true}
                          hoverStateEnabled={true}
                          remoteOperations={true}
                          allowColumnReordering={true}
                          allowColumnResizing={true}
                          columnAutoWidth={true}
                          onExporting={OnExporting}
                        >
                          <Export
                            enabled={true}
                            allowExportSelectedData={true}
                          />
                          <Paging enabled={true} defaultPageSize={100} />
                          <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                          />
                          <FilterRow visible={true} applyFilter="auto" />
                          <HeaderFilter visible={true} />

                          <SearchPanel
                            visible={true}
                            width={285}
                            placeholder={t("search...")}
                          />
                          <ColumnChooser
                            width={350}
                            height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                          >
                            <Search enabled />
                          </ColumnChooser>
                          <SortByGroupSummaryInfo
                            summaryItem="Total Count"
                            sortOrder="desc"
                          />
                          <Summary>
                            <GroupItem
                              summaryType="count"
                              alignByColumn
                              name="Total Count"
                            />
                          </Summary>
                          <Column
                            dataField="id"
                            caption={t("id")}
                            visible={false}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            dataField="aadtRawDataDT"
                            caption={t("date")}
                            visible={true}
                            dataType="datetime"
                            format={generalSetting?.dateFormat}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>

                          <Column
                            dataField="majorVolume"
                            caption={t("majorVolumeAadt")}
                            visible={true}
                            format="#,###"
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            dataField="minorVolume"
                            caption={t("minorVolumeAadt")}
                            visible={true}
                            format="#,###"
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            dataField="northApproachAADT"
                            caption={t("northApproachAADT")}
                            visible={true}
                            format="#,###"
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            dataField="southApproachAADT"
                            caption={t("southApproachAADT")}
                            visible={true}
                            format="#,###"
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            dataField="eastApproachAADT"
                            caption={t("eastApproachAADT")}
                            visible={true}
                            format="#,###"
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>
                          <Column
                            dataField="westApproachAADT"
                            caption={t("westApproachAADT")}
                            visible={true}
                            format="#,###"
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                          </Column>

                          <Column
                            dataField="sourceType"
                            caption={t("sourceType")}
                          >
                            <HeaderFilter>
                              <Search enabled />
                            </HeaderFilter>
                            <Lookup
                              dataSource={SortObjectByPropName(
                                lstSourceType,
                                "name"
                              )}
                              valueExpr="value"
                              displayExpr="name"
                            />
                          </Column>
                        </DataGrid>
                      </SimpleItem>

                      {/* {props.initDataTMC?.aadTs.length > 1 &&
                                    <SimpleItem colSpan={1}>
                                    <div style={{marginTop:"-1rem"}}>
                                    <Chart id="chart"
                                        dataSource={props.initDataTMC?.aadTs ?? []}
                                        // dataSource={mockData}
                                        >
                                        <Margin top={0} bottom={10} left={20} right={20} />
                                        <CommonSeriesSettings argumentField="aadtRawDataDT" type="line"/>
                                      <Series
                                        valueField="totalVolume"
                                        name={t('total')}
                                        color="#0b1d78"
                                        axis="leftAxis"
                                        visible={isTotalVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <Series
                                        valueField="majorVolume"
                                        name={t('major')}
                                        color="#008ac5"
                                        axis="leftAxis"
                                        visible={isMajorVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <Series
                                        valueField="minorVolume"
                                        name={t('minor')}
                                        color="#00a9b5"
                                        axis="leftAxis"
                                        visible={isMinorVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <Series
                                        valueField="northApproachAADT"
                                        name={t('north')}
                                        color="#5e1788"
                                        axis="rightAxis"
                                        visible={isNorthVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <Series
                                        valueField="eastApproachAADT"
                                        name={t('east')}
                                        color="#ab006f"
                                        axis="rightAxis"
                                        visible={isEastVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <Series
                                        valueField="southApproachAADT"
                                        name={t('south')}
                                        color="#f05442"
                                        axis="rightAxis"
                                        visible={isSouthVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <Series
                                        valueField="westApproachAADT"
                                        name={t('west')}
                                        color="#007b5e "
                                        axis="rightAxis"
                                        visible={isWestVisible}
                                      >
                                        <Point visible={true} symbol="circle" size={8} />
                                      </Series>
                                      <ValueAxis
                                        name="leftAxis"
                                        position="left"
                                        // valueType="startDT"
                                        showZero={true}
                                        valueMarginsEnabled={false}
                                        allowDecimals={false}
                                      >
                                        <Title text={t("total/major/minor")} />
                                      </ValueAxis>
                                      <ValueAxis
                                        name="rightAxis"
                                        position="right"
                                        showZero={true}
                                        valueMarginsEnabled={false}
                                        allowDecimals={false}
                                      >
                                        <Title text={t("north/east/south/west")} />
                                      </ValueAxis>
                                      <ArgumentAxis title={t('year')} >
                                        <Label customizeText={customizeArgumentAxisLabels}/>
                                      </ArgumentAxis>
                                      <Legend position="inside" horizontalAlignment="right" />
                                      <Export enabled={true} />
                                      <Tooltip enabled={true} location="edge" />
                                      <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip} />
                                    </Chart>

                                  <div style={{display:"flex", justifyContent:"center"}}>
                                    <CheckBox
                                      text={t('total')}
                                      value={isTotalVisible}
                                      onValueChanged={(e) => setIsTotalVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                    <CheckBox
                                      text={t('major')}
                                      value={isMajorVisible}
                                      onValueChanged={(e) => setIsMajorVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                    <CheckBox
                                      text={t('minor')}
                                      value={isMinorVisible}
                                      onValueChanged={(e) => setIsMinorVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                    <CheckBox
                                      text={t('north')}
                                      value={isNorthVisible}
                                      onValueChanged={(e) => setIsNorthVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                    <CheckBox
                                      text={t('east')}
                                      value={isEastVisible}
                                      onValueChanged={(e) => setIsEastVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                    <CheckBox
                                      text={t('south')}
                                      value={isSouthVisible}
                                      onValueChanged={(e) => setIsSouthVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                    <CheckBox
                                      text={t('west')}
                                      value={isWestVisible}
                                      onValueChanged={(e) => setIsWestVisible(e.value)}
                                      style={{margin:"0.75rem 1rem 1rem 1rem"}}
                                    />
                                  </div>
                                </div>
                                </SimpleItem>
                                } */}
                    </Form>
                  </CollapsibleCard>
                </div>
              </SimpleItem>
            </Form>
          </ValidationGroup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default IntersectionAADT;
