import { ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InfrastructureLocationPicker from "../../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { useAuth } from "../../../../../../contexts/auth";
import { VMMapLocation } from "../../../../../../types/collision/dto/collisionDtos";
import { TesMapModule } from "../../../../../../types/general/enums/generalEnums";
import { VMLocation } from "../../../../../../types/infrastructure/dto/locationdDto";
import {
  LocationPickerMode,
  LocationType,
} from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import { StudyBaseDTO } from "../../../../../../types/trafficStudy/dtos/studyDto";
import { StudyType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";

// props
interface IPros {
  initDataBase: StudyBaseDTO;
  didMount: boolean;
  tempMapLocation: VMMapLocation;
  mapLocation: VMMapLocation;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
  renderMap: boolean;
  setInfrastructureData: (inData: VMLocation) => void;
  initLocationData: any;
  tesModule: TesMapModule;
  handleLocationChange: (loc: VMMapLocation) => void;
  selectedMapLocation: VMMapLocation;
  componentMode: LocationPickerMode;
  renderCoordinate: boolean;
}

const Map = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setInfrastructureData(inData: VMLocation) {
    props.setInitDataBase({
      ...props.initDataBase,
      locationId: inData.id,
      locationDescription: inData.description,
      locationType: inData.locationType,
      geoId: inData.geoId,
      speedLimit:
        props.initDataBase.speedLimit === 0 ||
        props.initDataBase.speedLimit === undefined ||
        props.initDataBase.speedLimit === null
          ? inData.postedSpeed
          : props.initDataBase.speedLimit,
      sectionType:
        props.initDataBase.sectionType?.valueOf() === 0 ||
        props.initDataBase.sectionType == undefined ||
        props.initDataBase.sectionType == null
          ? inData.sectionType
          : props.initDataBase.sectionType,
      jurisdictionId: inData.jurisdictionId,
      municipalityId: inData.municipalityId,
      divisionId: inData.divisionId,
    });
  }
  function handleLocationChange(loc: VMMapLocation) {
    props.setMapLocation(loc);
  }
  // const handleLocationChange = useCallback(async (loc: VMMapLocation) => {
  //     props.setInitDataBase({ ...props.initDataBase })

  //   }, []);

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <InfrastructureLocationPicker
                didMount={props.didMount}
                renderMap={props.renderMap}
                setInfrastructureData={setInfrastructureData}
                initLocationData={{
                  customerId: props.initDataBase.customerId,
                  id: props.initDataBase.locationId,
                  description: props.initDataBase.locationDescription,
                  geoId: props.initDataBase.geoId,
                  locationType: props.initDataBase.locationType,
                }}
                tesModule={TesMapModule.StudyDetails}
                handleLocationChange={handleLocationChange}
                tempMapLocation={props.tempMapLocation}
                selectedMapLocation={props.mapLocation}
                componentMode={LocationPickerMode.TextBox}
                renderCoordinate={true}
                locationType={
                  props.initDataBase.studyType === StudyType.TMC
                    ? LocationType.Intersection
                    : LocationType.Midblock
                }
                showIcons={true}
                isLocked={props.isLocked}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default Map;
