const tabTitles = [
  {
    id: 0,
    text: "mmsCategoryByRoadSegment",
    icon: "fa-solid fa-file-lines",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "mmsCategoryByRin",
    icon: "fa-solid fa-layer-group",
    content: "Smoothing tab content",
  },
  {
    id: 2,
    text: "mmsRankMap",
    icon: "fa-solid fa-map-location-dot",
    content: "Map tab content",
  },
];

const tabTitlesForAdd = [
  {
    id: 0,
    text: "mmsCategoryByRin",
    icon: "fa-solid fa-layer-group",
    content: "Smoothing tab content",
  },
  {
    id: 1,
    text: "mmsRankMap",
    icon: "fa-solid fa-map-location-dot",
    content: "Map tab content",
  },
];

export { tabTitles, tabTitlesForAdd };
