import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import OverViewReport from "../../../../../components/overViewReport/overViewReport";
import { useAuth } from "../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";

// props
interface IPros {
  locationId: string;
  isLocked: boolean;
  generalSettings: GeneralSetting | null;
}

const OverView = (props: IPros) => {
  const [generalSetting] = useState<GeneralSetting>(new GeneralSetting());
  const { activeLoading } = useAuth();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className={"content-block"} style={{ margin: "0.5rem" }}>
        <div className="row">
          <OverViewReport
            locationType={LocationType.Intersection}
            generalSettings={generalSetting}
            locationId={props.locationId}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default OverView;
