//#region imports
import { ContextMenu } from "devextreme-react";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import {
  CollisionPermissions,
  TMSPermissions,
} from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { CollisionApiUrl } from "../../../../environment/routeSettings";
import { XmlImportSetting } from "../../../../types/collision/collisionTypes";
import { XMLImportLogDTO } from "../../../../types/collision/dto/collisionDtos";
import { OnExporting } from "../../../../utils/dataGridTools";
import { TesGet } from "../../../../utils/rest";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import "./importLog.scss";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

//#endregion imports
const XMLImportLogs = () => {
  //#region consts
  const [initData, setInitData] = useState<XMLImportLogDTO[]>([]);
  const [initDataFieldSetting, setInitDataFieldSetting] = useState<
    XmlImportSetting[]
  >([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const history = useNavigate();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
  ];
  //#endregion

  //#region functions
  useBrowserTitle({ pathname: "/collision/import/xml/xmlImportLogs" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        await getInitDataFieldSettings();
        // setLstStatuses(Enum2Array(ImportStatus));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        CollisionApiUrl() +
          "/api/xmlImport/GetXMLImportLogs/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitDataFieldSettings() {
    setInitDataFieldSetting(
      await TesGet(
        CollisionApiUrl() +
          "/api/xmlImport/GetAllXMLImportSettings/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const navigateToDetails = useCallback(
    (data: any) => {
      history("/collision/import/xml/xmlImportLogDetails/" + data.data.id);
    },
    [history]
  );

  //#endregion functions

  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_GeoCode,
        TMSPermissions.TMS_Admin,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`xmlImportLogs ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data.id,
                "/collision/import/xml/xmlImportLogDetails/"
              )
            }
          />
          <h2 className={"content-block"}>{t("xmlImportLogs")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
                columnAutoWidth={true}
                onExporting={OnExporting}
                onRowClick={navigateToDetails}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="id" caption={t("Id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="userId"
                  caption={t("userId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="startDT"
                  caption={t("startDT")}
                  dataType="datetime"
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="endDT"
                  caption={t("endDT")}
                  dataType="datetime"
                  visible={true}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="importSettingId"
                  caption={t("importSetting")}
                  visible={true}
                >
                  <Lookup
                    dataSource={initDataFieldSetting}
                    valueExpr={"id"}
                    displayExpr={"title"}
                  />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default XMLImportLogs;
