import { RequestResponseResult } from "../../types/general/generalTypes";
import { RequestErrorHandling, TesGet } from "../../utils/rest";
import { ReportApiUrl } from "../../environment/routeSettings";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import { TClientReport } from "../../types/report/reportTypes";

export const storeReportsDataKeyName = "TESReportsData";

export const getReportsData = async () => {
  const customerId = localStorage.getItem("selectedCustomerId");
  if (!customerId) return undefined;
  try {
    const res: RequestResponseResult<TClientReport[]> = await TesGet(
      `${ReportApiUrl()}/api/ClientReports/GetAllPagesReports/${customerId}`,
      true,
      false
    );
    if (res.responseCode === ResponseCode.OK) {
      return res.results;
    } else {
      await RequestErrorHandling(res);
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching Reports:", error);
    return undefined;
  }
};

export const getStoredReportsData = (): TClientReport[] | undefined => {
  const storedData = localStorage.getItem(storeReportsDataKeyName);
  if (!storedData) return undefined;
  return JSON.parse(storedData);
};
export const storeReportsData = (data: TClientReport[]) => {
  localStorage.setItem(storeReportsDataKeyName, JSON.stringify(data));
};
