import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import ShareURL from "../../../../components/shareURL/shareURL";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import reportLocations, {
  TReportLocation,
} from "../../../../constants/reportLocations";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import {
  FieldApiUrl,
  TrafficApiUrl,
} from "../../../../environment/routeSettings";
import { VMMapLocation } from "../../../../types/collision/dto/collisionDtos";
import {
  PagePermission,
  ResponseCode,
  TesMapModule,
} from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import { VMLocation } from "../../../../types/infrastructure/dto/locationdDto";
import {
  LocationPickerMode,
  LocationType,
} from "../../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import {
  ReportingPeriodDTO,
  StudyBaseDTO,
  StudySpeedWebDetailsDTO,
  StudyTmcWebDetailsDTO,
  StudyVolumeWebDetailsDTO,
} from "../../../../types/trafficStudy/dtos/studyDto";
import { SimpleTrafficCounterDTO } from "../../../../types/trafficStudy/dtos/TrafficCounterDTO";
import {
  StudyCategory,
  StudyType,
} from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { IStudyParams } from "../../../../types/trafficStudy/trafficStudyTypes";
import { CreateDateAsUTC } from "../../../../utils/dateTimeTools";
import MaxDropdownItemWidthCalculator from "../../../../utils/dropDownWidthCalculator";
import { Enum2Key } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import AllWayStopWarrant from "./components/allWayStopWarrant";
import Details from "./components/details/details";
import Files from "./components/files";
import IntersectionAADT from "./components/intersectionAADT/intersectionAADT";
import Logs from "./components/logs";
import Map from "./components/map/map";
import RelatedStudies from "./components/relatedStudies/relatedStudies";
import ReportingPeriod from "./components/reportingPeriod/reportingPeriod";
import RoadSegmentAADT from "./components/roadSegmentAADT/roadSegmentAADT";
import SignalWarrantsTab from "./components/signalWarrants/signalWarrants";
import SpeedRawData from "./components/speedRawData/speedRawData";
import SpeedStatistics from "./components/speedStatistics/speedStatistics";
import TMCResults from "./components/tmcResults";
import VolumeRawData from "./components/volumeRawData/volumeRawData";
import VolumeResults from "./components/volumeResults";
import WorkOrder from "./components/workOrder/workOrder";
import { speedTabTitles, tmcTabTitles, volumeTabTitles } from "./data/index";
import "./studyDetails.scss";
import TesTabs from "../../../../components/tesTab/tesTab";
import ReportSelector from "../../../../components/reportSelector";
import useBrowserTitle from "../../../../hooks/browserTitle/useBrowserTitle";

const StudyDetails = () => {
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;
  const history = useNavigate();
  const params = useParams<IStudyParams>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initDataBase, setInitDataBase] = useState<StudyBaseDTO>(
    new StudyBaseDTO()
  );
  const [initDataTMC, setInitDataTMC] = useState<StudyTmcWebDetailsDTO>(
    new StudyTmcWebDetailsDTO()
  );
  const [initDataVolume, setInitDataVolume] =
    useState<StudyVolumeWebDetailsDTO>(new StudyVolumeWebDetailsDTO());
  const [initDataSpeed, setInitDataSpeed] = useState<StudySpeedWebDetailsDTO>(
    new StudySpeedWebDetailsDTO()
  );
  const [tempMapLocation, setTempMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const [mapLocation, setMapLocation] = useState<VMMapLocation>(
    new VMMapLocation()
  );
  const [dataChanged, setDataChanged] = useState(false);
  const reportingPeriodValidation = useRef<ValidationGroupRef>(null);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const didMount = useRef(false);
  const [isLocked, setIsLocked] = useState(true);
  const [files, setFiles] = useState<any[]>([]);
  const [removedFile, setRemovedFile] = useState<string[]>([]);
  const [lstTrafficCounter, setLstTrafficCounter] =
    useState<SimpleTrafficCounterDTO[]>();
  const validationRef = useRef<ValidationGroupRef>(null);
  const [reportTypeList, setReportTypeList] = useState<TReportLocation[]>([]);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const studyNoRef = useRef<HTMLSpanElement>(null);
  const studyTypeRef = useRef<HTMLSpanElement>(null);
  const descRef = useRef<HTMLSpanElement>(null);
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);
  const { getPagePermission } = useAuth();

  const [locationColor, setLocationColor] = useState<string>("#424242");
  const [locationCursor, setLocationCursor] = useState<string>("default");

  useBrowserTitle({
    pathname: "/trafficStudy/studies/studyDetails",
    additionalText: `${t("withStudyNo")}: ${initDataBase.studyNo} ${t("andStudyType")}: ${Enum2Key(StudyType, initDataBase?.studyType)} `,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          await getLstTrafficCounter();
          if (params.studyId !== "AddNew") {
            await getInitDataStudy(params.studyId!);
            setSelectedIndex(0);
          } else {
            setIsLocked(false);
            await getInfrastructureTesCodeValues();
            var studyType = parseInt(params.studyType!) as StudyType;

            const reportingPeriod = (await TesGet(
              TrafficApiUrl() +
                "/api/Study/GetReportingPeriod/" +
                localStorage.getItem("selectedCustomerId") +
                "/" +
                studyType,
              true
            )) as RequestResponseResult<ReportingPeriodDTO[]>;
            setInitDataBase({
              ...initDataBase,
              studyType: studyType,
              customerId: localStorage.getItem("selectedCustomerId")!,
              reportingPeriods: reportingPeriod.results,
            });
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }

    fetchMyAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.studyId]);

  async function getLstTrafficCounter() {
    const postObj: LazyLoadingRequestBase = {
      customerId: localStorage.getItem("selectedCustomerId") as string,
      divisionId: null,
      filter: { returnAll: true },
    };
    const res = (await TesPost(
      TrafficApiUrl() + "/api/Import/GetCustomerSimplifiedTrafficCounter",
      postObj,
      true
    )) as RequestResponseResult<SimpleTrafficCounterDTO[]>;
    if (res.responseCode === ResponseCode.OK) {
      setLstTrafficCounter(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  async function getInitDataStudy(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TrafficApiUrl() + "/api/Study/GetStudyById/" + id,

        true
      )) as RequestResponseResult<any>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitDataBase(res.results);
        if (getPagePermission)
          setPagePermissionStatus(
            getPagePermission(
              [
                TrafficStudyPermissions.TrafficStudy_D_Study,
                TrafficStudyPermissions.TrafficStudy_E_Study,
              ],
              res.results
            )
          );
        if (res.results.mapLocation != null) {
          setTempMapLocation(res.results.mapLocation);
          setMapLocation(res.results.mapLocation);
        }
        if (res.results.studyType === StudyType.TMC) {
          var tmcRes = new StudyTmcWebDetailsDTO();
          Object.assign(tmcRes, res.results);
          setInitDataTMC(tmcRes);
          setReportTypeList([
            reportLocations.TrafficStudy_StudyDetails_TMCDetails,
          ]);
        }
        if (res.results.studyType === StudyType.Volume) {
          var speedRes = new StudySpeedWebDetailsDTO();
          var volumeResult = new StudyVolumeWebDetailsDTO();
          var tmcRes = new StudyTmcWebDetailsDTO();
          Object.assign(speedRes, res.results);
          Object.assign(volumeResult, res.results);
          Object.assign(tmcRes, res.results);
          setInitDataTMC(tmcRes);
          setInitDataVolume(volumeResult);
          setInitDataSpeed(speedRes);
          setReportTypeList([
            reportLocations.TrafficStudy_StudyDetails_VolumeDetails,
          ]);
        }
        if (res.results.studyType === StudyType.Speed) {
          var speedRes = new StudySpeedWebDetailsDTO();
          //let tmcResult = new StudyTmcWebDetailsDTO();
          var volumeResult = new StudyVolumeWebDetailsDTO();
          Object.assign(speedRes, res.results);
          Object.assign(volumeResult, res.results);
          var tmcRes = new StudyTmcWebDetailsDTO();
          tmcRes.rawData = speedRes.rawData;
          Object.assign(tmcRes, res.results);
          setInitDataTMC(tmcRes);
          setInitDataVolume(volumeResult);
          setInitDataSpeed(speedRes);
          setReportTypeList([reportLocations.TrafficStudy_StudyDetails_Speed]);
        }
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
          "/api/codeValues/infrastructureTesCodeValues/" +
          localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.studyId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false);
                } else {
                  onAdd();
                  setDataChanged(false);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //add and update functions
  async function onUpdate() {
    if (initDataBase.signalWarrantConfigChanged) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("signalWarrantConfigChanged"),
        buttons: [
          {
            text: t("update"),
            onClick: async (e) => {
              await update();
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
            },
          },
        ],
      });
      myDialog.show();
    } else if (initDataBase.allWayStopWarrantConfigChanged) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("allWayStopWarrantConfigChanged"),
        buttons: [
          {
            text: t("update"),
            onClick: async (e) => {
              await update();
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
            },
          },
        ],
      });
      myDialog.show();
    } else {
      await update();
    }
  }

  async function update() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      if (activeLoading) activeLoading(true);
      let postObj: any;
      if (initDataBase.studyType === StudyType.TMC) {
        postObj = {
          ...initDataBase,
          mapLocation: mapLocation,
          aadt: initDataTMC.aadt,
          aadTs: initDataTMC.aadTs,
        };
      } else if (initDataBase.studyType === StudyType.Speed) {
        //setReportTypeList([reportLocations.TrafficStudy_StudyDetails_SpeedDetails])
        postObj = {
          ...initDataBase,
          mapLocation: mapLocation,
          aadt: initDataSpeed.aadt,
          aadTs: initDataSpeed.aadTs,
        };
      } else {
        //setReportTypeList([reportLocations.TrafficStudy_StudyDetails_VolumeDetails])
        postObj = {
          ...initDataBase,
          mapLocation: mapLocation,
        };
      }

      delete postObj.signalWarrantConfig.studyBase;
      delete postObj.rawData;
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Study/updateStudy",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        await getInitDataStudy(initDataBase.id!);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        setDataChanged(false);
      } else if (res.responseCode === ResponseCode.WorkOrderExist) {
        notify(t("workOrderExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    }
  }

  async function onAdd() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      try {
        const postObj: StudyBaseDTO = {
          ...initDataBase,
          customerId: localStorage.getItem("selectedCustomerId")!,
          //divisionId: localStorage.getItem("defaultDivisionId")!,
          studyType: parseInt(params.studyType!),
          mapLocation: mapLocation,
        };
        if (activeLoading) activeLoading(true);
        const res = (await TesPost(
          TrafficApiUrl() + "/api/Study/AddStudy",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (activeLoading) activeLoading(false);
        if (res.responseCode === ResponseCode.OK) {
          notify(t("dataSuccessfullyAdded"), "success", 5000);
          history(-1);
          return;
        } else if (res.responseCode === ResponseCode.WorkOrderExist) {
          notify(t("workOrderExist"), "error", 5000);
        } else {
          await RequestErrorHandling(res);
        }
      } catch {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred"), "error", 5000);
      }
    }
    setDataChanged(false);
  }

  function setInfrastructureData(inData: VMLocation) {
    setInitDataBase({
      ...initDataBase,
      locationId: inData.id,
      locationDescription: inData.description,
      locationType: inData.locationType,
      geoId: inData.geoId,
      speedLimit:
        initDataBase.speedLimit === 0
          ? inData.postedSpeed
          : initDataBase.speedLimit,
      jurisdictionId: initDataBase.jurisdictionId,
      municipalityId: inData.municipalityId,
    });
  }

  function handleLocationChange(loc: VMMapLocation) {
    setMapLocation(loc);
  }

  async function onDelete() {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              const postObj = {
                id: initDataBase.id,
                customerId: initDataBase.customerId,
                divisionId: initDataBase.divisionId,
              };
              const res = (await TesPost(
                TrafficApiUrl() + "/api/Study/deleteStudy",
                postObj,
                true
              )) as RequestResponseResult<any>;
              history(-1);
              if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyDeleted"), "success", 5000);
                return;
              } else {
                await RequestErrorHandling(res);
              }
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && studyNoRef.current && studyNoRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else if (option === "Study No") {
        textToCopy = studyNoRef.current.innerText;
      } else if (option === "Study Type") {
        textToCopy = studyTypeRef.current?.innerText!;
      } else {
        textToCopy = descRef.current?.innerText!;
      }

      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  function handleChangeFile(lstFiles: any) {
    setFiles([...files, ...lstFiles]);
    setDataChanged(true);
  }

  function onDeleteNewFile(name: string) {
    setFiles([...files.filter((x) => x.name !== name)]);
    setDataChanged(true);
  }

  function onDeleteExistingFile(key: string) {
    setInitDataBase({
      ...initDataBase,
      files: initDataBase.files.filter((x) => x.key !== key),
    });
    setRemovedFile([...removedFile, key]);
    setDataChanged(true);
  }

  function onLocationDetails() {
    if (initDataBase?.locationType === LocationType.Intersection) {
      const newTabUrl =
        "/infrastructure/intersectionDetails/" + initDataBase.locationId;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    } else if (initDataBase?.locationType === LocationType.Midblock) {
      const newTabUrl =
        "/infrastructure/roadSegmentDetails/" + initDataBase.locationId;
      window.open(newTabUrl, "_blank"); // Opens the URL in a new tab
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
        TrafficStudyPermissions.TrafficStudy_R_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`studyDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    {initDataBase.studyCategory !==
                      StudyCategory.Classification && (
                      <span ref={studyTypeRef}>
                        {Enum2Key(StudyType, initDataBase?.studyType)}
                      </span>
                    )}
                    {initDataBase.studyCategory !==
                      StudyCategory.Classification && (
                      <span> {t("study")}: </span>
                    )}
                    {initDataBase.studyCategory ===
                      StudyCategory.Classification && (
                      <span ref={studyTypeRef}>{t("classification")}: </span>
                    )}
                    <span ref={studyNoRef}>{initDataBase.studyNo}</span>
                    <span> - </span>
                    <span
                      ref={descRef}
                      onMouseOver={(e) => {
                        setLocationColor("#F0B70d");
                        setLocationCursor("pointer");
                      }}
                      onMouseLeave={(e) => {
                        setLocationColor("#424242");
                        setLocationCursor("default");
                      }}
                      style={{ color: locationColor, cursor: locationCursor }}
                      onClick={onLocationDetails}
                    >
                      {initDataBase.locationDescription}
                    </span>
                    <span> </span>
                    <span ref={geoIdRef}>[{initDataBase?.geoId}]</span>
                  </p>
                  <DropDownButton
                    style={{ marginLeft: 10, width: "6rem" }}
                    icon="fa-solid fa-copy"
                    items={[
                      { name: t("description"), value: t("description") },
                      { name: t("geoId"), value: t("geoId") },
                      { name: t("studyNo"), value: t("studyNo") },
                      { name: t("studyType"), value: t("studyType") },
                    ]}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator([
                        { name: t("description"), value: t("description") },
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("studyNo"), value: t("studyNo") },
                        { name: t("studyType"), value: t("studyType") },
                      ]),
                    }}
                    displayExpr="name"
                    onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                {pagePermissionStatus === PagePermission.Edit &&
                  params.studyId !== "AddNew" && (
                    <div className="rightColumn">
                      <Permission
                        allowed={[TrafficStudyPermissions.TrafficStudy_D_Study]}
                        hasFeedBackElement={false}
                      >
                        {params.studyId !== "AddNew" && <ShareURL url={url} />}
                        <Button
                          onClick={() => LockHandler()}
                          icon={
                            isLocked
                              ? "fa-solid fa-lock"
                              : "fa-solid fa-lock-open"
                          }
                          hint={isLocked ? t("unlock") : t("lock")}
                        />
                      </Permission>
                    </div>
                  )}
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="leftColumn" style={{ gap: "6px" }}>
                    <Button
                      onClick={goBackPermission}
                      icon="fa-solid fa-arrow-left"
                      hint={t("goBack")}
                    />
                    {params.studyId !== "AddNew" && (
                      <ReportSelector
                        reportLocations={[...reportTypeList]}
                        getRecords={() => [
                          {
                            endDT: CreateDateAsUTC(initDataBase.rawDataEndDT!),
                            startDT: CreateDateAsUTC(
                              initDataBase.rawDataEndDT!
                            ),
                            id: initDataBase.id!,
                          },
                        ]}
                      />
                    )}
                  </div>

                  <div className="rightColumn">
                    {params.studyId === "AddNew" && (
                      <Permission
                        allowed={[TrafficStudyPermissions.TrafficStudy_D_Study]}
                        hasFeedBackElement={false}
                      >
                        <Button
                          onClick={onAdd}
                          icon="fa-solid fa-floppy-disk"
                          hint={t("save")}
                        />
                      </Permission>
                    )}
                    {params.studyId !== "AddNew" && isLocked === false && (
                      <Permission
                        allowed={[TrafficStudyPermissions.TrafficStudy_E_Study]}
                        hasFeedBackElement={false}
                      >
                        <Button
                          onClick={onUpdate}
                          icon="fa-solid fa-floppy-disk"
                          hint={t("update")}
                        />
                      </Permission>
                    )}
                  </div>
                  <div className="rightColumn">
                    <Permission
                      allowed={[TrafficStudyPermissions.TrafficStudy_R_Study]}
                      hasFeedBackElement={false}
                    >
                      {!isLocked &&
                        !window.location.href.includes("AddNew") && (
                          <Button
                            onClick={onDelete}
                            icon="fa-solid fa-trash"
                            hint={t("delete")}
                          />
                        )}
                    </Permission>
                  </div>
                </div>
              </div>
              <div className={"dx-card "}>
                <TesTabs
                  width={"100%"}
                  dataSource={
                    initDataBase.studyType === StudyType.TMC
                      ? tmcTabTitles
                      : initDataBase.studyType === StudyType.Volume
                        ? volumeTabTitles
                        : speedTabTitles
                  }
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />

                {selectedIndex === 0 && (
                  <Details
                    params={params}
                    generalSettings={generalSetting}
                    tempMapLocation={tempMapLocation}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    setInitDataBase={setInitDataBase}
                    didMount={didMount.current}
                    initDataBase={initDataBase}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    lstTrafficCounter={lstTrafficCounter!}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 1 && (
                  <Map
                    didMount={didMount.current}
                    renderMap={true}
                    setInfrastructureData={setInfrastructureData}
                    initLocationData={{
                      customerId: initDataBase.customerId,
                      id: initDataBase.locationId,
                      description: initDataBase.locationDescription,
                      geoId: initDataBase.geoId,
                      locationType: initDataBase.locationType,
                    }}
                    tesModule={TesMapModule.StudyDetails}
                    handleLocationChange={handleLocationChange}
                    tempMapLocation={tempMapLocation}
                    selectedMapLocation={mapLocation}
                    componentMode={LocationPickerMode.TextBox}
                    renderCoordinate={false}
                    initDataBase={initDataBase}
                    validationRef={validationRef}
                    mapLocation={mapLocation}
                    isLocked={isLocked}
                    setMapLocation={setMapLocation}
                    setInitDataBase={setInitDataBase}
                  />
                )}
                {selectedIndex === 2 && (
                  <WorkOrder
                    params={params}
                    setInitDataBase={setInitDataBase}
                    generalSettings={generalSetting}
                    initDataBase={initDataBase}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 3 && (
                  <ReportingPeriod
                    initDataBase={initDataBase}
                    reportingPeriodValidation={reportingPeriodValidation}
                    setInitDataBase={setInitDataBase}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 4 && (
                  <VolumeRawData
                    initDataBase={initDataTMC}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 5 &&
                  initDataBase.studyType === StudyType.Speed && (
                    <SpeedRawData
                      initDataSpeed={initDataSpeed}
                      generalSettings={generalSetting}
                      isLocked={isLocked}
                      validationRef={validationRef}
                    />
                  )}
                {selectedIndex === 7 &&
                  initDataBase.studyType === StudyType.Speed && (
                    <SpeedStatistics
                      initDataSpeed={initDataSpeed}
                      generalSettings={generalSetting}
                      isLocked={isLocked}
                      setDataChanged={setDataChanged}
                      validationRef={validationRef}
                    />
                  )}
                {selectedIndex === 6 &&
                  initDataBase.studyType === StudyType.TMC && (
                    <SignalWarrantsTab
                      generalSettings={generalSetting}
                      setInitDataBase={setInitDataBase}
                      initDataBase={initDataBase}
                      isLocked={isLocked}
                      setDataChanged={setDataChanged}
                      validationRef={validationRef}
                    />
                  )}
                {((selectedIndex === 8 &&
                  initDataBase.studyType === StudyType.Speed) ||
                  (selectedIndex === 6 &&
                    initDataBase.studyType === StudyType.Volume)) && (
                  <RoadSegmentAADT
                    generalSettings={generalSetting}
                    initDataSpeed={initDataSpeed}
                    setInitDataSpeed={setInitDataSpeed}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 7 &&
                  initDataBase.studyType === StudyType.TMC && (
                    <AllWayStopWarrant
                      generalSettings={generalSetting}
                      setInitDataBase={setInitDataBase}
                      initDataBase={initDataBase}
                      isLocked={isLocked}
                      setDataChanged={setDataChanged}
                      validationRef={validationRef}
                    />
                  )}
                {selectedIndex === 8 &&
                  initDataBase.studyType === StudyType.TMC && (
                    <IntersectionAADT
                      generalSettings={generalSetting}
                      initDataTMC={initDataTMC}
                      setInitDataTMC={setInitDataTMC}
                      isLocked={isLocked}
                      setDataChanged={setDataChanged}
                      validationRef={validationRef}
                    />
                  )}
                {selectedIndex === 5 &&
                  initDataBase.studyType === StudyType.TMC && (
                    <TMCResults
                      generalSettings={generalSetting}
                      isLocked={isLocked}
                      setDataChanged={setDataChanged}
                      initDataBase={initDataBase}
                      setInitDataBase={setInitDataBase}
                      initDataTmc={initDataTMC}
                      validationRef={validationRef}
                    />
                  )}
                {((selectedIndex === 6 &&
                  initDataBase.studyType === StudyType.Speed) ||
                  (selectedIndex === 5 &&
                    initDataBase.studyType === StudyType.Volume)) && (
                  <VolumeResults
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    initDataBase={initDataBase}
                    setInitDataBase={setInitDataBase}
                    initDataVolume={initDataVolume}
                    validationRef={validationRef}
                  />
                )}
                {((selectedIndex === 9 &&
                  initDataBase.studyType === StudyType.TMC) ||
                  (selectedIndex === 7 &&
                    initDataBase.studyType === StudyType.Volume) ||
                  (selectedIndex === 9 &&
                    initDataBase.studyType === StudyType.Speed)) && (
                  <Files
                    isLocked={isLocked}
                    initDataBase={initDataBase}
                    validationRef={validationRef}
                    files={files}
                    handleChangeFile={handleChangeFile}
                    onDeleteExistingFile={onDeleteExistingFile}
                    onDeleteNewFile={onDeleteNewFile}
                  />
                )}
                {((selectedIndex === 10 &&
                  initDataBase.studyType === StudyType.TMC) ||
                  (selectedIndex === 8 &&
                    initDataBase.studyType === StudyType.Volume) ||
                  (selectedIndex === 10 &&
                    initDataBase.studyType === StudyType.Speed)) && (
                  <Logs
                    generalSettings={generalSetting}
                    initDataBase={initDataBase}
                    validationRef={validationRef}
                    lstTrafficCounter={lstTrafficCounter}
                  />
                )}
                {((selectedIndex === 11 &&
                  initDataBase.studyType === StudyType.TMC) ||
                  (selectedIndex === 9 &&
                    initDataBase.studyType === StudyType.Volume) ||
                  (selectedIndex === 11 &&
                    initDataBase.studyType === StudyType.Speed)) && (
                  <RelatedStudies
                    generalSettings={generalSetting}
                    initDataBase={initDataBase}
                    validationRef={validationRef}
                    isLocked={isLocked}
                    initDataTesCodeValues={initDataTesCodeValues}
                  />
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default StudyDetails;
