import { ResponseCode } from "../../general/enums/generalEnums";
import { ErrorMessage } from "../../general/generalTypes";
import {
  LanguageType,
  TranslationValueStatus,
} from "../enums/translationEnums";

export class LanguageDetailsDtoListRequestResponseResult {
  id!: string;
  errorMessages!: ErrorMessage[];
  responseCode!: ResponseCode;
  results!: LanguageDetailsDto[];
}

export class LanguageDetailsDtoRequestResponseResult {
  id!: string;
  errorMessages!: ErrorMessage[];
  responseCode!: ResponseCode;
  results!: LanguageDetailsDto;
}

export class LanguageDetailsDto {
  id!: string;
  baseLanguageId!: string;
  languageCode!: string;
  languageName!: string;
  imageAddress!: string;
  description: string = "";
  languageType: LanguageType = 0;
  isDefault!: boolean;
  version!: number;
  parentLanguageId: string = "";
  parentLanguageName: string = "";
  customerId!: string;
  fileUrl: string | null = null;
  lastChangedUtcDateTime!: string;
  isGlobalDefault!: boolean;
}

export class LanguageTranslationDto {
  id!: string;
  baseLanguageId!: string;
  baseGroupLanguageId!: string;
  languageCode!: string;
  languageName!: string;
  imageAddress!: string;
  lastChangedUtcDateTime!: string;
  languageType: LanguageType = 0;
  translationObject!: { common: Record<string, string> };
}

export class AddLanguageDto {
  languageCode!: string;
  languageName!: string;
  imageAddress: string = "";
  description: string = "";
  isDefault!: boolean;
  languageType: LanguageType = 0;
  parentLanguageId!: string;
}

export class EditLanguageDto {
  id!: string;
  languageCode!: string;
  languageName!: string;
  imageAddress!: string;
  description: string = "";
  languageType: LanguageType = 0;
  parentLanguageId!: string;
}

export class GetTranslationDtoListRequestResponseResult {
  id!: string;
  errorMessages!: ErrorMessage[];
  responseCode!: ResponseCode;
  results!: GetTranslationDto[];
}

export class GetTranslationDto {
  keyId!: string;
  valueId!: string;
  key!: string;
  value!: string;
  status!: TranslationValueStatus;
  note!: string;
}

export class TranslateOfLanguageDto {
  languageId!: string;
  value!: string;
  status!: TranslationValueStatus;
  note!: string;
}

export class AddNewTranslationToLanguagesDto {
  key: string = "";
  translations: TranslateOfLanguageDto[] = [
    { languageId: "", value: "", status: TranslationValueStatus.OK, note: "" },
  ];
}

export class EditTranslationDto {
  languageId!: string;
  languageType: LanguageType = 0;
  translations!: GetTranslationDto[];
}

export class LanguageObjectDtoRequestResponseResult {
  errorMessages!: ErrorMessage[];
  responseCode!: ResponseCode;
  results!: LanguageObjectDto;
}

export class LanguageObjectDto {
  id!: string;
  baseLanguageId!: string;
  baseGroupLanguageId!: string;
  languageCode!: string;
  languageName!: string;
  imageAddress!: string;
  description: string = "";
  version!: number;
  languageType: LanguageType = 0;
  translations!: GetTranslationDto[];
}

export class CreateNewCustomizedLanguagesForCustomerDto {
  customerId!: string;
  languageDto!: AddLanguageDto;
}

export class AddExistsLanguageToCustomerDto {
  customerId!: string;
  languageId!: string;
  languageType: LanguageType = 0;
  isDefault!: boolean;
}

export class RemoveLanguageFromCustomerDto {
  customerId!: string;
  languageId!: string;
  languageType: LanguageType = 0;
}

export class SetDefaultLanguageForCustomerDto {
  id!: string;
  customerId!: string;
  languageId!: string;
  languageType: LanguageType = 0;
}

export class StringListRequestResponseResult {
  id!: string;
  errorMessages!: ErrorMessage[];
  responseCode!: ResponseCode;
  results!: string[];
}

export class EditKeyDto {
  keyId!: string;
  newKey!: string;
}

export class CustomerLanguageDto {
  customerId!: string;
  isDefault!: boolean;
}

export class GetKetDtos {
  keyId!: string;
  key!: string;
}
