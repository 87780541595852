import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useClientSetting } from "../../contexts/clientSetting";
import { FieldApiUrl, TrafficApiUrl } from "../../environment/routeSettings";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../types/field/enums/fieldEnums";
import { TesField } from "../../types/field/fieldType";
import { GridType, ResponseCode } from "../../types/general/enums/generalEnums";
import {
  LazyLoadingRequest,
  NameValue,
  RequestResponseResult,
} from "../../types/general/generalTypes";
import {
  CountStation,
  TrafficSection,
} from "../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../utils/dateTimeTools";
import { EnumFlag2Array } from "../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../utils/rest";
import { useNavigate } from "react-router-dom";
import { Button, LoadIndicator } from "devextreme-react";

interface IProps {
  label: string;
  value: string;
  name: string;
  onChangeFields: (name: string, value: any, isUpperCase: boolean) => void;
  isLocked: boolean;
  valueChanged: (e: string) => void;
}

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${TrafficApiUrl()}/api/TrafficSections/GetTrafficSections`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const TrafficSectionPicker = (props: IProps) => {
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [name, setName] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showTrafficSectionPicker, setShowTrafficSectionPicker] =
    useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { generalSetting } = useClientSetting();
  const { customerDivisions } = useClientSetting();
  const history = useNavigate();

  useEffect(() => {
    console.log("iscalled");
    async function fetchMyAPI() {
      setLoading(true);
      try {
        await getTrafficSection(props.value);
        setLoading(false);
      } catch (ex) {
        setLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function getInitialDataFields() {
    const res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      {
        customerId: localStorage.getItem("selectedCustomerId")!,
        categoryTypes: [
          FieldCategoryType.CountingStationGeneral,
          FieldCategoryType.PermanentCountingStationGeneral,
        ],
      },
      true
    )) as TesField[];

    setInitDataFields(
      res
        .filter(
          (obj, _, arr) =>
            arr.filter((o) => o.name === obj.name).length <= 1 ||
            obj.fieldCategoryType !==
              FieldCategoryType.PermanentCountingStationGeneral
        )
        .sort((a, b) => a.detailViewIndex - b.detailViewIndex)
    );
  }

  async function getTrafficSection(id: string) {
    if (
      id === undefined ||
      id === null ||
      id === "00000000-0000-0000-0000-000000000000"
    )
      return;
    const res = (await TesGet(
      TrafficApiUrl() + "/api/TrafficSections/GetTrafficSectionDetails/" + id,
      true
    )) as RequestResponseResult<TrafficSection>;
    if (res.responseCode === ResponseCode.OK) {
      if (res !== null) {
        setName(`Traffic Section - ${res.results.name}`);
      }
    } else {
      await RequestErrorHandling(res);
    }
  }

  function onRowClicked(selectedRowData: any) {
    setSelectedRowId(selectedRowData.data?._id);
    setShowTrafficSectionPicker(false);
    setName(
      `Traffic Section - ${selectedRowData.data?.GeoId} ${selectedRowData.data?.Name}`
    );
    props.onChangeFields(props.name, selectedRowData.data?._id, false);
  }

  async function onOpenPopUp() {
    if (!props.isLocked) {
      setShowTrafficSectionPicker(true);
      await getInitialDataFields();
    }
  }

  function openTrafficSectionDetails(id: string) {
    if (
      id === undefined ||
      id === null ||
      id === "00000000-0000-0000-0000-000000000000"
    )
      return;
    history("/infrastructure/trafficSectionDetails/" + id);
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <div style={{ flex: 1 }} onClick={() => onOpenPopUp()}>
          <TextBox
            label={props.label}
            labelMode="floating"
            value={name}
            disabled={props.isLocked}
            style={{ width: "100%" }}
          >
            <LoadIndicator
              visible={loading}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </TextBox>
        </div>
        <Button
          icon="fa-solid fa-location-dot"
          style={{ marginLeft: 5 }}
          onClick={(e) =>
            openTrafficSectionDetails(
              selectedRowId ? selectedRowId : props.value
            )
          }
          hint={t("trafficSection")}
          disabled={name === ""}
        />
      </div>
      <Popup
        width={"60%"}
        visible={showTrafficSectionPicker}
        resizeEnabled={true}
        showTitle={true}
        title={props.label}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowTrafficSectionPicker(false)}
      >
        <div style={{ height: "100%", display: "flex" }}>
          {showTrafficSectionPicker === true && (
            <DataGrid
              id="gridContainer"
              key={"_id"}
              ref={dataGridRef}
              dataSource={dataSource}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={onRowClicked}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              columnAutoWidth={true}
              height={"100%"}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={GridType.TrafficSection.toString()}
                savingTimeout={500}
              />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="_id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="DivisionId"
                allowSorting={true}
                visible={false}
                caption={t("division")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(customerDivisions!, "name")}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column
                dataField="Name"
                allowSorting={true}
                caption={t("name")}
                width={400}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="GeoId"
                allowSorting={true}
                caption={t("geoId")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="LHRS"
                allowSorting={true}
                caption={t("lhrs")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="Offset"
                allowSorting={true}
                caption={t("offset")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="NextTrafficSectionId"
                allowSorting={true}
                caption={t("nextTrafficSectionId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="PreviousTrafficSectionId"
                allowSorting={true}
                caption={t("previousTrafficSectionId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="OverlapTrafficSectionId"
                allowSorting={true}
                caption={t("overlapTrafficSectionId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="ExcludeMatchingTrending"
                allowSorting={true}
                caption={t("excludeMatchingTrending")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="IsNonAssumedRoad"
                allowSorting={true}
                caption={t("isNonAssumedRoad")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="IsBorderCrossing"
                allowSorting={true}
                caption={t("isBorderCrossing")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="IsOverLap"
                allowSorting={true}
                caption={t("isOverLap")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="IsPDCS"
                allowSorting={true}
                caption={t("isPDCS")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="IsDeleted"
                allowSorting={true}
                caption={t("isDeleted")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              {initDataFields &&
                initDataFields.length > 0 &&
                initDataFields.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(
                            d.fieldValues,
                            "name"
                          )}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.dateFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.timeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.dateTimeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        key={d.name}
                        dataField={d.name}
                        caption={d.labelText}
                        allowSorting={true}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
            </DataGrid>
          )}
        </div>
      </Popup>
    </React.Fragment>
  );
};
export default TrafficSectionPicker;
