//#region imports
import { createStore } from "devextreme-aspnet-data-nojquery";
import {
  ContextMenu,
  Form,
  Lookup,
  ScrollView,
  TextBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Summary,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { CollisionPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { CollisionApiUrl } from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { Hospitalization } from "../../../types/hospitalization/hospitalizationTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { RequestErrorHandling, TesPost } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import "./hospitalizations.scss";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

//#endregion imports

const dataSource = createStore({
  loadUrl: `${CollisionApiUrl()}/api/Hospitalizations/GetAllHospitalization/${localStorage.getItem(
    "selectedCustomerId"
  )}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
    ajaxOptions.headers = {
      "X-CSRF": "1",
    };
  },
});
const Hospitalizations = () => {
  //#region consts
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<Hospitalization>(
    new Hospitalization()
  );
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const { customerDivisions } = useClientSetting();
  //#endregion

  //#region functions

  useBrowserTitle({ pathname: "/hospitalization/hospitalizations" });

  const onValueChanged = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onCloseModal() {
    setShowModal(false);
    setSelectedData(new Hospitalization());
  }

  function onNew() {
    setShowModal(true);
  }

  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        customerId: localStorage.getItem("selectedCustomerId"),
      };
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        CollisionApiUrl() + "/api/Hospitalizations/AddNew",
        postObj,
        true
      )) as RequestResponseResult<Hospitalization>;
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        history("/hospitalization/hospitalizationDetails/" + res.results.id);
      } else if (res.responseCode === ResponseCode.CollisionNotExists) {
        notify(t("collisionNotExists"), "error", 5000);
      }
      await RequestErrorHandling(res);
      onCloseModal();
      if (activeLoading) activeLoading(false);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {
      if (isCtrlKeyPressed) {
        window.open(
          `/hospitalization/hospitalizationDetails/${e.data.id}`,
          "_blank"
        );
      } else {
        history(`/hospitalization/hospitalizationDetails/${e.data.id}`);
      }
    }
  }

  //#endregion functions

  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_V_Hospitalization,
        CollisionPermissions.Collision_D_Hospitalization,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={(e) =>
              RightClickMenu(
                e,
                selectedRowData.row.data.id,
                "/hospitalization/hospitalizationDetails/"
              )
            }
          />
          <h2 className={"content-block"}>{t("hospitalizations")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Permission
                    allowed={[CollisionPermissions.Collision_D_Hospitalization]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={onNew}
                      icon="fa-solid fa-circle-plus"
                      text={t("hospitalization")}
                    />
                  </Permission>
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={dataSource}
                onRowClick={onRowClicked}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={(e) => {
                  setSelectedRowData(e);
                }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.Street.toString()} savingTimeout={500} /> */}
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={285}
                  placeholder={t("search...")}
                />
                <ColumnChooser
                  width={350}
                  height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="Id"
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="accidentNumber"
                  caption={t("accidentNumber")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(
                      customerDivisions!,
                      "name"
                    )}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={t("add")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <TextBox
                    label={t("accidentNumber")}
                    labelMode="floating"
                    value={selectedData.accidentNumber}
                    onValueChange={(e) => onValueChanged("accidentNumber", e)}
                    className="modalInput"
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Hospitalizations;
