import { ResponseCode } from "../../general/enums/generalEnums";
import { ErrorMessage } from "../../general/generalTypes";
import { AreaDTO, VMLocation } from "../../infrastructure/dto/locationdDto";
import {
  SiteSubTypeAnalysisType,
  SiteSubTypeServerityType,
} from "../enums/siteTypeEnums";
import { AnalysisBase } from "../safetyTypes";

export class WorkBookDTORequestResponseResult {
  id!: string;
  errorMessages!: ErrorMessage;
  responseCode!: ResponseCode;
  results!: WorkBookDTO;
}

export class WorkBookDTO {
  constructor() {
    this.areas = [];
    this.analysis = [];
  }
  id!: string;
  customerId!: string;
  title!: string;
  description!: string;
  areas!: AreaDTO[];
  analysis!: AnalysisBase[];
}

export class SiteTypeDTO {
  id!: string;
  customerId!: string;
  name!: string;
  siteSubTypes?: SiteSubTypeDTO[];
}

export class SiteSubTypeDTO {
  constructor() {
    this.locations = [];
  }
  [key: string]: any;
  id!: string;
  description!: string;
  type!: SiteSubTypeAnalysisType;
  fatalInjurySpfFunctionId!: string;
  pdoSpfFunctionId!: string;
  serverityType!: SiteSubTypeServerityType;
  locations!: VMLocation[];
}
