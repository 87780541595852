import {
  ImportActionType,
  ImportCheckResponseStatus,
  ImportRequestStatus,
  ImportStatus,
} from "../enums/importEnums";
import {
  BinMapType,
  MovementType,
  StudyType,
} from "../enums/trafficStudyEnums";

export class ImportCheckRequest {
  constructor() {
    this.binMapId = "";
    this.fileKey = "";
  }
  customerId!: string;
  divisionId!: string;
  fileKey!: string;
  studyType?: StudyType;
  binMapId!: string;
  trafficCounterId!: string;
}

export class ImportCheckResponseStatusAction {
  [key: string]: any;
  checkStatus!: ImportCheckResponseStatus;
  actionType!: ImportActionType;
}

export class ImportCheckResponse {
  fileKey!: string;
  workOrderNo!: string;
  studyId!: string;
  studyType!: StudyType;
  binMapId!: string;
  customerId!: string;
  outgoingMovement!: MovementType;
  incomingMovement!: MovementType;
  statusAction!: ImportCheckResponseStatusAction[];
}
export class ImportResponse {
  fileKey!: string;
  studyType!: StudyType;
  status!: ImportStatus;
  note!: string;
}
export class ImportRequest {
  id!: string;
  customerId!: string;
  divisionId!: string;
  userId!: string;
  checkRequestDT!: Date;
  checkResponseDT!: Date;
  startImportDT!: Date;
  endImportDT!: Date;
  importRequestStatus!: ImportRequestStatus;
  importCheckRequests!: ImportCheckRequest[];
  importCheckResponses!: ImportCheckResponse[];
  importRequests!: ImportCheckResponse[];
  importResponses!: ImportResponse[];
  emails!: string[];
}

export class SimpleBinMapDTO {
  id!: string;
  name!: string;
  type!: BinMapType;
}

export class ImportResponseDTO {
  studyId!: string;
  trafficCounterId!: string;
  binMapId!: string;
  fileKey!: string;
  studyType!: StudyType;
  status!: ImportStatus;
  note!: string;
}
