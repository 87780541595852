//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { ModuleOfModulePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { ModuleOfModuleApiUrl } from "../../../environment/routeSettings";
import { VMField } from "../../../types/field/dto/fieldDTO";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import { IModuleParams } from "../../../types/moduleOfModules/moduleOfModulesTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { TesGet } from "../../../utils/rest";
import { LowerCaseFirstLetter } from "../../../utils/stringTools";
import "./data.scss";

const ModuleData = () => {
  //#region consts
  const [initData, setInitData] = useState<any[]>([]);
  const [initDataFields, setInitDataFields] = useState<VMField[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { generalSetting } = useClientSetting();
  const params = useParams<IModuleParams>();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  //#endregion consts

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        await getInitialDataFields();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const navigateToCollisionDetails = useCallback(() => {
    history("/moduleOfModules/dataDetails/" + params.moduleId + "/AddNew");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  async function getInitData() {
    setInitData(
      await TesGet(
        ModuleOfModuleApiUrl() +
          "/api/ClientModule/GetModuleDetailsData/" +
          params.moduleId,
        true
      )
    );
  }

  async function getInitialDataFields() {
    setInitDataFields(
      await TesGet(
        ModuleOfModuleApiUrl() +
          "/api/ClientModule/GetModuleFields/" +
          params.moduleId,
        true
      )
    );
  }

  function onRowClicked(data: any) {
    history(
      "/moduleOfModules/dataDetails/" + params.moduleId + "/" + data.data.id
    );
  }

  //#endregion functions
  return (
    <Permission
      allowed={[
        ModuleOfModulePermissions.ModuleOfModule_D_Data,
        ModuleOfModulePermissions.ModuleOfModule_E_Data,
        ModuleOfModulePermissions.ModuleOfModule_V_Data,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("data")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <Permission
                allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Data]}
                hasFeedBackElement={true}
              >
                <div className="rightColumn">
                  <Button
                    onClick={navigateToCollisionDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("data")}
                  />
                </div>
              </Permission>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initData}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={onRowClicked}
              hoverStateEnabled={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column dataField="Id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              {initDataFields?.map((d: VMField) => {
                if (d.fieldType === FieldType.List) {
                  return (
                    <Column
                      dataField={LowerCaseFirstLetter(d.name)}
                      allowSorting={false}
                      caption={d.labelText}
                      allowFiltering={true}
                    >
                      <Search enabled />
                      <Lookup
                        dataSource={SortObjectByPropName(d.fieldValues, "name")}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Number) {
                  return (
                    <Column
                      dataField={LowerCaseFirstLetter(d.name)}
                      allowSorting={false}
                      caption={d.labelText}
                      dataType="number"
                      alignment="left"
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Date) {
                  return (
                    <Column
                      dataField={LowerCaseFirstLetter(d.name)}
                      caption={d.labelText}
                      dataType="date"
                      format={generalSetting?.dateFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Time) {
                  return (
                    <Column
                      dataField={LowerCaseFirstLetter(d.name)}
                      caption={d.labelText}
                      dataType="datetime"
                      format={
                        generalSetting?.dateFormat
                          ? generalSetting?.dateFormat +
                            (generalSetting?.isAmPm === true
                              ? "hh:mm aa"
                              : "hh:mm")
                          : "MM/dd/yyyy hh:mm aa"
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.DateTime) {
                  return (
                    <Column
                      dataField={LowerCaseFirstLetter(d.name)}
                      caption={d.labelText}
                      dataType="datetime"
                      format={
                        generalSetting?.dateFormat
                          ? generalSetting?.dateFormat +
                            (generalSetting?.isAmPm === true
                              ? "hh:mm aa"
                              : "hh:mm")
                          : "MM/dd/yyyy hh:mm aa"
                      }
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      dataField={LowerCaseFirstLetter(d.name)}
                      allowSorting={false}
                      caption={d.labelText}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              })}
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default ModuleData;
