export enum LanguageType {
  Standard = 0,
  Groupe = 1,
  Customized = 2,
}

export enum TranslationValueStatus {
  OK = 0,
  NeedToReview = 1,
  NotApplicable = 2,
  ContextNeeded = 3,
}
