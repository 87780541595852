import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useClientSetting } from "../../contexts/clientSetting";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
} from "../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../types/field/dto/fieldDTO";
import {
  FieldCategoryType,
  FieldLocation2Show,
  FieldType,
} from "../../types/field/enums/fieldEnums";
import { TesField } from "../../types/field/fieldType";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import {
  INameId,
  LazyLoadingRequest,
  NameValue,
  RequestResponseResult,
} from "../../types/general/generalTypes";
import { VMLocation } from "../../types/infrastructure/dto/locationdDto";
import { LocationType } from "../../types/infrastructure/enums/infrastructureEnums";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../utils/dateTimeTools";
import { Enum2Array, EnumFlag2Array } from "../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../utils/rest";
import { InfrastructureLinearSystemType } from "../../types/infrastructure/infrastructureTypes";
import { useInfrastructure } from "../../contexts/infrastructure";

interface IProps {
  label: string;
  value: string;
  name: string;
  onChangeFields: (name: string, value: any, isUpperCase: boolean) => void;
  isLocked: boolean;
}

const dataSource = new CustomStore({
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()}/api/Locations/GetLocations`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("defaultDivisionId"),
        loadOptions: loadOption,
        filter: { type: LocationType.Intersection },
      } as LazyLoadingRequest,
      true
    );
  },
});

const IntersectionValuePicker = (props: IProps) => {
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [name, setName] = useState("");
  const [showIntersectionPicker, setIntersectionPicker] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [currentLocation, setCurrentLocation] = useState<VMLocation>();
  const { activeLoading } = useAuth();
  const history = useNavigate();
  const { generalSetting } = useClientSetting();
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([]);
  const [lstVolumeInheritType, setLstVolumeInheritType] = useState<NameValue[]>(
    []
  );

  const { infrastructureData } = useInfrastructure();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstLocationType(Enum2Array(LocationType));
        setLstVolumeInheritType(Enum2Array(lstVolumeInheritType));
        await getStreetName(props.value);
        await getInitDataSiteSubTypes();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.InfrastructureIntersectionGeneral,
        FieldCategoryType.InfrastructureIntersectionDetails,
        FieldCategoryType.InfrastructureIntersectionApproachDetails,
        FieldCategoryType.InfrastructureIntersectionOperation,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.gridViewIndex - b.gridViewIndex;
    });
    setInitDataFields(res);
  }

  async function getStreetName(id: string) {
    if (
      id === undefined ||
      id === null ||
      id === "00000000-0000-0000-0000-000000000000"
    )
      return;
    const res = (await TesPost(
      InfrastructureApiUrl() + "/api/Locations/GetLocationById",
      { id: id },
      true
    )) as RequestResponseResult<VMLocation>;
    if (res.responseCode === ResponseCode.OK) {
      if (res !== null) {
        if (res.results.locationType === LocationType.Intersection) {
          setName(
            `(Intersection - ${res.results.geoId}) ${res.results.description}`
          );
        } else if (res.results.locationType === LocationType.Midblock) {
          setName(
            `(Road Segment - ${res.results.geoId}) ${res.results.description}`
          );
        }
      }
      setCurrentLocation(res);
    } else {
      await RequestErrorHandling(res);
    }
  }

  // async function getInitialDataFieldValue() {
  //     setInitDataFieldsValue(await TesGet(FieldApiUrl() + "/api/CodeValues/GetAllCustomerCodeValue/" + localStorage.getItem('selectedCustomerId'), true));
  // }

  function onRowClick(selectedRowData: any) {
    setIntersectionPicker(false);
    if (selectedRowData.data.LocationType === LocationType.Intersection) {
      setName(
        `(Intersection - ${selectedRowData.data?.GeoId}) ${selectedRowData.data?.Description}`
      );
    }
    props.onChangeFields(props.name, selectedRowData.data?._id, false);
    setCurrentLocation({ id: selectedRowData.data?._id });
  }

  async function onOpenPopUp() {
    if (!props.isLocked) {
      setIntersectionPicker(true);
      await getInitialDataFields();
      // await getInitialDataFieldValue();
    }
  }

  function onLocationDetails() {
    if (currentLocation !== null && currentLocation?.id !== undefined) {
      history("/infrastructure/intersectionDetails/" + currentLocation?.id);
    }
  }

  const LHRSLinearSystemColumns = [
    { dataField: "EndLHRS", caption: t("endLHRS") },
    { dataField: "EndOffset", caption: t("endOffset") },
    { dataField: "LHRS", caption: t("lhrs") },
    { dataField: "NextEndOffset", caption: t("nextEndOffset") },
    { dataField: "NextLHRS", caption: t("nextLHRS") },
    { dataField: "NextStartOffset", caption: t("nextStartOffset") },
    { dataField: "PriorEndOffset", caption: t("priorEndOffset") },
    { dataField: "PriorLHRS", caption: t("priorLHRS") },
    { dataField: "PriorStartOffset", caption: t("priorStartOffset") },
    {
      dataField: "StartOffset",
      caption: t("StartOffset"),
      format: { type: "fixedPoint", precision: 2 },
    },
  ];
  const shouldRenderLHRSColumns =
    infrastructureData?.linearSystemType ===
    InfrastructureLinearSystemType.LHRS;

  return (
    <React.Fragment>
      <label onClick={() => onOpenPopUp()}>
        <TextBox
          // style={{ marginBottom: 20 }}
          label={props.label}
          labelMode="floating"
          value={name}
          disabled={props.isLocked}
          showClearButton={true}
          buttons={[
            {
              name: "search",
              location: "after",
              options: {
                icon: "fas fa-location-dot",
                stylingMode: "text",
                onClick: (x) => {
                  onLocationDetails();
                },
              },
            },
          ]}
        />
      </label>
      <Popup
        width={"60%"}
        visible={showIntersectionPicker}
        resizeEnabled={true}
        showTitle={true}
        title={props.label}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setIntersectionPicker(false)}
      >
        <div style={{ height: "100%" }}>
          {showIntersectionPicker === true && (
            <DataGrid
              ref={dataGridRef}
              dataSource={dataSource}
              style={{ height: "100%" }}
              rowAlternationEnabled={true}
              showBorders={true}
              selection={{ mode: "single" }}
              onRowClick={onRowClick}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />

              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="GeoId"
                allowSorting={true}
                caption={t("geoId")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Description"
                allowSorting={true}
                caption={t("description")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Inactive"
                allowSorting={true}
                caption={t("inactive")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Latitude"
                allowSorting={true}
                allowHeaderFiltering={false}
                caption={t("latitude")}
                format={{ type: "fixedPoint", precision: 4 }}
                visible={false}
              >
                <Search enabled />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Longitude"
                allowSorting={true}
                allowHeaderFiltering={false}
                caption={t("longitude")}
                format={{ type: "fixedPoint", precision: 4 }}
                visible={false}
              >
                <Search enabled />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="SiteSubTypeId"
                allowSorting={true}
                visible={false}
                caption={t("siteSubType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(
                    initDataSiteSubTypes,
                    "name"
                  )}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>

              <Column
                dataField="IsReviewNeeded"
                allowSorting={true}
                allowHeaderFiltering={false}
                caption={t("isReviewNeeded")}
                visible={false}
              >
                <Search enabled />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="OverwriteAutoGenerateDescription"
                allowSorting={true}
                allowHeaderFiltering={false}
                caption={t("overwriteAutoGenerateDescription")}
                visible={false}
              >
                <Search enabled />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="ReviewNote"
                allowSorting={true}
                allowHeaderFiltering={false}
                caption={t("reviewNote")}
                visible={false}
              >
                <Search enabled />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                dataField="TesId"
                allowSorting={true}
                allowHeaderFiltering={false}
                caption={t("TesId")}
                visible={false}
              >
                <Search enabled />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="LocationType"
                allowHeaderFiltering={true}
                caption={t("locationType")}
              >
                <Search enabled />
                <Lookup
                  dataSource={SortObjectByPropName(lstLocationType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="VolumeInheritType"
                allowHeaderFiltering={true}
                caption={t("volumeInheritType")}
                visible={false}
              >
                <Search enabled />
                <Lookup
                  dataSource={SortObjectByPropName(
                    lstVolumeInheritType,
                    "name"
                  )}
                  valueExpr="value"
                  displayExpr="name"
                />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              {shouldRenderLHRSColumns &&
                LHRSLinearSystemColumns.map((col) => (
                  <Column
                    key={col.dataField}
                    dataField={col.dataField}
                    allowSorting={true}
                    allowHeaderFiltering={false}
                    caption={col.caption}
                    format={col.format}
                    visible={false}
                  >
                    <Search enabled />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                ))}

              {initDataFields &&
                initDataFields?.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        allowFiltering={true}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(
                            d.fieldValues,
                            "name"
                          )}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="date"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.dateFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.timeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="datetime"
                        calculateCellValue={(e: any) =>
                          GridActualDateCalculator(e, d, generalSetting)
                        }
                        format={generalSetting?.dateTimeFormat}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Boolean) {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        dataType="boolean"
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        dataField={d.name}
                        caption={d.labelText}
                        allowHeaderFiltering={false}
                        visible={
                          EnumFlag2Array(
                            FieldLocation2Show,
                            d.fieldLocation2Show
                          ).some((x) => x === FieldLocation2Show.GridView)
                            ? true
                            : false
                        }
                      >
                        <Search enabled />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
            </DataGrid>
          )}
        </div>
      </Popup>
    </React.Fragment>
  );
};
export default IntersectionValuePicker;
