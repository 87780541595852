import React, {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import TreeView, { TreeViewRef } from "devextreme-react/tree-view";
import * as events from "devextreme/events";

import { getNavigation } from "../../app-navigation";
import { useNavigation } from "../../contexts/navigation";
import { useScreenSize } from "../../utils/media-query";
import { useAuth } from "../../contexts/auth";

import "./side-navigation-menu.scss";
import i18n from "../../react-i18next";
import { t } from "i18next";
import { ContextMenu } from "devextreme-react";
import { SidebarContextMenu } from "../../utils/rightClickMenu";
import { useTranslations } from "../../contexts/translations";

export default function SideNavigationMenu(props: any) {
  const { selectedItemChanged, openMenu, compactMode, onMenuReady } = props;
  const { user } = useAuth();
  const { selectedLanguage } = useTranslations();

  const { isLarge } = useScreenSize();

  const items = useMemo(
    () => {
      var p = ApplyPermissionsOnSidebarItems(getNavigation());
      const res = p.map((item: any) => ({ ...item, expanded: isLarge }));
      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNavigation, i18n.language, selectedLanguage]
  );

  const ContextMenuItems = [
    { text: t("openInNewTab"), icon: "fa-solid fa-up-right-from-square" },
  ];

  const [sidebarClickedData, setSidebarClickedData] = useState<any>(null);

  function ApplyPermissionsOnSidebarItems(array: any) {
    var res = [];
    var resIndex = 0;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [i, e] of array.entries()) {
      if (user?.permissions?.some((a) => e.allowed.indexOf(a) >= 0)) {
        res.push({ ...e, items: [] });
        if (e.items) {
          res[resIndex].items = ApplyPermissionsOnSidebarItems(e.items);
        }
        ++resIndex;
      }
    }
    return res;
  }

  const GetPaths = useCallback((list: any, res: string[]) => {
    if (!list) return;
    list.forEach((element: { items: any; path: string }) => {
      if (element.path !== null) {
        res.push(element.path);
      }
      GetPaths(element.items, res);
    });
  }, []);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef<TreeViewRef<any>>(null);
  const wrapperRef = useRef();
  const getWrapperRef = useCallback(
    (element: any) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e: any) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  useEffect(() => {
    const lstPaths: string[] = [];
    // GetPaths(navigation, lstPaths);
    GetPaths(getNavigation(), lstPaths);
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath !== undefined) {
      if (lstPaths.findIndex((x) => currentPath === x) === -1) {
        treeView().unselectAll();
      } else {
        treeView().selectItem(currentPath);
        treeView().expandItem(currentPath);
      }
    }

    if (compactMode) {
      treeView().collapseAll();
    }
  }, [currentPath, compactMode, GetPaths]);

  return (
    <div
      className={"dx-swatch-additional side-navigation-menu"}
      ref={getWrapperRef}
    >
      <div className={"menu-container"}>
        <TreeView
          id="treeview"
          ref={treeViewRef}
          items={items}
          keyExpr={"path"}
          selectionMode={"single"}
          focusStateEnabled={false}
          expandEvent={"click"}
          onItemClick={selectedItemChanged}
          //onContentReady={onMenuReady}
          width={"100%"}
          expandedExpr="false"
          onItemContextMenu={(e) => setSidebarClickedData(e.itemData)}
        />
      </div>
      {sidebarClickedData && sidebarClickedData.items?.length === 0 && (
        <ContextMenu
          dataSource={ContextMenuItems}
          width={100}
          target="#treeview .dx-treeview-item"
          onItemClick={(e) => SidebarContextMenu(sidebarClickedData)}
        />
      )}
    </div>
  );
}
