import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  MasterDetail,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/data-grid";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GeneralSetting } from "../../../../../../../contexts/clientSetting";
import {
  StudyBaseDTO,
  StudyTmcWebDetailsDTO,
} from "../../../../../../../types/trafficStudy/dtos/studyDto";
import { OnExporting } from "../../../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../../../utils/arrayTools";
import DetailTemplate from "./detailTemplate";

// props
interface IPros {
  isLocked: boolean;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>;
  initDataBase: StudyBaseDTO;
  initDataTmc: StudyTmcWebDetailsDTO;
  generalSettings: GeneralSetting | null;
  dataGridRef: React.RefObject<DataGridRef<any, any>>;
}
const Details = (props: IPros) => {
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchMyAPI() {}
    fetchMyAPI();
  }, [props.initDataBase]);

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <DataGrid
          id="gridContainer DetailsGrid"
          ref={props.dataGridRef}
          dataSource={SortObjectByPropName(
            props.initDataTmc.result,
            "periodType"
          )}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
          //onContentReady={e => {e.component.deselectAll()}}
          //onFilterValueChange={e => {props.dataGridRef?.current?.instance().deselectAll()}}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />

          <FilterRow visible={true} applyFilter="auto" />

          <Selection
            mode="multiple"
            selectAllMode={"allPages"}
            showCheckBoxesMode={"always"}
          />
          <HeaderFilter visible={true} />

          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
          <ColumnChooser
            width={350}
            height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
          <Summary>
            <GroupItem summaryType="count" alignByColumn name="Total Count" />
          </Summary>
          <Column dataField="id" caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>

          <Column dataField="periodName" caption={t("period")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>

          <Column
            dataField="phf"
            caption={t("phf")}
            visible={true}
            format="0.00"
          >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <MasterDetail enabled={true} component={DetailTemplate} />
        </DataGrid>
      </div>
    </React.Fragment>
  );
};
export default Details;
