import React, { forwardRef } from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import { OnExporting } from "../../utils/dataGridTools";
import { GridType } from "../../types/general/enums/generalEnums";
import CustomStore from "devextreme/data/custom_store";
import { RowClickEvent } from "devextreme/ui/data_grid";
import {
  TContextMenuAction,
  TDataTableColumn,
  TGridRowContextMenu,
} from "./types";
import { useTranslation } from "react-i18next";
import style from "./tesGrid.module.scss";

type TProps = {
  id?: string;
  gridKey: string;
  dataSource: CustomStore | Array<any>;
  onRowClick?: (e: RowClickEvent) => void;
  storageKey: GridType;
  hasSearchPanel: boolean;
  columns: TDataTableColumn[];
  rowContextMenu?: TGridRowContextMenu;
};
export const TESGrid = forwardRef<DataGridRef, TProps>((props, ref) => {
  const {
    id = "gridContainer",
    gridKey,
    dataSource,
    onRowClick = () => {},
    storageKey,
    hasSearchPanel,
    columns,
    rowContextMenu,
  } = props;

  const { t } = useTranslation();
  return (
    <>
      <DataGrid
        //TODO refine Styling To Prevent using dx-style with # selector
        // className={`${style.TESGridContainer}`}
        id={id}
        key={gridKey}
        ref={ref}
        dataSource={dataSource}
        rowAlternationEnabled={true}
        showBorders={true}
        onRowClick={onRowClick}
        hoverStateEnabled={true}
        remoteOperations={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        onContextMenuPreparing={(e) => {
          if (e.row && e.row.rowType === "data" && rowContextMenu) {
            e.items = rowContextMenu?.items.map((item) => ({
              ...item,
              onItemClick: () =>
                rowContextMenu?.onItemClick(
                  item.text as TContextMenuAction,
                  e.row?.data
                ),
            }));
          }
        }}
        onExporting={OnExporting}
        style={{ margin: "0 1rem" }}
        onOptionChanged={(e) => {
          if (e.fullName.split(".")[1] === "visible") e.component.refresh();
        }}
      >
        <Export enabled={true} allowExportSelectedData={true} />
        <Grouping contextMenuEnabled={true} autoExpandAll={false} />
        <GroupPanel visible={true} /> {/* or "auto" */}
        <FilterPanel visible={true} />
        <FilterBuilderPopup position={"top"} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={storageKey.toString()}
          savingTimeout={500}
        />
        <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
        <Summary>
          <GroupItem summaryType="count" alignByColumn name="Total Count" />
        </Summary>
        <Paging enabled={true} defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 200, 300, 400, 500]}
          showNavigationButtons={true}
          showInfo={true}
          visible={true}
        />
        <Selection
          mode="multiple"
          selectAllMode={"allPages"}
          showCheckBoxesMode={"always"}
          deferred={true}
          //@ts-ignore
          maxFilterLengthInRequest={10000}
        />
        <FilterRow visible={true} applyFilter="auto" />
        <HeaderFilter visible={true} />
        {hasSearchPanel && (
          <SearchPanel
            visible={true}
            width={285}
            placeholder={t("search...")}
          />
        )}
        <ColumnChooser
          width={350}
          height={400}
          enabled={true}
          mode="select"
          sortOrder="asc"
        >
          <Search enabled />
        </ColumnChooser>
        {columns?.length > 0 &&
          columns.map((col, index) => {
            return (
              <Column
                key={`${col.key}_${index}`}
                dataField={col.dataField}
                caption={col.caption}
                visible={col.visible}
                dataType={col.dataType === "time" ? "datetime" : col.dataType}
                format={col.format}
                calculateCellValue={col.calculateCellValue}
                sortOrder={col.sortOrder}
                width={col.width}
                alignment={col.alignment}
                allowGrouping={col.allowGrouping}
                allowSorting={col.allowSorting}
                allowFiltering={col.allowFiltering}
                allowHeaderFiltering={col.allowHeaderFiltering}
                headerFilter={col.headerFilter}
                lookup={col.lookup}
              />
            );
          })}
      </DataGrid>
    </>
  );
});
