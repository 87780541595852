import { Form, NumberBox, Validator } from "devextreme-react";
import { RequiredRule, SimpleItem } from "devextreme-react/cjs/form";
import ValidationGroup, {
  ValidationGroupRef,
} from "devextreme-react/cjs/validation-group";
import { t } from "i18next";
import React from "react";
import { AADTPattern } from "../../../../../../types/trafficStudy/trafficStudyTypes";

interface IPros {
  initData: AADTPattern;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const SeasonalAdjFactor = (props: IPros) => {
  const onValueChange = (name: string, value: any) => {
    props.setInitData({
      ...props.initData,
      seasonalFactor: {
        ...props.initData.seasonalFactor,
        [name]: value,
      },
    });
    props.setDataChanged(true);
  };

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={4}>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("sadt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.sadt ?? 0}
                onValueChange={(e) => onValueChange("sadt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("wadt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.wadt ?? 0}
                onValueChange={(e) => onValueChange("wadt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorSadt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorSadt ?? 0}
                onValueChange={(e) => onValueChange("factorSadt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorSawdt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorSawdt ?? 0}
                onValueChange={(e) => onValueChange("factorSawdt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorJulAdt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorJulAdt ?? 0}
                onValueChange={(e) => onValueChange("factorJulAdt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorJulAwdt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorJulAwdt ?? 0}
                onValueChange={(e) => onValueChange("factorJulAwdt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorAugAdt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorAugAdt ?? 0}
                onValueChange={(e) => onValueChange("factorAugAdt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorAugAwdt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorAugAwdt ?? 0}
                onValueChange={(e) => onValueChange("factorAugAwdt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                label={t("factorWadt")}
                labelMode="floating"
                value={props.initData.seasonalFactor?.factorWadt ?? 0}
                onValueChange={(e) => onValueChange("factorWadt", e)}
                disabled={props.isLocked}
                format={{ type: "fixedPoint", precision: 2 }}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </NumberBox>
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};

export default SeasonalAdjFactor;
