import {
  NumberBox,
  SelectBox,
  TextArea,
  TextBox,
  ValidationGroup,
} from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DateBox from "devextreme-react/date-box";
import { Form, SimpleItem } from "devextreme-react/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import SignCodePicker from "../../../../../components/signCodePicker/signCodePicker";
import SupportValuePicker from "../../../../../components/supportValuePicker/supportValuePicker";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { VMAppSetups } from "../../../../../types/sign/dto/signDto";
import { TesSign } from "../../../../../types/sign/signTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import design from "./details.module.scss";

// props
interface IPros {
  initDataSetups: VMAppSetups;
  initDataSign: TesSign;
  signId: string;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  onValueChange: (name: string, value: any) => void;
  onChangeLocationDescription: (
    locationId: string,
    locationDescription: string,
    geoId: string,
    locationType: LocationType,
    latitude: number,
    longitude: number
  ) => void;
  setInitDataSign: React.Dispatch<React.SetStateAction<TesSign>>;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  signCodeDesc: string;
  setSignCodeDesc: React.Dispatch<React.SetStateAction<string>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();
  const location = useLocation();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  function replaceStr(str: string) {
    str = str.replaceAll("/", "");
    str = str.replaceAll(":", "");
    str = str.replaceAll("*", "");
    str = str.replaceAll("?", "");
    str = str.replaceAll(">", "");
    str = str.replaceAll("<", "");
    str = str.replaceAll("|", "");
    str = str.replaceAll("\\", "");
    str = str.replaceAll(" ", "-");
    return str;
  }

  function findSignPic(signCodeId: string) {
    if (props.initDataSetups.signCode !== undefined) {
      var urlPath: string;
      const sc = props.initDataSetups.signCode.find((x) => x.id === signCodeId);
      if (sc && sc.code) {
        urlPath =
          props.initDataSetups.generalSetting.signImagesURL +
          replaceStr(sc.code) +
          ".png";
        if (!urlPath.includes("http")) {
          return undefined;
        }
        return urlPath;
      }
    }
    return undefined;
  }

  return (
    <div
      className={`signDetails-details ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <div className={design.signCol}>
            {!location.pathname.includes("AddNew") && (
              <div className={design.signContainer}>
                {findSignPic(props.initDataSign.signCodeId) !== undefined && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img
                    src={findSignPic(props.initDataSign.signCodeId)}
                    className={design.sign}
                  />
                )}
              </div>
            )}
            <div className={design.fieldsCol}>
              <Form colCount={3}>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("locationType")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={
                      props.initDataSign.signLocationTypeId === ""
                        ? props.initDataSetups.signLocationType.filter(
                            (a) => a.id === "Support"
                          )[0].id
                        : props.initDataSign.signLocationTypeId
                    }
                    onValueChange={(e) =>
                      props.onValueChange("signLocationTypeId", e)
                    }
                    className={design.fieldsRow1}
                    items={SortObjectByPropName(
                      props.initDataSetups.signLocationType,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextBox
                    style={{ marginBottom: 20 }}
                    label={t("signId")}
                    labelMode="floating"
                    value={props.initDataSign.signId}
                    onValueChange={(e) => props.onValueChange("signId", e)}
                    id="signId"
                    disabled={props.isLocked}
                    className={design.fieldsRow1}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <div className={design.fieldsRow1}>
                    <SupportValuePicker
                      label={t("support")}
                      initDataSign={props.initDataSign}
                      setInitDataSign={props.setInitDataSign}
                      onChangeLocationDescription={
                        props.onChangeLocationDescription
                      }
                      isLocked={props.isLocked}
                    />
                  </div>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <div className={design.fieldsOtherRow}>
                    <SignCodePicker
                      label={t("signCodes")}
                      onChangeFields={props.onValueChange}
                      initDataSign={props.initDataSign}
                      signCodes={props.initDataSetups.signCode}
                      signCodeDesc={props.signCodeDesc}
                      setSignCodeDesc={props.setSignCodeDesc}
                      isLocked={props.isLocked}
                    />
                  </div>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextBox
                    style={{ marginBottom: 20 }}
                    label={t("locationDescription")}
                    labelMode="floating"
                    value={props.initDataSign.support?.locationDescription}
                    disabled={props.isLocked}
                    onValueChange={(e) =>
                      props.onValueChange("signLocationDescription", e)
                    }
                    className={design.fieldsOtherRow}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("dimension")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={props.initDataSign.signDimensionId}
                    onValueChange={(e) =>
                      props.onValueChange("signDimensionId", e)
                    }
                    className={design.fieldsOtherRow}
                    items={SortObjectByPropName(
                      props.initDataSetups.signDimension,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    style={{ marginBottom: 20 }}
                    label={t("height")}
                    labelMode="floating"
                    value={props.initDataSign.height}
                    onValueChange={(e) => props.onValueChange("height", e)}
                    disabled={props.isLocked}
                    className={design.fieldsOtherRow}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("facingDirection")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={props.initDataSign.signFacingDirectionId}
                    onValueChange={(e) =>
                      props.onValueChange("signFacingDirectionId", e)
                    }
                    className={design.fieldsOtherRow}
                    items={SortObjectByPropName(
                      props.initDataSetups.signFacingDirection,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("facingMaterial")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={props.initDataSign.signFaceMaterialId}
                    onValueChange={(e) =>
                      props.onValueChange("signFaceMaterialId", e)
                    }
                    className={design.fieldsOtherRow}
                    items={SortObjectByPropName(
                      props.initDataSetups.signFaceMaterial,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("reflectiveCoatingId")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={props.initDataSign.signReflectiveCoatingId}
                    onValueChange={(e) =>
                      props.onValueChange("signReflectiveCoatingId", e)
                    }
                    className={design.fieldsOtherRow}
                    items={SortObjectByPropName(
                      props.initDataSetups.signReflectiveCoating,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("reflectiveRatingId")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={props.initDataSign.signReflectiveRatingId}
                    onValueChange={(e) =>
                      props.onValueChange("signReflectiveRatingId", e)
                    }
                    className={design.fieldsOtherRow}
                    items={SortObjectByPropName(
                      props.initDataSetups.signReflectiveRating,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>

                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("condition")}
                    valueExpr="id"
                    displayExpr="name"
                    labelMode="floating"
                    value={props.initDataSign.signConditionId}
                    onValueChange={(e) =>
                      props.onValueChange("signConditionId", e)
                    }
                    className={design.fieldsOtherRow}
                    items={SortObjectByPropName(
                      props.initDataSetups.signCondition,
                      "name"
                    )}
                    disabled={props.isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  {/* format needs to be implemented / check with soso*/}
                  <DateBox
                    label={t("dateInstalled")}
                    defaultValue={props.initDataSign.dateInstalled}
                    type="datetime"
                    onValueChange={(e) =>
                      props.onValueChange("dateInstalled", e)
                    }
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateTimeFormat}
                    displayFormat={props.generalSettings?.dateTimeFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    disabled={props.isLocked}
                    showClearButton={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t("dateExpired")}
                    defaultValue={props.initDataSign.dateInstalled}
                    type="datetime"
                    onValueChange={(e) => props.onValueChange("dateExpired", e)}
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateTimeFormat}
                    displayFormat={props.generalSettings?.dateTimeFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    disabled={props.isLocked}
                    showClearButton={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    style={{ marginBottom: 20 }}
                    label={t("materialCost")}
                    labelMode="floating"
                    value={props.initDataSign.materialCost}
                    onValueChange={(e) =>
                      props.onValueChange("materialCost", e)
                    }
                    disabled={props.isLocked}
                  />
                </SimpleItem>

                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    style={{ marginBottom: 20 }}
                    label={t("labourCost")}
                    labelMode="floating"
                    value={props.initDataSign.labourCost}
                    onValueChange={(e) => props.onValueChange("labourCost", e)}
                    disabled={props.isLocked}
                    className={design.fieldsOtherRow}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    style={{ marginBottom: 20 }}
                    label={t("totalCost")}
                    labelMode="floating"
                    value={
                      (props.initDataSign.installationCost
                        ? props.initDataSign.installationCost
                        : 0) +
                      (props.initDataSign.labourCost
                        ? props.initDataSign.labourCost
                        : 0) +
                      (props.initDataSign.materialCost
                        ? props.initDataSign.materialCost
                        : 0)
                    }
                    disabled={props.isLocked}
                    className={design.fieldsOtherRow}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextArea
                    label={t("note")}
                    labelMode="floating"
                    height={50}
                    value={props.initDataSign.note}
                    onValueChange={(e) => props.onValueChange("note", e)}
                    disabled={props.isLocked}
                    className={design.fieldsOtherRow}
                  />
                </SimpleItem>
              </Form>
            </div>
          </div>
        </ValidationGroup>
      </React.Fragment>
    </div>
  );
};

export default Details;
