//imports
import { Button, ContextMenu, SelectBox } from "devextreme-react";
import {
  ArgumentAxis,
  Chart,
  Export as ChartExport,
  CommonSeriesSettings,
  Legend,
  Series,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import CheckBox from "devextreme-react/check-box";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  FilterRow,
  Export as GridExport,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import DateBox from "devextreme-react/date-box";
import TagBox from "devextreme-react/tag-box";
import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import GeneralGisMapManager from "../../../../../components/gisMap/generalGisMapManager";
import { useAuth } from "../../../../../contexts/auth";
import { CollisionApiUrl } from "../../../../../environment/routeSettings";
import {
  IDashboardResult,
  MainDashboardRequest,
} from "../../../../../types/collision/collisionTypes";
import { GetCollisionDatePeriodRequestDTO } from "../../../../../types/collision/dto/collisionDtos";
import {
  DashboardReportType,
  QueryAvgType,
} from "../../../../../types/collision/enums/collisionEnums";
import { TesMapModule } from "../../../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  NameValueNumber,
} from "../../../../../types/general/generalTypes";
import { TesCodeValue } from "../../../../../types/infrastructure/infrastructureTypes";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { CreateDateAsUTC } from "../../../../../utils/dateTimeTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import { useScreenSize } from "../../../../../utils/media-query";
import { TesPost } from "../../../../../utils/rest";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";
import { IsNullOrEmpty } from "../../../../../utils/stringTools";
import design from "./overview.module.scss";
import LoadingScreen, {
  CheckLoadingStates,
} from "../../../../../components/loadingScreen/loadingScreen";
const FiveYearIcon = "/images/5YIcon.svg";
const OneYearIcon = "/images/1YIcon.svg";

//props
interface IPros {
  initDataSiteList: INameId[] | undefined;
  initDataTesCodeValues: TesCodeValue;
  generalSettings: GeneralSetting | null;
  datePeriod: GetCollisionDatePeriodRequestDTO | undefined;
}

const Overview = (props: IPros) => {
  //states

  const [dashboardReq, setDashboardReq] = useState<MainDashboardRequest>({
    customerId: localStorage.getItem("selectedCustomerId")!,
    divisionId: localStorage.getItem("selectedDivisionId")!,
    reportType: DashboardReportType.Overview,
    severityList: [1, 2, 3],
    emphasisAreaIds: [],
    peopleInvolvements: [1, 2, 3, 4],
    beginDateFrom: new Date(
      new Date(props.datePeriod?.endDT!).getFullYear() - 4,
      0,
      1
    ),
    beginDateTo: new Date(
      new Date(props.datePeriod?.endDT!).getFullYear(),
      11,
      31
    ),
    jurisdictionIds: [],
    municipalityIds: [],
    areaIds: [],
    topNLocation: 10,
    queryAvgType: QueryAvgType.AvgYearly,
  });
  const [lstQueryAvgType, setLstQueryAvgType] = useState<NameValue[]>([]);
  const [initData, setInitData] = useState<IDashboardResult | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [filterLoading, setFilterLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useNavigate();
  const { isXLarge } = useScreenSize();
  const dataGridRoadSegmentRef = useRef<DataGridRef<any, any>>(null);
  const dataGridIntersectionRef = useRef<DataGridRef<any, any>>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    // { text: t('openInNewTab'), icon:  "fa-solid fa-up-right-from-square"},
    { text: t("openLocation"), icon: "fa-solid fa-location-dot" },
  ];
  const lstTopList: NameValueNumber[] = [
    { name: "10", value: 10 },
    { name: "20", value: 20 },
    { name: "30", value: 30 },
    { name: "40", value: 40 },
    { name: "50", value: 50 },
    { name: "100", value: 100 },
    { name: "500", value: 500 },
  ];

  //#region functions
  const applyFilter = useCallback(async () => {
    try {
      setFilterLoading(true);
      var postOb: MainDashboardRequest = {
        ...dashboardReq,
        beginDateFrom: CreateDateAsUTC(dashboardReq.beginDateFrom!),
        beginDateTo: CreateDateAsUTC(dashboardReq.beginDateTo!),
      };
      setInitData(
        await TesPost(
          CollisionApiUrl() + "/api/Reports/GetMainDashboard",
          postOb,
          true
        )
      );
      setFilterLoading(false);
    } catch (ex) {
      setFilterLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardReq]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setLoading(true);
        setLstQueryAvgType(Enum2Array(QueryAvgType));
        await applyFilter();
        setLoading(false);
      } catch (ex) {
        setLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilter]);

  const onValueChange = (name: string, value: any) => {
    if (name === "peopleInvolvements") {
      var peopleInvolvementsIndex =
        dashboardReq.peopleInvolvements?.indexOf(value);
      if (peopleInvolvementsIndex !== -1) {
        var lstpeopleInvolvements = dashboardReq.peopleInvolvements?.filter(
          (x) => x !== value
        );
        if (lstpeopleInvolvements?.length === 0) {
          notify(t("oneItemShouldBeSelected"), "warning", 5000);
          return;
        }
        setDashboardReq({
          ...dashboardReq,
          peopleInvolvements: lstpeopleInvolvements,
        });
      } else {
        setDashboardReq({
          ...dashboardReq,
          peopleInvolvements: [...dashboardReq.peopleInvolvements!, value],
        });
      }
    } else if (name === "severity") {
      var index = dashboardReq.severityList.indexOf(value);
      if (index !== -1) {
        var lstNewSeverity = dashboardReq.severityList.filter(
          (x) => x !== value
        );
        if (lstNewSeverity.length === 0) {
          notify(t("oneItemShouldBeSelected"), "warning", 5000);
          return;
        }
        setDashboardReq({ ...dashboardReq, severityList: lstNewSeverity });
      } else {
        setDashboardReq({
          ...dashboardReq,
          severityList: [...dashboardReq.severityList, value],
        });
      }
    } else if (name === "emphasisArea") {
      var index = dashboardReq.emphasisAreaIds.indexOf(value);
      if (index !== -1) {
        var lstNewEmphasisAreaIds = dashboardReq.emphasisAreaIds.filter(
          (x) => x !== value
        );
        if (lstNewEmphasisAreaIds.length === 0) {
          notify(t("oneItemShouldBeSelected"), "warning", 5000);
          return;
        }
        setDashboardReq({
          ...dashboardReq,
          emphasisAreaIds: lstNewEmphasisAreaIds,
        });
      } else {
        setDashboardReq({
          ...dashboardReq,
          emphasisAreaIds: [...dashboardReq.emphasisAreaIds, value],
        });
      }
    } else if (name === "beginDateFrom") {
      if (dashboardReq.beginDateTo! < value) {
        notify("Select valid date range.", "error", 5000);
        return;
      }

      setDashboardReq({ ...dashboardReq, beginDateFrom: value });
    } else if (name === "beginDateTo") {
      if (dashboardReq.beginDateFrom! > value) {
        notify("Select valid date range.", "error", 5000);
        return;
      }
      setDashboardReq({
        ...dashboardReq,
        beginDateTo: value,
      });
    } else if (name === "siteList") {
      setDashboardReq({
        ...dashboardReq,
        areaIds: value,
      });
    } else if (name === "municipality") {
      setDashboardReq({
        ...dashboardReq,
        municipalityIds: value,
      });
    } else if (name === "jurisdiction") {
      setDashboardReq({
        ...dashboardReq,
        jurisdictionIds: value,
      });
    } else if (name === "topNLocation") {
      console.log(value);
      setDashboardReq({
        ...dashboardReq,
        topNLocation: value,
      });
    } else if (name === "queryAvgType") {
      setDashboardReq({
        ...dashboardReq,
        queryAvgType: value,
      });
    }
  };

  function onChangeTypeAll() {
    if (dashboardReq.peopleInvolvements?.includes(1)) {
      setDashboardReq({
        ...dashboardReq,
        peopleInvolvements: dashboardReq.peopleInvolvements?.filter(
          (x) => x !== 1
        ),
      });
    } else {
      setDashboardReq({ ...dashboardReq, peopleInvolvements: [1, 2, 3, 4] });
    }
  }

  function onIntersectionRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(
        `/infrastructure/intersectionDetails/${e.data.locationId}`,
        "_blank"
      );
    } else {
      history(`/infrastructure/intersectionDetails/${e.data.locationId}`);
    }
  }

  function onRoadSegmentRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(
        `/infrastructure/roadSegmentDetails/${e.data.locationId}`,
        "_blank"
      );
    } else {
      history(`/infrastructure/roadSegmentDetails/${e.data.locationId}`);
    }
  }

  function getLastNYear(decYear: number) {
    var currentYear = new Date().getFullYear();
    setDashboardReq({
      ...dashboardReq,
      beginDateFrom: new Date(currentYear - decYear, 0, 1),
      beginDateTo: new Date(currentYear - 1, 11, 31),
    });
  }

  //#endregion

  return (
    <div>
      {CheckLoadingStates([loading, filterLoading]) && <LoadingScreen />}
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={(e) =>
            RightClickMenu(
              e,
              selectedRowData.row.key.id,
              "",
              selectedRowData.row.data.locationType,
              selectedRowData.row.data.locationId
            )
          }
        />
        <div className={design.row}>
          <div className={design.column1}>
            <CollapsibleCard compact="dashboard" title={t("severity")}>
              <div className={design.collapsibleContentHorizontal}>
                <CheckBox
                  text={t("fatal")}
                  value={dashboardReq.severityList.includes(3)}
                  onValueChanged={() => onValueChange("severity", 3)}
                ></CheckBox>
                <CheckBox
                  text={t("injury")}
                  value={dashboardReq.severityList.includes(2)}
                  onValueChanged={() => onValueChange("severity", 2)}
                ></CheckBox>
                <CheckBox
                  text={t("PDO")}
                  value={dashboardReq.severityList.includes(1)}
                  onValueChanged={() => onValueChange("severity", 1)}
                ></CheckBox>
              </div>
            </CollapsibleCard>
            <CollapsibleCard compact="dashboard" title={t("type")}>
              <div className={design.collapsibleContentHorizontal}>
                <CheckBox
                  text={t("all")}
                  value={dashboardReq.peopleInvolvements?.includes(1)}
                  onValueChanged={() => onChangeTypeAll()}
                ></CheckBox>
                <CheckBox
                  text={t("pedestrians")}
                  disabled={
                    dashboardReq.peopleInvolvements?.includes(1) ? true : false
                  }
                  value={dashboardReq.peopleInvolvements?.includes(2)}
                  onValueChanged={() => onValueChange("peopleInvolvements", 2)}
                ></CheckBox>
                <CheckBox
                  text={t("cyclists")}
                  disabled={
                    dashboardReq.peopleInvolvements?.includes(1) ? true : false
                  }
                  value={dashboardReq.peopleInvolvements?.includes(3)}
                  onValueChanged={() => onValueChange("peopleInvolvements", 3)}
                ></CheckBox>
              </div>
              <div className={design.collapsibleContentHorizontal}>
                <CheckBox
                  text={t("motorcyclists")}
                  disabled={
                    dashboardReq.peopleInvolvements?.includes(1) ? true : false
                  }
                  value={dashboardReq.peopleInvolvements?.includes(4)}
                  onValueChanged={() => onValueChange("peopleInvolvements", 4)}
                ></CheckBox>
              </div>
            </CollapsibleCard>
            <CollapsibleCard compact="dashboard" title={t("filter")}>
              <div className={`${design.collapsibleContentVertical}`}>
                <div style={{ display: "flex" }}>
                  <span style={{ width: "20%", display: "inline-block" }}>
                    {t("from")}:
                  </span>
                  <DateBox
                    // label={t("from")}
                    value={dashboardReq.beginDateFrom}
                    type="date"
                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                    onValueChange={(e) => onValueChange("beginDateFrom", e)}
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateFormat}
                    displayFormat={props.generalSettings?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                    style={{
                      height: "2rem",
                      marginBottom: "0.5rem",
                      marginTop: "-0.5rem",
                      width: "80%",
                    }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <span style={{ width: "20%", display: "inline-block" }}>
                    {t("to")}:
                  </span>
                  <DateBox
                    // label={t("to")}
                    value={dashboardReq.beginDateTo}
                    type="date"
                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                    onValueChange={(e) => onValueChange("beginDateTo", e)}
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateFormat}
                    displayFormat={props.generalSettings?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                    style={{
                      height: "2rem",
                      marginBottom: "0.5rem",
                      width: "80%",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isXLarge ? "row" : "column",
                    justifyContent: isXLarge ? "space-around" : "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    icon={OneYearIcon}
                    text={t("lastYear")}
                    className="dateOptions"
                    style={{
                      marginBottom: "0.25rem",
                      width: isXLarge ? "auto" : "100%",
                    }}
                    onClick={() => {
                      getLastNYear(1);
                    }}
                  />
                  <Button
                    icon={FiveYearIcon}
                    text={t("lastFiveYears")}
                    className="dateOptions"
                    style={{
                      marginBottom: "0.25rem",
                      width: isXLarge ? "auto" : "100%",
                    }}
                    onClick={() => {
                      getLastNYear(5);
                    }}
                  />
                </div>
              </div>
              <div className={`${design.collapsibleContentVertical}`}>
                <SelectBox
                  label={t("topLocation")}
                  items={lstTopList}
                  displayExpr={"name"}
                  valueExpr={"value"}
                  value={dashboardReq?.topNLocation}
                  onValueChanged={(a) => onValueChange("topNLocation", a.value)}
                  searchEnabled={true}
                  style={{ marginBottom: "5px" }}
                />
                <TagBox
                  label={t("siteList")}
                  dataSource={props.initDataSiteList}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={(a) => onValueChange("siteList", a.value)}
                  searchEnabled={true}
                  style={{ marginBottom: "5px" }}
                />
                {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) &&
                  props.initDataTesCodeValues.jurisdictions?.length !== 0 &&
                  props.initDataTesCodeValues.jurisdictions !== undefined && (
                    <TagBox
                      label={t("jurisdiction")}
                      dataSource={SortObjectByPropName(
                        props.initDataTesCodeValues.jurisdictions,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                      onValueChanged={(a) =>
                        onValueChange("jurisdiction", a.value)
                      }
                      searchEnabled={true}
                      style={{ marginBottom: "5px" }}
                    />
                  )}
                {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) &&
                  props.initDataTesCodeValues.municipalities?.length !== 0 &&
                  props.initDataTesCodeValues.municipalities !== undefined && (
                    <TagBox
                      label={t("municipalities")}
                      dataSource={SortObjectByPropName(
                        props.initDataTesCodeValues.municipalities,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                      onValueChanged={(a) =>
                        onValueChange("municipality", a.value)
                      }
                      searchEnabled={true}
                      style={{ marginBottom: "5px" }}
                    />
                  )}
              </div>
            </CollapsibleCard>
          </div>

          <div className={design.column2}>
            {initData && initData.yearSummary && (
              <CollapsibleCard title={t("collisionFrequency")}>
                <div>
                  <Chart id="chart" dataSource={initData.yearSummary}>
                    <CommonSeriesSettings
                      argumentField="description"
                      type="stackedbar"
                      barWidth={60}
                    />
                    {dashboardReq.severityList.includes(2) && (
                      <Series
                        valueField="injury"
                        name={t("injury")}
                        axis="pdoInjury"
                        color="#008ac5"
                      />
                    )}
                    {dashboardReq.severityList.includes(1) && (
                      <Series
                        valueField="pdo"
                        name={t("PDO")}
                        axis="pdoInjury"
                        color="#00a9b5"
                      />
                    )}
                    {dashboardReq.severityList.includes(3) && (
                      <Series
                        valueField="fatal"
                        name={t("fatal")}
                        type="line"
                        axis="fatalAxix"
                        color="#0b1d78"
                      />
                    )}
                    {/* x axis */}
                    <ArgumentAxis title={t("year")} />

                    {dashboardReq.severityList.includes(3) && (
                      // y axis
                      <ValueAxis
                        name="fatalAxix"
                        position="right"
                        //valueType="fatal"
                        showZero={true}
                        valueMarginsEnabled={false}
                        allowDecimals={false}
                      >
                        <Title text={t("numberOfFatalCollisions")} />
                      </ValueAxis>
                    )}
                    {(dashboardReq.severityList.includes(1) ||
                      dashboardReq.severityList.includes(2)) && (
                      <ValueAxis
                        name="pdoInjury"
                        position="left"
                        allowDecimals={false}
                        type={"continuous"}
                      >
                        <Title
                          text={
                            dashboardReq.severityList.includes(1) &&
                            !dashboardReq.severityList.includes(2)
                              ? t("numberOfPDOCollisions")
                              : dashboardReq.severityList.includes(1) &&
                                dashboardReq.severityList.includes(2)
                              ? t("numberOfPDOInjuryCollisions")
                              : t("numberOfInjuryCollisions")
                          }
                        />
                      </ValueAxis>
                    )}
                    <ChartExport enabled={true} printingEnabled={false} />
                    <Tooltip
                      enabled={true}
                      location="edge"
                      //  customizeTooltip={this.customizeTooltip}
                    />

                    <Legend
                      position="inside"
                      horizontalAlignment="right"
                    ></Legend>
                  </Chart>
                </div>
              </CollapsibleCard>
            )}

            {initData && initData.intersectionSummery && (
              <CollapsibleCard
                title={`${t("top")} ${dashboardReq.topNLocation} ${t(
                  "highFrequency"
                )} ${t("intersections")}`}
              >
                <div style={{ width: "100%", height: "auto" }}>
                  <GeneralGisMapManager
                    tesModule={TesMapModule.CollisionOverviewDashboard}
                    showGis={true}
                    lstIntersectionGeoIdIn={initData.intersectionSummery.map(
                      (x) => x.geoId
                    )}
                    setLstRoadSegmentGeoIdIn={initData.roadSegmentSummery.map(
                      (x) => x.geoId
                    )}
                    locId="LocationId"
                  />
                </div>
              </CollapsibleCard>
            )}

            {initData && initData.yearSummary && (
              <CollapsibleCard
                title={`${t("top")} ${dashboardReq.topNLocation} ${t(
                  "highFrequency"
                )} ${t("roadSegments")}`}
              >
                <div>
                  <DataGrid
                    id="gridContainer"
                    ref={dataGridRoadSegmentRef}
                    dataSource={initData.roadSegmentSummery}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onRowClick={onRoadSegmentRowClicked}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onContextMenuPreparing={(e) => {
                      setSelectedRowData(e);
                    }}
                    // onExporting={OnExporting}
                  >
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[100, 200, 300, 400, 500]}
                      showNavigationButtons={true}
                      showInfo={true}
                      visible={true}
                    />
                    <GridExport enabled={true} allowExportSelectedData={true} />
                    <ColumnChooser
                      width={350}
                      height={400}
                      enabled={true}
                      mode="select"
                      sortOrder="asc"
                    >
                      <Search enabled />
                    </ColumnChooser>

                    <FilterRow visible={true} applyFilter="auto" />

                    <HeaderFilter visible={true} />

                    <SearchPanel
                      visible={true}
                      width={285}
                      placeholder={t("search...")}
                    />
                    <Column
                      alignment="left"
                      dataField="id"
                      allowSorting={true}
                      caption={t("id")}
                      visible={false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>

                    <Column
                      dataField="rank"
                      alignment="left"
                      caption={t("rank")}
                      allowFiltering={false}
                      width={70}
                    ></Column>
                    <Column
                      dataField="total"
                      caption={t("collisionFrequency")}
                      dataType="number"
                      alignment="left"
                      width={170}
                    ></Column>
                    <Column
                      dataField="locationDescription"
                      caption={t("locationDescription")}
                    ></Column>
                    <Column
                      dataField="fatal"
                      caption={t("fatal")}
                      visible={false}
                    ></Column>
                    <Column
                      dataField="injury"
                      caption={t("injury")}
                      visible={false}
                    ></Column>
                    <Column
                      dataField="pdo"
                      caption={t("pdo")}
                      visible={false}
                    ></Column>
                    <Column
                      dataField="fatalInjury"
                      caption={t("fatalInjury")}
                      visible={false}
                    ></Column>
                  </DataGrid>
                </div>
              </CollapsibleCard>
            )}

            {initData && initData.yearSummary && (
              <CollapsibleCard
                title={`${t("top")} ${dashboardReq.topNLocation} ${t(
                  "highFrequency"
                )} ${t("intersections")}`}
              >
                <div>
                  <DataGrid
                    id="gridContainer"
                    ref={dataGridIntersectionRef}
                    dataSource={initData.intersectionSummery}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onRowClick={onIntersectionRowClicked}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onExporting={OnExporting}
                    onContextMenuPreparing={(e) => {
                      setSelectedRowData(e);
                    }}
                  >
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[100, 200, 300, 400, 500]}
                      showNavigationButtons={true}
                      showInfo={true}
                      visible={true}
                    />
                    <GridExport enabled={true} allowExportSelectedData={true} />
                    <ColumnChooser
                      width={350}
                      height={400}
                      enabled={true}
                      mode="select"
                      sortOrder="asc"
                    >
                      <Search enabled />
                    </ColumnChooser>

                    <FilterRow visible={true} applyFilter="auto" />

                    <HeaderFilter visible={true} />

                    <SearchPanel
                      visible={true}
                      width={285}
                      placeholder={t("search...")}
                    />
                    <Column
                      alignment="left"
                      dataField="id"
                      allowSorting={true}
                      caption={t("id")}
                      visible={false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="rank"
                      alignment="left"
                      caption={t("rank")}
                      allowFiltering={false}
                      width={70}
                    ></Column>
                    <Column
                      dataField="total"
                      caption={t("collisionFrequency")}
                      dataType="number"
                      alignment="left"
                      width={170}
                    ></Column>
                    <Column
                      dataField="locationDescription"
                      caption={t("locationDescription")}
                    ></Column>
                    <Column
                      dataField="fatal"
                      caption={t("fatal")}
                      visible={false}
                    ></Column>
                    <Column
                      dataField="injury"
                      caption={t("injury")}
                      visible={false}
                    ></Column>
                    <Column
                      dataField="pdo"
                      caption={t("pdo")}
                      visible={false}
                    ></Column>
                    <Column
                      dataField="fatalInjury"
                      caption={t("fatalInjury")}
                      visible={false}
                    ></Column>
                  </DataGrid>
                </div>
              </CollapsibleCard>
            )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};
export default Overview;
