import React from "react";
import {
  Location,
  RoadSegmentDescription,
} from "../../../../../types/infrastructure/infrastructureTypes";
import TextBox from "devextreme-react/text-box";
import { TesField } from "../../../../../types/field/fieldType";
import { Switch } from "devextreme-react/switch";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import {
  FieldCategoryType,
  FieldPermission,
  FieldType,
} from "../../../../../types/field/enums/fieldEnums";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { t } from "i18next";
import { PageMode } from "../../../../../types/general/enums/generalEnums";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { Form } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import { DatePickerDateCalculator } from "../../../../../utils/dateTimeTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { useAuth } from "../../../../../contexts/auth";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { MisDetailsDTO } from "../../../../../types/infrastructure/dto/locationdDto";

interface IPros {
  description: RoadSegmentDescription;
  initDataFields: TesField[] | undefined;
  location: MisDetailsDTO;
  setLocation: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  pageMode: number;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const General = (props: IPros) => {
  const { customerDivisions } = useClientSetting();
  const { user } = useAuth();

  function onChangeFields(name: string, value: any, isUpperCase: boolean) {
    if (name === "geoId" || name === "inactive" || name === "divisionId") {
      props.setLocation({
        ...props.location,
        [name]: value,
      });
      if (props.pageMode === PageMode.Update) {
        props.setDataChanged(true);
      }
    } else {
      props.setLocation({
        ...props.location,
        general: {
          ...props.location.general,
          [name]: value,
        },
      });
    }
    if (props.pageMode === PageMode.Update) {
      props.setDataChanged(true);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                // style={{ marginBottom: 20 }}
                label={t("geoId")}
                labelMode="floating"
                value={props.location.geoId}
                onValueChange={(e) => onChangeFields("geoId", e, false)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TextBox>
            </SimpleItem>

            <SimpleItem
              colSpan={1}
              visible={user?.permissions?.some(
                (x) => x === TMSPermissions.TMS_Admin
              )}
            >
              <SelectBox
                placeholder=""
                // style={{ marginBottom: 20 }}
                label={t("division")}
                labelMode="floating"
                displayExpr="name"
                valueExpr="id"
                value={props.location.divisionId}
                onValueChange={(e) => onChangeFields("divisionId", e, false)}
                items={SortObjectByPropName(customerDivisions!, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>

            {/* eslint-disable-next-line array-callback-return */}
            {props.initDataFields &&
              props.initDataFields
                .filter(
                  (x) =>
                    x.fieldCategoryType ===
                      FieldCategoryType.InfrastructureMISGeneral &&
                    (props.isLocked ? true : x.readOnlyField === false)
                )
                .map((f, i) => {
                  if (f.fieldType === FieldType.String) {
                    return (
                      <SimpleItem colSpan={1}>
                        <TextBox
                          // style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="floating"
                          value={
                            props.location.general &&
                            props.location.general[f.name]
                          }
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }
                  if (
                    f.fieldType === FieldType.Number ||
                    f.fieldType === FieldType.DecimalNumber ||
                    f.fieldType === FieldType.DoubleNumber
                  ) {
                    return (
                      <SimpleItem colSpan={1}>
                        <TextBox
                          // style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="floating"
                          value={
                            props.location.general &&
                            props.location.general[f.name]
                          }
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Boolean) {
                    return (
                      <SimpleItem colSpan={1}>
                        <Switch
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          value={
                            props.location.general &&
                            props.location.general[f.name]
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Date) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.location.general[f.name]
                                )
                              : props.location.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="date"
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          key={i}
                          pickerType="calendar"
                          placeholder={props.generalSettings?.dateFormat}
                          displayFormat={props.generalSettings?.dateFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Time) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.location.general[f.name]
                                )
                              : props.location.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="time"
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          key={i}
                          pickerType="rollers"
                          placeholder={props.generalSettings?.timeFormat}
                          displayFormat={props.generalSettings?.timeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.DateTime) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.location.general[f.name]
                                )
                              : props.location.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="datetime"
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          key={i}
                          pickerType="calendar"
                          placeholder={props.generalSettings?.dateTimeFormat}
                          displayFormat={props.generalSettings?.dateTimeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.List) {
                    return (
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          items={SortObjectByPropName(f.fieldValues, "name")}
                          displayExpr="name"
                          valueExpr="id"
                          labelMode="floating"
                          label={f.labelText}
                          value={
                            props.location.general &&
                            props.location.general[f.name]
                          }
                          onValueChange={(e) => onChangeFields(f.name, e, true)}
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    );
                  }
                })}
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default General;
