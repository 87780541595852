import { Support } from "./supportTypes";
import {
  SignSetupType,
  AttachmentType,
  ChangeLogStatus,
} from "./enums/signEnums";

export interface ISignParams {
  [key: string]: any;
  signId: string;
}

export class SignSetup {
  [key: string]: any;
  id!: string;
  customerId!: string;
  signSetupType!: SignSetupType;
  index!: number;
  description!: string;
  tesDefault!: boolean;
  colour!: string;
  code!: string;
  height!: number;
  width!: number;
  note!: string;
  signCodeDimensions!: SignCode[];
  signCodeDescriptions!: SignCode[];
  signCodeTypes!: SignCode[];
  signFacingDirections!: TesSign[];
  signFaceMaterials!: TesSign[];
  signReflectiveCoatings!: TesSign[];
  signReflectiveRatings!: TesSign[];
  signConditions!: TesSign[];
  signDimensions!: TesSign[];
  isDeleted!: boolean;
  deleteDate!: Date;
}

export class SignCode {
  id!: string;
  customerId!: string;
  index!: number;
  colour!: string;
  code!: string;
  signSetupType!: SignSetupType;
  signTypeId!: string;
  signDimension!: SignSetup;
  signDimensionId!: string;
  signDescription!: SignSetup;
  signDescriptionId!: string;
  note!: string;
  signs!: TesSign[];
  isDeleted!: boolean;
  deletedTime!: Date;
}

export class Attachment {
  id!: string;
  uploadDT!: Date;
  key!: string;
  fileName?: string;
  sign!: TesSign;
  signId!: string;
  //Support
  supportId!: string;
  attachmentType!: AttachmentType;
  isDeleted!: boolean;
  deletedTime!: Date;
}

export class ChangeLog {
  id!: string;
  customerId!: string;
  status!: ChangeLogStatus;
  appId!: string;
  userId!: string;
  changeDT!: Date;
  getDT!: Date;
  field!: string;
  fromValue!: string;
  toValue!: string;
  username!: string;
  tesSign!: TesSign;
  //Support
  supportId!: string;
}

export class Patrol {
  id!: string;
  customerId!: string;
  userId!: string;
  sign!: TesSign;
  signId!: string;
  //Support
  supportId!: string;
}

export class SignSetting {
  id!: string;
  customerId!: string;
  signImagesURL!: string;
  currencySymbol!: string;
}

export class TesSign {
  [key: string]: any;
  id!: string;
  customerId!: string;
  version!: number;
  signLocationType!: SignSetup;
  signLocationTypeId!: string;
  signId!: string;
  support!: Support;
  supportId!: string;
  signCode!: SignCode;
  signCodeId!: string;
  signFacingDirection!: SignSetup;
  signFacingDirectionId!: string;
  signFaceMaterial!: SignSetup;
  signFaceMaterialId!: string;
  signReflectiveCoating!: SignSetup;
  signReflectiveCoatingId!: string;
  signReflectiveRating!: SignSetup;
  signReflectiveRatingId!: string;
  signDimension!: SignSetup;
  signDimensionId!: string;
  dateInstalled!: Date;
  dateExpired!: Date;
  signCondition!: SignSetup;
  signConditionId!: string;
  materialCost!: number;
  labourCost!: number;
  installationCost!: number;
  total!: number;
  height!: number;
  retroRefTestFailed!: boolean;
  note!: string;
  attachments!: Attachment[];
  changeLogs!: ChangeLog[];
  patrols!: Patrol[];
  isDeleted!: boolean;
  deletedTime!: Date;
}
