import {
  ImportActionType,
  ImportCheckResponseStatus,
} from "./enums/importEnums";
import { MovementType, StudyType } from "./enums/trafficStudyEnums";

export class ImportCheckResponseStatusAction {
  checkStatus!: ImportCheckResponseStatus;
  actionType!: ImportActionType;
}
export class ImportCheckResponse {
  fileKey!: string;
  workOrderNo!: string;
  studyId!: string;
  studyType!: StudyType;
  binMapId!: string;
  customerId!: string;
  outgoingMovement!: MovementType;
  incomingMovement!: MovementType;
  statusAction!: ImportCheckResponseStatusAction[];
}

export class NuMetricConvertorRequest {
  constructor() {
    this.filePaths = [];
  }
  [key: string]: any;
  customerId!: string;
  emails!: string[];
  filePaths!: string[];
}
