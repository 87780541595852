import {
  DataGrid,
  DropDownButton,
  NumberBox,
  Popup,
  SelectBox,
  Switch,
  TagBox,
  TextBox,
} from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import GeneralGisMapManager from "../../../../../components/gisMap/generalGisMapManager";
import Permission from "../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../constants/Permissions/SafetyPermissions";
import { useAuth } from "../../../../../contexts/auth";
import { SimplifiedTesField } from "../../../../../types/field/enums/fieldEnums";
import { SimplifiedField } from "../../../../../types/field/fieldType";
import {
  DayOfWeek,
  Month,
  TesMapModule,
  YesNo,
} from "../../../../../types/general/enums/generalEnums";
import {
  INameId,
  NameValue,
  NameValueNumber,
} from "../../../../../types/general/generalTypes";
import { SafetyPerformanceMeasureType } from "../../../../../types/safety/enums/analysisEnums";
import {
  EmpiricalBayesAnalysis,
  NetworkScreeningResult,
  RankingMap,
} from "../../../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import MaxDropdownItemWidthCalculator from "../../../../../utils/dropDownWidthCalculator";
import { Enum2Array } from "../../../../../utils/enumTools";

// props
interface IPros {
  isLocked: boolean;
  initData: EmpiricalBayesAnalysis;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  initDataTesFields: SimplifiedField[];
  initDataSiteSubTypes: INameId[];
}
const Results = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [lstYears, setLstYears] = useState<NameValueNumber[]>([]);
  const [lstMonths, setLstMonths] = useState<NameValue[]>([]);
  const [lstDays, setLstDays] = useState<NameValue[]>([]);
  const [lstNoneYesNo, setLstNoneYesNo] = useState<NameValue[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [lstSafetyPerformanceMeasure, setLstSafetyPerformanceMeasure] =
    useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const dropDownOptions: any[] = [
    { name: t("showExcessRank"), value: 1 },
    { name: t("showExpectedRank"), value: 2 },
  ];
  const [mapRes, setMapRes] = useState<RankingMap[]>([]);
  useEffect(() => {
    setLstMonths(Enum2Array(Month));
    setLstDays(Enum2Array(DayOfWeek));
    setLstNoneYesNo(Enum2Array(YesNo));
    setLstSafetyPerformanceMeasure(Enum2Array(SafetyPerformanceMeasureType));
    var yearRes: NameValueNumber[] = [];
    for (let year = new Date().getFullYear(); year > 1980; year--) {
      let newYew: NameValueNumber = {
        name: year.toString(),
        value: year,
      };
      yearRes.push(newYew);
    }
    setLstYears(yearRes);
  }, []);

  function onValueChanged(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value });
  }

  function onEmpiricalBayesSettingValueChanged(name: string, value: any) {
    props.setInitData({
      ...props.initData,
      empiricalBayesSetting: {
        ...props.initData.empiricalBayesSetting,
        [name]: value,
      },
    });
  }

  async function onShowOnMap(value: any) {
    try {
      var gridData = (await dataGridRef.current
        ?.instance()
        .getSelectedRowsData()) as NetworkScreeningResult[];
      if (gridData?.length === 0 ?? true) {
        notify(t("pleaseSelectSomeData"), "warning", 5000);
        return;
      }
      if (activeLoading) activeLoading(true);

      if (value === 1) {
        var rankings = gridData.map((x) => {
          var res: RankingMap = {
            geoId: x.locationGeoId,
            locationType: x.locationType,
            rank: x.excessRank,
            properties: {
              expectedRank: x.expectedRank,
              adjustedRank: x.adjustedRank,
              excess: x.excess.toFixed(2),
              observedFI: x.observedFI.toFixed(2),
              observedTotal: x.observedTotal.toFixed(2),
            },
          };
          return res;
        });
        setMapRes(rankings);
        rankings = [];
        if (activeLoading) activeLoading(false);
      } else if (value === 2) {
        var rankings = gridData.map((x) => {
          var res: RankingMap = {
            geoId: x.locationGeoId,
            locationType: x.locationType,
            rank: x.expectedRank,
            properties: {
              excessRank: x.excessRank,
              adjustedRank: x.adjustedRank,
              excess: x.excess.toFixed(2),
              observedFI: x.observedFI.toFixed(2),
              observedTotal: x.observedTotal.toFixed(2),
            },
          };
          return res;
        });
        setMapRes(rankings);
        rankings = [];
        if (activeLoading) activeLoading(false);
      }
      setShowMap(true);
      await dataGridRef.current?.instance().deselectAll();
    } catch (ex) {
      await dataGridRef.current?.instance().deselectAll();
      if (activeLoading) activeLoading(false);
      notify(t("errorInFetchReports") + ex, "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_V_Workbook]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <CollapsibleCard
                title={t("networkScreeningEBSetting")}
                isOpen={false}
              >
                <Form colCount={3}>
                  <SimpleItem colSpan={1}>
                    <TextBox
                      label={t("title")}
                      labelMode="floating"
                      value={props.initData.title}
                      onValueChange={(e) => onValueChanged("title", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("years")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstYears, "name")}
                      value={props.initData.years}
                      valueExpr="value"
                      displayExpr="name"
                      onValueChange={(e) => onValueChanged("years", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("months")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstMonths, "name")}
                      value={props.initData.months}
                      valueExpr="value"
                      displayExpr="name"
                      onValueChange={(e) => onValueChanged("months", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      style={{ marginTop: "-1.25rem" }}
                      label={t("daysOfWeek")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(lstDays, "name")}
                      value={props.initData.daysOfWeek}
                      valueExpr="value"
                      displayExpr="name"
                      onValueChange={(e) => onValueChanged("daysOfWeek", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      style={{ marginTop: "-1.25rem" }}
                      label={t("animalInvolvement")}
                      labelMode="floating"
                      value={props.initData.animalInvolvement}
                      onValueChange={(e) =>
                        onValueChanged("animalInvolvement", e)
                      }
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstNoneYesNo, "name")}
                      disabled={props.isLocked}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      style={{ marginTop: "-1.25rem" }}
                      label={t("bicycleInvolvement")}
                      labelMode="floating"
                      value={props.initData.bicycleInvolvement}
                      onValueChange={(e) =>
                        onValueChanged("bicycleInvolvement", e)
                      }
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstNoneYesNo, "name")}
                      disabled={props.isLocked}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      style={{ marginTop: "-1.25rem" }}
                      label={t("pedestrianInvolved")}
                      labelMode="floating"
                      value={props.initData.pedestrianInvolved}
                      onValueChange={(e) =>
                        onValueChanged("pedestrianInvolved", e)
                      }
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(lstNoneYesNo, "name")}
                      disabled={props.isLocked}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      style={{ marginTop: "-1.25rem" }}
                      label={t("initialImpactTypes")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(
                        props.initDataTesFields?.filter(
                          (x) =>
                            x.simplifiedTesField ===
                            SimplifiedTesField.InitialImpactType
                        ),
                        "fieldValue"
                      )}
                      value={props.initData.initialImpactTypes}
                      valueExpr="fieldValue"
                      displayExpr="fieldValue"
                      onValueChange={(e) =>
                        onValueChanged("initialImpactTypes", e)
                      }
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      style={{ marginTop: "-1.25rem" }}
                      label={t("weatherConditions")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(
                        props.initDataTesFields?.filter(
                          (x) =>
                            x.simplifiedTesField ===
                            SimplifiedTesField.WeatherCondition
                        ),
                        "fieldValue"
                      )}
                      value={props.initData.weatherConditions}
                      valueExpr="fieldValue"
                      displayExpr="fieldValue"
                      onValueChange={(e) =>
                        onValueChanged("weatherConditions", e)
                      }
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      style={{ marginTop: "-1.25rem" }}
                      label={t("roadSurfaceConditions")}
                      labelMode="floating"
                      className="modalInput"
                      dataSource={SortObjectByPropName(
                        props.initDataTesFields?.filter(
                          (x) =>
                            x.simplifiedTesField ===
                            SimplifiedTesField.RoadSurfaceCondition
                        ),
                        "fieldValue"
                      )}
                      value={props.initData.roadSurfaceConditions}
                      valueExpr="fieldValue"
                      displayExpr="fieldValue"
                      onValueChange={(e) =>
                        onValueChanged("roadSurfaceConditions", e)
                      }
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      style={{ marginTop: "-1.25rem" }}
                      step={0}
                      label={t("windowLength")}
                      labelMode="floating"
                      value={props.initData.empiricalBayesSetting?.windowLength}
                      //from EmpiricalBayesSetting
                      onValueChange={(e) =>
                        onEmpiricalBayesSettingValueChanged("windowLength", e)
                      }
                      className="modalInput"
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      style={{ marginTop: "-1.25rem" }}
                      step={0}
                      label={t("sptExpectedWeight")}
                      labelMode="floating"
                      value={
                        props.initData.empiricalBayesSetting?.sptExpectedWeight
                      }
                      //from EmpiricalBayesSetting
                      onValueChange={(e) =>
                        onEmpiricalBayesSettingValueChanged(
                          "sptExpectedWeight",
                          e
                        )
                      }
                      className="modalInput"
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      style={{ marginTop: "-1.25rem" }}
                      step={0}
                      label={t("sptExcessWeight")}
                      labelMode="floating"
                      value={
                        props.initData.empiricalBayesSetting?.sptExcessWeight
                      }
                      //from EmpiricalBayesSetting
                      onValueChange={(e) =>
                        onEmpiricalBayesSettingValueChanged(
                          "sptExcessWeight",
                          e
                        )
                      }
                      className="modalInput"
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      style={{ marginTop: "-1.25rem" }}
                      step={0}
                      label={t("incrementLength")}
                      labelMode="floating"
                      value={
                        props.initData.empiricalBayesSetting?.incrementLength
                      }
                      //from EmpiricalBayesSetting
                      onValueChange={(e) =>
                        onEmpiricalBayesSettingValueChanged(
                          "incrementLength",
                          e
                        )
                      }
                      className="modalInput"
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <SelectBox
                      placeholder=""
                      style={{ marginTop: "-1.25rem" }}
                      label={t("safetyPerformanceMeasure")}
                      labelMode="floating"
                      value={
                        props.initData.empiricalBayesSetting
                          ?.safetyPerformanceMeasure
                      }
                      //from EmpiricalBayesSetting
                      onValueChange={(e) =>
                        onEmpiricalBayesSettingValueChanged(
                          "safetyPerformanceMeasure",
                          e
                        )
                      }
                      className="modalInput"
                      valueExpr="value"
                      displayExpr="name"
                      items={SortObjectByPropName(
                        lstSafetyPerformanceMeasure,
                        "name"
                      )}
                      disabled={props.isLocked}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      style={{ marginTop: "-1.25rem" }}
                      step={0}
                      label={t("irr")}
                      labelMode="floating"
                      value={props.initData.empiricalBayesSetting?.irr}
                      //from EmpiricalBayesSetting
                      onValueChange={(e) =>
                        onEmpiricalBayesSettingValueChanged("irr", e)
                      }
                      className="modalInput"
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <div style={{ marginTop: "-1.25rem" }}>
                      {t("runOverRep")}
                    </div>
                    <Switch
                      style={{ marginTop: 5 }}
                      hint={t("inactive")}
                      value={props.initData.runOverRep}
                      onValueChange={(e) => onValueChanged("runOverRep", e)}
                    />
                  </SimpleItem>
                </Form>
              </CollapsibleCard>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <div
                className="row"
                style={{ marginTop: 10, padding: 10, position: "relative" }}
              >
                <h6
                  className={"content-block"}
                  style={{ margin: "0rem 0rem 1rem 0.4rem", fontSize: 19 }}
                >
                  {t("networkScreeningEBResult")}
                </h6>{" "}
                <div
                  className="rightColumn"
                  style={{
                    display: "flex",
                    position: "absolute",
                    top: 10,
                    right: 10,
                  }}
                >
                  <DropDownButton
                    splitButton={true}
                    useSelectMode={false}
                    items={SortObjectByPropName(dropDownOptions, "name")}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator(
                        SortObjectByPropName(dropDownOptions, "name")
                      ),
                    }}
                    displayExpr="name"
                    icon="fa-solid fa-map-location-dot"
                    keyExpr="value"
                    style={{ borderRadius: "4px", width: "10rem" }}
                    onButtonClick={(x) => onShowOnMap(1)}
                    onItemClick={(e) => onShowOnMap(e.itemData.value)}
                  />
                </div>
                <DataGrid
                  id="grid"
                  key="id"
                  ref={dataGridRef}
                  dataSource={props.initData.results}
                  allowColumnReordering={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                >
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                    //recursive={false}
                  />

                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    width={285}
                    placeholder={t("search...")}
                  />
                  <ColumnChooser
                    width={350}
                    height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    alignment="left"
                    dataField="id"
                    allowSorting={true}
                    caption={t("id")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("locationId")}
                    alignment="left"
                    dataField="locationId"
                    allowSorting={true}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("locationDescription")}
                    alignment="left"
                    dataField="locationDescription"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("locationGeoId")}
                    alignment="left"
                    dataField="locationGeoId"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="siteSubTypeId"
                    allowSorting={true}
                    visible={true}
                    caption={t("siteSubType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(
                        props.initDataSiteSubTypes,
                        "name"
                      )}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>

                  {/* <Column caption={t("lhrs")} alignment="left" dataField="lhrs" allowSorting={true}>
                  <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                </Column> */}
                  {/* <Column caption={t("offset")} alignment="left" dataField="offset" allowSorting={true}>
                  <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                </Column> */}
                  <Column
                    caption={t("excessRank")}
                    alignment="left"
                    dataField="excessRank"
                    allowSorting={true}
                    sortOrder={"asc"}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("expectedRank")}
                    alignment="left"
                    dataField="expectedRank"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("adjustedRank")}
                    alignment="left"
                    dataField="adjustedRank"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("excess")}
                    alignment="left"
                    dataField="excess"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("observedFI")}
                    alignment="left"
                    dataField="observedFI"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("predictedFI")}
                    alignment="left"
                    dataField="predictedFI"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("expectedFI")}
                    alignment="left"
                    dataField="expectedFI"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("observedTotal")}
                    alignment="left"
                    dataField="observedTotal"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("predictedTotal")}
                    alignment="left"
                    dataField="predictedTotal"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("expectedTotal")}
                    alignment="left"
                    dataField="expectedTotal"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("overRep")}
                    alignment="left"
                    dataField="overRep"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("endOffset")}
                    alignment="left"
                    dataField="endOffset"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("loss")}
                    alignment="left"
                    dataField="loss"
                    allowSorting={true}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("costRCFI")}
                    alignment="left"
                    dataField="costRCFI"
                    allowSorting={true}
                    format="#,###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("excessRatioFI")}
                    alignment="left"
                    dataField="excessRatioFI"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    caption={t("excessRatioTotal")}
                    alignment="left"
                    dataField="excessRatioTotal"
                    allowSorting={true}
                    format="#0.###"
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </DataGrid>
              </div>
            </SimpleItem>
          </Form>
        </div>

        {/* Map Popup */}
        <Popup
          width={"50%"}
          height={"auto"}
          visible={showMap}
          resizeEnabled={true}
          showTitle={true}
          title={t("map")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowMap(false)}
        >
          <GeneralGisMapManager
            tesModule={TesMapModule.CollisionOverviewDashboard}
            lstRanking={mapRes}
            dataGridRef={dataGridRef}
            showGis={showMap}
            setShowMap={setShowMap}
            locId="LocationId"
          />
        </Popup>
      </React.Fragment>
    </Permission>
  );
};
export default Results;
