import React from "react";
import design from "./permission.module.scss";
import { useAuth } from "../../contexts/auth";

interface IPros {
  allowed: string[];
  hasFeedBackElement?: boolean;
  children: any;
}

const Permission = (props: IPros) => {
  const { user } = useAuth();
  if (user?.permissions?.some((a) => props.allowed.indexOf(a) >= 0)) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  if (props.hasFeedBackElement) {
    // if (true) {
    return (
      <div className={`${design.main}`}>
        <i className={`fa-regular fa-hand ${design.danger}`}></i>
        <h1>You don't have permission</h1>
        <p className={`${design.message}`}>
          Access to this resource is denied. To resolve this issue, please reach
          out to your supervisor and request permission to access this resource
          or to adjust your current permissions.
        </p>
      </div>
    );
  }

  return <React.Fragment />;
};

export default Permission;
