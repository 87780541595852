import { TrendingResult } from "../trafficStudyTypes";

export class TrendingDetailDTO {
  id!: string;
  name!: string;
  year!: number;
  results!: TrendingResultsDTO[];
  excludedYears!: number[];
}

export class TrendingResultsDTO extends TrendingResult {
  trafficSectionName!: string;
  countStationId!: String;
  countStationName!: string;
}
