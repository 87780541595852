import i18n from "../../../../react-i18next";

const addTabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
];

const updateTabTitles = [
  {
    id: 0,
    text: "details",
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "tags",
    icon: "fa-solid fa-language",
    content: "tags tab content",
  },
  {
    id: 2,
    text: "customers",
    icon: "fa-solid fa-users",
    content: "tags tab content",
  },
];

export { addTabTitles, updateTabTitles };
