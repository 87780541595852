import { Form, NumberBox } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { SimpleItem } from "devextreme-react/form";
import { SelectBox } from "devextreme-react/select-box";
import { Switch } from "devextreme-react/switch";
import TextBox from "devextreme-react/text-box";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { t } from "i18next";
import React, { useEffect } from "react";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { TesField } from "../../../../../types/field/fieldType";
import { PageMode } from "../../../../../types/general/enums/generalEnums";
import { CountStationDTO } from "../../../../../types/infrastructure/dto/infrstructureDtos";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { NameValue } from "../../../../../types/general/generalTypes";
import {
  FieldCategoryType,
  FieldPermission,
  FieldType,
} from "../../../../../types/field/enums/fieldEnums";
import { DatePickerDateCalculator } from "../../../../../utils/dateTimeTools";
import { CountStationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";

interface IPros {
  initDataFields: TesField[] | undefined;
  initDataCountStation: CountStationDTO;
  setInitDataCountStation: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  pageMode: number;
  validationRef: React.RefObject<ValidationGroupRef>;
  lstCountStationTypes: NameValue[];
}

const General = (props: IPros) => {
  const { customerDivisions } = useClientSetting();
  const { user } = useAuth();
  const filteredGeneralFieldsByCountStationType =
    props.initDataFields && props.initDataCountStation
      ? props.initDataCountStation.countStationType ===
        CountStationType.CountingStation
        ? props.initDataFields.filter(
            (x) =>
              x.fieldCategoryType === FieldCategoryType.CountingStationGeneral
          )
        : props.initDataFields.filter(
            (x) =>
              x.fieldCategoryType ===
              FieldCategoryType.PermanentCountingStationGeneral
          )
      : [];

  function onChangeFields(name: string, value: any, isUpperCase: boolean) {
    props.setInitDataCountStation({
      ...props.initDataCountStation,
      [name]: value,
    });
    props.setDataChanged(true);
  }

  function onChangeDynamicFields(
    name: string,
    value: any,
    isUpperCase: boolean
  ) {
    props.setInitDataCountStation({
      ...props.initDataCountStation,
      general: {
        ...props.initDataCountStation.general,
        [name]: value,
      },
    });
    props.setDataChanged(true);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                // style={{ marginBottom: 20 }}
                label={t("name")}
                labelMode="floating"
                value={props.initDataCountStation.name}
                disabled={props.isLocked}
                onValueChange={(e) => onChangeFields("name", e, false)}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <TextBox
                // style={{ marginBottom: 20 }}
                label={t("description")}
                labelMode="floating"
                value={props.initDataCountStation.description}
                disabled={props.isLocked}
                onValueChange={(e) => onChangeFields("description", e, false)}
              />
            </SimpleItem>
            <SimpleItem
              colSpan={1}
              visible={user?.permissions?.some(
                (x) => x === TMSPermissions.TMS_Admin
              )}
            >
              <SelectBox
                placeholder=""
                // style={{ marginBottom: 20 }}
                label={t("division")}
                labelMode="floating"
                displayExpr="name"
                valueExpr="id"
                value={props.initDataCountStation.divisionId}
                onValueChange={(e) => onChangeFields("divisionId", e, false)}
                items={SortObjectByPropName(customerDivisions!, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t("countStationNo")}
                labelMode="floating"
                value={props.initDataCountStation.countStationNo}
                onValueChange={(e) =>
                  onChangeFields("countStationNo", e, false)
                }
                className="modalInput"
                style={{ marginBottom: "0" }}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                // style={{ marginBottom: 20 }}
                label={t("countStationType")}
                labelMode="floating"
                displayExpr="name"
                valueExpr="value"
                value={props.initDataCountStation.countStationType}
                onValueChange={(e) =>
                  onChangeFields("countStationType", e, false)
                }
                items={SortObjectByPropName(props.lstCountStationTypes, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t("lhrs")}
                labelMode="floating"
                value={props.initDataCountStation.lhrs}
                onValueChange={(e) => onChangeFields("lhrs", e, false)}
                className="modalInput"
                style={{ marginBottom: "0" }}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t("offset")}
                labelMode="floating"
                value={props.initDataCountStation.offset}
                onValueChange={(e) => onChangeFields("offset", e, false)}
                className="modalInput"
                style={{ marginBottom: "0" }}
                disabled={props.isLocked}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("installationDate")}
                type="date"
                value={props.initDataCountStation.installationDate}
                onValueChange={(e) =>
                  onChangeFields("installationDate", e, false)
                }
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                showClearButton={true}
                disabled={props.isLocked}
              />
            </SimpleItem>
            {filteredGeneralFieldsByCountStationType &&
              filteredGeneralFieldsByCountStationType
                .filter((x) =>
                  props.isLocked ? true : x.readOnlyField === false
                )
                .map((f, i) => {
                  if (f.fieldType === FieldType.String) {
                    return (
                      <SimpleItem colSpan={1}>
                        <TextBox
                          // style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="floating"
                          value={
                            props.initDataCountStation.general &&
                            props.initDataCountStation.general[f.name]
                          }
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }
                  if (
                    f.fieldType === FieldType.Number ||
                    f.fieldType === FieldType.DecimalNumber ||
                    f.fieldType === FieldType.DoubleNumber
                  ) {
                    return (
                      <SimpleItem colSpan={1}>
                        <TextBox
                          // style={{ marginBottom: 20 }}
                          label={f.labelText}
                          labelMode="floating"
                          value={
                            props.initDataCountStation.general &&
                            props.initDataCountStation.general[f.name]
                          }
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Boolean) {
                    return (
                      <SimpleItem colSpan={1}>
                        <Switch
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          value={
                            props.initDataCountStation.general &&
                            props.initDataCountStation.general[f.name]
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Date) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.initDataCountStation.general[f.name]
                                )
                              : props.initDataCountStation.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="date"
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          pickerType="calendar"
                          placeholder={props.generalSettings?.dateFormat}
                          displayFormat={props.generalSettings?.dateFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.Time) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.initDataCountStation.general[f.name]
                                )
                              : props.initDataCountStation.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="time"
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          pickerType="rollers"
                          placeholder={props.generalSettings?.timeFormat}
                          displayFormat={props.generalSettings?.timeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.DateTime) {
                    return (
                      <SimpleItem colSpan={1}>
                        <DateBox
                          label={f.labelText}
                          value={
                            f.exactDT === true
                              ? DatePickerDateCalculator(
                                  props.initDataCountStation.general[f.name]
                                )
                              : props.initDataCountStation.general[f.name]
                          }
                          dateSerializationFormat={
                            f.exactDT === true
                              ? "yyyy-MM-ddTHH:mm:ss"
                              : undefined
                          }
                          type="datetime"
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          pickerType="calendar"
                          placeholder={props.generalSettings?.dateTimeFormat}
                          displayFormat={props.generalSettings?.dateTimeFormat}
                          useMaskBehavior={true}
                          openOnFieldClick={true}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                        />
                      </SimpleItem>
                    );
                  }

                  if (f.fieldType === FieldType.List) {
                    return (
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          items={SortObjectByPropName(f.fieldValues, "name")}
                          displayExpr="name"
                          valueExpr="id"
                          labelMode="floating"
                          label={f.labelText}
                          value={
                            props.initDataCountStation.general &&
                            props.initDataCountStation.general[f.name]
                          }
                          onValueChange={(e) =>
                            onChangeDynamicFields(f.name, e, true)
                          }
                          key={i}
                          disabled={
                            props.isLocked ||
                            f.permission === FieldPermission.View ||
                            f.readOnlyField
                          }
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                    );
                  }
                })}

            <SimpleItem colSpan={1}>
              <div>{t("isDeleted")}</div>
              <Switch
                style={{ marginTop: 5 }}
                hint={t("isDeleted")}
                value={props.initDataCountStation.isDeleted}
                onValueChange={(e) => onChangeFields("isDeleted", e, false)}
                disabled={props.isLocked}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default General;
