import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "results",
    icon: "fa-solid fa-square-poll-vertical",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "errors",
    icon: "fa-solid fa-exclamation",
    content: "Work Order tab content",
  },
  // {
  //   id: 2,
  //   text: "diagnosis",
  //   icon: "fa-solid fa-heart-pulse",
  //   content: "Work Order tab content",
  // },
];
export default tabTitles;
