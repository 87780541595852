import { DataGrid, Form, NumberBox, Popup } from "devextreme-react";
import {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary,
} from "devextreme-react/cjs/data-grid";
import { SimpleItem } from "devextreme-react/cjs/form";
import Tabs from "devextreme-react/tabs";
import { t } from "i18next";
import React, { useRef, useState } from "react";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import {
  AdjustmentFactor,
  SeasonalAdjustmentFactor,
} from "../../../../../types/infrastructure/infrastructureTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import DayOfWeekAdjFactor from "./components/dayOfWeekAdjustmentFactor";
import MonthlyAdjFactor from "./components/monthlyAdjustmnetFactor";
import tabTitles from "./data";
import HourAdjFactor from "./components/hourAdjustmentFactor";
import TesTabs from "../../../../../components/tesTab/tesTab";

interface IPros {
  adjustmentFactors: AdjustmentFactor[];
}

const AdjFactor = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<AdjustmentFactor>({
    ...new AdjustmentFactor(),
    seasonalFactor: new SeasonalAdjustmentFactor(),
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  function onRowClick(e: any) {
    setSelectedRow(e.data);
    setShowModal(true);
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  return (
    <React.Fragment>
      <DataGrid
        id="gridContainer"
        ref={dataGridRef}
        dataSource={props.adjustmentFactors}
        rowAlternationEnabled={true}
        showBorders={true}
        hoverStateEnabled={true}
        remoteOperations={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onExporting={OnExporting}
        onRowClick={(e) => onRowClick(e)}
      >
        <Export enabled={true} allowExportSelectedData={true} />
        <Paging enabled={true} defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 200, 300, 400, 500]}
          showNavigationButtons={true}
          showInfo={true}
          visible={true}
        />
        <FilterRow visible={true} applyFilter="auto" />

        <HeaderFilter visible={true} />
        <SearchPanel visible={true} width={285} placeholder={t("search...")} />
        <ColumnChooser
          width={350}
          height={400}
          enabled={true}
          mode="select"
          sortOrder="asc"
        >
          <Search enabled />
        </ColumnChooser>
        <SortByGroupSummaryInfo summaryItem="Total Count" sortOrder="desc" />
        <Summary>
          <GroupItem summaryType="count" alignByColumn name="Total Count" />
        </Summary>
        <Column
          dataField="id"
          caption={t("id")}
          alignment="left"
          visible={false}
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="pcsId"
          caption={t("pcsId")}
          alignment="left"
          visible={false}
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="aadt" caption={t("aadt")} alignment="left">
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="year" caption={t("year")} alignment="left">
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="totalVolume"
          caption={t("totalVolume")}
          alignment="left"
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="noSupplementedDay"
          caption={t("noSupplementedDay")}
          alignment="left"
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="totalVolume4Supplemented"
          caption={t("totalVolume4Supplemented")}
          alignment="left"
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="totalVolume4Original"
          caption={t("totalVolume4Original")}
          alignment="left"
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="dhvPercent"
          caption={t("dhvPercent")}
          alignment="left"
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="noOriginalDay"
          caption={t("noOriginalDay")}
          alignment="left"
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
      </DataGrid>

      <Popup
        width={"85%"}
        height={"90%"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("adjustmentFactors")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowModal(false)}
      >
        <div>
          <CollapsibleCard isOpen={false} title={t("details")}>
            <Form colCount={7}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("aadt")}
                  labelMode="floating"
                  value={selectedRow.aadt}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("year")}
                  labelMode="floating"
                  value={selectedRow.year}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("totalVolume")}
                  labelMode="floating"
                  value={selectedRow.totalVolume}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("noSupplementedDay")}
                  labelMode="floating"
                  value={selectedRow.noSupplementedDay}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("totalVolume4Supplemented")}
                  labelMode="floating"
                  value={selectedRow.totalVolume4Supplemented}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("totalVolume4Original")}
                  labelMode="floating"
                  value={selectedRow.totalVolume4Original}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("noOriginalDay")}
                  labelMode="floating"
                  value={selectedRow.noOriginalDay}
                  format={{ type: "fixedPoint" }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
            </Form>
          </CollapsibleCard>
          <CollapsibleCard isOpen={true} title={t("seasonalAdjustmentFactor")}>
            <Form colCount={7}>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("sadt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.sadt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("wadt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.wadt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorSadt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorSadt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorSawdt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorSawdt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorJulAdt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorJulAdt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorJulAwdt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorJulAwdt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorAugAdt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorAugAdt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorAugAwdt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorAugAwdt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  label={t("factorWadt")}
                  labelMode="floating"
                  value={selectedRow.seasonalFactor.factorWadt}
                  format={{ type: "fixedPoint", precision: 2 }}
                  className="modalInput"
                  style={{ margin: "-0.5rem 0 -0.5rem 0" }}
                />
              </SimpleItem>
            </Form>
          </CollapsibleCard>
          <Form colCount={1}>
            <SimpleItem colSpan={1}>
              <div className={"dx-card"}>
                <TesTabs
                  width={"100%"}
                  dataSource={tabTitles}
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />
                {selectedIndex === 0 && (
                  <MonthlyAdjFactor
                    gridData={selectedRow.monthlyAdjustmentFactors}
                  />
                )}

                {selectedIndex === 1 && (
                  <DayOfWeekAdjFactor
                    gridData={selectedRow.dayOfWeekAdjustmentFactors}
                  />
                )}

                {selectedIndex === 2 && (
                  <HourAdjFactor gridData={selectedRow.hourAdjustmentFactors} />
                )}
              </div>
            </SimpleItem>
          </Form>
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default AdjFactor;
