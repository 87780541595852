import { Attachment } from "./../sign/signTypes";
import { VMPersonnel } from "./dto/maintenanceDto";
import {
  MaintenanceType,
  JobStatusType,
  AssetType,
} from "./enums/maintenanceEnums";

export interface IJobParams {
  [key: string]: any;
  jobId: string;
}

export class JobType {
  id!: string;
  customerId!: string;
  name!: string;
  note!: string;
  index!: number;
  isInstallation!: boolean;
  jobs!: Job[];
  isDeleted!: boolean;
  deletedDate!: Date;
}

export class JobStatus {
  id!: string;
  customerId!: string;
  name!: string;
  index!: number;
  jobStatusType!: JobStatusType;
  jobs!: Job[];
  assets!: Asset[];
  isDeleted!: boolean;
  deletedDate!: Date;
}

export class Asset {
  id!: string;
  assetId!: string;
  job!: Job;
  jobId!: string;
  type!: AssetType;
  note!: string;
  status!: JobStatus;
  statusId!: string;
}
export class VMAsset {
  id!: string | undefined;
  assetId!: string | undefined;
  type!: AssetType;
  note!: string | undefined;
  statusId!: string | undefined;
}

export class Personnel {
  constructor() {
    this.costs = [];
    this.jobs = [];
  }
  [key: string]: any;
  id!: string;
  userId!: string;
  customerId!: string;
  costs!: Cost[];
  jobs!: Job[];
  note!: string;
  isDeleted!: boolean;
  deletedDate!: Date;
}

export class Cost {
  id!: string;
  price!: number;
  fromDate!: Date;
  toDate!: Date;
  personnel!: Personnel;
  personnelId!: string;
  equipment!: Equipment;
  equipmentId!: string;
  isDeleted!: boolean;
  deletedDate!: Date;
}

export class Equipment {
  id!: string;
  customerId!: string;
  name!: string;
  costs!: Cost[];
  jobs!: Job[];
  note!: string;
  isDeleted!: boolean;
  deletedDate!: Date;
}

export class Tag {
  id?: string;
  customerId?: string;
  name?: string;
  jobs?: Job[];
  isDeleted?: boolean;
  deletedDate?: Date;
}

export class SignStatus {
  [key: string]: any;
  statusId: string | undefined;
  note: string | undefined;
}
export class SupportStatus {
  [key: string]: any;
  statusId: string | undefined;
  note: string | undefined;
}
export class NewJob {
  id!: string;
  customerId!: string;
  jobId?: string;
  name?: string;
  jobTypeId?: string;
  jobStatusId?: string;
  assignDate?: Date;
  completionDate?: Date;
  duration?: string;
  labourCost?: number;
  materialCost?: number;
  equipmentCost?: number;
  accountNo?: string;
  equipments?: string[];
  personnels?: string[];
  assets?: VMAsset[];
  tags?: string[];
  deletedAttachments?: string[];
  note?: string;
}
export class Job {
  id!: string;
  jobId!: string;
  customerId!: string;
  name!: string;
  jobType!: JobType;
  signRelated!: boolean;
  supportRelated!: boolean;
  maintenanceType!: MaintenanceType;
  jobTypeName!: string;
  jobTypeNote!: string;
  jobTypeId!: string;
  jobStatus!: JobStatus;
  jobStatusName!: string;
  jobStatusId!: string;
  assignDate!: Date;
  completionDate!: Date;
  duration!: number;
  labourCost!: number;
  materialCost!: number;
  equipmentCost!: number;
  otherCost!: number;
  total!: number;
  equipments!: Equipment[];
  personnels!: VMPersonnel[];
  tags!: Tag[];
  assets!: Asset[];
  attachments!: Attachment[];
  note!: string;
  accountNo!: string;
  tagNames!: string[];
  isDeleted!: boolean;
  deletedDate!: Date;
}
