const SignPermissions = {
  Sign_D: "Sign_D",
  Sign_R: "Sign_R",
  Sign_V: "Sign_V",
  Sign_E: "Sign_E",
  Sign_Sync: "Sign_Sync",
  Sign_D_Setup: "Sign_D_Setup",
  Sign_V_Report: "Sign_V_Report",
};
export default SignPermissions;
