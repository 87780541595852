export enum SyncType {
  Collisions = 1,
  CollisionCodeGroups = 2,
  Intersections = 3,
  RoadSegments = 4,
  Streets = 5,
  InfrastructureCodeGroups = 6,
  SimplifiedFields = 7,
  SiteTypes = 8,
}

export enum SyncStatus {
  Pending = 1,
  Synced = 2,
  Error = 3,
  Syncing = 4,
  Disabled = 0,
}

export enum JobStatus {
  Pending = 1,
  InProgress = 2,
  Done = 3,
  Error = 4,
  Disabled = 0,
}

export enum JobType {
  CleanAllCollisions = 1,
  CleanAllIntersections = 2,
  CleanAllRoadSegments = 3,
  CleanAllStreets = 4,
}
