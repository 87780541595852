import { DateTime } from "luxon";
import {
  AnalysisType,
  SWPProximityCheckRes,
  SignalWarrantsPredictionStatus,
  VRUResultCalculationStatus,
} from "./enums/systematicScreeningEnums";
import { AreaDTO } from "../infrastructure/dto/locationdDto";
import { FlatRawDataDTO, Justification } from "../trafficStudy/dtos/studyDto";
import { SectionDetailType } from "../infrastructure/enums/infrastructureEnums";
import {
  SignalWarrantConfig,
  VolumeSummary,
} from "../trafficStudy/trafficStudyTypes";
import { extend } from "@devexpress/analytics-core/analytics-internal";
import { ITesBase } from "../infrastructure/infrastructureTypes";
import { INameId } from "../general/generalTypes";

export interface IProjectParam {
  [key: string]: any;
  projectId?: string;
}
export interface ISignalWarrantAnalysisId {
  [key: string]: any;
  analysisId?: string;
  projectId: string;
}

export interface IAnalysisResultParam {
  [key: string]: any;
  analysisResultId?: string;
}

export class ProjectBase {
  id!: string;
  customerId!: string;
  title!: string;
  description!: string;
  areaIds!: string[];
}

export class Project extends ProjectBase {
  analysis!: AnalysisBase[];
}

export class AnalysisBase {
  id!: string;
  projectId!: string;
  customerId!: string;
  analysisType!: AnalysisType;
  title!: string;
  analysisDateTime!: DateTime;
}

export class ProjectDTO {
  id!: string;
  customerId!: string;
  title!: string;
  description!: string;
  areaIds!: string[];
  areas!: AreaDTO[];
  analysis!: AnalysisBase[];
}

export class AnalysisSettingsBase {
  id!: string;
  customerId!: string;
}

export class SignalWarrantsPredictionSettings extends AnalysisSettingsBase {
  pastYearsPrediction!: number;
  predictionYear!: number;
  excludeYears!: number[];
  municipalityDefaultGrowth!: number;
  northUserDefinedGrowth!: number;
  eastUserDefinedGrowth!: number;
  southUserDefinedGrowth!: number;
  westUserDefinedGrowth!: number;
  distanceThreshold!: number;
  maxGrowthRateThreshold!: number;
  minGrowthRateThreshold!: number;
  runForAllYears!: boolean;
}

export class SignalWarrantsPredictionResult {
  id!: string;
  analysisId!: string;
  projectId!: string;
  latestStudyId!: string;
  locationId!: string;
  locationDescription!: string;
  geoId!: string;
  proximityCheckPassed!: boolean;
  predictionJustificationSummary!: string;
  predictionYear!: number;
  latestJustificationSummary!: string;
  currentYearJustificationSummary!: string;
  manuallyExcluded!: boolean;
  sectionType!: SectionDetailType;
  signalWarrantPredictionRes!: Justification;
  signalWarrantConfig!: SignalWarrantConfig;
  predictionIsJustified!: boolean;
  currentYearIsJustified!: boolean;
  latestIsJustified!: boolean;
  trafficSignal!: string;
  latestStudyYear!: number;
  currentYear!: number;
  notPredicted!: boolean;
  currentYearNotPredicted!: boolean;
  errorMessage!: string;
  status!: SignalWarrantsPredictionStatus;
  detailedProximityCheckRes!: SWPProximityCheckResult;
}

export class SignalWarrantsPredAnalysis extends AnalysisBase {
  projectId!: string;
  analysisType!: AnalysisType;
  analysisSettings!: SignalWarrantsPredictionSettings;
  results!: SignalWarrantsPredictionResult[];
}
export class SWPProximityCheckResult {
  north!: SWPProximityCheckRes;
  south!: SWPProximityCheckRes;
  east!: SWPProximityCheckRes;
  west!: SWPProximityCheckRes;
  passed!: boolean;
}

export class SignalWarrantPredictionRequest {
  customerId!: string;
  analysisId?: string;
  projectId?: string;
  locationIds?: string[];
  areaIds?: string[];
  pastYearsPrediction!: number;
  predictionYear!: number;
  excludeYears!: number[];
  excludeLocalId?: string[];
  municipalityDefaultGrowth!: number;
  northUserDefinedGrowth!: number;
  eastUserDefinedGrowth!: number;
  southUserDefinedGrowth!: number;
  westUserDefinedGrowth!: number;
  distanceThreshold!: number;
  maxGrowthRateThreshold!: number;
  minGrowthRateThreshold!: number;
  runForAllYears!: boolean;
}

export class SignalWarrantAnalysisDetails {
  flatRawDatas!: FlatRawDataDTO[];
  yearVolumes!: YearVolume[];
  signalWarrantPercentages!: SignalWarrantPercentage4Year[];
  analysisResult!: SignalWarrantsPredictionResult;
}

export class SummayGrid {
  interval!: string;
  summary!: string;
  year!: number;
  isJustified!: boolean;
}

export class YearVolume extends VolumeSummary {
  year!: number;
}

export class SignalWarrantPercentage4Year {
  year!: number;
  justification1Percentage!: number;
  justification2Percentage!: number;
  justification4Percentage!: number;
}

export type VRUTargetPercentile = {
  from: number;
  value: number;
};

export type VRUScoringDetail = {
  score: number;
  vruTargetPercentiles: VRUTargetPercentile[];
};

export type VRUScoring = {
  collisions: VRUScoringDetail;
  psi: VRUScoringDetail;
  pedVol: VRUScoringDetail;
  transit: VRUScoringDetail;
  attractors: VRUScoringDetail;
  popDensity: VRUScoringDetail;
  vehVol: VRUScoringDetail;
  vehTurns: VRUScoringDetail;
  speed: VRUScoringDetail;
  lanes: VRUScoringDetail;
  rightTurnLanes: VRUScoringDetail;
  rtor: VRUScoringDetail;
  leftTurnPhasing: VRUScoringDetail;
  lpi: VRUScoringDetail;
};

export type VRUProjectDto = {
  id: string;
  customerId: string;
  divisionId?: string;
  name: string;
  years: number[];
  jurisdictionIds: string[];
  municipalityIds: string[];
  divisionIds: string[];
};

export type VRUProjectDetailDto = {
  id: string;
  customerId: string;
  divisionId?: string | null;
  name: string;
  years: number[];
  vruScoring: VRUScoring;
  jurisdictionIds: string[];
  municipalityIds: string[];
  divisionIds: string[];
  vruResults: VRUResult[];
  workBookId: string;
  networkScreeningId: string;
};

export type VRUProjectInputDto = {
  id?: string;
  customerId: string;
  divisionId?: string;
  name: string;
  years: number[];
  vruScoring: VRUScoring;
  jurisdictionIds: string[];
  municipalityIds: string[];
  divisionIds: string[];
  workBookId: string;
  networkScreeningId: string;
};

export class VRUResult implements ITesBase {
  id!: string;
  locationId!: string;
  customerId!: string;
  divisionId?: string;
  MunicipalityId!: string;
  JurisdictionId!: string;
  TrafficControl!: string;
  vruProjectId!: string;
  calculationStatus!: VRUResultCalculationStatus;

  // Intersection Information
  locationDescription!: string;
  sectionDetailType!: SectionDetailType;
  geoId!: string;

  // Safety
  cycCollisions!: number;
  pedCollisions!: number;
  sumCollisions!: number;
  psi?: number | null;

  // Demand
  tmcCountDate?: Date | null;
  studyId?: string | null;
  tmcVeh?: number | null;
  tmcPeds?: number | null;
  tmcTurn?: number | null;

  // Attractors
  postSecondaryCount!: number;
  schoolsCount!: number;
  hospitalCount!: number;
  sumAttractors!: number;

  // Existing Conditions
  transitRoutesCount!: number;
  vivaCount!: number;
  transitCount!: number;
  sumLanes!: number;
  avgSpeedCorr!: number;
  rtLaneCount!: number;
  hasRTOR!: boolean;
  hasProtectedLT!: boolean;
  hasLPI!: boolean;
  popDensity!: number;

  // Scoring
  scoreCrashes!: number;
  scorePSI!: number;
  scorePedVol!: number;
  scoreTransit!: number;
  scoreAttractors!: number;
  scorePopDensity!: number;
  scoreVehVol!: number;
  scoreVehTurns!: number;
  scoreSpeed!: number;
  scoreLanes!: number;
  scoreRTLanes!: number;
  scoreRTOR!: number;
  scoreLTPhasing!: number;
  scoreLPI!: number;

  // Factor Scores
  factorScoreSafety!: number;
  factorScoreDemand!: number;
  factorScoreExConditions!: number;
}

export type SimpleWorkBook = {
  name: string;
  id: string;
  analysis: INameId[];
};
