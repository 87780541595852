import i18n from "../../../../react-i18next";

const tabTitles = [
  {
    id: 0,
    text: "general",
    icon: "fa-solid fa-info",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "location",
    icon: "fa-solid fa-map",
    content: "Work Order tab content",
  },
  {
    id: 2,
    text: "directionalSplits",
    icon: "fa-solid fa-arrows-split-up-and-left",
    content: "Work Order tab content",
  },
];

const tabTitlesWithAdjFactor = [
  {
    id: 0,
    text: "general",
    icon: "fa-solid fa-info",
    content: "Details tab content",
  },
  {
    id: 1,
    text: "location",
    icon: "fa-solid fa-map",
    content: "Work Order tab content",
  },
  {
    id: 2,
    text: "adjustmentFactors",
    icon: "fa-solid fa-calculator",
    content: "Work Order tab content",
  },
];

export { tabTitles, tabTitlesWithAdjFactor };
