//#region imports
import React, { useState, useRef } from "react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Button as DevExpressButton,
  Lookup,
  Field,
  Export,
  Search,
  DataGridRef,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import {
  DateBox,
  Form,
  NumberBox,
  ScrollView,
  SelectBox,
  Switch,
  TextArea,
  TextBox,
} from "devextreme-react";
import { ModalMode } from "../../../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import {
  VMCollisionGroupedField,
  VMField,
} from "../../../../../types/field/dto/fieldDTO";
import { CustomerCollision } from "../../../../../types/collision/collisionTypes";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { SimpleItem } from "devextreme-react/form";
import {
  ValidationGroup,
  ValidationGroupRef,
} from "devextreme-react/validation-group";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { v4 as uuid } from "uuid";
import { custom } from "devextreme/ui/dialog";
import { FieldType } from "../../../../../types/field/enums/fieldEnums";
import {
  DatePickerDateCalculator,
  GridActualDateCalculator,
} from "../../../../../utils/dateTimeTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
//#endregion imports

//#region props
interface IPros {
  initDataFields: VMCollisionGroupedField;
  initDataCollision: CustomerCollision;
  generalSettings: GeneralSetting | null;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
  setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
}
//#endregion props
const Roads = (props: IPros) => {
  //#region consts
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<{ [key: string]: any }>({});
  const { t } = useTranslation();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  //#endregion consts
  //#region functions
  function onChangeField(name: string, value: any, isUpperCase: boolean) {
    setSelectedData({
      ...selectedData,
      [name]: isUpperCase ? value.toUpperCase() : value,
    });
    props.setDataChanged(true);
  }
  function onCloseModal() {
    setSelectedData({});
    setShowModal(false);
  }
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  function onEdit(d: any) {
    let newData = {};
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  function onAdd() {
    props.setInitDataCollision({
      ...props.initDataCollision,
      roads: [
        ...props.initDataCollision?.roads,
        { ...selectedData, Id: uuid() },
      ],
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }
  function onUpdate() {
    const roads = props.initDataCollision.roads;
    const selectedDataIndex: number = roads.findIndex(
      (x: any) => x.Id === selectedData["Id"]
    );
    roads[selectedDataIndex] = selectedData;
    props.setInitDataCollision({
      ...props.initDataCollision,
      roads: roads,
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            props.setInitDataCollision({
              ...props.initDataCollision,
              roads: [
                ...props.initDataCollision.roads.filter(
                  (x) => x.Id !== d.row.data.Id
                ),
              ],
            });
            dataGridRef.current?.instance().refresh();
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  //#endregion functions
  return (
    <div
      className={`collisionDetails-roads ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <div className="row" style={{ marginTop: 20, padding: 10 }}>
          <div className="row">
            <div className="rightColumn">
              <Button
                onClick={onNew}
                icon="fa-solid fa-circle-plus"
                text={t("road")}
                disabled={props.isLocked}
              />
            </div>
          </div>

          {props.initDataFields != null && (
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initDataCollision.roads ?? []}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={false}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              {/* <StateStoring
                            enabled={true}
                            savingTimeout={1000}
                            type="custom"
                            customLoad={sendStorageRequest}
                            customSave={sendStorageRequest}
                        /> */}
              <Export enabled={true} allowExportSelectedData={true} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />

              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel
                visible={true}
                width={285}
                placeholder={t("search...")}
              />
              <ColumnChooser
                width={350}
                height={400}
                enabled={true}
                mode="select"
                sortOrder="asc"
              >
                <Search enabled />
              </ColumnChooser>

              <Column
                dataField="Id"
                caption={t("id")}
                alignment="left"
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                caption={t("index")}
                alignment="left"
                allowSorting={false}
                cellRender={({ data }) => {
                  const dataSource = dataGridRef.current
                    ?.instance()
                    .getDataSource()
                    ?.items();
                  const dataIndex = dataSource?.indexOf(data);
                  return dataIndex !== undefined && dataIndex !== -1 ? (
                    <span>{dataIndex + 1}</span>
                  ) : null;
                }}
              />
              {/* eslint-disable-next-line array-callback-return */}
              {props.initDataFields?.roadFields?.map(
                (f: VMField, i: number) => {
                  if (f.fieldType === FieldType.List) {
                    return (
                      <Column dataField={f.name} caption={f.labelText} key={i}>
                        <Lookup
                          dataSource={SortObjectByPropName(
                            f.fieldValues,
                            "name"
                          )}
                          valueExpr="id"
                          displayExpr="name"
                        />
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (f.fieldType === FieldType.Date) {
                    <Column
                      dataField={f.name}
                      caption={f.labelText}
                      calculateCellValue={(e: any) =>
                        GridActualDateCalculator(e, f, props.generalSettings)
                      }
                      key={i}
                      dataType="date"
                      format={props.generalSettings?.dateFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>;
                  } else if (
                    f.fieldType === FieldType.Time ||
                    f.fieldType === FieldType.DateTime
                  ) {
                    <Column
                      dataField={f.name}
                      caption={f.labelText}
                      calculateCellValue={(e: any) =>
                        GridActualDateCalculator(e, f, props.generalSettings)
                      }
                      key={i}
                      dataType="datetime"
                      format={props.generalSettings?.dateTimeFormat}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>;
                  } else {
                    return (
                      <Column
                        dataField={f.name}
                        caption={f.labelText}
                        alignment="left"
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                }
              )}
              <Column
                type="buttons"
                caption={t("actions")}
                width={110}
                visible={!props.isLocked}
                fixed={true}
                fixedPosition="right"
              >
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          )}

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={props.validationRef}>
                <Form colCount={2}>
                  <SimpleItem colSpan={1}>
                    <NumberBox
                      label={t("index")}
                      labelMode="floating"
                      value={
                        (dataGridRef.current
                          ?.instance()
                          .getDataSource()
                          ?.items().length || 0) + 1
                      }
                      className="modalInput"
                      style={{ marginBottom: "0" }}
                      disabled={true}
                    />
                  </SimpleItem>
                  {/* eslint-disable-next-line array-callback-return */}
                  {props.initDataFields?.roadFields?.map(
                    (f: VMField, i: number) => {
                      if (f.fieldType === FieldType.String) {
                        return (
                          <SimpleItem colSpan={1}>
                            <TextBox
                              label={f.labelText}
                              labelMode="floating"
                              key={i}
                              value={selectedData[f.name]}
                              onValueChange={(e) =>
                                onChangeField(f.name, e, f.isUpperCase)
                              }
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </TextBox>
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.Number) {
                        return (
                          <SimpleItem colSpan={1}>
                            <NumberBox
                              step={0}
                              label={f.labelText}
                              labelMode="floating"
                              key={i}
                              value={selectedData[f.name]}
                              onValueChange={(e) =>
                                onChangeField(f.name, e, f.isUpperCase)
                              }
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </NumberBox>
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.Boolean) {
                        return (
                          <SimpleItem colSpan={1}>
                            <div>{f.labelText}</div>
                            <Switch
                              style={{ marginTop: 5, fontWeight: "bold" }}
                              value={selectedData[f.name]}
                              onValueChange={(e) =>
                                onChangeField(f.name, e, false)
                              }
                              hint={f.labelText}
                            />
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.Date) {
                        return (
                          <SimpleItem colSpan={1}>
                            <DateBox
                              type="date"
                              label={f.labelText}
                              value={
                                f.exactDT === true
                                  ? DatePickerDateCalculator(
                                      selectedData[f.name]
                                    )
                                  : selectedData[f.name]
                              }
                              dateSerializationFormat={
                                f.exactDT === true
                                  ? "yyyy-MM-ddTHH:mm:ss"
                                  : undefined
                              }
                              onValueChange={(e) =>
                                onChangeField(f.name, e, false)
                              }
                              pickerType="calendar"
                              placeholder={props.generalSettings?.dateFormat}
                              displayFormat={props.generalSettings?.dateFormat}
                              useMaskBehavior={true}
                              openOnFieldClick={true}
                              showClearButton={true}
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </DateBox>
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.Time) {
                        return (
                          <SimpleItem colSpan={1}>
                            <DateBox
                              type="time"
                              label={f.labelText}
                              value={
                                f.exactDT === true
                                  ? DatePickerDateCalculator(
                                      selectedData[f.name]
                                    )
                                  : selectedData[f.name]
                              }
                              dateSerializationFormat={
                                f.exactDT === true
                                  ? "yyyy-MM-ddTHH:mm:ss"
                                  : undefined
                              }
                              onValueChange={(e) =>
                                onChangeField(f.name, e, false)
                              }
                              pickerType="rollers"
                              placeholder={props.generalSettings?.timeFormat}
                              displayFormat={props.generalSettings?.timeFormat}
                              useMaskBehavior={true}
                              openOnFieldClick={true}
                              showClearButton={true}
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </DateBox>
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.DateTime) {
                        return (
                          <SimpleItem colSpan={1}>
                            <DateBox
                              type="datetime"
                              label={f.labelText}
                              value={
                                f.exactDT === true
                                  ? DatePickerDateCalculator(
                                      selectedData[f.name]
                                    )
                                  : selectedData[f.name]
                              }
                              dateSerializationFormat={
                                f.exactDT === true
                                  ? "yyyy-MM-ddTHH:mm:ss"
                                  : undefined
                              }
                              onValueChange={(e) =>
                                onChangeField(f.name, e, false)
                              }
                              pickerType="calendar"
                              placeholder={
                                props.generalSettings?.dateTimeFormat
                              }
                              displayFormat={
                                props.generalSettings?.dateTimeFormat
                              }
                              useMaskBehavior={true}
                              openOnFieldClick={true}
                              showClearButton={true}
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </DateBox>
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.List) {
                        return (
                          <SimpleItem colSpan={1}>
                            <SelectBox
                              placeholder=""
                              label={f.labelText}
                              valueExpr="id"
                              displayExpr="name"
                              value={selectedData[f.name]}
                              onValueChange={(e) =>
                                onChangeField(f.name, e, f.isUpperCase)
                              }
                              items={SortObjectByPropName(
                                f.fieldValues,
                                "name"
                              )}
                              showClearButton={true}
                              searchEnabled={true}
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </SelectBox>
                          </SimpleItem>
                        );
                      }
                      if (f.fieldType === FieldType.TextArea) {
                        return (
                          <SimpleItem colSpan={1}>
                            <TextArea
                              label={f.labelText}
                              value={selectedData[f.name]}
                              onValueChange={(e) =>
                                onChangeField(f.name, e, f.isUpperCase)
                              }
                            >
                              {f.isRequired && (
                                <Validator>
                                  <RequiredRule
                                    message={t("fieldIsRequired")}
                                  />
                                </Validator>
                              )}
                            </TextArea>
                          </SimpleItem>
                        );
                      }
                    }
                  )}
                </Form>
              </ValidationGroup>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20, marginTop: "10px" }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      style={{ marginTop: "10px" }}
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      style={{ marginTop: "10px" }}
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </div>
      </React.Fragment>
    </div>
  );
};
export default Roads;
