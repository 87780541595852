import { Button, NumberBox, SelectBox } from "devextreme-react";
import Form, { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../components/permission/permision";
import {
  TMSPermissions,
  TrafficStudyPermissions,
} from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import { MatchingGeneralSettings } from "../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../../utils/rest";
import "./setting.scss";
import useBrowserTitle from "../../../../../hooks/browserTitle/useBrowserTitle";

const Settings = () => {
  const [initData, setInitData] = useState<MatchingGeneralSettings>(
    new MatchingGeneralSettings()
  );
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const { customerDivisions } = useClientSetting();

  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useBrowserTitle({ pathname: "/trafficStudy/matching/setups/settings" });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    var res = (await TesGet(
      TrafficApiUrl() +
        "/api/MatchingSettings/GetCustomerMatchingGeneralSettings/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<MatchingGeneralSettings>;
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  function onValueChange(name: string, value: any) {
    setInitData({ ...initData, [name]: value });
  }

  async function onSave() {
    console.log(initData);
    try {
      if (activeLoading) activeLoading(true);
      let postObj = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId")!,
      };
      const res = (await TesPost(
        TrafficApiUrl() +
          "/api/MatchingSettings/AddUpdateMatchingGeneralSettings",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div
        className={`signalWarrants ${compactViewModel ? "compactStyle" : ""}`}
      >
        <React.Fragment>
          <h2 className={"content-block"}>{t("matchingSettings")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div className="row" style={{ marginTop: 15 }}>
                <div className="rightColumn">
                  <Button
                    id="rowButtons"
                    onClick={() => LockHandler()}
                    icon={
                      isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                    }
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">
                  {isLocked === false && (
                    <Button
                      id="rowButtons"
                      onClick={() => onSave()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    // style={{ marginBottom: 20 }}
                    className="firstLine"
                    label={t("division")}
                    labelMode="floating"
                    displayExpr="name"
                    valueExpr="id"
                    value={initData?.divisionId}
                    onValueChange={(e) => onValueChange("divisionId", e)}
                    items={SortObjectByPropName(customerDivisions!, "name")}
                    disabled={isLocked}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    // format="#,###"
                    step={0}
                    className="firstLine"
                    label={t("outCycleMaxScore")}
                    labelMode="floating"
                    value={initData?.outCycleMaxScore}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("outCycleMaxScore", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    // format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("allOutCycleMaxScore")}
                    labelMode="floating"
                    value={initData?.allOutCycleMaxScore}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("allOutCycleMaxScore", e)
                    }
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    // format="#,###"
                    step={0}
                    className="middleLine"
                    label={t("topNMatchSelection")}
                    labelMode="floating"
                    value={initData?.topNMatchSelection}
                    disabled={isLocked}
                    onValueChange={(e) =>
                      onValueChange("topNMatchSelection", e)
                    }
                  />
                </SimpleItem>
                <SimpleItem>
                  <NumberBox
                    // format="#,###"
                    step={0}
                    className="lastLine"
                    label={t("minYear")}
                    labelMode="floating"
                    value={initData?.minYear}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("minYear", e)}
                  />
                </SimpleItem>
              </Form>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Settings;
