import { Button, Tabs } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import { TMSPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { CollisionApiUrl } from "../../../../environment/routeSettings";
import {
  XMLRedaction,
  XMLRedactionParams,
} from "../../../../types/collision/collisionTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import {
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  RequestErrorHandling,
  TesDelete,
  TesGet,
  TesPost,
} from "../../../../utils/rest";
import General from "./components/general";
import PDFRedaction from "./components/pdfreduction";
import PictureExtractionSettings from "./components/pictureExtraction";
import XMLTags from "./components/xmltags";
import tabTitles from "./data/index";
import { Enum2Array } from "../../../../utils/enumTools";
import { XmlRedactionDefault } from "../../../../types/collision/enums/collisionEnums";
import TesTabs from "../../../../components/tesTab/tesTab";

const XMLRedactionDetails = () => {
  const history = useNavigate();
  const params = useParams<XMLRedactionParams>();
  const [initData, setInitData] = useState<XMLRedaction>(new XMLRedaction());
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const [lstXmlRedactionDefault, setLstXmlRedactionDefault] = useState<
    NameValue[]
  >([]);
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const validationRef = useRef<ValidationGroupRef>(null);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstXmlRedactionDefault(Enum2Array(XmlRedactionDefault));
        if (params.id !== "AddNew") {
          await getInitData(params.id!);
        } else {
          setIsLocked(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        CollisionApiUrl() +
          "/api/XmlFileManager/GetReductionFieldsById/" +
          params.id,
        true
      )) as RequestResponseResult<XMLRedaction>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  async function addXMLRedaction() {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: XMLRedaction = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId") as string,
      };
      const res = (await TesPost(
        CollisionApiUrl() + "/api/XmlFileManager/CreateReduction",
        postObj,
        true
      )) as RequestResponseResult<XMLRedaction>;
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
      } else {
        await RequestErrorHandling(res);
      }
      if (activeLoading) activeLoading(false);
      else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function updateXMLRedaction() {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: XMLRedaction = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId") as string,
      };
      const res = (await TesPost(
        CollisionApiUrl() + "/api/XmlFileManager/UpdateReduction",
        postObj,
        true
      )) as RequestResponseResult<XMLRedaction>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        // history(-1);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onDelete() {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e: any) => {
            try {
              await TesDelete(
                CollisionApiUrl() +
                  "/api/XmlFileManager/DeleteReduction/" +
                  params.id,
                true
              );
              history(-1);
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e: any) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  return (
    <Permission allowed={[TMSPermissions.TMS_Admin]} hasFeedBackElement={true}>
      <div
        className={`xmlImportLogDetails ${
          compactViewModel ? "compactStyle" : ""
        }`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.id !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p
                    ref={headingParagraphRef}
                    className={"detailsHeading"}
                    style={{ display: "inline" }}
                  >
                    <span>
                      {t("name")}: {initData?.name}
                    </span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => history(-1)}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn">
                  {params.id !== "AddNew" && isLocked === false && (
                    <Permission
                      allowed={[TMSPermissions.TMS_Admin]}
                      hasFeedBackElement={true}
                    >
                      <Button
                        onClick={() => onDelete()}
                        icon={"fa-solid fa-trash"}
                        hint={t("delete")}
                      />
                    </Permission>
                  )}
                  {params.id === "AddNew" && (
                    <Permission
                      allowed={[TMSPermissions.TMS_Admin]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => addXMLRedaction()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    </Permission>
                  )}
                  {params.id !== "AddNew" && isLocked === false && (
                    <Permission
                      allowed={[TMSPermissions.TMS_Admin]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => updateXMLRedaction()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    </Permission>
                  )}
                  <Permission
                    allowed={[TMSPermissions.TMS_Admin]}
                    hasFeedBackElement={true}
                  >
                    <Button
                      onClick={() => LockHandler()}
                      icon={
                        isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"
                      }
                      hint={isLocked ? t("unlock") : t("lock")}
                    />
                  </Permission>
                  <div className="rightColumn"></div>
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <TesTabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onTabChange={handleTabChange}
              />

              {selectedIndex === 0 && (
                <General
                  isLocked={isLocked}
                  initData={initData}
                  setInitData={setInitData}
                  validationRef={validationRef}
                  lstXmlRedactionDefault={lstXmlRedactionDefault}
                />
              )}

              {selectedIndex === 1 && (
                <XMLTags
                  isLocked={isLocked}
                  initData={initData}
                  setInitData={setInitData}
                  validationRef={validationRef}
                />
              )}

              {selectedIndex === 2 && (
                <PDFRedaction
                  isLocked={isLocked}
                  initData={initData}
                  setInitData={setInitData}
                  validationRef={validationRef}
                />
              )}

              {selectedIndex === 3 && (
                <PictureExtractionSettings
                  isLocked={isLocked}
                  initData={initData}
                  setInitData={setInitData}
                  validationRef={validationRef}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default XMLRedactionDetails;
