import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import {
  FieldApiUrl,
  InfrastructureApiUrl,
  ReportApiUrl,
} from "../../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../../types/field/dto/fieldDTO";
import { FieldCategoryType } from "../../../types/field/enums/fieldEnums";
import { TesField } from "../../../types/field/fieldType";
import {
  PageMode,
  PagePermission,
  ResponseCode,
} from "../../../types/general/enums/generalEnums";
import {
  INameId,
  RequestResponseResult,
  TabTitle,
} from "../../../types/general/generalTypes";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import {
  IIntersectionParams,
  IntersectionDescription,
  Location,
} from "../../../types/infrastructure/infrastructureTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import AADT from "./components/aadt";
import ApproachDetails from "./components/approachDetails/approachDetails";
import Collision from "./components/collision/collision";
import Details from "./components/details/details";
import General from "./components/general/general";
import LocationMap from "./components/location/location";
import Operation from "./components/operation/operation";
import OverView from "./components/overView/overView";
import Sign from "./components/sign/sign";
import Support from "./components/support/support";
import TrafficStudy from "./components/trafficStudy/trafficStudy";
import TabsDataUpdate, { TabsDataAdd } from "./data";
import "./intersectionDetails.scss";
import TesTabs from "../../../components/tesTab/tesTab";
import useBrowserTitle from "../../../hooks/browserTitle/useBrowserTitle";

const IntersectionDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [description, setDescription] = useState<IntersectionDescription>(
    new IntersectionDescription()
  );
  const [dataChanged, setDataChanged] = useState(false);
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [initDataSiteSubTypes, setInitDataSiteSubTypes] = useState<INameId[]>(
    []
  );
  const [location, setLocation] = useState<Location>(new Location());
  const [pageMode, setPageMode] = useState<number>(0);
  const [titles, setTitles] = useState<TabTitle[]>([]);
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const params = useParams<IIntersectionParams>();
  const { generalSetting } = useClientSetting();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);
  const validationRef = useRef<ValidationGroupRef>(null);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const locDescRef = useRef<HTMLSpanElement>(null);
  const { getPagePermission } = useAuth();
  const [pagePermissionStatus, setPagePermissionStatus] =
    useState<PagePermission>(PagePermission.Deny);

  useBrowserTitle({
    pathname: "/infrastructure/intersectionDetails",
    additionalText: `[${location.geoId}] ${location.description}`,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (location.overwriteAutoGenerateDescription) {
        Promise.all([
          getStreetName(location?.street1Id),
          getStreetName(location?.street2Id),
          getStreetName(location?.street3Id),
          getStreetName(location?.street4Id),
        ]).then((e) => {
          onChangeDescription(e[0], e[1], e[2], e[3]);
        });
      }
    };
    fetchData();
  }, [
    location.street1Id,
    location.street2Id,
    location.street3Id,
    location.street4Id,
  ]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInitDataSiteSubTypes();
        if (params.intersectionId !== "AddNew") {
          setTitles(TabsDataUpdate(params.intersectionId!));
          setPageMode(PageMode.Update);
          await getIntersectionData(params.intersectionId!);
        } else {
          setTitles(TabsDataAdd(params.intersectionId!));
          setIsLocked(false);
          setPageMode(PageMode.Add);
        }
        if (activeLoading) activeLoading(false);
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.intersectionId]);

  async function getStreetName(id: string) {
    if (id === undefined || id === null || id === "") return "";
    const res = (await TesGet(
      InfrastructureApiUrl() + "/api/streets/GetStreetName/" + id,
      true
    )) as RequestResponseResult<INameId>;
    if (res.responseCode === ResponseCode.OK) {
      return res?.results?.name ?? "";
    } else {
      await RequestErrorHandling(res);
    }
    return "";
  }

  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem("selectedCustomerId")!,
      categoryTypes: [
        FieldCategoryType.InfrastructureIntersectionGeneral,
        FieldCategoryType.InfrastructureIntersectionDetails,
        FieldCategoryType.InfrastructureIntersectionApproachDetails,
        FieldCategoryType.InfrastructureIntersectionOperation,
      ],
    };
    var res = (await TesPost(
      FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory",
      postOb,
      true
    )) as TesField[];
    res = res.sort(function (a, b) {
      return a.detailViewIndex - b.detailViewIndex;
    });
    setInitDataFields(res);
  }

  async function getInitDataSiteSubTypes() {
    var res = (await TesGet(
      InfrastructureApiUrl() +
        "/api/SiteTypes/GetAllSiteSubTypesName/" +
        localStorage.getItem("selectedCustomerId"),
      true
    )) as RequestResponseResult<INameId[]>;
    if (res.responseCode === ResponseCode.OK) {
      setInitDataSiteSubTypes(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  function handleTabChange(index: number) {
    setSelectedIndex(index);
  }

  function onChangeDescription(
    streetName1: string,
    streetName2: string,
    streetName3: string,
    streetName4: string
  ) {
    if (location.overwriteAutoGenerateDescription) {
      let description = streetName1.trim() + " @ " + streetName2.trim();
      if (streetName3) {
        description += " /" + streetName3.trim();
      }
      if (streetName4) {
        description += " /" + streetName4.trim();
      }
      setLocation((prev) => {
        return { ...prev, description };
      });
    }
    setDataChanged(true);
  }

  async function getIntersectionData(Id: string) {
    const postObj = {
      Id: Id,
      CustomerId: localStorage.getItem("selectedCustomerId"),
    };
    const res = (await TesPost(
      InfrastructureApiUrl() + "/api/Locations/GetIntersectionData",
      postObj,
      true
    )) as RequestResponseResult<Location>;
    if (res.responseCode === ResponseCode.OK) {
      if (res == null) {
        notify("The intersection does not exist.", "error", 5000);
        return;
      }
      setLocation(res.results);
      if (getPagePermission)
        setPagePermissionStatus(
          getPagePermission(
            [InfrastructurePermissions.Infrastructure_D_Site],
            res.results
          )
        );
    } else {
      await RequestErrorHandling(res);
    }
  }
  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.intersectionId !== "AddNew") {
                  updateIntersection();
                  setDataChanged(false);
                } else {
                  addIntersection();
                  setDataChanged(false);
                  history(-1);
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              //setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function updateIntersection() {
    try {
      if (activeLoading) activeLoading(true);
      var postObj: Location = {
        ...location,
        id: params.intersectionId!,
        customerId: localStorage.getItem("selectedCustomerId") as string,
        locationType: 2,
        description: location.description,
        // ....mapLocation,
      };
      try {
        var res = (await TesPost(
          InfrastructureApiUrl() + "/api/Locations/UpdateIntersection",
          postObj,
          true
        )) as RequestResponseResult<Location>;
        if (activeLoading) activeLoading(false);
        if (res.responseCode === ResponseCode.OK) {
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
        } else if (res.responseCode === ResponseCode.IdNotExists) {
          notify(t("idNotExists"), "error", 5000);
        } else {
          await RequestErrorHandling(res);
        }
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    setDataChanged(false);
  }

  async function addIntersection() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        if (activeLoading) activeLoading(true);
        var postObj: Location = {
          ...location,
          customerId: localStorage.getItem("selectedCustomerId") as string,
          divisionId:
            location.divisionId === "" ||
            location.divisionId === undefined ||
            location.divisionId === null
              ? localStorage.getItem("defaultDivisionId")!
              : location.divisionId,
          locationType: LocationType.Intersection,
          description: location.description,
          //...mapLocation
        };
        const res = (await TesPost(
          InfrastructureApiUrl() + "/api/Locations/AddNewIntersection",
          postObj,
          true
        )) as RequestResponseResult<Location>;
        if (res.responseCode === ResponseCode.OK) {
          notify(t("dataSuccessfullyAdded"), "success", 5000);
          history(-1);
        } else {
          await RequestErrorHandling(res);
        }
        if (activeLoading) activeLoading(false);
        if (dataChanged === false) {
          history("/infrastructure/intersections");
          return;
        } else {
          await RequestErrorHandling(res);
        }
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    setDataChanged(false);
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && locDescRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = locDescRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_D_Site,
        InfrastructurePermissions.Infrastructure_R_Site,
        InfrastructurePermissions.Infrastructure_V_Site,
      ]}
      hasFeedBackElement={true}
    >
      <div
        className={`intersectionDetails ${
          compactViewModel ? "compactStyle" : ""
        }`}
      >
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t("intersection")}: </span>
                    <span ref={locDescRef}>{location?.description}</span>
                    <span> </span>
                    <span ref={geoIdRef}>[{location?.geoId}]</span>
                  </p>
                  <DropDownButton
                    style={{ marginLeft: 10, width: "5rem" }}
                    icon="fa-solid fa-copy"
                    items={[
                      { name: t("geoId"), value: t("geoId") },
                      { name: t("location"), value: t("location") },
                    ]}
                    dropDownOptions={{
                      width: MaxDropdownItemWidthCalculator([
                        { name: t("geoId"), value: t("geoId") },
                        { name: t("location"), value: t("location") },
                      ]),
                    }}
                    displayExpr="name"
                    onItemClick={(e) => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                  <hr
                    className="line"
                    style={{ display: "block", marginTop: "1rem" }}
                  ></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                {pagePermissionStatus === PagePermission.Edit &&
                  params.intersectionId !== "AddNew" && (
                    <div className="rightColumn">
                      <Permission
                        allowed={[
                          InfrastructurePermissions.Infrastructure_D_Site,
                        ]}
                        hasFeedBackElement={false}
                      >
                        <Button
                          onClick={() => LockHandler()}
                          icon={
                            isLocked
                              ? "fa-solid fa-lock"
                              : "fa-solid fa-lock-open"
                          }
                          hint={isLocked ? t("unlock") : t("lock")}
                        />
                      </Permission>
                    </div>
                  )}
                <div className="rightColumn">
                  {params.intersectionId === "AddNew" && (
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_D_Site,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => addIntersection()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("save")}
                      />
                    </Permission>
                  )}
                  {params.intersectionId !== "AddNew" && <ShareURL url={url} />}
                  {params.intersectionId !== "AddNew" && isLocked === false && (
                    <Permission
                      allowed={[
                        InfrastructurePermissions.Infrastructure_D_Site,
                      ]}
                      hasFeedBackElement={false}
                    >
                      <Button
                        onClick={() => updateIntersection()}
                        icon="fa-solid fa-floppy-disk"
                        hint={t("update")}
                      />
                    </Permission>
                  )}
                </div>
              </div>
            </div>
            {params.intersectionId !== "AddNew" ? (
              <div className={"dx-card "}>
                <TesTabs
                  width={"100%"}
                  dataSource={titles}
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />
                {selectedIndex === 0 && (
                  <OverView
                    locationId={location.id}
                    isLocked={isLocked}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 1 && (
                  <General
                    validationRef={validationRef}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    isLocked={isLocked}
                  />
                )}
                {selectedIndex === 2 && (
                  <LocationMap
                    validationRef={validationRef}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    isLocked={isLocked}
                  />
                )}

                {selectedIndex === 3 && (
                  <Details
                    location={location}
                    setLocation={setLocation}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                    initDataFields={initDataFields}
                  />
                )}
                {selectedIndex === 4 && (
                  <ApproachDetails
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 5 && (
                  <Operation
                    initDataFields={initDataFields}
                    siteSubeTypes={initDataSiteSubTypes}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 6 && (
                  <Collision
                    intersectionId={params.intersectionId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 7 && (
                  <TrafficStudy
                    intersectionId={params.intersectionId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 8 && (
                  <AADT
                    intersectionId={params.intersectionId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 9 && (
                  <Sign
                    intersectionId={params.intersectionId!}
                    isLocked={isLocked}
                    generalSettings={generalSetting}
                    validationRef={validationRef}
                  />
                )}
                {selectedIndex === 10 && (
                  <Support
                    intersectionId={params.intersectionId!}
                    isLocked={isLocked}
                    validationRef={validationRef}
                  />
                )}
              </div>
            ) : (
              <div className={"dx-card "}>
                <TesTabs
                  width={"100%"}
                  dataSource={titles}
                  selectedIndex={selectedIndex}
                  onTabChange={handleTabChange}
                />
                {selectedIndex === 0 && (
                  <General
                    validationRef={validationRef}
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    isLocked={isLocked}
                  />
                )}
                {selectedIndex === 1 && (
                  <Details
                    location={location}
                    setLocation={setLocation}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                    initDataFields={initDataFields}
                  />
                )}
                {selectedIndex === 2 && (
                  <ApproachDetails
                    initDataFields={initDataFields}
                    location={location}
                    setLocation={setLocation}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    validationRef={validationRef}
                    generalSettings={generalSetting}
                  />
                )}
                {selectedIndex === 3 && (
                  <Operation
                    initDataFields={initDataFields}
                    siteSubeTypes={initDataSiteSubTypes}
                    location={location}
                    setLocation={setLocation}
                    generalSettings={generalSetting}
                    isLocked={isLocked}
                    setDataChanged={setDataChanged}
                    pageMode={pageMode}
                    validationRef={validationRef}
                  />
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default IntersectionDetails;
