import { useEffect } from "react";
import { TITLE_TEXT } from "./constants";
import { useTranslation } from "react-i18next";
import { useTranslations } from "../../contexts/translations";
import { TPathName } from "./types";

type TProps = {
  pathname: TPathName;
  additionalText?: string;
};
const useBrowserTitle = (props: TProps) => {
  const { pathname, additionalText = "" } = props;

  const { availableTranslations, selectedLanguage } = useTranslations();
  const { t } = useTranslation();

  useEffect(() => {
    let tTag: string = "";
    let title: string = "";
    for (const [key, item] of Object.entries(TITLE_TEXT)) {
      if (item.condition === "exact" && key === pathname) {
        tTag = item.tTag;
        break;
      } else if (item.condition === "startWith" && pathname.startsWith(key)) {
        tTag = item.tTag;
        break;
      }
    }
    if (tTag) {
      title = `${t(tTag)} ${additionalText} | TES`;
    }
    document.title = title;
  }, [availableTranslations, selectedLanguage, pathname, additionalText]);
};

export default useBrowserTitle;
