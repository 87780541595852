import { SelectBox, Switch, TextBox, ValidationGroup } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, SimpleItem } from "devextreme-react/form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NameValue } from "../../../../../../types/general/generalTypes";
import {
  DataSourceType,
  LocationType,
} from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import { FieldDataSource } from "../../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../../utils/enumTools";

// props
interface IPros {
  initDataFieldDataSource: FieldDataSource;
  setInitDataFieldDataSource: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  lockField: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const [lstDataSourceType, setLstDataSourceType] = useState<NameValue[]>([]);
  const [lstInfrastructureType, setLstInfrastructureType] = useState<
    NameValue[]
  >([]);
  const { t } = useTranslation();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  useEffect(() => {
    setLstDataSourceType(Enum2Array(DataSourceType));
    setLstInfrastructureType(Enum2Array(LocationType));
  }, []);

  function onValueChange(name: string, value: any) {
    props.setInitDataFieldDataSource({
      ...props.initDataFieldDataSource,
      [name]: value,
    });
    props.setDataChanged(true);
  }

  return (
    <div
      className={`fieldDataSourceDetails-details ${compactViewModel ? "compactStyle" : ""}`}
    >
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <div className="row" style={{ marginTop: 20, padding: 10 }}>
            <Form colCount={2}>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("dataSourceType")}
                  valueExpr="value"
                  displayExpr="name"
                  value={props.initDataFieldDataSource.dataSourceType}
                  labelMode="floating"
                  onValueChange={(e) => onValueChange("dataSourceType", e)}
                  items={SortObjectByPropName(lstDataSourceType, "name")}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("dataSourceName")}
                  labelMode="floating"
                  value={props.initDataFieldDataSource.dataSourceName}
                  onValueChange={(e) => onValueChange("dataSourceName", e)}
                  disabled={props.isLocked}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("infrastructureType")}
                  valueExpr="value"
                  displayExpr="name"
                  value={props.initDataFieldDataSource.infrastructureType}
                  labelMode="floating"
                  onValueChange={(e) => onValueChange("infrastructureType", e)}
                  items={SortObjectByPropName(lstInfrastructureType, "name")}
                  disabled={props.isLocked || props.lockField}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <div style={{ color: "#424242" }}>{t("isApproachLayer")}</div>
                <Switch
                  style={{ marginTop: 5 }}
                  hint={t("isApproachLayer")}
                  value={props.initDataFieldDataSource.isApproachLayer}
                  onValueChange={(e) => onValueChange("isApproachLayer", e)}
                  disabled={props.isLocked}
                />
              </SimpleItem>
            </Form>
          </div>
        </ValidationGroup>
      </React.Fragment>
    </div>
  );
};
export default Details;
