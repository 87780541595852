import { MessageType, ResponseCode } from "./enums/generalEnums";

export class NameValue {
  name!: string;
  value!: string;
}
export class NameValueNumber {
  name!: string;
  value!: number;
}
export interface INameId {
  name: string;
  id: string;
}

export class RequestResponseResult<T> {
  messages!: ErrorMessage[];
  responseCode!: ResponseCode;
  isChanged!: boolean;
  results!: T;
}

export class ErrorMessage {
  id!: string;
  message!: string;
  messageCode!: ResponseCode;
  type!: MessageType;
}

export class GoogleRecapchResponse {
  success!: boolean;
  challenge_ts!: Date;
  score!: number;
  action!: string;
}

export class AttachmentDownloadURL {
  id!: string;
  downloadUrl!: string;
}

export class TabTitle {
  id!: number;
  text!: string;
  icon!: string;
  content!: string;
  disabled?: boolean;
}

export class CopySetting {
  fromCustomerId!: string;
  toCustomerId!: string;
}

export class GridSetting {
  horizontalScrolling: boolean = true;
}

export class GeneralUISetting {
  viewMode: string = "normal";
}

export type Dictionary<Key extends keyof any, Value> = {
  [key in Key]: Value; // Mapped types syntax
};

export class LazyLoadingRequestBase {
  customerId!: string;
  divisionId?: string | null;
  filter?: LazyLoadingFilter;
}

export class LazyLoadingRequest extends LazyLoadingRequestBase {
  loadOptions!: any;
}

export class LazyLoadingFilter {
  id?: string;
  type?: number;
  returnAll?: boolean;
}
