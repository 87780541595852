import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  PropsWithChildren,
} from "react";
import { LanguageDetailsDto } from "../../types/translation/dtos/translationDtos";
import i18n from "../../react-i18next";
import { locale } from "devextreme/localization";
import {
  fetchLanguagesList,
  fetchTranslation,
  getStoredSelectedLanguage,
  getStoredTranslations,
  storeSelectedLangKeyName,
  storeSelectedLanguage,
  storeTranslations,
  storeTranslationsKeyName,
} from "./utils";
import { useTranslation } from "react-i18next";

export type TTranslation = LanguageDetailsDto & {
  translation: {
    common: Record<string, string>;
  };
};

type TTranslationContext = {
  availableTranslations: string[];
  clearStoredTranslations: () => void;
  selectedLanguage: string;
  onChangeLanguage: (lang: string) => void;
  translateObjectKeys: (
    list: Array<Record<string, any>>,
    keysToTranslate: Array<string>
  ) => Array<Record<string, any>>;
  reFetchTranslations: () => void;
  isTranslationLoading: boolean;
};
const initialData: TTranslationContext = {
  availableTranslations: ["defaultEn"],
  clearStoredTranslations: () => {},
  selectedLanguage: "defaultEn",
  onChangeLanguage: (lang: string) => {},
  translateObjectKeys: (
    list: Array<Record<string, any>>,
    keysToTranslate: Array<string>
  ) => [],
  reFetchTranslations: () => {},
  isTranslationLoading: false,
};

export const TranslationsContext = createContext(initialData);

const useTranslations = () => useContext(TranslationsContext);

function TranslationsProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation();

  const [availableTranslations, setAvailableTranslations] = useState<string[]>([
    "defaultEn",
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("defaultEn");
  const [isLoading, setIsLoading] = useState(false);

  const fetchAndManageTranslationsStorage = async (
    languageList: LanguageDetailsDto[]
  ) => {
    let storedTranslations = getStoredTranslations();

    const fetchedLanguagesIds = languageList.map((item) => item.id);
    //remove stored translations that are not in fetched languages
    storedTranslations = storedTranslations?.filter((item) =>
      fetchedLanguagesIds.includes(item.id)
    );

    const newTranslations: TTranslation[] = storedTranslations
      ? [...storedTranslations]
      : [];

    await Promise.all(
      languageList.map(async (langItem) => {
        const storedTranslation = storedTranslations?.find(
          (x) => x.languageCode === langItem.languageCode
        );
        if (
          !storedTranslation ||
          (storedTranslation.id === langItem.id &&
            storedTranslation?.lastChangedUtcDateTime <
              langItem.lastChangedUtcDateTime)
        ) {
          const fetchedTranslation = await fetchTranslation(
            langItem.languageType,
            langItem.id
          );
          if (fetchedTranslation) {
            if (storedTranslation) {
              newTranslations.splice(
                newTranslations.indexOf(storedTranslation),
                1
              );
            }
            newTranslations.push({
              ...langItem,
              translation: fetchedTranslation.translationObject,
            });
          }
        }
      })
    );
    storeTranslations(newTranslations);
  };
  const manageSelectedLanguage = () => {
    const storedSelectedLang = getStoredSelectedLanguage();
    const translations = getStoredTranslations();

    let languageShouldBeSelected = storedSelectedLang || "defaultEn";
    if (
      !storedSelectedLang ||
      !translations.find((t) => t.languageCode === storedSelectedLang)
    ) {
      languageShouldBeSelected =
        translations.find((t) => t.isDefault)?.languageCode || "defaultEn";
      storeSelectedLanguage(languageShouldBeSelected);
    }
    setSelectedLanguage(languageShouldBeSelected);
  };
  const manageAvailableLanguages = () => {
    const storedTranslations: TTranslation[] = getStoredTranslations();
    setAvailableTranslations(
      storedTranslations.map((item) => item.languageCode)
    );
  };
  const changeDevExtremeLanguage = () => {
    const selectedLanguage = getStoredSelectedLanguage();
    if (selectedLanguage) {
      locale(selectedLanguage);
    }
  };
  const configI18n = () => {
    const storedTranslations: TTranslation[] = getStoredTranslations();

    let resources = {};
    storedTranslations.map((item) => {
      resources = { ...resources, [item.languageCode]: item.translation };
    });

    storedTranslations.forEach((item) => {
      i18n.addResourceBundle(
        item.languageCode,
        "common",
        item.translation.common,
        true,
        true
      );
    });
  };
  const changeI18nLanguage = async () => {
    await i18n.changeLanguage(getStoredSelectedLanguage() || undefined);
  };

  const onChangeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    storeSelectedLanguage(language);
    manageSelectedLanguage();
    changeDevExtremeLanguage();
    document.location.reload();
  };

  const clearStoredTranslations = useCallback(() => {
    localStorage.removeItem(storeTranslationsKeyName);
    localStorage.removeItem(storeSelectedLangKeyName);
    setAvailableTranslations(["defaultEn"]);
    setSelectedLanguage("defaultEn");
  }, []);

  const fetchAndConfigTranslations = async () => {
    setIsLoading(true);
    const languagesList = await fetchLanguagesList();
    if (languagesList && languagesList.length > 0) {
      await fetchAndManageTranslationsStorage(languagesList);
      configI18n();
      manageSelectedLanguage();
      await changeI18nLanguage();
      manageAvailableLanguages();
      changeDevExtremeLanguage();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAndConfigTranslations();
  }, []);

  const translateObjectKeys = useCallback(
    (
      list: Array<Record<string, any>>,
      keysToTranslate: Array<string>
    ): Array<Record<string, any>> => {
      if (
        !list ||
        !Array.isArray(list) ||
        !keysToTranslate ||
        !Array.isArray(keysToTranslate)
      ) {
        return list;
      }

      return list.map((item) => {
        const translatedItem = { ...item };
        keysToTranslate.forEach((key) => {
          if (key in item && typeof item[key] === "string") {
            translatedItem[key] = t(item[key]);
          }
        });
        return translatedItem;
      });
    },
    [selectedLanguage, availableTranslations]
  );

  const reFetchTranslations = async () => {
    await fetchAndConfigTranslations();
  };

  const value = {
    availableTranslations,
    selectedLanguage,
    onChangeLanguage,
    clearStoredTranslations,
    translateObjectKeys,
    reFetchTranslations,
    isTranslationLoading: isLoading,
  };
  return (
    <TranslationsContext.Provider value={value}>
      {children}
    </TranslationsContext.Provider>
  );
}

export { TranslationsProvider, useTranslations };
